import React from "react";
import firebase from "firebase";
import { UserView } from "./UserView";
import {
  CrudlStatus,
  ToggleState,
  PageState,
  FeedbackState,
  ViewType,
  BusinessType,
  UserRoleType,
} from "../../common/config/AppConstants";
import { ViewState } from "../../common/config/AppConstants";
import { ValidateState } from "../../common/config/AppConstants";
import * as pr from "../../common/PrimeComponents";
import * as format from "../../common/FormatFunctions";
import { ErrorScreen } from "../Common/ErrorScreen";
import * as configuration from "../../common/config/Configuration";

export class UserController extends React.Component {
  state = {
    pageName: "UserController",
    pageState: PageState.IDLE,
    sidebarWidth: "50%",

    //DISPLAY STATES
    viewState: ViewState.TABLE,
    validateState: ValidateState.NONE,
    crudlState: CrudlStatus.LIST,
    menuState: [],

    // PROPS
    viewType: ViewType.STANDARD,

    // DATA TABLE
    expandedRows: null,
    tableColumns: [],
    columnOptions: [],
    settingsToggle: ToggleState.OFF,

    // DETAILS
    userList: null,
    originalEditedUser: "",
    editedUser: null,
    receivedFeedback: {
      state: FeedbackState.NONE,
      display: "User",
      crudlStatus: CrudlStatus.LIST,
    },
    codeTag: "",
  };

  componentDidMount = async () => {
    await this.initialDataRender();
  };

  componentDidCatch = (error, info) => {
    this.props.log("This threw an error");
    this.props.log(error);
    this.props.log(info);
    //COMPONENT ISSUE CHECK IF THIS ERROR HANDLING IS CORRECT
    this.setError(false, "componentDidCatch", error);
  };

  initialDataRender = async () => {
    this.setViewModel();
    await this.setPageData();
  };

  setError = (critical, methodName, error) => {
    this.props.log.error(error.message);
    if (critical) {
      this.setCriticalError();
    } else {
      this.setStandardError();
    }
  };
  setStandardError = () => {
    //MORE CAN BE ADDED HERE AS APPROPRIATE
    this.showError("User");
    this.setState({ pageState: PageState.IDLE });
  };
  setCriticalError = () => {
    //SET ERROR STATE OF PAGE
    this.setState({ pageState: PageState.ERROR });
  };

  setViewModel = () => {
    try {
      this.props.viewModel.updateState = this.updateState;
      this.props.viewModel.showError = this.showError;
      this.props.viewModel.showSuccess = this.showSuccess;
      this.props.viewModel.showEmpty = this.showEmpty;
    } catch (error) {
      this.setError(false, "setViewModel", error);
    }
  };

  setPageData = async () => {
    try {
      this.setState({ pageState: PageState.LOADING });

      this.menuState(CrudlStatus.LIST);

      await this.handleProps();
      this.setUpColumns();

      // SPECIFIC API CALLS GO HERE
      if (
        this.props.viewType === ViewType.STANDARD ||
        this.props.viewType === ViewType.COUNCIL
      ) {
        await this.props.viewModel.getUsers();
      } else if (this.props.viewType === ViewType.MY_BUSINESS) {
        console.log("Hit MY BUSINESS", this.state.codeTag);
        await this.props.viewModel.getUsers(this.state.codeTag);
      }

      this.setState({
        pageState: PageState.IDLE,
      });
    } catch (error) {
      this.setError(true, "setPageData", error);
    }
  };
  handleProps = async () => {
    if (format.isNotNull(this.props.viewType)) {
      await this.setState({ viewType: this.props.viewType });
    }
    if (format.isNotNull(this.props.sidebarWidth)) {
      await this.setState({ sidebarWidth: this.props.sidebarWidth });
    }
    if (format.isNotNull(this.props.crudlState)) {
      await this.setState({ crudlState: this.props.crudlState });
      // Add Conditions per viewType if applicable
      if (this.props.crudlState === CrudlStatus.CREATE) {
        this.executeSetup(this.props.crudlState);
      } else if (this.props.crudlState === CrudlStatus.UPDATE) {
        this.executeSetup(
          this.props.crudlState,
          this.props.editedObjectFromProps
        );
      }
    }
    if (format.isNotNull(this.props.codeTag)) {
      await this.setState({ codeTag: this.props.codeTag });
    }
  };
  showError = (display, crudlStatus, whereToView) => {
    try {
      if (whereToView === ViewState.TABLE) {
        this.props.feedback.showError(this.growl, display, crudlStatus);
      }
      if (whereToView === ViewState.CARD) {
        this.setState({
          receivedFeedback: {
            state: FeedbackState.ERROR,
            display: display,
            crudlStatus: crudlStatus,
          },
        });
      }
    } catch (error) {
      this.setError(false, "showError", error);
    }
  };

  showSuccess = (display, crudlStatus, whereToView) => {
    try {
      if (whereToView === ViewState.TABLE) {
        this.props.feedback.showSuccess(this.growl, display, crudlStatus);
      }
      if (whereToView === ViewState.CARD) {
        this.setState({
          receivedFeedback: {
            state: FeedbackState.SUCCESS,
            display: display,
            crudlStatus: crudlStatus,
          },
        });
      }
    } catch (error) {
      this.setError(false, "showSuccess", error);
    }
  };

  showEmpty = (display, whereToView) => {
    try {
      if (whereToView === ViewState.TABLE) {
        this.props.feedback.showEmpty(this.growl, display);
      }
      if (whereToView === ViewState.CARD) {
        this.setState({
          receivedFeedback: {
            state: FeedbackState.EMPTY,
            display: display,
            crudlStatus: CrudlStatus.LIST,
          },
        });
      }
    } catch (error) {
      this.setError(false, "showEmpty", error);
    }
  };

  getStateValuesAsObject = () => {
    try {
      var externalObject = {};
      externalObject.userBean = this.state.editedUser;
      if (format.isNotNull(this.state.codeTag)) {
        externalObject.userBean.codeTag = this.state.codeTag;
      }
      externalObject.viewType = this.state.viewType;

      return externalObject;
    } catch (error) {
      this.setError(false, "getStateValuesAsObject", error);
    }
  };

  updateBean = async (field, value) => {
    try {
      this.props.log.info(
        "Updating : [" + field + "] with value [" + value + "]"
      );
      await this.setState((prevState) => ({
        editedUser: {
          // object that we want to update
          ...prevState.editedUser, // keep all other key-value pairs
          [field]: value,
        },
      }));
    } catch (error) {
      this.setError(false, "updateBean", error);
    }
  };

  updateState = (key, value) => {
    try {
      this.setState({ [key]: value });
    } catch (error) {
      this.setError(false, "updateState", error);
    }
  };

  validateChanges = async () => {
    try {
      if (this.state.editedUser === null) {
        await this.discardChanges();
      } else {
        if (
          format.isJsonEqual(
            this.state.originalEditedUser,
            this.state.editedUser
          )
        ) {
          await this.discardChanges();
        } else {
          this.setState({ validateState: ValidateState.CONFIRM });
        }
      }
    } catch (error) {
      this.setError(false, "validateChanges", error);
    }
  };

  discardChanges = async () => {
    try {
      this.setState({
        viewState: ViewState.TABLE,
        toggleChangesMade: false,
        crudlState: CrudlStatus.LIST,
        selectedUser: null,
      });

      // TODO use viewType constant from AppConstants
      if (this.props.viewType !== "STANDARD") {
        if (format.isFunction(this.props.updateStateFromProps)) {
          // Toggle any relevant Views for create or update pages
          // this.props.updateStateFromProps("", false);
        }
      }

      await this.setPageData();
      this.menuState(CrudlStatus.LIST);
    } catch (error) {
      this.setError(false, "discardChanges", error);
    }
  };

  checkRequiredFields = () => {
    try {
      let fieldList = [];
      if (format.isNotNull(this.state.editedUser)) {
        fieldList.push(this.state.editedUser.firstName);
        fieldList.push(this.state.editedUser.surname);
        fieldList.push(this.state.editedUser.email);

        if (this.state.crudlState === CrudlStatus.CREATE) {
          fieldList.push(this.state.editedUser.password);
          fieldList.push(this.state.editedUser.confirmPassword);

          if (this.state.editedUser.userAlreadyExists) {
            return true;
          }
          if (
            this.state.editedUser.password !==
            this.state.editedUser.confirmPassword
          ) {
            return true;
          }
        }

        return format.validateValues(fieldList);
      } else {
        return true;
      }
    } catch (error) {
      this.setError(false, "checkRequiredFields", error);
    }
  };

  menuState = (crudlStatus) => {
    try {
      this.setState({
        menuState: pr.getBreadcrumbMenuState(crudlStatus, "User", "#/users"),
      });
    } catch (error) {
      this.setError(false, "menuState", error);
    }
  };

  executeCrudl = async (stayOnPage) => {
    try {
      if (format.isNotNull(this.state.crudlState)) {
        this.props.log.info(
          "Executing " +
            this.state.crudlState.value +
            " Staying[" +
            stayOnPage +
            "]"
        );

        switch (this.state.crudlState) {
          case CrudlStatus.CREATE:
            await this.addUser(stayOnPage);
            break;
          case CrudlStatus.UPDATE:
            await this.updateUser(stayOnPage);
            break;
          case CrudlStatus.DELETE:
            await this.deleteUser();
            break;
          default:
            break;
        }
      }
    } catch (error) {
      this.setError(false, "executeCrudl", error);
    }
  };

  executeSetup = async (requiredCrudlState, data) => {
    try {
      if (format.isNotNull(requiredCrudlState)) {
        this.props.log.info("Setting up " + requiredCrudlState.value);
        switch (requiredCrudlState) {
          case CrudlStatus.CREATE:
            await this.createSetup();
            break;
          case CrudlStatus.UPDATE:
            await this.updateSetup(data);
            break;
          case CrudlStatus.VIEW:
            await this.viewSetup(data);
            break;
          case CrudlStatus.DELETE:
            await this.deleteSetup(data);
            break;
          default:
            break;
        }
      }
    } catch (error) {
      this.setError(false, "executeSetup", error);
    }
  };

  setUpColumns = () => {
    let localCols = [];
    //SPECIFIC COLUMNS FOR TABLE
    localCols.push(pr.getTableColumn("firstName", "First Name"));
    localCols.push(pr.getTableColumn("surname", "Surname"));
    localCols.push(pr.getTableColumn("email", "Email"));

    this.setState({ tableColumns: localCols });
    var colOptions = pr.getColumnOptions(localCols);
    this.setState({ columnOptions: colOptions });
  };

  columnToggle = (event) => {
    this.setState({ tableColumns: event.value });
  };

  createSetup = async () => {
    window.scrollTo(0, 0);

    var data = {};
    data = await this.additionalParseFunctions(data, CrudlStatus.CREATE);

    this.setState({
      viewState: ViewState.CARD,
      crudlState: CrudlStatus.CREATE,
      editedUser: data,
      originalEditedUser: JSON.stringify(data),
    });

    this.menuState(CrudlStatus.CREATE);
  };

  updateSetup = async (data) => {
    window.scrollTo(0, 0);

    data = await this.additionalParseFunctions(data, CrudlStatus.UPDATE);

    this.setState({
      viewState: ViewState.CARD,
      crudlState: CrudlStatus.UPDATE,
      editedUser: data,
      originalEditedUser: JSON.stringify(data),
    });

    this.menuState(CrudlStatus.UPDATE);
  };

  viewSetup = async (data) => {
    window.scrollTo(0, 0);

    data = await this.additionalParseFunctions(data, CrudlStatus.VIEW);

    this.setState({
      viewState: ViewState.CARD,
      crudlState: CrudlStatus.VIEW,
      editedUser: data,
      originalEditedUser: JSON.stringify(data),
    });

    this.menuState(CrudlStatus.VIEW);
  };

  deleteSetup = async (data) => {
    data = await this.additionalParseFunctions(data, CrudlStatus.DELETE);

    this.setState({
      crudlState: CrudlStatus.DELETE,
      editedUser: data,
      originalEditedUser: JSON.stringify(data),
      validateState: ValidateState.DELETE,
    });
    this.menuState(CrudlStatus.DELETE);
  };

  additionalParseFunctions = async (data, crudlState) => {
    switch (crudlState) {
      case CrudlStatus.CREATE:
        let profileType = localStorage.getItem("profileType");
        if (profileType === BusinessType.BUYER) {
          data.roleType = UserRoleType.BuyerUser;
        } else if (profileType === BusinessType.SUPPLIER) {
          data.roleType = UserRoleType.SupplierUser;
        } else if (profileType === BusinessType.BUYERSUPPLIER) {
          data.roleType = UserRoleType.BuysupplyUser;
        }
        if (this.state.viewType === ViewType.COUNCIL) {
          data.roleType = UserRoleType.CouncilAdmin;
        }
        break;
      case CrudlStatus.UPDATE || CrudlStatus.VIEW:
        break;
      case CrudlStatus.DELETE:
        break;
      default:
        break;
    }
    this.props.log.info(data);
    return data;
  };

  addUser = async (stayOnPage) => {
    this.setState({ pageState: PageState.LOADING });
    var stateVariables = this.getStateValuesAsObject();

    if (!format.isNotNull(stayOnPage) || stayOnPage === false) {
      stateVariables.viewState = ViewState.TABLE;
    } else {
      stateVariables.viewState = ViewState.CARD;
    }

    var userResponse = await this.props.viewModel.executeCRUDLAction(
      CrudlStatus.CREATE,
      stateVariables
    );

    if (userResponse.errorCode === 0) {
      configuration.createUserWithFirebase(
        this.state.editedUser.email,
        this.state.editedUser.password
      );
      // this.sendVerificationEmail();
      if (!format.isNotNull(stayOnPage) || stayOnPage === false) {
        await this.updateUserList(
          userResponse,
          CrudlStatus.CREATE,
          stateVariables
        );
      } else {
        await this.updateCurrentUser(
          userResponse,
          CrudlStatus.CREATE,
          stateVariables
        );
      }
    } else {
      this.setState({ pageState: PageState.IDLE });
    }
  };
  updateUser = async (stayOnPage) => {
    this.setState({ pageState: PageState.LOADING });
    var stateVariables = this.getStateValuesAsObject();

    if (!format.isNotNull(stayOnPage) || stayOnPage === false) {
      stateVariables.viewState = ViewState.TABLE;
    } else {
      stateVariables.viewState = ViewState.CARD;
    }

    var userResponse = await this.props.viewModel.executeCRUDLAction(
      CrudlStatus.UPDATE,
      stateVariables
    );

    if (userResponse.errorCode === 0) {
      if (!format.isNotNull(stayOnPage) || stayOnPage === false) {
        await this.updateUserList(
          userResponse,
          CrudlStatus.CREATE,
          stateVariables
        );
      } else {
        await this.updateCurrentUser(
          userResponse,
          CrudlStatus.CREATE,
          stateVariables
        );
      }
    } else {
      this.setState({ pageState: PageState.IDLE });
    }
  };

  deleteUser = async () => {
    this.setState({ pageState: PageState.LOADING });
    var stateVariables = this.getStateValuesAsObject();

    var userResponse = await this.props.viewModel.executeCRUDLAction(
      CrudlStatus.DELETE,
      stateVariables
    );

    if (userResponse.errorCode === 0) {
      await this.updateUserList(
        userResponse,
        CrudlStatus.DELETE,
        stateVariables
      );
    } else {
      this.setState({ pageState: PageState.IDLE });
    }
  };

  updateUserList = async (apiResponse, type, stateVariables) => {
    this.setState({ pageState: PageState.LOADING });

    // TODO use viewType constant from AppConstants
    if (this.props.viewType !== "STANDARD") {
      if (format.isFunction(this.props.updateObjectFromProps)) {
        // any specific setting variables back up stream for props
        // this.props.updateObjectFromProps(
        //   "name",
        //   stateVariables.userBean.name
        // );
      }
    }

    if (this.props.viewType === ViewType.STANDARD) {
      await this.props.viewModel.getUsers();
    } else if (this.props.viewType === ViewType.MY_BUSINESS) {
      await this.props.viewModel.getUsers(this.state.codeTag);
    }

    // toggle any create or update Props views to close or change
    if (this.props.viewType !== "STANDARD") {
      if (format.isFunction(this.props.updateStateFromProps)) {
        // this.props.updateStateFromProps("toggleCreateUser", false); // example
        // this.props.updateStateFromProps("userList", this.state.userList); // example
      }
    }

    this.setState({
      viewState: ViewState.TABLE,
      crudlState: CrudlStatus.LIST,
      validateState: ValidateState.NONE,
      editedUser: null,
    });

    this.menuState(CrudlStatus.LIST);

    this.setState({ pageState: PageState.IDLE });
  };

  updateCurrentUser = async (apiResponse, type, stateVariables) => {
    this.setState({ pageState: PageState.LOADING });

    var userList = [];
    if (this.props.viewType === ViewType.STANDARD) {
      userList = await this.props.viewModel.getUsersReturned();
    } else if (this.props.viewType === ViewType.MY_BUSINESS) {
      userList = await this.props.viewModel.getUsersReturned(
        this.state.codeTag
      );
    }
    var user = {};
    if (format.isNotNull(userList)) {
      userList.forEach((element) => {
        if (element.id === apiResponse.id) {
          user = format.deepCopy(element);
        }
      });
    }
    await this.updateSetup(user);
    this.setState({ userList: userList });
    this.setState({ pageState: PageState.IDLE });
  };

  checkEmailExistsInFirebase = () => {
    firebase
      .auth()
      .fetchSignInMethodsForEmail(this.state.editedUser.email)
      .then((result) => {
        console.log(result);
        this.updateBean("validatedEmail", true);
        console.log(this.state.editedUser.validatedEmail);
        if (!format.isNotNull(result)) {
          console.log("User does not exist");
          this.updateBean("userAlreadyExists", false);

          return <label> User does not exist </label>;
        }

        if (format.isNotNull(result)) {
          console.log("Sigin in methods array is populated");
          this.updateBean("userAlreadyExists", true);

          return <label>User already exists</label>;
        }
      })

      .catch((error) => {
        // TODO handle the error here and return false
        console.log(error);
        if (error.code === "auth/quota-exceeded") {
          this.growl.show({
            severity: "error",
            summary: <br />,
            detail: error.message + " Try again in 1 minute",
            life: 3000,
          });
        }
      });
  };

  checkEmailAddressAlreadyExists = async () => {
    if (
      format.isNotNull(this.state.editedUser.email) &&
      this.state.editedUser.email.includes("@") &&
      this.state.editedUser.email.includes(".")
    ) {
      let userResponse = await this.props.viewModel.userGetByEmail(
        this.state.editedUser.email
      );
      if (format.isNotNull(userResponse)) {
        if (userResponse.errorCode === 0) {
          this.updateBean("userAlreadyExists", false);
          return this.checkEmailExistsInFirebase();
        } else {
          this.updateBean("userAlreadyExists", true);
          return <label>User already exists</label>;
        }
      } else {
        this.updateBean("userAlreadyExists", false);
        return <label>User already exists</label>;
      }
    }
  };

  sendVerificationEmail = () => {
    firebase
      .auth()
      .signInWithEmailAndPassword(
        this.state.editedUser.email,
        this.state.editedUser.password
      );
  };

  render() {
    return (
      <React.Fragment>
        <pr.Messages
          style={{ textAlign: "left" }}
          ref={(el) => (this.growl = el)}
        />

        {/*VERY VERY BAD ERROS ONLY eg PAGE LOAD*/}
        {this.state.pageState === PageState.ERROR && (
          <ErrorScreen
            loading={this.state.pageState === PageState.LOADING}
            refresh={this.setPageData}
          />
        )}

        {this.state.pageState !== PageState.ERROR && (
          <UserView
            //STATE + HELPER VALUES
            viewState={this.state.viewState}
            validateState={this.state.validateState}
            crudlState={this.state.crudlState}
            menuState={this.state.menuState}
            loading={this.state.pageState === PageState.LOADING}
            updateState={this.updateState}
            refresh={this.setPageData}
            feedback={this.props.feedback}
            receivedFeedback={this.state.receivedFeedback}
            //CARD SPECIFIC PROPS
            viewType={this.state.viewType}
            editedObject={this.state.editedUser}
            userTypeOptions={this.state.userTypeOptions}
            updateEdited={this.updateBean}
            validateChanges={this.validateChanges}
            discardChanges={this.discardChanges}
            checkRequiredFields={this.checkRequiredFields}
            crudlExecute={this.executeCrudl}
            sidebarWidth={this.state.sidebarWidth}
            //TABLE SPECIFIC PROPS
            crudlControl={this.executeSetup}
            userList={this.state.userList}
            expandedRows={this.state.expandedRows}
            tableColumns={this.state.tableColumns}
            columnOptions={this.state.columnOptions}
            columnToggle={this.columnToggle}
            tableReference={this.state.tableReference}
            settingsToggle={this.state.settingsToggle}
            // validation
            checkEmailAddressAlreadyExists={this.checkEmailAddressAlreadyExists}
            sendVerificationEmail={this.sendVerificationEmail}
          />
        )}
      </React.Fragment>
    );
  }
}
