import React from "react";
import { RequiredController } from "./RequiredController";
import { RequiredViewModel } from "./RequiredViewModel";
import * as feedback from "../../common/Feedback/Feedback";
import * as log from "../../common/Feedback/Log";

export class RequiredProvider extends React.Component {
  constructor(props) {
    super(props);
    this.viewModel = new RequiredViewModel();
    this.logger = log;
    this.feedback = feedback;

    console.log(props);
  }

  render() {
    return (
      <RequiredController
        viewModel={this.viewModel}
        log={this.logger}
        feedback={this.feedback}
        toggleTopbar={this.props.toggleTopbar}
      />
    );
  }
}
