import React from "react";
//PrimeReact Components
import * as pr from "../../../common/PrimeComponents";
import { FeedbackState } from "../../../common/config/AppConstants";
import * as format from "../../../common/FormatFunctions";
import { LoadScreen } from "../../Common/LoadScreen";

export class PPECard extends React.Component {
  componentDidUpdate = (prevProps) => {
    // Typical usage (don't forget to compare props):
    if (this.props.receivedFeedback !== prevProps.receivedFeedback) {
      switch (this.props.receivedFeedback.state) {
        case FeedbackState.EMPTY:
          this.props.feedback.showEmpty(
            this.growl,
            this.props.receivedFeedback.display
          );
          break;
        case FeedbackState.SUCCESS:
          this.props.feedback.showSuccess(
            this.growl,
            this.props.receivedFeedback.display,
            this.props.receivedFeedback.crudlStatus
          );
          break;
        case FeedbackState.ERROR:
          this.props.feedback.showError(
            this.growl,
            this.props.receivedFeedback.display,
            this.props.receivedFeedback.crudlStatus
          );
          break;
        default:
          break;
      }
    }
  };

  stepOneRequirementsDisabled = () => {
    if (format.isNotNull(this.props.editedObject)) {
      // ADD REQUIREDD FIELDS HERE AS APPROPRIATE
      // return false;
      return format.validateValues([
        this.props.editedObject.businessName,
        this.props.editedObject.businessDescription,
        this.props.editedObject.addressLine1,
        this.props.editedObject.city,
        this.props.editedObject.countyState,
        this.props.editedObject.demographicArea,
        this.props.editedObject.postalZipCode,
        this.props.editedObject.email,
        this.props.editedObject.websiteUrl,
      ]);
    } else {
      return true;
    }
  };
  stepTwoRequirementsDisabled = () => {
    if (format.isNotNull(this.props.ppeBeanList)) {
      // ADD REQUIREDD FIELDS HERE AS APPROPRIATE
      var disabled = true;
      this.props.ppeBeanList.forEach((element) => {
        if (element.checked === true) {
          disabled = false;
        }
      });

      return disabled;
    } else {
      return true;
    }
  };
  stepThreeRequirementsDisabled = () => {
    if (format.isNotNull(this.props.editedObject)) {
      // ADD REQUIREDD FIELDS HERE AS APPROPRIATE
      return format.validateValues([
        this.props.editedObject.userFirstName,
        this.props.editedObject.userEmail,
        this.props.editedObject.userSurname,
        this.props.editedObject.conditionsSigned,
        this.props.editedObject.userPassword,
        this.props.editedObject.userConfirmPassword,
      ]);
    } else {
      return true;
    }
  };

  render() {
    return (
      <div className="ppe-page" id="login-buy">
        <div className="p-grid dashboard bpm">
          {this.props.loading === true && <LoadScreen />}
          {this.props.loading !== true && (
            <React.Fragment>
              <div className="p-col-12">
                <div className="p-grid">
                  <div className="p-col-2"></div>
                  <div className="p-col-8">
                    <pr.Steps
                      model={this.props.steps}
                      activeIndex={this.props.activeStep}
                      onSelect={(e) =>
                        this.props.updateState("activeStep", e.index)
                      }
                      readOnly={false}
                    />
                  </div>
                  <div className="p-col-2"></div>
                </div>
              </div>
              <div className="p-col-12">
                {this.props.activeStep === 0 && (
                  <div className="p-grid dashboard">
                    <div className="p-col-2"></div>
                    <div className="p-col-8">
                      <div className="p-col-12">
                        <h1 style={{ lineHeight: "40px", margin: "0" }}>
                          Please add your business details
                        </h1>
                      </div>
                      <div className="p-col-12">
                        <h3 style={{ fontWeight: "normal", margin: "0" }}>
                          To work safely during the pandemic companies may wish
                          to source additional materials ranging from PPE, to
                          materials to assist with social distancing or other
                          related items. By registering on the portal buyers can
                          register the items they wish to source and suppliers,
                          the relevant items they can supply. Simply register
                          using the following 3 step process.
                        </h3>
                      </div>{" "}
                      <div className="p-col-12"></div>
                      <div className="p-col-12 card">
                        <div className="p-grid">
                          <div className="p-col-12">
                            First of all please tell us how you would like to
                            use the portal. Please tick at least one option
                          </div>
                          <div className="p-col-12">
                            <div className="p-grid">
                              <div className="p-col-6">
                                <label
                                  htmlFor="businessDescription"
                                  className="custom-label"
                                >
                                  I would like to supply products and services
                                </label>
                              </div>
                              <div className="p-col-6">
                                <pr.Checkbox
                                  id="supplier"
                                  rows={5}
                                  checked={
                                    this.props.editedObject.supplier || false
                                  }
                                  onChange={(e) => {
                                    this.props.updateBean(
                                      "supplier",
                                      e.checked
                                    );
                                  }}
                                  style={{ resize: "none" }}
                                />
                              </div>
                            </div>
                            <div className="p-grid">
                              <div className="p-col-6">
                                <label
                                  htmlFor="businessDescription"
                                  className="custom-label"
                                >
                                  I would like to buy products and services
                                </label>
                              </div>
                              <div className="p-col-6">
                                <pr.Checkbox
                                  id="buyer"
                                  rows={5}
                                  checked={
                                    this.props.editedObject.buyer || false
                                  }
                                  onChange={(e) => {
                                    this.props.updateBean("buyer", e.checked);
                                  }}
                                  style={{ resize: "none" }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="p-col-12 card">
                        <div className="p-grid">
                          <div className="p-col-3">
                            <label
                              htmlFor="businessName"
                              className="custom-label"
                            >
                              Business Name *
                            </label>
                          </div>
                          <div className="p-col-9">
                            {" "}
                            <pr.InputText
                              id="businessName"
                              className="required"
                              value={this.props.editedObject.businessName || ""}
                              onChange={(e) => {
                                this.props.updateBean(
                                  e.target.id,
                                  e.target.value
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="p-grid">
                          <div className="p-col-3">
                            <label
                              htmlFor="businessDescription"
                              className="custom-label"
                            >
                              Business Description *
                            </label>
                          </div>
                          <div className="p-col-9">
                            <pr.InputTextarea
                              id="businessDescription"
                              rows={5}
                              value={
                                this.props.editedObject.businessDescription ||
                                ""
                              }
                              onChange={(e) => {
                                this.props.updateBean(
                                  e.target.id,
                                  e.target.value
                                );
                              }}
                              style={{ resize: "none" }}
                            />
                            <label htmlFor="shortDescription">
                              Please tell us about your business in one sentence
                            </label>
                          </div>
                        </div>

                        {this.props.editedObject.buyer && (
                          <div className="p-grid">
                            <div className="p-col-3">
                              <label
                                htmlFor="countyState"
                                className="custom-label"
                              >
                                Sector *
                              </label>
                            </div>
                            <div className="p-col-9">
                              <pr.Dropdown
                                id="sector"
                                className="required"
                                value={this.props.editedObject.sector}
                                options={this.props.sectorOptions}
                                onChange={(e) => {
                                  this.props.updateBean("sector", e.value);
                                }}
                                filter={false}
                                filterBy="label"
                                filterPlaceholder="Search"
                                style={{ marginTop: "1px", width: "100%" }}
                              />
                              <label htmlFor="shortDescription">
                                Please choose one
                              </label>
                            </div>
                          </div>
                        )}
                        {this.props.editedObject.supplier && (
                          <div className="p-grid">
                            <div className="p-col-3">
                              <label
                                htmlFor="countyState"
                                className="custom-label"
                              >
                                Supplier Type? *
                              </label>
                            </div>
                            <div className="p-col-9">
                              <pr.Dropdown
                                id="businessType"
                                className="required"
                                value={this.props.editedObject.subType}
                                options={[
                                  {
                                    label: "Manufacturer",
                                    value: "Manufacturer",
                                  },
                                  {
                                    label: "Distributor",
                                    value: "Distributor",
                                  },
                                  { label: "Other", value: "Other" },
                                ]}
                                onChange={(e) => {
                                  this.props.updateBean("subType", e.value);
                                }}
                                filter={false}
                                filterBy="label"
                                filterPlaceholder="Search"
                                style={{ marginTop: "1px", width: "100%" }}
                              />
                              <label htmlFor="shortDescription">
                                How would you best describe your business?
                              </label>
                            </div>
                          </div>
                        )}
                        <div className="p-grid">
                          <div className="p-col-3">
                            <label
                              htmlFor="addressLine1"
                              className="custom-label"
                            >
                              Address Line 1 *
                            </label>
                          </div>
                          <div className="p-col-9">
                            <pr.InputText
                              id="addressLine1"
                              value={this.props.editedObject.addressLine1 || ""}
                              onChange={(e) => {
                                this.props.updateBean(
                                  e.target.id,
                                  e.target.value
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="p-grid">
                          <div className="p-col-3">
                            <label
                              htmlFor="addressLine2"
                              className="custom-label"
                            >
                              Address Line 2
                            </label>
                          </div>
                          <div className="p-col-9">
                            <pr.InputText
                              id="addressLine2"
                              value={this.props.editedObject.addressLine2 || ""}
                              onChange={(e) => {
                                this.props.updateBean(
                                  e.target.id,
                                  e.target.value
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="p-grid">
                          <div className="p-col-3">
                            <label htmlFor="city" className="custom-label">
                              City *
                            </label>
                          </div>
                          <div className="p-col-9">
                            <pr.InputText
                              id="city"
                              className="required"
                              value={this.props.editedObject.city || ""}
                              onChange={(e) => {
                                this.props.updateBean(
                                  e.target.id,
                                  e.target.value
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="p-grid">
                          <div className="p-col-3">
                            <label
                              htmlFor="countyState"
                              className="custom-label"
                            >
                              County *
                            </label>
                          </div>
                          <div className="p-col-9">
                            <pr.Dropdown
                              id="countyState"
                              className="required"
                              value={this.props.editedObject.countyState}
                              options={this.props.countyOptions}
                              onChange={(e) => {
                                this.props.updateBean("countyState", e.value);
                              }}
                              filter={false}
                              filterBy="label"
                              filterPlaceholder="Search"
                              style={{ marginTop: "1px", width: "100%" }}
                            />
                          </div>
                        </div>
                        <div className="p-grid">
                          <div className="p-col-3">
                            <label
                              htmlFor="demographicArea"
                              className="custom-label"
                            >
                              Demographic Area *
                            </label>
                          </div>
                          <div className="p-col-9">
                            <pr.Dropdown
                              id="demographicArea"
                              className="required"
                              value={this.props.editedObject.demographicArea}
                              options={this.props.demographicAreaOptions}
                              onChange={(e) => {
                                this.props.updateBean(
                                  "demographicArea",
                                  e.value
                                );
                              }}
                              filter={false}
                              filterBy="label"
                              filterPlaceholder="Search"
                              style={{ marginTop: "1px", width: "100%" }}
                            />
                            <label htmlFor="shortDescription">
                              Please select where your business is located
                            </label>
                          </div>
                        </div>
                        <div className="p-grid">
                          <div className="p-col-3">
                            <label
                              htmlFor="postalZipCode"
                              className="custom-label"
                            >
                              Postcode *
                            </label>
                          </div>
                          <div className="p-col-9">
                            <pr.InputText
                              id="postalZipCode"
                              className="required"
                              value={
                                this.props.editedObject.postalZipCode || ""
                              }
                              onChange={(e) => {
                                this.props.updateBean(
                                  e.target.id,
                                  e.target.value
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="p-grid">
                          <div className="p-col-3">
                            <label htmlFor="email" className="custom-label">
                              Business Email *
                            </label>
                          </div>
                          <div className="p-col-9">
                            <pr.InputText
                              id="email"
                              className="required"
                              value={this.props.editedObject.email || ""}
                              onChange={(e) => {
                                this.props.updateBean(
                                  e.target.id,
                                  e.target.value
                                );
                              }}
                            />
                            <label htmlFor="shortDescription">
                              Try not to use personal emails, this information
                              will be made available to users of the portal
                            </label>
                          </div>

                          {this.props.editedObject.email &&
                          (!this.props.editedObject.email.includes("@") ||
                            !this.props.editedObject.email.includes(".")) ? (
                            <label style={{ color: "red" }}>
                              Incorrect email format
                            </label>
                          ) : (
                            <label />
                          )}
                        </div>
                        <div className="p-grid">
                          <div className="p-col-3">
                            <label
                              htmlFor="addressLine1"
                              className="custom-label"
                            >
                              Website Url *
                            </label>
                          </div>
                          <div className="p-col-9">
                            <pr.InputText
                              id="websiteUrl"
                              value={this.props.editedObject.websiteUrl || ""}
                              onChange={(e) => {
                                this.props.updateBean(
                                  e.target.id,
                                  e.target.value
                                );
                              }}
                            />
                            <label htmlFor="shortDescription">
                              Please include https:// or http:// when filling
                              out this field
                            </label>
                          </div>
                        </div>
                        <div className="p-grid">
                          <div className="p-col-3">
                            <label
                              htmlFor="telephoneNumber"
                              className="custom-label"
                            >
                              Telephone Number
                            </label>
                          </div>
                          <div className="p-col-9">
                            <pr.InputText
                              id="telephoneNumber"
                              className="required"
                              value={
                                this.props.editedObject.telephoneNumber || ""
                              }
                              onChange={(e) => {
                                this.props.updateBean(
                                  e.target.id,
                                  e.target.value
                                );
                              }}
                            />
                          </div>
                        </div>

                        <div className="p-grid">
                          <div className="p-col-3">
                            <label
                              htmlFor="discoveredVia"
                              className="custom-label"
                            >
                              Source
                            </label>
                          </div>
                          <div className="p-col-9">
                            <pr.Dropdown
                              id="discoveredVia"
                              className="required"
                              value={this.props.editedObject.discoveredVia}
                              options={this.props.discoveredViaOptions}
                              onChange={(e) => {
                                this.props.updateBean("discoveredVia", e.value);
                              }}
                              filter={false}
                              filterBy="label"
                              filterPlaceholder="Search"
                              style={{ marginTop: "1px", width: "100%" }}
                            />
                            <label htmlFor="shortDescription">
                              Where did you find out about BuySupplyNI? *
                            </label>
                          </div>
                        </div>

                        <div className="p-col-12">
                          {/*Empty div for spacing*/}
                        </div>
                      </div>
                      <div className="p-col-12">
                        <pr.Button
                          label="Next"
                          disabled={this.stepOneRequirementsDisabled()}
                          icon={"pi pi-arrow-right"}
                          onClick={() => {
                            this.props.updateState("activeStep", 1);
                          }}
                          style={
                            this.stepOneRequirementsDisabled()
                              ? { float: "right", opacity: 0.5 }
                              : { float: "right" }
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
                {this.props.activeStep === 1 && (
                  <div className="p-grid dashboard">
                    <div className="p-col-2"></div>
                    <div className="p-col-8">
                      <div className="p-col-12">
                        <h1 style={{ lineHeight: "40px", margin: "0" }}>
                          Please select the products you wish to source
                        </h1>
                      </div>
                      <div className="p-col-12">
                        Tick products required and please select at least 1
                      </div>
                      <div className="p-col-12">
                        <pr.Accordion activeIndex={0}>
                          <pr.AccordionTab header="PPE">
                            <div className="p-col-12">
                              {this.displayPPEOptions(
                                this.props.ppeBeanList,
                                "PPE"
                              )}
                            </div>
                          </pr.AccordionTab>
                        </pr.Accordion>
                        <pr.Accordion activeIndex={0}>
                          <pr.AccordionTab header="Social Distancing Materials">
                            <div className="p-col-12">
                              {this.displayPPEOptions(
                                this.props.ppeBeanList,
                                "Social Distancing Materials"
                              )}
                            </div>
                          </pr.AccordionTab>
                        </pr.Accordion>
                        <pr.Accordion activeIndex={0}>
                          <pr.AccordionTab header="Other Covid-19 Related Products">
                            <div className="p-col-12">
                              {this.displayPPEOptions(
                                this.props.ppeBeanList,
                                "Other Covid-19 Related Products"
                              )}
                            </div>
                          </pr.AccordionTab>
                        </pr.Accordion>
                      </div>
                      <pr.Button
                        className="secondary-btn"
                        label="Next"
                        disabled={this.stepTwoRequirementsDisabled()}
                        icon="pi pi-arrow-right"
                        onClick={() => {
                          this.props.updateState("activeStep", 2);
                        }}
                        style={
                          this.stepTwoRequirementsDisabled()
                            ? { float: "right", opacity: 0.5 }
                            : { float: "right" }
                        }
                      />
                      <pr.Button
                        className="secondary-btn"
                        label="Previous"
                        icon="pi pi-arrow-left"
                        onClick={() => {
                          this.props.updateState("activeStep", 0);
                        }}
                        style={{ float: "left" }}
                      />
                    </div>
                  </div>
                )}
                {this.props.activeStep === 2 && (
                  <div className="p-grid dashboard">
                    <div className="p-col-2"></div>
                    <div className="p-col-8">
                      <div className="p-col-12">
                        <h1 style={{ lineHeight: "40px", margin: "0" }}>
                          Please add your details
                        </h1>
                      </div>
                      <div className="p-col-12">
                        These will be the details used to log into your account.
                        After registering please login and verify your email
                      </div>
                      <div className="p-col-12 card">
                        <div className="p-grid">
                          <div className="p-col-3">
                            <label
                              htmlFor="userFirstName"
                              className="custom-label"
                            >
                              First Name *
                            </label>
                          </div>
                          <div className="p-col-9">
                            <pr.InputText
                              id="userFirstName"
                              className="required"
                              value={
                                this.props.editedObject.userFirstName || ""
                              }
                              onChange={(e) => {
                                this.props.updateBean(
                                  e.target.id,
                                  e.target.value
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="p-grid">
                          <div className="p-col-3">
                            <label
                              htmlFor="userSurname"
                              className="custom-label"
                            >
                              Surname *
                            </label>
                          </div>
                          <div className="p-col-9">
                            <pr.InputText
                              id="userSurname"
                              className="required"
                              value={this.props.editedObject.userSurname || ""}
                              onChange={(e) => {
                                this.props.updateBean(
                                  e.target.id,
                                  e.target.value
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="p-grid">
                          <div className="p-col-3">
                            <label htmlFor="userEmail" className="custom-label">
                              Email *
                            </label>
                          </div>
                          <div className="p-col-9">
                            <pr.InputText
                              id="userEmail"
                              className="required"
                              value={this.props.editedObject.userEmail || ""}
                              onChange={(e) => {
                                this.props.updateBean(
                                  e.target.id,
                                  e.target.value
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="p-grid">
                          <div className="p-col-3">
                            <label
                              htmlFor="userContactTelephone"
                              className="custom-label"
                            >
                              Telephone
                            </label>
                          </div>
                          <div className="p-col-9">
                            <pr.InputText
                              id="userContactTelephone"
                              value={
                                this.props.editedObject.userContactTelephone ||
                                ""
                              }
                              onChange={(e) => {
                                this.props.updateBean(
                                  e.target.id,
                                  e.target.value
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="p-grid">
                          <div className="p-col-3">
                            <label
                              htmlFor="userPassword"
                              className="custom-label"
                            >
                              Password *
                            </label>
                          </div>

                          <div className="p-col-7">
                            {this.props.editedObject.showPassword ? (
                              <pr.InputText
                                id="userPassword"
                                // className="required"
                                value={
                                  this.props.editedObject.userPassword || ""
                                }
                                onChange={(e) => {
                                  console.log(e);
                                  this.props.updateBean(
                                    e.target.id,
                                    e.target.value
                                  );
                                }}
                              />
                            ) : (
                              <pr.Password
                                id="userPassword"
                                // className="required"
                                feedback={false}
                                promptLabel="Strength"
                                value={
                                  this.props.editedObject.userPassword || ""
                                }
                                onChange={(e) => {
                                  this.props.updateBean(
                                    e.target.parentElement.id,
                                    e.target.value
                                  );
                                }}
                              />
                            )}
                          </div>

                          <div className="p-col-2">
                            <pr.Button
                              icon={
                                this.props.editedObject.showPassword
                                  ? "pi pi-eye-slash"
                                  : "pi pi-eye"
                              }
                              onClick={() => {
                                this.props.updateBean(
                                  "showPassword",
                                  !this.props.editedObject.showPassword
                                );
                              }}
                              style={{ margin: "0" }}
                            />
                          </div>
                        </div>
                        <div className="p-col-12">
                          <div className="p-col-3">
                            <label
                              htmlFor="userPassword"
                              className="input-label"
                            >
                              Confirm Password *
                            </label>
                          </div>
                          <div className="p-col-7">
                            {this.props.editedObject.showConfirmPassword ? (
                              <pr.InputText
                                id="userConfirmPassword"
                                className="required"
                                value={
                                  this.props.editedObject.userConfirmPassword ||
                                  ""
                                }
                                onChange={(e) => {
                                  this.props.updateBean(
                                    e.target.id,
                                    e.target.value
                                  );
                                }}
                              />
                            ) : (
                              <pr.Password
                                id="userConfirmPassword"
                                className="required"
                                feedback={false}
                                promptLabel="Strength"
                                value={
                                  this.props.editedObject.userConfirmPassword ||
                                  ""
                                }
                                onChange={(e) => {
                                  this.props.updateBean(
                                    e.target.parentElement.id,
                                    e.target.value
                                  );
                                }}
                              />
                            )}
                          </div>

                          <div className="p-col-2">
                            <pr.Button
                              icon={
                                this.props.editedObject.showConfirmPassword
                                  ? "pi pi-eye-slash"
                                  : "pi pi-eye"
                              }
                              onClick={() => {
                                this.props.updateBean(
                                  "showConfirmPassword",
                                  !this.props.editedObject.showConfirmPassword
                                );
                              }}
                              style={{ margin: "0" }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="p-col-12">
                        {this.props.editedObject.supplier === true && (
                          <div className="p-col-12 card">
                            <div className="p-col-12">
                              <label
                                htmlFor="businessDescription"
                                className="custom-label"
                              >
                                Please confirm that the PPE products you can
                                supply are certified to meet UK requirements
                                where applicable.
                              </label>
                            </div>
                            <div className="p-col-12">
                              <div className="p-col-6">
                                <label
                                  htmlFor="businessDescription"
                                  className="custom-label"
                                >
                                  Yes , My products meet UK requirements
                                </label>
                              </div>
                              <div className="p-col-6">
                                <pr.Checkbox
                                  id="supplier"
                                  rows={5}
                                  checked={
                                    this.props.editedObject.certifiedProducts ||
                                    false
                                  }
                                  onChange={(e) => {
                                    this.props.updateBean(
                                      "certifiedProducts",
                                      e.checked
                                    );
                                  }}
                                  style={{ resize: "none" }}
                                />
                              </div>
                            </div>
                            {!this.props.editedObject.certifiedProducts && (
                              <div className="p-col-12">
                                <div className="p-col-12">
                                  <label
                                    htmlFor="businessName"
                                    className="input-label"
                                  >
                                    If not , can your provide more details
                                  </label>
                                </div>
                                <div className="p-col-12">
                                  {" "}
                                  <pr.InputText
                                    id="certifiedProductsDescription"
                                    className="required"
                                    value={
                                      this.props.editedObject
                                        .certifiedProductsDescription || ""
                                    }
                                    onChange={(e) => {
                                      this.props.updateBean(
                                        e.target.id,
                                        e.target.value
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                        <div className="p-col-12 card">
                          {/*Empty div for spacing*/}

                          <div className="p-col-10">
                            Mid and East Antrim Borough Council will collect and
                            process your personal information as a legitimate
                            interest as defined by the Data Protection Act 2018’
                            We will keep your information secure, accurate and
                            for no longer than is necessary in accordance with
                            data protection laws. If you wish to find out more
                            about how we control and process personal data and
                            protect your privacy please visit the following:{" "}
                            <label
                              className="input-label"
                              onClick={() => {
                                window.open(
                                  "https://www.midandeastantrim.gov.uk/privacy-notice"
                                );
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              https://www.midandeastantrim.gov.uk/privacy-notice
                            </label>
                            <br></br>
                            <label className="custom-label">
                              I understand the terms and conditions{" "}
                            </label>
                            <pr.Checkbox
                              checked={
                                this.props.editedObject.conditionsSigned ||
                                false
                              }
                              onChange={(e) => {
                                this.props.updateBean(
                                  "conditionsSigned",
                                  e.checked
                                );
                              }}
                            />
                          </div>
                          <div className="p-col-2">
                            <img
                              src="/assets/layout/images/council/niDirect.png"
                              alt=""
                              style={{
                                width: "100%",
                                height: "100%",
                                zIndex: "1",
                              }}
                            />
                          </div>

                          <div className="p-col-10"></div>
                          <div className="p-col-2"></div>
                        </div>
                        <div className="p-col-12">
                          {/*Empty div for spacing*/}
                        </div>
                        <div className="p-col-12">
                          <pr.Button
                            className="secondary-btn"
                            disabled={this.stepThreeRequirementsDisabled()}
                            label="Register"
                            icon="pi pi-check"
                            onClick={(e) => {
                              this.props.runRegister();
                            }}
                            style={
                              this.stepThreeRequirementsDisabled()
                                ? { float: "right", opacity: 0.5 }
                                : { float: "right" }
                            }
                          />
                          <pr.Button
                            className="secondary-btn"
                            label="Previous"
                            icon="pi pi-arrow-left"
                            onClick={() => {
                              this.props.updateState("activeStep", 1);
                            }}
                            style={{ float: "left" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {this.props.activeStep === 3 && (
                  <React.Fragment>
                    <div className="p-col-2"></div>
                    <div className="p-col-8">
                      <div
                        className="p-col-12 card"
                        style={
                          this.props.addProductView ? {} : { display: "none" }
                        }
                      >
                        <div className="p-col-12">
                          <div className="p-col-3">
                            <label
                              htmlFor="businessName"
                              className="input-label"
                            >
                              Name *
                            </label>
                          </div>
                          <div className="p-col-9">
                            {" "}
                            <pr.InputText
                              id="name"
                              className="required"
                              value={this.props.productBean.name || ""}
                              onChange={(e) => {
                                this.props.updateProductBean(
                                  e.target.id,
                                  e.target.value
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="p-col-12">
                          <div className="p-col-3">
                            <label
                              htmlFor="businessName"
                              className="input-label"
                            >
                              Description*
                            </label>
                          </div>
                          <div className="p-col-9">
                            {" "}
                            <pr.InputText
                              id="description"
                              className="required"
                              value={this.props.productBean.description || ""}
                              onChange={(e) => {
                                this.props.updateProductBean(
                                  e.target.id,
                                  e.target.value
                                );
                              }}
                            />
                          </div>
                        </div>

                        <div className="p-col-12">
                          <div className="p-col-3">
                            <label
                              htmlFor="businessName"
                              className="input-label"
                            >
                              Component*
                            </label>
                          </div>
                          <div className="p-col-9">
                            <pr.MultiSelect
                              value={this.props.productBean.componentList}
                              options={this.filterOptions(
                                this.props.ppeBeanList
                              )}
                              optionLabel="name"
                              optionValue="id"
                              onChange={(e) =>
                                this.props.updateProductBean(
                                  "componentList",
                                  e.value
                                )
                              }
                            />{" "}
                          </div>
                        </div>
                        {this.props.editedObject.buyer &&
                          this.props.editedObject.supplier === false && (
                            <div className="p-col-12">
                              <div className="p-col-3">
                                <label
                                  htmlFor="businessName"
                                  className="input-label"
                                >
                                  What Type of supplier are you?
                                </label>
                              </div>
                              <div className="p-col-9">PPE Buyer</div>
                            </div>
                          )}
                        {this.props.editedObject.supplier &&
                          this.props.editedObject.buyer === false && (
                            <div className="p-col-12">
                              <div className="p-col-3">
                                <label
                                  htmlFor="businessName"
                                  className="input-label"
                                >
                                  Type
                                </label>
                              </div>
                              <div className="p-col-9">PPE Supplier</div>
                            </div>
                          )}
                        {this.props.editedObject.buyer &&
                          this.props.editedObject.supplier && (
                            <div className="p-col-12">
                              <div className="p-col-3">
                                <label
                                  htmlFor="businessName"
                                  className="input-label"
                                >
                                  Type
                                </label>
                              </div>
                              <div className="p-col-9">
                                <pr.Dropdown
                                  id="sector"
                                  className="required"
                                  value={this.props.productBean.type}
                                  options={[
                                    { label: "Buyer", value: "PPE BUYER" },
                                    {
                                      label: "Supplier",
                                      value: "PPE SUPPLIER",
                                    },
                                  ]}
                                  onChange={(e) => {
                                    this.props.updateProductBean(
                                      "type",
                                      e.value
                                    );
                                  }}
                                  filter={false}
                                  filterBy="label"
                                  filterPlaceholder="Search"
                                  style={{ marginTop: "1px", width: "100%" }}
                                />
                              </div>
                            </div>
                          )}
                        <div className="p-col-12">
                          <div className="p-col-3">
                            <label
                              htmlFor="businessName"
                              className="input-label"
                            >
                              Quantity*
                            </label>
                          </div>
                          <div className="p-col-9">
                            {" "}
                            <pr.InputText
                              id="quantity"
                              className="required"
                              value={this.props.productBean.quantity || ""}
                              onChange={(e) => {
                                this.props.updateProductBean(
                                  e.target.id,
                                  e.target.value
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="p-col-12">
                          <pr.Button
                            label={"Add"}
                            icon="pi pi-plus"
                            style={{
                              float: "right",
                            }}
                            onClick={() => {
                              this.props.addProductBean();
                            }}
                          />
                        </div>
                      </div>

                      <div className="p-col-12">
                        <pr.DataTable
                          ref={(el) => {
                            this.dt = el;
                          }}
                          value={this.props.editedObject.productList}
                          selectionMode="single"
                          onSelectionChange={(e) => {
                            // this.props.crudlControl(CrudlStatus.UPDATE, e.data);
                          }}
                          header={
                            <div className="p-grid">
                              <div className="p-col-12">
                                <pr.Button
                                  label={"Add New"}
                                  icon="pi pi-plus"
                                  style={{
                                    float: "right",
                                  }}
                                  onClick={() => {
                                    this.props.updateState(
                                      "addProductView",
                                      true
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          }
                          emptyMessage="No Products"
                          rows={Infinity}
                          autoLayout={true}
                          paginator={false}
                          alwaysShowPaginator={false}
                          responsive={true}
                          loading={this.props.loading}
                        >
                          <pr.Column field={"name"} header="Name" />
                          <pr.Column
                            field={"description"}
                            header="Description"
                          />

                          <pr.Column field={"type"} header="Type" />
                          <pr.Column field={"quantity"} header="Quantity" />
                        </pr.DataTable>
                      </div>
                      <div className="p-col-12">
                        <pr.Button
                          className="secondary-btn"
                          label="Register"
                          icon="pi pi-check"
                          onClick={() => {}}
                          style={{ float: "right" }}
                        />
                        <pr.Button
                          className="secondary-btn"
                          label="Previous"
                          icon="pi pi-arrow-left"
                          onClick={() => {
                            this.props.updateState("activeStep", 2);
                          }}
                          style={{ float: "left" }}
                        />
                      </div>
                    </div>
                    <div className="p-col-2"></div>
                  </React.Fragment>
                )}
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }

  displayPPEOptions = (ppeBeanList, heading) => {
    var componentArray = [];
    var index = 0;

    if (format.isNotNull(ppeBeanList)) {
      ppeBeanList.forEach((element) => {
        if (element.header === heading) {
          componentArray[index] = (
            <div className="p-grid">
              <div className="p-col-4">
                <label htmlFor={element.name} className="custom-label">
                  {element.name}
                </label>
              </div>
              <div className="p-col-4">
                <pr.Checkbox
                  id={element.name}
                  checked={element.checked}
                  onChange={(e) => {
                    this.props.updatePPEBean(element.id, "checked", e.checked);
                  }}
                ></pr.Checkbox>
              </div>
              <div className="p-col-4"></div>
            </div>
          );
          index++;
        }
      });
    }

    return componentArray;
  };

  filterOptions = (list) => {
    var returnList = [];
    if (format.isNotNull(list)) {
      list.forEach((element) => {
        if (element.checked) {
          returnList.push(element);
        }
      });
    }

    return returnList;
  };
}
