import * as Constant from "../config/Configuration";
import firebase from "firebase";

// https://firebase.google.com/docs/analytics/events
// https://firebase.google.com/docs/analytics/user-properties
// Events that come as standard for google analytics
// https://developers.google.com/gtagjs/reference/event

const analytics = Constant.getFirebaseAnalytics();

export const PageView = {
  BUYER_PROFILE: "BUYER_PROFILE",
  SUPPLIER_PROFILE: "SUPPLIER_PROFILE",
  ENQUIRIES: "ENQUIRIES",
  MY_BUSINESS: "MY_BUSINESS",
  BUYER_DASHBOARD: "BUYER_DASHBOARD",
  SUPPLIER_DASHBOARD: "SUPPLIER_DASHBOARD",
  BUYERSUPPLIER_DASHBOARD: "BUYERSUPPLIER_DASHBOARD",
  PRODUCT_CONTACT: "PRODUCT_CONTACT",
  VIEW_BUYER_PRODUCT_DETAILS: "VIEW_BUYER_PRODUCT_DETAILS",
  VIEW_SUPPLIER_PRODUCT_DETAILS: "VIEW_SUPPLIER_PRODUCT_DETAILS",
  LOGIN: "LOGIN",
  REGISTER: "REGISTER",
};

export const Actions = {
  BUYER_PROFILE_CONTACT: "BUYER_PROFILE_CONTACT",
  SUPPLIER_PROFILE_CONTACT: "SUPPLIER_PROFILE_CONTACT",
  CONTACT_MULTIPLE: "CONTACT_MULTIPLE",
  BUYER_PRODUCT_CONTACT: "BUYER_PRODUCT_CONTACT",
  SUPPLIER_PRODUCT_CONTACT: "SUPPLIER_PRODUCT_CONTACT",
  PROVIDE_FEEDBACK: "PROVIDE_FEEDBACK",
  REQUEST_FEEDBACK: "REQUEST_FEEDBACK",
  CREATE_PRODUCT_BUYER: "CREATE_PRODUCT_BUYER",
  UPDATE_PRODUCT_BUYER: "UPDATE_PRODUCT_BUYER",
  DELETE_PRODUCT_BUYER: "DELETE_PRODUCT_BUYER",
  CREATE_PRODUCT_SUPPLIER: "CREATE_PRODUCT_SUPPLIER",
  UPDATE_PRODUCT_SUPPLIER: "UPDATE_PRODUCT_SUPPLIER",
  DELETE_PRODUCT_SUPPLIER: "DELETE_PRODUCT_SUPPLIER",
  PROVIDED_GENERAL_INFORMATION: "PROVIDED_GENERAL_INFORMATION",
  // DASHBOARD_SEARCH_COULDNT_FIND_PRODUCT:
  //   "DASHBOARD_SEARCH_COULDNT_FIND_PRODUCT",
  SWITCH_TO_PPE: "SWITCH_TO_PPE",
  SWITCH_TO_BUYSUPPLY: "SWITCH_TO_BUYSUPPLY",
};

export const General = {
  REGISTER_TYPE: "REGISTER_TYPE",
  PRODUCT_DEMOGRAPHIC: "PRODUCT_DEMOGRAPHIC",
  PRODUCT_CATEGORY: "PRODUCT_CATEGORY",
  PRODUCT_CATEGORY_CHANGED: "PRODUCT_CATEGORY_CHANGED",
  BUSINESS_DEMOGRAPHIC: "BUSINESS_DEMOGRAPHIC",
  BUSINESS_DEMOGRAPHIC_CHANGED: "BUSINESS_DEMOGRAPHIC_CHANGED",
};

export function logEvent(eventName) {
  analytics.logEvent(eventName);
}

export function logEventCustom(eventName, object) {
  let obj = object;
  obj.acting_business = localStorage.getItem("loggedInBusinessName");
  analytics.logEvent(eventName, object);
}

/** Used for Potential contacts
 * Found in toggleApplicationCreate method
 * - ProfileController
 * - ProductController
 */
export function logApplicationProductEvent(eventName, application) {
  analytics.logEvent(eventName, {
    viewed_application_business_name: application.businessName,
    viewed_application_product_name: application.name,
    product_name: application.name,
    product_category: application.componentNameToDisplay,
    acting_business: localStorage.getItem("loggedInBusinessName"),
    demographic_product_enquiry: localStorage.getItem(
      "loggedInDemographicArea"
    ),
  });
}

/** Used for Potential contacts
 * Found in toggleApplicationCreate method
 * - ProfileController
 */
export function logApplicationBusinessEvent(eventName, application) {
  let obj = application.companyDetails;
  analytics.logEvent(eventName, {
    viewed_application_business_name: obj.businessName,
    acting_business: localStorage.getItem("loggedInBusinessName"),
  });
}

/**
 * Providing Feedback in the Enquries (ApplicationController) section
 */
export function applicationFeedbackEvent(eventName, application) {
  analytics.logEvent(eventName, {
    request_feedback_from: application.businessNameBuyerId,
    product_name: application.productName,
    acting_business: localStorage.getItem("loggedInBusinessName"),
    active_business_demographic: localStorage.getItem(
      "loggedInDemographicArea"
    ),
  });
}

/** The only method that is called when a Contact is made. All others are for potential views
 * Found in AddApplication method
 * - ApplicationController
 */
export function applicationContactMultipleEvent(eventName, application) {
  analytics.logEvent(eventName, {
    submitted_application_product_name: application.productName,
    received_application_business_name: application.businessName,
    submitted_application_business_name: localStorage.getItem(
      "loggedInBusinessName"
    ),
    active_business_demographic: localStorage.getItem(
      "loggedInDemographicArea"
    ),
    demographic_product_enquiry: localStorage.getItem(
      "loggedInDemographicArea"
    ),
  });
}

/**
 * Not used yet
 */
export function logProfileEvent(eventName, profile) {
  analytics.logEvent(eventName, {
    viewed_business_name: profile.businessName,
    acting_business: localStorage.getItem("loggedInBusinessName"),
    active_business_demographic: localStorage.getItem(
      "loggedInDemographicArea"
    ),
  });
}
/**
 * If a company fills out the Form in Business Settings section
 */
export function logProfileItemEvent(eventName, profile) {
  analytics.logEvent(eventName, {
    acting_business: profile.businessName,
    active_business_demographic: localStorage.getItem(
      "loggedInDemographicArea"
    ),
  });
}

export function pageViewEvent(path, title) {
  analytics.logEvent(firebase.analytics.EventName.PAGE_VIEW, {
    page_location: "https://buysupply-development/" + path,
    page_path: "/" + path,
    page_title: title,
    acting_business: localStorage.getItem("loggedInBusinessName"),
  });
}

export function screenViewEvent(screenName) {
  analytics.logEvent(firebase.analytics.EventName.SCREEN_VIEW, {
    screen_name: screenName,
    acting_business: localStorage.getItem("loggedInBusinessName"),
  });
}

// globalFilter on $Table.js files
export function searchEvent(searchEvent) {
  analytics.logEvent(firebase.analytics.EventName.SEARCH, {
    search_term: searchEvent,
    acting_business: localStorage.getItem("loggedInBusinessName"),
    active_business_demographic: localStorage.getItem(
      "loggedInDemographicArea"
    ),
  });
}

// https://developers.google.com/gtagjs/reference/event#select_content
// View Details Button of Product or Business

/**
 Items Object: 
 id *
 name *
 brand
 category
 coupon
 list_name
 list_position
 price
 quantity
 variant
 */
export function selectBusinessEvent(contentType, object) {
  analytics.logEvent("select_business", {
    content_type: contentType,
    viewed_business_name: object.businessName,
    acting_business: localStorage.getItem("loggedInBusinessName"),
  });
}
export function selectProductEvent(contentType, object) {
  analytics.logEvent("select_product", {
    content_type: contentType,
    product_name: object.name,
    viewed_business_name: object.businessName,
    product_category: object.componentNameToDisplay,
    acting_business: localStorage.getItem("loggedInBusinessName"),
  });
}
// Datatable expansion Views (only used for Profile)
export function viewItemEvent(object) {
  analytics.logEvent(firebase.analytics.EventName.VIEW_ITEM, {
    viewed_business_name: object.businessName,
    acting_business: localStorage.getItem("loggedInBusinessName"),
  });
}

export function loginEvent(method) {
  analytics.logEvent(firebase.analytics.EventName.LOGIN, {
    method: method,
    business_name: localStorage.getItem("loggedInBusinessName"),
    users_name: localStorage.getItem("loggedInUsersName"),
  });
}
export function loginCustomDimension(method) {
  analytics.logEvent("Logged In Business", {
    method: method,
    business_name: localStorage.getItem("loggedInBusinessName"),
    businessName: localStorage.getItem("loggedInBusinessName"),
    users_name: localStorage.getItem("loggedInUsersName"),
  });

  analytics.logEvent("Most_Active_Business", {
    acting_business: localStorage.getItem("loggedInBusinessName"),
  });

  analytics.logEvent("Most_Active_Category", {
    product_category: "Test_Category",
  });
}

// https://developers.google.com/gtagjs/reference/event#sign_up
export function signUpEvent(request) {
  analytics.logEvent(firebase.analytics.EventName.SIGN_UP, {
    method: request.type,
    registered_business_type: request.type,
    registered_demographic_area: request.demographicArea,
  });
}
