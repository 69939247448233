import * as Constant from "../config/Configuration";
import * as format from "../FormatFunctions";

const firebaseAuth = Constant.getFirebaseAuth();
const ref = Constant.getFirebaseDatabase();
export function auth(email, pw) {
  return firebaseAuth().createUserWithEmailAndPassword(email, pw);
}
export const DisplayState = {
  PENDING: "PENDING",
  ACTIVEUSER: "ACTIVEUSER",
  NOUSER: "NOUSER",
};

export function logout() {
  return firebaseAuth().signOut();
}

export function login(email, pw) {
  return firebaseAuth().signInWithEmailAndPassword(email, pw);
}

export function resetPassword(email) {
  return firebaseAuth().sendPasswordResetEmail(email);
}
export async function authenticationCheck(dataRender) {
  console.log(dataRender);
  if (format.isFunction(dataRender)) {
    firebaseAuth().onAuthStateChanged(async function (user) {
      if (user) {
        await dataRender(DisplayState.ACTIVEUSER);
      } else {
        return dataRender(DisplayState.NOUSER);
      }
    });

    //unsubscribe();
  }
}

export async function checkRefresh(dataRender) {
  firebaseAuth().onAuthStateChanged(function (user) {
    if (user) {
      dataRender();
    } else {
      return "";
    }
  });
}
export function checkIdToken(booleanValue) {
  return firebaseAuth().currentUser.getIdToken(booleanValue);
}

export function saveUser(user) {
  return ref
    .child(`users/${user.uid}/info`)
    .set({
      email: user.email,
      uid: user.uid,
    })
    .then(() => user);
}
