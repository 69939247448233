import { BaseViewModel } from "../../common/BaseViewModel";
import * as Format from "../../common/FormatFunctions";

import * as ApiService from "../../common/CloudService/ApiService";
import {
  CrudlStatus,
  ViewState,
  ComponentType,
} from "../../common/config/AppConstants";
import * as UIMap from "../../common/PrimeComponents";
import { defaultedGlobalBid } from "../../common/config/AppConstants";
export class RegisterViewModel extends BaseViewModel {
  getComponents = async () => {
    let request = {};
    request.codeTag = defaultedGlobalBid;
    request.type = ComponentType.MANUFACTURE;
    var companyResponse = await this.componentGetByCodeTagUnsecure(request);
    if (
      Format.isNotNull(companyResponse.errorCode) &&
      companyResponse.errorCode === 0
    ) {
      if (Format.isNotNull(companyResponse.componentList)) {
        this.updateState("componentList", companyResponse.componentList);
        this.logger.info(companyResponse.componentList);
      } else {
        this.showEmpty("Component", ViewState.TABLE);
      }
    } else {
      this.showError("Component", CrudlStatus.LIST, ViewState.TABLE);
    }
  };

  getBusinessIndustries = async () => {
    var businessIndustryResponse =
      await this.getBusinessIndustryListByCodeTagUnsecure();
    if (
      Format.isNotNull(businessIndustryResponse.errorCode) &&
      businessIndustryResponse.errorCode === 0
    ) {
      if (Format.isNotNull(businessIndustryResponse.businessIndustryList)) {
        this.updateState(
          "businessIndustryList",
          businessIndustryResponse.businessIndustryList
        );
        let array = UIMap.getDropdownOptionsExtension(
          businessIndustryResponse.businessIndustryList,
          "name",
          "id"
        );
        this.updateState("businessTypeOptions", array);
        this.logger.info(businessIndustryResponse.businessIndustryList);
      } else {
        this.showEmpty("Business Industries", ViewState.TABLE);
      }
    } else {
      this.showError("Business Industries", CrudlStatus.LIST, ViewState.TABLE);
    }
  };

  createBusiness = async (businessRequest) => {
    let businessResponse = await ApiService.businessCreate(businessRequest);
    if (businessResponse.errorCode === 0) {
      this.updateState("businessId", businessResponse.id);
      this.showSuccess("Business", CrudlStatus.CREATE, ViewState.CARD);
    } else {
      this.showError("Business", CrudlStatus.CREATE, ViewState.CARD);
    }

    return businessResponse;
  };

  businessListByName = async (businessName) => {
    let businessRequest = {};
    businessRequest.businessName = businessName;
    let businessResponse = await ApiService.businessListByName(businessRequest);
    return businessResponse;
  };

  createMatrixItem = async (matrixItemRequest) => {
    let matrixItemResponse = await ApiService.matrixItemCreate(
      matrixItemRequest
    );
    if (matrixItemResponse.errorCode === 0) {
      this.showSuccess("MatrixItem", CrudlStatus.CREATE, ViewState.CARD);
    } else {
      this.showError("MatrixItem", CrudlStatus.CREATE, ViewState.CARD);
    }

    return matrixItemResponse;
  };

  createUser = async (userRequest) => {
    let userResponse = await ApiService.userCreate(userRequest);
    if (userResponse.errorCode === 0) {
      this.updateState("userId", userResponse.id);
      this.showSuccess("User", CrudlStatus.CREATE, ViewState.CARD);
    } else {
      this.showError("User", CrudlStatus.CREATE, ViewState.CARD);
    }

    return userResponse;
  };

  createProduct = async (productRequest) => {
    let productResponse = await ApiService.productCreate(productRequest);
    if (productResponse.errorCode === 0) {
      this.showSuccess("Product", CrudlStatus.CREATE, ViewState.CARD);
    } else {
      this.showError("Product", CrudlStatus.CREATE, ViewState.CARD);
    }

    return productResponse;
  };

  createProfile = async (profileRequest) => {
    let profileResponse = await ApiService.profileCreate(profileRequest);
    if (profileResponse.errorCode === 0) {
      this.updateState("profileId", profileResponse.id); // would only be used to create profileItems
      this.showSuccess("Profile", CrudlStatus.CREATE, ViewState.CARD);
    } else {
      this.showError("Profile", CrudlStatus.CREATE, ViewState.CARD);
    }

    return profileResponse;
  };

  userGetByEmail = async (email) => {
    let userRequest = {};
    userRequest.email = email;
    let userResponse = await ApiService.userGetByEmail(userRequest);
    return userResponse;
  };
}
