import React from "react";
import { PPEProfileView } from "./PPEProfileView";
import {
  CrudlStatus,
  ToggleState,
  PageState,
  FeedbackState,
} from "../../common/config/AppConstants";
import { ViewState } from "../../common/config/AppConstants";
import { ValidateState } from "../../common/config/AppConstants";
import * as pr from "../../common/PrimeComponents";
import * as format from "../../common/FormatFunctions";
import { ErrorScreen } from "../Common/ErrorScreen";

export class PPEProfileController extends React.Component {
  state = {
    pageName: "PPEProfileController",
    pageState: PageState.IDLE,
    editLoading: true,

    //DISPLAY STATES
    viewState: ViewState.TABLE,
    validateState: ValidateState.NONE,
    crudlState: CrudlStatus.LIST,
    menuState: [],

    // DATA TABLE
    expandedRows: null,
    tableColumns: [],
    columnOptions: [],
    rowCount: 10,
    settingsToggle: ToggleState.OFF,

    // DETAILS
    ppeprofileList: null,
    originalEditedPPEProfile: "",
    editedPPEProfile: null,
    receivedFeedback: {
      state: FeedbackState.NONE,
      display: "PPEProfile",
      crudlStatus: CrudlStatus.LIST,
    },
    businessBean: {},
    profileBean: {},
    componentList: [],
    activeStep: 0,
    countyOptions: [
      { label: "Antrim", value: "Antrim" },
      { label: "Armagh", value: "Armagh" },
      { label: "Derry/Londonderry", value: "Derry/Londonderry" },
      { label: "Down", value: "Down" },
      { label: "Fermanagh", value: "Fermanagh" },
      { label: "Tyrone", value: "Tyrone" },
    ],

    discoveredViaOptions: [
      { label: "Direct Mail", value: "Direct Mail" },
      { label: "Email", value: "Email" },
      { label: "Advertising", value: "Advertising" },
      { label: "Event", value: "Event" },
      { label: "Social Media", value: "Social Media" },
      { label: "Word of Mouth", value: "Word of Mouth" },
      { label: "Council Staff", value: "Council Staff" },
    ],
    steps: [
      {
        label: "Buisness Details",
      },
      {
        label: "Product Information",
      },
      {
        label: "User Information",
      },
    ],
  };

  componentDidMount = async () => {
    await this.initialDataRender();
  };

  componentDidCatch = (error, info) => {
    console.log("This went in here");

    //COMPONENT ISSUE CHECK IF THIS ERROR HANDLING IS CORRECT
    this.setError(false, "componentDidCatch", error);
  };

  initialDataRender = async () => {
    this.setViewModel();
    await this.setPageData();
  };

  setError = (critical, methodName, error) => {
    this.props.log.error(error.message);
    if (critical) {
      this.setCriticalError();
    } else {
      this.setStandardError();
    }
  };
  setStandardError = () => {
    //MORE CAN BE ADDED HERE AS APPROPRIATE
    this.showError("PPEProfile");
    this.setState({ pageState: PageState.IDLE });
  };
  setCriticalError = () => {
    //SET ERROR STATE OF PAGE
    this.setState({ pageState: PageState.ERROR });
  };

  setViewModel = () => {
    try {
      this.props.viewModel.updateState = this.updateState;
      this.props.viewModel.showError = this.showError;
      this.props.viewModel.showSuccess = this.showSuccess;
      this.props.viewModel.showEmpty = this.showEmpty;
    } catch (error) {
      this.setError(false, "setViewModel", error);
    }
  };

  setPageData = async () => {
    try {
      this.setState({ pageState: PageState.LOADING });

      this.menuState(CrudlStatus.LIST);

      this.setUpColumns();

      // SPECIFIC API CALLS GO HERE
      this.props.viewModel.registerGetList();
      await this.props.viewModel.loadBusinessDetails();

      this.setState({
        pageState: PageState.IDLE,
      });
    } catch (error) {
      this.setError(true, "setPageData", error);
    }
  };
  showError = (display, crudlStatus, whereToView) => {
    try {
      if (whereToView === ViewState.TABLE) {
        this.props.feedback.showError(this.growl, display, crudlStatus);
      }
      if (whereToView === ViewState.CARD) {
        this.setState({
          receivedFeedback: {
            state: FeedbackState.ERROR,
            display: display,
            crudlStatus: crudlStatus,
          },
        });
      }
    } catch (error) {
      this.setError(false, "showError", error);
    }
  };

  showSuccess = (display, crudlStatus, whereToView) => {
    try {
      if (whereToView === ViewState.TABLE) {
        this.props.feedback.showSuccess(this.growl, display, crudlStatus);
      }
      if (whereToView === ViewState.CARD) {
        this.setState({
          receivedFeedback: {
            state: FeedbackState.SUCCESS,
            display: display,
            crudlStatus: crudlStatus,
          },
        });
      }
    } catch (error) {
      this.setError(false, "showSuccess", error);
    }
  };

  showEmpty = (display, whereToView) => {
    try {
      if (whereToView === ViewState.TABLE) {
        this.props.feedback.showEmpty(this.growl, display);
      }
      if (whereToView === ViewState.CARD) {
        this.setState({
          receivedFeedback: {
            state: FeedbackState.EMPTY,
            display: display,
            crudlStatus: CrudlStatus.LIST,
          },
        });
      }
    } catch (error) {
      this.setError(false, "showEmpty", error);
    }
  };

  getStateValuesAsObject = () => {
    try {
      var externalObject = {};
      externalObject.ppeprofileBean = this.state.editedPPEProfile;

      return externalObject;
    } catch (error) {
      this.setError(false, "getStateValuesAsObject", error);
    }
  };

  updateBean = async (field, value) => {
    try {
      this.props.log.info(
        "Updating : [" + field + "] with value [" + value + "]"
      );
      await this.setState((prevState) => ({
        businessBean: {
          // object that we want to update
          ...prevState.businessBean, // keep all other key-value pairs
          [field]: value,
        },
      }));
    } catch (error) {
      this.setError(false, "updateBean", error);
    }
  };

  updatePPEBean = async (id, field, value) => {
    var ppeBeanlist = format.deepCopy(this.state.componentList);

    if (format.isNotNull(ppeBeanlist)) {
      ppeBeanlist.forEach((element) => {
        if (element.id === id) {
          element[field] = value;
        }
      });
    }

    this.updateState("componentList", ppeBeanlist);
  };

  updateState = (key, value) => {
    try {
      this.setState({ [key]: value });
    } catch (error) {
      this.setError(false, "updateState", error);
    }
  };

  validateChanges = async () => {
    try {
      if (this.state.editedPPEProfile === null) {
        await this.discardChanges();
      } else {
        if (
          format.isJsonEqual(
            this.state.originalEditedPPEProfile,
            this.state.editedPPEProfile
          )
        ) {
          await this.discardChanges();
        } else {
          this.setState({ validateState: ValidateState.CONFIRM });
        }
      }
    } catch (error) {
      this.setError(false, "validateChanges", error);
    }
  };

  discardChanges = async () => {
    try {
      this.setState({
        viewState: ViewState.TABLE,
        toggleChangesMade: false,
        crudlState: CrudlStatus.LIST,
        selectedPPEProfile: null,
      });
      await this.setPageData();
      this.menuState(CrudlStatus.LIST);
    } catch (error) {
      this.setError(false, "discardChanges", error);
    }
  };

  checkRequiredFields = () => {
    try {
      if (format.isNotNull(this.state.editedPPEProfile)) {
        // ADD REQUIREDD FIELDS HERE AS APPROPRIATE
        return format.validateValues([this.state.editedPPEProfile.name]);
      } else {
        return true;
      }
    } catch (error) {
      this.setError(false, "checkRequiredFields", error);
    }
  };

  menuState = (crudlStatus) => {
    try {
      this.setState({
        menuState: pr.getBreadcrumbMenuState(
          crudlStatus,
          "PPEProfile",
          "#/ppeprofiles"
        ),
      });
    } catch (error) {
      this.setError(false, "menuState", error);
    }
  };

  executeCrudl = async (stayOnPage) => {
    try {
      if (format.isNotNull(this.state.crudlState)) {
        this.props.log.info(
          "Executing " +
            this.state.crudlState.value +
            " Staying[" +
            stayOnPage +
            "]"
        );

        switch (this.state.crudlState) {
          case CrudlStatus.CREATE:
            await this.addPPEProfile(stayOnPage);
            break;
          case CrudlStatus.UPDATE:
            await this.updatePPEProfile(stayOnPage);
            break;
          case CrudlStatus.DELETE:
            await this.deletePPEProfile();
            break;
          default:
            break;
        }
      }
    } catch (error) {
      this.setError(false, "executeCrudl", error);
    }
  };

  executeSetup = async (requiredCrudlState, data) => {
    try {
      if (format.isNotNull(requiredCrudlState)) {
        this.props.log.info("Setting up " + requiredCrudlState.value);
        switch (requiredCrudlState) {
          case CrudlStatus.CREATE:
            await this.createSetup();
            break;
          case CrudlStatus.UPDATE:
            await this.updateSetup(data);
            break;
          case CrudlStatus.VIEW:
            await this.viewSetup(data);
            break;
          case CrudlStatus.DELETE:
            await this.deleteSetup(data);
            break;
          default:
            break;
        }
      }
    } catch (error) {
      this.setError(false, "executeSetup", error);
    }
  };

  setUpColumns = () => {
    let localCols = [];
    //SPECIFIC COLUMNS FOR TABLE
    localCols.push(pr.getTableColumn("name", "Name"));
    localCols.push(pr.getTableColumn("description", "Description"));
    localCols.push(pr.getTableColumn("type", "Type"));

    this.setState({ tableColumns: localCols });
    var colOptions = pr.getColumnOptions(localCols);
    this.setState({ columnOptions: colOptions });
  };

  columnToggle = (event) => {
    this.setState({ tableColumns: event.value });
  };

  createSetup = async () => {
    window.scrollTo(0, 0);

    var data = {};
    data = await this.additionalParseFunctions(data, CrudlStatus.CREATE);

    this.setState({
      viewState: ViewState.CARD,
      crudlState: CrudlStatus.CREATE,
      editedPPEProfile: data,
      originalEditedPPEProfile: JSON.stringify(data),
    });

    this.menuState(CrudlStatus.CREATE);
  };

  updateSetup = async (data) => {
    window.scrollTo(0, 0);

    data = await this.additionalParseFunctions(data, CrudlStatus.UPDATE);

    this.setState({
      viewState: ViewState.CARD,
      crudlState: CrudlStatus.UPDATE,
      editedPPEProfile: data,
      originalEditedPPEProfile: JSON.stringify(data),
    });

    this.menuState(CrudlStatus.UPDATE);
  };

  viewSetup = async (data) => {
    window.scrollTo(0, 0);

    data = await this.additionalParseFunctions(data, CrudlStatus.VIEW);

    this.setState({
      viewState: ViewState.CARD,
      crudlState: CrudlStatus.VIEW,
      editedPPEProfile: data,
      originalEditedPPEProfile: JSON.stringify(data),
    });

    this.menuState(CrudlStatus.VIEW);
  };

  deleteSetup = async (data) => {
    data = await this.additionalParseFunctions(data, CrudlStatus.DELETE);

    this.setState({
      crudlState: CrudlStatus.DELETE,
      editedPPEProfile: data,
      originalEditedPPEProfile: JSON.stringify(data),
      validateState: ValidateState.DELETE,
    });
    this.menuState(CrudlStatus.DELETE);
  };

  additionalParseFunctions = async (data, crudlState) => {
    //TODO if required
    switch (crudlState) {
      case CrudlStatus.CREATE:
        break;
      case CrudlStatus.UPDATE || CrudlStatus.VIEW:
        break;
      case CrudlStatus.DELETE:
        break;
      default:
        break;
    }
    this.props.log.info(data);
    return data;
  };

  addPPEProfile = async (stayOnPage) => {
    this.setState({ pageState: PageState.LOADING });
    var stateVariables = this.getStateValuesAsObject();

    if (!format.isNotNull(stayOnPage) || stayOnPage === false) {
      stateVariables.viewState = ViewState.TABLE;
    } else {
      stateVariables.viewState = ViewState.CARD;
    }

    var ppeprofileResponse = await this.props.viewModel.executeCRUDLAction(
      CrudlStatus.CREATE,
      stateVariables
    );

    if (ppeprofileResponse.errorCode === 0) {
      if (!format.isNotNull(stayOnPage) || stayOnPage === false) {
        await this.updatePPEProfileList(
          ppeprofileResponse,
          CrudlStatus.CREATE,
          stateVariables
        );
      } else {
        await this.updateCurrentPPEProfile(
          ppeprofileResponse,
          CrudlStatus.CREATE,
          stateVariables
        );
      }
    } else {
      this.setState({ pageState: PageState.IDLE });
    }
  };
  updatePPEProfile = async (stayOnPage) => {
    this.setState({ pageState: PageState.LOADING });
    var stateVariables = this.getStateValuesAsObject();

    if (!format.isNotNull(stayOnPage) || stayOnPage === false) {
      stateVariables.viewState = ViewState.TABLE;
    } else {
      stateVariables.viewState = ViewState.CARD;
    }

    var ppeprofileResponse = await this.props.viewModel.executeCRUDLAction(
      CrudlStatus.UPDATE,
      stateVariables
    );

    if (ppeprofileResponse.errorCode === 0) {
      if (!format.isNotNull(stayOnPage) || stayOnPage === false) {
        await this.updatePPEProfileList(
          ppeprofileResponse,
          CrudlStatus.CREATE,
          stateVariables
        );
      } else {
        await this.updateCurrentPPEProfile(
          ppeprofileResponse,
          CrudlStatus.CREATE,
          stateVariables
        );
      }
    } else {
      this.setState({ pageState: PageState.IDLE });
    }
  };

  deletePPEProfile = async () => {
    this.setState({ pageState: PageState.LOADING });
    var stateVariables = this.getStateValuesAsObject();

    var ppeprofileResponse = await this.props.viewModel.executeCRUDLAction(
      CrudlStatus.DELETE,
      stateVariables
    );

    if (ppeprofileResponse.errorCode === 0) {
      await this.updatePPEProfileList(
        ppeprofileResponse,
        CrudlStatus.DELETE,
        stateVariables
      );
    } else {
      this.setState({ pageState: PageState.IDLE });
    }
  };

  updatePPEProfileList = async (apiResponse, type, stateVariables) => {
    this.setState({ pageState: PageState.LOADING });

    await this.props.viewModel.getPPEProfiles();

    this.setState({
      viewState: ViewState.TABLE,
      crudlState: CrudlStatus.LIST,
      validateState: ValidateState.NONE,
      editedPPEProfile: null,
    });

    this.menuState(CrudlStatus.LIST);

    this.setState({ pageState: PageState.IDLE });
  };

  updateCurrentPPEProfile = async (apiResponse, type, stateVariables) => {
    this.setState({ pageState: PageState.LOADING });

    var ppeprofileList = await this.props.viewModel.getPPEProfilesReturned();
    var ppeprofile = {};
    if (format.isNotNull(ppeprofileList)) {
      ppeprofileList.forEach((element) => {
        if (element.id === apiResponse.id) {
          ppeprofile = format.deepCopy(element);
        }
      });
    }
    await this.updateSetup(ppeprofile);
    this.setState({ ppeprofileList: ppeprofileList });
    this.setState({ pageState: PageState.IDLE });
  };

  updateBusiness = async () => {
    this.setState({ pageState: PageState.LOADING });

    var business = format.deepCopy(this.state.businessBean);

    await this.props.viewModel.updateBusiness(business);

    this.menuState(CrudlStatus.LIST);

    this.setUpColumns();

    // SPECIFIC API CALLS GO HERE

    await this.props.viewModel.loadBusinessDetails();

    this.setState({
      pageState: PageState.IDLE,
    });
  };

  updateProfile = async () => {
    this.setState({ pageState: PageState.LOADING });

    var profileBean = format.deepCopy(this.state.profileBean);
    profileBean.buyer = this.state.businessBean.buyer;
    profileBean.supplier = this.state.businessBean.supplier;

    var ppeBeanList = format.deepCopy(this.state.componentList);

    await this.props.viewModel.updateProfile(profileBean, ppeBeanList);

    this.menuState(CrudlStatus.LIST);

    this.setUpColumns();

    // SPECIFIC API CALLS GO HERE

    await this.props.viewModel.loadBusinessDetails();

    this.setState({
      pageState: PageState.IDLE,
    });
  };

  render() {
    return (
      <React.Fragment>
        <pr.Messages
          style={{ textAlign: "left" }}
          ref={(el) => (this.growl = el)}
        />

        {/*VERY VERY BAD ERROS ONLY eg PAGE LOAD*/}
        {this.state.pageState === PageState.ERROR && (
          <ErrorScreen
            loading={this.state.pageState === PageState.LOADING}
            refresh={this.setPageData}
          />
        )}

        {this.state.pageState !== PageState.ERROR && (
          <PPEProfileView
            //STATE + HELPER VALUES
            viewState={this.state.viewState}
            validateState={this.state.validateState}
            crudlState={this.state.crudlState}
            menuState={this.state.menuState}
            loading={this.state.pageState === PageState.LOADING}
            updateState={this.updateState}
            refresh={this.setPageData}
            feedback={this.props.feedback}
            receivedFeedback={this.state.receivedFeedback}
            //CARD SPECIFIC PROPS
            editedObject={this.state.businessBean}
            ppeprofileTypeOptions={this.state.ppeprofileTypeOptions}
            updateEdited={this.updateBean}
            validateChanges={this.validateChanges}
            discardChanges={this.discardChanges}
            checkRequiredFields={this.checkRequiredFields}
            crudlExecute={this.executeCrudl}
            sidebarWidth={"75%"}
            //TABLE SPECIFIC PROPS
            crudlControl={this.executeSetup}
            ppeprofileList={this.state.ppeprofileList}
            expandedRows={this.state.expandedRows}
            tableColumns={this.state.tableColumns}
            columnOptions={this.state.columnOptions}
            columnToggle={this.columnToggle}
            tableReference={this.state.tableReference}
            rowCount={this.state.rowCount}
            settingsToggle={this.state.settingsToggle}
            updatePPEBean={this.updatePPEBean}
            ppeBeanList={this.state.componentList}
            updateBusiness={this.updateBusiness}
            updateProfile={this.updateProfile}
            steps={this.state.steps}
            activeStep={this.state.activeStep}
            discoveredViaOptions={this.state.discoveredViaOptions}
            countyOptions={this.state.countyOptions}
            sectorOptions={this.state.sectorOptions}
            demographicAreaOptions={this.state.demographicAreaOptions}
          />
        )}
      </React.Fragment>
    );
  }
}
