import React from "react";
//PrimeReact Components
import * as pr from "../../../common/PrimeComponents";
import { CrudlStatus, ViewType } from "../../../common/config/AppConstants";
import { ToggleState } from "../../../common/config/AppConstants";
import * as format from "../../../common/FormatFunctions";
import { UserDisplay } from "./UserDisplay";

export class UserTable extends React.Component {
  export = () => {
    this.dt.exportCSV();
  };

  getUserColumns = () => {
    var allColumns = [];
    // allColumns.push(
    //   <pr.Column
    //     key="expander"
    //     expander={true}
    //     style={{ width: "5em", float: "middle" }}
    //   />
    // );
    if (format.isNotNull(this.props.tableColumns)) {
      let columns = this.props.tableColumns.map((col, i) => {
        return (
          <pr.Column
            key={col.field}
            field={col.field}
            header={col.header}
            sortable={col.sortable}
            filter={col.filter}
            filterMatchMode={col.filterMatchMode}
            style={col.style}
          />
        );
      });

      columns.forEach((e) => {
        allColumns.push(e);
      });
    }
    return allColumns;
  };

  render() {
    return (
      <div className="p-grid bpm">
        {this.props.viewType === ViewType.STANDARD && (
          <>
            <div className="p-col-12">
              <pr.BreadCrumb
                model={this.props.menuState}
                home={pr.getBreadcrumbMenuHome()}
              />
            </div>
            <div className="p-col-12">
              <div className="p-col-12 p-lg-3">
                <div className="content-section">
                  <div className="feature-intro">
                    <h1>{"Users"}</h1>
                    <p>{"A collection of all Users"}</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="p-col-12"
              style={
                this.props.settingsToggle === ToggleState.ON
                  ? {}
                  : { display: "none" }
              }
            >
              <div className="p-grid bpm">
                <div className="p-col-6">
                  <h3>Table Columns</h3>
                  <pr.MultiSelect
                    value={this.props.tableColumns}
                    options={this.props.columnOptions}
                    onChange={this.props.columnToggle}
                    style={{
                      width: "100%",
                      algin: "middle",
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        )}

        <div className="p-col-12">
          <pr.DataTable
            ref={(el) => {
              this.dt = el;
            }}
            value={this.props.userList}
            selectionMode="single"
            onSelectionChange={(e) => {
              if (this.props.crudlState !== CrudlStatus.VIEW) {
                this.props.crudlControl(CrudlStatus.UPDATE, e.value);
              }
            }}
            header={
              <div className="p-grid">
                <div className="p-col-12">
                  {this.props.viewType === ViewType.STANDARD && (
                    <pr.Button
                      className="table-button"
                      type="button"
                      style={{
                        float: "left",
                      }}
                      icon={
                        this.props.settingsToggle === ToggleState.OFF
                          ? "pi-md-settings"
                          : "pi-md-close"
                      }
                      onClick={(e) => {
                        if (this.props.settingsToggle === ToggleState.ON) {
                          console.log("SettingsToggle ==> OFF");
                          this.props.updateState(
                            "settingsToggle",
                            ToggleState.OFF
                          );
                        }
                        if (this.props.settingsToggle === ToggleState.OFF) {
                          console.log("SettingsToggle ==> ON");
                          this.props.updateState(
                            "settingsToggle",
                            ToggleState.ON
                          );
                        }
                      }}
                    />
                  )}
                  {(this.props.viewType === ViewType.STANDARD ||
                    this.props.viewType === ViewType.COUNCIL) && (
                    <pr.Button
                      className="table-button"
                      label={"Add New"}
                      icon="pi-md-add"
                      style={{
                        float: "right",
                      }}
                      onClick={() => {
                        this.props.crudlControl(CrudlStatus.CREATE);
                      }}
                    />
                  )}
                  {this.props.viewType === ViewType.MY_BUSINESS &&
                    this.props.crudlState !== CrudlStatus.VIEW && (
                      <pr.Button
                        className="table-button"
                        label={"Add New"}
                        icon="pi-md-add"
                        style={{
                          float: "right",
                        }}
                        onClick={() => {
                          this.props.crudlControl(CrudlStatus.CREATE);
                        }}
                      />
                    )}
                  {this.props.viewType === ViewType.COUNCIL && (
                    <pr.Button
                      className="table-button"
                      type="button"
                      label={"Export Data"}
                      style={{
                        float: "right",
                      }}
                      icon="pi-md-launch"
                      onClick={this.export}
                    />
                  )}
                  <pr.Button
                    className="table-button"
                    type="button"
                    style={{
                      float: "right",
                    }}
                    label={"Refresh"}
                    icon={
                      this.props.loading === true
                        ? "pi-spin pi-md-autorenew"
                        : "pi-md-autorenew"
                    }
                    onClick={this.props.refresh}
                  />
                </div>
              </div>
            }
            emptyMessage="No Users"
            expandedRows={this.props.expandedRows}
            onRowToggle={(e) => this.props.updateState("expandedRows", e.data)}
            rowExpansionUser={this.getDisplayCard}
            rows={Infinity}
            autoLayout={true}
            paginator={false}
            alwaysShowPaginator={false}
            responsive={true}
            loading={this.props.loading}
          >
            {this.getUserColumns()}
          </pr.DataTable>
        </div>
      </div>
    );
  }
  getDisplayCard = (data) => {
    return <UserDisplay data={data} onClick={this.props.crudlControl} />;
  };
}
