import { apiUnsecure } from "../../common/CloudService/ApiPromisify";
import * as c from "../../common/config/AppConstants";
//ClientLibsReference
import { CloudService } from "../../common/CloudService/ClientLibsReference";
import { CrudlStatus } from "../../common/config/AppConstants";
import firebase from "firebase";
import * as pr from "../../common/PrimeComponents";

export class PPEViewModel {
  showLoadingScreen = () => {
    this.updateState("loaded", false);
  };

  hideLoadingScreen = () => {
    this.updateState("loaded", true);
  };

  componentGetByType = async () => {
    let componentRequest = new CloudService.ComponentRequest();

    componentRequest.type = "PPE";

    await apiUnsecure(
      "componentApiV1ComponentGetByType",
      componentRequest
    ).then((res) => {
      if (res.data) {
        let componentResponse =
          CloudService.ComponentResponse.constructFromObject(res.data);
        console.log(componentResponse);
        this.updateState("componentList", componentResponse.componentList);
      }
    });
  };

  registerGetList = async () => {
    await apiUnsecure("registerApiV1RegisterGetEnums", {}).then((res) => {
      if (res.data) {
        let registerResponse =
          CloudService.RegisterResponse.constructFromObject(res.data);
        console.log(registerResponse);
        this.updateState(
          "sectorOptions",
          pr.getDropdownOptionsFromList(registerResponse.sectorList)
        );
        this.updateState(
          "demographicAreaOptions",
          pr.getDropdownOptionsFromList(registerResponse.demographicAreaList)
        );
        this.updateState(
          "businessType",
          pr.getDropdownOptionsFromList(registerResponse.businessTypeList)
        );
      }
    });
  };

  splitAndRunMethods = (ppeBean) => {
    //Need a User Id Here and a CodeTag
  };

  registerV2 = async (businessRequest, ppeBeanList) => {
    var request = {};
    request.buyer = businessRequest.buyer;
    request.supplier = businessRequest.supplier;
    request.certifiedProducts = businessRequest.certifiedProducts;

    request.certifiedProductsDescription =
      businessRequest.certifiedProductsDescription;
    request.userFirstName = businessRequest.userFirstName;
    request.userSurname = businessRequest.userSurname;
    request.userEmail = businessRequest.userEmail;
    request.userContactTelephone = businessRequest.userContactTelephone;

    console.log(request);

    businessRequest = this.getBusinessRequest(businessRequest);

    var userRequest = this.getUserRequest(request);

    var profileRequest = this.getProfileRequest(request, ppeBeanList);

    var matrixItemList = this.getMatrixItemRequest(request, ppeBeanList);

    var registerRequest = new CloudService.RegisterRequest();

    registerRequest.businessRequest = businessRequest;

    registerRequest.userRequest = userRequest;
    registerRequest.profileRequest = profileRequest;
    registerRequest.matrixItemRequest = matrixItemList;

    console.log(registerRequest);

    await apiUnsecure("registerApiV1RegisterCreate", registerRequest).then(
      async (res) => {
        if (res.data) {
          let registerResponse =
            CloudService.BusinessResponse.constructFromObject(res.data);
          console.log(registerResponse);

          if (registerResponse.errorCode === 0) {
            await this.createUserFirebase(businessRequest);
            this.updateState("registeredSuccessfully", true);
          } else {
            this.updateState("registrationFailed", true);
          }
        }
      }
    );
  };

  getBusinessRequest = (businessRequest) => {
    businessRequest.currency = "GBP";
    businessRequest.countryCode = "GBR";
    businessRequest.timezone = "Europe/London";
    businessRequest.configJsonStr = "{}";
    businessRequest.type = "Buyer";

    return businessRequest;
  };

  getUserRequest = (businessRequest) => {
    let userRequest = new CloudService.UserRequest();

    userRequest.firstName = businessRequest.userFirstName;
    userRequest.surname = businessRequest.userSurname;
    userRequest.email = businessRequest.userEmail.replace(/\s+/g, "");
    userRequest.contactTelephone = businessRequest.userContactTelephone;
    userRequest.roleType = 50;

    return userRequest;
  };

  getProfileRequest = (businessRequest, ppeBeanList) => {
    let profileRequest = new CloudService.ProfileRequest();

    profileRequest.certifiedProducts = businessRequest.certifiedProducts;
    profileRequest.certifiedProductsDescription =
      businessRequest.certifiedProductsDescription;

    if (businessRequest.supplier === false && businessRequest.buyer === false) {
      profileRequest.type = "PPEBuyer";
      profileRequest.certifiedProducts = true;

      let componentBuyerIdList = [];
      let componentIdList = [];
      ppeBeanList.forEach((e) => {
        if (e.checked) {
          componentBuyerIdList.push(e.id);
          componentIdList.push(e.id);
        }
      });
      profileRequest.componentListSupplier = componentIdList;
      profileRequest.componentListBuyer = componentIdList;
      profileRequest.componentList = componentIdList;
    }
    if (businessRequest.supplier === true && businessRequest.buyer === false) {
      profileRequest.type = "PPESupplier";
      let componentBuyerIdList = [];
      let componentIdList = [];
      ppeBeanList.forEach((e) => {
        if (e.checked) {
          componentBuyerIdList.push(e.id);
          componentIdList.push(e.id);
        }
      });
      profileRequest.componentListSupplier = componentIdList;
      profileRequest.componentListBuyer = componentIdList;
      profileRequest.componentList = componentIdList;
    }
    if (businessRequest.supplier === false && businessRequest.buyer === true) {
      profileRequest.type = "PPEBuyer";
      profileRequest.certifiedProducts = true;
      let componentBuyerIdList = [];
      let componentIdList = [];
      ppeBeanList.forEach((e) => {
        if (e.checked) {
          componentBuyerIdList.push(e.id);
          componentIdList.push(e.id);
        }
      });
      profileRequest.componentListSupplier = componentIdList;
      profileRequest.componentListBuyer = componentIdList;
      profileRequest.componentList = componentIdList;

      profileRequest.certifiedProducts = true;
    }
    if (businessRequest.supplier === true && businessRequest.buyer === true) {
      profileRequest.type = "Both";
      let componentSupplierIdList = [];
      let componentBuyerIdList = [];
      let componentIdList = [];
      ppeBeanList.forEach((e) => {
        if (e.checked) {
          if (e.localType === "Supply") {
            componentSupplierIdList.push(e.id);
          } else {
            componentBuyerIdList.push(e.id);
          }

          componentIdList.push(e.id);
        }
      });

      console.log(componentSupplierIdList);
      console.log(componentBuyerIdList);

      profileRequest.componentListSupplier = componentIdList;
      profileRequest.componentListBuyer = componentIdList;
      profileRequest.componentList = componentIdList;
    }
    profileRequest.status = "Pending";

    return profileRequest;
  };

  getMatrixItemRequest = (businessRequest, ppeBeanList) => {
    let matrixItemArray = [];

    if (businessRequest.supplier === false && businessRequest.buyer === false) {
      ppeBeanList.forEach((e) => (e.localType = "Buy"));
    }
    if (businessRequest.supplier === true && businessRequest.buyer === false) {
      ppeBeanList.forEach((e) => (e.localType = "Supply"));
    }
    if (businessRequest.supplier === false && businessRequest.buyer === true) {
      ppeBeanList.forEach((e) => (e.localType = "Buy"));
    }
    if (businessRequest.supplier === true && businessRequest.buyer === true) {
      ppeBeanList.forEach((e) => (e.localType = "Both"));
    }
    ppeBeanList.forEach((e) => {
      if (e.checked) {
        var score = "";
        if (e.localType === "Both") {
          score = "Yes, can buy & can supply";
        } else if (e.localType === "Supply") {
          score = "Yes, can supply";
        } else {
          score = "Yes, can buy";
        }
        matrixItemArray.push({
          componentId: e.id,
          score: score,
        });
      }
    });

    let matrixItemRequest = new CloudService.MatrixItemRequest();

    matrixItemRequest.matrixItemList = matrixItemArray;
  };

  //////////////////////// V1 /////////////////////////////////////
  createBusiness = async (businessRequest, ppeBeanList) => {
    businessRequest.currency = "GBP";
    businessRequest.countryCode = "GBR";
    businessRequest.timezone = "Europe/London";
    businessRequest.configJsonStr = "{}";
    businessRequest.type = "Buyer";

    console.log(businessRequest);
    var codeTag = "";
    var userId = "";
    var errorOccured = false;

    await apiUnsecure("businessApiV1CreateBusiness", businessRequest).then(
      async (res) => {
        if (res.data) {
          let businessResponse =
            CloudService.BusinessResponse.constructFromObject(res.data);
          console.log(businessResponse);

          if (businessResponse.errorCode === 0) {
            codeTag = businessResponse.id;
          } else {
            this.showError("Business", CrudlStatus.CREATE);
            errorOccured = true;
          }
        }
      }
    );

    var errorUser = await this.createUser(codeTag, businessRequest);
    if (errorUser === "error") {
      errorOccured = true;
      this.showError("User", CrudlStatus.CREATE);
    }

    await this.loginCloud(businessRequest.userEmail);

    userId = localStorage.getItem("userId");

    //Create Profile
    var errorProfile = await this.createProfile(
      codeTag,
      userId,
      ppeBeanList,
      businessRequest.supplier,
      businessRequest.buyer,
      businessRequest.certifiedProducts,
      businessRequest.certifiedProductsDescription
    );
    if (errorProfile === "error") {
      errorOccured = true;
      this.showError("Profile", CrudlStatus.CREATE);
    }

    //CreateMatrixItemList
    var errorMatrix = await this.createMatrixItem(
      codeTag,
      userId,
      ppeBeanList,
      businessRequest.supplier,
      businessRequest.buyer
    );
    if (errorMatrix === "error") {
      errorOccured = true;
      this.showError("Matrix", CrudlStatus.CREATE);
    }

    await this.createUserFirebase(businessRequest);
    if (errorOccured) {
      this.updateState("registrationFailed", true);
    } else {
      this.updateState("registeredSuccessfully", true);
    }

    //await this.getProfile(businessRequest.userEmail);
  };
  createProfile = async (
    codeTag,
    userId,
    ppeBeanList,
    supplierFlag,
    buyerFlag,
    certifiedProducts,
    certifiedProductsDescription
  ) => {
    let profileRequest = new CloudService.ProfileRequest();
    profileRequest.codeTag = codeTag;
    profileRequest.userId = userId;

    profileRequest.certifiedProducts = certifiedProducts;
    profileRequest.certifiedProductsDescription = certifiedProductsDescription;

    if (supplierFlag === false && buyerFlag === false) {
      profileRequest.type = "PPEBuyer";
      profileRequest.certifiedProducts = true;

      let componentBuyerIdList = [];
      let componentIdList = [];
      ppeBeanList.forEach((e) => {
        if (e.checked) {
          componentBuyerIdList.push(e.id);
          componentIdList.push(e.id);
        }
      });
      profileRequest.componentListSupplier = componentIdList;
      profileRequest.componentListBuyer = componentIdList;
      profileRequest.componentList = componentIdList;
    }
    if (supplierFlag === true && buyerFlag === false) {
      profileRequest.type = "PPESupplier";
      let componentBuyerIdList = [];
      let componentIdList = [];
      ppeBeanList.forEach((e) => {
        if (e.checked) {
          componentBuyerIdList.push(e.id);
          componentIdList.push(e.id);
        }
      });
      profileRequest.componentListSupplier = componentIdList;
      profileRequest.componentListBuyer = componentIdList;
      profileRequest.componentList = componentIdList;
    }
    if (supplierFlag === false && buyerFlag === true) {
      profileRequest.type = "PPEBuyer";
      profileRequest.certifiedProducts = true;
      let componentBuyerIdList = [];
      let componentIdList = [];
      ppeBeanList.forEach((e) => {
        if (e.checked) {
          componentBuyerIdList.push(e.id);
          componentIdList.push(e.id);
        }
      });
      profileRequest.componentListSupplier = componentIdList;
      profileRequest.componentListBuyer = componentIdList;
      profileRequest.componentList = componentIdList;

      profileRequest.certifiedProducts = true;
    }
    if (supplierFlag === true && buyerFlag === true) {
      profileRequest.type = "Both";
      let componentSupplierIdList = [];
      let componentBuyerIdList = [];
      let componentIdList = [];
      ppeBeanList.forEach((e) => {
        if (e.checked) {
          if (e.localType === "Supply") {
            componentSupplierIdList.push(e.id);
          } else {
            componentBuyerIdList.push(e.id);
          }

          componentIdList.push(e.id);
        }
      });

      console.log(componentSupplierIdList);
      console.log(componentBuyerIdList);

      profileRequest.componentListSupplier = componentIdList;
      profileRequest.componentListBuyer = componentIdList;
      profileRequest.componentList = componentIdList;
    }
    profileRequest.status = "Pending";

    await apiUnsecure("profileApiV1ProfileCreate", profileRequest).then(
      (res) => {
        if (res.data) {
          let profileResponse =
            CloudService.ProfileResponse.constructFromObject(res.data);
          console.log(profileResponse);

          if (profileResponse.errorCode === 0) {
          } else {
            return "error";
          }
        }
      }
    );
  };
  createUserFirebase = async (businessRequest) => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(
        businessRequest.userEmail.replace(/\s+/g, ""),
        businessRequest.userPassword
      )
      .then(async () => {})

      .catch(async (error) => {
        // Handle Errors here.
        var errorCode = error.code;
        // var errorMessage = error.message;
        if (errorCode === "auth/email-already-in-use") {
          this.updateState("firebaseMessage", true);
        } else {
        }
        console.log(error);
      });
  };

  createUser = async (codeTag, businessRequest) => {
    let userRequest = new CloudService.UserRequest();
    userRequest.codeTag = codeTag;
    userRequest.firstName = businessRequest.userFirstName;
    userRequest.surname = businessRequest.userSurname;
    userRequest.email = businessRequest.userEmail;
    userRequest.contactTelephone = businessRequest.userContactTelephone;
    userRequest.roleType = 50;

    await apiUnsecure("userApiV1UserCreate", userRequest).then((res) => {
      if (res.data) {
        let userResponse = CloudService.UserResponse.constructFromObject(
          res.data
        );
        console.log(userResponse);

        if (userResponse.errorCode === 0) {
          var userId = userResponse.id;
          return userId;
        } else {
          return "error";
        }
      }
    });
  };

  createMatrixItem = async (
    codeTag,
    userId,
    ppeBeanList,
    supplierFlag,
    buyerFlag
  ) => {
    let matrixItemArray = [];

    if (supplierFlag === false && buyerFlag === false) {
      ppeBeanList.forEach((e) => (e.localType = "Buy"));
    }
    if (supplierFlag === true && buyerFlag === false) {
      ppeBeanList.forEach((e) => (e.localType = "Supply"));
    }
    if (supplierFlag === false && buyerFlag === true) {
      ppeBeanList.forEach((e) => (e.localType = "Buy"));
    }
    if (supplierFlag === true && buyerFlag === true) {
      ppeBeanList.forEach((e) => (e.localType = "Both"));
    }
    ppeBeanList.forEach((e) => {
      if (e.checked) {
        var score = "";
        if (e.localType === "Both") {
          score = "Yes, can buy & can supply";
        } else if (e.localType === "Supply") {
          score = "Yes, can supply";
        } else {
          score = "Yes, can buy";
        }
        matrixItemArray.push({
          codeTag: codeTag,
          userId: userId,
          componentId: e.id,
          score: score,
        });
      }
    });

    let matrixItemRequest = new CloudService.MatrixItemRequest();
    matrixItemRequest.codeTag = codeTag;
    matrixItemRequest.userId = userId;
    matrixItemRequest.matrixItemList = matrixItemArray;

    await apiUnsecure(
      "matrixItemApiV1MatrixItemCreate",
      matrixItemRequest
    ).then((res) => {
      if (res.data) {
        let matrixItemResponse =
          CloudService.MatrixItemResponse.constructFromObject(res.data);
        console.log(matrixItemResponse);

        if (matrixItemResponse.errorCode === 0) {
        } else {
          return "error";
        }
      }
    });
  };

  loginCloud = async (email) => {
    let loginRequest = new CloudService.LoginRequest();

    loginRequest.codeTag = localStorage.getItem("globalBid");
    if (!localStorage.getItem("globalBid")) {
      loginRequest.codeTag = c.defaultedGlobalBid;
    }
    loginRequest.userEmail = email;

    await apiUnsecure("loginApiV1Login", loginRequest).then(async (res) => {
      let loginResponse = CloudService.LoginResponse.constructFromObject(
        res.data
      );
      console.log(loginResponse);

      if (loginResponse.errorCode !== 0) {
        localStorage.clear();
        localStorage.setItem("globalBid", loginResponse.globalBid);

        return "error";
      } else {
        if (loginResponse.roleType === "100") {
        } else {
          Object.entries(loginResponse).forEach(([key, value]) => {
            localStorage.setItem(key, value);
          });
        }
      }
    });
  };

  getProfile = async (email) => {
    let profileRequest = new CloudService.ProfileRequest();
    profileRequest.codeTag = localStorage.getItem("clientId");

    var returnValue = 0;
    var returnStatus = "";
    await apiUnsecure("profileApiV1ProfileGetByCodeTag", profileRequest).then(
      (res) => {
        if (res.data) {
          let profileResponse =
            CloudService.ProfileResponse.constructFromObject(res.data);
          console.log(profileResponse);
          var validPPEProfile = "";
          var validPPEProfileStatus = "";
          if (Array.isArray(profileResponse.profileList)) {
            profileResponse.profileList.forEach((element) => {
              if (element.type === "PPESupplier") {
                validPPEProfile = "PPESupplier";
                validPPEProfileStatus = element.profileStatus;
              }
              if (element.type === "PPEBuyer") {
                validPPEProfile = "PPEBuyer";
                validPPEProfileStatus = element.profileStatus;
              }
              if (element.type === "Both") {
                validPPEProfile = "Both";
                validPPEProfileStatus = element.profileStatus;
              }
            });
          }

          //TEST A
          //validPPEProfile = "";
          if (validPPEProfile === "") {
            returnValue = 1;
            //Use Case 1 - Need to Create Profile
          } else {
            if (validPPEProfileStatus !== "Approved") {
              // Use Case 2 need to Diplsy information about not yet being approved
              returnValue = 2;
              returnStatus = validPPEProfileStatus;
            } else {
              localStorage.setItem("profileStatus", validPPEProfileStatus);
              localStorage.setItem("profileType", "PPE");
              localStorage.setItem("validPPEProfile", validPPEProfile);
              localStorage.setItem("email", email);
              window.location = "#/Dashboard";
              window.location.reload();

              returnValue = 3;
            }
          }
        }
      }
    );

    return [returnValue, returnStatus];
  };
}
