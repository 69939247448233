import React from "react";
import { ProfileItemView } from "./ProfileItemView";
import {
  CrudlStatus,
  ToggleState,
  PageState,
  FeedbackState,
  ViewType,
  ViewState,
  ValidateState,
} from "../../common/config/AppConstants";
import * as pr from "../../common/PrimeComponents";
import * as format from "../../common/FormatFunctions";
import { ErrorScreen } from "../Common/ErrorScreen";
import * as analytics from "../../common/helpers/firebaseAnalytics";

export class ProfileItemController extends React.Component {
  state = {
    pageName: "ProfileItemController",
    pageState: PageState.IDLE,
    sidebarWidth: "100%",

    //DISPLAY STATES
    viewState: ViewState.TABLE,
    validateState: ValidateState.NONE,
    crudlState: CrudlStatus.LIST,
    menuState: [],

    // PROPS
    viewType: ViewType.STANDARD,

    // DATA TABLE
    expandedRows: null,
    tableColumns: [],
    columnOptions: [],
    settingsToggle: ToggleState.OFF,

    // DETAILS
    defaultProfileItemList: [],
    profileItemList: null,
    originalEditedProfileItem: "{}",
    editedProfileItem: {},
    receivedFeedback: {
      state: FeedbackState.NONE,
      display: "ProfileItem",
      crudlStatus: CrudlStatus.LIST,
    },
    profile: {},
  };

  componentDidMount = async () => {
    await this.initialDataRender();
  };

  componentDidCatch = (error, info) => {
    this.props.log("This threw an error");
    this.props.log(error);
    this.props.log(info);
    //COMPONENT ISSUE CHECK IF THIS ERROR HANDLING IS CORRECT
    this.setError(false, "componentDidCatch", error);
  };

  initialDataRender = async () => {
    this.setViewModel();
    await this.setPageData();
  };

  setError = (critical, methodName, error) => {
    this.props.log.error(error.message);
    if (critical) {
      this.setCriticalError();
    } else {
      this.setStandardError();
    }
  };
  setStandardError = () => {
    //MORE CAN BE ADDED HERE AS APPROPRIATE
    this.showError("ProfileItem");
    this.setState({ pageState: PageState.IDLE });
  };
  setCriticalError = () => {
    //SET ERROR STATE OF PAGE
    this.setState({ pageState: PageState.ERROR });
  };

  setViewModel = () => {
    try {
      this.props.viewModel.updateState = this.updateState;
      this.props.viewModel.showError = this.showError;
      this.props.viewModel.showSuccess = this.showSuccess;
      this.props.viewModel.showEmpty = this.showEmpty;
    } catch (error) {
      this.setError(false, "setViewModel", error);
    }
  };

  setPageData = async () => {
    try {
      this.setState({ pageState: PageState.LOADING });

      this.menuState(CrudlStatus.LIST);

      await this.handleProps();

      // SPECIFIC API CALLS GO HERE
      await this.props.viewModel.getProfileItems(this.state.profile.id);

      this.setState({
        pageState: PageState.IDLE,
      });
    } catch (error) {
      this.setError(true, "setPageData", error);
    }
  };
  handleProps = async () => {
    if (format.isNotNull(this.props.viewType)) {
      await this.setState({ viewType: this.props.viewType });
    }
    if (format.isNotNull(this.props.profile)) {
      await this.setState({ profile: this.props.profile });
    }
    if (format.isNotNull(this.props.crudlState)) {
      await this.setState({ crudlState: this.props.crudlState });
    }
  };
  showError = (display, crudlStatus, whereToView) => {
    try {
      if (whereToView === ViewState.TABLE) {
        this.props.feedback.showError(this.growl, display, crudlStatus);
      }
      if (whereToView === ViewState.CARD) {
        this.setState({
          receivedFeedback: {
            state: FeedbackState.ERROR,
            display: display,
            crudlStatus: crudlStatus,
          },
        });
      }
    } catch (error) {
      this.setError(false, "showError", error);
    }
  };

  showSuccess = (display, crudlStatus, whereToView) => {
    try {
      if (whereToView === ViewState.TABLE) {
        this.props.feedback.showSuccess(this.growl, display, crudlStatus);
      }
      if (whereToView === ViewState.CARD) {
        this.setState({
          receivedFeedback: {
            state: FeedbackState.SUCCESS,
            display: display,
            crudlStatus: crudlStatus,
          },
        });
      }
    } catch (error) {
      this.setError(false, "showSuccess", error);
    }
  };

  showEmpty = (display, whereToView) => {
    try {
      if (whereToView === ViewState.TABLE) {
        this.props.feedback.showEmpty(this.growl, display);
      }
      if (whereToView === ViewState.CARD) {
        this.setState({
          receivedFeedback: {
            state: FeedbackState.EMPTY,
            display: display,
            crudlStatus: CrudlStatus.LIST,
          },
        });
      }
    } catch (error) {
      this.setError(false, "showEmpty", error);
    }
  };

  getStateValuesAsObject = () => {
    try {
      var externalObject = {};
      externalObject.profileitemBean = this.state.editedProfileItem;
      externalObject.viewType = this.state.viewType;

      return externalObject;
    } catch (error) {
      this.setError(false, "getStateValuesAsObject", error);
    }
  };

  updateBean = async (field, value) => {
    try {
      this.props.log.info(
        "Updating : [" + field + "] with value [" + value + "]"
      );
      await this.setState((prevState) => ({
        editedProfileItem: {
          // object that we want to update
          ...prevState.editedProfileItem, // keep all other key-value pairs
          [field]: value,
        },
      }));
    } catch (error) {
      this.setError(false, "updateBean", error);
    }
  };

  updateState = (key, value) => {
    try {
      this.setState({ [key]: value });
    } catch (error) {
      this.setError(false, "updateState", error);
    }
  };

  validateChanges = async () => {
    try {
      if (this.state.editedProfileItem === null) {
        await this.discardChanges();
      } else {
        if (
          format.isJsonEqual(
            this.state.originalEditedProfileItem,
            this.state.editedProfileItem
          )
        ) {
          await this.discardChanges();
        } else {
          this.setState({ validateState: ValidateState.CONFIRM });
        }
      }
    } catch (error) {
      this.setError(false, "validateChanges", error);
    }
  };

  discardChanges = async () => {
    try {
      this.setState({
        viewState: ViewState.TABLE,
        toggleChangesMade: false,
        crudlState: CrudlStatus.LIST,
        selectedProfileItem: null,
      });

      // TODO use viewType constant from AppConstants
      if (this.props.viewType !== "STANDARD") {
        if (format.isFunction(this.props.updateStateFromProps)) {
          // Toggle any relevant Views for create or update pages
          // this.props.updateStateFromProps("", false);
        }
      }

      await this.setPageData();
      this.menuState(CrudlStatus.LIST);
    } catch (error) {
      this.setError(false, "discardChanges", error);
    }
  };

  checkRequiredFields = () => {
    try {
      let fieldList = [];
      if (format.isNotNull(this.state.editedProfileItem)) {
        fieldList.push(this.state.editedProfileItem.name);
        return format.validateValues(fieldList);
      } else {
        return true;
      }
    } catch (error) {
      this.setError(false, "checkRequiredFields", error);
    }
  };

  menuState = (crudlStatus) => {
    try {
      this.setState({
        menuState: pr.getBreadcrumbMenuState(
          crudlStatus,
          "ProfileItem",
          "#/profileitems"
        ),
      });
    } catch (error) {
      this.setError(false, "menuState", error);
    }
  };

  executeCrudl = async () => {
    try {
      if (format.isNotNull(this.state.profileItemList)) {
        let createList = [];
        let updateList = [];
        for (var profile of this.state.profileItemList) {
          if (profile.crudlState === CrudlStatus.CREATE.value) {
            createList.push(profile);
          } else if (profile.crudlState === CrudlStatus.UPDATE.value) {
            updateList.push(profile);
          }
        }

        if (format.isNotNull(createList)) {
          await this.addProfileItem(createList);
        }
        if (format.isNotNull(updateList)) {
          await this.updateProfileItem(updateList);
        }
      }

      await this.updateProfileItemList();
    } catch (error) {
      this.setError(false, "executeCrudl", error);
    }
  };

  additionalParseFunctions = async (data, crudlState) => {
    //TODO if required
    switch (crudlState) {
      case CrudlStatus.CREATE:
        break;
      case CrudlStatus.UPDATE || CrudlStatus.VIEW:
        break;
      case CrudlStatus.DELETE:
        break;
      default:
        break;
    }
    this.props.log.info(data);
    return data;
  };

  addProfileItem = async (profileItemList) => {
    this.setState({ pageState: PageState.LOADING });
    var stateVariables = this.getStateValuesAsObject();

    stateVariables.viewState = ViewState.CARD;
    stateVariables.profileitemBean.profileItemList = profileItemList;

    analytics.logProfileItemEvent(
      analytics.Actions.PROVIDED_GENERAL_INFORMATION,
      this.state.profile
    );

    var profileitemResponse = await this.props.viewModel.executeCRUDLAction(
      CrudlStatus.CREATE,
      stateVariables
    );

    if (profileitemResponse.errorCode === 0) {
    } else {
      this.setState({ pageState: PageState.IDLE });
    }
  };
  updateProfileItem = async (profileItemList) => {
    this.setState({ pageState: PageState.LOADING });
    var stateVariables = this.getStateValuesAsObject();

    stateVariables.viewState = ViewState.CARD;
    stateVariables.profileitemBean.profileItemList = profileItemList;

    var profileitemResponse = await this.props.viewModel.executeCRUDLAction(
      CrudlStatus.UPDATE,
      stateVariables
    );

    if (profileitemResponse.errorCode === 0) {
    } else {
      this.setState({ pageState: PageState.IDLE });
    }
  };

  updateProfileItemList = async () => {
    this.setState({ pageState: PageState.LOADING });

    await this.props.viewModel.getProfileItems(this.state.profile.id);

    this.setState({ pageState: PageState.IDLE, editedProfileItem: {} });
  };

  updateFormList = (object) => {
    let updatedList = [];

    if (format.isNotNull(this.state.profileItemList)) {
      this.state.profileItemList.forEach((e) => {
        if (e.question === object.question) {
          updatedList.push(object);
        } else {
          updatedList.push(e);
        }
      });
    }

    this.setState({ profileItemList: updatedList });
  };

  render() {
    return (
      <React.Fragment>
        <pr.Messages
          style={{ textAlign: "left" }}
          ref={(el) => (this.growl = el)}
        />

        {/*VERY VERY BAD ERROS ONLY eg PAGE LOAD*/}
        {this.state.pageState === PageState.ERROR && (
          <ErrorScreen
            loading={this.state.pageState === PageState.LOADING}
            refresh={this.setPageData}
          />
        )}

        {this.state.pageState !== PageState.ERROR && (
          <ProfileItemView
            //STATE + HELPER VALUES
            viewState={this.state.viewState}
            validateState={this.state.validateState}
            crudlState={this.state.crudlState}
            menuState={this.state.menuState}
            loading={this.state.pageState === PageState.LOADING}
            updateState={this.updateState}
            refresh={this.setPageData}
            feedback={this.props.feedback}
            receivedFeedback={this.state.receivedFeedback}
            //CARD SPECIFIC PROPS
            viewType={this.state.viewType}
            editedObject={this.state.editedProfileItem}
            profileitemTypeOptions={this.state.profileitemTypeOptions}
            updateEdited={this.updateBean}
            validateChanges={this.validateChanges}
            discardChanges={this.discardChanges}
            checkRequiredFields={this.checkRequiredFields}
            crudlExecute={this.executeCrudl}
            profileItemList={this.state.profileItemList}
            toggleGeneral={this.state.toggleGeneral}
            toggleQuality={this.state.toggleQuality}
            toggleCost={this.state.toggleCost}
            toggleLogistics={this.state.toggleLogistics}
            toggleDevelopment={this.state.toggleDevelopment}
            toggleManagement={this.state.toggleManagement}
            updateProfileItemList={this.updateFormList}
          />
        )}
      </React.Fragment>
    );
  }
}
