import React from "react";
//PrimeReact Components
import { CrudlStatus } from "../../../common/config/AppConstants";
import * as format from "../../../common/FormatFunctions";
export class UserDisplay extends React.Component {
  render() {
    var bean = this.props.data;
    if (!format.isNotNull(bean)) {
      return <div></div>;
    }
    return (
      <div
        className="p-col-12 card-round"
        onClick={(e) => {
          if (format.isFunction(this.props.onClick)) {
            this.props.onClick(CrudlStatus.VIEW, bean);
          }
        }}
      >
        <div className="p-grid">
          <h2>User Summary: </h2>
          <label />

          <div className="p-col-12 p-md-2 row"></div>
          <div className="p-col-12 p-md-8  row">
            <div className="p-col-4 p-md-4 p-lg-4 cell">
              {/* EXAMPLE
               <b style={{ float: "right" }}>Name: </b>
              <br></br>
              <br></br>
              <b style={{ float: "right" }}>Description: </b> */}
            </div>

            <div className="p-col-4 p-md-4 p-lg-4 cell">
              {/* EXAMPLE
              <label>{bean.name || "Not Set"}</label>
              <br></br>
              <br></br>
              <label>{bean.description || "Not Set"}</label> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
