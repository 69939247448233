import React from "react";
import { EnquiriesController } from "./EnquiriesController";
import { EnquiriesViewModel } from "./EnquiriesViewModel";
import * as feedback from "../../common/Feedback/Feedback";
import * as log from "../../common/Feedback/Log";

export class EnquiriesProvider extends React.Component {
  constructor(props) {
    super(props);
    this.viewModel = new EnquiriesViewModel();
    this.logger = log;
    this.feedback = feedback;

    console.log(props);
  }

  render() {
    return (
      <EnquiriesController
        viewModel={this.viewModel}
        log={this.logger}
        feedback={this.feedback}
        toggleTopbar={this.props.toggleTopbar}
      />
    );
  }
}
