import React from "react";
import { PPEView } from "./PPEView";
import {
  CrudlStatus,
  ToggleState,
  PageState,
  FeedbackState,
} from "../../common/config/AppConstants";
import { ViewState } from "../../common/config/AppConstants";
import { ValidateState } from "../../common/config/AppConstants";
import * as format from "../../common/FormatFunctions";

export class PPEController extends React.Component {
  state = {
    pageName: "PPEController",
    pageState: PageState.IDLE,
    editLoading: true,

    //DISPLAY STATES
    viewState: ViewState.TABLE,
    validateState: ValidateState.NONE,
    crudlState: CrudlStatus.LIST,
    menuState: [],

    // DATA TABLE
    expandedRows: null,
    tableColumns: [],
    columnOptions: [],
    rowCount: 10,
    settingsToggle: ToggleState.OFF,

    // DETAILS
    ppeList: null,
    originalEditedPPE: "",
    editedPPE: { buyer: false, supplier: false },

    receivedFeedback: {
      state: FeedbackState.NONE,
      display: "PPE",
      crudlStatus: CrudlStatus.LIST,
    },

    activeStep: 0,
    steps: [
      {
        label: "Business Details",
      },
      {
        label: "Product Information",
      },
      {
        label: "User Information",
      },
    ],

    countyOptions: [
      { label: "Antrim", value: "Antrim" },
      { label: "Armagh", value: "Armagh" },
      { label: "Derry/Londonderry", value: "Derry/Londonderry" },
      { label: "Down", value: "Down" },
      { label: "Fermanagh", value: "Fermanagh" },
      { label: "Tyrone", value: "Tyrone" },
    ],

    discoveredViaOptions: [
      { label: "Direct Mail", value: "Direct Mail" },
      { label: "Email", value: "Email" },
      { label: "Advertising", value: "Advertising" },
      { label: "Event", value: "Event" },
      { label: "Social Media", value: "Social Media" },
      { label: "Word of Mouth", value: "Word of Mouth" },
      { label: "Council Staff", value: "Council Staff" },
    ],

    productList: [],
    productBean: {},
  };

  componentDidMount = async () => {
    await this.initialDataRender();
  };

  componentDidCatch = (error, info) => {
    console.log("This went in here");

    //COMPONENT ISSUE CHECK IF THIS ERROR HANDLING IS CORRECT
    this.setError(false, "componentDidCatch", error);
  };

  initialDataRender = async () => {
    this.setViewModel();
    await this.setPageData();
  };

  setError = (critical, methodName, error) => {
    this.props.log.error(error.message);
    if (critical) {
      this.setCriticalError();
    } else {
      this.setStandardError();
    }
    // this.props.viewModel.createException(
    //   error,
    //   this.state.pageName,
    //   methodName
    // );
  };
  setStandardError = () => {
    //MORE CAN BE ADDED HERE AS APPROPRIATE
    this.showError("PPE");
    this.setState({ pageState: PageState.IDLE });
  };
  setCriticalError = () => {
    //SET ERROR STATE OF PAGE
    this.setState({ pageState: PageState.ERROR });
  };

  setViewModel = () => {
    try {
      this.props.viewModel.updateState = this.updateState;
      this.props.viewModel.showError = this.showError;
      this.props.viewModel.showSuccess = this.showSuccess;
      this.props.viewModel.showEmpty = this.showEmpty;
    } catch (error) {
      this.setError(false, "setViewModel", error);
    }
  };

  setPageData = async () => {
    try {
      this.setState({ pageState: PageState.LOADING });

      this.menuState(CrudlStatus.LIST);

      this.setUpColumns();

      // SPECIFIC API CALLS GO HERE]
      await this.props.viewModel.componentGetByType();
      await this.props.viewModel.registerGetList();

      this.setState({
        pageState: PageState.IDLE,
      });
    } catch (error) {
      this.setError(true, "setPageData", error);
    }
  };
  showError = (display, crudlStatus, whereToView) => {
    try {
      if (whereToView === ViewState.TABLE) {
        this.props.feedback.showError(this.growl, display, crudlStatus);
      }
      if (whereToView === ViewState.CARD) {
        this.setState({
          receivedFeedback: {
            state: FeedbackState.ERROR,
            display: display,
            crudlStatus: crudlStatus,
          },
        });
      }
    } catch (error) {
      this.setError(false, "showError", error);
    }
  };

  showSuccess = (display, crudlStatus, whereToView) => {
    try {
      if (whereToView === ViewState.TABLE) {
        this.props.feedback.showSuccess(this.growl, display, crudlStatus);
      }
      if (whereToView === ViewState.CARD) {
        this.setState({
          receivedFeedback: {
            state: FeedbackState.SUCCESS,
            display: display,
            crudlStatus: crudlStatus,
          },
        });
      }
    } catch (error) {
      this.setError(false, "showSuccess", error);
    }
  };

  showEmpty = (display, whereToView) => {
    try {
      if (whereToView === ViewState.TABLE) {
        this.props.feedback.showEmpty(this.growl, display);
      }
      if (whereToView === ViewState.CARD) {
        this.setState({
          receivedFeedback: {
            state: FeedbackState.EMPTY,
            display: display,
            crudlStatus: CrudlStatus.LIST,
          },
        });
      }
    } catch (error) {
      this.setError(false, "showEmpty", error);
    }
  };

  getStateValuesAsObject = () => {
    try {
      var externalObject = {};
      externalObject.ppeBean = this.state.editedPPE;

      return externalObject;
    } catch (error) {
      this.setError(false, "getStateValuesAsObject", error);
    }
  };

  updateBean = async (field, value) => {
    try {
      this.props.log.info(
        "Updating : [" + field + "] with value [" + value + "]"
      );
      await this.setState((prevState) => ({
        editedPPE: {
          // object that we want to update
          ...prevState.editedPPE, // keep all other key-value pairs
          [field]: value,
        },
      }));
    } catch (error) {
      this.setError(false, "updateBean", error);
    }
  };
  updatePPEBean = async (id, field, value) => {
    var ppeBeanlist = format.deepCopy(this.state.componentList);

    if (format.isNotNull(ppeBeanlist)) {
      ppeBeanlist.forEach((element) => {
        if (element.id === id) {
          element[field] = value;
        }
      });
    }

    this.updateState("componentList", ppeBeanlist);
  };

  addProductBean = async () => {
    var productList = format.deepCopy(this.state.editedPPE.productList);

    if (format.isNotNull(this.state.productBean)) {
      if (productList === null) {
        productList = [];
      }
      productList.push(format.deepCopy(this.state.productBean));
    }

    this.updateBean("productList", productList);
    this.updateState("productBean", {});
    this.updateState("addProductView", false);
  };

  updateProductBean = async (field, value) => {
    try {
      this.props.log.info(
        "Updating : [" + field + "] with value [" + value + "]"
      );
      await this.setState((prevState) => ({
        productBean: {
          // object that we want to update
          ...prevState.productBean, // keep all other key-value pairs
          [field]: value,
        },
      }));
    } catch (error) {
      this.setError(false, "updateBean", error);
    }
  };
  updateState = (key, value) => {
    try {
      this.setState({ [key]: value });
    } catch (error) {
      this.setError(false, "updateState", error);
    }
  };

  validateChanges = async () => {
    try {
      if (this.state.editedPPE === null) {
        await this.discardChanges();
      } else {
        if (
          format.isJsonEqual(this.state.originalEditedPPE, this.state.editedPPE)
        ) {
          await this.discardChanges();
        } else {
          this.setState({ validateState: ValidateState.CONFIRM });
        }
      }
    } catch (error) {
      this.setError(false, "validateChanges", error);
    }
  };

  discardChanges = async () => {
    try {
      this.setState({
        viewState: ViewState.TABLE,
        toggleChangesMade: false,
        crudlState: CrudlStatus.LIST,
        selectedPPE: null,
      });
      await this.setPageData();
      this.menuState(CrudlStatus.LIST);
    } catch (error) {
      this.setError(false, "discardChanges", error);
    }
  };

  checkRequiredFields = () => {
    try {
      if (format.isNotNull(this.state.editedPPE)) {
        // ADD REQUIREDD FIELDS HERE AS APPROPRIATE
        return format.validateValues([this.state.editedPPE.name]);
      } else {
        return true;
      }
    } catch (error) {
      this.setError(false, "checkRequiredFields", error);
    }
  };

  menuState = (crudlStatus) => {
    // try {
    //   this.setState({
    //     menuState: pr.getBreadcrumbMenuState(crudlStatus, "PPE", "#/ppes"),
    //   });
    // } catch (error) {
    //   this.setError(false, "menuState", error);
    // }
  };

  executeCrudl = async (stayOnPage) => {
    try {
      if (format.isNotNull(this.state.crudlState)) {
        this.props.log.info(
          "Executing " +
            this.state.crudlState.value +
            " Staying[" +
            stayOnPage +
            "]"
        );

        switch (this.state.crudlState) {
          case CrudlStatus.CREATE:
            await this.addPPE(stayOnPage);
            break;
          case CrudlStatus.UPDATE:
            await this.updatePPE(stayOnPage);
            break;
          case CrudlStatus.DELETE:
            await this.deletePPE();
            break;
          default:
            break;
        }
      }
    } catch (error) {
      this.setError(false, "executeCrudl", error);
    }
  };

  executeSetup = async (requiredCrudlState, data) => {
    try {
      if (format.isNotNull(requiredCrudlState)) {
        this.props.log.info("Setting up " + requiredCrudlState.value);
        switch (requiredCrudlState) {
          case CrudlStatus.CREATE:
            await this.createSetup();
            break;
          case CrudlStatus.UPDATE:
            await this.updateSetup(data);
            break;
          case CrudlStatus.VIEW:
            await this.viewSetup(data);
            break;
          case CrudlStatus.DELETE:
            await this.deleteSetup(data);
            break;
          default:
            break;
        }
      }
    } catch (error) {
      this.setError(false, "executeSetup", error);
    }
  };

  runRegister = async () => {
    this.setState({ pageState: PageState.LOADING });
    await this.props.viewModel.registerV2(
      this.state.editedPPE,
      this.state.componentList
    );
    this.setState({ pageState: PageState.IDLE });
  };
  setUpColumns = () => {
    // let localCols = [];
    // //SPECIFIC COLUMNS FOR TABLE
    // localCols.push(pr.getTableColumn("name", "Name"));
    // localCols.push(pr.getTableColumn("description", "Description"));
    // localCols.push(pr.getTableColumn("type", "Type"));
    // this.setState({ tableColumns: localCols });
    // var colOptions = pr.getColumnOptions(localCols);
    // this.setState({ columnOptions: colOptions });
  };

  columnToggle = (event) => {
    this.setState({ tableColumns: event.value });
  };

  createSetup = async () => {
    window.scrollTo(0, 0);

    var data = {};
    data = await this.additionalParseFunctions(data, CrudlStatus.CREATE);

    this.setState({
      viewState: ViewState.CARD,
      crudlState: CrudlStatus.CREATE,
      editedPPE: data,
      originalEditedPPE: JSON.stringify(data),
    });

    this.menuState(CrudlStatus.CREATE);
  };

  updateSetup = async (data) => {
    window.scrollTo(0, 0);

    data = await this.additionalParseFunctions(data, CrudlStatus.UPDATE);

    this.setState({
      viewState: ViewState.CARD,
      crudlState: CrudlStatus.UPDATE,
      editedPPE: data,
      originalEditedPPE: JSON.stringify(data),
    });

    this.menuState(CrudlStatus.UPDATE);
  };

  viewSetup = async (data) => {
    window.scrollTo(0, 0);

    data = await this.additionalParseFunctions(data, CrudlStatus.VIEW);

    this.setState({
      viewState: ViewState.CARD,
      crudlState: CrudlStatus.VIEW,
      editedPPE: data,
      originalEditedPPE: JSON.stringify(data),
    });

    this.menuState(CrudlStatus.VIEW);
  };

  deleteSetup = async (data) => {
    data = await this.additionalParseFunctions(data, CrudlStatus.DELETE);

    this.setState({
      crudlState: CrudlStatus.DELETE,
      editedPPE: data,
      originalEditedPPE: JSON.stringify(data),
      validateState: ValidateState.DELETE,
    });
    this.menuState(CrudlStatus.DELETE);
  };

  additionalParseFunctions = async (data, crudlState) => {
    //TODO if required
    switch (crudlState) {
      case CrudlStatus.CREATE:
        break;
      case CrudlStatus.UPDATE || CrudlStatus.VIEW:
        break;
      case CrudlStatus.DELETE:
        break;
      default:
        break;
    }
    this.props.log.info(data);
    return data;
  };

  addPPE = async (stayOnPage) => {
    this.setState({ pageState: PageState.LOADING });
    var stateVariables = this.getStateValuesAsObject();

    if (!format.isNotNull(stayOnPage) || stayOnPage === false) {
      stateVariables.viewState = ViewState.TABLE;
    } else {
      stateVariables.viewState = ViewState.CARD;
    }

    var ppeResponse = await this.props.viewModel.executeCRUDLAction(
      CrudlStatus.CREATE,
      stateVariables
    );

    if (ppeResponse.errorCode === 0) {
      if (!format.isNotNull(stayOnPage) || stayOnPage === false) {
        await this.updatePPEList(
          ppeResponse,
          CrudlStatus.CREATE,
          stateVariables
        );
      } else {
        await this.updateCurrentPPE(
          ppeResponse,
          CrudlStatus.CREATE,
          stateVariables
        );
      }
    } else {
      this.setState({ pageState: PageState.IDLE });
    }
  };
  updatePPE = async (stayOnPage) => {
    this.setState({ pageState: PageState.LOADING });
    var stateVariables = this.getStateValuesAsObject();

    if (!format.isNotNull(stayOnPage) || stayOnPage === false) {
      stateVariables.viewState = ViewState.TABLE;
    } else {
      stateVariables.viewState = ViewState.CARD;
    }

    var ppeResponse = await this.props.viewModel.executeCRUDLAction(
      CrudlStatus.UPDATE,
      stateVariables
    );

    if (ppeResponse.errorCode === 0) {
      if (!format.isNotNull(stayOnPage) || stayOnPage === false) {
        await this.updatePPEList(
          ppeResponse,
          CrudlStatus.CREATE,
          stateVariables
        );
      } else {
        await this.updateCurrentPPE(
          ppeResponse,
          CrudlStatus.CREATE,
          stateVariables
        );
      }
    } else {
      this.setState({ pageState: PageState.IDLE });
    }
  };

  deletePPE = async () => {
    this.setState({ pageState: PageState.LOADING });
    var stateVariables = this.getStateValuesAsObject();

    var ppeResponse = await this.props.viewModel.executeCRUDLAction(
      CrudlStatus.DELETE,
      stateVariables
    );

    if (ppeResponse.errorCode === 0) {
      await this.updatePPEList(ppeResponse, CrudlStatus.DELETE, stateVariables);
    } else {
      this.setState({ pageState: PageState.IDLE });
    }
  };

  updatePPEList = async (apiResponse, type, stateVariables) => {
    this.setState({ pageState: PageState.LOADING });

    await this.props.viewModel.getPPEs();

    this.setState({
      viewState: ViewState.TABLE,
      crudlState: CrudlStatus.LIST,
      validateState: ValidateState.NONE,
      editedPPE: null,
    });

    this.menuState(CrudlStatus.LIST);

    this.setState({ pageState: PageState.IDLE });
  };

  updateCurrentPPE = async (apiResponse, type, stateVariables) => {
    this.setState({ pageState: PageState.LOADING });

    var ppeList = await this.props.viewModel.getPPEsReturned();
    var ppe = {};
    if (format.isNotNull(ppeList)) {
      ppeList.forEach((element) => {
        if (element.id === apiResponse.id) {
          ppe = format.deepCopy(element);
        }
      });
    }
    await this.updateSetup(ppe);
    this.setState({ ppeList: ppeList });
    this.setState({ pageState: PageState.IDLE });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.pageState !== PageState.ERROR && (
          <PPEView
            //STATE + HELPER VALUES
            viewState={this.state.viewState}
            validateState={this.state.validateState}
            crudlState={this.state.crudlState}
            menuState={this.state.menuState}
            loading={this.state.pageState === PageState.LOADING}
            updateState={this.updateState}
            refresh={this.setPageData}
            feedback={this.props.feedback}
            receivedFeedback={this.state.receivedFeedback}
            //CARD SPECIFIC PROPS
            editedObject={this.state.editedPPE}
            ppeTypeOptions={this.state.ppeTypeOptions}
            updateEdited={this.updateBean}
            validateChanges={this.validateChanges}
            discardChanges={this.discardChanges}
            checkRequiredFields={this.checkRequiredFields}
            crudlExecute={this.executeCrudl}
            sidebarWidth={"75%"}
            //TABLE SPECIFIC PROPS
            crudlControl={this.executeSetup}
            ppeList={this.state.ppeList}
            expandedRows={this.state.expandedRows}
            tableColumns={this.state.tableColumns}
            columnOptions={this.state.columnOptions}
            columnToggle={this.columnToggle}
            tableReference={this.state.tableReference}
            rowCount={this.state.rowCount}
            settingsToggle={this.state.settingsToggle}
            //
            steps={this.state.steps}
            activeStep={this.state.activeStep}
            discoveredViaOptions={this.state.discoveredViaOptions}
            countyOptions={this.state.countyOptions}
            sectorOptions={this.state.sectorOptions}
            demographicAreaOptions={this.state.demographicAreaOptions}
            updatePPEBean={this.updatePPEBean}
            ppeBeanList={this.state.componentList}
            addProductView={this.state.addProductView}
            updateProductBean={this.updateProductBean}
            productBean={this.state.productBean}
            addProductBean={this.addProductBean}
            businessType={this.state.businessType}
            runRegister={this.runRegister}
            registeredSuccessfully={this.state.registeredSuccessfully}
            registrationFailed={this.state.registrationFailed}
            firebaseMessage={this.state.firebaseMessage}
          />
        )}
      </React.Fragment>
    );
  }
}
