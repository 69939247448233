import React from "react";
import { ProductCard } from "./UI/ProductCard";
import { ProductBatch } from "./UI/ProductBatch";
import { ProductBatchSummary } from "./UI/ProductBatchSummary";
import { ProductTable } from "./UI/ProductTable";
import {
  ViewState,
  ValidateState,
  CrudlStatus,
  ViewType,
} from "../../common/config/AppConstants";
import * as pr from "../../common/PrimeComponents";
import * as format from "../../common/FormatFunctions";
import { ApplicationProvider } from "../Application/ApplicationProvider";
import { MVVMValidateDialog } from "../Common/MVVMValidateDialog";
import { ImageViewer } from "../Common/ImageViewer";
import { FileViewer } from "../Common/FileViewer";
import { Input, InputType, LabelPosition, UpdateType } from "../Common/Input";

export class ProductView extends React.Component {
  tutorialVideo = (title, source) => {
    return (
      <pr.Dialog
        header={title}
        footer={
          <pr.Button
            className="secondary-btn"
            label="Close"
            icon="pi pi-times"
            onClick={() => {
              this.props.updateState("showTutorial", false);
            }}
            style={{
              float: "right",
            }}
          />
        }
        visible={true}
        onHide={() => {
          this.props.updateState("showTutorial", false);
        }}
        modal={true}
        blockScroll={true}
        style={{ width: "80vw" }}
      >
        {/* <video width="100%" height="98%" controls>
          <source src={source} type="video/webm" />
          Your browser does not support video elements, please update to the
          latest version
        </video> */}
        <div>
          <center>
            <iframe
              src={source}
              allowFullScreen
              style={{
                position: "relative",
                top: 0,
                left: 0,
              }}
            ></iframe>
          </center>
        </div>
      </pr.Dialog>
    );
  };
  render() {
    return (
      <div
        className="dashboard"
        style={this.props.loading === true ? { pointerEvents: "none" } : {}}
      >
        {this.props.showTutorial &&
          this.props.viewType === ViewType.MY_PRODUCT &&
          this.tutorialVideo(
            "My Products Tutorial",
            // "/assets/layout/images/council/my-products.webm"
            "https://player.vimeo.com/video/582850361?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          )}
        {this.props.showTutorial &&
          this.props.viewType === ViewType.MY_REQUIREMENT &&
          this.tutorialVideo(
            "My Requirements Tutorial",
            // "/assets/layout/images/council/my-requirements.webm"
            "https://player.vimeo.com/video/582850415?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          )}
        {this.props.showTutorial &&
          this.props.viewType === ViewType.BUYER &&
          this.tutorialVideo(
            "Buyer Requirements Tutorial",
            // "/assets/layout/images/council/buyer-requirements.webm"
            "https://player.vimeo.com/video/582864768?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          )}
        {this.props.showTutorial &&
          this.props.viewType === ViewType.SUPPLIER &&
          this.tutorialVideo(
            "Supplier Products & Services Tutorial",
            // "/assets/layout/images/council/supplier-products-and-services.webm"
            "https://player.vimeo.com/video/582850491?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          )}
        {!this.props.batchUpload && !this.props.displayBatchSummary && (
          <ProductTable
            //CUSTOMISATION
            tableReference={this.props.tableReference}
            tableColumns={this.props.tableColumns}
            columnOptions={this.props.columnOptions}
            columnToggle={this.props.columnToggle}
            expandedRows={this.props.expandedRows}
            settingsToggle={this.props.settingsToggle}
            //
            updateState={this.props.updateState}
            productList={this.props.productList}
            crudlControl={this.props.crudlControl}
            validateState={this.props.validateState}
            menuState={this.props.menuState}
            crudlState={this.props.crudlState}
            loading={this.props.loading}
            refresh={this.props.refresh}
            viewType={this.props.viewType}
            editedObject={this.props.editedObject}
            productDetailsView={this.props.productDetailsView}
            globalFilter={this.props.globalFilter}
            setUpApplication={this.props.setUpApplication}
            toggleApplication={this.props.toggleApplication}
            dashboardType={this.props.dashboardType}
            productFilterListEmpty={this.props.productFilterListEmpty}
            updateStateFromProps={this.props.updateStateFromProps}
          />
        )}
        {this.props.toggleApplication && (
          <ApplicationProvider
            crudlState={CrudlStatus.CREATE}
            viewType={ViewType.CONTACT}
            editedObjectFromProps={this.props.productToContact} //product to contact about
            updateStateFromProps={this.props.updateState} // update state variable toggleApplication
            sidebarWidth={"60%"}
            dashboardType={this.props.dashboardType}
          />
        )}
        {this.props.viewState === ViewState.CARD && (
          <React.Fragment>
            <pr.Sidebar
              visible={true}
              position="right"
              fullScreen={this.props.sidebarWidth === "100%" ? true : false}
              onHide={() => {
                this.props.validateChanges();
              }}
              baseZIndex={10000000000}
              style={{
                width: this.props.sidebarWidth,
                zIndex: 10000000000,
              }}
              icons={() => (
                <div
                  className="p-col-12"
                  style={{
                    float: "left",
                    paddingLeft: "3rem",
                    fontSize: "initial",
                  }}
                >
                  <div className="p-grid">
                    <div className="p-col-6">
                      <label className="header-label">
                        {format.capitalize(this.props.crudlState.display) +
                          " Product"}
                      </label>
                    </div>
                  </div>
                </div>
              )}
            >
              <MVVMValidateDialog
                header="Product"
                isVisible={this.props.validateState === ValidateState.CONFIRM}
                visibleField="validateState"
                updateVisibility={this.props.updateState}
                runConfirm={this.props.discardChanges}
                message={"Discard Changes?"}
              />
              <MVVMValidateDialog
                header="Delete Product"
                isVisible={this.props.validateState === ValidateState.DELETE}
                visibleField="validateState"
                updateVisibility={this.props.updateState}
                message={" Are you sure you want to delete?"}
                type={"CRUDL"}
                crudlExecute={this.props.crudlExecute}
                crudlState={this.props.crudlState}
              />
              <ProductCard
                loading={this.props.loading}
                feedback={this.props.feedback}
                receivedFeedback={this.props.receivedFeedback}
                crudlState={this.props.crudlState}
                crudlControl={this.props.crudlControl}
                updateValue={this.props.updateEdited}
                updateState={this.props.updateState}
                editedObject={this.props.editedObject}
                checkRequiredFields={this.props.checkRequiredFields}
                discardChanges={this.props.discardChanges}
                validateState={this.props.validateState}
                validateChanges={this.props.validateChanges}
                crudlExecute={this.props.crudlExecute}
                viewType={this.props.viewType}
                componentOptions={this.props.componentOptions}
              />
            </pr.Sidebar>
            {/* <div className="custom-overlay" /> */}
          </React.Fragment>
        )}
        {this.props.toggleDocumentUpload && this.props.batchUpload === true && (
          <pr.Sidebar
            className={"docImageSidebar"}
            visible={true}
            position="right"
            baseZIndex={100000000000}
            style={{
              width: "50%",
            }}
            onHide={(e) => {
              this.props.updateState("toggleDocumentUpload", false);
              this.props.updateState("toggleSidebarObject", null);
              this.props.updateState("toggleDocumentField", null);
            }}
          >
            <FileViewer
              fileList={
                this.props.toggleSidebarObject[this.props.toggleDocumentField]
              }
              updateType={"CUSTOM"}
              updateValue={(localList) => {
                this.props.onProductEditorValueChange(
                  this.props.toggleSidebarObject.sequenceId - 1,
                  this.props.toggleDocumentField,
                  localList,
                  true
                );
              }}
              field={this.props.toggleDocumentField}
            />
          </pr.Sidebar>
        )}
        {this.props.toggleImageUpload && this.props.batchUpload === true && (
          <pr.Sidebar
            className={"docImageSidebar"}
            visible={true}
            position="right"
            baseZIndex={100000000000}
            style={{
              width: "50%",
            }}
            onHide={(e) => {
              this.props.updateState("toggleImageUpload", false);
              this.props.updateState("toggleSidebarObject", null);
              this.props.updateState("toggleImageField", null);
            }}
          >
            <ImageViewer
              imageList={
                this.props.toggleSidebarObject[this.props.toggleImageField]
              }
              updateType={"CUSTOM"}
              updateValue={(imageUri) => {
                this.props.onProductEditorValueChange(
                  this.props.toggleSidebarObject.sequenceId - 1,
                  this.props.toggleImageField,
                  imageUri,
                  true
                );
              }}
              field={this.props.toggleImageField}
              productLogo={this.props.toggleSidebarObject.productLogo}
              toggleProductLogo={true}
              updateLogoType={"CUSTOM"}
              updateValueProductLogo={(productLogo) => {
                this.props.onProductEditorValueChange(
                  this.props.toggleSidebarObject.sequenceId - 1,
                  "productLogo",
                  productLogo,
                  true
                );
              }}
            />
          </pr.Sidebar>
        )}
        {this.props.toggleNotesUpload && this.props.batchUpload === true && (
          <pr.Dialog
            className={"docImageSidebar"}
            visible={this.props.toggleNotesUpload}
            modal={true}
            blockScroll={true}
            style={{ width: "80vw" }}
            onHide={(e) => {
              this.props.updateState("toggleNotesUpload", false);
              this.props.updateState("toggleSidebarObject", null);
              this.props.updateState("toggleNotesField", null);
            }}
          >
            <Input
              id={this.props.toggleNotesField}
              labelDisplay={"Additional Details"}
              labelDisplayPosition={LabelPosition.TOP}
              type={InputType.NOTES}
              value={
                this.props.toggleSidebarObject[this.props.toggleNotesField]
              }
              onChangeType={UpdateType.CUSTOM}
              onChange={(notes) => {
                this.props.onProductEditorValueChange(
                  this.props.toggleSidebarObject.sequenceId - 1,
                  this.props.toggleNotesField,
                  notes.htmlValue,
                  true
                );
              }}
              field={this.props.toggleNotesField}
              visible={true}
              style={{ height: "100%" }}
            />
          </pr.Dialog>
        )}
        {this.props.batchUpload === true && (
          <React.Fragment>
            <pr.Sidebar
              id="BatchSidebar"
              visible={true}
              position="right"
              fullScreen={this.props.sidebarWidth === "100%" ? true : false}
              onHide={() => {
                this.props.validateChangesBatch();
              }}
              baseZIndex={10000000000}
              style={{
                width: this.props.sidebarWidth,
              }}
              icons={() => (
                <div
                  className="p-col-12"
                  style={{
                    float: "left",
                    paddingLeft: "3rem",
                    fontSize: "initial",
                  }}
                >
                  <div className="p-grid">
                    <div className="p-col-6">
                      <label className="header-label">
                        {"Upload Multiple Products"}
                      </label>
                    </div>
                  </div>
                </div>
              )}
            >
              <MVVMValidateDialog
                header="Multiple Products"
                isVisible={this.props.validateState === ValidateState.CONFIRM}
                visibleField="validateState"
                updateVisibility={this.props.updateState}
                runConfirm={this.props.discardChangesBatch}
                message={
                  "Discard Changes? Any data you have entered will be lost"
                }
              />
              <ProductBatch
                loading={this.props.loading}
                crudlState={this.props.crudlState}
                crudlControl={this.props.crudlControl}
                refresh={this.props.refresh}
                viewType={this.props.viewType}
                updateState={this.props.updateState}
                validateChanges={this.props.validateChangesBatch}
                batchList={this.props.batchList}
                tableColumns={this.props.tableColumnsBatch}
                columnOptions={this.props.columnOptionsBatch}
                // options
                supplierOptions={this.props.supplierOptions}
                clientOptions={this.props.clientOptions}
                sortBatchProducts={this.props.sortBatchProducts}
                checkRequiredFields={this.props.checkRequiredFieldsBatch}
                batchListEmptyRow={this.props.batchListEmptyRow}
                finishBatchUpload={this.finishBatchUpload}
                onProductEditorValueChange={
                  this.props.onProductEditorValueChange
                }
                componentOptions={this.props.componentOptions}
                componentList={this.props.componentList}
                toggleImageDisplay={this.props.toggleImageDisplay}
                toggleDocumentDisplay={this.props.toggleDocumentDisplay}
                toggleNotesDisplay={this.props.toggleNotesDisplay}
              />
            </pr.Sidebar>
          </React.Fragment>
        )}

        {this.props.displayBatchSummary === true && (
          <React.Fragment>
            <pr.Sidebar
              visible={true}
              position="right"
              fullScreen={this.props.sidebarWidth === "100%" ? true : false}
              onHide={() => {
                if (
                  this.props.batchProductsCreate === true &&
                  this.props.productFailure !== true
                ) {
                  this.props.finishBatchUpload();
                } else if (
                  this.props.batchProductsCreate === true &&
                  this.props.productFailure === true
                ) {
                  this.props.setUpBatchListOfFailures();
                } else {
                  this.props.updateState("displayBatchSummary", false);
                  this.props.updateState("batchUpload", true);
                }
              }}
              baseZIndex={1000000000000}
              style={{
                width: this.props.sidebarWidth,
              }}
              icons={() => (
                <div
                  className="p-col-12"
                  style={{
                    float: "left",
                    paddingLeft: "3rem",
                    fontSize: "initial",
                  }}
                >
                  <div className="p-grid">
                    <div className="p-col-6">
                      <label className="header-label">
                        {"Multiple Product Summary"}
                      </label>
                    </div>
                  </div>
                </div>
              )}
            >
              <ProductBatchSummary
                loading={this.props.loading}
                crudlState={this.props.crudlState}
                crudlControl={this.props.crudlControl}
                refresh={this.props.refresh}
                viewType={this.props.viewType}
                updateState={this.props.updateState}
                validateChanges={this.props.validateChangesBatch}
                batchList={this.props.batchList}
                sortedBatchList={this.props.sortedBatchList}
                tableColumns={this.props.tableColumnsBatch}
                columnOptions={this.props.columnOptionsBatch}
                checkRequiredFields={this.props.checkRequiredFields}
                // options
                createBatchProducts={this.props.createBatchProducts}
                batchProductsCreate={this.props.batchProductsCreate}
                productFailure={this.props.productFailure}
                setUpBatchListOfFailures={this.props.setUpBatchListOfFailures}
                finishBatchUpload={this.props.finishBatchUpload}
                expandedRows={this.props.expandedRows}
              />
            </pr.Sidebar>
          </React.Fragment>
        )}
      </div>
    );
  }
}
