import { BaseViewModel } from "../../common/BaseViewModel";
import * as Format from "../../common/FormatFunctions";
import { CloudService } from "../../common/CloudService/ClientLibsReference";
import * as ApiService from "../../common/CloudService/ApiService";
import {
  CrudlStatus,
  ViewState,
  BusinessType,
  ComponentType,
} from "../../common/config/AppConstants";

export class ProductViewModel extends BaseViewModel {
  getProducts = async (type, codeTag) => {
    //CRITICAL - ERROR + EMPTY MESSAGE
    var productResponse = await this.getProductListByCodeTagAndType(
      type,
      codeTag
    );
    if (
      Format.isNotNull(productResponse.errorCode) &&
      productResponse.errorCode === 0
    ) {
      if (Format.isNotNull(productResponse.productList)) {
        this.updateState("productList", productResponse.productList);
        this.logger.info(productResponse.productList);
        return productResponse.productList;
      } else {
        this.showEmpty("Product", ViewState.TABLE);
      }
    } else {
      this.showError("Product", CrudlStatus.LIST, ViewState.TABLE);
    }
  };

  getProductsReturned = async (type, codeTag) => {
    //CRITICAL - ERROR + EMPTY MESSAGE
    var productResponse = await this.getProductListByCodeTagAndType(
      type,
      codeTag
    );
    if (
      Format.isNotNull(productResponse.errorCode) &&
      productResponse.errorCode === 0
    ) {
      if (Format.isNotNull(productResponse.productList)) {
        this.logger.info(productResponse.productList);
        return productResponse.productList;
      } else {
        this.showEmpty("Product", ViewState.TABLE);
      }
    } else {
      this.showError("Product", CrudlStatus.LIST, ViewState.TABLE);
    }
  };

  getProductsByCodeTag = async (codeTag) => {
    //CRITICAL - ERROR + EMPTY MESSAGE
    var productResponse = await this.getProductListByCodeTag(codeTag);
    if (
      Format.isNotNull(productResponse.errorCode) &&
      productResponse.errorCode === 0
    ) {
      if (Format.isNotNull(productResponse.productList)) {
        this.updateState("productList", productResponse.productList);
        return productResponse.productList;
      } else {
        this.showEmpty("Product", ViewState.TABLE);
      }
    } else {
      this.showError("Product", CrudlStatus.LIST, ViewState.TABLE);
    }
  };
  getProductsByType = async (type) => {
    var productResponse = await this.getProductListByType(type);
    if (
      Format.isNotNull(productResponse.errorCode) &&
      productResponse.errorCode === 0
    ) {
      if (Format.isNotNull(productResponse.productList)) {
        this.updateState("productList", productResponse.productList);
        return productResponse.productList;
      } else {
        this.showEmpty("Product", ViewState.TABLE);
      }
    } else {
      this.showError("Product", CrudlStatus.LIST, ViewState.TABLE);
    }
  };
  getProductsByComponentList = async (componentList, type) => {
    //CRITICAL - ERROR + EMPTY MESSAGE
    var productResponse = await this.getProductListByComponentList(
      componentList,
      type
    );
    if (
      Format.isNotNull(productResponse.errorCode) &&
      productResponse.errorCode === 0
    ) {
      if (Format.isNotNull(productResponse.productList)) {
        this.updateState("productList", productResponse.productList);
        return productResponse.productList;
      } else {
        this.showEmpty("Product", ViewState.TABLE);
      }
    } else {
      this.showError("Product", CrudlStatus.LIST, ViewState.TABLE);
    }
  };

  reloadDataAfterExecute = (
    products,
    apiResponse,
    type,
    externalStateValues
  ) => {
    var updatedProductList = [];
    if (type === CrudlStatus.CREATE) {
      updatedProductList = this.reloadAfterCreate(
        products,
        apiResponse,
        externalStateValues
      );
    }
    if (type === CrudlStatus.UPDATE) {
      updatedProductList = this.reloadAfterUpdate(
        products,
        apiResponse,
        externalStateValues
      );
    }
    if (type === CrudlStatus.DELETE) {
      updatedProductList = this.reloadAfterDelete(
        products,
        apiResponse,
        externalStateValues
      );
    }

    this.updateState("productList", updatedProductList);
  };

  reloadAfterCreate = (productList, apiResponse, externalStateValues) => {
    if (productList === undefined || productList === null) {
      productList = [];
    }
    var productBean = externalStateValues.productBean;
    productBean.id = apiResponse.id;
    productBean.lastUpdatedDateTime = apiResponse.lastUpdatedDateTime;

    productList.push(productBean);

    return productList;
  };

  reloadAfterUpdate = (productList, apiResponse, externalStateValues) => {
    var i = 0;
    productList.forEach((e) => {
      if (apiResponse.id === e.id) {
        productList[i] = externalStateValues.productBean;
        productList[i].lastUpdatedDateTime = apiResponse.lastUpdatedDateTime;
      }
      i++;
    });

    return productList;
  };

  reloadAfterDelete = (productList, apiResponse, externalStateValues) => {
    var i = 0;
    productList.forEach((e) => {
      //DELETE
      if (apiResponse.id === e.id) {
        productList.splice(i, 1);
      }
      i++;
    });

    return productList;
  };
  executeCRUDLAction = async (crudlStatus, stateVariables) => {
    let productRequest = CloudService.ProductRequest.constructFromObject(
      stateVariables.productBean
    );
    if (crudlStatus === CrudlStatus.CREATE) {
      return await this.createProduct(productRequest, stateVariables);
    }
    if (crudlStatus === CrudlStatus.UPDATE) {
      return await this.updateProduct(productRequest, stateVariables);
    }
    if (crudlStatus === CrudlStatus.DELETE) {
      return await this.deleteProduct(productRequest);
    }
  };

  createProduct = async (productRequest, stateVariables) => {
    if (!Format.isNotNull(productRequest.codeTag)) {
      productRequest.codeTag = this.codeTag;
    }
    productRequest.userId = this.userId;

    let productResponse = await ApiService.productCreate(productRequest);
    if (productResponse.errorCode === 0) {
      this.showSuccess("Product", CrudlStatus.CREATE, stateVariables.viewState);
    } else {
      this.showError("Product", CrudlStatus.CREATE, ViewState.CARD);
    }

    return productResponse;
  };

  deleteProduct = async (productRequest) => {
    //RESET PRODUCT REQUEST
    var request = {};
    if (Format.isNotNull(productRequest.codeTag)) {
      request.codeTag = productRequest.codeTag;
    } else {
      request.codeTag = this.codeTag;
    }
    request.userId = this.userId;
    request.id = productRequest.id;
    request.lastUpdatedDateTime = productRequest.lastUpdatedDateTime;

    let productResponse = await ApiService.productDelete(request);
    if (productResponse.errorCode === 0) {
      this.showSuccess("Product", CrudlStatus.DELETE, ViewState.TABLE);
    } else {
      this.showError("Product", CrudlStatus.DELETE, ViewState.CARD);
    }

    return productResponse;
  };

  updateProduct = async (productRequest, stateVariables) => {
    if (!Format.isNotNull(productRequest.codeTag)) {
      productRequest.codeTag = this.codeTag;
    }
    productRequest.userId = this.userId;

    let productResponse = await ApiService.productUpdate(productRequest);
    if (productResponse.errorCode === 0) {
      this.showSuccess("Product", CrudlStatus.UPDATE, stateVariables.viewState);
    } else {
      this.showError("Product", CrudlStatus.UPDATE, ViewState.CARD);
    }

    return productResponse;
  };

  getComponents = async () => {
    var componentResponse = await this.componentGetByCodeTag(
      ComponentType.MANUFACTURE
    );
    if (
      Format.isNotNull(componentResponse.errorCode) &&
      componentResponse.errorCode === 0
    ) {
      if (Format.isNotNull(componentResponse.componentList)) {
        this.updateState("componentList", componentResponse.componentList);
        return componentResponse.componentList;
      } else {
        this.logger.info("Component is empty");
      }
    } else {
      this.logger.info("Component api failed");
    }
  };

  getProfile = async (codeTag) => {
    let request = {};
    request.codeTag = this.codeTag;
    if (Format.isNotNull(codeTag)) {
      request.codeTag = codeTag;
    }

    var profileResponse = await this.profileGetByCodeTag(request);
    if (
      Format.isNotNull(profileResponse.errorCode) &&
      profileResponse.errorCode === 0
    ) {
      if (Format.isNotNull(profileResponse.profileList)) {
        let businessComponentList = [];
        profileResponse.profileList.forEach((element) => {
          if (
            element.type === BusinessType.BUYER ||
            element.type === BusinessType.SUPPLIER ||
            element.type === BusinessType.BUYERSUPPLIER
          ) {
            businessComponentList = element.componentList;
          }
        });

        this.logger.info("Business Component List", businessComponentList);

        return businessComponentList;
      } else {
        this.logger.info("Profile is empty");
      }
    } else {
      this.logger.info("Profile api failed");
    }
  };
}
