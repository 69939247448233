import React, { Component } from "react";

export class PPEFooterImage extends Component {
  render() {
    return (
      <div className="footer">
        <div className="clearfix">
          <span className="footer-text-left">{/*Empty div for spacing*/}</span>
          <span className="footer-text-right">
            <img
              src="/assets/layout/images/council/INI_corporate_isolated.png"
              alt=""
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                width: "4em",
                height: "3em",
              }}
            />
          </span>

          <span className="footer-text-right">
            <img
              src="/assets/layout/images/council/logo-black.png"
              alt=""
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                width: "8em",
                height: "3em",
              }}
            />
            <div>Copyright © 2020 All Rights Reserved by BuySupplyNI.</div>

            <a
              style={{ display: "table-cell", float: "right" }}
              href={"https://bpmbuild.com/"}
              target="_blank"
              rel="noreferrer"
            >
              Built by BPMBuild.
            </a>
          </span>
        </div>
      </div>
    );
  }
}
