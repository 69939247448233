import React from "react";
//PrimeReact Components
import * as pr from "../../../common/PrimeComponents";
import {
  CrudlStatus,
  ValidateState,
} from "../../../common/config/AppConstants";
import * as format from "../../../common/FormatFunctions";
import { RequirementDisplay } from "./RequirementDisplay";
import { MVVMValidateDialog } from "../../Common/MVVMValidateDialog";
import ReactHtmlParser from "react-html-parser";
export class RequirementTable extends React.Component {
  export = () => {
    this.dt.exportCSV();
  };

  getRequirementColumns = () => {
    console.log(this.props);
    var allColumns = [];
    if (format.isNotNull(this.props.tableColumns)) {
      let columns = this.props.tableColumns.map((col, i) => {
        return (
          <pr.Column
            key={col.field}
            field={col.field}
            header={col.header}
            sortable={col.sortable}
            filter={col.filter}
            filterMatchMode={col.filterMatchMode}
            style={col.style}
          />
        );
      });

      columns.forEach((e) => {
        allColumns.push(e);
      });
    }
    return allColumns;
  };

  render() {
    return (
      <div className="p-grid bpm">
        <div className="p-col-12">
          <div className="content-section">
            <div className="feature-intro">
              <h1>{"My Requirements"}</h1>
              <p>{"A List of Products/Services I currently Require"}</p>
            </div>
          </div>
        </div>
        <MVVMValidateDialog
          header="Delete Requirement"
          isVisible={this.props.validateState === ValidateState.DELETE}
          visibleField="validateState"
          updateVisibility={this.props.updateState}
          message={" Are you sure you want to delete?"}
          type={"CRUDL"}
          crudlExecute={this.props.crudlExecute}
          crudlState={this.props.crudlState}
        />
        <pr.Dialog
          header={"Product Details"}
          style={{ width: "90%" }}
          footer={
            <div className="p-grid">
              <div className="p-col-12">
                <pr.Button
                  className="blk mrgn flt-r"
                  label="Close"
                  icon="pi-md-check"
                  onClick={() => {
                    this.props.updateState("productDetailsView", false);
                  }}
                />
              </div>
            </div>
          }
          visible={this.props.productDetailsView}
          onHide={() => {
            this.props.updateState("productDetailsView", false);
          }}
          modal={true}
        >
          <div className="p-grid">
            <div className="p-col-10">
              {ReactHtmlParser(this.props.productDetails)}
            </div>
          </div>
        </pr.Dialog>
        {/* <div className="p-col-6">
          <pr.Button
            label={"Add New"}
            style={{
              float: "right",
            }}
            onClick={() => {
              this.props.crudlControl(CrudlStatus.CREATE);
            }}
          />
        </div> */}
        <div className="p-col-12">
          <pr.DataTable
            ref={(el) => {
              this.dt = el;
            }}
            value={this.props.requirementList}
            emptyMessage="No Requirements"
            expandedRows={this.props.expandedRows}
            onRowToggle={(e) => this.props.updateState("expandedRows", e.data)}
            rowExpansionRequirement={this.getDisplayCard}
            rows={Infinity}
            autoLayout={true}
            paginator={false}
            alwaysShowPaginator={false}
            responsive={true}
            loading={this.props.loading}
            scrollable={true}
            scrollHeight="30vh"
          >
            <pr.Column
              key={"type"}
              field={"componentNameToDisplay"}
              header={"Type"}
              sortable={true}
              filter={true}
              filterMatchMode={"contains"}
              style={{ overflow: "auto" }}
            ></pr.Column>
            <pr.Column
              header="Product/Service Details"
              body={this.moreDetails}
            ></pr.Column>
            <pr.Column
              key={"dateRequired"}
              body={this.dateRequired}
              header={"Date Required"}
            ></pr.Column>
            <pr.Column
              key={"location"}
              field={"location"}
              header={"Location"}
              sortable={true}
              filter={true}
              filterMatchMode={"contains"}
              style={{ overflow: "auto" }}
            ></pr.Column>
            <pr.Column
              key={"productStatus"}
              field={"productStatus"}
              header={"Status"}
              sortable={true}
              filter={true}
              filterMatchMode={"contains"}
              style={{ overflow: "auto" }}
            ></pr.Column>
            <pr.Column body={this.getEditDeleteTemplate}></pr.Column>
          </pr.DataTable>
        </div>
      </div>
    );
  }

  dateRequired = (rowData, column) => {
    if (format.isNotNull(rowData.dateRequired)) {
      return (
        <div className="p-grid">
          <div className="p-col-12">
            {format.formatStringDate(rowData.dateRequired.toString())}
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  };
  moreDetails = (rowData, column) => {
    return (
      <div className="p-grid">
        <div className="p-col-12">
          <pr.Button
            type="button"
            label={"View Details"}
            onClick={(e) => {
              this.props.updateState(
                "productDetails",
                rowData.longDescriptionText
              );
              this.props.updateState("productDetailsView", true);
            }}
            style={{}}
          ></pr.Button>
        </div>
      </div>
    );
  };
  getEditDeleteTemplate = (rowData, column) => {
    return (
      <div className="p-grid">
        <div className="p-col-12">
          <pr.Button
            type="button"
            icon="pi pi-pencil"
            onClick={(e) =>
              this.props.crudlControl(CrudlStatus.UPDATE, rowData)
            }
            style={{}}
          ></pr.Button>
          <pr.Button
            type="button"
            icon="pi pi-trash"
            onClick={(e) => {
              this.props.crudlControl(CrudlStatus.DELETE, rowData);
            }}
            style={{ backgroundColor: "red" }}
          ></pr.Button>
        </div>
      </div>
    );
  };
  getDisplayCard = (data) => {
    return <RequirementDisplay data={data} onClick={this.props.crudlControl} />;
  };
}
