import React from "react";
//PrimeReact Components
import * as pr from "../../common/PrimeComponents";
import * as format from "../../common/FormatFunctions";
import * as feedback from "../../common/Feedback/Feedback";
import { LoadScreen } from "../Common/LoadScreen";
import { ScenarioViewModel } from "./ScenarioViewModel";

export class Scenario1 extends React.Component {
  constructor() {
    super();
    this.state = {
      editedObject: { buyer: false, supplier: false },
      componentList: [],
    };

    this.viewModel = new ScenarioViewModel();
  }

  componentDidMount = async () => {
    await this.initialDataRender();
  };

  componentDidCatch = (error, info) => {
    console.log("Error", error);
    console.log("Error Info", info);
  };

  initialDataRender = async () => {
    await this.setViewModel();
  };

  setViewModel = async () => {
    try {
      console.log("Getting Components");
      var componentList = await this.viewModel.componentGetByType();

      this.setState({ componentList: componentList });
    } catch (error) {}
  };

  updateBean = async (field, value) => {
    try {
      await this.setState((prevState) => ({
        editedObject: {
          // object that we want to update
          ...prevState.editedObject, // keep all other key-value pairs
          [field]: value,
        },
      }));
    } catch (error) {}
  };

  updatePPEBean = async (id, field, value) => {
    var ppeBeanlist = format.deepCopy(this.state.componentList);

    if (format.isNotNull(ppeBeanlist)) {
      ppeBeanlist.forEach((element) => {
        if (element.id === id) {
          element[field] = value;
        }
      });
    }

    this.setState({ componentList: ppeBeanlist });
  };

  runRegister = async () => {
    this.setState({ loading: true });

    await this.viewModel.createProfile(
      localStorage.getItem("clientId"),
      localStorage.getItem("userId"),
      this.state.componentList,
      this.state.editedObject.supplier,
      this.state.editedObject.buyer,
      this.state.editedObject.certifiedProducts,
      this.state.editedObject.certifiedProductsDescription
    );

    await this.viewModel.createMatrixItem(
      localStorage.getItem("clientId"),
      localStorage.getItem("userId"),
      this.state.componentList,
      this.state.editedObject.supplier,
      this.state.editedObject.buyer
    );

    feedback.showSuccess(this.growl, "Request");

    var [status, type, profile] = await this.viewModel.getProfile(
      localStorage.getItem("clientId"),
      localStorage.getItem("userId")
    );

    if (status === "Approved") {
      localStorage.setItem("profileStatus", status);
      localStorage.setItem("profileType", "PPE");
      localStorage.setItem("validPPEProfile", profile);
      window.location = "#/Dashboard";
      window.location.reload();
    } else {
      this.setState({ requestSent: true });
    }
  };

  checkRequirements = () => {};

  render() {
    if (this.state.requestSent) {
      return (
        <div className="p-grid dashboard">
          <div className="p-col-12">
            Request has been sent, Please allow a maximum of 48 hours for
            approval
          </div>
          <div className="p-col-12">
            <pr.Button
              className="secondary-btn"
              label="Close"
              icon="pi pi-check"
              onClick={(e) => {
                this.props.close();
              }}
              style={{ float: "right" }}
            />
          </div>
        </div>
      );
    }
    return (
      <pr.ScrollPanel
        className="scrl"
        ref={(el) => (this.layoutMenuScroller = el)}
      >
        <pr.Messages
          style={{ textAlign: "left" }}
          ref={(el) => (this.growl = el)}
        />
        <div className="p-grid dashboard">
          {this.state.loading === true && <LoadScreen />}
          {this.state.loading !== true && (
            <React.Fragment>
              <div className="p-grid dashboard">
                <div className="p-col-12">
                  <h2>{"Register for PPE"}</h2>
                  <div className="p-col-12">
                    <div className="p-col-12">
                      <div className="p-col-3">
                        <label
                          htmlFor="businessDescription"
                          className="custom-label"
                        >
                          I would like to supply products and services
                        </label>
                      </div>
                      <div className="p-col-9">
                        <pr.Checkbox
                          id="supplier"
                          rows={5}
                          checked={this.state.editedObject.supplier || false}
                          onChange={(e) => {
                            this.updateBean("supplier", e.checked);
                          }}
                          style={{ resize: "none" }}
                        />
                      </div>
                    </div>
                    <div className="p-col-12">
                      <div className="p-col-3">
                        <label
                          htmlFor="businessDescription"
                          className="custom-label"
                        >
                          I would like to buy products and services
                        </label>
                      </div>
                      <div className="p-col-9">
                        <pr.Checkbox
                          id="buyer"
                          rows={5}
                          checked={this.state.editedObject.buyer || false}
                          onChange={(e) => {
                            this.updateBean("buyer", e.checked);
                          }}
                          style={{ resize: "none" }}
                        />
                      </div>
                    </div>
                  </div>

                  {this.state.editedObject.supplier === true && (
                    <div className="p-col-12 card">
                      <div className="p-col-12">
                        <label
                          htmlFor="businessDescription"
                          className="custom-label"
                        >
                          Please confirm that the PPE products you can supply
                          are certified to meet UK requirements where
                          applicable.
                        </label>
                      </div>
                      <div className="p-col-12">
                        <div className="p-col-6">
                          <label
                            htmlFor="businessDescription"
                            className="custom-label"
                          >
                            Yes , My products meet UK requirements
                          </label>
                        </div>
                        <div className="p-col-6">
                          <pr.Checkbox
                            id="supplier"
                            rows={5}
                            checked={
                              this.state.editedObject.certifiedProducts || false
                            }
                            onChange={(e) => {
                              this.updateBean("certifiedProducts", e.checked);
                            }}
                            style={{ resize: "none" }}
                          />
                        </div>
                      </div>
                      {!this.state.editedObject.certifiedProducts && (
                        <div className="p-col-12">
                          <div className="p-col-12">
                            <label
                              htmlFor="businessName"
                              className="input-label"
                            >
                              If not , can your provide more details
                            </label>
                          </div>
                          <div className="p-col-12">
                            {" "}
                            <pr.InputText
                              id="certifiedProductsDescription"
                              className="required"
                              value={
                                this.state.editedObject
                                  .certifiedProductsDescription || ""
                              }
                              onChange={(e) => {
                                this.updateBean(e.target.id, e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="p-col-12">
                    <pr.Accordion activeIndex={0}>
                      <pr.AccordionTab header="PPE">
                        <div className="p-grid">
                          {this.displayPPEOptions(
                            this.state.componentList,
                            "PPE"
                          )}
                        </div>
                      </pr.AccordionTab>
                    </pr.Accordion>
                    <pr.Accordion activeIndex={0}>
                      <pr.AccordionTab header="Social Distancing Materials">
                        <div className="p-grid">
                          {this.displayPPEOptions(
                            this.state.componentList,
                            "Social Distancing Materials"
                          )}
                        </div>
                      </pr.AccordionTab>
                    </pr.Accordion>
                    <pr.Accordion activeIndex={0}>
                      <pr.AccordionTab header="Other Covid-19 Related Products">
                        <div className="p-grid">
                          {this.displayPPEOptions(
                            this.state.componentList,
                            "Other Covid-19 Related Products"
                          )}
                        </div>
                      </pr.AccordionTab>
                    </pr.Accordion>
                  </div>

                  <div className="p-col-12">
                    <pr.Button
                      className="secondary-btn"
                      label="Register"
                      diabled={this.checkRequirements()}
                      icon="pi pi-check"
                      onClick={(e) => {
                        this.runRegister();
                      }}
                      style={{ float: "right" }}
                    />
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </pr.ScrollPanel>
    );
  }

  displayPPEOptions = (ppeBeanList, heading) => {
    var componentArray = [];
    var index = 0;

    if (format.isNotNull(ppeBeanList)) {
      ppeBeanList.forEach((element) => {
        if (element.header === heading) {
          componentArray[index] = (
            <div className="p-col-12">
              <div className="p-col-4">
                <label htmlFor={element.name} className="input-label">
                  {element.name}
                </label>
              </div>
              <div className="p-col-4">
                <pr.Checkbox
                  id={element.name}
                  checked={element.checked}
                  onChange={(e) => {
                    this.updatePPEBean(element.id, "checked", e.checked);
                  }}
                ></pr.Checkbox>
              </div>
            </div>
          );
          index++;
        }
      });
    }

    return componentArray;
  };

  filterOptions = (list) => {
    var returnList = [];
    if (format.isNotNull(list)) {
      list.forEach((element) => {
        if (element.checked) {
          returnList.push(element);
        }
      });
    }

    return returnList;
  };
}
