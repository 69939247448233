import React from "react";
//PrimeReact Components
import * as pr from "../../../common/PrimeComponents";
import { resetPassword } from "../../../common/helpers/auth";
import {
  CrudlStatus,
  FeedbackState,
  ViewType,
} from "../../../common/config/AppConstants";
import { LoadScreen } from "../../Common/LoadScreen";
import {
  Input,
  InputType,
  LabelPosition,
  UpdateType,
} from "../../Common/Input";

export class UserCard extends React.Component {
  componentDidUpdate = (prevProps) => {
    // Typical usage (don't forget to compare props):
    if (this.props.receivedFeedback !== prevProps.receivedFeedback) {
      switch (this.props.receivedFeedback.state) {
        case FeedbackState.EMPTY:
          this.props.feedback.showEmpty(
            this.growl,
            this.props.receivedFeedback.display
          );
          break;
        case FeedbackState.SUCCESS:
          this.props.feedback.showSuccess(
            this.growl,
            this.props.receivedFeedback.display,
            this.props.receivedFeedback.crudlStatus
          );
          break;
        case FeedbackState.ERROR:
          this.props.feedback.showError(
            this.growl,
            this.props.receivedFeedback.display,
            this.props.receivedFeedback.crudlStatus
          );
          break;
        default:
          break;
      }
    }
  };

  displayInputText = (target, label, validateEmail) => {
    return (
      <div className="p-col-12">
        <span className="md-inputfield form-field">
          <pr.InputText
            id={target}
            className="required"
            value={this.props.editedObject[target] || ""}
            onChange={(e) => {
              this.props.updateValue(target, e.target.value);
            }}
            onBlur={() => {
              this.props.checkEmailAddressAlreadyExists();
            }}
            style={validateEmail ? { textTransform: "lowercase" } : {}}
          />
          <label htmlFor={target} className="input-label">
            {label}
          </label>
        </span>

        {validateEmail && (
          <>
            {this.props.editedObject[target] &&
            (!this.props.editedObject[target].includes("@") ||
              !this.props.editedObject[target].includes(".")) ? (
              <label style={{ color: "red" }}>Incorrect email format</label>
            ) : (
              <label />
            )}
            {this.props.editedObject.userAlreadyExists ? (
              <div>
                <label style={{ color: "red" }}>User already exists</label>
              </div>
            ) : (
              <label />
            )}
          </>
        )}
      </div>
    );
  };

  displayPassword = (target, label, validatePassword) => {
    let showPassword = "showPassword" + target;
    return (
      <div className="p-col-12 p-grid">
        <div className="p-col-10">
          <span className="md-inputfield form-field">
            {this.props.editedObject[showPassword] ? (
              <pr.InputText
                id={target}
                className="required"
                value={this.props.editedObject[target] || ""}
                onChange={(e) => {
                  this.props.updateValue(target, e.target.value);
                }}
              />
            ) : (
              <pr.Password
                id={target}
                value={this.props.editedObject[target] || ""}
                onChange={(e) => {
                  this.props.updateValue(target, e.target.value);
                }}
                feedback={false}
                promptLabel="Strength"
              />
            )}
            <label htmlFor={target} className="imput-label">
              {label}
            </label>
          </span>
          {validatePassword && (
            <>
              {this.props.editedObject[target] &&
                this.props.editedObject[target].length < 7 && (
                  <label style={{ color: "red" }}>
                    Invalid Password Length
                  </label>
                )}
            </>
          )}
        </div>
        <div className="p-col-2">
          <pr.Button
            icon={
              this.props.editedObject[showPassword]
                ? "pi-md-visibility-off"
                : "pi-md-visibility"
            }
            onClick={() => {
              this.props.updateValue(
                showPassword,
                !this.props.editedObject[showPassword]
              );
            }}
          />
        </div>
      </div>
    );
  };

  displayCard = () => {
    return (
      <React.Fragment>
        <div className="scrl">
          <div className="p-grid">
            <div className="p-col-12"></div>
            {this.props.loading === true && <LoadScreen />}
            {this.props.loading !== true && (
              <React.Fragment>
                {this.props.crudlState === CrudlStatus.CREATE ? (
                  <>
                    {this.displayInputText("firstName", "First Name *", false)}
                    {this.displayInputText("surname", "Surname *", false)}
                    {this.displayInputText(
                      "contactTelephone",
                      "Telephone",
                      false
                    )}
                    {this.displayInputText("email", "Email *", true)}
                    {this.displayPassword("password", "Password *", true)}
                    {this.displayPassword(
                      "confirmPassword",
                      "Confirm Password *",
                      false
                    )}
                  </>
                ) : (
                  <>
                    <div className="p-col-12">
                      <Input
                        id={"firstName"}
                        labelDisplay={"First Name"}
                        labelDisplayPosition={LabelPosition.LEFT}
                        required={true}
                        type={InputType.STRING}
                        disabled={this.props.crudlState === CrudlStatus.VIEW}
                        value={this.props.editedObject.firstName}
                        onChangeType={UpdateType.STANDARD}
                        onChange={this.props.updateValue}
                        field={"firstName"}
                        visible={true}
                      />
                    </div>
                    <div className="p-col-12">
                      <Input
                        id={"surname"}
                        labelDisplay={"Surname"}
                        labelDisplayPosition={LabelPosition.LEFT}
                        required={true}
                        type={InputType.STRING}
                        disabled={this.props.crudlState === CrudlStatus.VIEW}
                        value={this.props.editedObject.surname}
                        onChangeType={UpdateType.STANDARD}
                        onChange={this.props.updateValue}
                        field={"surname"}
                        visible={true}
                      />
                    </div>
                    <div className="p-col-12">
                      <Input
                        id={"contactTelephone"}
                        labelDisplay={"Telephone"}
                        labelDisplayPosition={LabelPosition.LEFT}
                        required={false}
                        type={InputType.STRING}
                        disabled={this.props.crudlState === CrudlStatus.VIEW}
                        value={this.props.editedObject.contactTelephone}
                        onChangeType={UpdateType.STANDARD}
                        onChange={this.props.updateValue}
                        field={"contactTelephone"}
                        visible={true}
                      />
                    </div>
                    <div className="p-col-12">
                      <Input
                        id={"email"}
                        labelDisplay={"Email"}
                        labelDisplayPosition={LabelPosition.LEFT}
                        required={true}
                        type={InputType.STRING}
                        disabled={this.props.crudlState === CrudlStatus.VIEW}
                        value={this.props.editedObject.email}
                        onChangeType={UpdateType.STANDARD}
                        onChange={this.props.updateValue}
                        field={"email"}
                        visible={true}
                        style={{ textTransform: "lowercase" }}
                      />
                    </div>
                    <div className="p-col-6">
                      <pr.Button
                        label="Reset Password"
                        onClick={() => {
                          this.growl.clear();
                          resetPassword(this.props.editedObject.email)
                            .then(() => {
                              this.growl.show({
                                severity: "success",
                                summary: <br />,
                                detail: "Success: Password reset email sent",
                                life: 3000,
                              });
                            })
                            .catch((error) => {
                              this.growl.show({
                                severity: "error",
                                summary: <br />,
                                detail: error.toString(),
                                life: 3000,
                              });
                            });
                        }}
                      />
                    </div>
                  </>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
        <div className="sidebar-footer">
          <pr.Button
            className="mrgn flt-l"
            label="Cancel"
            icon="pi-md-close"
            onClick={() => {
              this.props.validateChanges();
            }}
          />
          {this.props.crudlState !== CrudlStatus.CREATE &&
          this.props.crudlState !== CrudlStatus.VIEW ? (
            <pr.Button
              className="mrgn flt-r"
              label="Delete"
              icon={
                this.props.loading ? "pi-spin pi-md-autorenew" : "pi-md-delete"
              }
              onClick={() => {
                this.props.crudlControl(
                  CrudlStatus.DELETE,
                  this.props.editedObject
                );
              }}
            />
          ) : (
            <label />
          )}
          {this.props.crudlState !== CrudlStatus.VIEW ? (
            <pr.Button
              className="mrgn flt-r"
              disabled={this.props.checkRequiredFields()}
              label="Save"
              icon={
                this.props.loading ? "pi-spin pi-md-autorenew" : "pi-md-save"
              }
              onClick={() => {
                this.props.crudlExecute(true);
              }}
            />
          ) : (
            <label />
          )}
          {this.props.crudlState !== CrudlStatus.VIEW ? (
            <pr.Button
              className="mrgn flt-r"
              disabled={this.props.checkRequiredFields()}
              label="Save and Close"
              icon={
                this.props.loading
                  ? "pi-spin pi-md-autorenew"
                  : "pi-md-tab-unselected"
              }
              onClick={() => {
                this.props.crudlExecute(false);
              }}
            />
          ) : (
            <label />
          )}
        </div>
      </React.Fragment>
    );
  };

  displayRegister = () => {
    console.log(this.props.editedObject.conditionsSigned);
    console.log(this.props.editedObject.userAlreadyExists);
    return (
      <div className="p-grid dashboard">
        <div className="p-col-12">
          <pr.Button
            label="i"
            tooltip={
              "Multiple Users can be registered to a business. Once registered and logged-In, Simply register new users by using the Add New button in the Business Settings page"
            }
            tooltipOptions={{
              position: "left",
            }}
            style={{
              borderRadius: "25px",
              float: "right",
            }}
          />
        </div>
        <div className="p-col-6">
          {this.displayInputText("firstName", "First Name *", false)}
          {this.displayInputText("surname", "Surname *", false)}
          {this.displayInputText("contactTelephone", "Telephone", false)}
        </div>
        <div className="p-col-6">
          {this.displayInputText("email", "Email *", true)}
          {this.displayPassword("password", "Password *", true)}
          {this.displayPassword("confirmPassword", "Confirm Password *", false)}
        </div>
        <div className="p-col-12">
          <span>
            Mid and East Antrim Borough Council will collect and process your
            personal information as a legitimate interest as defined by the Data
            Protection Act 2018’ We will keep your information secure, accurate
            and for no longer than is necessary in accordance with data
            protection laws. If you wish to find out more about how we control
            and process personal data and protect your privacy please visit the
            following:{" "}
          </span>
          <label
            className="input-label"
            onClick={() => {
              window.open("https://www.midandeastantrim.gov.uk/privacy-notice");
            }}
            style={{ cursor: "pointer", color: "blue", width: "fit-content" }}
          >
            https://www.midandeastantrim.gov.uk/privacy-notice
          </label>
        </div>
        <div className="p-col-12">
          <label className="custom-label">
            I understand the terms and conditions{" "}
          </label>
          <pr.Checkbox
            checked={this.props.editedObject.conditionsSigned || false}
            onChange={(e) => {
              this.props.updateValue("conditionsSigned", e.checked);
            }}
          />
        </div>
        <div className="p-col-12">
          <pr.Button
            className="flt-r"
            disabled={
              !this.props.editedObject.conditionsSigned ||
              !this.props.editedObject.firstName ||
              !this.props.editedObject.surname ||
              !this.props.editedObject.email ||
              !this.props.editedObject.password ||
              this.props.editedObject.password !==
                this.props.editedObject.confirmPassword ||
              this.props.editedObject.userAlreadyExists
            }
            label="Next"
            icon={"pi pi-arrow-right"}
            onClick={() => {
              this.props.updateState("index", 3);
            }}
            style={{ float: "right" }}
          />
          <pr.Button
            className="flt-l"
            label="Back"
            icon="pi pi-arrow-left"
            onClick={() => {
              this.props.updateState("index", 1);
            }}
          />
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <pr.Messages
          style={{ textAlign: "left" }}
          ref={(el) => (this.growl = el)}
        />
        {this.props.viewType === ViewType.REGISTER
          ? this.displayRegister()
          : this.displayCard()}
      </React.Fragment>
    );
  }
}
