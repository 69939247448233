import { BaseViewModel } from "../../common/BaseViewModel";
import * as Format from "../../common/FormatFunctions";
import { CloudService } from "../../common/CloudService/ClientLibsReference";
import * as ApiService from "../../common/CloudService/ApiService";
import {
  BusinessType,
  CrudlStatus,
  ViewState,
} from "../../common/config/AppConstants";
import * as UIMap from "../../common/PrimeComponents";
export class BusinessViewModel extends BaseViewModel {
  getBusinesss = async (codeTag) => {
    //CRITICAL - ERROR + EMPTY MESSAGE
    var businessResponse = await this.getBusinessListByCodeTag(codeTag);
    if (
      Format.isNotNull(businessResponse.errorCode) &&
      businessResponse.errorCode === 0
    ) {
      if (Format.isNotNull(businessResponse.businesses)) {
        this.updateState("businessList", businessResponse.businesses);
        this.logger.info(businessResponse.businesses);
      } else {
        this.showEmpty("Business", ViewState.TABLE);
      }
    } else {
      this.showError("Business", CrudlStatus.LIST, ViewState.TABLE);
    }
  };

  getBusinesssReturned = async (codeTag) => {
    //CRITICAL - ERROR + EMPTY MESSAGE
    var businessResponse = await this.getBusinessListByCodeTag(codeTag);
    if (
      Format.isNotNull(businessResponse.errorCode) &&
      businessResponse.errorCode === 0
    ) {
      if (Format.isNotNull(businessResponse.businesses)) {
        this.logger.info(businessResponse.businesses);
        return businessResponse.businesses;
      } else {
        this.showEmpty("Business", ViewState.TABLE);
      }
    } else {
      this.showError("Business", CrudlStatus.LIST, ViewState.TABLE);
    }
  };

  executeCRUDLAction = async (crudlStatus, stateVariables) => {
    let businessRequest = CloudService.BusinessRequest.constructFromObject(
      stateVariables.businessBean
    );
    if (crudlStatus === CrudlStatus.CREATE) {
      return await this.createBusiness(businessRequest, stateVariables);
    }
    if (crudlStatus === CrudlStatus.UPDATE) {
      return await this.updateBusiness(businessRequest, stateVariables);
    }
    if (crudlStatus === CrudlStatus.DELETE) {
      return await this.deleteBusiness(businessRequest);
    }
  };

  createBusiness = async (businessRequest, stateVariables) => {
    if (!Format.isNotNull(businessRequest.codeTag)) {
      businessRequest.codeTag = this.codeTag;
    }
    businessRequest.userId = this.userId;

    let businessResponse = await ApiService.businessCreate(businessRequest);
    if (businessResponse.errorCode === 0) {
      this.showSuccess(
        "Business",
        CrudlStatus.CREATE,
        stateVariables.viewState
      );
    } else {
      this.showError("Business", CrudlStatus.CREATE, ViewState.CARD);
    }

    return businessResponse;
  };

  deleteBusiness = async (businessRequest) => {
    //RESET PRODUCT REQUEST
    var request = {};
    if (Format.isNotNull(businessRequest.codeTag)) {
      request.codeTag = businessRequest.codeTag;
    } else {
      request.codeTag = this.codeTag;
    }
    request.userId = this.userId;
    request.id = businessRequest.id;
    request.lastUpdatedDateTime = businessRequest.lastUpdatedDateTime;

    let businessResponse = await ApiService.businessDelete(request);
    if (businessResponse.errorCode === 0) {
      this.showSuccess("Business", CrudlStatus.DELETE, ViewState.TABLE);
    } else {
      this.showError("Business", CrudlStatus.DELETE, ViewState.CARD);
    }

    return businessResponse;
  };

  updateBusiness = async (businessRequest, stateVariables) => {
    if (!Format.isNotNull(businessRequest.codeTag)) {
      businessRequest.codeTag = this.codeTag;
    }
    businessRequest.userId = this.userId;

    let businessResponse = await ApiService.businessUpdate(businessRequest);
    if (businessResponse.errorCode === 0) {
      this.showSuccess(
        "Business",
        CrudlStatus.UPDATE,
        stateVariables.viewState
      );
    } else {
      this.showError("Business", CrudlStatus.UPDATE, ViewState.CARD);
    }

    return businessResponse;
  };

  getBusinessById = async (codeTag) => {
    //CRITICAL - ERROR + EMPTY MESSAGE
    console.log("getBusinessById");
    var businessResponse = await this.getBusinessAccount(codeTag);
    if (
      Format.isNotNull(businessResponse.errorCode) &&
      businessResponse.errorCode === 0
    ) {
      if (businessResponse.type === BusinessType.BUYER) {
        businessResponse.typeBuyer = true;
      } else if (businessResponse.type === BusinessType.SUPPLIER) {
        businessResponse.typeSupplier = true;
      } else if (businessResponse.type === BusinessType.BUYERSUPPLIER) {
        businessResponse.typeBuyer = true;
        businessResponse.typeSupplier = true;
      }
      this.updateState("editedBusiness", businessResponse);
      this.updateState(
        "originalEditedBusiness",
        JSON.stringify(businessResponse)
      );
    } else {
      this.showError("Business", CrudlStatus.LIST, ViewState.TABLE);
    }
  };

  getProfile = async (codeTag) => {
    let request = {};
    request.codeTag = this.codeTag;
    if (Format.isNotNull(codeTag)) {
      request.codeTag = codeTag;
    }
    var profileResponse = await this.profileGetByCodeTag(request);
    if (
      Format.isNotNull(profileResponse.errorCode) &&
      profileResponse.errorCode === 0
    ) {
      if (Format.isNotNull(profileResponse.profileList)) {
        let profileToReturn = null;
        profileResponse.profileList.forEach((profile) => {
          if (Format.isNotNull(profile.type)) {
            if (
              profile.type === BusinessType.BUYER ||
              profile.type === BusinessType.SUPPLIER ||
              profile.type === BusinessType.BUYERSUPPLIER
            ) {
              profileToReturn = Format.deepCopy(profile);
            }
          }
        });
        this.updateState("profileObj", profileToReturn);

        return profileToReturn;
      } else {
        this.logger.info("Profile is empty");
      }
    } else {
      this.logger.info("Profile api failed");
    }
  };

  updateProfile = async (profileRequest) => {
    let profileResponse = await ApiService.profileUpdate(profileRequest);
    if (profileResponse.errorCode === 0) {
      return profileResponse;
      // this.showSuccess("Profile", CrudlStatus.UPDATE, ViewState.CARD);
    } else {
      this.showError("Profile", CrudlStatus.UPDATE, ViewState.CARD);
    }

    return profileResponse;
  };

  getBusinessIndustries = async () => {
    var businessIndustryResponse =
      await this.getBusinessIndustryListByCodeTag();
    if (
      Format.isNotNull(businessIndustryResponse.errorCode) &&
      businessIndustryResponse.errorCode === 0
    ) {
      if (Format.isNotNull(businessIndustryResponse.businessIndustryList)) {
        this.updateState(
          "businessIndustryList",
          businessIndustryResponse.businessIndustryList
        );
        let array = UIMap.getDropdownOptionsExtension(
          businessIndustryResponse.businessIndustryList,
          "name",
          "id"
        );
        this.updateState("businessTypeOptions", array);
        this.logger.info(businessIndustryResponse.businessIndustryList);
      } else {
        this.showEmpty("Business Industries", ViewState.TABLE);
      }
    } else {
      this.showError("Business Industries", CrudlStatus.LIST, ViewState.TABLE);
    }
  };
}
