import React, { Component } from "react";
import * as pr from "../../common/PrimeComponents";
import * as Log from "../../common/Feedback/Log";
import Holdable from "./holdable";
import * as format from "../../common/FormatFunctions";
import * as Constant from "../../common/config/Configuration";

export class FileViewer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: null,
      errors: [],
      layout: "list",
      deleteDialog: false,
      nameDialog: false,
      files: [],
      name: "",
      currentFileUri: "",
      loading: false,
    };

    this.logger = Log;
  }
  onClick = (evt) => {
    window.open(evt.currentTarget.id);
  };

  onHold = (evt) => {
    if (!this.props.readOnly) {
      this.setState({ currentFileUri: evt.currentTarget.id });
      this.toggleDialog(true);
    }
  };
  onClickDeleteButton = (url) => {
    this.setState({ currentFileUri: url });
    this.toggleDialog(true);
  };

  toggleDialog = (booleanValue) => {
    this.setState({ deleteDialog: booleanValue });
  };
  toggleName = (booleanValue) => {
    this.setState({ nameDialog: booleanValue });
  };

  onRemoveFile = () => {
    this.logger.info(this.state.currentFileUri);
    var localFileUri = format.deepCopy(this.props.fileList);

    localFileUri = localFileUri.filter(
      (element) => element.url !== this.state.currentFileUri
    );

    let localList = [];
    localFileUri.forEach((file) => {
      // files returned in string json format. Each file object needs parsed
      var row = JSON.parse(file);
      let fileBeingDeleted = false;
      if (row.url === this.state.currentFileUri) {
        fileBeingDeleted = true;
      }
      if (!fileBeingDeleted) {
        localList.push(file);
      }
    });

    this.logger.info(localList);

    if (this.props.updateType === "LIST") {
      this.props.updateValue(this.props.field, localList, this.props.index);
    } else if (this.props.updateType === "CUSTOM") {
      this.props.updateValue(localList);
    } else {
      this.props.updateValue(this.props.field, localList);
    }
    this.setState({ currentFileUri: "" });
    this.toggleDialog(false);
  };

  onLocalUploadFile = (files) => {
    const fileArray = Object.values(files);
    console.log(fileArray);
    var name = "";
    fileArray.forEach((element) => {
      name = format.deepCopy(element.name).split(".")[0];
    });
    this.setState({ files: fileArray, name: name });
    this.toggleName(true);
  };
  onFirebaseUploadFile = () => {
    this.setState({ loading: true });
    const ref = Constant.getFirebaseStorage();
    this.logger.info(this.state.files);
    if (format.isNotNull(this.state.files)) {
      var fileArray = this.state.files;

      console.log(this.state.name);
      var name = this.state.name;

      fileArray.forEach((e) => {
        const metadata = {
          contentType: e.type,
          visibilty: "public",
        };

        console.log(name + e.name.split(".")[1]);
        name = name + "." + e.name.split(".")[1];
        const task = ref.child(name).put(e, metadata);
        task
          .then((snapshot) => snapshot.ref.getDownloadURL())
          .then((url) => {
            this.logger.info(url);
            var localList = format.deepCopy(this.props.fileList);
            if (!format.isNotNull(localList)) {
              localList = [];
            }

            localList.push(JSON.stringify({ url: url, name: name }));

            this.logger.info(localList);
            if (this.props.updateType === "LIST") {
              this.props.updateValue(
                this.props.field,
                localList,
                this.props.index
              );
            } else if (this.props.updateType === "CUSTOM") {
              this.props.updateValue(localList);
            } else {
              this.props.updateValue(this.props.field, localList);
            }

            //this.dt.clear();
            this.setState({ loading: false });
          });
      });
    }
    this.setState({ name: "", nameDialog: false, files: [] });
  };

  render() {
    return (
      <React.Fragment>
        <div className="p-col-12">
          {this.state.loading ? (
            <pr.ProgressSpinner />
          ) : (
            <>
              {!this.props.readOnly && (
                <pr.FileUpload
                  ref={(el) => {
                    this.dt = el;
                  }}
                  style={{ float: "left" }}
                  mode="basic"
                  auto={true}
                  customUpload={true}
                  accept={this.props.accept}
                  chooseLabel={"Upload"}
                  onSelect={(e) => {
                    this.onLocalUploadFile(e.files);
                  }}
                ></pr.FileUpload>
              )}
            </>
          )}
        </div>
        <pr.Dialog
          header={"Remove File"}
          footer={
            <div>
              <pr.Button
                className="secondary-btn"
                label="Yes"
                icon="pi pi-check"
                onClick={() => {
                  this.onRemoveFile();
                }}
                style={{
                  backgroundColor: "black",
                  color: "white",
                  float: "right",
                }}
              />
              <pr.Button
                className="secondary-btn"
                label="No"
                icon="pi pi-times"
                onClick={() => {
                  this.toggleDialog(false);
                }}
                style={{
                  backgroundColor: "black",
                  color: "white",
                  float: "right",
                }}
              />
            </div>
          }
          visible={this.state.deleteDialog}
          // style={{ color: "white" }}
          modal={true}
          onHide={(e) => this.toggleDialog(false)}
        >
          Are you sure you want to delete this File?
        </pr.Dialog>
        <pr.Dialog
          header={"Name File"}
          visible={this.state.nameDialog}
          style={{ color: "white" }}
          modal={true}
          onHide={(e) => this.toggleName(false)}
        >
          <div className="p-grid">
            <div className="p-col-12">
              <pr.InputText
                value={this.state.name || ""}
                onChange={(e) => this.setState({ name: e.target.value })}
                style={{ color: "white", backgroundColor: "black" }}
              />
            </div>

            <div className="p-col-12">
              <pr.Button
                className="secondary-btn"
                label="Continue"
                icon="pi pi-check"
                onClick={() => {
                  this.onFirebaseUploadFile();
                }}
                style={{
                  backgroundColor: "black",
                  color: "white",
                  float: "right",
                }}
              />
            </div>
          </div>
        </pr.Dialog>
        {this.getItemTemplate(this.props.fileList)}
        {/* <pr.DataView
          header={
            <pr.FileUpload
              style={{ float: "right" }}
              mode="basic"
              auto={true}
              customUpload={true}
              accept={this.props.accept}
              chooseLabel={"Upload"}
              onSelect={e => {
                this.onUploadImage(e.files);
              }}
            ></pr.FileUpload>
          }
          value={this.props.imageList}
          layout={this.state.layout}
          itemTemplate={this.getItemTemplate}
        ></pr.DataView> */}
      </React.Fragment>
    );
  }
  getItemTemplate = (fileList) => {
    var componentArray = [];
    var index = 0;

    if (format.isNotNull(fileList)) {
      fileList.forEach((file) => {
        var parsedFile = JSON.parse(file);
        componentArray[index] = (
          <div className="p-grid">
            <div className="p-col-1">
              <div className="p-grid">
                <div className="p-col-12"></div>
                <div className="p-col-12">
                  {!this.props.readOnly && (
                    <pr.Button
                      className="deleteIcon"
                      icon="pi pi-times"
                      onClick={() => {
                        this.onClickDeleteButton(parsedFile.url);
                      }}
                    />
                  )}
                </div>
                <div className="p-col-12"></div>
              </div>
            </div>
            <div className="p-col-10">
              <Holdable
                key={"holdable" + index}
                onClick={this.onClick}
                onHold={this.onHold}
                id={parsedFile.url}
              >
                <div className="p-grid card">
                  <div className="p-col-12 p-md-1">
                    <i className="pi pi-file"></i>
                  </div>
                  <div className="p-col-12 p-md-11">
                    <label className="link-label">{parsedFile.name}</label>
                  </div>
                </div>
              </Holdable>
            </div>
          </div>
        );
        index++;
      });
    }
    return componentArray;
  };
}
