import React, { Component } from "react";
//PrimeReact Components
import * as pr from "../../common/PrimeComponents";

export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {};

  render() {
    return (
      <div className="p-grid dashboard">
        <div className="p-col-12">
          <h1 style={{ lineHeight: "40px", margin: "0" }}>
            Please complete the form where applicable
          </h1>
        </div>
        <div className="p-col-12">
          <div className="p-col-12">
            <pr.Fieldset legend="General" toggleable={true}>
              <div className="p-col-12">
                <div className="p-col-12">
                  <span className="md-inputfield form-field">
                    <pr.InputText
                      id="formGeneralCompanyName"
                      value={
                        this.props.formObject.general.formGeneralCompanyName ||
                        ""
                      }
                      onChange={(e) => {
                        this.props.changeState(
                          "general",
                          e.target.id,
                          e.target.value
                        );
                      }}
                    />
                    <label
                      htmlFor="formGeneralCompanyName"
                      className="input-label"
                    >
                      Company Name
                    </label>
                  </span>
                </div>
                <div className="p-col-12">
                  <span className="md-inputfield form-field">
                    <pr.InputText
                      id="formGeneralParentCompanyName"
                      value={
                        this.props.formObject.general
                          .formGeneralParentCompanyName || ""
                      }
                      onChange={(e) => {
                        this.props.changeState(
                          "general",
                          e.target.id,
                          e.target.value
                        );
                      }}
                    />
                    <label
                      htmlFor="formGeneralParentCompanyName"
                      className="input-label"
                    >
                      Parent Company Name
                    </label>
                  </span>
                </div>
                <div className="p-col-12">
                  <span className="md-inputfield form-field">
                    <pr.InputText
                      id="formGeneralLocation"
                      value={
                        this.props.formObject.general.formGeneralLocation || ""
                      }
                      onChange={(e) => {
                        this.props.changeState(
                          "general",
                          e.target.id,
                          e.target.value
                        );
                      }}
                    />
                    <label
                      htmlFor="formGeneralLocation"
                      className="input-label"
                    >
                      Location
                    </label>
                  </span>
                </div>
                <div className="p-col-12">
                  <span className="md-inputfield form-field">
                    <pr.InputText
                      id="formGeneralProducts"
                      value={
                        this.props.formObject.general.formGeneralProducts || ""
                      }
                      onChange={(e) => {
                        this.props.changeState(
                          "general",
                          e.target.id,
                          e.target.value
                        );
                      }}
                    />
                    <label
                      htmlFor="formGeneralProducts"
                      className="input-label"
                    >
                      Products
                    </label>
                  </span>
                </div>
                <div className="p-col-12">
                  <span className="md-inputfield form-field">
                    <pr.InputText
                      id="formGeneralRevenue"
                      value={
                        this.props.formObject.general.formGeneralRevenue || ""
                      }
                      onChange={(e) => {
                        this.props.changeState(
                          "general",
                          e.target.id,
                          e.target.value
                        );
                      }}
                    />
                    <label htmlFor="formGeneralRevenue" className="input-label">
                      Revenue
                    </label>
                  </span>
                </div>
                <div className="p-col-12">
                  <span className="md-inputfield form-field">
                    <pr.InputText
                      id="formGeneralEmployees"
                      value={
                        this.props.formObject.general.formGeneralEmployees || ""
                      }
                      onChange={(e) => {
                        this.props.changeState(
                          "general",
                          e.target.id,
                          e.target.value
                        );
                      }}
                    />
                    <label
                      htmlFor="formGeneralEmployees"
                      className="input-label"
                    >
                      Number of Employees
                    </label>
                  </span>
                </div>
                <div className="p-col-12">
                  <span className="md-inputfield form-field">
                    <pr.InputText
                      id="formGeneralCapacityUtilisation"
                      value={
                        this.props.formObject.general
                          .formGeneralCapacityUtilisation || ""
                      }
                      onChange={(e) => {
                        this.props.changeState(
                          "general",
                          e.target.id,
                          e.target.value
                        );
                      }}
                    />
                    <label
                      htmlFor="formGeneralCapacityUtilisation"
                      className="input-label"
                    >
                      Capacity Utilisation %
                    </label>
                  </span>
                </div>
                <div className="p-col-12">{/*Empty div for spacing*/}</div>
              </div>
            </pr.Fieldset>
          </div>
          <div className="p-col-12">
            <pr.Fieldset legend="Quality" toggleable={true}>
              <div className="p-col-12">
                <div className="p-col-12">
                  <b>1) Are you 3rd party certified?</b>
                </div>
                <div className="p-col-12 p-md-6">
                  <div className="p-col-12">
                    <span className="md-inputfield form-field">
                      <pr.InputText
                        id="formQualityLeanManufacturing"
                        value={
                          this.props.formObject.quality
                            .formQualityLeanManufacturing || ""
                        }
                        onChange={(e) => {
                          this.props.changeState(
                            "quality",
                            e.target.id,
                            e.target.value
                          );
                        }}
                      />
                      <label
                        htmlFor="formQualityLeanManufacturing"
                        className="input-label"
                      >
                        Lean Manufacturing
                      </label>
                    </span>
                  </div>
                  <div className="p-col-12">
                    <span className="md-inputfield form-field">
                      <pr.InputText
                        id="formQualityTotalQualityManage"
                        value={
                          this.props.formObject.quality
                            .formQualityTotalQualityManage || ""
                        }
                        onChange={(e) => {
                          this.props.changeState(
                            "quality",
                            e.target.id,
                            e.target.value
                          );
                        }}
                      />
                      <label
                        htmlFor="formQualityTotalQualityManage"
                        className="input-label"
                      >
                        Total Quality Management
                      </label>
                    </span>
                  </div>
                  <div className="p-col-12">
                    <span className="md-inputfield form-field">
                      <pr.InputText
                        id="formQualityKaizen"
                        value={
                          this.props.formObject.quality.formQualityKaizen || ""
                        }
                        onChange={(e) => {
                          this.props.changeState(
                            "quality",
                            e.target.id,
                            e.target.value
                          );
                        }}
                      />
                      <label
                        htmlFor="formQualityKaizen"
                        className="input-label"
                      >
                        Kaizen
                      </label>
                    </span>
                  </div>
                  <div className="p-col-12">
                    <span className="md-inputfield form-field">
                      <pr.InputText
                        id="formQuality6Sigma"
                        value={
                          this.props.formObject.quality.formQuality6Sigma || ""
                        }
                        onChange={(e) => {
                          this.props.changeState(
                            "quality",
                            e.target.id,
                            e.target.value
                          );
                        }}
                      />
                      <label
                        htmlFor="formQuality6Sigma"
                        className="input-label"
                      >
                        6-Sigma
                      </label>
                    </span>
                  </div>
                </div>
                <div className="p-col-12 p-md-6">
                  <div className="p-col-12">
                    <span className="md-inputfield form-field">
                      <pr.InputText
                        id="formQualityOther1"
                        value={
                          this.props.formObject.quality.formQualityOther1 || ""
                        }
                        onChange={(e) => {
                          this.props.changeState(
                            "quality",
                            e.target.id,
                            e.target.value
                          );
                        }}
                      />
                      <label
                        htmlFor="formQualityOther1"
                        className="input-label"
                      >
                        Other (please name)
                      </label>
                    </span>
                  </div>
                  <div className="p-col-12">
                    <span className="md-inputfield form-field">
                      <pr.InputText
                        id="formQualityOther2"
                        value={
                          this.props.formObject.quality.formQualityOther2 || ""
                        }
                        onChange={(e) => {
                          this.props.changeState(
                            "quality",
                            e.target.id,
                            e.target.value
                          );
                        }}
                      />
                      <label
                        htmlFor="formQualityOther2"
                        className="input-label"
                      >
                        Other (please name)
                      </label>
                    </span>
                  </div>
                  <div className="p-col-12">
                    <span className="md-inputfield form-field">
                      <pr.InputText
                        id="formQualityOther3"
                        value={
                          this.props.formObject.quality.formQualityOther3 || ""
                        }
                        onChange={(e) => {
                          this.props.changeState(
                            "quality",
                            e.target.id,
                            e.target.value
                          );
                        }}
                      />
                      <label
                        htmlFor="formQualityOther3"
                        className="input-label"
                      >
                        Other (please name)
                      </label>
                    </span>
                  </div>
                  <div className="p-col-12">
                    <span className="md-inputfield form-field">
                      <pr.InputText
                        id="formQualityOther4"
                        value={
                          this.props.formObject.quality.formQualityOther4 || ""
                        }
                        onChange={(e) => {
                          this.props.changeState(
                            "quality",
                            e.target.id,
                            e.target.value
                          );
                        }}
                      />
                      <label
                        htmlFor="formQualityOther4"
                        className="input-label"
                      >
                        Other (please name)
                      </label>
                    </span>
                  </div>
                </div>
                <div className="p-col-12">
                  <br />
                  <b>
                    2) Do you have a continuous improvement process? If yes,
                    check which one and identify the registrar.
                  </b>
                </div>
                <div className="p-col-12">
                  <div
                    className="p-col-12 p-md-4"
                    style={{ paddingTop: "2em" }}
                  >
                    <b className="custom-label">Lean</b>
                  </div>
                  <div className="p-col-4" style={{ paddingTop: "2em" }}>
                    <center>
                      <pr.Checkbox
                        checked={
                          this.props.formObject.quality.formQualityLean || false
                        }
                        onChange={(e) => {
                          this.props.changeState(
                            "quality",
                            "formQualityLean",
                            e.checked
                          );
                        }}
                      />
                    </center>
                  </div>
                  <div className="p-col-8 p-md-4">
                    <span className="md-inputfield form-field">
                      <pr.InputText
                        id="formQualityLeanRegistrar"
                        disabled={
                          !this.props.formObject.quality.formQualityLean
                        }
                        value={
                          this.props.formObject.quality
                            .formQualityLeanRegistrar || ""
                        }
                        onChange={(e) => {
                          this.props.changeState(
                            "quality",
                            e.target.id,
                            e.target.value
                          );
                        }}
                      />
                      <label
                        htmlFor="formQualityLeanRegistrar"
                        className="input-label"
                      >
                        Registrar
                      </label>
                    </span>
                  </div>
                </div>
                <div className="p-col-12">
                  <div
                    className="p-col-12 p-md-4"
                    style={{ paddingTop: "2em" }}
                  >
                    <b className="custom-label">TQM</b>
                  </div>
                  <div className="p-col-4" style={{ paddingTop: "2em" }}>
                    <center>
                      <pr.Checkbox
                        checked={
                          this.props.formObject.quality.formQualityTQM || false
                        }
                        onChange={(e) => {
                          this.props.changeState(
                            "quality",
                            "formQualityTQM",
                            e.checked
                          );
                        }}
                      />
                    </center>
                  </div>
                  <div className="p-col-8 p-md-4">
                    <span className="md-inputfield form-field">
                      <pr.InputText
                        id="formQualityTQMRegistrar"
                        disabled={!this.props.formObject.quality.formQualityTQM}
                        value={
                          this.props.formObject.quality
                            .formQualityTQMRegistrar || ""
                        }
                        onChange={(e) => {
                          this.props.changeState(
                            "quality",
                            e.target.id,
                            e.target.value
                          );
                        }}
                      />
                      <label
                        htmlFor="formQualityTQMRegistrar"
                        className="input-label"
                      >
                        Registrar
                      </label>
                    </span>
                  </div>
                </div>
                <div className="p-col-12">
                  <div
                    className="p-col-12 p-md-4"
                    style={{ paddingTop: "2em" }}
                  >
                    <b className="custom-label">TS16949</b>
                  </div>
                  <div className="p-col-4" style={{ paddingTop: "2em" }}>
                    <center>
                      <pr.Checkbox
                        checked={
                          this.props.formObject.quality.formQualityTS16949 ||
                          false
                        }
                        onChange={(e) => {
                          this.props.changeState(
                            "quality",
                            "formQualityTS16949",
                            e.checked
                          );
                        }}
                      />
                    </center>
                  </div>
                  <div className="p-col-8 p-md-4">
                    <span className="md-inputfield form-field">
                      <pr.InputText
                        id="formQualityTS16949Registrar"
                        disabled={
                          !this.props.formObject.quality.formQualityTS16949
                        }
                        value={
                          this.props.formObject.quality
                            .formQualityTS16949Registrar || ""
                        }
                        onChange={(e) => {
                          this.props.changeState(
                            "quality",
                            e.target.id,
                            e.target.value
                          );
                        }}
                      />
                      <label
                        htmlFor="formQualityTS16949Registrar"
                        className="input-label"
                      >
                        Registrar
                      </label>
                    </span>
                  </div>
                </div>
                <div className="p-col-12">
                  <div
                    className="p-col-12 p-md-4"
                    style={{ paddingTop: "2em" }}
                  >
                    <b className="custom-label">CAT Certified</b>
                  </div>
                  <div className="p-col-4" style={{ paddingTop: "2em" }}>
                    <center>
                      <pr.Checkbox
                        checked={
                          this.props.formObject.quality
                            .formQualityCATCertified || false
                        }
                        onChange={(e) => {
                          this.props.changeState(
                            "quality",
                            "formQualityCATCertified",
                            e.checked
                          );
                        }}
                      />
                    </center>
                  </div>
                  <div className="p-col-8 p-md-4">
                    <span className="md-inputfield form-field">
                      <pr.InputText
                        id="formQualityCATCertifiedRegistrar"
                        disabled={
                          !this.props.formObject.quality.formQualityCATCertified
                        }
                        value={
                          this.props.formObject.quality
                            .formQualityCATCertifiedRegistrar || ""
                        }
                        onChange={(e) => {
                          this.props.changeState(
                            "quality",
                            e.target.id,
                            e.target.value
                          );
                        }}
                      />
                      <label
                        htmlFor="formQualityCATCertifiedRegistrar"
                        className="input-label"
                      >
                        Registrar
                      </label>
                    </span>
                  </div>
                </div>
                <div className="p-col-12">
                  <div
                    className="p-col-12 p-md-4"
                    style={{ paddingTop: "2em" }}
                  >
                    <b className="custom-label">ISO 9001</b>
                  </div>
                  <div className="p-col-4" style={{ paddingTop: "2em" }}>
                    <center>
                      <pr.Checkbox
                        checked={
                          this.props.formObject.quality.formQualityISO9001 ||
                          false
                        }
                        onChange={(e) => {
                          this.props.changeState(
                            "quality",
                            "formQualityISO9001",
                            e.checked
                          );
                        }}
                      />
                    </center>
                  </div>
                  <div className="p-col-8 p-md-4">
                    <span className="md-inputfield form-field">
                      <pr.InputText
                        id="formQualityISO9001Registrar"
                        disabled={
                          !this.props.formObject.quality.formQualityISO9001
                        }
                        value={
                          this.props.formObject.quality
                            .formQualityISO9001Registrar || ""
                        }
                        onChange={(e) => {
                          this.props.changeState(
                            "quality",
                            e.target.id,
                            e.target.value
                          );
                        }}
                      />
                      <label
                        htmlFor="formQualityISO9001Registrar"
                        className="input-label"
                      >
                        Registrar
                      </label>
                    </span>
                  </div>
                </div>
                <div className="p-col-12">
                  <div
                    className="p-col-12 p-md-4"
                    style={{ paddingTop: "2em" }}
                  >
                    <b className="custom-label">IATF</b>
                  </div>
                  <div className="p-col-4" style={{ paddingTop: "2em" }}>
                    <center>
                      <pr.Checkbox
                        checked={
                          this.props.formObject.quality.formQualityIATF || false
                        }
                        onChange={(e) => {
                          this.props.changeState(
                            "quality",
                            "formQualityIATF",
                            e.checked
                          );
                        }}
                      />
                    </center>
                  </div>
                  <div className="p-col-8 p-md-4">
                    <span className="md-inputfield form-field">
                      <pr.InputText
                        id="formQualityIATFRegistrar"
                        disabled={
                          !this.props.formObject.quality.formQualityIATF
                        }
                        value={
                          this.props.formObject.quality
                            .formQualityIATFRegistrar || ""
                        }
                        onChange={(e) => {
                          this.props.changeState(
                            "quality",
                            e.target.id,
                            e.target.value
                          );
                        }}
                      />
                      <label
                        htmlFor="formQualityIATFRegistrar"
                        className="input-label"
                      >
                        Registrar
                      </label>
                    </span>
                  </div>
                </div>
              </div>
            </pr.Fieldset>
          </div>
          <div className="p-col-12">
            <pr.Fieldset legend="Cost" toggleable={true}>
              <div className="p-col-12">
                <div className="p-col-12">
                  <div className="p-col-12 p-md-4">
                    <b>1) Do you have an open book costing philosophy?</b>
                  </div>
                  <div className="p-col-12 p-md-4">
                    <center>
                      <pr.Checkbox
                        checked={
                          this.props.formObject.cost.formCostOpenBook || false
                        }
                        onChange={(e) => {
                          this.props.changeState(
                            "cost",
                            "formCostOpenBook",
                            e.checked
                          );
                        }}
                      />
                    </center>
                  </div>
                </div>
                <div className="p-col-12">
                  <div className="p-col-12 p-md-4">
                    <b>
                      2) Are you willing to participate in "should cost"
                      modelling to assure appropriate cost solutions are
                      provided to the end customer?
                    </b>
                  </div>
                  <div className="p-col-12 p-md-4">
                    <center>
                      <pr.Checkbox
                        checked={
                          this.props.formObject.cost.formCostShouldCost || false
                        }
                        onChange={(e) => {
                          this.props.changeState(
                            "cost",
                            "formCostShouldCost",
                            e.checked
                          );
                        }}
                      />
                    </center>
                  </div>
                </div>
                <div className="p-col-12">
                  <div className="p-col-12 p-md-4">
                    <b>3) Currency</b>
                  </div>
                  <div className="p-col-12 p-md-4">
                    <center>
                      <pr.Dropdown
                        value={this.props.formObject.cost.formCostCurrency}
                        options={[
                          {
                            label: "US Dollar (USD)",
                            value: "US Dollar (USD)",
                          },
                          { label: "Euro (EUR)", value: "Euro (EUR)" },
                          {
                            label: "Japenese Yen (JPY)",
                            value: "Japenese Yen (JPY)",
                          },
                          {
                            label: "Pound Sterling (GBP)",
                            value: "Pound Sterling (GBP)",
                          },
                          {
                            label: "Australian Dollar (AUD)",
                            value: "Australian Dollar (AUD)",
                          },
                          {
                            label: "Canadian Dollar (CAD)",
                            value: "Canadian Dollar (CAD)",
                          },
                          {
                            label: "Swiss Franc (CHF)",
                            value: "Swiss Franc (CHF)",
                          },
                          {
                            label: "Chinese Renminbi (CNH)",
                            value: "Chinese Renminbi (CNH)",
                          },
                          {
                            label: "Swedish Krona (SEK)",
                            value: "Swedish Krona (SEK)",
                          },
                          {
                            label: "New Zealand Dollar (NZD)",
                            value: "New Zealand Dollar (NZD)",
                          },
                          {
                            label: "Polish Złoty (PLN)",
                            value: "Polish Złoty (PLN)",
                          },
                        ]}
                        onChange={(e) => {
                          this.props.changeState(
                            "cost",
                            "formCostCurrency",
                            e.value
                          );
                        }}
                        style={{ width: "100%" }}
                      />
                    </center>
                  </div>
                </div>
              </div>
            </pr.Fieldset>
          </div>
          <div className="p-col-12">
            <pr.Fieldset legend="Logistics" toggleable={true}>
              <div className="p-col-12">
                <div className="p-col-12">
                  <div className="p-col-12">
                    <b>1) What are your current annual inventory turns for:</b>
                  </div>
                  <div className="p-col-12 p-md-6">
                    <div className="p-col-12">
                      <span className="md-inputfield form-field">
                        <pr.InputText
                          id="formLogisticsRawMaterials"
                          value={
                            this.props.formObject.logistics
                              .formLogisticsRawMaterials || ""
                          }
                          onChange={(e) => {
                            this.props.changeState(
                              "logistics",
                              e.target.id,
                              e.target.value
                            );
                          }}
                        />
                        <label
                          htmlFor="formLogisticsRawMaterials"
                          className="input-label"
                        >
                          Raw Materials
                        </label>
                      </span>
                    </div>
                    <div className="p-col-12">
                      <label
                        htmlFor="formLogisticsRawMaterialsExplanation"
                        className="custom-label"
                      >
                        Please explain how you calculated this number
                      </label>
                      <pr.InputTextarea
                        id="formLogisticsRawMaterialsExplanation"
                        rows={5}
                        value={
                          this.props.formObject.logistics
                            .formLogisticsRawMaterialsExplanation || ""
                        }
                        onChange={(e) => {
                          this.props.changeState(
                            "logistics",
                            e.target.id,
                            e.target.value
                          );
                        }}
                        style={{ resize: "none" }}
                      />
                    </div>
                  </div>
                  <div className="p-col-12 p-md-6">
                    <div className="p-col-12">
                      <span className="md-inputfield form-field">
                        <pr.InputText
                          id="formLogisticsFinishedGoods"
                          value={
                            this.props.formObject.logistics
                              .formLogisticsFinishedGoods || ""
                          }
                          onChange={(e) => {
                            this.props.changeState(
                              "logistics",
                              e.target.id,
                              e.target.value
                            );
                          }}
                        />
                        <label
                          htmlFor="formLogisticsFinishedGoods"
                          className="input-label"
                        >
                          Finished Goods
                        </label>
                      </span>
                    </div>
                    <div className="p-col-12">
                      <label
                        htmlFor="formLogisticsFinishedGoodsExplanation"
                        className="custom-label"
                      >
                        Please explain how you calculated this number
                      </label>
                      <pr.InputTextarea
                        id="formLogisticsFinishedGoodsExplanation"
                        rows={5}
                        value={
                          this.props.formObject.logistics
                            .formLogisticsFinishedGoodsExplanation || ""
                        }
                        onChange={(e) => {
                          this.props.changeState(
                            "logistics",
                            e.target.id,
                            e.target.value
                          );
                        }}
                        style={{ resize: "none" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="p-col-12">
                  <div
                    className="p-col-12 p-md-4"
                    style={{ paddingTop: "2em" }}
                  >
                    <b>
                      2) What is your current delivery performance for your top
                      five customers?
                    </b>
                  </div>
                  <div className="p-col-12 p-md-4">
                    <span className="md-inputfield form-field">
                      <pr.InputText
                        id="formLogisticsCurrentDeliveryPerformance"
                        value={
                          this.props.formObject.logistics
                            .formLogisticsCurrentDeliveryPerformance || ""
                        }
                        onChange={(e) => {
                          this.props.changeState(
                            "logistics",
                            e.target.id,
                            e.target.value
                          );
                        }}
                      />
                      <label
                        htmlFor="formLogisticsCurrentDeliveryPerformance"
                        className="input-label"
                      >
                        %
                      </label>
                    </span>
                  </div>
                </div>
                <div className="p-col-12">
                  <div className="p-col-12 p-md-4">
                    <b>
                      3) Does your company operate consignment stock for any
                      customers?
                    </b>
                  </div>
                  <div className="p-col-12 p-md-4">
                    <center>
                      <pr.Checkbox
                        checked={
                          this.props.formObject.logistics
                            .formLogisticsConsignmentStock || false
                        }
                        onChange={(e) => {
                          this.props.changeState(
                            "logistics",
                            "formLogisticsConsignmentStock",
                            e.checked
                          );
                        }}
                      />
                    </center>
                  </div>
                  <div className="p-col-12 p-md-4">
                    <label
                      htmlFor="formLogisticsConsignmentStockExplanation"
                      className="custom-label"
                    >
                      If yes, please list the requirements
                    </label>
                    <pr.InputTextarea
                      id="formLogisticsConsignmentStockExplanation"
                      rows={5}
                      value={
                        this.props.formObject.logistics
                          .formLogisticsConsignmentStockExplanation || ""
                      }
                      onChange={(e) => {
                        this.props.changeState(
                          "logistics",
                          e.target.id,
                          e.target.value
                        );
                      }}
                      style={{ resize: "none" }}
                    />
                  </div>
                </div>
                <div className="p-col-12">
                  <div className="p-col-12 p-md-4">
                    <b>
                      4) Does your company operate returnable packaging
                      programs?
                    </b>
                  </div>
                  <div className="p-col-12 p-md-4">
                    <center>
                      <pr.Checkbox
                        checked={
                          this.props.formObject.logistics
                            .formLogisticsReturnablePackaging || false
                        }
                        onChange={(e) => {
                          this.props.changeState(
                            "logistics",
                            "formLogisticsReturnablePackaging",
                            e.checked
                          );
                        }}
                      />
                    </center>
                  </div>
                  <div className="p-col-12 p-md-4">
                    <label
                      htmlFor="formLogisticsReturnablePackagingExplanation"
                      className="custom-label"
                    >
                      If yes, who owns and maintains the containers?
                    </label>
                    <pr.InputTextarea
                      id="formLogisticsReturnablePackagingExplanation"
                      rows={5}
                      value={
                        this.props.formObject.logistics
                          .formLogisticsReturnablePackagingExplanation || ""
                      }
                      onChange={(e) => {
                        this.props.changeState(
                          "logistics",
                          e.target.id,
                          e.target.value
                        );
                      }}
                      style={{ resize: "none" }}
                    />
                  </div>
                </div>
                <div className="p-col-12">
                  <div
                    className="p-col-12 p-md-4"
                    style={{ paddingTop: "2em" }}
                  >
                    <b>
                      5) What is your standard lead time for material
                      aquisition?
                    </b>
                  </div>
                  <div className="p-col-12 p-md-4">
                    <span className="md-inputfield form-field">
                      <pr.InputText
                        id="formLogisticsMaterialAcquisitionLeadTime"
                        value={
                          this.props.formObject.logistics
                            .formLogisticsMaterialAcquisitionLeadTime || ""
                        }
                        onChange={(e) => {
                          this.props.changeState(
                            "logistics",
                            e.target.id,
                            e.target.value
                          );
                        }}
                      />
                      <label
                        htmlFor="formLogisticsMaterialAcquisitionLeadTime"
                        className="input-label"
                      >
                        Lead Time
                      </label>
                    </span>
                  </div>
                </div>
                <div className="p-col-12">
                  <div
                    className="p-col-12 p-md-4"
                    style={{ paddingTop: "2em" }}
                  >
                    <b>
                      6) What is your standard lead time for finished goods?
                    </b>
                  </div>
                  <div className="p-col-12 p-md-4">
                    <span className="md-inputfield form-field">
                      <pr.InputText
                        id="formLogisticsFinishedGoodsLeadTime"
                        value={
                          this.props.formObject.logistics
                            .formLogisticsFinishedGoodsLeadTime || ""
                        }
                        onChange={(e) => {
                          this.props.changeState(
                            "logistics",
                            e.target.id,
                            e.target.value
                          );
                        }}
                      />
                      <label
                        htmlFor="formLogisticsFinishedGoodsLeadTime"
                        className="input-label"
                      >
                        Lead Time
                      </label>
                    </span>
                  </div>
                </div>
                <div className="p-col-12">
                  <div className="p-col-12">
                    <b>
                      7) Please answer Yes/No to the following questions about
                      other services:
                    </b>
                  </div>
                  <div className="p-col-12">
                    <div className="p-col-12 p-md-4">
                      <b className="custom-label">
                        a) Do you currently make JIT deliveries to any of your
                        customers?
                      </b>
                    </div>
                    <div className="p-col-12 p-md-4">
                      <pr.Dropdown
                        value={
                          this.props.formObject.logistics.formLogisticsJIT ||
                          false
                        }
                        options={[
                          { label: "Yes", value: "Yes" },
                          { label: "No", value: "No" },
                        ]}
                        onChange={(e) => {
                          this.props.changeState(
                            "logistics",
                            "formLogisticsJIT",
                            e.value
                          );
                        }}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="p-col-12">
                    <div className="p-col-12 p-md-4">
                      <b className="custom-label">
                        b) Do you have electronic Advanced Shipping Notice (ASN)
                        capability?
                      </b>
                    </div>
                    <div className="p-col-12 p-md-4">
                      <pr.Dropdown
                        value={
                          this.props.formObject.logistics.formLogisticsASN ||
                          false
                        }
                        options={[
                          { label: "Yes", value: "Yes" },
                          { label: "No", value: "No" },
                        ]}
                        onChange={(e) => {
                          this.props.changeState(
                            "logistics",
                            "formLogisticsASN",
                            e.value
                          );
                        }}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="p-col-12">
                    <div className="p-col-12 p-md-4">
                      <b className="custom-label">
                        c) Do you currently offer VMI to anybody?
                      </b>
                    </div>
                    <div className="p-col-12 p-md-4">
                      <pr.Dropdown
                        value={
                          this.props.formObject.logistics.formLogisticsVMI ||
                          false
                        }
                        options={[
                          { label: "Yes", value: "Yes" },
                          { label: "No", value: "No" },
                        ]}
                        onChange={(e) => {
                          this.props.changeState(
                            "logistics",
                            "formLogisticsVMI",
                            e.value
                          );
                        }}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="p-col-12">
                    <div className="p-col-12 p-md-4">
                      <b className="custom-label">
                        d) Do you have EDI capability for sending/receiving?
                      </b>
                    </div>
                    <div className="p-col-12 p-md-4">
                      <pr.Dropdown
                        value={
                          this.props.formObject.logistics.formLogisticsEDI ||
                          false
                        }
                        options={[
                          { label: "Yes", value: "Yes" },
                          { label: "No", value: "No" },
                        ]}
                        onChange={(e) => {
                          this.props.changeState(
                            "logistics",
                            "formLogisticsEDI",
                            e.value
                          );
                        }}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="p-col-12">
                    <div className="p-col-12 p-md-4">
                      <b className="custom-label">
                        e) Do you have warehousing facilities in Northern
                        Ireland?
                      </b>
                    </div>
                    <div className="p-col-12 p-md-4">
                      <pr.Dropdown
                        value={
                          this.props.formObject.logistics
                            .formLogisticsWarehousingFacilitiesNI || false
                        }
                        options={[
                          { label: "Yes", value: "Yes" },
                          { label: "No", value: "No" },
                        ]}
                        onChange={(e) => {
                          this.props.changeState(
                            "logistics",
                            "formLogisticsWarehousingFacilitiesNI",
                            e.value
                          );
                        }}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="p-col-12">
                    <div className="p-col-12 p-md-4">
                      <b className="custom-label">
                        f) If not, are you willing to acquire warehousing
                        capability in N.I.?
                      </b>
                    </div>
                    <div className="p-col-12 p-md-4">
                      <pr.Dropdown
                        value={
                          this.props.formObject.logistics
                            .formLogisticsWarehousingCapabilityNI || false
                        }
                        options={[
                          { label: "Yes", value: "Yes" },
                          { label: "No", value: "No" },
                        ]}
                        onChange={(e) => {
                          this.props.changeState(
                            "logistics",
                            "formLogisticsWarehousingCapabilityNI",
                            e.value
                          );
                        }}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </pr.Fieldset>
          </div>
          <div className="p-col-12">
            <pr.Fieldset legend="Development" toggleable={true}>
              <div className="p-col-12">
                <div className="p-col-12">
                  <div className="p-col-12">
                    <b>
                      1) Which CAD (Computer Aided Design) systems do you use?
                      (Please indicate all systems) <br /> Note the number of
                      CAD "stations" in each system.
                    </b>
                  </div>
                  <div className="p-col-12 p-md-6">
                    <span className="md-inputfield form-field">
                      <pr.InputText
                        id="formDevelopmentSystem1"
                        value={
                          this.props.formObject.development
                            .formDevelopmentSystem1 || ""
                        }
                        onChange={(e) => {
                          this.props.changeState(
                            "development",
                            e.target.id,
                            e.target.value
                          );
                        }}
                      />
                      <label
                        htmlFor="formDevelopmentSystem1"
                        className="input-label"
                      >
                        System
                      </label>
                    </span>
                  </div>
                  <div className="p-col-12 p-md-6">
                    <span className="md-inputfield form-field">
                      <pr.InputText
                        id="formDevelopmentNumberOfStations1"
                        value={
                          this.props.formObject.development
                            .formDevelopmentNumberOfStations1 || ""
                        }
                        onChange={(e) => {
                          this.props.changeState(
                            "development",
                            e.target.id,
                            e.target.value
                          );
                        }}
                      />
                      <label
                        htmlFor="formDevelopmentNumberOfStations1"
                        className="input-label"
                      >
                        # of Stations
                      </label>
                    </span>
                  </div>
                  <div className="p-col-12 p-md-6">
                    <span className="md-inputfield form-field">
                      <pr.InputText
                        id="formDevelopmentSystem2"
                        value={
                          this.props.formObject.development
                            .formDevelopmentSystem2 || ""
                        }
                        onChange={(e) => {
                          this.props.changeState(
                            "development",
                            e.target.id,
                            e.target.value
                          );
                        }}
                      />
                      <label
                        htmlFor="formDevelopmentSystem2"
                        className="input-label"
                      >
                        System
                      </label>
                    </span>
                  </div>
                  <div className="p-col-12 p-md-6">
                    <span className="md-inputfield form-field">
                      <pr.InputText
                        id="formDevelopmentNumberOfStations2"
                        value={
                          this.props.formObject.development
                            .formDevelopmentNumberOfStations2 || ""
                        }
                        onChange={(e) => {
                          this.props.changeState(
                            "development",
                            e.target.id,
                            e.target.value
                          );
                        }}
                      />
                      <label
                        htmlFor="formDevelopmentNumberOfStations2"
                        className="input-label"
                      >
                        # of Stations
                      </label>
                    </span>
                  </div>
                  <div className="p-col-12 p-md-6">
                    <span className="md-inputfield form-field">
                      <pr.InputText
                        id="formDevelopmentSystem3"
                        value={
                          this.props.formObject.development
                            .formDevelopmentSystem3 || ""
                        }
                        onChange={(e) => {
                          this.props.changeState(
                            "development",
                            e.target.id,
                            e.target.value
                          );
                        }}
                      />
                      <label
                        htmlFor="formDevelopmentSystem3"
                        className="input-label"
                      >
                        System
                      </label>
                    </span>
                  </div>
                  <div className="p-col-12 p-md-6">
                    <span className="md-inputfield form-field">
                      <pr.InputText
                        id="formDevelopmentNumberOfStations3"
                        value={
                          this.props.formObject.development
                            .formDevelopmentNumberOfStations3 || ""
                        }
                        onChange={(e) => {
                          this.props.changeState(
                            "development",
                            e.target.id,
                            e.target.value
                          );
                        }}
                      />
                      <label
                        htmlFor="formDevelopmentNumberOfStations3"
                        className="input-label"
                      >
                        # of Stations
                      </label>
                    </span>
                  </div>
                </div>
                <div className="p-col-12">
                  <div className="p-col-12 p-md-4">
                    <b>
                      2) Does your company have a Research and Development
                      division or have you completed any recent R&D projects?
                    </b>
                  </div>
                  <div className="p-col-12 p-md-4">
                    <center>
                      <pr.Checkbox
                        checked={
                          this.props.formObject.development
                            .formDevelopmentRAndD || false
                        }
                        onChange={(e) => {
                          this.props.changeState(
                            "development",
                            "formDevelopmentRAndD",
                            e.checked
                          );
                        }}
                      />
                    </center>
                  </div>
                  <div className="p-col-12 p-md-4">
                    <label
                      htmlFor="formDevelopmentRAndDExplanation"
                      className="custom-label"
                    >
                      If yes, please provide brief information about the type
                      and scope of work conducted
                    </label>
                    <pr.InputTextarea
                      id="formDevelopmentRAndDExplanation"
                      rows={5}
                      value={
                        this.props.formObject.development
                          .formDevelopmentRAndDExplanation || ""
                      }
                      onChange={(e) => {
                        this.props.changeState(
                          "development",
                          e.target.id,
                          e.target.value
                        );
                      }}
                      style={{ resize: "none" }}
                    />
                  </div>
                </div>
                <div className="p-col-12">
                  <div className="p-col-12 p-md-4">
                    <b>
                      3) Describe the design capabilities within your
                      engineering group for applications and components.
                    </b>
                  </div>
                  <div className="p-col-12 p-md-8">
                    <pr.InputTextarea
                      id="formDevelopmentDesignCapabilities"
                      rows={5}
                      value={
                        this.props.formObject.development
                          .formDevelopmentDesignCapabilities || ""
                      }
                      onChange={(e) => {
                        this.props.changeState(
                          "development",
                          e.target.id,
                          e.target.value
                        );
                      }}
                      style={{ resize: "none" }}
                    />
                  </div>
                </div>
                <div className="p-col-12">
                  <div
                    className="p-col-12 p-md-4"
                    style={{ paddingTop: "2em" }}
                  >
                    <b>
                      4) Does your company have a prototyping capability? If
                      yes, please estimate lead time to provide prototype
                      samples of your design.
                    </b>
                  </div>
                  <div
                    className="p-col-12 p-md-4"
                    style={{ paddingTop: "2em" }}
                  >
                    <center>
                      <pr.Checkbox
                        checked={
                          this.props.formObject.development
                            .formDevelopmentPrototypingCapabilities || false
                        }
                        onChange={(e) => {
                          this.props.changeState(
                            "development",
                            "formDevelopmentPrototypingCapabilities",
                            e.checked
                          );
                        }}
                      />
                    </center>
                  </div>
                  <div className="p-col-12 p-md-4">
                    <span className="md-inputfield form-field">
                      <pr.InputText
                        id="formDevelopmentProtottypingCapabiltiesWeeks"
                        value={
                          this.props.formObject.development
                            .formDevelopmentProtottypingCapabiltiesWeeks || ""
                        }
                        onChange={(e) => {
                          this.props.changeState(
                            "development",
                            e.target.id,
                            e.target.value
                          );
                        }}
                      />
                      <label
                        htmlFor="formDevelopmentProtottypingCapabiltiesWeeks"
                        className="input-label"
                      >
                        Weeks
                      </label>
                    </span>
                  </div>
                </div>
                <div className="p-col-12">
                  <div className="p-col-12">
                    <b>
                      5) Please note any other engineering capabilities you
                      have.
                    </b>
                  </div>
                  <div className="p-col-12">
                    <div className="p-col-12 p-md-4">
                      <b className="custom-label">
                        Rapid Prototyping Capabilty
                      </b>
                    </div>
                    <div className="p-col-12 p-md-4">
                      <center>
                        <pr.Checkbox
                          checked={
                            this.props.formObject.development
                              .formDevelopmentRapidPrototypingCapability ||
                            false
                          }
                          onChange={(e) => {
                            this.props.changeState(
                              "development",
                              "formDevelopmentRapidPrototypingCapability",
                              e.checked
                            );
                          }}
                        />
                      </center>
                    </div>
                  </div>
                  <div className="p-col-12">
                    <div className="p-col-12 p-md-4">
                      <b className="custom-label">FEA Software Capability</b>
                    </div>
                    <div className="p-col-12 p-md-4">
                      <center>
                        <pr.Checkbox
                          checked={
                            this.props.formObject.development
                              .formDevelopmentFEASoftwareCapability || false
                          }
                          onChange={(e) => {
                            this.props.changeState(
                              "development",
                              "formDevelopmentFEASoftwareCapability",
                              e.checked
                            );
                          }}
                        />
                      </center>
                    </div>
                  </div>
                  <div className="p-col-12">
                    <div className="p-col-12">
                      <span className="md-inputfield form-field">
                        <pr.InputText
                          id="formDevelopmentOtherName1"
                          value={
                            this.props.formObject.development
                              .formDevelopmentOtherName1 || ""
                          }
                          onChange={(e) => {
                            this.props.changeState(
                              "development",
                              e.target.id,
                              e.target.value
                            );
                          }}
                        />
                        <label
                          htmlFor="formDevelopmentOtherName1"
                          className="input-label"
                        >
                          Other (name)
                        </label>
                      </span>
                    </div>
                    <div className="p-col-12">
                      <span className="md-inputfield form-field">
                        <pr.InputText
                          id="formDevelopmentOtherName2"
                          value={
                            this.props.formObject.development
                              .formDevelopmentOtherName2 || ""
                          }
                          onChange={(e) => {
                            this.props.changeState(
                              "development",
                              e.target.id,
                              e.target.value
                            );
                          }}
                        />
                        <label
                          htmlFor="formDevelopmentOtherName2"
                          className="input-label"
                        >
                          Other (name)
                        </label>
                      </span>
                    </div>
                    <div className="p-col-12">
                      <span className="md-inputfield form-field">
                        <pr.InputText
                          id="formDevelopmentOtherName3"
                          value={
                            this.props.formObject.development
                              .formDevelopmentOtherName3 || ""
                          }
                          onChange={(e) => {
                            this.props.changeState(
                              "development",
                              e.target.id,
                              e.target.value
                            );
                          }}
                        />
                        <label
                          htmlFor="formDevelopmentOtherName3"
                          className="input-label"
                        >
                          Other (name)
                        </label>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </pr.Fieldset>
          </div>
          <div className="p-col-12">
            <pr.Fieldset legend="Management" toggleable={true}>
              <div className="p-col-12">
                <b>Do you have the following:</b>
              </div>
              <div className="p-col-12">
                <div className="p-col-12 p-md-4">
                  <b className="custom-label">RoHs Compliance</b>
                </div>
                <div className="p-col-12 p-md-4">
                  <center>
                    <pr.Checkbox
                      checked={
                        this.props.formObject.management
                          .formManagementRoHsCompliance || false
                      }
                      onChange={(e) => {
                        this.props.changeState(
                          "management",
                          "formManagementRoHsCompliance",
                          e.checked
                        );
                      }}
                    />
                  </center>
                </div>
              </div>
              <div className="p-col-12">
                <div className="p-col-12 p-md-4">
                  <b className="custom-label">ISO 14001</b>
                </div>
                <div className="p-col-12 p-md-4">
                  <center>
                    <pr.Checkbox
                      checked={
                        this.props.formObject.management
                          .formManagementISO14001 || false
                      }
                      onChange={(e) => {
                        this.props.changeState(
                          "management",
                          "formManagementISO14001",
                          e.checked
                        );
                      }}
                    />
                  </center>
                </div>
              </div>
            </pr.Fieldset>
          </div>
          <div className="p-col-12">{/*Empty div for spacing*/}</div>
        </div>
      </div>
    );
  }
}
