import React from "react";
import * as pr from "../../common/PrimeComponents";
import * as format from "../../common/FormatFunctions";
import * as feedback from "../../common/Feedback/Feedback";
import { LoadScreen } from "../Common/LoadScreen";
import { ScenarioAlternateViewModel } from "./ScenarioAlternateViewModel";
import { Form } from "./Form";

export class Scenario1 extends React.Component {
  constructor() {
    super();
    this.state = {
      editedObject: { buyer: false, supplier: false },
      componentList: [],
      formObject: {
        general: {},
        quality: {},
        cost: {},
        logistics: {},
        development: {},
        management: {},
      },
      capabilityOptionsSupply: [
        { label: "Yes, I can supply", value: "Yes, I can supply" },
        {
          label: "Potential, open for development",
          value: "Potential, open for development",
        },
      ],
      capabilityOptionsBuy: [
        { label: "Yes, I want to procure", value: "Yes, I want to procure" },
        {
          label: "Potential, open to procurement",
          value: "Potential, open to procurement",
        },
      ],
      conditionsSigned: false,
    };

    this.viewModel = new ScenarioAlternateViewModel();
  }

  componentDidMount = async () => {
    await this.initialDataRender();
  };

  componentDidCatch = (error, info) => {
    console.log("This went in here");

    //COMPONENT ISSUE CHECK IF THIS ERROR HANDLING IS CORRECT
  };

  initialDataRender = async () => {
    await this.setViewModel();
  };

  setViewModel = async () => {
    try {
      console.log("Getting Components");
      var componentList = await this.viewModel.componentGetByType();

      this.setState({ categoryList: componentList });
    } catch (error) {}
  };

  updateBean = async (field, value) => {
    try {
      await this.setState((prevState) => ({
        editedObject: {
          // object that we want to update
          ...prevState.editedObject, // keep all other key-value pairs
          [field]: value,
        },
      }));
    } catch (error) {}
  };

  updateFormBean = async (type, field, value) => {
    var formObject = format.deepCopy(this.state.formObject);

    if (format.isNotNull(formObject)) {
      var particularObject = formObject[type];
      if (format.isNotNull(particularObject)) {
        particularObject[field] = value;
      }
      formObject[type] = particularObject;
    }

    this.setState({ formObject: formObject });
  };

  updateState = (key, value) => {
    try {
      this.setState({ [key]: value });
    } catch (error) {
      //this.setError(false, "updateState", error);
    }
  };

  runRegister = async () => {
    this.setState({ loading: true });

    var profileType = "Buyer";
    var roletype = "30";
    if (this.state.editedObject.supplier === true) {
      profileType = "Supplier";
      roletype = "40";
    }

    await this.viewModel.createProfile(
      localStorage.getItem("clientId"),
      localStorage.getItem("userId"),
      this.state.categoryList,
      profileType,
      this.state.formObject
    );

    await this.viewModel.createMatrixItem(
      localStorage.getItem("clientId"),
      localStorage.getItem("userId"),
      this.state.categoryList
    );

    feedback.showSuccess(this.growl, "Request");

    await this.viewModel.getProfile(
      localStorage.getItem("clientId"),
      localStorage.getItem("userId")
    );

    localStorage.setItem("roletype", roletype);
    this.setState({ requestSent: true });
  };

  checkRequirements = () => {};

  render() {
    if (this.state.requestSent) {
      return (
        <div className="p-grid dashboard">
          <div className="p-col-12">
            Request has been sent, Please allow a maximum of 48 hours for
            approval
          </div>
          <div className="p-col-12">
            <pr.Button
              className="secondary-btn"
              label="Close"
              icon="pi pi-check"
              onClick={(e) => {
                this.props.close();
              }}
              style={{ float: "right" }}
            />
          </div>
        </div>
      );
    }
    return (
      <React.Fragment>
        <pr.ScrollPanel
          className="scrl"
          ref={(el) => (this.layoutMenuScroller = el)}
        >
          <pr.Messages
            style={{ textAlign: "left" }}
            ref={(el) => (this.growl = el)}
          />
          <div className="p-grid dashboard">
            {this.state.loading === true && <LoadScreen />}
            {this.state.loading !== true && (
              <React.Fragment>
                <div className="p-grid dashboard">
                  <div className="p-col-12">
                    <h2>
                      {
                        " Register for the BuySupplyNI portal for the manufacturing, construction and engineering sectors"
                      }
                    </h2>
                    <div className="p-col-12">
                      <div className="p-col-12">
                        <div className="p-col-3">
                          <label
                            htmlFor="businessDescription"
                            className="custom-label"
                          >
                            I would like to supply
                          </label>
                        </div>
                        <div className="p-col-9">
                          <pr.Checkbox
                            id="supplier"
                            rows={5}
                            checked={this.state.editedObject.supplier || false}
                            onChange={(e) => {
                              this.updateBean("supplier", e.checked);
                              this.updateBean("buyer", !e.checked);
                            }}
                            style={{ resize: "none" }}
                          />
                        </div>
                      </div>
                      <div className="p-col-12">
                        <div className="p-col-3">
                          <label
                            htmlFor="businessDescription"
                            className="custom-label"
                          >
                            I would like to buy
                          </label>
                        </div>
                        <div className="p-col-9">
                          <pr.Checkbox
                            id="buyer"
                            rows={5}
                            checked={this.state.editedObject.buyer || false}
                            onChange={(e) => {
                              this.updateBean("buyer", e.checked);
                              this.updateBean("supplier", !e.checked);
                            }}
                            style={{ resize: "none" }}
                          />
                        </div>
                      </div>
                    </div>
                    <React.Fragment>
                      <div className="p-col-12">
                        {this.state.editedObject.supplier === true && (
                          <div className="p-col-12 card">
                            <div className="p-grid">
                              <Form
                                formObject={this.state.formObject}
                                changeState={this.updateFormBean}
                                updateState={this.updateState}
                              />
                            </div>
                          </div>
                        )}

                        {(this.state.editedObject.supplier === true ||
                          this.state.editedObject.buyer === true) && (
                          <div className="p-col-12 card">
                            <div className="p-grid">
                              <h1
                                style={{
                                  lineHeight: "40px",
                                  margin: "0",
                                }}
                              >
                                Please select the categories you trade in and
                                describe your capability
                              </h1>

                              {this.categoryListToCards()}
                            </div>
                          </div>
                        )}
                      </div>
                    </React.Fragment>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </pr.ScrollPanel>
        <div className="sidebar-footer">
          <pr.Button
            style={{ float: "right" }}
            label="Register"
            onClick={() => {
              this.runRegister();
            }}
          />
        </div>
      </React.Fragment>
    );
  }

  changeCategoriesState = (key, value) => {
    let categoryList = this.state.categoryList;

    categoryList.forEach((e) => {
      if (key === e.name) {
        if (value === false) {
          e.selectedOnCreate = value;
          e.score = "";
          e.details = "";
        } else {
          e.selectedOnCreate = value;
        }
      }
      if (key === e.name + " Score") {
        e.score = value;
      }
      if (key === e.name + " Details") {
        e.details = value;
      }
    });

    this.setState({ categoryList: categoryList });
  };

  categoryListToCards = () => {
    let categoryArray = [];
    let i = 0;

    if (Array.isArray(this.state.categoryList)) {
      this.state.categoryList.forEach((e) => {
        if (e.name === "Other") {
          categoryArray[this.state.categoryList.length - 1] = (
            <div
              className="p-col-12 card"
              key={this.state.categoryList.length - 1}
            >
              <div className="p-col-12 p-md-4" style={{ paddingTop: "1.5em" }}>
                <b>{e.name}</b>
              </div>
              <div className="p-col-4" style={{ paddingTop: "1.5em" }}>
                <center>
                  <pr.Checkbox
                    checked={e.selectedOnCreate || false}
                    onChange={(f) => {
                      this.changeCategoriesState(e.name, f.checked);
                    }}
                  />
                </center>
              </div>
              <div className="p-col-8 p-md-4">
                <label htmlFor={e.name + " Score"} className="input-label">
                  Capability
                </label>
                <pr.Dropdown
                  id={e.name + " Score"}
                  className="required"
                  disabled={!e.selectedOnCreate}
                  placeholder={e.selectedOnCreate ? "Please Select" : ""}
                  value={e.score || ""}
                  options={this.getCategoryOptions()}
                  onChange={(f) => {
                    this.changeCategoriesState(e.name + " Score", f.value);
                  }}
                  filter={false}
                  filterBy="label"
                  filterPlaceholder="Search"
                  style={{ marginTop: "1px", width: "100%" }}
                />
              </div>
              <div className="p-col-12">
                <label htmlFor={e.name + " Details"} className="input-label">
                  Details
                </label>
                <textarea
                  id={e.name + " Details"}
                  disabled={!e.selectedOnCreate}
                  rows={5}
                  value={e.details || ""}
                  onChange={(f) => {
                    this.changeCategoriesState(f.target.id, f.target.value);
                  }}
                />
              </div>
            </div>
          );
        } else {
          categoryArray[i] = (
            <div className="p-col-12 card" key={i}>
              <div className="p-col-12 p-md-4" style={{ paddingTop: "1.5em" }}>
                <b>{e.name}</b>
              </div>
              <div className="p-col-4" style={{ paddingTop: "1.5em" }}>
                <center>
                  <pr.Checkbox
                    checked={e.selectedOnCreate || false}
                    onChange={(f) => {
                      this.changeCategoriesState(e.name, f.checked);
                    }}
                  />
                </center>
              </div>
              <div className="p-col-8 p-md-4">
                <label htmlFor={e.name + " Score"} className="input-label">
                  Capability
                </label>
                <pr.Dropdown
                  id={e.name + " Score"}
                  className="required"
                  disabled={!e.selectedOnCreate}
                  value={e.score || ""}
                  options={this.getCategoryOptions()}
                  onChange={(f) => {
                    this.changeCategoriesState(e.name + " Score", f.value);
                  }}
                  filter={false}
                  filterBy="label"
                  filterPlaceholder="Search"
                  style={{ marginTop: "1px", width: "100%" }}
                />
              </div>
            </div>
          );
          i++;
        }
      });
    }

    return categoryArray;
  };

  getCategoryOptions = () => {
    if (this.state.editedObject.supplier === true) {
      return this.state.capabilityOptionsSupply;
    } else {
      return this.state.capabilityOptionsBuy;
    }
  };

  isDisabled = () => {
    let isDisabled = true;

    this.state.categoryList.forEach((e) => {
      if (e.selectedOnCreate) {
        if (e.score) {
          isDisabled = false;
        }
      }
    });

    return isDisabled;
  };
}
