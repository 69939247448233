import React, { Component } from "react";
//PrimeReact Components
import * as pr from "../../common/PrimeComponents";
//Common Functions
import { api } from "../../common/CloudService/ApiPromisify";
import { CloudService } from "../../common/CloudService/ClientLibsReference";
import { LoadScreen } from "../Common/LoadScreen";

import firebase from "firebase";

//import * as fl from "./FilterFunctions";

export class Employee extends Component {
  constructor() {
    super();
    this.state = {
      //Loading
      loaded: false,
      editLoaded: true,
      //Display
      userSelected: false,
      userEdited: false,
      userAdded: false,
      userDeleted: false,
      //Details
      userList: [],
      selectedUser: "",
      userAddressLine1: "",
      userAddressLine2: "",
      userCodeTag: "",
      userContactTelephone: "",
      userDateCreated: "",
      userEmail: "",
      userFirstName: "",
      userId: "",
      userLastUpdatedDateTime: "",
      userPin: "",
      userPostcode: "",
      userRoleType: "",
      userSurname: "",
      userTown: "",
      //Edit Details
      editedUserAddressLine1: "",
      editedUserAddressLine2: "",
      editedUserContactTelephone: "",
      editedUserEmail: "",
      editedUserFirstName: "",
      editedUserPin: "",
      editedUserPostcode: "",
      editedUserRoleType: "",
      editedUserSurname: "",
      editedUserTown: "",
      //Other
      userPinList: [],
    };
  }

  componentDidMount = () => {
    this.getUsers();
  };

  getUsers = async () => {
    let userRequest = new CloudService.UserRequest();
    userRequest.codeTag = localStorage.getItem("clientId");
    userRequest.userId = localStorage.getItem("userId");

    await api("userApiV1UserListInBusinessGlobal", userRequest).then((res) => {
      let userResponse = CloudService.UserResponse.constructFromObject(
        res.data
      );
      console.log(userResponse);

      if (userResponse.errorCode === 0) {
        this.setState(
          {
            userList: userResponse.users,
          },
          () => {
            this.setPinList();
          }
        );
      } else {
        this.growl.show({
          severity: "error",
          summary: "Error",
          detail:
            "An error occured retrieving users, please refresh and try again",
          life: 3000,
        });
      }
    });

    this.setState({ loaded: true, editLoaded: true });
  };

  setPinList = () => {
    if (this.state.userList) {
      let userPinList = [];
      this.state.userList.forEach((e) => {
        userPinList.push(e.pin);
      });
      this.setState({ userPinList: userPinList }, () => {
        this.reselectUser();
      });
    }
  };

  checkPin = () => {
    let check = false;

    // if (
    //   this.state.userAdded === true ||
    //   this.state.editedUserPin !== this.state.userPin
    // ) {
    //   this.state.userPinList.forEach((e) => {
    //     if (this.state.editedUserPin === e) {
    //       check = true;
    //     }
    //   });
    // }

    return check;
  };

  updateUser = async () => {
    this.setState({ editLoaded: false });

    if (this.checkPin() === true) {
      this.setState({ editLoaded: true }, () => {
        this.growl.show({
          severity: "error",
          summary: "Error",
          detail: "The PIN entered is already in use, please enter another PIN",
          life: 3000,
        });
      });
    } else {
      let userRequest = new CloudService.UserRequest();
      userRequest.codeTag = localStorage.getItem("clientId");
      userRequest.userId = localStorage.getItem("userId");
      userRequest.id = this.state.userId;
      userRequest.lastUpdatedDateTime = this.state.userLastUpdatedDateTime;
      userRequest.firstName = this.state.editedUserFirstName;
      userRequest.surname = this.state.editedUserSurname;
      userRequest.email = this.state.editedUserEmail;

      await api("userApiV1UserUpdateAccountGlobal", userRequest).then((res) => {
        let userResponse = CloudService.UserResponse.constructFromObject(
          res.data
        );
        console.log(userResponse);

        if (userResponse.errorCode === 0) {
          this.setState({
            userEdited: false,
          });
          this.growl.show({
            severity: "success",
            summary: "Success",
            detail: "User successfully updated",
            life: 3000,
          });
          let typeOfUpdate = 1;
          this.updateUserList(userResponse, typeOfUpdate);
        } else {
          this.setState({ editLoaded: true });
          this.growl.show({
            severity: "error",
            summary: "Error",
            detail: "An error occured updating the user, please try again",
            life: 3000,
          });
        }
      });
    }
  };

  addUserFirebase = async () => {
    this.setState({ editLoaded: false });

    firebase
      .auth()
      .createUserWithEmailAndPassword(this.state.editedUserEmail, "password")
      .then(() => {
        firebase
          .auth()
          .sendPasswordResetEmail(this.state.editedUserEmail)
          .then(() => {
            this.addUser();
          });
      })
      .catch((error) => {
        this.setState({ editLoaded: true }, () => {
          this.growl.show({
            severity: "error",
            summary: "Error",
            detail: "Failed to create user, " + error.message.toLowerCase(),
            life: 3000,
          });
        });
      });
  };

  addUser = async () => {
    this.setState({ editLoaded: false });

    if (this.checkPin() === true) {
      this.setState({ editLoaded: true }, () => {
        this.growl.show({
          severity: "error",
          summary: "Error",
          detail: "The PIN entered is already in use, please enter another PIN",
          life: 3000,
        });
      });
    } else {
      let userRequest = new CloudService.UserRequest();
      userRequest.codeTag = localStorage.getItem("clientId");
      userRequest.userId = localStorage.getItem("userId");
      userRequest.firstName = this.state.editedUserFirstName;
      userRequest.surname = this.state.editedUserSurname;
      userRequest.email = this.state.editedUserEmail;

      userRequest.roleType = 50;

      await api("userApiV1UserCreateGlobal", userRequest).then((res) => {
        let userResponse = CloudService.UserResponse.constructFromObject(
          res.data
        );
        console.log(userResponse);

        if (userResponse.errorCode === 0) {
          this.setState({
            userEdited: false,
            userAdded: false,
          });
          this.growl.show({
            severity: "success",
            summary: "Success",
            detail: "User successfully created",
            life: 3000,
          });
          let typeOfUpdate = 2;
          this.updateUserList(userResponse, typeOfUpdate);
        } else {
          this.setState({ editLoaded: true });
          this.growl.show({
            severity: "error",
            summary: "Error",
            detail: "An error occured creating the user, please try again",
            life: 3000,
          });
        }
      });
    }
  };

  deleteUser = async () => {
    this.setState({ loaded: false });

    let userRequest = new CloudService.UserRequest();
    userRequest.codeTag = localStorage.getItem("clientId");
    userRequest.userId = localStorage.getItem("userId");
    userRequest.id = this.state.userId;
    userRequest.lastUpdatedDateTime = this.state.userLastUpdatedDateTime;

    await api("userApiV1UserDeleteGlobal", userRequest).then((res) => {
      let userResponse = CloudService.UserResponse.constructFromObject(
        res.data
      );
      console.log(userResponse);

      if (userResponse.errorCode === 0) {
        this.setState({
          userSelected: false,
        });
        this.growl.show({
          severity: "success",
          summary: "Success",
          detail: "User successfully deleted",
          life: 3000,
        });
        let typeOfUpdate = 3;
        this.updateUserList(userResponse, typeOfUpdate);
      } else {
        this.setState({ loaded: true });
        this.growl.show({
          severity: "error",
          summary: "Error",
          detail: "An error occured deleting the user, please try again",
          life: 3000,
        });
      }
    });
  };

  updateUserList = (apiResponse, type) => {
    let userList = this.state.userList;
    let i = 0;

    if (type === 2) {
      userList.push({
        id: apiResponse.id,
        lastUpdatedDateTime: apiResponse.lastUpdatedDateTime,
        firstName: this.state.editedUserFirstName,
        surname: this.state.editedUserSurname,
        email: this.state.editedUserEmail,
        roleType: this.state.editedUserRoleType,
      });
    } else {
      this.state.userList.forEach((e) => {
        if (apiResponse.id === e.id || this.state.userId === e.id) {
          if (type === 1) {
            userList[i].lastUpdatedDateTime = apiResponse.lastUpdatedDateTime;
            userList[i].firstName = this.state.editedUserFirstName;
            userList[i].surname = this.state.editedUserSurname;
            userList[i].email = this.state.editedUserEmail;

            userList[i].roleType = this.state.editedUserRoleType;
          }
          if (type === 3) {
            userList.splice(i, 1);
          }
        }
        i++;
      });
    }

    this.setState(
      { userList: userList, loaded: true, editLoaded: true },
      () => {
        console.log(this.state.userList);
        this.setPinList();
      }
    );
  };

  selectUser = (data) => {
    this.setState(
      {
        selectedUser: data,

        userCodeTag: data.codeTag,

        userDateCreated: data.dateCreated,
        userEmail: data.email,
        userFirstName: data.firstName,
        userId: data.id,
        userLastUpdatedDateTime: data.lastUpdatedDateTime,

        userRoleType: data.roleType,
        userSurname: data.surname,
      },
      () => {
        console.log(this.state.selectedUser);
      }
    );
  };

  editUser = () => {
    if (this.state.userAdded === true) {
      this.setState({
        editedUserEmail: "",
        editedUserFirstName: "",
        editedUserRoleType: "",
        editedUserSurname: "",
      });
    } else {
      this.setState({
        editedUserEmail: this.state.userEmail,
        editedUserFirstName: this.state.userFirstName,

        editedUserRoleType: this.state.userRoleType,
        editedUserSurname: this.state.userSurname,
      });
    }
  };

  reselectUser = () => {
    if (
      this.state.selectedUser !== undefined &&
      this.state.selectedUser !== ""
    ) {
      this.state.userList.forEach((e) => {
        if (this.state.userId === e.id) {
          this.setState(
            {
              userSelected: true,
              userEdited: false,
              userAdded: false,
            },
            () => {
              this.selectUser(e);
            }
          );
        }
      });
    }
  };

  checkRequiredFields = () => {
    let disable = false;

    if (
      this.state.editedUserFirstName &&
      this.state.editedUserSurname &&
      this.state.editedUserEmail
    ) {
      disable = false;
    } else {
      disable = true;
    }

    return disable;
  };

  roleTypeEditor = () => {
    return (
      <div className="p-col-6">
        <label className="input-label">&nbsp;Role Type *</label>
        <br />
        <pr.Dropdown
          value={this.state.editedUserRoleType}
          options={[10, 20, 30]}
          onChange={(e) => {
            this.setState({
              editedUserRoleType: e.value,
            });
          }}
          filter={false}
          filterBy="label"
          filterPlaceholder="Search"
          style={{ marginTop: "1px", width: "100%" }}
        />
      </div>
    );
  };

  render() {
    const s = this.state;

    return (
      <React.Fragment>
        <pr.Messages
          style={{ textAlign: "left" }}
          ref={(el) => (this.growl = el)}
        />

        <div
          className="p-grid dashboard"
          style={s.editLoaded === false ? { pointerEvents: "none" } : {}}
        >
          <pr.Dialog
            header="Delete User"
            footer={
              <div>
                <pr.Button
                  className="secondary-btn"
                  label="Yes"
                  icon="pi pi-check"
                  onClick={() => {
                    this.setState({ userDeleted: false });
                    this.deleteUser();
                  }}
                  style={{
                    float: "right",
                  }}
                />
                <pr.Button
                  className="secondary-btn"
                  label="No"
                  icon="pi pi-times"
                  onClick={() => {
                    this.setState({ userDeleted: false });
                  }}
                  style={{
                    float: "right",
                  }}
                />
              </div>
            }
            visible={s.userDeleted}
            style={{ width: "50vw" }}
            modal={true}
            onHide={(e) => this.setState({ userDeleted: false })}
          >
            Are you sure you want to delete?
          </pr.Dialog>

          {s.loaded === false ? (
            <LoadScreen />
          ) : (
            <div className="p-col-12">
              <div className="p-grid">
                {/*Users Card*/}
                {s.userSelected === false ? (
                  <label />
                ) : (
                  <div className="p-col-12 card">
                    <div className="p-col-12 p-grid">
                      <div className="p-col-6">
                        <b>First Name: </b>
                        <label>{s.userFirstName || "Not Set"}</label>
                      </div>
                      <div className="p-col-6">
                        <b>Surname: </b>
                        <label>{s.userSurname || "Not Set"}</label>
                      </div>
                    </div>
                    <div className="p-col-12 p-grid">
                      <div className="p-col-6">
                        <b>Email: </b>
                        <label>{s.userEmail || "Not Set"}</label>
                      </div>
                    </div>

                    <div className="p-col-12">
                      <pr.Button
                        label="Edit"
                        icon="pi pi-plus"
                        onClick={() => {
                          this.setState(
                            {
                              userSelected: false,
                              userEdited: true,
                              userAdded: false,
                            },
                            () => {
                              this.editUser();
                            }
                          );
                        }}
                        style={{ float: "right" }}
                      />
                      <pr.Button
                        label="Delete"
                        icon="pi pi-trash"
                        onClick={() => {
                          this.setState({ userDeleted: true });
                        }}
                        style={{ float: "right" }}
                      />
                      <pr.Button
                        className="secondary-btn"
                        label="Close"
                        icon="pi pi-times"
                        onClick={() => {
                          this.setState({
                            userSelected: false,
                            userEdited: false,
                            userAdded: false,
                            selectedUser: "",
                          });
                        }}
                        style={{ float: "right" }}
                      />
                    </div>
                  </div>
                )}

                {/*Editing Users Card*/}
                {s.userEdited === false ? (
                  <label />
                ) : (
                  <div className="p-col-12 card">
                    <div className="p-col-12 p-grid">
                      <div className="p-col-6">
                        <span className="md-inputfield form-field">
                          <pr.InputText
                            value={s.editedUserFirstName || ""}
                            onChange={(e) =>
                              this.setState({
                                editedUserFirstName: e.target.value,
                              })
                            }
                          />
                          <label className="input-label">First Name *</label>
                        </span>
                      </div>
                      <div className="p-col-6">
                        <span className="md-inputfield form-field">
                          <pr.InputText
                            value={s.editedUserSurname || ""}
                            onChange={(e) =>
                              this.setState({
                                editedUserSurname: e.target.value,
                              })
                            }
                          />
                          <label className="input-label">Surname *</label>
                        </span>
                      </div>
                    </div>
                    <div
                      className="p-col-12 p-grid"
                      style={
                        this.state.userAdded === true ? {} : { display: "none" }
                      }
                    >
                      <div className="p-col-6">
                        <span className="md-inputfield form-field">
                          <pr.InputText
                            value={s.editedUserEmail || ""}
                            onChange={(e) =>
                              this.setState({
                                editedUserEmail: e.target.value,
                              })
                            }
                          />
                          <label className="input-label">Email *</label>
                        </span>
                      </div>
                    </div>

                    <div className="p-col-12">
                      <pr.Button
                        disabled={this.checkRequiredFields()}
                        label="Save"
                        icon={
                          s.editLoaded === false
                            ? "pi pi-spin pi-spinner"
                            : "pi pi-check"
                        }
                        onClick={() => {
                          s.userAdded === true
                            ? this.addUserFirebase()
                            : this.updateUser();
                        }}
                        style={{
                          float: "right",
                        }}
                      />
                      <pr.Button
                        className="secondary-btn"
                        label="Cancel"
                        icon="pi pi-times"
                        onClick={() => {
                          s.userAdded === true
                            ? this.setState({
                                userSelected: false,
                                userEdited: false,
                                userAdded: false,
                              })
                            : this.setState({
                                userSelected: true,
                                userEdited: false,
                                userAdded: false,
                              });
                        }}
                        style={{ float: "right" }}
                      />
                    </div>
                  </div>
                )}

                <div className="p-col-12"></div>
                <div className="p-col-12">
                  <pr.Button
                    className="secondary-btn"
                    label="Create New"
                    icon="pi pi-pencil"
                    onClick={() => {
                      this.setState(
                        {
                          userSelected: false,
                          userEdited: true,
                          userAdded: true,
                          selectedUser: "",
                        },
                        () => {
                          this.editUser();
                        }
                      );
                    }}
                    style={{ float: "right" }}
                  />
                </div>
                <div className="p-col-12">
                  <pr.DataTable
                    value={s.userList}
                    selectionMode="single"
                    onSelectionChange={(e) => {
                      this.setState(
                        {
                          userSelected: true,
                          userEdited: false,
                          userAdded: false,
                        },
                        () => {
                          this.selectUser(e.value);
                        }
                      );
                    }}
                    selection={s.selectedUser}
                    emptyMessage="No Employees"
                    rows={Infinity}
                    autoLayout={true}
                    paginator={false}
                    alwaysShowPaginator={false}
                    responsive={true}
                    loading={this.props.loading}
                    scrollable={true}
                    scrollHeight="50vh"
                  >
                    <pr.Column
                      field="firstName"
                      header="First Name"
                      sortable={true}
                      filter={true}
                      filterMatchMode="contains"
                      style={{ overflow: "auto" }}
                    />
                    <pr.Column
                      field="surname"
                      header="Surame"
                      sortable={true}
                      filter={true}
                      filterMatchMode="contains"
                      style={{ overflow: "auto" }}
                    />
                    <pr.Column
                      field="email"
                      header="Email"
                      sortable={true}
                      filter={true}
                      filterMatchMode="contains"
                      style={{ overflow: "auto" }}
                    />
                  </pr.DataTable>
                </div>
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}
