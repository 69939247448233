import React from "react";
import { BusinessView } from "./BusinessView";
import {
  CrudlStatus,
  ToggleState,
  PageState,
  FeedbackState,
  ViewState,
  ValidateState,
  ViewType,
  BusinessType,
} from "../../common/config/AppConstants";
import * as pr from "../../common/PrimeComponents";
import * as format from "../../common/FormatFunctions";
import { ErrorScreen } from "../Common/ErrorScreen";
import * as analytics from "../../common/helpers/firebaseAnalytics";

export class BusinessController extends React.Component {
  state = {
    pageName: "BusinessController",
    pageState: PageState.IDLE,
    sidebarWidth: "100%",

    //DISPLAY STATES
    viewState: ViewState.TABLE,
    validateState: ValidateState.NONE,
    crudlState: CrudlStatus.LIST,
    menuState: [],

    // PROPS
    viewType: ViewType.STANDARD,

    // DATA TABLE
    expandedRows: null,
    tableColumns: [],
    columnOptions: [],
    settingsToggle: ToggleState.OFF,

    // DETAILS
    businessList: null,
    originalEditedBusiness: "",
    editedBusiness: null,
    receivedFeedback: {
      state: FeedbackState.NONE,
      display: "Business",
      crudlStatus: CrudlStatus.LIST,
    },
    profileObj: {},
    codeTag: null, // used when viewing a business
  };

  componentDidMount = async () => {
    await this.initialDataRender();
  };

  componentDidCatch = (error, info) => {
    this.props.log("This threw an error");
    this.props.log(error);
    this.props.log(info);
    //COMPONENT ISSUE CHECK IF THIS ERROR HANDLING IS CORRECT
    this.setError(false, "componentDidCatch", error);
  };

  initialDataRender = async () => {
    this.setViewModel();
    await this.setPageData();
  };

  setError = (critical, methodName, error) => {
    this.props.log.error(error.message);
    if (critical) {
      this.setCriticalError();
    } else {
      this.setStandardError();
    }
  };
  setStandardError = () => {
    //MORE CAN BE ADDED HERE AS APPROPRIATE
    this.showError("Business");
    this.setState({ pageState: PageState.IDLE });
  };
  setCriticalError = () => {
    //SET ERROR STATE OF PAGE
    this.setState({ pageState: PageState.ERROR });
  };

  setViewModel = () => {
    try {
      this.props.viewModel.updateState = this.updateState;
      this.props.viewModel.showError = this.showError;
      this.props.viewModel.showSuccess = this.showSuccess;
      this.props.viewModel.showEmpty = this.showEmpty;
    } catch (error) {
      this.setError(false, "setViewModel", error);
    }
  };

  setPageData = async () => {
    try {
      this.setState({ pageState: PageState.LOADING });

      this.menuState(CrudlStatus.LIST);

      await this.handleProps();
      this.setUpColumns();

      if (this.state.viewType === ViewType.STANDARD) {
        await this.props.viewModel.getBusinesss();
      } else if (this.state.viewType === ViewType.MY_BUSINESS) {
        await this.props.viewModel.getBusinessById();
        await this.props.viewModel.getProfile();
        analytics.screenViewEvent(analytics.PageView.MY_BUSINESS);
      } else if (this.state.viewType === ViewType.COUNCIL) {
        // using state to override when COUNCIL operating on behalf business and loggedIn codeTag would be incorrect
        await this.props.viewModel.getBusinessById(this.state.codeTag);
        await this.props.viewModel.getProfile(this.state.codeTag);
      } else if (this.state.viewType === ViewType.VIEW_BUSINESS) {
        await this.props.viewModel.getBusinessById(this.state.codeTag);
        await this.props.viewModel.getProfile(this.state.codeTag);
      }

      await this.props.viewModel.getBusinessIndustries();

      this.setState({
        pageState: PageState.IDLE,
      });
    } catch (error) {
      this.setError(true, "setPageData", error);
    }
  };
  handleProps = async () => {
    if (format.isNotNull(this.props.viewType)) {
      await this.setState({ viewType: this.props.viewType });
    }
    if (format.isNotNull(this.props.sidebarWidth)) {
      await this.setState({ sidebarWidth: this.props.sidebarWidth });
    }
    if (format.isNotNull(this.props.crudlState)) {
      await this.setState({ crudlState: this.props.crudlState });
    }
    if (format.isNotNull(this.props.codeTag)) {
      await this.setState({ codeTag: this.props.codeTag });
    }
  };
  showError = (display, crudlStatus, whereToView) => {
    try {
      if (whereToView === ViewState.TABLE) {
        this.props.feedback.showError(this.growl, display, crudlStatus);
      }
      if (whereToView === ViewState.CARD) {
        this.setState({
          receivedFeedback: {
            state: FeedbackState.ERROR,
            display: display,
            crudlStatus: crudlStatus,
          },
        });
      }
    } catch (error) {
      this.setError(false, "showError", error);
    }
  };

  showSuccess = (display, crudlStatus, whereToView) => {
    try {
      if (whereToView === ViewState.TABLE) {
        this.props.feedback.showSuccess(this.growl, display, crudlStatus);
      }
      if (whereToView === ViewState.CARD) {
        this.setState({
          receivedFeedback: {
            state: FeedbackState.SUCCESS,
            display: display,
            crudlStatus: crudlStatus,
          },
        });
      }
    } catch (error) {
      this.setError(false, "showSuccess", error);
    }
  };

  showEmpty = (display, whereToView) => {
    try {
      if (whereToView === ViewState.TABLE) {
        this.props.feedback.showEmpty(this.growl, display);
      }
      if (whereToView === ViewState.CARD) {
        this.setState({
          receivedFeedback: {
            state: FeedbackState.EMPTY,
            display: display,
            crudlStatus: CrudlStatus.LIST,
          },
        });
      }
    } catch (error) {
      this.setError(false, "showEmpty", error);
    }
  };

  getStateValuesAsObject = () => {
    try {
      var externalObject = {};
      externalObject.businessBean = this.state.editedBusiness;
      if (this.state.viewType === ViewType.COUNCIL) {
        externalObject.businessBean.codeTag = this.state.codeTag;
      }

      externalObject.viewType = this.state.viewType;

      return externalObject;
    } catch (error) {
      this.setError(false, "getStateValuesAsObject", error);
    }
  };

  updateBean = async (field, value) => {
    try {
      this.props.log.info(
        "Updating : [" + field + "] with value [" + value + "]"
      );
      await this.setState((prevState) => ({
        editedBusiness: {
          // object that we want to update
          ...prevState.editedBusiness, // keep all other key-value pairs
          [field]: value,
        },
      }));
    } catch (error) {
      this.setError(false, "updateBean", error);
    }
  };

  updateState = (key, value) => {
    try {
      this.setState({ [key]: value });
    } catch (error) {
      this.setError(false, "updateState", error);
    }
  };

  validateChanges = async () => {
    try {
      if (this.state.editedBusiness === null) {
        await this.discardChanges();
      } else {
        if (
          format.isJsonEqual(
            this.state.originalEditedBusiness,
            this.state.editedBusiness
          )
        ) {
          await this.discardChanges();
        } else {
          this.setState({ validateState: ValidateState.CONFIRM });
        }
      }
    } catch (error) {
      this.setError(false, "validateChanges", error);
    }
  };

  discardChanges = async () => {
    try {
      this.setState({
        viewState: ViewState.TABLE,
        toggleChangesMade: false,
        crudlState: CrudlStatus.LIST,
        selectedBusiness: null,
      });

      // TODO use viewType constant from AppConstants
      if (this.props.viewType !== "STANDARD") {
        if (format.isFunction(this.props.updateStateFromProps)) {
          // Toggle any relevant Views for create or update pages
          // this.props.updateStateFromProps("", false);
        }
      }

      await this.setPageData();
      this.menuState(CrudlStatus.LIST);
    } catch (error) {
      this.setError(false, "discardChanges", error);
    }
  };

  checkRequiredFields = () => {
    try {
      let fieldList = [];
      if (format.isNotNull(this.state.editedBusiness)) {
        if (this.state.crudlState === CrudlStatus.CREATE) {
          fieldList.push(this.state.editedBusiness.businessName);
          fieldList.push(this.state.editedBusiness.discoveredVia);
        }

        fieldList.push(this.state.editedBusiness.city);
        fieldList.push(this.state.editedBusiness.countyState);
        fieldList.push(this.state.editedBusiness.demographicArea);
        fieldList.push(this.state.editedBusiness.postalZipCode);
        fieldList.push(this.state.editedBusiness.email);
        fieldList.push(this.state.editedBusiness.telephoneNumber);
        fieldList.push(this.state.editedBusiness.subType);

        if (
          !this.state.editedBusiness.typeBuyer &&
          !this.state.editedBusiness.typeSupplier
        ) {
          return true;
        }

        return format.validateValues(fieldList);
      } else {
        return true;
      }
    } catch (error) {
      this.setError(false, "checkRequiredFields", error);
    }
  };

  menuState = (crudlStatus) => {
    try {
      this.setState({
        menuState: pr.getBreadcrumbMenuState(
          crudlStatus,
          "Business",
          "#/businesss"
        ),
      });
    } catch (error) {
      this.setError(false, "menuState", error);
    }
  };

  executeCrudl = async (stayOnPage) => {
    try {
      if (format.isNotNull(this.state.crudlState)) {
        this.props.log.info(
          "Executing " +
            this.state.crudlState.value +
            " Staying[" +
            stayOnPage +
            "]"
        );

        switch (this.state.crudlState) {
          case CrudlStatus.CREATE:
            await this.addBusiness(stayOnPage);
            break;
          case CrudlStatus.UPDATE:
            await this.updateBusiness(stayOnPage);
            break;
          case CrudlStatus.DELETE:
            await this.deleteBusiness();
            break;
          default:
            break;
        }
      }
    } catch (error) {
      this.setError(false, "executeCrudl", error);
    }
  };

  executeSetup = async (requiredCrudlState, data) => {
    try {
      if (format.isNotNull(requiredCrudlState)) {
        this.props.log.info("Setting up " + requiredCrudlState.value);
        switch (requiredCrudlState) {
          case CrudlStatus.CREATE:
            await this.createSetup();
            break;
          case CrudlStatus.UPDATE:
            await this.updateSetup(data);
            break;
          case CrudlStatus.VIEW:
            await this.viewSetup(data);
            break;
          case CrudlStatus.DELETE:
            await this.deleteSetup(data);
            break;
          default:
            break;
        }
      }
    } catch (error) {
      this.setError(false, "executeSetup", error);
    }
  };

  setUpColumns = () => {
    let localCols = [];
    //SPECIFIC COLUMNS FOR TABLE
    localCols.push(pr.getTableColumn("name", "Name"));
    localCols.push(pr.getTableColumn("description", "Description"));
    localCols.push(pr.getTableColumn("type", "Type"));
    localCols.push(pr.getTableColumn("subType", "Industry"));

    this.setState({ tableColumns: localCols });
    var colOptions = pr.getColumnOptions(localCols);
    this.setState({ columnOptions: colOptions });
  };

  columnToggle = (event) => {
    this.setState({ tableColumns: event.value });
  };

  createSetup = async () => {
    window.scrollTo(0, 0);

    var data = {};
    data = await this.additionalParseFunctions(data, CrudlStatus.CREATE);

    this.setState({
      viewState: ViewState.CARD,
      crudlState: CrudlStatus.CREATE,
      editedBusiness: data,
      originalEditedBusiness: JSON.stringify(data),
    });

    this.menuState(CrudlStatus.CREATE);
  };

  updateSetup = async (data) => {
    window.scrollTo(0, 0);

    data = await this.additionalParseFunctions(data, CrudlStatus.UPDATE);

    this.setState({
      viewState: ViewState.CARD,
      crudlState: CrudlStatus.UPDATE,
      editedBusiness: data,
      originalEditedBusiness: JSON.stringify(data),
    });

    this.menuState(CrudlStatus.UPDATE);
  };

  viewSetup = async (data) => {
    window.scrollTo(0, 0);

    data = await this.additionalParseFunctions(data, CrudlStatus.VIEW);

    this.setState({
      viewState: ViewState.CARD,
      crudlState: CrudlStatus.VIEW,
      editedBusiness: data,
      originalEditedBusiness: JSON.stringify(data),
    });

    this.menuState(CrudlStatus.VIEW);
  };

  deleteSetup = async (data) => {
    data = await this.additionalParseFunctions(data, CrudlStatus.DELETE);

    this.setState({
      crudlState: CrudlStatus.DELETE,
      editedBusiness: data,
      originalEditedBusiness: JSON.stringify(data),
      validateState: ValidateState.DELETE,
    });
    this.menuState(CrudlStatus.DELETE);
  };

  additionalParseFunctions = async (data, crudlState) => {
    //TODO if required
    switch (crudlState) {
      case CrudlStatus.CREATE:
        break;
      case CrudlStatus.UPDATE || CrudlStatus.VIEW:
        break;
      case CrudlStatus.DELETE:
        break;
      default:
        break;
    }
    this.props.log.info(data);
    return data;
  };

  addBusiness = async (stayOnPage) => {
    this.setState({ pageState: PageState.LOADING });
    var stateVariables = this.getStateValuesAsObject();

    if (!format.isNotNull(stayOnPage) || stayOnPage === false) {
      stateVariables.viewState = ViewState.TABLE;
    } else {
      stateVariables.viewState = ViewState.CARD;
    }

    var businessResponse = await this.props.viewModel.executeCRUDLAction(
      CrudlStatus.CREATE,
      stateVariables
    );

    if (businessResponse.errorCode === 0) {
      if (!format.isNotNull(stayOnPage) || stayOnPage === false) {
        await this.updateBusinessList(
          businessResponse,
          CrudlStatus.CREATE,
          stateVariables
        );
      } else {
        await this.updateCurrentBusiness(
          businessResponse,
          CrudlStatus.CREATE,
          stateVariables
        );
      }
    } else {
      this.setState({ pageState: PageState.IDLE });
    }
  };
  updateBusiness = async (stayOnPage) => {
    this.setState({ pageState: PageState.LOADING });
    var stateVariables = this.getStateValuesAsObject();

    if (!format.isNotNull(stayOnPage) || stayOnPage === false) {
      stateVariables.viewState = ViewState.TABLE;
    } else {
      stateVariables.viewState = ViewState.CARD;
    }

    let businessName = stateVariables.businessBean.businessName;
    delete stateVariables.businessBean.businessName;

    var businessResponse = await this.props.viewModel.executeCRUDLAction(
      CrudlStatus.UPDATE,
      stateVariables
    );

    if (businessResponse.errorCode === 0) {
      if (this.props.viewType === ViewType.MY_BUSINESS) {
        let editedBusiness = format.deepCopy(this.state.editedBusiness);
        editedBusiness.lastUpdatedDateTime =
          businessResponse.lastUpdatedDateTime;
        editedBusiness.businessName = businessName;

        console.log(editedBusiness);
        await this.updateProfile(editedBusiness.type);

        this.setState({
          editedBusiness: editedBusiness,
          pageState: PageState.IDLE,
        });
      } else {
        if (!format.isNotNull(stayOnPage) || stayOnPage === false) {
          await this.updateBusinessList(
            businessResponse,
            CrudlStatus.CREATE,
            stateVariables
          );
        } else {
          await this.updateCurrentBusiness(
            businessResponse,
            CrudlStatus.CREATE,
            stateVariables
          );
        }
      }
    } else {
      this.setState({ pageState: PageState.IDLE });
    }
  };

  updateProfile = async (type) => {
    console.log(type);
    let profileRequest = format.deepCopy(this.state.profileObj);

    console.log(profileRequest);
    profileRequest.type = type;
    delete profileRequest.profileStatus; // we dont want profile approval email to be sent.
    if (format.isNotNull(profileRequest.profileItemList)) {
      delete profileRequest.profileItemList;
    }

    let profileResponse = await this.props.viewModel.updateProfile(
      profileRequest
    );
    if (profileResponse.errorCode === 0) {
      let profileObj = format.deepCopy(this.state.profileObj);
      profileObj.lastUpdatedDateTime = profileResponse.lastUpdatedDateTime;

      this.setState({
        profileObj: profileObj,
      });
    }
  };

  deleteBusiness = async () => {
    this.setState({ pageState: PageState.LOADING });
    var stateVariables = this.getStateValuesAsObject();

    var businessResponse = await this.props.viewModel.executeCRUDLAction(
      CrudlStatus.DELETE,
      stateVariables
    );

    if (businessResponse.errorCode === 0) {
      await this.updateBusinessList(
        businessResponse,
        CrudlStatus.DELETE,
        stateVariables
      );
    } else {
      this.setState({ pageState: PageState.IDLE });
    }
  };

  updateBusinessList = async (apiResponse, type, stateVariables) => {
    this.setState({ pageState: PageState.LOADING });

    // TODO use viewType constant from AppConstants
    if (this.props.viewType !== "STANDARD") {
      if (format.isFunction(this.props.updateObjectFromProps)) {
        // any specific setting variables back up stream for props
        // this.props.updateObjectFromProps(
        //   "name",
        //   stateVariables.businessBean.name
        // );
      }
    }

    await this.props.viewModel.getBusinesss();

    // toggle any create or update Props views to close or change
    if (this.props.viewType !== "STANDARD") {
      if (format.isFunction(this.props.updateStateFromProps)) {
        // this.props.updateStateFromProps("toggleCreateBusiness", false); // example
        // this.props.updateStateFromProps("businessList", this.state.businessList); // example
      }
    }

    this.setState({
      viewState: ViewState.TABLE,
      crudlState: CrudlStatus.LIST,
      validateState: ValidateState.NONE,
      editedBusiness: null,
    });

    this.menuState(CrudlStatus.LIST);

    this.setState({ pageState: PageState.IDLE });
  };

  updateCurrentBusiness = async (apiResponse, type, stateVariables) => {
    this.setState({ pageState: PageState.LOADING });

    var businessList = await this.props.viewModel.getBusinesssReturned();
    var business = {};
    if (format.isNotNull(businessList)) {
      businessList.forEach((element) => {
        if (element.id === apiResponse.id) {
          if (element.type === BusinessType.BUYER) {
            element.typeBuyer = true;
          } else if (element.type === BusinessType.SUPPLIER) {
            element.typeSupplier = true;
          } else if (element.type === BusinessType.BUYERSUPPLIER) {
            element.typeBuyer = true;
            element.typeSupplier = true;
          }
          business = format.deepCopy(element);
        }
      });
    }
    await this.updateSetup(business);
    this.setState({ businessList: businessList });
    this.setState({ pageState: PageState.IDLE });
  };

  updateBusinessType = (target, value) => {
    let editedBusiness = format.deepCopy(this.state.editedBusiness);
    this.updateBean(target, value);
    editedBusiness[target] = value;

    if (editedBusiness.typeBuyer && !editedBusiness.typeSupplier) {
      this.updateBean("type", BusinessType.BUYER);
    } else if (!editedBusiness.typeBuyer && editedBusiness.typeSupplier) {
      this.updateBean("type", BusinessType.SUPPLIER);
    } else if (editedBusiness.typeBuyer && editedBusiness.typeSupplier) {
      this.updateBean("type", BusinessType.BUYERSUPPLIER);
    } else {
      this.updateBean("type", null);
    }
  };

  render() {
    return (
      <React.Fragment>
        <pr.Messages
          style={{ textAlign: "left" }}
          ref={(el) => (this.growl = el)}
        />

        {/*VERY VERY BAD ERROS ONLY eg PAGE LOAD*/}
        {this.state.pageState === PageState.ERROR && (
          <ErrorScreen
            loading={this.state.pageState === PageState.LOADING}
            refresh={this.setPageData}
          />
        )}

        {this.state.pageState !== PageState.ERROR && (
          <BusinessView
            //STATE + HELPER VALUES
            viewState={this.state.viewState}
            validateState={this.state.validateState}
            crudlState={this.state.crudlState}
            menuState={this.state.menuState}
            loading={this.state.pageState === PageState.LOADING}
            updateState={this.updateState}
            refresh={this.setPageData}
            feedback={this.props.feedback}
            receivedFeedback={this.state.receivedFeedback}
            //CARD SPECIFIC PROPS
            viewType={this.state.viewType}
            editedObject={this.state.editedBusiness}
            businessTypeOptions={this.state.businessTypeOptions}
            updateEdited={this.updateBean}
            validateChanges={this.validateChanges}
            discardChanges={this.discardChanges}
            checkRequiredFields={this.checkRequiredFields}
            crudlExecute={this.executeCrudl}
            sidebarWidth={this.state.sidebarWidth}
            //TABLE SPECIFIC PROPS
            crudlControl={this.executeSetup}
            businessList={this.state.businessList}
            expandedRows={this.state.expandedRows}
            tableColumns={this.state.tableColumns}
            columnOptions={this.state.columnOptions}
            columnToggle={this.columnToggle}
            tableReference={this.state.tableReference}
            settingsToggle={this.state.settingsToggle}
            updateBusinessType={this.updateBusinessType}
            profileObj={this.state.profileObj}
            showTutorial={this.state.showTutorial}
          />
        )}
      </React.Fragment>
    );
  }
}
