import { BaseViewModel } from "../../common/BaseViewModel";
import * as Format from "../../common/FormatFunctions";
import { CloudService } from "../../common/CloudService/ClientLibsReference";

import { CrudlStatus, ViewState } from "../../common/config/AppConstants";
import { api } from "../../common/CloudService/ApiPromisify";
import * as pr from "../../common/PrimeComponents";
export class RequirementViewModel extends BaseViewModel {
  showLoadingScreen = () => {
    this.updateState("loaded", false);
  };

  hideLoadingScreen = () => {
    this.updateState("loaded", true);
  };

  getPossibleComponents = async () => {
    let componentRequest = new CloudService.ComponentRequest();
    componentRequest.codeTag = this.codeTag;
    componentRequest.userId = this.userId;

    componentRequest.type = "PPE";

    await api("componentApiV1ComponentGetByType", componentRequest).then(
      (res) => {
        if (res.data) {
          let componentResponse =
            CloudService.ComponentResponse.constructFromObject(res.data);
          console.log(componentResponse);
          this.updateState(
            "componentList",
            pr.getDropdownOptions(componentResponse.componentList)
          );
        }
      }
    );
  };
  getRequirements = async () => {
    let productRequest = new CloudService.ProductRequest();
    productRequest.codeTag = this.codeTag;
    productRequest.userId = this.userId;

    productRequest.type = "PPEBuyer";

    await api("productApiV1ProductGetByCodeTag", productRequest).then((res) => {
      console.log(res);
      if (res.data) {
        let productResponse = CloudService.ProductResponse.constructFromObject(
          res.data
        );
        console.log(productResponse);
        if (
          Format.isNotNull(productResponse.errorCode) &&
          productResponse.errorCode === 0
        ) {
          if (Format.isNotNull(productResponse.productList)) {
            this.updateState("requirementList", productResponse.productList);
            this.logger.info(productResponse.productList);
          } else {
            this.showEmpty("My Requirements", ViewState.TABLE);
          }
        } else {
          this.showError("My Requirements", CrudlStatus.LIST, ViewState.TABLE);
        }
      }
    });
  };
  getRequirementsReturned = async () => {
    let productRequest = new CloudService.ProductRequest();
    productRequest.codeTag = this.codeTag;
    productRequest.userId = this.userId;

    productRequest.type = "PPEBuyer";

    await api("productApiV1ProductGetByCodeTag", productRequest).then((res) => {
      if (res.data) {
        let productResponse = CloudService.ProductResponse.constructFromObject(
          res.data
        );
        console.log(productResponse);
        if (
          Format.isNotNull(productResponse.errorCode) &&
          productResponse.errorCode === 0
        ) {
          if (Format.isNotNull(productResponse.productList)) {
            this.updateState("requirementList", productResponse.productList);
            return productResponse.productList;
          } else {
            this.showEmpty("My Requirements", ViewState.TABLE);
          }
        } else {
          this.showError("My Requirements", CrudlStatus.LIST, ViewState.TABLE);
        }
      }
    });
  };

  reloadDataAfterExecute = (
    requirements,
    apiResponse,
    type,
    externalStateValues
  ) => {
    var updatedRequirementList = [];
    if (type === CrudlStatus.CREATE) {
      updatedRequirementList = this.reloadAfterCreate(
        requirements,
        apiResponse,
        externalStateValues
      );
    }
    if (type === CrudlStatus.UPDATE) {
      updatedRequirementList = this.reloadAfterUpdate(
        requirements,
        apiResponse,
        externalStateValues
      );
    }
    if (type === CrudlStatus.DELETE) {
      updatedRequirementList = this.reloadAfterDelete(
        requirements,
        apiResponse,
        externalStateValues
      );
    }

    this.updateState("requirementList", updatedRequirementList);
  };

  reloadAfterCreate = (requirementList, apiResponse, externalStateValues) => {
    if (requirementList === undefined || requirementList === null) {
      requirementList = [];
    }
    var requirementBean = externalStateValues.requirementBean;
    requirementBean.id = apiResponse.id;
    requirementBean.lastUpdatedDateTime = apiResponse.lastUpdatedDateTime;

    requirementList.push(requirementBean);

    return requirementList;
  };

  reloadAfterUpdate = (requirementList, apiResponse, externalStateValues) => {
    var i = 0;
    requirementList.forEach((e) => {
      if (apiResponse.id === e.id) {
        requirementList[i] = externalStateValues.requirementBean;
        requirementList[i].lastUpdatedDateTime =
          apiResponse.lastUpdatedDateTime;
      }
      i++;
    });

    return requirementList;
  };

  reloadAfterDelete = (requirementList, apiResponse, externalStateValues) => {
    var i = 0;
    requirementList.forEach((e) => {
      //DELETE
      if (apiResponse.id === e.id) {
        requirementList.splice(i, 1);
      }
      i++;
    });

    return requirementList;
  };
  executeCRUDLAction = async (crudlStatus, stateVariables) => {
    let requirementRequest = stateVariables.requirementBean;

    if (crudlStatus === CrudlStatus.CREATE) {
      return await this.createRequirement(requirementRequest, stateVariables);
    }
    if (crudlStatus === CrudlStatus.UPDATE) {
      return await this.updateRequirement(requirementRequest, stateVariables);
    }
    if (crudlStatus === CrudlStatus.DELETE) {
      return await this.deleteRequirement(requirementRequest);
    }
  };

  createRequirement = async (requirementRequest, stateVariables) => {
    requirementRequest.codeTag = this.codeTag;
    requirementRequest.userId = this.userId;

    requirementRequest.type = "PPEBuyer";

    if (Format.isNotNull(requirementRequest.componentId)) {
      requirementRequest.componentList = [requirementRequest.componentId];

      if (Format.isNotNull(stateVariables.componentList)) {
        stateVariables.componentList.forEach((element) => {
          if (element.value === requirementRequest.componentId) {
            requirementRequest.componentNameToDisplay = element.label;
            requirementRequest.name = element.label;
          }
        });
      }
    }
    console.log(requirementRequest);
    var productResponse = {};

    await api("productApiV1ProductCreate", requirementRequest).then((res) => {
      if (res.data) {
        productResponse = CloudService.ProductResponse.constructFromObject(
          res.data
        );
        console.log(productResponse);
        if (
          Format.isNotNull(productResponse.errorCode) &&
          productResponse.errorCode === 0
        ) {
          this.showSuccess(
            "My Requirements",
            CrudlStatus.CREATE,
            stateVariables.viewState
          );
        } else {
          this.showError("My Requirements", CrudlStatus.CREATE, ViewState.CARD);
        }
      }
    });

    return productResponse;
  };

  deleteRequirement = async (requirementRequest) => {
    var request = {};
    request.codeTag = this.codeTag;
    request.userId = this.userId;
    request.id = requirementRequest.id;
    request.lastUpdatedDateTime = requirementRequest.lastUpdatedDateTime;
    var productResponse = {};

    await api("productApiV1ProductDelete", requirementRequest).then((res) => {
      if (res.data) {
        productResponse = CloudService.ProductResponse.constructFromObject(
          res.data
        );
        console.log(productResponse);
        if (
          Format.isNotNull(productResponse.errorCode) &&
          productResponse.errorCode === 0
        ) {
          this.showSuccess(
            "My Requirements",
            CrudlStatus.DELETE,
            ViewState.TABLE
          );
        } else {
          this.showError("My Requirements", CrudlStatus.CREATE, ViewState.CARD);
        }
      }
    });

    return productResponse;
  };

  updateRequirement = async (requirementRequest, stateVariables) => {
    requirementRequest.codeTag = this.codeTag;
    requirementRequest.userId = this.userId;

    requirementRequest.type = "PPEBuyer";

    if (Format.isNotNull(requirementRequest.componentId)) {
      requirementRequest.componentList = [requirementRequest.componentId];

      if (Format.isNotNull(stateVariables.componentList)) {
        stateVariables.componentList.forEach((element) => {
          if (element.value === requirementRequest.componentId) {
            requirementRequest.componentNameToDisplay = element.label;
          }
        });
      }
    }
    console.log(requirementRequest);
    var productResponse = {};

    await api("productApiV1ProductUpdate", requirementRequest).then((res) => {
      if (res.data) {
        productResponse = CloudService.ProductResponse.constructFromObject(
          res.data
        );
        console.log(productResponse);
        if (
          Format.isNotNull(productResponse.errorCode) &&
          productResponse.errorCode === 0
        ) {
          this.showSuccess(
            "My Requirements",
            CrudlStatus.CREATE,
            stateVariables.viewState
          );
        } else {
          this.showError("My Requirements", CrudlStatus.CREATE, ViewState.CARD);
        }
      }
    });

    return productResponse;
  };
}
