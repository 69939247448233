import React from "react";
import { CategoryCard } from "./UI/CategoryCard";

export class CategoryView extends React.Component {
  render() {
    return (
      <div
        className="dashboard"
        style={this.props.loading === true ? { pointerEvents: "none" } : {}}
      >
        <CategoryCard
          loading={this.props.loading}
          feedback={this.props.feedback}
          receivedFeedback={this.props.receivedFeedback}
          crudlState={this.props.crudlState}
          updateValue={this.props.updateEdited}
          updateState={this.props.updateState}
          editedObject={this.props.editedObject}
          checkRequiredFields={this.props.checkRequiredFields}
          discardChanges={this.props.discardChanges}
          validateState={this.props.validateState}
          validateChanges={this.props.validateChanges}
          crudlExecute={this.props.crudlExecute}
          viewType={this.props.viewType}
          //
          componentList={this.props.componentList}
          changeCategoriesState={this.props.changeCategoriesState}
          editedBusiness={this.props.editedBusiness}
        />
      </div>
    );
  }
}
