/**
 * buysupply-production.appspot.com
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.1
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient', 'model/BusinessBean'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('./BusinessBean'));
  } else {
    // Browser globals (root is window)
    if (!root.BuysupplyProductionappspotcom) {
      root.BuysupplyProductionappspotcom = {};
    }
    root.BuysupplyProductionappspotcom.ActivityRequest = factory(root.BuysupplyProductionappspotcom.ApiClient, root.BuysupplyProductionappspotcom.BusinessBean);
  }
}(this, function(ApiClient, BusinessBean) {
  'use strict';




  /**
   * The ActivityRequest model module.
   * @module model/ActivityRequest
   * @version 1.0.0
   */

  /**
   * Constructs a new <code>ActivityRequest</code>.
   * @alias module:model/ActivityRequest
   * @class
   */
  var exports = function() {
    var _this = this;












  };

  /**
   * Constructs a <code>ActivityRequest</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ActivityRequest} obj Optional instance to populate.
   * @return {module:model/ActivityRequest} The populated <code>ActivityRequest</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('codeTag')) {
        obj['codeTag'] = ApiClient.convertToType(data['codeTag'], 'String');
      }
      if (data.hasOwnProperty('companyDetails')) {
        obj['companyDetails'] = BusinessBean.constructFromObject(data['companyDetails']);
      }
      if (data.hasOwnProperty('dateCreated')) {
        obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
      }
      if (data.hasOwnProperty('dateCreatedToDisplayed')) {
        obj['dateCreatedToDisplayed'] = ApiClient.convertToType(data['dateCreatedToDisplayed'], 'String');
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('lastUpdatedDateTime')) {
        obj['lastUpdatedDateTime'] = ApiClient.convertToType(data['lastUpdatedDateTime'], 'Date');
      }
      if (data.hasOwnProperty('message')) {
        obj['message'] = ApiClient.convertToType(data['message'], 'String');
      }
      if (data.hasOwnProperty('type')) {
        obj['type'] = ApiClient.convertToType(data['type'], 'String');
      }
      if (data.hasOwnProperty('userId')) {
        obj['userId'] = ApiClient.convertToType(data['userId'], 'String');
      }
      if (data.hasOwnProperty('viewedProfile')) {
        obj['viewedProfile'] = ApiClient.convertToType(data['viewedProfile'], 'String');
      }
      if (data.hasOwnProperty('viewedProfileDetails')) {
        obj['viewedProfileDetails'] = BusinessBean.constructFromObject(data['viewedProfileDetails']);
      }
    }
    return obj;
  }

  /**
   * @member {String} codeTag
   */
  exports.prototype['codeTag'] = undefined;
  /**
   * @member {module:model/BusinessBean} companyDetails
   */
  exports.prototype['companyDetails'] = undefined;
  /**
   * @member {Date} dateCreated
   */
  exports.prototype['dateCreated'] = undefined;
  /**
   * @member {String} dateCreatedToDisplayed
   */
  exports.prototype['dateCreatedToDisplayed'] = undefined;
  /**
   * @member {String} id
   */
  exports.prototype['id'] = undefined;
  /**
   * @member {Date} lastUpdatedDateTime
   */
  exports.prototype['lastUpdatedDateTime'] = undefined;
  /**
   * @member {String} message
   */
  exports.prototype['message'] = undefined;
  /**
   * @member {String} type
   */
  exports.prototype['type'] = undefined;
  /**
   * @member {String} userId
   */
  exports.prototype['userId'] = undefined;
  /**
   * @member {String} viewedProfile
   */
  exports.prototype['viewedProfile'] = undefined;
  /**
   * @member {module:model/BusinessBean} viewedProfileDetails
   */
  exports.prototype['viewedProfileDetails'] = undefined;



  return exports;
}));


