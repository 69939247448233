import React from "react";
import { ApplicationCard } from "./UI/ApplicationCard";
import { ApplicationTable } from "./UI/ApplicationTable";
import { ApplicationOutlook } from "./UI/ApplicationOutlook";
import {
  ValidateState,
  ViewType,
  ViewState,
  CrudlStatus,
} from "../../common/config/AppConstants";
import * as pr from "../../common/PrimeComponents";
import * as format from "../../common/FormatFunctions";

import { MVVMValidateDialog } from "../Common/MVVMValidateDialog";
import { BusinessProvider } from "../Business/BusinessProvider";
export class ApplicationView extends React.Component {
  render() {
    return (
      <div
        className="dashboard"
        style={this.props.loading === true ? { pointerEvents: "none" } : {}}
      >
        {this.props.viewType === ViewType.ENQUIRIES &&
          this.props.toggleViewBusinessDetails && (
            <pr.Sidebar
              visible={true}
              position="left"
              fullScreen={this.props.sidebarWidth === "100%" ? true : false}
              onHide={() => {
                this.props.updateState("toggleViewBusinessDetails", false);
                this.props.updateState("viewBusinessId", null);
              }}
              baseZIndex={10000000000}
              style={{
                width: "75%",
                zIndex: 10000000000,
              }}
            >
              <BusinessProvider
                codeTag={this.props.viewBusinessId}
                crudlState={CrudlStatus.VIEW}
                viewType={ViewType.VIEW_BUSINESS}
                updateStateFromProps={this.props.updateState}
              />
            </pr.Sidebar>
          )}
        {this.props.viewType === ViewType.STANDARD ||
        this.props.viewType === ViewType.COUNCIL ||
        this.props.viewType === ViewType.CONTACT ? (
          <>
            {this.props.viewState === ViewState.CARD && (
              <React.Fragment>
                <pr.Sidebar
                  visible={true}
                  position="right"
                  fullScreen={this.props.sidebarWidth === "100%" ? true : false}
                  onHide={() => {
                    this.props.validateChanges();
                  }}
                  baseZIndex={10000000000}
                  style={{
                    width: this.props.sidebarWidth,
                    zIndex: 10000000000,
                  }}
                  icons={() => (
                    <div
                      className="p-col-12"
                      style={{
                        float: "left",
                        paddingLeft: "3rem",
                        fontSize: "initial",
                      }}
                    >
                      <div className="p-grid">
                        <div className="p-col-6">
                          {this.props.viewType === ViewType.CONTACT && (
                            <label className="header-label">{"Contact"}</label>
                          )}
                          {this.props.viewType === ViewType.STANDARD && (
                            <label className="header-label">
                              {format.capitalize(
                                this.props.crudlState.display
                              ) + " Enquiry"}
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                >
                  <MVVMValidateDialog
                    header="Application"
                    isVisible={
                      this.props.validateState === ValidateState.CONFIRM
                    }
                    visibleField="validateState"
                    updateVisibility={this.props.updateState}
                    runConfirm={this.props.discardChanges}
                    message={"Discard Changes?"}
                  />
                  <MVVMValidateDialog
                    header="Delete Application"
                    isVisible={
                      this.props.validateState === ValidateState.DELETE
                    }
                    visibleField="validateState"
                    updateVisibility={this.props.updateState}
                    message={" Are you sure you want to delete?"}
                    type={"CRUDL"}
                    crudlExecute={this.props.crudlExecute}
                    crudlState={this.props.crudlState}
                  />
                  <ApplicationCard
                    loading={this.props.loading}
                    feedback={this.props.feedback}
                    receivedFeedback={this.props.receivedFeedback}
                    crudlState={this.props.crudlState}
                    crudlControl={this.props.crudlControl}
                    updateValue={this.props.updateEdited}
                    updateState={this.props.updateState}
                    editedObject={this.props.editedObject}
                    checkRequiredFields={this.props.checkRequiredFields}
                    discardChanges={this.props.discardChanges}
                    validateState={this.props.validateState}
                    validateChanges={this.props.validateChanges}
                    crudlExecute={this.props.crudlExecute}
                    viewType={this.props.viewType}
                    businessOptions={this.props.businessOptions}
                    productOptions={this.props.productOptions}
                    // displayBusinessDetails={this.props.displayBusinessDetails}
                  />
                </pr.Sidebar>
                <div className="custom-overlay" />
              </React.Fragment>
            )}

            {this.props.viewType !== ViewType.CONTACT && (
              <ApplicationTable
                //CUSTOMISATION
                tableReference={this.props.tableReference}
                tableColumns={this.props.tableColumns}
                columnOptions={this.props.columnOptions}
                columnToggle={this.props.columnToggle}
                expandedRows={this.props.expandedRows}
                settingsToggle={this.props.settingsToggle}
                //
                updateState={this.props.updateState}
                updateValue={this.props.updateEdited}
                applicationList={this.props.applicationList}
                crudlControl={this.props.crudlControl}
                validateState={this.props.validateState}
                menuState={this.props.menuState}
                crudlState={this.props.crudlState}
                loading={this.props.loading}
                refresh={this.props.refresh}
                viewType={this.props.viewType}
                productDetails={this.props.productDetails}
                productDetailsView={this.props.productDetailsView}
                updateFromTable={this.props.updateFromTable}
                globalFilter={this.props.globalFilter}
                requestFeedback={this.props.requestFeedback}
                profileList={this.props.profileList}
                productList={this.props.productList}
                updateValueTableRowInList={this.props.updateValueTableRowInList}
                showDialogUpdate={this.props.showDialogUpdate}
                sidebarWidth={this.props.sidebarWidth}
                // council
                applicationType={this.props.applicationType}
                searchArchived={this.props.searchArchived}
                getApplicationsCouncil={this.props.getApplicationsCouncil}
                archiveApplication={this.props.archiveApplication}
                toggleUpdateApplication={this.props.toggleUpdateApplication}
                deleteApplication={this.props.deleteApplication}
              />
            )}
          </>
        ) : (
          <label />
        )}

        {this.props.viewType === ViewType.ENQUIRIES && (
          <ApplicationOutlook
            updateState={this.props.updateState}
            updateValue={this.props.updateEdited}
            applicationList={this.props.applicationList}
            crudlControl={this.props.crudlControl}
            validateState={this.props.validateState}
            menuState={this.props.menuState}
            crudlState={this.props.crudlState}
            loading={this.props.loading}
            refresh={this.props.refresh}
            viewType={this.props.viewType}
            productDetails={this.props.productDetails}
            productDetailsView={this.props.productDetailsView}
            updateFromTable={this.props.updateFromTable}
            globalFilter={this.props.globalFilter}
            feedback={this.props.feedback}
            requestFeedback={this.props.requestFeedback}
            profileList={this.props.profileList}
            productList={this.props.productList}
            updateValueTableRowInList={this.props.updateValueTableRowInList}
            showDialogUpdate={this.props.showDialogUpdate}
            editedObject={this.props.editedObject}
            discardChanges={this.props.discardChanges}
            displayBusinessDetails={this.props.displayBusinessDetails}
            checkRequiredFields={this.props.checkRequiredFields}
            validateChanges={this.props.validateChanges}
            crudlExecute={this.props.crudlExecute}
            businessOptions={this.props.businessOptions}
            productOptions={this.props.productOptions}
            showTutorial={this.props.showTutorial}
          />
        )}
      </div>
    );
  }
}
