import React, { Component } from "react";
//import classNames from "classnames";
//Imports from common
import { logout } from "./common/helpers/auth";

export class MasterInlineProfile extends Component {
  constructor() {
    super();
    this.state = {
      expanded: false,
    };
    this.onClick = this.onClick.bind(this);
  }

  onClick(event) {
    this.setState({ expanded: !this.state.expanded });
    event.preventDefault();
  }

  render() {
    return (
      <div>
        {/* <div
          className={classNames("profile", {
            "profile-expanded": this.state.expanded
          })}
        >
          <a onClick={this.onClick}>
            <img
              className="profile-image"
              src="assets/layout/images/avatar.png"
              alt="Profile"
            />
            <span className="profile-name">
              {localStorage.getItem("email")}
            </span>
            <i className="material-icons">keyboard_arrow_down</i>
          </a>
        </div> */}

        <ul className="ultima-menu profile-menu">
          <li
            role="menuitem"
            onClick={() => {
              logout();
              localStorage.clear();
              window.location = "#/";
              window.location.reload();
            }}
          >
            <button
              className="ripplelink"
              tabIndex={this.state.expanded ? null : "-1"}
            >
              <i className="material-icons">power_settings_new</i>
              <span>Logout</span>
            </button>
          </li>
        </ul>
      </div>
    );
  }
}
