import React from "react";
//PrimeReact Components
import * as pr from "../../../common/PrimeComponents";
import { ViewType } from "../../../common/config/AppConstants";

export class LoginCard extends React.Component {
  render() {
    const {
      loading,
      rememberMe,
      showPassword,
      email,
      password,
      handleSubmitFirebase,
      handleKeyPress,
      onInputChange,
      onCheckboxChange,
      toggleShowPassword,
      viewType,
    } = this.props;

    return (
      <div className="login-page" id={viewType} onKeyPress={handleKeyPress}>
        <div className="p-grid" style={{ marginRight: 0 }}>
          <div className="login-panel card">
            <div className="p-grid">
              <div
                className="p-col-12"
                style={{ paddingTop: "25px !important" }}
              >
                <img
                  src="/assets/layout/images/council/logo.png"
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
              <div className="p-col-12">
                <span className="md-inputfield form-field">
                  <pr.InputText
                    id="email"
                    value={email}
                    onChange={onInputChange}
                    style={{ textTransform: "lowercase" }}
                  />
                  <label htmlFor="email" className="imput-label">
                    Email Address
                  </label>
                </span>
              </div>
              <div className="p-col-12 p-grid">
                <div className="p-col-10">
                  <span className="md-inputfield form-field">
                    {showPassword ? (
                      <pr.InputText
                        id="password"
                        value={password}
                        onChange={onInputChange}
                      />
                    ) : (
                      <pr.Password
                        id="password"
                        value={password || ""}
                        onChange={onInputChange}
                        feedback={false}
                        promptLabel="Strength"
                      />
                    )}
                    <label htmlFor="password" className="imput-label">
                      Password
                    </label>
                  </span>
                </div>
                <div className="p-col-2">
                  <pr.Button
                    icon={
                      showPassword ? "pi-md-visibility-off" : "pi-md-visibility"
                    }
                    onClick={toggleShowPassword}
                  />
                </div>
              </div>
              <div className="p-col-12">
                <pr.Checkbox
                  id="rememberMe"
                  checked={rememberMe}
                  onChange={(e) => {
                    e.id = "rememberMe";
                    onCheckboxChange(e);
                  }}
                />
                <label htmlFor="rememberMe" className="custom-label">
                  Remember Me
                </label>
              </div>
              <div className="p-col-12">
                {viewType === ViewType.COUNCIL ? (
                  <pr.Button
                    label="Sign In"
                    icon={loading ? "pi-spin pi-md-autorenew" : "pi-md-send"}
                    onClick={handleSubmitFirebase}
                    style={{ width: "100%" }}
                  />
                ) : (
                  <>
                    <pr.Button
                      label="Sign In"
                      icon={loading ? "pi-spin pi-md-autorenew" : "pi-md-send"}
                      onClick={handleSubmitFirebase}
                      style={{ width: "100%" }}
                    />
                    <pr.Button
                      className="secondary-btn"
                      label="Register"
                      icon="pi pi-user-plus"
                      onClick={() => {
                        window.location = "#/register";
                      }}
                      style={{ width: "100%" }}
                    />
                  </>
                )}

                <br />
                <hr />
                <a href="#/password">I forgot my Password</a>
                <br />
                <hr />
              </div>
            </div>
          </div>
        </div>
        <img
          src="/assets/layout/images/council/logo-white.png"
          alt=""
          style={{
            position: "absolute",
            right: "1em",
            bottom: "1em",
            width: "6em",
            height: "2em",
            zIndex: "1",
          }}
        />
      </div>
    );
  }
}
