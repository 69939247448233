import { BaseViewModel } from "../../common/BaseViewModel";
import * as Format from "../../common/FormatFunctions";
import * as ApiService from "../../common/CloudService/ApiService";
import { CrudlStatus, ViewState } from "../../common/config/AppConstants";
import * as UIMap from "../../common/PrimeComponents";
export class ApplicationViewModel extends BaseViewModel {
  getApplications = async () => {
    //CRITICAL - ERROR + EMPTY MESSAGE
    var applicationResponse = await this.getApplicationListByCodeTag();
    if (
      Format.isNotNull(applicationResponse.errorCode) &&
      applicationResponse.errorCode === 0
    ) {
      if (Format.isNotNull(applicationResponse.applicationList)) {
        this.updateState(
          "applicationList",
          applicationResponse.applicationList
        );
        this.logger.info(applicationResponse.applicationList);
      } else {
        this.showEmpty("Application", ViewState.TABLE);
      }
    } else {
      this.showError("Application", CrudlStatus.LIST, ViewState.TABLE);
    }
  };

  getApplicationsByApplicationType = async (
    applicationType,
    archivedCouncil
  ) => {
    let request = {};
    request.codeTag = localStorage.getItem("globalBid");
    request.applicationType = applicationType;
    if (Format.isNotNull(archivedCouncil)) {
      request.archivedCouncil = archivedCouncil;
    }
    var applicationResponse = await this.getApplicationListByApplicationType(
      request
    );
    if (
      Format.isNotNull(applicationResponse.errorCode) &&
      applicationResponse.errorCode === 0
    ) {
      if (Format.isNotNull(applicationResponse.applicationList)) {
        this.updateState(
          "applicationList",
          applicationResponse.applicationList
        );
        this.logger.info(applicationResponse.applicationList);
      } else {
        this.updateState("applicationList", []);
        this.showEmpty("Application", ViewState.TABLE);
      }
    } else {
      this.showError("Application", CrudlStatus.LIST, ViewState.TABLE);
    }
  };

  getApplicationsReturned = async () => {
    //CRITICAL - ERROR + EMPTY MESSAGE
    var applicationResponse = await this.getApplicationListByCodeTag();
    if (
      Format.isNotNull(applicationResponse.errorCode) &&
      applicationResponse.errorCode === 0
    ) {
      if (Format.isNotNull(applicationResponse.applicationList)) {
        this.logger.info(applicationResponse.applicationList);
        return applicationResponse.applicationList;
      } else {
        this.showEmpty("Application", ViewState.TABLE);
      }
    } else {
      this.showError("Application", CrudlStatus.LIST, ViewState.TABLE);
    }
  };

  reloadDataAfterExecute = (
    applications,
    apiResponse,
    type,
    externalStateValues
  ) => {
    var updatedApplicationList = [];
    if (type === CrudlStatus.CREATE) {
      updatedApplicationList = this.reloadAfterCreate(
        applications,
        apiResponse,
        externalStateValues
      );
    }
    if (type === CrudlStatus.UPDATE) {
      updatedApplicationList = this.reloadAfterUpdate(
        applications,
        apiResponse,
        externalStateValues
      );
    }
    if (type === CrudlStatus.DELETE) {
      updatedApplicationList = this.reloadAfterDelete(
        applications,
        apiResponse,
        externalStateValues
      );
    }

    this.updateState("applicationList", updatedApplicationList);
  };

  reloadAfterCreate = (applicationList, apiResponse, externalStateValues) => {
    if (applicationList === undefined || applicationList === null) {
      applicationList = [];
    }
    var applicationBean = externalStateValues.applicationBean;
    applicationBean.id = apiResponse.id;
    applicationBean.lastUpdatedDateTime = apiResponse.lastUpdatedDateTime;

    applicationList.push(applicationBean);

    return applicationList;
  };

  reloadAfterUpdate = (applicationList, apiResponse, externalStateValues) => {
    var i = 0;
    applicationList.forEach((e) => {
      if (apiResponse.id === e.id) {
        applicationList[i] = externalStateValues.applicationBean;
        applicationList[i].lastUpdatedDateTime =
          apiResponse.lastUpdatedDateTime;
      }
      i++;
    });

    return applicationList;
  };

  reloadAfterDelete = (applicationList, apiResponse, externalStateValues) => {
    var i = 0;
    applicationList.forEach((e) => {
      //DELETE
      if (apiResponse.id === e.id) {
        applicationList.splice(i, 1);
      }
      i++;
    });

    return applicationList;
  };
  executeCRUDLAction = async (crudlStatus, stateVariables) => {
    let applicationRequest = stateVariables.applicationBean;

    if (crudlStatus === CrudlStatus.CREATE) {
      return await this.createApplication(applicationRequest, stateVariables);
    }
    if (crudlStatus === CrudlStatus.UPDATE) {
      return await this.updateApplication(applicationRequest, stateVariables);
    }
    if (crudlStatus === CrudlStatus.DELETE) {
      return await this.deleteApplication(applicationRequest);
    }
  };

  createApplication = async (applicationRequest, stateVariables) => {
    applicationRequest.codeTag = this.codeTag;
    applicationRequest.userId = this.userId;

    let applicationResponse = await ApiService.applicationCreate(
      applicationRequest
    );
    if (applicationResponse.errorCode === 0) {
      this.showSuccess(
        "Application",
        CrudlStatus.CREATE,
        stateVariables.viewState
      );
    } else {
      this.showError("Application", CrudlStatus.CREATE, ViewState.CARD);
    }

    return applicationResponse;
  };

  deleteApplication = async (applicationRequest) => {
    //RESET PRODUCT REQUEST
    var request = {};
    request.codeTag = applicationRequest.codeTag;
    request.userId = applicationRequest.userId;
    request.id = applicationRequest.id;
    request.lastUpdatedDateTime = applicationRequest.lastUpdatedDateTime;
    request.buyerId = applicationRequest.buyerId;

    let applicationResponse = await ApiService.applicationDelete(request);
    if (applicationResponse.errorCode === 0) {
      this.showSuccess("Application", CrudlStatus.DELETE, ViewState.TABLE);
    } else {
      this.showError("Application", CrudlStatus.DELETE, ViewState.CARD);
    }

    return applicationResponse;
  };

  updateApplication = async (applicationRequest, stateVariables) => {
    // applicationRequest.codeTag = this.codeTag;
    // applicationRequest.userId = this.userId;

    if (Format.isNotNull(applicationRequest.productDetailsList)) {
      delete applicationRequest.productDetailsList;
    }

    let applicationResponse = await ApiService.applicationUpdate(
      applicationRequest
    );
    if (applicationResponse.errorCode === 0) {
      this.showSuccess(
        "Application",
        CrudlStatus.UPDATE,
        stateVariables.viewState
      );
    } else {
      this.showError("Application", CrudlStatus.UPDATE, ViewState.CARD);
    }

    return applicationResponse;
  };

  getAllProfiles = async (type) => {
    //CRITICAL - ERROR + EMPTY MESSAGE
    let request = {};
    request.profileStatus = "Approved";
    if (Format.isNotNull(type)) {
      request.type = type;
    }
    var profileResponse = await this.getProfileListByProfileStatus(request);
    if (
      Format.isNotNull(profileResponse.errorCode) &&
      profileResponse.errorCode === 0
    ) {
      if (Format.isNotNull(profileResponse.profileList)) {
        this.updateState("profileList", profileResponse.profileList);

        let genericArray = [];
        let i = 0;
        if (profileResponse.profileList !== undefined) {
          console.log("ProfileListByProfileStatus");
          profileResponse.profileList.forEach((e) => {
            let obj = e.companyDetails;
            // if (Format.isNotNull(obj) && !Format.isNotNull(obj.businessName)) {
            //   obj.businessName = "Not Found";
            // } else {
            //   obj = {
            //     businessName: "TEST",
            //   };
            // }
            if (Format.isNotNull(obj)) {
              genericArray[i] = {
                label: obj.businessName,
                value: e.codeTag,
                id: e.id,
              };
              i++;
            } else {
              console.log("No Profile Company Details", e.id);
            }
          });
        }
        this.updateState("businessOptions", genericArray);
        this.updateState("profileList", profileResponse.profileList);
      } else {
        this.showEmpty("Profile", ViewState.TABLE);
      }
    } else {
      this.showError("Profile", CrudlStatus.LIST, ViewState.TABLE);
    }
  };

  getProductsByCodeTagAndType = async (type, codeTag) => {
    //CRITICAL - ERROR + EMPTY MESSAGE
    var productResponse = await this.getProductListByCodeTagAndType(
      type,
      codeTag
    );
    if (
      Format.isNotNull(productResponse.errorCode) &&
      productResponse.errorCode === 0
    ) {
      if (Format.isNotNull(productResponse.productList)) {
        let optionsArray = UIMap.getDropdownOptionsExtension(
          productResponse.productList,
          "name",
          "id"
        );
        this.updateState("productOptions", optionsArray);
        this.updateState("productList", productResponse.productList);
        return productResponse.productList;
      } else {
        this.showEmpty("Product", ViewState.TABLE);
      }
    } else {
      this.showError("Product", CrudlStatus.LIST, ViewState.TABLE);
    }
  };

  applicationFeedbackRequest = async (applicationRequest) => {
    // This is an update - use existing codeTag and userId from request params as to no conflict with potential council user intervention

    let applicationResponse = await ApiService.applicationFeedbackRequest(
      applicationRequest
    );

    return applicationResponse;
  };
}
