import React from "react";
import { DashboardView } from "./DashboardView";
import {
  CrudlStatus,
  ToggleState,
  PageState,
  FeedbackState,
  ViewType,
  ViewState,
  ValidateState,
  BusinessType,
  ApplicationStatus,
  ProductType,
  ProfileStatus,
  ProcessType,
} from "../../common/config/AppConstants";
import * as pr from "../../common/PrimeComponents";
import * as format from "../../common/FormatFunctions";
import { ErrorScreen } from "../Common/ErrorScreen";
import * as analytics from "../../common/helpers/firebaseAnalytics";

export class DashboardController extends React.Component {
  state = {
    pageName: "DashboardController",
    pageState: PageState.IDLE,
    sidebarWidth: "100%",

    //DISPLAY STATES
    viewState: ViewState.TABLE,
    validateState: ValidateState.NONE,
    crudlState: CrudlStatus.LIST,
    menuState: [],

    // PROPS
    viewType: ViewType.STANDARD,

    // DATA TABLE
    expandedRows: null,
    tableColumns: [],
    columnOptions: [],
    settingsToggle: ToggleState.OFF,

    // DETAILS
    dashboardList: null,
    originalEditedDashboard: "",
    editedDashboard: null,
    receivedFeedback: {
      state: FeedbackState.NONE,
      display: "Dashboard",
      crudlStatus: CrudlStatus.LIST,
    },
    applicationList: [],
    contactList: [],
    applicationsPending: [],
  };

  componentDidMount = async () => {
    await this.initialDataRender();
  };

  componentDidCatch = (error, info) => {
    this.props.log("This threw an error");
    this.props.log(error);
    this.props.log(info);
    //COMPONENT ISSUE CHECK IF THIS ERROR HANDLING IS CORRECT
    this.setError(false, "componentDidCatch", error);
  };

  initialDataRender = async () => {
    this.setViewModel();
    await this.setPageData();
  };

  setError = (critical, methodName, error) => {
    this.props.log.error(error.message);
    if (critical) {
      this.setCriticalError();
    } else {
      this.setStandardError();
    }
  };
  setStandardError = () => {
    //MORE CAN BE ADDED HERE AS APPROPRIATE
    this.showError("Dashboard");
    this.setState({ pageState: PageState.IDLE });
  };
  setCriticalError = () => {
    //SET ERROR STATE OF PAGE
    this.setState({ pageState: PageState.ERROR });
  };

  setViewModel = () => {
    try {
      this.props.viewModel.updateState = this.updateState;
      this.props.viewModel.showError = this.showError;
      this.props.viewModel.showSuccess = this.showSuccess;
      this.props.viewModel.showEmpty = this.showEmpty;
    } catch (error) {
      this.setError(false, "setViewModel", error);
    }
  };

  setPageData = async () => {
    try {
      this.setState({ pageState: PageState.LOADING });

      this.menuState(CrudlStatus.LIST);

      await this.handleProps();
      this.setUpColumns();

      if (this.state.viewType === ViewType.COUNCIL) {
        // business
        let businessPending = 0;
        let businessUnsuccessful = 0;
        let businessSuccessful = 0;
        // applications
        let applicationsSubmitted = 0;
        let applicationsSeen = 0;
        let applicationsInProgress = 0;
        let applicationsAccepted = 0;
        let applciationsUnsuccessful = 0;
        let applicationsWithdrawn = 0;

        let applicationListBuyer = [];
        let applicationListSupplier = [];

        let ppeBusinessStats = {
          businessPending: 0,
          businessUnsuccessful: 0,
          businessSuccessful: 0,
        };
        let ppeApplicationStats = {
          applicationsSubmitted: 0,
          applicationsSeen: 0,
          applicationsInProgress: 0,
          applicationsAccepted: 0,
          applciationsUnsuccessful: 0,
          applicationsWithdrawn: 0,
        };

        let applicationList = await this.props.viewModel.getAllApplications();
        if (format.isNotNull(applicationList)) {
          applicationList.forEach((e) => {
            if (
              e.applicationType === BusinessType.PPEBUYER ||
              e.applicationType === BusinessType.PPESUPPLIER
            ) {
              if (e.status === ApplicationStatus.SUBMITTED) {
                ppeApplicationStats.applicationsSubmitted++;
              }
              if (e.status === ApplicationStatus.SEEN) {
                ppeApplicationStats.applicationsSeen++;
              }
              if (e.status === ApplicationStatus.IN_PROGRESS) {
                ppeApplicationStats.applicationsInProgress++;
              }
              if (e.status === ApplicationStatus.ACCEPTED) {
                ppeApplicationStats.applicationsAccepted++;
              }
              if (e.status === ApplicationStatus.UNSUCCESSFUL) {
                ppeApplicationStats.applciationsUnsuccessful++;
              }
              if (e.status === ApplicationStatus.WITHDRAWN) {
                ppeApplicationStats.applicationsWithdrawn++;
              }
            } else {
              if (e.status === ApplicationStatus.SUBMITTED) {
                applicationsSubmitted++;
              }
              if (e.status === ApplicationStatus.SEEN) {
                applicationsSeen++;
              }
              if (e.status === ApplicationStatus.IN_PROGRESS) {
                applicationsInProgress++;
              }
              if (e.status === ApplicationStatus.ACCEPTED) {
                applicationsAccepted++;
              }
              if (e.status === ApplicationStatus.UNSUCCESSFUL) {
                applciationsUnsuccessful++;
              }
              if (e.status === ApplicationStatus.WITHDRAWN) {
                applicationsWithdrawn++;
              }
            }

            if (e.type === ProductType.BUYER) {
              applicationListBuyer.push(e);
            } else {
              applicationListSupplier.push(e);
            }
          });
        }

        let profileList = await this.props.viewModel.getAllProfiles();
        if (format.isNotNull(profileList)) {
          profileList.forEach((e) => {
            if (
              e.type === BusinessType.PPEBUYER ||
              e.type === BusinessType.PPESUPPLIER ||
              e.type === BusinessType.PPEBOTH
            ) {
              if (e.profileStatus === ProfileStatus.PENDING) {
                ppeBusinessStats.businessPending++;
              }
              if (e.profileStatus === ProfileStatus.APPROVED) {
                ppeBusinessStats.businessSuccessful++;
              }
              if (e.profileStatus === ProfileStatus.UNSUCCESSFUL) {
                ppeBusinessStats.businessUnsuccessful++;
              }
            } else {
              if (e.profileStatus === ProfileStatus.PENDING) {
                businessPending++;
              }
              if (e.profileStatus === ProfileStatus.APPROVED) {
                businessSuccessful++;
              }
              if (e.profileStatus === ProfileStatus.UNSUCCESSFUL) {
                businessUnsuccessful++;
              }
            }
          });
        }

        // Gap Analysis
        await this.props.viewModel.getAllMatrixAnalysis();

        this.setState({
          businessPending: businessPending,
          businessUnsuccessful: businessUnsuccessful,
          businessSuccessful: businessSuccessful,
          applicationsSubmitted: applicationsSubmitted,
          applicationsSeen: applicationsSeen,
          applicationsInProgress: applicationsInProgress,
          applicationsAccepted: applicationsAccepted,
          applciationsUnsuccessful: applciationsUnsuccessful,
          applicationsWithdrawn: applicationsWithdrawn,
          applicationListBuyer: applicationListBuyer,
          applicationListSupplier: applicationListSupplier,
          ppeBusinessStats: ppeBusinessStats,
          ppeApplicationStats: ppeApplicationStats,
          profileList: profileList,
        });
      } else {
        let viewType = "";
        let profileType = localStorage.getItem("profileType");
        if (profileType === BusinessType.BUYER) {
          viewType = ViewType.BUYER;
          analytics.screenViewEvent(analytics.PageView.BUYER_DASHBOARD);
        } else if (profileType === BusinessType.SUPPLIER) {
          viewType = ViewType.SUPPLIER;
          analytics.screenViewEvent(analytics.PageView.SUPPLIER_DASHBOARD);
        } else if (profileType === BusinessType.BUYERSUPPLIER) {
          viewType = ViewType.BUYERSUPPLIER;
          analytics.screenViewEvent(analytics.PageView.BUYERSUPPLIER_DASHBOARD);
        }

        let applicationList =
          await this.props.viewModel.getApplicationsByCodeTag();
        // await this.props.viewModel.getContactsByCodeTag();

        let applicationsPending = [];
        let contactList = [];
        if (format.isNotNull(applicationList)) {
          applicationList.forEach((e) => {
            if (
              e.status !== ApplicationStatus.ACCEPTED &&
              e.status !== ApplicationStatus.SUCCESSFUL &&
              e.status !== ApplicationStatus.WITHDRAWN &&
              e.status !== ApplicationStatus.UNSUCCESSFUL
            ) {
              applicationsPending.push(e);
            }
            if (e.processType === ProcessType.RECEIVED) {
              contactList.push(e);
            }
          });
        }
        this.setState({
          viewType: viewType,
          applicationsPending: applicationsPending,
          contactList: contactList,
        });
      }

      this.setState({
        pageState: PageState.IDLE,
      });
    } catch (error) {
      this.setError(true, "setPageData", error);
    }
  };
  handleProps = async () => {
    if (format.isNotNull(this.props.viewType)) {
      await this.setState({ viewType: this.props.viewType });
    }
    if (format.isNotNull(this.props.sidebarWidth)) {
      await this.setState({ sidebarWidth: this.props.sidebarWidth });
    }
    if (format.isNotNull(this.props.crudlState)) {
      await this.setState({ crudlState: this.props.crudlState });
      // Add Conditions per viewType if applicable
      if (this.props.crudlState === CrudlStatus.CREATE) {
        this.executeSetup(this.props.crudlState);
      } else if (this.props.crudlState === CrudlStatus.UPDATE) {
        this.executeSetup(
          this.props.crudlState,
          this.props.editedObjectFromProps
        );
      }
    }
  };
  showError = (display, crudlStatus, whereToView) => {
    try {
      if (whereToView === ViewState.TABLE) {
        this.props.feedback.showError(this.growl, display, crudlStatus);
      }
      if (whereToView === ViewState.CARD) {
        this.setState({
          receivedFeedback: {
            state: FeedbackState.ERROR,
            display: display,
            crudlStatus: crudlStatus,
          },
        });
      }
    } catch (error) {
      this.setError(false, "showError", error);
    }
  };

  showSuccess = (display, crudlStatus, whereToView) => {
    try {
      if (whereToView === ViewState.TABLE) {
        this.props.feedback.showSuccess(this.growl, display, crudlStatus);
      }
      if (whereToView === ViewState.CARD) {
        this.setState({
          receivedFeedback: {
            state: FeedbackState.SUCCESS,
            display: display,
            crudlStatus: crudlStatus,
          },
        });
      }
    } catch (error) {
      this.setError(false, "showSuccess", error);
    }
  };

  showEmpty = (display, whereToView) => {
    try {
      if (whereToView === ViewState.TABLE) {
        this.props.feedback.showEmpty(this.growl, display);
      }
      if (whereToView === ViewState.CARD) {
        this.setState({
          receivedFeedback: {
            state: FeedbackState.EMPTY,
            display: display,
            crudlStatus: CrudlStatus.LIST,
          },
        });
      }
    } catch (error) {
      this.setError(false, "showEmpty", error);
    }
  };

  getStateValuesAsObject = () => {
    try {
      var externalObject = {};
      externalObject.dashboardBean = this.state.editedDashboard;
      externalObject.viewType = this.state.viewType;

      return externalObject;
    } catch (error) {
      this.setError(false, "getStateValuesAsObject", error);
    }
  };

  updateBean = async (field, value) => {
    try {
      this.props.log.info(
        "Updating : [" + field + "] with value [" + value + "]"
      );
      await this.setState((prevState) => ({
        editedDashboard: {
          // object that we want to update
          ...prevState.editedDashboard, // keep all other key-value pairs
          [field]: value,
        },
      }));
    } catch (error) {
      this.setError(false, "updateBean", error);
    }
  };

  updateState = (key, value) => {
    try {
      this.setState({ [key]: value });
    } catch (error) {
      this.setError(false, "updateState", error);
    }
  };

  validateChanges = async () => {
    try {
      if (this.state.editedDashboard === null) {
        await this.discardChanges();
      } else {
        if (
          format.isJsonEqual(
            this.state.originalEditedDashboard,
            this.state.editedDashboard
          )
        ) {
          await this.discardChanges();
        } else {
          this.setState({ validateState: ValidateState.CONFIRM });
        }
      }
    } catch (error) {
      this.setError(false, "validateChanges", error);
    }
  };

  discardChanges = async () => {
    try {
      this.setState({
        viewState: ViewState.TABLE,
        toggleChangesMade: false,
        crudlState: CrudlStatus.LIST,
        selectedDashboard: null,
      });

      // TODO use viewType constant from AppConstants
      if (this.props.viewType !== "STANDARD") {
        if (format.isFunction(this.props.updateStateFromProps)) {
          // Toggle any relevant Views for create or update pages
          // this.props.updateStateFromProps("", false);
        }
      }

      await this.setPageData();
      this.menuState(CrudlStatus.LIST);
    } catch (error) {
      this.setError(false, "discardChanges", error);
    }
  };

  checkRequiredFields = () => {
    try {
      if (format.isNotNull(this.state.editedDashboard)) {
        // ADD REQUIREDD FIELDS HERE AS APPROPRIATE
        return format.validateValues([this.state.editedDashboard.name]);
      } else {
        return true;
      }
    } catch (error) {
      this.setError(false, "checkRequiredFields", error);
    }
  };

  menuState = (crudlStatus) => {
    try {
      this.setState({
        menuState: pr.getBreadcrumbMenuState(
          crudlStatus,
          "Dashboard",
          "#/dashboards"
        ),
      });
    } catch (error) {
      this.setError(false, "menuState", error);
    }
  };

  executeCrudl = async (stayOnPage) => {
    try {
      if (format.isNotNull(this.state.crudlState)) {
        this.props.log.info(
          "Executing " +
            this.state.crudlState.value +
            " Staying[" +
            stayOnPage +
            "]"
        );

        switch (this.state.crudlState) {
          case CrudlStatus.CREATE:
            await this.addDashboard(stayOnPage);
            break;
          case CrudlStatus.UPDATE:
            await this.updateDashboard(stayOnPage);
            break;
          case CrudlStatus.DELETE:
            await this.deleteDashboard();
            break;
          default:
            break;
        }
      }
    } catch (error) {
      this.setError(false, "executeCrudl", error);
    }
  };

  executeSetup = async (requiredCrudlState, data) => {
    try {
      if (format.isNotNull(requiredCrudlState)) {
        this.props.log.info("Setting up " + requiredCrudlState.value);
        switch (requiredCrudlState) {
          case CrudlStatus.CREATE:
            await this.createSetup();
            break;
          case CrudlStatus.UPDATE:
            await this.updateSetup(data);
            break;
          case CrudlStatus.VIEW:
            await this.viewSetup(data);
            break;
          case CrudlStatus.DELETE:
            await this.deleteSetup(data);
            break;
          default:
            break;
        }
      }
    } catch (error) {
      this.setError(false, "executeSetup", error);
    }
  };

  setUpColumns = () => {
    let localCols = [];
    //SPECIFIC COLUMNS FOR TABLE
    localCols.push(pr.getTableColumn("name", "Name"));
    localCols.push(pr.getTableColumn("description", "Description"));
    localCols.push(pr.getTableColumn("type", "Type"));

    this.setState({ tableColumns: localCols });
    var colOptions = pr.getColumnOptions(localCols);
    this.setState({ columnOptions: colOptions });
  };

  columnToggle = (event) => {
    this.setState({ tableColumns: event.value });
  };

  createSetup = async () => {
    window.scrollTo(0, 0);

    var data = {};
    data = await this.additionalParseFunctions(data, CrudlStatus.CREATE);

    this.setState({
      viewState: ViewState.CARD,
      crudlState: CrudlStatus.CREATE,
      editedDashboard: data,
      originalEditedDashboard: JSON.stringify(data),
    });

    this.menuState(CrudlStatus.CREATE);
  };

  updateSetup = async (data) => {
    window.scrollTo(0, 0);

    data = await this.additionalParseFunctions(data, CrudlStatus.UPDATE);

    this.setState({
      viewState: ViewState.CARD,
      crudlState: CrudlStatus.UPDATE,
      editedDashboard: data,
      originalEditedDashboard: JSON.stringify(data),
    });

    this.menuState(CrudlStatus.UPDATE);
  };

  viewSetup = async (data) => {
    window.scrollTo(0, 0);

    data = await this.additionalParseFunctions(data, CrudlStatus.VIEW);

    this.setState({
      viewState: ViewState.CARD,
      crudlState: CrudlStatus.VIEW,
      editedDashboard: data,
      originalEditedDashboard: JSON.stringify(data),
    });

    this.menuState(CrudlStatus.VIEW);
  };

  deleteSetup = async (data) => {
    data = await this.additionalParseFunctions(data, CrudlStatus.DELETE);

    this.setState({
      crudlState: CrudlStatus.DELETE,
      editedDashboard: data,
      originalEditedDashboard: JSON.stringify(data),
      validateState: ValidateState.DELETE,
    });
    this.menuState(CrudlStatus.DELETE);
  };

  additionalParseFunctions = async (data, crudlState) => {
    //TODO if required
    switch (crudlState) {
      case CrudlStatus.CREATE:
        break;
      case CrudlStatus.UPDATE || CrudlStatus.VIEW:
        break;
      case CrudlStatus.DELETE:
        break;
      default:
        break;
    }
    this.props.log.info(data);
    return data;
  };

  addDashboard = async (stayOnPage) => {
    this.setState({ pageState: PageState.LOADING });
    var stateVariables = this.getStateValuesAsObject();

    if (!format.isNotNull(stayOnPage) || stayOnPage === false) {
      stateVariables.viewState = ViewState.TABLE;
    } else {
      stateVariables.viewState = ViewState.CARD;
    }

    var dashboardResponse = await this.props.viewModel.executeCRUDLAction(
      CrudlStatus.CREATE,
      stateVariables
    );

    if (dashboardResponse.errorCode === 0) {
      if (!format.isNotNull(stayOnPage) || stayOnPage === false) {
        await this.updateDashboardList(
          dashboardResponse,
          CrudlStatus.CREATE,
          stateVariables
        );
      } else {
        await this.updateCurrentDashboard(
          dashboardResponse,
          CrudlStatus.CREATE,
          stateVariables
        );
      }
    } else {
      this.setState({ pageState: PageState.IDLE });
    }
  };
  updateDashboard = async (stayOnPage) => {
    this.setState({ pageState: PageState.LOADING });
    var stateVariables = this.getStateValuesAsObject();

    if (!format.isNotNull(stayOnPage) || stayOnPage === false) {
      stateVariables.viewState = ViewState.TABLE;
    } else {
      stateVariables.viewState = ViewState.CARD;
    }

    var dashboardResponse = await this.props.viewModel.executeCRUDLAction(
      CrudlStatus.UPDATE,
      stateVariables
    );

    if (dashboardResponse.errorCode === 0) {
      if (!format.isNotNull(stayOnPage) || stayOnPage === false) {
        await this.updateDashboardList(
          dashboardResponse,
          CrudlStatus.CREATE,
          stateVariables
        );
      } else {
        await this.updateCurrentDashboard(
          dashboardResponse,
          CrudlStatus.CREATE,
          stateVariables
        );
      }
    } else {
      this.setState({ pageState: PageState.IDLE });
    }
  };

  deleteDashboard = async () => {
    this.setState({ pageState: PageState.LOADING });
    var stateVariables = this.getStateValuesAsObject();

    var dashboardResponse = await this.props.viewModel.executeCRUDLAction(
      CrudlStatus.DELETE,
      stateVariables
    );

    if (dashboardResponse.errorCode === 0) {
      await this.updateDashboardList(
        dashboardResponse,
        CrudlStatus.DELETE,
        stateVariables
      );
    } else {
      this.setState({ pageState: PageState.IDLE });
    }
  };

  updateDashboardList = async (apiResponse, type, stateVariables) => {
    this.setState({ pageState: PageState.LOADING });

    // TODO use viewType constant from AppConstants
    if (this.props.viewType !== "STANDARD") {
      if (format.isFunction(this.props.updateObjectFromProps)) {
        // any specific setting variables back up stream for props
        // this.props.updateObjectFromProps(
        //   "name",
        //   stateVariables.dashboardBean.name
        // );
      }
    }

    await this.props.viewModel.getDashboards();

    // toggle any create or update Props views to close or change
    if (this.props.viewType !== "STANDARD") {
      if (format.isFunction(this.props.updateStateFromProps)) {
        // this.props.updateStateFromProps("toggleCreateDashboard", false); // example
        // this.props.updateStateFromProps("dashboardList", this.state.dashboardList); // example
      }
    }

    this.setState({
      viewState: ViewState.TABLE,
      crudlState: CrudlStatus.LIST,
      validateState: ValidateState.NONE,
      editedDashboard: null,
    });

    this.menuState(CrudlStatus.LIST);

    this.setState({ pageState: PageState.IDLE });
  };

  updateCurrentDashboard = async (apiResponse, type, stateVariables) => {
    this.setState({ pageState: PageState.LOADING });

    var dashboardList = await this.props.viewModel.getDashboardsReturned();
    var dashboard = {};
    if (format.isNotNull(dashboardList)) {
      dashboardList.forEach((element) => {
        if (element.id === apiResponse.id) {
          dashboard = format.deepCopy(element);
        }
      });
    }
    await this.updateSetup(dashboard);
    this.setState({ dashboardList: dashboardList });
    this.setState({ pageState: PageState.IDLE });
  };

  render() {
    return (
      <React.Fragment>
        <pr.Messages
          style={{ textAlign: "left" }}
          ref={(el) => (this.growl = el)}
        />

        {/*VERY VERY BAD ERROS ONLY eg PAGE LOAD*/}
        {this.state.pageState === PageState.ERROR && (
          <ErrorScreen
            loading={this.state.pageState === PageState.LOADING}
            refresh={this.setPageData}
          />
        )}

        {this.state.pageState !== PageState.ERROR && (
          <DashboardView
            //STATE + HELPER VALUES
            viewState={this.state.viewState}
            validateState={this.state.validateState}
            crudlState={this.state.crudlState}
            menuState={this.state.menuState}
            loading={this.state.pageState === PageState.LOADING}
            updateState={this.updateState}
            refresh={this.setPageData}
            feedback={this.props.feedback}
            receivedFeedback={this.state.receivedFeedback}
            //CARD SPECIFIC PROPS
            viewType={this.state.viewType}
            editedObject={this.state.editedDashboard}
            dashboardTypeOptions={this.state.dashboardTypeOptions}
            updateEdited={this.updateBean}
            validateChanges={this.validateChanges}
            discardChanges={this.discardChanges}
            checkRequiredFields={this.checkRequiredFields}
            crudlExecute={this.executeCrudl}
            sidebarWidth={this.state.sidebarWidth}
            //TABLE SPECIFIC PROPS
            crudlControl={this.executeSetup}
            dashboardList={this.state.dashboardList}
            expandedRows={this.state.expandedRows}
            tableColumns={this.state.tableColumns}
            columnOptions={this.state.columnOptions}
            columnToggle={this.columnToggle}
            tableReference={this.state.tableReference}
            settingsToggle={this.state.settingsToggle}
            globalFilter={this.state.globalFilter}
            productFilterListEmpty={this.state.productFilterListEmpty}
            applicationList={this.state.applicationList}
            contactList={this.state.contactList}
            applicationsPending={this.state.applicationsPending}
            // Council Dashboard
            businessPending={this.state.businessPending}
            businessUnsuccessful={this.state.businessUnsuccessful}
            businessSuccessful={this.state.businessSuccessful}
            applicationsSubmitted={this.state.applicationsSubmitted}
            applicationsSeen={this.state.applicationsSeen}
            applicationsInProgress={this.state.applicationsInProgress}
            applicationsAccepted={this.state.applicationsAccepted}
            applciationsUnsuccessful={this.state.applciationsUnsuccessful}
            applicationsWithdrawn={this.state.applicationsWithdrawn}
            gapAnalysisOverall={this.state.gapAnalysisOverall}
            gapAnalysisBuyer={this.state.gapAnalysisBuyer}
            gapAnalysisSupplier={this.state.gapAnalysisSupplier}
            applicationListBuyer={this.state.applicationListBuyer}
            applicationListSupplier={this.state.applicationListSupplier}
            profileList={this.state.profileList}
            showTutorial={this.state.showTutorial}
            ppeBusinessStats={this.state.ppeBusinessStats}
            ppeApplicationStats={this.state.ppeApplicationStats}
          />
        )}
      </React.Fragment>
    );
  }
}
