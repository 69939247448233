import React, { Component } from "react";
import firebase from "firebase";
import * as pr from "../../common/PrimeComponents";
import { LoginViewModel } from "../Login/LoginViewModel";
import * as format from "../../common/FormatFunctions";

export class Verify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stripePublicKey: "",
      stripeSecretKey: "",
    };
    this.viewModel = new LoginViewModel();
  }

  componentDidMount = () => {};

  verifiedLogin = async (user) => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(async (idToken) => {
        localStorage.setItem("idToken", idToken);

        window.location = "#/login";
        window.location.reload();
      });
  };

  render() {
    return (
      <div className="p-grid">
        <div className="p-col-12">{/*spacing*/}</div>
        <div className="p-col-12">
          <div className="p-col-12">
            <pr.Messages
              style={{ textAlign: "left" }}
              ref={(el) => (this.growl = el)}
            />
            <div className="p-grid">
              <div className="p-col-4"></div>
              <div className="p-col-4">
                <div className="p-col-12">
                  <img
                    margin="0 auto"
                    src="/assets/layout/images/council/logo.png"
                    alt=""
                    height="100%"
                    width="100%"
                  />
                </div>
                <div
                  className="p-col-12 card"
                  style={{
                    paddingTop: "0",
                    paddingBottom: "0",
                    fontSize: "18px",
                    lineHeight: "30px",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <span>Please verify your account.</span>
                  </div>
                  <div>
                    <span>
                      Check your inbox for verification email, click on the link
                      and return to this page to continue
                    </span>
                  </div>
                  <div className="p-col-12">
                    <pr.Button
                      icon="pi pi-search"
                      label="Continue"
                      onClick={() => {
                        firebase
                          .auth()
                          .currentUser.reload()
                          .then(() => {
                            let user = firebase.auth().currentUser;

                            if (user.emailVerified) {
                              this.verifiedLogin(user);
                            } else {
                              this.growl.show({
                                severity: "warn",
                                summary: "Verification-",
                                detail:
                                  "Email is not verified, please verify your email and try again",
                                life: 3000,
                              });
                            }
                          });
                      }}
                      style={{ marginTop: "3.5px", marginBottom: "3.5px" }}
                    />
                  </div>
                  <div className="p-col-12">
                    <pr.Button
                      icon="pi pi-arrow-left"
                      label="Return to Login"
                      onClick={() => {
                        let loginPage = "#/login";
                        if (
                          format.isNotNull(localStorage.getItem("loginPage"))
                        ) {
                          loginPage = localStorage.getItem("loginPage");
                        }
                        window.location = loginPage;
                      }}
                      style={{ marginTop: "3.5px", marginBottom: "3.5px" }}
                    />
                  </div>
                </div>
              </div>
              <div className="p-col-4"></div>
            </div>
          </div>
        </div>
        <div className="p-col-12">
          <pr.Button
            className="mrgn flt-r"
            icon="pi pi-replay"
            label="Didnt receive an email? Click to resend"
            onClick={() => {
              let user = firebase.auth().currentUser;
              user.sendEmailVerification();
              this.growl.show({
                severity: "info",
                summary: "Verification - ",
                detail: "Please check your inbox or spam folder",
                life: 3000,
              });
            }}
            style={{ marginTop: "3.5px", marginBottom: "3.5px" }}
          />
        </div>
      </div>
    );
  }
}
