import React from "react";
import { ProfileView } from "./ProfileView";
import {
  CrudlStatus,
  ToggleState,
  PageState,
  FeedbackState,
  ViewType,
  ViewState,
  ValidateState,
  BusinessType,
} from "../../common/config/AppConstants";
import * as pr from "../../common/PrimeComponents";
import * as format from "../../common/FormatFunctions";
import { ErrorScreen } from "../Common/ErrorScreen";
import * as analytics from "../../common/helpers/firebaseAnalytics";

export class ProfileController extends React.Component {
  state = {
    pageName: "ProfileController",
    pageState: PageState.IDLE,
    sidebarWidth: "100%",

    //DISPLAY STATES
    viewState: ViewState.TABLE,
    validateState: ValidateState.NONE,
    crudlState: CrudlStatus.LIST,
    menuState: [],

    // PROPS
    viewType: ViewType.STANDARD,

    // DATA TABLE
    expandedRows: null,
    tableColumns: [],
    columnOptions: [],
    settingsToggle: ToggleState.OFF,

    // DETAILS
    profileList: null,
    originalEditedProfile: "",
    editedProfile: null,
    receivedFeedback: {
      state: FeedbackState.NONE,
      display: "Profile",
      crudlStatus: CrudlStatus.LIST,
    },
    selectedBusiness: {},
    toggleEditBusiness: false,
  };

  componentDidMount = async () => {
    let globalFilter = "";
    if (format.isNotNull(this.props.params)) {
      globalFilter = this.props.params.globalFilter;
    }
    if (format.isNotNull(globalFilter)) {
      await this.setState({ globalFilter: globalFilter });
    }
    await this.initialDataRender();
  };

  componentDidCatch = (error, info) => {
    this.props.log("This threw an error");
    this.props.log(error);
    this.props.log(info);
    //COMPONENT ISSUE CHECK IF THIS ERROR HANDLING IS CORRECT
    this.setError(false, "componentDidCatch", error);
  };

  initialDataRender = async () => {
    this.setViewModel();
    await this.setPageData();
  };

  setError = (critical, methodName, error) => {
    this.props.log.error(error.message);
    if (critical) {
      this.setCriticalError();
    } else {
      this.setStandardError();
    }
  };
  setStandardError = () => {
    //MORE CAN BE ADDED HERE AS APPROPRIATE
    this.showError("Profile");
    this.setState({ pageState: PageState.IDLE });
  };
  setCriticalError = () => {
    //SET ERROR STATE OF PAGE
    this.setState({ pageState: PageState.ERROR });
  };

  setViewModel = () => {
    try {
      this.props.viewModel.updateState = this.updateState;
      this.props.viewModel.showError = this.showError;
      this.props.viewModel.showSuccess = this.showSuccess;
      this.props.viewModel.showEmpty = this.showEmpty;
    } catch (error) {
      this.setError(false, "setViewModel", error);
    }
  };

  setPageData = async () => {
    try {
      this.setState({ pageState: PageState.LOADING });

      this.menuState(CrudlStatus.LIST);

      await this.handleProps();
      this.setUpColumns();

      // SPECIFIC API CALLS GO HERE
      let profileList = [];
      let componentList = [];
      if (this.props.viewType === ViewType.BUYER) {
        profileList = await this.props.viewModel.getProfilesByType(
          BusinessType.BUYER
        );
        analytics.screenViewEvent(analytics.PageView.BUYER_PROFILE);
      } else if (this.props.viewType === ViewType.SUPPLIER) {
        profileList = await this.props.viewModel.getProfilesByType(
          BusinessType.SUPPLIER
        );
        analytics.screenViewEvent(analytics.PageView.SUPPLIER_PROFILE);
      } else if (this.props.viewType === ViewType.COUNCIL) {
        profileList = await this.props.viewModel.getAllProfiles();
      } else if (this.props.viewType === ViewType.DASHBOARD) {
        let myProfile = await this.props.viewModel.getMyProfile();
        profileList =
          await this.props.viewModel.getProfilesByTypeAndComponentList(
            this.state.dashboardType,
            myProfile.componentList
          );
      }

      componentList =
        await this.props.viewModel.getComponentMatrixItemsByCodeTag();

      if (format.isNotNull(profileList)) {
        profileList.forEach((profile) => {
          let componentDisplayList = [];
          if (format.isNotNull(profile.componentList)) {
            profile.componentList.forEach((profileComponent) => {
              if (format.isNotNull(componentList)) {
                componentList.forEach((component) => {
                  if (profileComponent === component.id) {
                    if (format.isNotNull(component.matrixItemList)) {
                      component.matrixItemList.forEach((matrixItem) => {
                        if (matrixItem.codeTag === profile.codeTag) {
                          let componentToDisplay = {};
                          componentToDisplay.title = component.name;
                          componentToDisplay.score = matrixItem.score;
                          componentToDisplay.details =
                            matrixItem.otherComponent;
                          componentDisplayList.push(componentToDisplay);
                        }
                      });
                    }
                  }
                });
              }
            });
          }
          profile.componentDisplayList = componentDisplayList;
        });
      }

      this.setState({
        pageState: PageState.IDLE,
        profileList: profileList,
        componentList: componentList,
      });
    } catch (error) {
      this.setError(true, "setPageData", error);
    }
  };
  handleProps = async () => {
    if (format.isNotNull(this.props.viewType)) {
      await this.setState({ viewType: this.props.viewType });
    }
    // if (format.isNotNull(this.props.globalFilter)) {
    //   await this.setState({ globalFilter: this.props.globalFilter });
    // }
    if (format.isNotNull(this.props.dashboardType)) {
      await this.setState({ dashboardType: this.props.dashboardType });
    }
  };
  showError = (display, crudlStatus, whereToView) => {
    try {
      if (whereToView === ViewState.TABLE) {
        this.props.feedback.showError(this.growl, display, crudlStatus);
      }
      if (whereToView === ViewState.CARD) {
        this.setState({
          receivedFeedback: {
            state: FeedbackState.ERROR,
            display: display,
            crudlStatus: crudlStatus,
          },
        });
      }
    } catch (error) {
      this.setError(false, "showError", error);
    }
  };

  showSuccess = (display, crudlStatus, whereToView) => {
    try {
      if (whereToView === ViewState.TABLE) {
        this.props.feedback.showSuccess(this.growl, display, crudlStatus);
      }
      if (whereToView === ViewState.CARD) {
        this.setState({
          receivedFeedback: {
            state: FeedbackState.SUCCESS,
            display: display,
            crudlStatus: crudlStatus,
          },
        });
      }
    } catch (error) {
      this.setError(false, "showSuccess", error);
    }
  };

  showEmpty = (display, whereToView) => {
    try {
      if (whereToView === ViewState.TABLE) {
        this.props.feedback.showEmpty(this.growl, display);
      }
      if (whereToView === ViewState.CARD) {
        this.setState({
          receivedFeedback: {
            state: FeedbackState.EMPTY,
            display: display,
            crudlStatus: CrudlStatus.LIST,
          },
        });
      }
    } catch (error) {
      this.setError(false, "showEmpty", error);
    }
  };

  getStateValuesAsObject = (editedObject) => {
    try {
      var externalObject = {};
      if (format.isNotNull(editedObject)) {
        externalObject.profileBean = editedObject;
      } else {
        externalObject.profileBean = this.state.editedProfile;
      }

      externalObject.viewType = this.state.viewType;

      return externalObject;
    } catch (error) {
      this.setError(false, "getStateValuesAsObject", error);
    }
  };

  updateBean = async (field, value) => {
    try {
      this.props.log.info(
        "Updating : [" + field + "] with value [" + value + "]"
      );
      await this.setState((prevState) => ({
        editedProfile: {
          // object that we want to update
          ...prevState.editedProfile, // keep all other key-value pairs
          [field]: value,
        },
      }));
    } catch (error) {
      this.setError(false, "updateBean", error);
    }
  };

  updateState = (key, value) => {
    try {
      this.setState({ [key]: value });
    } catch (error) {
      this.setError(false, "updateState", error);
    }
  };

  validateChanges = async () => {
    try {
      if (this.state.editedProfile === null) {
        await this.discardChanges();
      } else {
        if (
          format.isJsonEqual(
            this.state.originalEditedProfile,
            this.state.editedProfile
          )
        ) {
          await this.discardChanges();
        } else {
          this.setState({ validateState: ValidateState.CONFIRM });
        }
      }
    } catch (error) {
      this.setError(false, "validateChanges", error);
    }
  };

  discardChanges = async () => {
    try {
      this.setState({
        viewState: ViewState.TABLE,
        toggleChangesMade: false,
        crudlState: CrudlStatus.LIST,
        selectedProfile: null,
      });

      // TODO use viewType constant from AppConstants
      if (this.props.viewType !== "STANDARD") {
        if (format.isFunction(this.props.updateStateFromProps)) {
          // Toggle any relevant Views for create or update pages
          // this.props.updateStateFromProps("", false);
        }
      }

      await this.setPageData();
      this.menuState(CrudlStatus.LIST);
    } catch (error) {
      this.setError(false, "discardChanges", error);
    }
  };

  checkRequiredFields = () => {
    try {
      if (format.isNotNull(this.state.editedProfile)) {
        // ADD REQUIREDD FIELDS HERE AS APPROPRIATE
        return format.validateValues([this.state.editedProfile.name]);
      } else {
        return true;
      }
    } catch (error) {
      this.setError(false, "checkRequiredFields", error);
    }
  };

  menuState = (crudlStatus) => {
    try {
      this.setState({
        menuState: pr.getBreadcrumbMenuState(
          crudlStatus,
          "Profile",
          "#/profiles"
        ),
      });
    } catch (error) {
      this.setError(false, "menuState", error);
    }
  };

  executeCrudl = async (stayOnPage) => {
    try {
      if (format.isNotNull(this.state.crudlState)) {
        this.props.log.info(
          "Executing " +
            this.state.crudlState.value +
            " Staying[" +
            stayOnPage +
            "]"
        );

        switch (this.state.crudlState) {
          case CrudlStatus.CREATE:
            await this.addProfile(stayOnPage);
            break;
          case CrudlStatus.UPDATE:
            await this.updateProfile(stayOnPage);
            break;
          case CrudlStatus.DELETE:
            await this.deleteProfile();
            break;
          default:
            break;
        }
      }
    } catch (error) {
      this.setError(false, "executeCrudl", error);
    }
  };

  executeSetup = async (requiredCrudlState, data) => {
    try {
      if (format.isNotNull(requiredCrudlState)) {
        this.props.log.info("Setting up " + requiredCrudlState.value);
        switch (requiredCrudlState) {
          case CrudlStatus.CREATE:
            await this.createSetup();
            break;
          case CrudlStatus.UPDATE:
            await this.updateSetup(data);
            break;
          case CrudlStatus.VIEW:
            await this.viewSetup(data);
            break;
          case CrudlStatus.DELETE:
            await this.deleteSetup(data);
            break;
          default:
            break;
        }
      }
    } catch (error) {
      this.setError(false, "executeSetup", error);
    }
  };

  setUpColumns = () => {
    let localCols = [];
    //SPECIFIC COLUMNS FOR TABLE
    if (this.props.viewType === ViewType.BUYER) {
      localCols.push(pr.getTableColumn("companyDetails.businessName", "Name"));
      localCols.push(pr.getTableColumn("type", "Type"));
    } else if (this.props.viewType === ViewType.SUPPLIER) {
      localCols.push(pr.getTableColumn("companyDetails.businessName", "Name"));
      localCols.push(pr.getTableColumn("type", "Type"));
    } else if (this.props.viewType === ViewType.COUNCIL) {
      localCols.push(pr.getTableColumn("companyDetails.businessName", "Name"));
      localCols.push(pr.getTableColumn("type", "Type", "equals"));
      localCols.push(pr.getTableColumn("profileStatus", "Profile Status"));
    } else if (this.state.viewType === ViewType.DASHBOARD) {
      localCols.push(pr.getTableColumn("companyDetails.businessName", "Name"));
      localCols.push(pr.getTableColumn("companyDetails.city", "Location"));
      localCols.push(
        pr.getTableColumn("companyDetails.demographicArea", "Demographic")
      );
    }

    this.setState({ tableColumns: localCols });
    var colOptions = pr.getColumnOptions(localCols);
    this.setState({ columnOptions: colOptions });
  };

  columnToggle = (event) => {
    this.setState({ tableColumns: event.value });
  };

  createSetup = async () => {
    window.scrollTo(0, 0);

    var data = {};
    data = await this.additionalParseFunctions(data, CrudlStatus.CREATE);

    this.setState({
      viewState: ViewState.CARD,
      crudlState: CrudlStatus.CREATE,
      editedProfile: data,
      originalEditedProfile: JSON.stringify(data),
    });

    this.menuState(CrudlStatus.CREATE);
  };

  updateSetup = async (data) => {
    window.scrollTo(0, 0);

    data = await this.additionalParseFunctions(data, CrudlStatus.UPDATE);

    this.setState({
      viewState: ViewState.CARD,
      crudlState: CrudlStatus.UPDATE,
      editedProfile: data,
      originalEditedProfile: JSON.stringify(data),
    });

    this.menuState(CrudlStatus.UPDATE);
  };

  viewSetup = async (data) => {
    window.scrollTo(0, 0);

    data = await this.additionalParseFunctions(data, CrudlStatus.VIEW);

    this.setState({
      viewState: ViewState.CARD,
      crudlState: CrudlStatus.VIEW,
      editedProfile: data,
      originalEditedProfile: JSON.stringify(data),
    });

    this.menuState(CrudlStatus.VIEW);
  };

  deleteSetup = async (data) => {
    data = await this.additionalParseFunctions(data, CrudlStatus.DELETE);

    this.setState({
      crudlState: CrudlStatus.DELETE,
      editedProfile: data,
      originalEditedProfile: JSON.stringify(data),
      validateState: ValidateState.DELETE,
    });
    this.menuState(CrudlStatus.DELETE);
  };

  additionalParseFunctions = async (data, crudlState) => {
    //TODO if required
    switch (crudlState) {
      case CrudlStatus.CREATE:
        break;
      case CrudlStatus.UPDATE || CrudlStatus.VIEW:
        break;
      case CrudlStatus.DELETE:
        break;
      default:
        break;
    }
    this.props.log.info(data);
    return data;
  };

  addProfile = async (stayOnPage) => {
    this.setState({ pageState: PageState.LOADING });
    var stateVariables = this.getStateValuesAsObject();

    if (!format.isNotNull(stayOnPage) || stayOnPage === false) {
      stateVariables.viewState = ViewState.TABLE;
    } else {
      stateVariables.viewState = ViewState.CARD;
    }

    var profileResponse = await this.props.viewModel.executeCRUDLAction(
      CrudlStatus.CREATE,
      stateVariables
    );

    if (profileResponse.errorCode === 0) {
      if (!format.isNotNull(stayOnPage) || stayOnPage === false) {
        await this.updateProfileList(
          profileResponse,
          CrudlStatus.CREATE,
          stateVariables
        );
      } else {
        await this.updateCurrentProfile(
          profileResponse,
          CrudlStatus.CREATE,
          stateVariables
        );
      }
    } else {
      this.setState({ pageState: PageState.IDLE });
    }
  };
  updateProfile = async (stayOnPage) => {
    this.setState({ pageState: PageState.LOADING });
    var stateVariables = this.getStateValuesAsObject();

    if (!format.isNotNull(stayOnPage) || stayOnPage === false) {
      stateVariables.viewState = ViewState.TABLE;
    } else {
      stateVariables.viewState = ViewState.CARD;
    }

    var profileResponse = await this.props.viewModel.executeCRUDLAction(
      CrudlStatus.UPDATE,
      stateVariables
    );

    if (profileResponse.errorCode === 0) {
      if (!format.isNotNull(stayOnPage) || stayOnPage === false) {
        await this.updateProfileList(
          profileResponse,
          CrudlStatus.CREATE,
          stateVariables
        );
      } else {
        await this.updateCurrentProfile(
          profileResponse,
          CrudlStatus.CREATE,
          stateVariables
        );
      }
    } else {
      this.setState({ pageState: PageState.IDLE });
    }
  };

  deleteProfile = async () => {
    this.setState({ pageState: PageState.LOADING });
    var stateVariables = this.getStateValuesAsObject();

    var profileResponse = await this.props.viewModel.executeCRUDLAction(
      CrudlStatus.DELETE,
      stateVariables
    );

    if (profileResponse.errorCode === 0) {
      await this.updateProfileList(
        profileResponse,
        CrudlStatus.DELETE,
        stateVariables
      );
    } else {
      this.setState({ pageState: PageState.IDLE });
    }
  };

  updateProfileList = async (apiResponse, type, stateVariables) => {
    this.setState({ pageState: PageState.LOADING });

    // TODO use viewType constant from AppConstants
    if (this.props.viewType !== "STANDARD") {
      if (format.isFunction(this.props.updateObjectFromProps)) {
        // any specific setting variables back up stream for props
        // this.props.updateObjectFromProps(
        //   "name",
        //   stateVariables.profileBean.name
        // );
      }
    }

    if (this.props.viewType === ViewType.BUYER) {
      await this.props.viewModel.getProfilesByType(BusinessType.BUYER);
    } else if (this.props.viewType === ViewType.SUPPLIER) {
      await this.props.viewModel.getProfilesByType(BusinessType.SUPPLIER);
    } else if (this.props.viewType === ViewType.COUNCIL) {
      await this.props.viewModel.getProfiles();
    }

    // toggle any create or update Props views to close or change
    if (this.props.viewType !== "STANDARD") {
      if (format.isFunction(this.props.updateStateFromProps)) {
        // this.props.updateStateFromProps("toggleCreateProfile", false); // example
        // this.props.updateStateFromProps("profileList", this.state.profileList); // example
      }
    }

    this.setState({
      viewState: ViewState.TABLE,
      crudlState: CrudlStatus.LIST,
      validateState: ValidateState.NONE,
      editedProfile: null,
    });

    this.menuState(CrudlStatus.LIST);

    this.setState({ pageState: PageState.IDLE });
  };

  updateCurrentProfile = async (apiResponse, type, stateVariables) => {
    this.setState({ pageState: PageState.LOADING });

    var profileList = {};
    if (this.props.viewType === ViewType.BUYER) {
      profileList = await this.props.viewModel.getProfilesByType(
        BusinessType.BUYER
      );
    } else if (this.props.viewType === ViewType.SUPPLIER) {
      profileList = await this.props.viewModel.getProfilesByType(
        BusinessType.SUPPLIER
      );
    } else if (this.props.viewType === ViewType.COUNCIL) {
      profileList = await this.props.viewModel.getProfiles();
    }

    var profile = {};
    if (format.isNotNull(profileList)) {
      profileList.forEach((element) => {
        if (element.id === apiResponse.id) {
          profile = format.deepCopy(element);
        }
      });
    }
    await this.updateSetup(profile);
    this.setState({ profileList: profileList });
    this.setState({ pageState: PageState.IDLE });
  };

  updateProfileStatus = async (profile, profileStatus) => {
    this.setState({ pageState: PageState.LOADING });

    profile.profileStatus = profileStatus;
    var stateVariables = this.getStateValuesAsObject(profile);
    stateVariables.viewState = ViewState.TABLE;

    var profileResponse = await this.props.viewModel.executeCRUDLAction(
      CrudlStatus.UPDATE,
      stateVariables
    );

    profile.lastUpdatedDateTime = profileResponse.lastUpdatedDateTime;

    if (profileResponse.errorCode === 0) {
      if (format.isNotNull(this.state.profileList)) {
        let updatedList = [];
        this.state.profileList.forEach((e) => {
          if (e.id === profile.id) {
            updatedList.push(profile);
          } else {
            updatedList.push(e);
          }
        });
        this.setState({ profileList: updatedList });
      }
    }

    this.setState({ pageState: PageState.IDLE });
  };

  toggleApplicationCreate = async (rowData) => {
    console.log(rowData);
    let productToContact = {
      codeTag: rowData.codeTag,
      type: rowData.type,
    };
    if (this.props.viewType === ViewType.BUYER) {
      analytics.screenViewEvent(analytics.PageView.VIEW_BUYER_PRODUCT_DETAILS);
      analytics.logApplicationBusinessEvent(
        analytics.Actions.BUYER_PROFILE_CONTACT,
        rowData
      );
      this.setState({ productToContact: productToContact });
    } else if (this.props.viewType === ViewType.SUPPLIER) {
      analytics.screenViewEvent(
        analytics.PageView.VIEW_SUPPLIER_PRODUCT_DETAILS
      );
      analytics.logApplicationBusinessEvent(
        analytics.Actions.SUPPLIER_PROFILE_CONTACT,
        rowData
      );
      this.setState({ productToContact: productToContact });
    } else if (this.props.viewType === ViewType.DASHBOARD) {
      if (this.props.dashboardType === BusinessType.BUYER) {
        analytics.screenViewEvent(
          analytics.PageView.VIEW_BUYER_PRODUCT_DETAILS
        );
        analytics.logApplicationProductEvent(
          analytics.Actions.BUYER_PROFILE_CONTACT,
          rowData
        );
      } else if (this.props.dashboardType === BusinessType.SUPPLIER) {
        analytics.screenViewEvent(
          analytics.PageView.VIEW_SUPPLIER_PRODUCT_DETAILS
        );
        analytics.logApplicationProductEvent(
          analytics.Actions.SUPPLIER_PROFILE_CONTACT,
          rowData
        );
      }
    }
    this.setState({ toggleApplication: true });
  };

  editBusiness = (profileBean) => {
    // should only be hit by COUNCIL users acting on behalf of a business
    console.log("PROFILE BEAN", profileBean);
    this.setState({ toggleEditBusiness: true, selectedBusiness: profileBean });
  };

  render() {
    return (
      <React.Fragment>
        <pr.Messages
          style={{ textAlign: "left" }}
          ref={(el) => (this.growl = el)}
        />

        {/*VERY VERY BAD ERROS ONLY eg PAGE LOAD*/}
        {this.state.pageState === PageState.ERROR && (
          <ErrorScreen
            loading={this.state.pageState === PageState.LOADING}
            refresh={this.setPageData}
          />
        )}

        {this.state.pageState !== PageState.ERROR && (
          <ProfileView
            //STATE + HELPER VALUES
            viewState={this.state.viewState}
            validateState={this.state.validateState}
            crudlState={this.state.crudlState}
            menuState={this.state.menuState}
            loading={this.state.pageState === PageState.LOADING}
            updateState={this.updateState}
            refresh={this.setPageData}
            feedback={this.props.feedback}
            receivedFeedback={this.state.receivedFeedback}
            //CARD SPECIFIC PROPS
            viewType={this.state.viewType}
            editedObject={this.state.editedProfile}
            profileTypeOptions={this.state.profileTypeOptions}
            updateEdited={this.updateBean}
            validateChanges={this.validateChanges}
            discardChanges={this.discardChanges}
            checkRequiredFields={this.checkRequiredFields}
            crudlExecute={this.executeCrudl}
            sidebarWidth={this.state.sidebarWidth}
            //TABLE SPECIFIC PROPS
            crudlControl={this.executeSetup}
            profileList={this.state.profileList}
            expandedRows={this.state.expandedRows}
            tableColumns={this.state.tableColumns}
            columnOptions={this.state.columnOptions}
            columnToggle={this.columnToggle}
            tableReference={this.state.tableReference}
            settingsToggle={this.state.settingsToggle}
            globalFilter={
              this.props.globalFilter
                ? this.props.globalFilter
                : this.state.globalFilter
            }
            dashboardType={this.state.dashboardType}
            updateProfileStatus={this.updateProfileStatus}
            toggleApplicationCreate={this.toggleApplicationCreate}
            toggleApplication={this.state.toggleApplication}
            showTutorial={this.state.showTutorial}
            productToContact={this.state.productToContact}
            // should only be used by COUNCIL users on behalf of business
            editBusiness={this.editBusiness}
            toggleEditBusiness={this.state.toggleEditBusiness}
            selectedBusiness={this.state.selectedBusiness}
          />
        )}
      </React.Fragment>
    );
  }
}
