import React from "react";
//PrimeReact Components
import * as pr from "../../../common/PrimeComponents";
import { Input, InputType, LabelPosition } from "../../Common/Input";
import { ImageViewer } from "../../Common/ImageViewer";
import { FileViewer } from "../../Common/FileViewer";
import * as format from "../../../common/FormatFunctions";
import { ViewType } from "../../../common/config/AppConstants";

export class ProductBatchSummary extends React.Component {
  componentWillUnmount = () => {
    this.props.updateState("expandedRows", null);
  };
  render() {
    return (
      <React.Fragment>
        <div className="scrl">
          <pr.DataTable
            value={this.props.sortedBatchList}
            editMode="cell"
            editable={true}
            className="editable-cells-table"
            loading={this.props.loading}
            expandedRows={this.props.expandedRows}
            onRowToggle={(e) => this.props.updateState("expandedRows", e.data)}
            rowExpansionTemplate={this.getDisplayCard}
          >
            <pr.Column expander style={{ width: "3em" }} />
            <pr.Column field="name" header="Name" />
            <pr.Column field="componentNameToDisplay" header="Type" />
            {this.props.viewType === ViewType.MY_PRODUCT && (
              <pr.Column field="location" header="Location" />
            )}
            <pr.Column field="productStatus" header="Status" />
            {this.props.viewType === ViewType.MY_REQUIREMENT && (
              <pr.Column field="dateRequiredToDisplay" header="Date Required" />
            )}

            {this.props.batchProductsCreate === true && (
              <pr.Column
                field="state"
                header="Progress"
                body={(rowData, column) => {
                  if (rowData.state === "COMPLETE") {
                    return <pr.Checkbox checked={true} />;
                  } else if (rowData.state === "FAILED") {
                    return <label style={{ color: "red" }}>{"FAILED"}</label>;
                  } else {
                    return (
                      <pr.ProgressBar
                        mode="indeterminate"
                        style={{ height: "6px" }}
                      />
                    );
                  }
                }}
              />
            )}
          </pr.DataTable>
        </div>
        <div className="sidebar-footer">
          <hr />
          {this.props.batchProductsCreate === true &&
            this.props.productFailure !== true && (
              <pr.Button
                className="mrgn flt-r"
                label="Finish"
                icon="pi-md-save"
                onClick={() => {
                  this.props.finishBatchUpload();
                }}
              />
            )}
          {this.props.productFailure === true && (
            <pr.Button
              className="mrgn flt-l"
              label="Review Failed Batch List"
              icon="pi-md-arrow-left"
              onClick={() => {
                this.props.setUpBatchListOfFailures();
              }}
            />
          )}
          {this.props.batchProductsCreate !== true && (
            <React.Fragment>
              <pr.Button
                className="mrgn flt-l"
                label="Cancel"
                icon="pi-md-close"
                onClick={() => {
                  this.props.updateState("displayBatchSummary", false);
                  this.props.updateState("batchUpload", true);
                }}
              />
              <pr.Button
                className="mrgn flt-r"
                label="Save"
                icon="pi-md-book"
                onClick={() => {
                  this.props.createBatchProducts();
                }}
              />
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
  getDisplayCard = (data) => {
    return (
      <div className="p-col-12">
        <div className="p-grid">
          <div className="p-col-12">
            <h3>{"Here is how your product will appear"}</h3>
          </div>

          <div className="p-col-12">
            {format.isNotNull(data) && (
              <div className="p-grid card" style={{ width: "100%" }}>
                <div className="p-col-6">
                  <Input
                    id={"name"}
                    labelDisplay={"Name"}
                    labelDisplayPosition={LabelPosition.LEFT}
                    required={false}
                    type={InputType.STRING}
                    disabled={true}
                    value={data.name}
                    field={"Name"}
                    visible={true}
                  />
                </div>
                <div className="p-col-6">
                  <Input
                    id={"componentNameToDisplay"}
                    labelDisplay={"Category"}
                    labelDisplayPosition={LabelPosition.LEFT}
                    required={false}
                    type={InputType.STRING}
                    disabled={true}
                    value={data.componentNameToDisplay}
                    field={"componentNameToDisplay"}
                    visible={true}
                  />
                </div>
                {/* <div className="p-col-6">
                  <Input
                    id={"businessName"}
                    labelDisplay={"Supplier Name"}
                    labelDisplayPosition={LabelPosition.LEFT}
                    required={false}
                    type={InputType.STRING}
                    disabled={true}
                    value={data.businessName}
                    field={"businessName"}
                    visible={true}
                  />
                </div> */}
                {this.props.viewType === ViewType.MY_PRODUCT && (
                  <div className="p-col-6">
                    <Input
                      id={"location"}
                      labelDisplay={"Location"}
                      labelDisplayPosition={LabelPosition.LEFT}
                      required={false}
                      type={InputType.STRING}
                      disabled={true}
                      value={data.location}
                      field={"location"}
                      visible={true}
                    />
                  </div>
                )}
                {this.props.viewType === ViewType.MY_PRODUCT && (
                  <div className="p-col-6">
                    <Input
                      id={"shortDescription"}
                      labelDisplay={"Link To Website"}
                      labelDisplayPosition={LabelPosition.LEFT}
                      required={false}
                      type={InputType.STRING}
                      disabled={true}
                      value={data.shortDescription}
                      field={"shortDescription"}
                      visible={true}
                    />
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="p-col-12">
            <div className="p-grid" style={{ width: "100%" }}>
              <div className="p-col-12">
                <pr.TabView>
                  <pr.TabPanel header="Details">
                    <div className="p-grid card">
                      {format.isNotNull(data) && (
                        <div className="p-col-10">
                          <Input
                            id={"longDescription"}
                            labelDisplay={"Additional Details"}
                            labelDisplayPosition={LabelPosition.TOP}
                            required={false}
                            type={InputType.NOTES}
                            disabled={true}
                            value={data.longDescription}
                            field={"longDescription"}
                            visible={true}
                          />
                        </div>
                      )}
                    </div>
                  </pr.TabPanel>
                  <pr.TabPanel header="Images">
                    <div className="p-grid">
                      {format.isNotNull(data) && (
                        <div className="p-col-12">
                          <ImageViewer
                            imageList={data.imageUri}
                            readOnly={true}
                            toggleProductLogo={false}
                          />
                        </div>
                      )}
                    </div>
                  </pr.TabPanel>
                  <pr.TabPanel header="Files">
                    <div className="p-grid card">
                      {format.isNotNull(data) && (
                        <div className="p-col-12">
                          <FileViewer fileList={data.pdfUrls} readOnly={true} />
                        </div>
                      )}
                    </div>
                  </pr.TabPanel>
                </pr.TabView>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}
