import React from "react";
import { Register } from "./UI/Register";
import { LoadScreen } from "../Common/LoadScreen";

export class RegisterView extends React.Component {
  render() {
    return (
      <div
        className="dashboard"
        style={this.props.loading === true ? { pointerEvents: "none" } : {}}
      >
        {this.props.loading === true && <LoadScreen />}
        {this.props.loading !== true && (
          <Register
            loading={this.props.loading}
            feedback={this.props.feedback}
            receivedFeedback={this.props.receivedFeedback}
            crudlState={this.props.crudlState}
            updateValue={this.props.updateEdited}
            updateState={this.props.updateState}
            editedObject={this.props.editedObject}
            checkRequiredFields={this.props.checkRequiredFields}
            viewType={this.props.viewType}
            //
            index={this.props.index}
            componentFormComplete={this.props.componentFormComplete}
            componentList={this.props.componentList}
            changeCategoriesState={this.props.changeCategoriesState}
            businessFormComplete={this.props.businessFormComplete}
            userFormComplete={this.props.userFormComplete}
            editedBusiness={this.props.editedBusiness}
            editedUser={this.props.editedUser}
            editedProduct={this.props.editedProduct}
            updateValueBusiness={this.props.updateEditedBusiness}
            updateValueUser={this.props.updateEditedUser}
            updateValueProduct={this.props.updateEditedProduct}
            componentOptions={this.props.componentOptions}
            onRegister={this.props.onRegister}
            checkEmailAddressAlreadyExists={
              this.props.checkEmailAddressAlreadyExists
            }
            checkBusinessAlreadyExists={this.props.checkBusinessAlreadyExists}
            updateBusinessType={this.props.updateBusinessType}
            businessTypeOptions={this.props.businessTypeOptions}
          />
        )}
      </div>
    );
  }
}
