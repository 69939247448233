import { BaseViewModel } from "../../common/BaseViewModel";
import * as Format from "../../common/FormatFunctions";
import { CloudService } from "../../common/CloudService/ClientLibsReference";

import { CrudlStatus, ViewState } from "../../common/config/AppConstants";
import { api } from "../../common/CloudService/ApiPromisify";
import * as pr from "../../common/PrimeComponents";
export class EnquiriesViewModel extends BaseViewModel {
  showLoadingScreen = () => {
    this.updateState("loaded", false);
  };

  hideLoadingScreen = () => {
    this.updateState("loaded", true);
  };

  getType = () => {
    var profileType = localStorage.getItem("validPPEProfile");
    if (Format.isNotNull(profileType)) {
      return profileType;
    }

    return "";
  };

  getPossibleComponents = async () => {
    let componentRequest = new CloudService.ComponentRequest();
    componentRequest.codeTag = this.codeTag;
    componentRequest.userId = this.userId;

    componentRequest.type = "PPE";

    await api("componentApiV1ComponentGetByType", componentRequest).then(
      (res) => {
        if (res.data) {
          let componentResponse =
            CloudService.ComponentResponse.constructFromObject(res.data);
          console.log(componentResponse);
          this.updateState(
            "componentList",
            pr.getDropdownOptions(componentResponse.componentList)
          );
        }
      }
    );
  };

  getEnquiriesListByType = async (request, type) => {
    var applicationRequest = Format.deepCopy(request);
    applicationRequest.applicationType = type;

    var applicationList = [];
    await api(
      "applicationApiV1ApplicationGetByPPEBusiness",
      applicationRequest
    ).then((res) => {
      console.log(res);
      if (res.data) {
        let applicationResponse =
          CloudService.ApplicationResponse.constructFromObject(res.data);
        console.log(applicationResponse);
        if (
          Format.isNotNull(applicationResponse.errorCode) &&
          applicationResponse.errorCode === 0
        ) {
          if (Format.isNotNull(applicationResponse.applicationList)) {
            applicationList = applicationResponse.applicationList;
          }
        }
      }
    });

    return applicationList;
  };
  getEnquiriess = async () => {
    let applicationRequest = new CloudService.ApplicationRequest();
    applicationRequest.codeTag = this.codeTag;
    applicationRequest.userId = this.userId;

    var profileType = this.getType();

    var applicationList = [];
    if (profileType === "Both") {
      var applicationListBuyer = await this.getEnquiriesListByType(
        applicationRequest,
        "PPEBuyer"
      );
      var applicationListSupplier = await this.getEnquiriesListByType(
        applicationRequest,
        "PPESupplier"
      );
      applicationList = applicationListBuyer.concat(applicationListSupplier);
    } else {
      applicationList = await this.getEnquiriesListByType(
        applicationRequest,
        profileType
      );
    }

    if (Format.isNotNull(applicationList)) {
      this.updateState("enquiriesList", applicationList);
      this.logger.info(applicationList);
    } else {
      //this.showEmpty("Application", ViewState.TABLE);
    }
  };
  getEnquiriessReturned = async () => {
    let applicationRequest = new CloudService.ApplicationRequest();
    applicationRequest.codeTag = this.codeTag;
    applicationRequest.userId = this.userId;

    var profileType = this.getType();

    var applicationList = [];
    if (profileType === "Both") {
      var applicationListBuyer = await this.getEnquiriesListByType(
        applicationRequest,
        "PPEBuyer"
      );
      var applicationListSupplier = await this.getEnquiriesListByType(
        applicationRequest,
        "PPESupplier"
      );
      applicationList = applicationListBuyer.concat(applicationListSupplier);
    } else {
      applicationList = await this.getEnquiriesListByType(
        applicationRequest,
        profileType
      );
    }

    if (Format.isNotNull(applicationList)) {
      this.updateState("enquiriesList", applicationList);
      return applicationList;
    } else {
      this.showEmpty("Application", ViewState.TABLE);
    }
  };

  reloadDataAfterExecute = (
    enquiriess,
    apiResponse,
    type,
    externalStateValues
  ) => {
    var updatedEnquiriesList = [];
    if (type === CrudlStatus.CREATE) {
      updatedEnquiriesList = this.reloadAfterCreate(
        enquiriess,
        apiResponse,
        externalStateValues
      );
    }
    if (type === CrudlStatus.UPDATE) {
      updatedEnquiriesList = this.reloadAfterUpdate(
        enquiriess,
        apiResponse,
        externalStateValues
      );
    }
    if (type === CrudlStatus.DELETE) {
      updatedEnquiriesList = this.reloadAfterDelete(
        enquiriess,
        apiResponse,
        externalStateValues
      );
    }

    this.updateState("enquiriesList", updatedEnquiriesList);
  };

  reloadAfterCreate = (enquiriesList, apiResponse, externalStateValues) => {
    if (enquiriesList === undefined || enquiriesList === null) {
      enquiriesList = [];
    }
    var enquiriesBean = externalStateValues.enquiriesBean;
    enquiriesBean.id = apiResponse.id;
    enquiriesBean.lastUpdatedDateTime = apiResponse.lastUpdatedDateTime;

    enquiriesList.push(enquiriesBean);

    return enquiriesList;
  };

  reloadAfterUpdate = (enquiriesList, apiResponse, externalStateValues) => {
    var i = 0;
    enquiriesList.forEach((e) => {
      if (apiResponse.id === e.id) {
        enquiriesList[i] = externalStateValues.enquiriesBean;
        enquiriesList[i].lastUpdatedDateTime = apiResponse.lastUpdatedDateTime;
      }
      i++;
    });

    return enquiriesList;
  };

  reloadAfterDelete = (enquiriesList, apiResponse, externalStateValues) => {
    var i = 0;
    enquiriesList.forEach((e) => {
      //DELETE
      if (apiResponse.id === e.id) {
        enquiriesList.splice(i, 1);
      }
      i++;
    });

    return enquiriesList;
  };
  executeCRUDLAction = async (crudlStatus, stateVariables) => {
    let enquiriesRequest = stateVariables.enquiriesBean;
    if (crudlStatus === CrudlStatus.CREATE) {
      return await this.createEnquiries(enquiriesRequest, stateVariables);
    }
    if (crudlStatus === CrudlStatus.UPDATE) {
      return await this.updateEnquiries(enquiriesRequest, stateVariables);
    }
    if (crudlStatus === CrudlStatus.DELETE) {
      return await this.deleteEnquiries(enquiriesRequest);
    }
  };

  createEnquiries = async (enquiriesRequest, stateVariables) => {
    // enquiriesRequest.codeTag = this.codeTag;
    // enquiriesRequest.userId = this.userId;
    // let enquiriesResponse = await ApiService.enquiriesCreate(enquiriesRequest);
    // if (enquiriesResponse.errorCode === 0) {
    //   this.showSuccess(
    //     "Enquiries",
    //     CrudlStatus.CREATE,
    //     stateVariables.viewState
    //   );
    // } else {
    //   this.showError("Enquiries", CrudlStatus.CREATE, ViewState.CARD);
    // }
    // return enquiriesResponse;
  };

  deleteEnquiries = async (enquiriesRequest) => {
    //RESET PRODUCT REQUEST
    // var request = {};
    // request.codeTag = this.codeTag;
    // request.userId = this.userId;
    // request.id = enquiriesRequest.id;
    // request.lastUpdatedDateTime = enquiriesRequest.lastUpdatedDateTime;
    // let enquiriesResponse = await ApiService.enquiriesDelete(request);
    // if (enquiriesResponse.errorCode === 0) {
    //   this.showSuccess("Enquiries", CrudlStatus.DELETE, ViewState.TABLE);
    // } else {
    //   this.showError("Enquiries", CrudlStatus.DELETE, ViewState.CARD);
    // }
    // return enquiriesResponse;
  };

  updateEnquiries = async (enquiriesRequest, stateVariables) => {
    console.log(enquiriesRequest);
    var applicationResponse = {};

    console.log("Enquiries Update Request");
    console.log(enquiriesRequest);
    await api("applicationApiV1ApplicationUpdate", enquiriesRequest).then(
      (res) => {
        if (res.data) {
          applicationResponse =
            CloudService.ApplicationResponse.constructFromObject(res.data);
          console.log(applicationResponse);
          if (
            Format.isNotNull(applicationResponse.errorCode) &&
            applicationResponse.errorCode === 0
          ) {
            console.log("Enquiries Update Response");
            console.log(applicationResponse);
            this.showSuccess("Enquiries", CrudlStatus.UPDATE, ViewState.TABLE);
          } else {
            this.showError("Enquiries", CrudlStatus.UPDATE, ViewState.CARD);
          }
        }
      }
    );

    return applicationResponse;
  };
}
