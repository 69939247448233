import * as log from "./Feedback/Log";
import * as ApiService from "./CloudService/ApiService";
import { CloudService } from "./CloudService/ClientLibsReference";
import { defaultedGlobalBid } from "./config/AppConstants";
import * as format from "./FormatFunctions";

export class BaseViewModel {
  constructor() {
    // For Council Users - ClientId === GlobalBid
    this.codeTag = localStorage.getItem("clientId");
    this.userId = localStorage.getItem("userId");
    this.logger = log;
  }

  callApi = async (request, api) => {
    await api(api, request).then((res) => {
      if (res.data) {
        return res.data;
      } else {
        return { errorCode: 10001 };
      }
    });
  };

  login = async (request) => {
    let loginRequest = new CloudService.LoginRequest();
    loginRequest = request;
    let loginResponse = await ApiService.login(loginRequest);
    if (loginResponse.errorCode === 0) {
      console.log("successful login");
      return loginResponse;
    } else {
      console.log("failed login");
      return loginResponse;
    }
  };

  profileListAll = async (request) => {
    let profileRequest = new CloudService.ProfileRequest();
    profileRequest = request;
    let profileResponse = await ApiService.profileListAll(profileRequest);
    if (profileResponse.errorCode === 0) {
      return profileResponse;
    } else {
      return profileResponse;
    }
  };

  profileGetByCodeTag = async (request) => {
    let profileRequest = new CloudService.ProfileRequest();
    profileRequest = request;
    let profileResponse = await ApiService.profileGetByCodeTag(profileRequest);
    if (profileResponse.errorCode === 0) {
      return profileResponse;
    } else {
      return profileResponse;
    }
  };
  getProfileListByCodeTag = async (request) => {
    let profileRequest = new CloudService.ProfileRequest();
    profileRequest = request;
    profileRequest.codeTag = this.codeTag;
    profileRequest.userId = this.userId;
    let profileResponse = await ApiService.profileGetByCodeTag(profileRequest);
    if (profileResponse.errorCode === 0) {
      return profileResponse;
    } else {
      return profileResponse;
    }
  };
  getProfileListByType = async (request) => {
    let profileRequest = new CloudService.ProfileRequest();
    profileRequest = request;
    profileRequest.codeTag = this.codeTag;
    profileRequest.userId = this.userId;
    let profileResponse = await ApiService.profileGetByType(profileRequest);
    if (profileResponse.errorCode === 0) {
      return profileResponse;
    } else {
      return profileResponse;
    }
  };

  getProfileListByTypeAndComponentList = async (request) => {
    let profileRequest = new CloudService.ProfileRequest();
    profileRequest = request;
    profileRequest.codeTag = this.codeTag;
    profileRequest.userId = this.userId;
    let profileResponse = await ApiService.profileGetByTypeAndComponentList(
      profileRequest
    );
    if (profileResponse.errorCode === 0) {
      return profileResponse;
    } else {
      return profileResponse;
    }
  };

  componentGetByCodeTagUnsecure = async (request) => {
    let componentRequest = new CloudService.ComponentRequest();
    componentRequest = request;
    let componentResponse = await ApiService.componentGetByCodeTagUnsecure(
      componentRequest
    );
    if (componentResponse.errorCode === 0) {
      return componentResponse;
    } else {
      return componentResponse;
    }
  };

  componentGetByCodeTag = async (type) => {
    let componentRequest = new CloudService.ComponentRequest();
    componentRequest.codeTag = defaultedGlobalBid;
    if (format.isNotNull(type)) {
      componentRequest.type = type;
    }

    let componentResponse = await ApiService.componentGetByCodeTag(
      componentRequest
    );
    if (componentResponse.errorCode === 0) {
      return componentResponse;
    } else {
      return componentResponse;
    }
  };

  componentGetMatrixItemsByCodeTag = async (codeTag) => {
    let componentRequest = new CloudService.ComponentRequest();
    componentRequest.codeTag = this.codeTag;
    if (format.isNotNull(codeTag)) {
      componentRequest.codeTag = codeTag;
    }
    let componentResponse = await ApiService.componentGetMatrixItems(
      componentRequest
    );
    if (componentResponse.errorCode === 0) {
      return componentResponse;
    } else {
      return componentResponse;
    }
  };

  getProductListByCodeTag = async (codeTag) => {
    let productRequest = new CloudService.ProductRequest();
    productRequest.codeTag = this.codeTag;
    if (format.isNotNull(codeTag)) {
      productRequest.codeTag = codeTag;
    }

    productRequest.userId = this.userId;
    let productResponse = await ApiService.productGetByCodeTag(productRequest);
    if (productResponse.errorCode === 0) {
      return productResponse;
    } else {
      return productResponse;
    }
  };
  getProductListByCodeTagAndType = async (type, codeTag) => {
    let productRequest = new CloudService.ProductRequest();
    productRequest.codeTag = this.codeTag;
    if (format.isNotNull(codeTag)) {
      productRequest.codeTag = codeTag;
    }
    productRequest.userId = this.userId;
    productRequest.type = type;
    let productResponse = await ApiService.productGetByCodeTagAndType(
      productRequest
    );
    if (productResponse.errorCode === 0) {
      return productResponse;
    } else {
      return productResponse;
    }
  };
  getProductListByType = async (type) => {
    let productRequest = new CloudService.ProductRequest();
    productRequest.codeTag = this.codeTag;
    productRequest.userId = this.userId;
    if (format.isNotNull(type)) {
      productRequest.type = type;
    }

    let productResponse = await ApiService.productGetByType(productRequest);
    if (productResponse.errorCode === 0) {
      return productResponse;
    } else {
      return productResponse;
    }
  };
  getProductListByComponentList = async (componentList, type) => {
    let productRequest = new CloudService.ProductRequest();
    productRequest.codeTag = this.codeTag;
    productRequest.userId = this.userId;
    if (format.isNotNull(componentList)) {
      productRequest.componentList = componentList;
    }
    if (format.isNotNull(type)) {
      productRequest.type = type;
    }

    let productResponse = await ApiService.productGetByComponentList(
      productRequest
    );
    if (productResponse.errorCode === 0) {
      return productResponse;
    } else {
      return productResponse;
    }
  };

  getContactListByCodeTag = async () => {
    let contactRequest = new CloudService.ContactRequest();
    contactRequest.codeTag = this.codeTag;
    contactRequest.userId = this.userId;

    let contactResponse = await ApiService.contactGetByCodeTag(contactRequest);
    if (contactResponse.errorCode === 0) {
      return contactResponse;
    } else {
      return contactResponse;
    }
  };

  getApplicationListByBuyerId = async (buyerId) => {
    let applicationRequest = new CloudService.ApplicationRequest();
    applicationRequest.codeTag = this.codeTag;
    applicationRequest.userId = this.userId;
    if (format.isNotNull(buyerId)) {
      applicationRequest.buyerId = buyerId;
    }

    let applicationResponse = await ApiService.applicationGetByBuyerId(
      applicationRequest
    );
    if (applicationResponse.errorCode === 0) {
      return applicationResponse;
    } else {
      return applicationResponse;
    }
  };

  applicationListAll = async (request) => {
    let applicationRequest = new CloudService.ApplicationRequest();
    applicationRequest = request;

    let applicationResponse = await ApiService.applicationListAll(
      applicationRequest
    );
    if (applicationResponse.errorCode === 0) {
      return applicationResponse;
    } else {
      return applicationResponse;
    }
  };

  getApplicationListByCodeTag = async () => {
    let applicationRequest = new CloudService.ApplicationRequest();
    applicationRequest.codeTag = this.codeTag;
    applicationRequest.userId = this.userId;

    let applicationResponse = await ApiService.applicationGetByCodeTag(
      applicationRequest
    );
    if (applicationResponse.errorCode === 0) {
      return applicationResponse;
    } else {
      return applicationResponse;
    }
  };

  getApplicationListByApplicationType = async (request) => {
    let applicationRequest = new CloudService.ApplicationRequest();
    applicationRequest = request;
    applicationRequest.userId = this.userId;

    let applicationResponse = await ApiService.applicationGetByApplicationType(
      applicationRequest
    );
    if (applicationResponse.errorCode === 0) {
      return applicationResponse;
    } else {
      return applicationResponse;
    }
  };

  getProfileListByProfileStatus = async (request) => {
    let profileRequest = new CloudService.ProfileRequest();
    profileRequest = request;
    profileRequest.codeTag = this.codeTag;
    profileRequest.userId = this.userId;

    let profileResponse = await ApiService.profileGetByProfileStatus(
      profileRequest
    );
    if (profileResponse.errorCode === 0) {
      return profileResponse;
    } else {
      return profileResponse;
    }
  };

  getBusinessListByCodeTag = async (codeTag) => {
    let businessRequest = new CloudService.BusinessRequest();

    if (format.isNotNull(codeTag)) {
      businessRequest.codeTag = codeTag;
    } else {
      businessRequest.codeTag = this.codeTag;
    }
    businessRequest.userId = this.userId;
    let businessResponse = await ApiService.businessListAll(businessRequest);
    if (businessResponse.errorCode === 0) {
      return businessResponse;
    } else {
      return businessResponse;
    }
  };

  getBusinessAccount = async (codeTag) => {
    let businessRequest = new CloudService.BusinessRequest();
    businessRequest.codeTag = this.codeTag;
    businessRequest.userId = this.userId;
    if (format.isNotNull(codeTag)) {
      businessRequest.codeTag = codeTag;
    }
    let businessResponse = await ApiService.businessGetByCodeTag(
      businessRequest
    );
    if (businessResponse.errorCode === 0) {
      return businessResponse;
    } else {
      return businessResponse;
    }
  };

  getUserListByCodeTag = async (codeTag) => {
    let userRequest = new CloudService.UserRequest();
    userRequest.codeTag = this.codeTag;
    userRequest.userId = this.userId;
    if (format.isNotNull(codeTag)) {
      console.log("CodeTag is not null", codeTag);
      userRequest.codeTag = codeTag;
    }
    let userResponse = await ApiService.userListInBusinessGlobal(userRequest);
    if (userResponse.errorCode === 0) {
      return userResponse;
    } else {
      return userResponse;
    }
  };

  getProfileItemListByCodeTag = async () => {
    let profileItemRequest = new CloudService.ProfileItemRequest();
    profileItemRequest.codeTag = this.codeTag;
    profileItemRequest.userId = this.userId;

    let profileItemResponse = await ApiService.profileItemGetByCodeTag(
      profileItemRequest
    );
    if (profileItemResponse.errorCode === 0) {
      return profileItemResponse;
    } else {
      return profileItemResponse;
    }
  };

  getMatrixListByMatrixType = async (request) => {
    let matrixRequest = new CloudService.MatrixRequest();
    matrixRequest = request;

    let matrixResponse = await ApiService.matrixGetByMatrixType(matrixRequest);
    if (matrixResponse.errorCode === 0) {
      return matrixResponse;
    } else {
      return matrixResponse;
    }
  };
  getMatrixListMostRecentByMatrixType = async (request) => {
    let matrixRequest = new CloudService.MatrixRequest();
    matrixRequest = request;

    let matrixResponse = await ApiService.matrixGetMostRecentByMatrixType(
      matrixRequest
    );
    if (matrixResponse.errorCode === 0) {
      return matrixResponse;
    } else {
      return matrixResponse;
    }
  };
  getBusinessIndustryListByCodeTag = async () => {
    let businessIndustryRequest = new CloudService.BusinessIndustryRequest();
    businessIndustryRequest.codeTag = defaultedGlobalBid;

    let businessIndustryResponse =
      await ApiService.businessIndustryGetByCodeTag(businessIndustryRequest);
    if (businessIndustryResponse.errorCode === 0) {
      return businessIndustryResponse;
    } else {
      return businessIndustryResponse;
    }
  };
  getBusinessIndustryListByCodeTagUnsecure = async () => {
    let businessIndustryRequest = new CloudService.BusinessIndustryRequest();
    businessIndustryRequest.codeTag = defaultedGlobalBid;

    let businessIndustryResponse =
      await ApiService.businessIndustryGetByCodeTagUnsecure(
        businessIndustryRequest
      );
    if (businessIndustryResponse.errorCode === 0) {
      return businessIndustryResponse;
    } else {
      return businessIndustryResponse;
    }
  };
}
