import { BaseViewModel } from "../../common/BaseViewModel";
import * as Format from "../../common/FormatFunctions";
import { CloudService } from "../../common/CloudService/ClientLibsReference";

import { CrudlStatus, ViewState } from "../../common/config/AppConstants";
import { api } from "../../common/CloudService/ApiPromisify";
import * as pr from "../../common/PrimeComponents";
export class SupplyViewModel extends BaseViewModel {
  showLoadingScreen = () => {
    this.updateState("loaded", false);
  };

  hideLoadingScreen = () => {
    this.updateState("loaded", true);
  };

  getPossibleComponents = async () => {
    let componentRequest = new CloudService.ComponentRequest();
    componentRequest.codeTag = this.codeTag;
    componentRequest.userId = this.userId;

    componentRequest.type = "PPE";

    await api("componentApiV1ComponentGetByType", componentRequest).then(
      (res) => {
        if (res.data) {
          let componentResponse =
            CloudService.ComponentResponse.constructFromObject(res.data);
          console.log(componentResponse);
          this.updateState(
            "componentList",
            pr.getDropdownOptions(componentResponse.componentList)
          );
        }
      }
    );
  };

  getSupplys = async () => {
    let productRequest = new CloudService.ProductRequest();
    productRequest.codeTag = this.codeTag;
    productRequest.userId = this.userId;

    productRequest.type = "PPESupplier";

    await api("productApiV1ProductGetByCodeTag", productRequest).then((res) => {
      console.log(res);
      if (res.data) {
        let productResponse = CloudService.ProductResponse.constructFromObject(
          res.data
        );
        console.log(productResponse);
        if (
          Format.isNotNull(productResponse.errorCode) &&
          productResponse.errorCode === 0
        ) {
          if (Format.isNotNull(productResponse.productList)) {
            this.updateState("supplyList", productResponse.productList);
            this.logger.info(productResponse.productList);
          } else {
            this.showEmpty("My Supplies", ViewState.TABLE);
          }
        } else {
          this.showError("My Supplies", CrudlStatus.LIST, ViewState.TABLE);
        }
      }
    });
  };
  getSupplysReturned = async () => {
    let productRequest = new CloudService.ProductRequest();
    productRequest.codeTag = this.codeTag;
    productRequest.userId = this.userId;

    productRequest.type = "PPESupplier";

    await api("productApiV1ProductGetByCodeTag", productRequest).then((res) => {
      if (res.data) {
        let productResponse = CloudService.ProductResponse.constructFromObject(
          res.data
        );
        console.log(productResponse);
        if (
          Format.isNotNull(productResponse.errorCode) &&
          productResponse.errorCode === 0
        ) {
          if (Format.isNotNull(productResponse.productList)) {
            this.updateState("supplyList", productResponse.productList);
            return productResponse.productList;
          } else {
            this.showEmpty("My Supplies", ViewState.TABLE);
          }
        } else {
          this.showError("My Supplies", CrudlStatus.LIST, ViewState.TABLE);
        }
      }
    });
  };

  reloadDataAfterExecute = (
    supplys,
    apiResponse,
    type,
    externalStateValues
  ) => {
    var updatedSupplyList = [];
    if (type === CrudlStatus.CREATE) {
      updatedSupplyList = this.reloadAfterCreate(
        supplys,
        apiResponse,
        externalStateValues
      );
    }
    if (type === CrudlStatus.UPDATE) {
      updatedSupplyList = this.reloadAfterUpdate(
        supplys,
        apiResponse,
        externalStateValues
      );
    }
    if (type === CrudlStatus.DELETE) {
      updatedSupplyList = this.reloadAfterDelete(
        supplys,
        apiResponse,
        externalStateValues
      );
    }

    this.updateState("supplyList", updatedSupplyList);
  };

  reloadAfterCreate = (supplyList, apiResponse, externalStateValues) => {
    if (supplyList === undefined || supplyList === null) {
      supplyList = [];
    }
    var supplyBean = externalStateValues.supplyBean;
    supplyBean.id = apiResponse.id;
    supplyBean.lastUpdatedDateTime = apiResponse.lastUpdatedDateTime;

    supplyList.push(supplyBean);

    return supplyList;
  };

  reloadAfterUpdate = (supplyList, apiResponse, externalStateValues) => {
    var i = 0;
    supplyList.forEach((e) => {
      if (apiResponse.id === e.id) {
        supplyList[i] = externalStateValues.supplyBean;
        supplyList[i].lastUpdatedDateTime = apiResponse.lastUpdatedDateTime;
      }
      i++;
    });

    return supplyList;
  };

  reloadAfterDelete = (supplyList, apiResponse, externalStateValues) => {
    var i = 0;
    supplyList.forEach((e) => {
      //DELETE
      if (apiResponse.id === e.id) {
        supplyList.splice(i, 1);
      }
      i++;
    });

    return supplyList;
  };
  executeCRUDLAction = async (crudlStatus, stateVariables) => {
    let supplyRequest = stateVariables.supplyBean;

    if (crudlStatus === CrudlStatus.CREATE) {
      return await this.createSupply(supplyRequest, stateVariables);
    }
    if (crudlStatus === CrudlStatus.UPDATE) {
      return await this.updateSupply(supplyRequest, stateVariables);
    }
    if (crudlStatus === CrudlStatus.DELETE) {
      return await this.deleteSupply(supplyRequest);
    }
  };

  createSupply = async (supplyRequest, stateVariables) => {
    supplyRequest.codeTag = this.codeTag;
    supplyRequest.userId = this.userId;

    supplyRequest.type = "PPESupplier";

    if (Format.isNotNull(supplyRequest.componentId)) {
      supplyRequest.componentList = [supplyRequest.componentId];

      if (Format.isNotNull(stateVariables.componentList)) {
        stateVariables.componentList.forEach((element) => {
          if (element.value === supplyRequest.componentId) {
            supplyRequest.componentNameToDisplay = element.label;
          }
        });
      }
    }
    console.log(supplyRequest);
    var productResponse = {};

    await api("productApiV1ProductCreate", supplyRequest).then((res) => {
      if (res.data) {
        productResponse = CloudService.ProductResponse.constructFromObject(
          res.data
        );
        console.log(productResponse);
        if (
          Format.isNotNull(productResponse.errorCode) &&
          productResponse.errorCode === 0
        ) {
          this.showSuccess(
            "My Supplies",
            CrudlStatus.CREATE,
            stateVariables.viewState
          );
        } else {
          this.showError("My Supplies", CrudlStatus.CREATE, ViewState.CARD);
        }
      }
    });

    return productResponse;
  };

  deleteSupply = async (supplyRequest) => {
    var request = {};
    request.codeTag = this.codeTag;
    request.userId = this.userId;
    request.id = supplyRequest.id;
    request.lastUpdatedDateTime = supplyRequest.lastUpdatedDateTime;
    var productResponse = {};

    await api("productApiV1ProductDelete", supplyRequest).then((res) => {
      if (res.data) {
        productResponse = CloudService.ProductResponse.constructFromObject(
          res.data
        );
        console.log(productResponse);
        if (
          Format.isNotNull(productResponse.errorCode) &&
          productResponse.errorCode === 0
        ) {
          this.showSuccess("My Supplies", CrudlStatus.DELETE, ViewState.TABLE);
        } else {
          this.showError("My Supplies", CrudlStatus.CREATE, ViewState.CARD);
        }
      }
    });

    return productResponse;
  };

  updateSupply = async (supplyRequest, stateVariables) => {
    supplyRequest.codeTag = this.codeTag;
    supplyRequest.userId = this.userId;

    supplyRequest.type = "PPESupplier";

    if (Format.isNotNull(supplyRequest.componentId)) {
      supplyRequest.componentList = [supplyRequest.componentId];

      if (Format.isNotNull(stateVariables.componentList)) {
        stateVariables.componentList.forEach((element) => {
          if (element.value === supplyRequest.componentId) {
            supplyRequest.componentNameToDisplay = element.label;
          }
        });
      }
    }
    console.log(supplyRequest);
    var productResponse = {};

    await api("productApiV1ProductUpdate", supplyRequest).then((res) => {
      if (res.data) {
        productResponse = CloudService.ProductResponse.constructFromObject(
          res.data
        );
        console.log(productResponse);
        if (
          Format.isNotNull(productResponse.errorCode) &&
          productResponse.errorCode === 0
        ) {
          this.showSuccess(
            "My Supplies",
            CrudlStatus.CREATE,
            stateVariables.viewState
          );
        } else {
          this.showError("My Supplies", CrudlStatus.CREATE, ViewState.CARD);
        }
      }
    });

    return productResponse;
  };
}
