import React from "react";
//PrimeReact Components
import * as pr from "../../../common/PrimeComponents";
import {
  CrudlStatus,
  FeedbackState,
} from "../../../common/config/AppConstants";
import * as format from "../../../common/FormatFunctions";
import { LoadScreen } from "../../Common/LoadScreen";
import firebase from "firebase";
import { Notes } from "../../Common/Notes";
import { FileViewer } from "../../Common/FileViewer";
import { ImageViewer } from "../../Common/ImageViewer";
export class SupplyCard extends React.Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
  };
  componentDidUpdate = (prevProps) => {
    // Typical usage (don't forget to compare props):
    if (this.props.receivedFeedback !== prevProps.receivedFeedback) {
      switch (this.props.receivedFeedback.state) {
        case FeedbackState.EMPTY:
          this.props.feedback.showEmpty(
            this.growl,
            this.props.receivedFeedback.display
          );
          break;
        case FeedbackState.SUCCESS:
          this.props.feedback.showSuccess(
            this.growl,
            this.props.receivedFeedback.display,
            this.props.receivedFeedback.crudlStatus
          );
          break;
        case FeedbackState.ERROR:
          this.props.feedback.showError(
            this.growl,
            this.props.receivedFeedback.display,
            this.props.receivedFeedback.crudlStatus
          );
          break;
        default:
          break;
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <pr.Messages
          style={{ textAlign: "left" }}
          ref={(el) => (this.growl = el)}
        />

        <div className="p-grid bpm">
          <div className="p-col-12">
            <h2>
              {format.capitalize(this.props.crudlState.display) +
                " Product/Service"}
            </h2>
          </div>
          <div className="p-col-12"></div>
          {this.props.loading === true && <LoadScreen />}
          {this.props.loading !== true && (
            <React.Fragment>
              <div className="p-col-12 card">
                <div className="p-col-12"></div>
                <div className="p-grid">
                  <div className="p-col-12 p-md-6">
                    <div className="p-col-12 p-md-3">
                      <label htmlFor="businessName" className="input-label">
                        Product/Service Name *
                      </label>
                    </div>
                    <div className="p-col-12 p-md-9">
                      <pr.InputText
                        id="name"
                        className="required"
                        value={this.props.editedObject.name || ""}
                        onChange={(e) => {
                          this.props.updateValue(e.target.id, e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="p-col-12 p-md-6">
                    <div className="p-col-12 p-md-3">
                      <label htmlFor="businessName" className="input-label">
                        Product/Service Type *
                      </label>
                    </div>
                    <div className="p-col-12 p-md-9">
                      <pr.Dropdown
                        id="sector"
                        className="required"
                        value={this.props.editedObject.componentId}
                        options={this.props.componentList}
                        onChange={(e) => {
                          this.props.updateValue("componentId", e.value);
                        }}
                        filter={true}
                        filterBy="label"
                        filterPlaceholder="Search"
                        style={{ marginTop: "1px", width: "100%" }}
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="p-col-4"></div>
                </div>

                <div className="p-grid">
                  <div className="p-col-12 p-md-6">
                    <div className="p-col-12 p-md-3">
                      <label className="input-label">Link to E-Commerce</label>
                    </div>
                    <div className="p-col-12 p-md-9">
                      <pr.InputText
                        id="shortDescription"
                        className="required"
                        value={this.props.editedObject.shortDescription || ""}
                        onChange={(e) => {
                          this.props.updateValue(e.target.id, e.target.value);
                        }}
                      />
                      <label htmlFor="shortDescription">
                        Add link to product here so customers can buy direct
                      </label>
                      <br></br>
                      <label htmlFor="shortDescription">
                        Please include https:// or http:// when filling out this
                        field
                      </label>
                    </div>
                  </div>
                  <div className="p-col-12 p-md-6">
                    <div className="p-col-12 p-md-3">
                      <label htmlFor="Status" className="input-label">
                        Status
                      </label>
                    </div>
                    <div className="p-col-12 p-md-9">
                      <pr.Dropdown
                        id="status"
                        className="required"
                        value={this.props.editedObject.productStatus}
                        options={[
                          { label: "Active", value: "Active" },
                          { label: "Inactive", value: "Inactive" },
                        ]}
                        onChange={(e) => {
                          this.props.updateValue("productStatus", e.value);
                        }}
                        style={{ marginTop: "1px", width: "100%" }}
                      />
                      <label htmlFor="shortDescription">
                        Active : Buyers can view, Inactive : Buyers can't view
                      </label>
                    </div>
                  </div>
                  {this.props.crudlState === CrudlStatus.UPDATE && (
                    <div className="p-col-12 p-md-6">
                      <div className="p-col-12 p-md-3">
                        <label htmlFor="businessName" className="input-label">
                          Location
                        </label>
                      </div>
                      <div className="p-col-12 p-md-9">
                        <pr.InputText
                          id="location"
                          value={this.props.editedObject.location || ""}
                          onChange={(e) => {
                            this.props.updateValue(e.target.id, e.target.value);
                          }}
                        />
                        <label htmlFor="shortDescription">
                          This is where your product is delivered from or where
                          you are based, by default this is the city in your
                          profile details
                        </label>
                      </div>
                    </div>
                  )}
                </div>
                <div className="p-col-12 p-md-12">
                  <pr.Accordion>
                    <pr.AccordionTab header="Images">
                      <div className="p-grid">
                        <div className="p-col-12">
                          <ImageViewer
                            fileList={this.props.editedObject.imageUri}
                            updateValue={this.props.updateValue}
                            field={"imageUri"}
                            readOnly={false}
                          />
                        </div>
                      </div>
                    </pr.AccordionTab>
                  </pr.Accordion>
                  <pr.Accordion>
                    <pr.AccordionTab header="Files">
                      <div className="p-grid">
                        <div className="p-col-12">
                          <FileViewer
                            fileList={this.props.editedObject.pdfUrls}
                            updateValue={this.props.updateValue}
                            field={"pdfUrls"}
                            readOnly={false}
                          />
                        </div>
                      </div>
                    </pr.AccordionTab>
                  </pr.Accordion>
                </div>
                <div className="p-col-12 p-md-12">
                  <div className="p-col-12 p-md-3">
                    <label htmlFor="businessName" className="input-label">
                      Product/Service Details *
                    </label>
                  </div>
                  <div className="p-col-12 p-md-10">
                    <Notes
                      id={this.props.editedObject.id}
                      field={"longDescriptionText"}
                      value={this.props.editedObject.longDescriptionText}
                      updateValue={this.props.updateValue}
                      diabeld={false}
                    ></Notes>
                  </div>
                </div>
                <div className="p-col-12">
                  <div className="p-grid">
                    <div className="p-col-12">
                      {this.props.crudlState === CrudlStatus.UPDATE ? (
                        <pr.Button
                          style={{ float: "right" }}
                          disabled={this.props.checkRequiredFields()}
                          label={"Update"}
                          onClick={() => {
                            this.props.crudlExecute(false);
                          }}
                        />
                      ) : (
                        <label />
                      )}
                      {this.props.crudlState === CrudlStatus.CREATE ? (
                        <pr.Button
                          style={
                            this.props.checkRequiredFields()
                              ? { float: "right", opacity: 0.5 }
                              : { float: "right" }
                          }
                          disabled={this.props.checkRequiredFields()}
                          label={"Add"}
                          onClick={() => {
                            this.props.crudlExecute(false);
                          }}
                        />
                      ) : (
                        <label />
                      )}

                      {this.props.crudlState === CrudlStatus.UPDATE ? (
                        <pr.Button
                          style={
                            this.props.checkRequiredFields()
                              ? { float: "left", opacity: 0.5 }
                              : { float: "left" }
                          }
                          disabled={this.props.checkRequiredFields()}
                          label={"Cancel"}
                          onClick={() => {
                            this.props.discardChanges();
                          }}
                        />
                      ) : (
                        <label />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }

  onUploadProduct = (files) => {
    const ref = firebase.storage().ref();
    console.log(Object.values(files));
    const fileArray = Object.values(files);

    fileArray.forEach((e) => {
      let that = this;
      const name = new Date() + "-" + e.name;
      const metadata = {
        contentType: e.type,
        visibilty: "public",
      };

      const task = ref.child(name).put(e, metadata);
      task
        .then((snapshot) => snapshot.ref.getDownloadURL())
        .then((url) => {
          console.log(url);
          that.props.updateValue("imageUri", url);
        });
    });
  };
}
