import React, { Component } from "react";
import * as pr from "../../common/PrimeComponents";
import * as Log from "../../common/Feedback/Log";
import Holdable from "./holdable";
import * as format from "../../common/FormatFunctions";
import * as Constant from "../../common/config/Configuration";

export class SingleFileUploader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: null,
      errors: [],
      layout: "list",
      deleteDialog: false,
      nameDialog: false,
      files: [],
      name: "",
      currentFileUri: "",
    };

    this.logger = Log;
  }
  onClick = (evt) => {
    window.open(evt.currentTarget.id);
  };

  onHold = (evt) => {
    this.setState({ currentFileUri: evt.currentTarget.id });
    this.toggleDialog(true);
  };

  toggleDialog = (booleanValue) => {
    this.setState({ deleteDialog: booleanValue });
  };
  toggleName = (booleanValue) => {
    this.setState({ nameDialog: booleanValue });
  };

  onRemoveFile = () => {
    this.logger.info(this.state.currentFileUri);
    var localFileUri = format.deepCopy(this.props.fileList);
    localFileUri = localFileUri.filter(
      (element) => element.url !== this.state.currentFileUri
    );

    this.props.updateValue(this.props.field, localFileUri);
    this.setState({ currentFileUri: "" });
    this.toggleDialog(false);
  };

  onLocalUploadFile = (files) => {
    const fileArray = Object.values(files);
    console.log(fileArray);
    var name = "";
    fileArray.forEach((element) => {
      name = format.deepCopy(element.name).split(".")[0];
    });
    this.setState({ files: fileArray, name: name }, () => {
      this.onFirebaseUploadFile();
    });

    // this.toggleName(true);
  };
  onFirebaseUploadFile = () => {
    const ref = Constant.getFirebaseStorage();
    this.logger.info(this.state.files);
    if (format.isNotNull(this.state.files)) {
      var fileArray = this.state.files;

      console.log(this.state.name);
      var name = this.state.name;

      fileArray.forEach((e) => {
        const metadata = {
          contentType: e.type,
          visibilty: "public",
        };

        console.log(name + e.name.split(".")[1]);
        name = name + "." + e.name.split(".")[1];
        const task = ref.child(name).put(e, metadata);
        task
          .then((snapshot) => snapshot.ref.getDownloadURL())
          .then((url) => {
            this.logger.info(url);
            if (this.props.field) {
              this.props.updateValue(this.props.field, url);
            } else {
              this.props.updateValue(url);
            }
          });
      });
    }
    this.setState({ name: "", files: [] });
    //this.dt.clear();
  };

  render() {
    return (
      <React.Fragment>
        {!this.props.disabled ? (
          <div className="p-grid">
            <div className="p-col-12">
              <pr.FileUpload
                className="businessLogoUpload"
                ref={(el) => {
                  this.dt = el;
                }}
                style={this.props.style || { float: "right" }}
                mode="basic"
                auto={true}
                customUpload={true}
                accept={this.props.accept}
                chooseLabel={this.props.chooseLabel || "Upload"}
                onSelect={(e) => {
                  this.onLocalUploadFile(e.files);
                }}
                disabled={this.props.disabled}
              ></pr.FileUpload>
            </div>
          </div>
        ) : (
          <div className="p-grid">
            <div className="p-col-12">
              {/* <Holdable
              onClick={this.onClick}
              onHold={this.onHold}
              id={this.props.value}
            >
              File Uploaded, Click to View
            </Holdable> */}
              <pr.Button
                icon={"pi pi-trash"}
                onClick={(event) => {
                  if (this.props.field) {
                    this.props.updateValue(this.props.field, null);
                  } else {
                    this.props.updateValue(null);
                  }
                }}
                style={{ float: "right" }}
              />
              <pr.Button
                icon={"pi pi-file-o"}
                id={this.props.value}
                onClick={this.onClick}
                style={{ float: "right" }}
              />
            </div>
          </div>
        )}

        {/* <pr.DataView
          header={
            <pr.FileUpload
              style={{ float: "right" }}
              mode="basic"
              auto={true}
              customUpload={true}
              accept={this.props.accept}
              chooseLabel={"Upload"}
              onSelect={e => {
                this.onUploadImage(e.files);
              }}
            ></pr.FileUpload>
          }
          value={this.props.imageList}
          layout={this.state.layout}
          itemTemplate={this.getItemTemplate}
        ></pr.DataView> */}
      </React.Fragment>
    );
  }
  getItemTemplate = (file) => {
    var componentArray = [];
    var index = 0;

    if (format.isNotNull(file)) {
      componentArray[index] = (
        <Holdable onClick={this.onClick} onHold={this.onHold} id={file.value}>
          <div className="p-grid card">
            <div className="p-col-12 p-md-1">
              <i className="pi pi-file"></i>
            </div>
            <div className="p-col-12 p-md-11">
              <label className="link-label">{file.value}</label>
            </div>
          </div>
        </Holdable>
      );
      index++;
    }
    return componentArray;
  };
}
