import React from "react";
import { PPECard } from "./UI/PPECard";
import * as pr from "../../common/PrimeComponents";
import { LoadScreen } from "../Common/LoadScreen";
export class PPEView extends React.Component {
  render() {
    if (this.props.registeredSuccessfully) {
      return (
        <div className="ui-g dashboard">
          <React.Fragment>
            <div className="ui-g-12">
              <div className="ui-g-2"></div>
              <div className="ui-g-8">
                <h1 style={{ lineHeight: "40px", margin: "0" }}>
                  Registered Successfully
                </h1>
                <div
                  style={this.props.firebaseMessage ? {} : { display: "none" }}
                >
                  Your email address already exists - If you can log in with
                  your old password, or reset your password to access the PPE
                  Portal
                </div>
                Please Login to Continue
                <pr.Button
                  label="Login"
                  icon={"pi pi-arrow-right"}
                  onClick={() => {
                    window.location = "#/login-covid-19-supplies";
                  }}
                  style={{ float: "right" }}
                />
              </div>
              <div className="ui-g-2"></div>
            </div>
          </React.Fragment>
        </div>
      );
    }
    if (this.props.registrationFailed) {
      return (
        <div className="ui-g dashboard">
          <React.Fragment>
            <div className="ui-g-12">
              <div className="ui-g-2"></div>
              <div className="ui-g-8">
                <h1 style={{ lineHeight: "40px", margin: "0" }}>
                  Registration Failed
                </h1>
                Please Check Details and try again
                <pr.Button
                  label="Try Again"
                  onClick={() => {
                    this.props.updateState("registrationFailed", false);
                  }}
                  style={{ float: "right" }}
                />
              </div>
              <div className="ui-g-2"></div>
            </div>
          </React.Fragment>
        </div>
      );
    }

    return (
      <React.Fragment>
        {this.props.loading ? (
          <LoadScreen></LoadScreen>
        ) : (
          <PPECard
            loading={this.props.loading}
            feedback={this.props.feedback}
            receivedFeedback={this.props.receivedFeedback}
            crudlState={this.props.crudlState}
            crudlControl={this.props.crudlControl}
            updateBean={this.props.updateEdited}
            updateState={this.props.updateState}
            editedObject={this.props.editedObject}
            checkRequiredFields={this.props.checkRequiredFields}
            discardChanges={this.props.discardChanges}
            validateState={this.props.validateState}
            validateChanges={this.props.validateChanges}
            crudlExecute={this.props.crudlExecute}
            //
            steps={this.props.steps}
            activeStep={this.props.activeStep}
            discoveredViaOptions={this.props.discoveredViaOptions}
            countyOptions={this.props.countyOptions}
            sectorOptions={this.props.sectorOptions}
            demographicAreaOptions={this.props.demographicAreaOptions}
            updatePPEBean={this.props.updatePPEBean}
            ppeBeanList={this.props.ppeBeanList}
            addProductView={this.props.addProductView}
            updateProductBean={this.props.updateProductBean}
            productBean={this.props.productBean}
            addProductBean={this.props.addProductBean}
            businessType={this.props.businessType}
            runRegister={this.props.runRegister}
          />
        )}
      </React.Fragment>
    );
  }
}
