import { Button } from "primereact/button/button.esm";
import { Calendar } from "primereact/calendar/calendar.esm";
import { Column } from "primereact/column/column.esm";
import { DataTable } from "primereact/datatable/datatable.esm";
import { DataView } from "primereact/dataview/dataview.esm";
import { Dialog } from "primereact/dialog/dialog.esm";
import { Dropdown } from "primereact/dropdown/dropdown.esm";
import { InputText } from "primereact/inputtext/inputtext.esm";
import { InputTextarea } from "primereact/inputtextarea/inputtextarea.esm";
import { Password } from "primereact/password/password.esm";
import { ProgressSpinner } from "primereact/progressspinner/progressspinner.esm";
import { ScrollPanel } from "primereact/scrollpanel/scrollpanel.esm";
import { Toolbar } from "primereact/toolbar/toolbar.esm";
import { MegaMenu } from "primereact/megamenu/megamenu.esm";
import { OverlayPanel } from "primereact/overlaypanel/overlaypanel.esm";
import { ListBox } from "primereact/listbox/listbox.esm";
import { Sidebar } from "primereact/sidebar/sidebar.esm";
import { SlideMenu } from "primereact/slidemenu/slidemenu.esm";
import { MultiSelect } from "primereact/multiselect/multiselect.esm";
import { Checkbox } from "primereact/checkbox/checkbox.esm";
import { PickList } from "primereact/picklist/picklist.esm";
import { Panel } from "primereact/panel/panel.esm";
import { Accordion } from "primereact/accordion/accordion.esm";
import { AccordionTab } from "primereact/accordion/accordion.esm";
import { Fieldset } from "primereact/fieldset/fieldset.esm";
import { FileUpload } from "primereact/fileupload/fileupload.esm";
import { Messages } from "primereact/messages/messages.esm";
import { Message } from "primereact/message/message.esm";
import { Steps } from "primereact/steps/steps.esm";
import { TabView } from "primereact/tabview/tabview.esm";
import { TabPanel } from "primereact/tabview/tabview.esm";
import { Editor } from "primereact/editor/editor.esm";
import { InputSwitch } from "primereact/inputswitch/inputswitch.esm";
import { OrganizationChart } from "primereact/organizationchart/organizationchart.esm";
import { TriStateCheckbox } from "primereact/tristatecheckbox/tristatecheckbox.esm";
import { Card } from "primereact/card/card.esm";
import { Slider } from "primereact/slider/slider.esm";
import { OrderList } from "primereact/orderlist/orderlist.esm";
import { BreadCrumb } from "primereact/breadcrumb/breadcrumb.esm";
import { TreeTable } from "primereact/treetable/treetable.esm";
import { Tree } from "primereact/tree/tree.esm";
import { Galleria } from "primereact/galleria/galleria.esm";
import { ColorPicker } from "primereact/colorpicker/colorpicker.esm";

import { Menubar } from "primereact/menubar/menubar.esm";
import { Menu } from "primereact/menu/menu.esm";
import { InputMask } from "primereact/inputmask/inputmask.esm";
import { Chips } from "primereact/chips/chips.esm";
import { ToggleButton } from "primereact/togglebutton/togglebutton.esm";
import { DataScroller } from "primereact/datascroller/datascroller.esm";
import { ProgressBar } from "primereact/progressbar/progressbar.esm";
import { InputNumber } from "primereact/inputnumber/inputnumber.esm";
import { SplitButton } from "primereact/splitbutton/splitbutton.esm";
import { TieredMenu } from "primereact/tieredmenu/tieredmenu.esm";
import { ContextMenu } from "primereact/contextmenu/contextmenu.esm";
import { AutoComplete } from "primereact/autocomplete/autocomplete.esm";
import { Toast } from "primereact/toast/toast.esm";
import React from "react";
import { CrudlStatus } from "../common/config/AppConstants";
import { ConfirmPopup } from "primereact/confirmpopup/confirmpopup.esm";
import { RadioButton } from "primereact/radiobutton/radiobutton.esm";
import { Tooltip } from "primereact/tooltip/tooltip.esm";
import { Tag } from "primereact/tag/tag.esm";
import { Skeleton } from "primereact/skeleton/skeleton.esm";

export {
  Button,
  Calendar,
  Column,
  DataTable,
  Dialog,
  Dropdown,
  InputText,
  Password,
  ProgressSpinner,
  ScrollPanel,
  InputTextarea,
  Toolbar,
  MegaMenu,
  OverlayPanel,
  ListBox,
  Sidebar,
  SlideMenu,
  MultiSelect,
  Checkbox,
  PickList,
  DataView,
  Panel,
  AccordionTab,
  Accordion,
  Fieldset,
  FileUpload,
  Messages,
  Message,
  Steps,
  TabPanel,
  TabView,
  OrganizationChart,
  //FlowChartWithState as FlowChart,
  InputSwitch,
  Editor,
  TriStateCheckbox,
  Card,
  BreadCrumb,
  Slider,
  TreeTable,
  OrderList,
  Galleria,
  ColorPicker,
  Menubar,
  Menu,
  InputMask,
  Chips,
  Tree,
  ToggleButton,
  DataScroller,
  ProgressBar,
  InputNumber,
  SplitButton,
  TieredMenu,
  ContextMenu,
  AutoComplete,
  Toast,
  ConfirmPopup,
  RadioButton,
  Tooltip,
  Tag,
  Skeleton,
};

export const getTableColumn = (name, display, filterMatchMode, style) => {
  return {
    field: name,
    header: display,
    sortable: true,
    filter: true,
    filterMatchMode: filterMatchMode ? filterMatchMode : "contains",
    style: style ? style : { overflow: "auto" },
  };
};

export const getColumnOptions = (columnList) => {
  let colOptions = [];
  for (let col of columnList) {
    colOptions.push({ label: col.header, value: col });
  }
  return colOptions;
};

export const getExpanderColumn = () => {
  return (
    <Column
      key="expander"
      expander={true}
      style={{ width: "3em", float: "middle" }}
    />
  );
};

export const getEditDeleteColumn = (
  rowData,
  column,
  editMethod,
  deleteMethod
) => {
  return (
    <div>
      <Button
        icon="pi pi-pencil"
        onClick={() => {
          editMethod(rowData);
        }}
        style={{ float: "middle" }}
      />
      <Button
        icon="pi pi-trash"
        onClick={() => {
          deleteMethod(rowData);
        }}
        style={{ float: "middle" }}
      />
    </div>
  );
};

export const getDropdownOptions = (array) => {
  let genericArray = [];
  let i = 0;

  if (array !== undefined) {
    array.forEach((e) => {
      genericArray[i] = {
        label: e.name,
        value: e.id,
      };
      i++;
    });
  }
  console.log(genericArray);
  return genericArray;
};
export const getDropdownOptionsFromList = (array) => {
  let genericArray = [];
  let i = 0;

  if (array !== undefined) {
    array.forEach((e) => {
      genericArray[i] = {
        label: e,
        value: e,
      };
      i++;
    });
  }
  console.log(genericArray);
  return genericArray;
};
export const getListOptions = (array, field) => {
  let genericArray = [];
  if (array !== undefined) {
    array.forEach((e) => {
      if (e[field]) {
        genericArray.push(e[field]);
      }
    });
  }

  return genericArray;
};
export const getDropdownOptionsExtension = (array, label, value) => {
  let genericArray = [];
  let i = 0;

  if (array !== undefined) {
    array.forEach((e) => {
      genericArray[i] = {
        label: e[label],
        value: e[value],
        id: e.id,
      };
      i++;
    });
  }

  return genericArray;
};
export const getBreadcrumbMenuState = (crudlStatus, key, keyUrl) => {
  var updatedMenuItems = [];
  if (crudlStatus === CrudlStatus.LIST) {
    updatedMenuItems = [
      { label: key + "s", url: keyUrl },
      { label: "View " + key + "s", url: "" },
    ];
  }
  if (crudlStatus === CrudlStatus.UPDATE) {
    updatedMenuItems = [
      { label: key + "s", url: keyUrl },
      { label: "View " + key + "s", url: "" },
      { label: "Edit " + key, url: "" },
    ];
  }
  if (crudlStatus === CrudlStatus.CREATE) {
    updatedMenuItems = [
      { label: key + "s", url: keyUrl },
      { label: "View " + key + "s", url: "" },
      { label: "Add " + key, url: "" },
    ];
  }
  if (crudlStatus === CrudlStatus.VIEW) {
    updatedMenuItems = [
      { label: key + "s", url: keyUrl },
      { label: "View " + key + "s", url: "" },
      { label: "View " + key, url: "" },
    ];
  }

  return updatedMenuItems;
};

export const getBreadcrumbMenuHome = () => {
  return {
    icon: "pi pi-home",
    url: "#/home",
  };
};
