import React from "react";
import { ApplicationController } from "./ApplicationController";
import { ApplicationViewModel } from "./ApplicationViewModel";
import * as feedback from "../../common/Feedback/Feedback";
import * as log from "../../common/Feedback/Log";
import * as format from "../../common/FormatFunctions";

export class ApplicationProvider extends React.Component {
  constructor(props) {
    super(props);
    this.viewModel = new ApplicationViewModel();
    this.logger = log;
    this.feedback = feedback;
    if (format.isNotNull(props.match)) {
      this.params = props.match.params;
    }
  }

  render() {
    return (
      <ApplicationController
        viewModel={this.viewModel}
        log={this.logger}
        feedback={this.feedback}
        toggleTopbar={this.props.toggleTopbar}
        // Additional Props
        sidebarWidth={this.props.sidebarWidth}
        dashboardType={this.props.dashboardType}
        viewType={this.props.viewType}
        applicationType={this.props.applicationType}
        params={this.params}
        // dependant on viewType
        crudlState={this.props.crudlState}
        editedObjectFromProps={this.props.editedObjectFromProps}
        updateStateFromProps={this.props.updateStateFromProps}
        updateObjectFromProps={this.props.updateObjectFromProps}
      />
    );
  }
}
