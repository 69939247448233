import React, { Component } from "react";
// import * as configParser from "../../common/helpers/ConfigurationParser";
export class LoadScreen extends Component {
  render() {
    return (
      <div className="p-col-12">
        <div className="splash-screen" style={{ background: "#ffffff" }}>
          <div
            className="splash-container"
            style={{ paddingTop: "10em", paddingBottom: "10em" }}
          >
            <div>
              <img
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "99%",
                }}
                // src={configParser.getProperty("businessLogoSecondary")}
                alt=""
              />
            </div>
            <div className="load-bar">
              <div className="bar" />
              <div className="bar" />
              <div className="bar" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
