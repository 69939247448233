import React, { Component } from "react";
import classNames from "classnames";
import { MasterInlineProfile } from "./MasterInlineProfile";
//import { MasterFooter } from "./MasterFooter";
import { AppMenu } from "./AppMenu";
import { AppRightPanel } from "./AppRightPanel";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Route } from "react-router-dom";
import { logout } from "./common/helpers/auth";
import * as pr from "./common/PrimeComponents";

import "./ripple.js";
import "./Master.css";

import { PPEMasterTopbar } from "./PPEMasterTopbar";
import { PPEProfileProvider } from "./components/PPEProfile/PPEProfileProvider";
import { RequirementProvider } from "./components/Requirement/RequirementProvider";
import { RequiredProvider } from "./components/Required/RequiredProvider";
import { AvailableProvider } from "./components/Available/AvailableProvider";
import { SupplyProvider } from "./components/Supply/SupplyProvider";
import { EnquiriesProvider } from "./components/Enquiries/EnquiriesProvider";
import * as format from "./common/FormatFunctions";
import { PPEFooterImage } from "./PPEFooterImage";
import { api } from "./common/CloudService/ApiPromisify";
import { CloudService } from "./common/CloudService/ClientLibsReference";

import { Scenario1 } from "./components/SwitchAlternate/Scenario1";
import { Scenario2 } from "./components/SwitchAlternate/Scenario2";
import * as analytics from "./common/helpers/firebaseAnalytics";

export class PPEMaster extends Component {
  constructor() {
    super();
    this.state = {
      layoutMode: "overlay",
      profileMode: "inline",
      overlayMenuActive: false,
      staticMenuDesktopInactive: false,
      staticMenuMobileActive: false,
      rotateMenuButton: false,
      topbarMenuActive: false,
      activeTopbarItem: null,
      darkMenu: false,
      rightPanelActive: false,
      menuActive: true,
      logoutDialog: false,
    };

    this.onDocumentClick = this.onDocumentClick.bind(this);
    this.onMenuClick = this.onMenuClick.bind(this);
    this.onMenuButtonClick = this.onMenuButtonClick.bind(this);
    this.onTopbarMenuButtonClick = this.onTopbarMenuButtonClick.bind(this);
    this.onTopbarItemClick = this.onTopbarItemClick.bind(this);
    this.onMenuItemClick = this.onMenuItemClick.bind(this);
    this.onRootMenuItemClick = this.onRootMenuItemClick.bind(this);
    this.onRightPanelButtonClick = this.onRightPanelButtonClick.bind(this);
    this.onRightPanelClick = this.onRightPanelClick.bind(this);
    this.createMenu();
  }

  onMenuClick(event) {
    this.menuClick = true;

    if (!this.isHorizontal()) {
      setTimeout(() => {
        this.layoutMenuScroller.moveBar();
      }, 500);
    }
  }

  onMenuButtonClick(event) {
    this.menuClick = true;
    this.setState({
      rotateMenuButton: !this.state.rotateMenuButton,
      topbarMenuActive: false,
    });

    if (this.state.layoutMode === "overlay") {
      this.setState({
        overlayMenuActive: !this.state.overlayMenuActive,
      });
    } else {
      if (this.isDesktop())
        this.setState({
          staticMenuDesktopInactive: !this.state.staticMenuDesktopInactive,
        });
      else
        this.setState({
          staticMenuMobileActive: !this.state.staticMenuMobileActive,
        });
    }

    event.preventDefault();
  }

  onTopbarMenuButtonClick(event) {
    this.topbarItemClick = true;
    this.setState({ topbarMenuActive: !this.state.topbarMenuActive });
    this.hideOverlayMenu();
    event.preventDefault();
  }

  onTopbarItemClick(event) {
    this.topbarItemClick = true;

    if (this.state.activeTopbarItem === event.item)
      this.setState({ activeTopbarItem: null });
    else this.setState({ activeTopbarItem: event.item });

    event.originalEvent.preventDefault();
  }

  onMenuItemClick(event) {
    if (!event.item.items) {
      this.hideOverlayMenu();
    }
    if (!event.item.items && (this.isHorizontal() || this.isSlim())) {
      this.setState({
        menuActive: false,
      });
    }
  }

  onRootMenuItemClick(event) {
    this.setState({
      menuActive: !this.state.menuActive,
    });

    event.originalEvent.preventDefault();
  }

  onRightPanelButtonClick(event) {
    this.rightPanelClick = true;
    this.setState({
      rightPanelActive: !this.state.rightPanelActive,
    });
    event.preventDefault();
  }

  onRightPanelClick(event) {
    this.rightPanelClick = true;
  }

  onDocumentClick(event) {
    if (!this.topbarItemClick) {
      this.setState({
        activeTopbarItem: null,
        topbarMenuActive: false,
      });
    }

    if (!this.menuClick) {
      if (this.isHorizontal() || this.isSlim()) {
        this.setState({
          menuActive: false,
        });
      }

      this.hideOverlayMenu();
    }

    if (!this.rightPanelClick) {
      this.setState({
        rightPanelActive: false,
      });
    }

    this.topbarItemClick = false;
    this.menuClick = false;
    this.rightPanelClick = false;
  }

  hideOverlayMenu() {
    this.setState({
      rotateMenuButton: false,
      overlayMenuActive: false,
      staticMenuMobileActive: false,
    });
  }

  isTablet() {
    let width = window.innerWidth;
    return width <= 1024 && width > 640;
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }

  isMobile() {
    return window.innerWidth <= 640;
  }

  isOverlay() {
    return this.state.layoutMode === "overlay";
  }

  isHorizontal() {
    return this.state.layoutMode === "horizontal";
  }

  isSlim() {
    return this.state.layoutMode === "slim";
  }

  changeTheme(theme) {
    this.changeStyleSheetUrl("layout-css", theme, "layout");
    this.changeStyleSheetUrl("theme-css", theme, "theme");
  }

  changeStyleSheetUrl(id, value, prefix) {
    let element = document.getElementById(id);
    let urlTokens = element.getAttribute("href").split("/");
    urlTokens[urlTokens.length - 1] = prefix + "-" + value + ".css";
    let newURL = urlTokens.join("/");
    element.setAttribute("href", newURL);
  }

  createBuyerMenu = () => {
    return [
      {
        label: "Enquiries",
        icon: "inbox",
        command: () => {
          window.location = "#/Dashboard";
        },
      },

      {
        label: "Suppliers Products & Services",
        icon: "store",
        command: () => {
          window.location = "#/suppliers-products-and-services";
        },
      },

      {
        label: "My Requirements",
        icon: "search",
        command: () => {
          window.location = "#/my-requirements";
        },
      },
      {
        label: "My Profile",
        icon: "business",
        command: () => {
          window.location = "#/my-profile";
        },
      },
      {
        label: "Switch to Buy/Supply",
        icon: "sync_alt",
        command: () => {
          this.checkForNormalProfile();
        },
      },
      {
        label: "Logout",
        icon: "power_settings_new",
        command: () => {
          this.setState({ logoutDialog: true });
        },
      },
    ];
  };
  createSupplierMenu = () => {
    return [
      {
        label: "Enquiries",
        icon: "inbox",
        command: () => {
          window.location = "#/Dashboard";
        },
      },
      {
        label: "Buyer Requirements",
        icon: "shop_two",
        command: () => {
          window.location = "#/buyer-requirements";
        },
      },

      {
        label: "My Supplies",
        icon: "control_point",
        command: () => {
          window.location = "#/my-supplies";
        },
      },

      {
        label: "My Profile",
        icon: "business",
        command: () => {
          window.location = "#/my-profile";
        },
      },
      {
        label: "Switch to Buy/Supply",
        icon: "sync_alt",
        command: () => {
          this.checkForNormalProfile();
        },
      },
      {
        label: "Logout",
        icon: "power_settings_new",
        command: () => {
          this.setState({ logoutDialog: true });
        },
      },
    ];
  };

  createBothMenu = () => {
    return [
      {
        label: "Enquiries",
        icon: "inbox",
        command: () => {
          window.location = "#/Dashboard";
        },
      },
      {
        label: "Buyer Requirements",
        icon: "shop_two",
        command: () => {
          window.location = "#/buyer-requirements";
        },
      },
      {
        label: "Suppliers Products & Services",
        icon: "store",
        command: () => {
          window.location = "#/suppliers-products-and-services";
        },
      },

      {
        label: "My Supplies",
        icon: "control_point",
        command: () => {
          window.location = "#/my-supplies";
        },
      },
      {
        label: "My Requirements",
        icon: "search",
        command: () => {
          window.location = "#/my-requirements";
        },
      },
      {
        label: "My Profile",
        icon: "business",
        command: () => {
          window.location = "#/my-profile";
        },
      },
      {
        label: "Switch to Buy/Supply",
        icon: "sync_alt",
        command: () => {
          this.checkForNormalProfile();
        },
      },
      {
        label: "Logout",
        icon: "power_settings_new",
        command: () => {
          this.setState({ logoutDialog: true });
        },
      },
    ];
  };

  createMenu() {
    var menu = [];

    var profileType = localStorage.getItem("validPPEProfile");
    if (format.isNotNull(profileType)) {
      if (profileType === "PPESupplier") {
        menu = this.createSupplierMenu();
      }

      if (profileType === "PPEBuyer") {
        menu = this.createBuyerMenu();
      }

      if (profileType === "Both") {
        menu = this.createBothMenu();
      }
    }

    this.menu = menu;
  }
  getLogoutDialog = () => {
    return (
      <pr.Dialog
        header="Logging out"
        footer={
          <div className="p-grid">
            <div className="p-col-12">
              <pr.Button
                className="blk mrgn flt-r"
                label="Yes"
                icon="pi-md-check"
                onClick={() => {
                  logout();
                  localStorage.clear();
                  window.location = "#/login-covid-19-supplies";
                }}
              />
              <pr.Button
                className="blk mrgn flt-r"
                label="No"
                icon="pi-md-close"
                onClick={() => {
                  this.setState({ logoutDialog: false });
                }}
              />
            </div>
          </div>
        }
        visible={this.state.logoutDialog}
        onHide={() => {
          this.setState({ logoutDialog: false });
        }}
        modal={true}
      >
        Are you sure you want to logout?
      </pr.Dialog>
    );
  };
  checkForNormalProfile = async () => {
    this.setState({ profileLoading: true });
    var [value, status] = await this.getProfile();
    analytics.logEvent(analytics.Actions.SWITCH_TO_BUYSUPPLY);
    if (value === 1) {
      this.setState({ scenario1SideBar: true });
    }
    if (value === 2) {
      this.setState({ scenario2SideBar: true, profileStatus: status });
    }
    if (value === 3) {
      //Your dead on here
    }
    this.setState({ profileLoading: false });
  };

  getProfile = async () => {
    let profileRequest = {};
    profileRequest.codeTag = localStorage.getItem("clientId");

    var returnValue = 0;
    var returnStatus = "";
    await api("profileApiV1ProfileGetByCodeTag", profileRequest).then((res) => {
      if (res.data) {
        let profileResponse = CloudService.ProfileResponse.constructFromObject(
          res.data
        );
        console.log(profileResponse);
        var validProfile = "";
        var validProfileStatus = "";
        if (Array.isArray(profileResponse.profileList)) {
          profileResponse.profileList.forEach((element) => {
            if (element.type === "Buyer") {
              validProfile = "Buyer";
              validProfileStatus = element.profileStatus;
            }
            if (element.type === "Supplier") {
              validProfile = "Supplier";
              validProfileStatus = element.profileStatus;
            }
          });
        }

        //TEST A
        //validPPEProfile = "";
        if (validProfile === "") {
          returnValue = 1;
          //Use Case 1 - Need to Create Profile
        } else {
          if (validProfileStatus !== "Approved") {
            // Use Case 2 need to Diplsy information about not yet being approved
            returnValue = 2;
            returnStatus = validProfileStatus;
          } else {
            localStorage.setItem("profileStatus", validProfileStatus);
            localStorage.setItem("profileType", validProfile);
            localStorage.removeItem("validPPEProfile");
            window.location = "#/Dashboard";
            window.location.reload();

            returnValue = 3;
          }
        }
      }
    });

    return [returnValue, returnStatus];
  };

  render() {
    let layoutContainerClassName = classNames("layout-container", {
      "menu-layout-static": this.state.layoutMode !== "overlay",
      "menu-layout-overlay": this.state.layoutMode === "overlay",
      "layout-menu-overlay-active": this.state.overlayMenuActive,
      "menu-layout-slim": this.state.layoutMode === "slim",
      "menu-layout-horizontal": this.state.layoutMode === "horizontal",
      "layout-menu-static-inactive": this.state.staticMenuDesktopInactive,
      "layout-menu-static-active": this.state.staticMenuMobileActive,
    });
    let menuClassName = classNames("layout-menu", {
      "layout-menu-dark": this.state.darkMenu,
    });

    return (
      <div className="layout-wrapper" onClick={this.onDocumentClick}>
        <div
          ref={(el) => (this.layoutContainer = el)}
          className={layoutContainerClassName}
        >
          {this.getLogoutDialog()}
          {this.state.scenario1SideBar && (
            <pr.Sidebar
              visible={true}
              position="left"
              fullScreen={false}
              onHide={() => {
                this.setState({ scenario1SideBar: false });
              }}
              baseZIndex={10000000000}
              style={{
                width: "75%",
              }}
            >
              <Scenario1
                close={() => {
                  this.setState({ scenario1SideBar: false });
                }}
              ></Scenario1>
            </pr.Sidebar>
          )}

          <pr.Sidebar
            visible={this.state.scenario2SideBar}
            position="left"
            fullScreen={false}
            onHide={() => {
              this.setState({ scenario2SideBar: false });
            }}
            baseZIndex={10000000000}
            style={{
              width: "50%",
            }}
          >
            <Scenario2
              status={this.state.profileStatus}
              close={() => {
                this.setState({ scenario2SideBar: false });
              }}
            ></Scenario2>
          </pr.Sidebar>
          <PPEMasterTopbar
            profileMode={this.state.profileMode}
            horizontal={this.props.horizontal}
            topbarMenuActive={this.state.topbarMenuActive}
            activeTopbarItem={this.state.activeTopbarItem}
            onMenuButtonClick={this.onMenuButtonClick}
            onTopbarMenuButtonClick={this.onTopbarMenuButtonClick}
            onTopbarItemClick={this.onTopbarItemClick}
            onRightPanelButtonClick={this.onRightPanelButtonClick}
          />

          <div
            className={menuClassName}
            //style={{ top: "0px" }}
            onClick={this.onMenuClick}
          >
            <pr.ScrollPanel
              ref={(el) => (this.layoutMenuScroller = el)}
              style={{ height: "100%" }}
            >
              <div className="menu-scroll-content">
                {this.state.profileMode === "inline" &&
                  this.state.layoutMode !== "horizontal" && (
                    <MasterInlineProfile />
                  )}
                <AppMenu
                  model={this.menu}
                  onMenuItemClick={this.onMenuItemClick}
                  onRootMenuItemClick={this.onRootMenuItemClick}
                  layoutMode={this.state.layoutMode}
                  active={this.state.menuActive}
                />
              </div>
            </pr.ScrollPanel>
          </div>

          <div className="layout-main" style={{ margin: "0px" }}>
            <Route
              path="/buyer-requirements"
              exact
              component={RequiredProvider}
            />
            <Route
              path="/suppliers-products-and-services"
              exact
              component={AvailableProvider}
            />
            <Route path="/my-supplies" exact component={SupplyProvider} />
            <Route
              path="/my-requirements"
              exact
              component={RequirementProvider}
            />
            <Route path="/dashboard" exact component={EnquiriesProvider} />
            <Route path="/my-profile" exact component={PPEProfileProvider} />

            <PPEFooterImage />
            {/* <MasterFooter /> */}
          </div>

          <AppRightPanel
            expanded={this.state.rightPanelActive}
            onContentClick={this.onRightPanelClick}
          />

          <div className="layout-mask" />
        </div>
      </div>
    );
  }
}
