import React from "react";
//PrimeReact Components
import * as pr from "../../../common/PrimeComponents";
import * as format from "../../../common/FormatFunctions";
import * as EnumDropdowns from "../../../common/config/EnumDropdowns";
import { ViewType } from "../../../common/config/AppConstants";

export class ProductBatch extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div id="batchBody" className="scrl">
          <pr.DataTable
            id="batchTable"
            value={this.props.batchList}
            editMode="cell"
            className="editable-cells-table"
            editable={true}
          >
            <pr.Column
              field="name"
              header="Name *"
              style={{ textAlign: "center", background: "transparent" }}
              body={(props) => {
                return this.stringTemplate(props, "name");
              }}
              editor={(props) => this.getTextEditor(props, "name")}
            />
            <pr.Column
              className="p-cell-editing"
              field="componentList"
              header={"Type *"}
              style={{ textAlign: "center" }}
              body={(props) => {
                return this.stringTemplate(props, "componentNameToDisplay");
              }}
              editor={(props) =>
                this.getDropdownEditor(
                  props,
                  "componentList",
                  this.props.componentOptions,
                  ""
                )
              }
            />
            <pr.Column
              className="p-editable-column p-cell-editing"
              field="productStatus"
              header={"Status *"}
              style={{ textAlign: "center" }}
              body={(props) => {
                return this.dropdownStatusTemplate(
                  props,
                  "productStatus",
                  EnumDropdowns.ProductStatusOptions
                );
              }}
              editor={(props) =>
                this.getDropdownEditor(
                  props,
                  "productStatus",
                  EnumDropdowns.ProductStatusOptions,
                  ""
                )
              }
            />
            {this.props.viewType === ViewType.MY_PRODUCT && (
              <pr.Column
                field="shortDescription"
                header="Link to Website"
                style={{ textAlign: "center" }}
                body={(props) => {
                  return this.stringTemplate(props, "shortDescription");
                }}
                editor={(props) =>
                  this.getTextEditor(props, "shortDescription")
                }
              />
            )}
            {this.props.viewType === ViewType.MY_PRODUCT && (
              <pr.Column
                className="p-cell-editing"
                field="location"
                header="Location"
                style={{ textAlign: "center" }}
                body={(props) => {
                  return this.stringTemplate(props, "location");
                }}
                editor={(props) => this.getTextEditor(props, "location")}
              />
            )}
            <pr.Column
              field="imageUri"
              header="Images *"
              style={{ textAlign: "center" }}
              body={(props) => {
                return this.imagesBodyTemplate(props, "imageUri");
              }}
            />
            <pr.Column
              field="pdfUrls"
              header="Documents"
              style={{ textAlign: "center" }}
              body={(props) => {
                return this.documentsBodyTemplate(props, "pdfUrls");
              }}
            />
            {this.props.viewType === ViewType.MY_REQUIREMENT && (
              <pr.Column
                field="dateRequired"
                header="Date"
                style={{ textAlign: "center" }}
                body={(props) => {
                  return this.dateBodyTemplate(props, "dateRequired");
                }}
              />
            )}
            <pr.Column
              field="longDescription"
              header="Additional Details"
              style={{ textAlign: "center" }}
              body={(props) => {
                return this.notesBodyTemplate(props, "longDescription");
              }}
            />
          </pr.DataTable>
        </div>
        <div className="sidebar-footer">
          <hr />
          <pr.Button
            className="mrgn flt-l"
            label="Cancel"
            icon="pi-md-close"
            onClick={() => {
              this.props.validateChanges();
            }}
          />
          <pr.Button
            className="mrgn flt-r"
            label="Summary"
            icon="pi-md-book"
            onClick={() => {
              this.props.sortBatchProducts();
            }}
            disabled={this.props.checkRequiredFields()}
          />
        </div>
      </React.Fragment>
    );
  }

  // Templates
  stringTemplate = (rowData, field) => {
    return (
      <pr.InputText
        value={rowData[field] || ""}
        disabled={true}
        style={{ minHeight: "5px" }}
      />
    );
  };
  dropdownStatusTemplate = (rowData, field, options) => {
    return (
      <pr.Dropdown
        value={rowData[field][0]}
        options={options}
        style={{ width: "100%", zIndex: 10000000001 }}
      />
    );
  };

  dateBodyTemplate = (rowData, field) => {
    var dateToDisplay = "";
    if (format.isNotNull(rowData[field])) {
      dateToDisplay = format.formatDate(rowData[field], "DDMMYYYY");
    }

    return (
      <React.Fragment>
        <div
          onClick={(e) => {
            this[rowData.sequenceId].toggle(e);
          }}
          style={
            dateToDisplay
              ? {}
              : {
                  float: "left",
                  padding: "0.8rem",
                  width: "100%",
                  height: "100%",
                }
          }
        >
          {dateToDisplay}
        </div>

        <pr.OverlayPanel
          ref={(el) => {
            this[rowData.sequenceId] = el;
          }}
        >
          {this.getDateEditor(rowData, field, "dd/mm/yy")}
        </pr.OverlayPanel>
      </React.Fragment>
    );
  };
  decimalBodyTemplate = (rowData, field) => {
    var priceToDisplay = "";
    if (format.isNotNull(rowData[field])) {
      priceToDisplay = format.priceToDisplay(rowData[field]);
    } else {
      priceToDisplay = format.priceToDisplay("0.00");
    }
    return priceToDisplay;
  };
  dropdownBodyTemplate = (rowData, field, returnField, array) => {
    var display = "";
    if (field === "componentList") {
      if (format.isNotNull(rowData.componentNameToDisplay)) {
        display = rowData.componentNameToDisplay;
      }
      //   else if (format.isNotNull(array)) {
      //     array.forEach((e) => {
      //       console.log(rowData[field]);
      //       if (format.isNotNull(rowData[field])) {
      //         let componentList = rowData[field];
      //         componentList.forEach((f) => {
      //           if (e.id === f) {
      //             display = e[returnField];
      //           }
      //         });
      //       }
      //     });
      //   }
    }

    return display;
  };
  imagesBodyTemplate = (rowData, field) => {
    return (
      <React.Fragment>
        <div
          onClick={(e) => {
            this.props.toggleImageDisplay(rowData, field);
          }}
          style={{
            float: "left",
            padding: "0.8rem",
            width: "100%",
            height: "100%",
          }}
        >
          <pr.Button
            label={
              rowData[field]
                ? "Upload (" + rowData[field].length + ")"
                : "Upload"
            }
            onClick={() => {}}
          />
        </div>
      </React.Fragment>
    );
  };
  documentsBodyTemplate = (rowData, field) => {
    return (
      <React.Fragment>
        <div
          onClick={(e) => {
            this.props.toggleDocumentDisplay(rowData, field);
          }}
          style={{
            float: "left",
            padding: "0.8rem",
            width: "100%",
            height: "100%",
          }}
        >
          <pr.Button
            label={
              rowData[field]
                ? "Upload (" + rowData[field].length + ")"
                : "Upload"
            }
            onClick={() => {}}
          />
        </div>
      </React.Fragment>
    );
  };
  notesBodyTemplate = (rowData, field) => {
    return (
      <React.Fragment>
        <div
          onClick={(e) => {
            this.props.toggleNotesDisplay(rowData, field);
          }}
        >
          <pr.Button
            label={rowData[field] ? "View" : "Add"}
            onClick={() => {}}
          />
        </div>
      </React.Fragment>
    );
  };

  // Editors
  getDateEditor = (rowData, field, format) => {
    return (
      <pr.Calendar
        showIcon={false}
        value={rowData[field]}
        style={{ width: "100%", zIndex: 100000000011111111111111 }}
        inputStyle={{ width: "100%" }}
        onChange={(e) => {
          this.props.onProductEditorValueChange(
            rowData.sequenceId - 1,
            field,
            new Date(
              Date.UTC(
                e.value.getFullYear(),
                e.value.getMonth(),
                e.value.getDate()
              )
            )
          );
        }}
        dateFormat={format}
        inline
        locale={"en"}
      />
    );
  };
  getTextEditor = (props, field) => {
    return (
      <pr.InputText
        value={props.rowData[field] || ""}
        onChange={(e) =>
          this.props.onProductEditorValueChange(
            props.rowIndex,
            props.field,
            e.target.value
          )
        }
        style={{ minHeight: "5px" }}
      />
    );
  };
  getDecimalEditor = (props, field) => {
    return (
      <pr.InputNumber
        value={props.rowData[field] || 0}
        onValueChange={(e) =>
          this.props.onProductEditorValueChange(
            props.rowIndex,
            props.field,
            e.target.value
          )
        }
        mode="decimal"
        minFractionDigits={2}
      />
    );
  };
  getIntegerEditor = (props, field) => {
    return (
      <pr.InputNumber
        value={props.rowData[field] || 0}
        onChange={(e) =>
          this.props.onProductEditorValueChange(
            props.rowIndex,
            props.field,
            e.target.value
          )
        }
      />
    );
  };
  getDropdownEditor = (props, field, options, placeholder) => {
    return (
      <pr.Dropdown
        className="dropdownDatatable"
        value={props.rowData[field]}
        options={options}
        optionLabel="label"
        optionValue="value"
        onChange={(e) => {
          let array = [];
          array.push(e.value);
          this.props.onProductEditorValueChange(props.rowIndex, field, array);
        }}
        style={{ width: "100%", zIndex: 10000000001 }}
        placeholder={placeholder}
      />
    );
  };
}
