/**
 * buysupply-production.appspot.com
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.1
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient', 'model/BusinessBean', 'model/IProfileBean', 'model/IProfileItemBean'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('./BusinessBean'), require('./IProfileBean'), require('./IProfileItemBean'));
  } else {
    // Browser globals (root is window)
    if (!root.BuysupplyProductionappspotcom) {
      root.BuysupplyProductionappspotcom = {};
    }
    root.BuysupplyProductionappspotcom.IProfileBean = factory(root.BuysupplyProductionappspotcom.ApiClient, root.BuysupplyProductionappspotcom.BusinessBean, root.BuysupplyProductionappspotcom.IProfileBean, root.BuysupplyProductionappspotcom.IProfileItemBean);
  }
}(this, function(ApiClient, BusinessBean, IProfileBean, IProfileItemBean) {
  'use strict';




  /**
   * The IProfileBean model module.
   * @module model/IProfileBean
   * @version 1.0.0
   */

  /**
   * Constructs a new <code>IProfileBean</code>.
   * @alias module:model/IProfileBean
   * @class
   */
  var exports = function() {
    var _this = this;





















  };

  /**
   * Constructs a <code>IProfileBean</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/IProfileBean} obj Optional instance to populate.
   * @return {module:model/IProfileBean} The populated <code>IProfileBean</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('beanInstance')) {
        obj['beanInstance'] = IProfileBean.constructFromObject(data['beanInstance']);
      }
      if (data.hasOwnProperty('certifiedProducts')) {
        obj['certifiedProducts'] = ApiClient.convertToType(data['certifiedProducts'], 'Boolean');
      }
      if (data.hasOwnProperty('certifiedProductsDescription')) {
        obj['certifiedProductsDescription'] = ApiClient.convertToType(data['certifiedProductsDescription'], 'String');
      }
      if (data.hasOwnProperty('codeTag')) {
        obj['codeTag'] = ApiClient.convertToType(data['codeTag'], 'String');
      }
      if (data.hasOwnProperty('companyDetails')) {
        obj['companyDetails'] = BusinessBean.constructFromObject(data['companyDetails']);
      }
      if (data.hasOwnProperty('componentList')) {
        obj['componentList'] = ApiClient.convertToType(data['componentList'], ['String']);
      }
      if (data.hasOwnProperty('componentListBuyer')) {
        obj['componentListBuyer'] = ApiClient.convertToType(data['componentListBuyer'], ['String']);
      }
      if (data.hasOwnProperty('componentListSupplier')) {
        obj['componentListSupplier'] = ApiClient.convertToType(data['componentListSupplier'], ['String']);
      }
      if (data.hasOwnProperty('contactedByList')) {
        obj['contactedByList'] = ApiClient.convertToType(data['contactedByList'], [BusinessBean]);
      }
      if (data.hasOwnProperty('dateCreated')) {
        obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
      }
      if (data.hasOwnProperty('formId')) {
        obj['formId'] = ApiClient.convertToType(data['formId'], 'String');
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('lastUpdatedDateTime')) {
        obj['lastUpdatedDateTime'] = ApiClient.convertToType(data['lastUpdatedDateTime'], 'Date');
      }
      if (data.hasOwnProperty('numberOfContacts')) {
        obj['numberOfContacts'] = ApiClient.convertToType(data['numberOfContacts'], 'Number');
      }
      if (data.hasOwnProperty('numberOfProfileVisits')) {
        obj['numberOfProfileVisits'] = ApiClient.convertToType(data['numberOfProfileVisits'], 'Number');
      }
      if (data.hasOwnProperty('profileItemList')) {
        obj['profileItemList'] = ApiClient.convertToType(data['profileItemList'], [IProfileItemBean]);
      }
      if (data.hasOwnProperty('profileStatus')) {
        obj['profileStatus'] = ApiClient.convertToType(data['profileStatus'], 'String');
      }
      if (data.hasOwnProperty('type')) {
        obj['type'] = ApiClient.convertToType(data['type'], 'String');
      }
      if (data.hasOwnProperty('userEmail')) {
        obj['userEmail'] = ApiClient.convertToType(data['userEmail'], 'String');
      }
      if (data.hasOwnProperty('userId')) {
        obj['userId'] = ApiClient.convertToType(data['userId'], 'String');
      }
    }
    return obj;
  }

  /**
   * @member {module:model/IProfileBean} beanInstance
   */
  exports.prototype['beanInstance'] = undefined;
  /**
   * @member {Boolean} certifiedProducts
   */
  exports.prototype['certifiedProducts'] = undefined;
  /**
   * @member {String} certifiedProductsDescription
   */
  exports.prototype['certifiedProductsDescription'] = undefined;
  /**
   * @member {String} codeTag
   */
  exports.prototype['codeTag'] = undefined;
  /**
   * @member {module:model/BusinessBean} companyDetails
   */
  exports.prototype['companyDetails'] = undefined;
  /**
   * @member {Array.<String>} componentList
   */
  exports.prototype['componentList'] = undefined;
  /**
   * @member {Array.<String>} componentListBuyer
   */
  exports.prototype['componentListBuyer'] = undefined;
  /**
   * @member {Array.<String>} componentListSupplier
   */
  exports.prototype['componentListSupplier'] = undefined;
  /**
   * @member {Array.<module:model/BusinessBean>} contactedByList
   */
  exports.prototype['contactedByList'] = undefined;
  /**
   * @member {Date} dateCreated
   */
  exports.prototype['dateCreated'] = undefined;
  /**
   * @member {String} formId
   */
  exports.prototype['formId'] = undefined;
  /**
   * @member {String} id
   */
  exports.prototype['id'] = undefined;
  /**
   * @member {Date} lastUpdatedDateTime
   */
  exports.prototype['lastUpdatedDateTime'] = undefined;
  /**
   * @member {Number} numberOfContacts
   */
  exports.prototype['numberOfContacts'] = undefined;
  /**
   * @member {Number} numberOfProfileVisits
   */
  exports.prototype['numberOfProfileVisits'] = undefined;
  /**
   * @member {Array.<module:model/IProfileItemBean>} profileItemList
   */
  exports.prototype['profileItemList'] = undefined;
  /**
   * @member {String} profileStatus
   */
  exports.prototype['profileStatus'] = undefined;
  /**
   * @member {String} type
   */
  exports.prototype['type'] = undefined;
  /**
   * @member {String} userEmail
   */
  exports.prototype['userEmail'] = undefined;
  /**
   * @member {String} userId
   */
  exports.prototype['userId'] = undefined;



  return exports;
}));


