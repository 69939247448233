import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
//Imports from common
import { logout } from "./common/helpers/auth";

export class MasterTopbar extends Component {
  static defaultProps = {
    onMenuButtonClick: null,
    onTopbarMenuButtonClick: null,
    onTopbarItemClick: null,
    profileMode: null,
    horizontal: false,
    topbarMenuActive: false,
    activeTopbarItem: null,
    onRightPanelButtonClick: null,
  };

  static propTypes = {
    onMenuButtonClick: PropTypes.func.isRequired,
    onTopbarMenuButtonClick: PropTypes.func.isRequired,
    onTopbarItemClick: PropTypes.func.isRequired,
    profileMode: PropTypes.string.isRequired,
    horizontal: PropTypes.bool.isRequired,
    topbarMenuActive: PropTypes.bool.isRequired,
    activeTopbarItem: PropTypes.string,
    onRightPanelButtonClick: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {};
  }

  onTopbarItemClick(event, item) {
    if (this.props.onTopbarItemClick) {
      this.props.onTopbarItemClick({
        originalEvent: event,
        item: item,
      });
    }
  }

  render() {
    let topbarItemsClassName = classNames("topbar-items animated fadeInDown", {
      "topbar-items-visible": this.props.topbarMenuActive,
    });

    return (
      <div className="topbar clearfix">
        <div
          className="topbar-left"
          style={{ backgroundColor: "transparent" }}
        ></div>
        <div className="topbar-right">
          <a id="menu-button" onClick={this.props.onMenuButtonClick} href="/#/">
            <i />
          </a>

          <a
            id="topbar-menu-button"
            onClick={this.props.onTopbarMenuButtonClick}
            href="/#/"
          >
            <i className="material-icons">menu</i>
          </a>
          <ul className={topbarItemsClassName}>
            {(this.props.profileMode === "top" || this.props.horizontal) && (
              <li
                className={classNames("profile-item", {
                  "active-top-menu": this.props.activeTopbarItem === "profile",
                })}
              >
                <a
                  onClick={(e) => this.onTopbarItemClick(e, "profile")}
                  href="/#/"
                >
                  <img
                    className="profile-image"
                    src="assets/layout/images/avatar.png"
                    alt="Profile"
                  />
                  <span className="topbar-item-name">
                    {localStorage.getItem("email")}
                  </span>
                </a>

                <ul className="ultima-menu animated fadeInDown">
                  <li
                    role="menuitem"
                    onClick={() => {
                      logout();
                      localStorage.clear();
                      window.location = "#/";
                      window.location.reload();
                    }}
                  >
                    <button>
                      <i className="material-icons">power_settings_new</i>
                      <span>Logout</span>
                    </button>
                  </li>
                </ul>
              </li>
            )}

            <li
              className={classNames({
                "active-top-menu": this.props.activeTopbarItem === "settings",
              })}
            >
              <a
                onClick={(e) => this.onTopbarItemClick(e, "settings")}
                href="/#/"
              >
                <i className="topbar-icon material-icons">settings</i>
                <span className="topbar-item-name">Settings</span>
              </a>
              {localStorage.getItem("roleType") === "10" ||
              localStorage.getItem("roleType") === "20" ? (
                <ul className="ultima-menu animated fadeInDown">
                  <li role="menuitem">
                    <button>
                      <span>
                        <center>
                          {"Signed in as: " + localStorage.getItem("email")}
                        </center>
                      </span>
                    </button>
                  </li>
                  <li
                    role="menuitem"
                    onClick={() => {
                      window.location = "#/Help_Desk";
                    }}
                  >
                    <button>
                      <i className="material-icons">info</i>
                      <span>Help Desk</span>
                    </button>
                  </li>
                  <li
                    role="menuitem"
                    onClick={() => {
                      window.location = "#/council_users";
                    }}
                  >
                    <button>
                      <i className="material-icons">settings</i>
                      <span>Users</span>
                    </button>
                  </li>
                  <li
                    role="menuitem"
                    onClick={() => {
                      logout();
                      localStorage.clear();
                      window.location = "#/council_login";
                      window.location.reload();
                    }}
                  >
                    <button>
                      <i className="material-icons">power_settings_new</i>
                      <span>Logout</span>
                    </button>
                  </li>
                </ul>
              ) : localStorage.getItem("roleType") === "30" ? (
                <ul className="ultima-menu animated fadeInDown">
                  <li role="menuitem">
                    <button>
                      <center>
                        {"Signed in as: " + localStorage.getItem("email")}
                      </center>
                    </button>
                  </li>
                  <li
                    role="menuitem"
                    onClick={() => {
                      window.location = "#/Help_Desk";
                    }}
                  >
                    <button>
                      <i className="material-icons">info</i>
                      <span>Help Desk</span>
                    </button>
                  </li>
                  <li
                    role="menuitem"
                    onClick={() => {
                      window.location = "#/business_settings";
                    }}
                  >
                    <button>
                      <i className="material-icons">settings</i>
                      <span>Business Settings</span>
                    </button>
                  </li>
                  <li
                    role="menuitem"
                    onClick={() => {
                      logout();
                      localStorage.clear();
                      window.location = "#/login";
                      window.location.reload();
                    }}
                  >
                    <button>
                      <i className="material-icons">power_settings_new</i>
                      <span>Logout</span>
                    </button>
                  </li>
                </ul>
              ) : (
                <ul className="ultima-menu animated fadeInDown">
                  <li role="menuitem">
                    <button>
                      <center>
                        {"Signed in as: " + localStorage.getItem("email")}
                      </center>
                    </button>
                  </li>
                  <li
                    role="menuitem"
                    onClick={() => {
                      window.location = "#/Help_Desk";
                    }}
                  >
                    <button>
                      <i className="material-icons">info</i>
                      <span>Help Desk</span>
                    </button>
                  </li>
                  <li
                    role="menuitem"
                    onClick={() => {
                      window.location = "#/business_settings";
                    }}
                  >
                    <button>
                      <i className="material-icons">settings</i>
                      <span>Business Settings</span>
                    </button>
                  </li>
                  <li
                    role="menuitem"
                    onClick={() => {
                      logout();
                      var location = "";
                      if (localStorage.getItem("profileType") === "PPE") {
                        location = "#/login-covid-19-supplies";
                      } else {
                        location = "#/login";
                      }

                      localStorage.clear();
                      window.location = location;
                      window.location.reload();
                    }}
                  >
                    <button>
                      <i className="material-icons">power_settings_new</i>
                      <span>Logout</span>
                    </button>
                  </li>
                </ul>
              )}
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
