import React from "react";
//PrimeReact Components
import * as pr from "../../../common/PrimeComponents";
import { CrudlStatus } from "../../../common/config/AppConstants";
import * as format from "../../../common/FormatFunctions";
import { RequiredDisplay } from "./RequiredDisplay";
import ReactHtmlParser from "react-html-parser";

export class RequiredTable extends React.Component {
  export = () => {
    this.dt.exportCSV();
  };

  getRequiredColumns = () => {
    console.log(this.props);
    var allColumns = [];
    // allColumns.push(
    //   <pr.Column
    //     key="expander"
    //     expander={true}
    //     style={{ width: "5em", float: "middle" }}
    //   />
    // );
    if (format.isNotNull(this.props.tableColumns)) {
      let columns = this.props.tableColumns.map((col, i) => {
        return (
          <pr.Column
            key={col.field}
            field={col.field}
            header={col.header}
            sortable={col.sortable}
            filter={col.filter}
            filterMatchMode={col.filterMatchMode}
            style={col.style}
          />
        );
      });

      columns.forEach((e) => {
        allColumns.push(e);
      });
    }
    return allColumns;
  };

  render() {
    return (
      <div className="p-grid bpm">
        <div className="p-col-12">
          <div className="p-col-12">
            <div className="content-section">
              <div className="feature-intro">
                <h1>{"Buyer Requirements"}</h1>
                <pr.Accordion>
                  <pr.AccordionTab header="How to Use">
                    <div className="p-grid">
                      <div className="p-col-12">
                        A list of specific requirements uploaded by buyers. Read
                        the information that the buyer has added and if you can
                        provide the product/service, then contact the buyer with
                        the relevant information related to their requirements.
                        Should the buyer be happy with your request, they will
                        accept the enquiry and you will be emailed their contact
                        details to make direct contact with the buyer.
                      </div>
                    </div>
                  </pr.AccordionTab>
                </pr.Accordion>
              </div>
            </div>
          </div>
        </div>
        <pr.Dialog
          header={"Product Details"}
          style={{ width: "90%" }}
          footer={
            <div className="p-grid">
              <div className="p-col-12">
                <pr.Button
                  className="blk mrgn flt-r"
                  label="Close"
                  icon="pi-md-check"
                  onClick={() => {
                    this.props.updateState("productDetailsView", false);
                  }}
                />
              </div>
            </div>
          }
          visible={this.props.productDetailsView}
          onHide={() => {
            this.props.updateState("productDetailsView", false);
          }}
          modal={true}
        >
          <div className="p-grid">
            <div className="p-col-10">
              {ReactHtmlParser(this.props.productDetails)}
            </div>
          </div>
        </pr.Dialog>
        <div className="p-col-12"></div>
        <>
          <div className="p-col-12 p-md-3"></div>
          <div className="p-col-12 p-md-6">
            <pr.InputText
              id="title"
              value={this.props.globalFilter || ""}
              onChange={(e) => {
                this.props.updateState("globalFilter", e.target.value);
              }}
              placeholder="Type here to search entire table"
              style={{ height: "5vh", backgroundColor: "white" }}
            />
          </div>
        </>
        <div className="p-col-12">
          <pr.DataTable
            ref={(el) => {
              this.dt = el;
            }}
            value={this.props.requiredList}
            emptyMessage="No Requireds"
            expandedRows={this.props.expandedRows}
            onRowToggle={(e) => this.props.updateState("expandedRows", e.data)}
            rowExpansionRequired={this.getDisplayCard}
            rows={Infinity}
            autoLayout={true}
            paginator={false}
            alwaysShowPaginator={false}
            responsive={true}
            loading={this.props.loading}
            scrollable={true}
            scrollHeight="60vh"
            globalFilter={this.props.globalFilter}
          >
            <pr.Column
              key={"header"}
              field={"header"}
              header={"Product Type"}
              sortable={true}
              filter={true}
              filterMatchMode={"contains"}
              style={{ overflow: "auto" }}
            ></pr.Column>

            <pr.Column
              key={"componentNameToDisplay"}
              field={"componentNameToDisplay"}
              header={"Category"}
              sortable={true}
              filter={true}
              filterMatchMode={"contains"}
              style={{ overflow: "auto" }}
            ></pr.Column>
            <pr.Column
              key={"supplierName"}
              field={"businessName"}
              header={"Buyer Name"}
              sortable={true}
              filter={true}
              filterMatchMode={"contains"}
              style={{ overflow: "auto" }}
            ></pr.Column>
            <pr.Column
              header="More Details"
              body={this.moreDetails}
            ></pr.Column>
            <pr.Column
              key={"supplierName"}
              field={"dateCreatedToDisplay"}
              header={"Date Added"}
              sortable={true}
              filter={true}
              filterMatchMode={"contains"}
              style={{ overflow: "auto" }}
            ></pr.Column>
            <pr.Column
              key={"supplierName"}
              field={"dateRequiredToDisplay"}
              header={"Required By(Date)"}
              sortable={true}
              filter={true}
              filterMatchMode={"contains"}
              style={{ overflow: "auto" }}
            ></pr.Column>
            <pr.Column
              key={"location"}
              field={"location"}
              header={"Location/City"}
              sortable={true}
              filter={true}
              filterMatchMode={"contains"}
              style={{ overflow: "auto" }}
            ></pr.Column>

            <pr.Column body={this.getApplicationTemplate}></pr.Column>
          </pr.DataTable>
        </div>
      </div>
    );
  }
  getApplicationTemplate = (rowData, column) => {
    return (
      <div className="p-grid">
        <div className="p-col-3"></div>
        <div className="p-col-6">
          <pr.Button
            type="button"
            label={"Contact "}
            onClick={(e) =>
              this.props.crudlControl(CrudlStatus.CREATE, rowData)
            }
            style={{}}
          ></pr.Button>
        </div>
      </div>
    );
  };
  getLinkToWebsite = (rowData) => {
    if (format.isNotNull(rowData.shortDescription)) {
      if (rowData.shortDescription.includes("http")) {
        return (
          <a
            style={{ display: "table-cell" }}
            href={rowData.shortDescription}
            target="_blank"
            rel="noreferrer"
          >
            Buy Online
          </a>
        );
      } else {
        return <label></label>;
      }
    }

    return <label></label>;
  };
  getLinkToExternalWebsite = (rowData) => {
    if (format.isNotNull(rowData.companyWebsite)) {
      if (rowData.companyWebsite.includes("http")) {
        return (
          <a
            style={{ display: "table-cell" }}
            href={rowData.companyWebsite}
            target="_blank"
            rel="noreferrer"
          >
            View Website
          </a>
        );
      } else {
        return <label></label>;
      }
    }

    return <label></label>;
  };
  moreDetails = (rowData, column) => {
    return (
      <div className="p-grid">
        <div className="p-col-2"></div>
        <div className="p-col-8">
          <pr.Button
            type="button"
            label={"View Details"}
            onClick={(e) => {
              this.props.updateState(
                "productDetails",
                rowData.longDescriptionText
              );
              this.props.updateState("productDetailsView", true);
            }}
            style={{}}
          ></pr.Button>
        </div>
      </div>
    );
  };
  getDisplayCard = (data) => {
    return <RequiredDisplay data={data} onClick={this.props.crudlControl} />;
  };
}
