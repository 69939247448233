import React from "react";
import { BusinessType } from "../../common/config/AppConstants";
import { ViewType } from "../../common/config/AppConstants";
import * as pr from "../../common/PrimeComponents";

import { ProductProvider } from "../Product/ProductProvider";
import { Input, InputType, LabelPosition, UpdateType } from "../Common/Input";
import { ProfileProvider } from "../Profile/ProfileProvider";
import { LoadScreen } from "../Common/LoadScreen";
import * as format from "../../common/FormatFunctions";
import * as analytics from "../../common/helpers/firebaseAnalytics";
export class DashboardView extends React.Component {
  product = (dashboardType, globalFilter) => {
    return (
      <ProductProvider
        viewType={ViewType.DASHBOARD}
        globalFilter={globalFilter ? this.props.globalFilter : null}
        dashboardType={dashboardType}
        updateStateFromProps={this.props.updateState}
        productFilterListEmpty={this.props.productFilterListEmpty}
      />
    );
  };
  business = (dashboardType, globalFilter) => {
    return (
      <ProfileProvider
        viewType={ViewType.DASHBOARD}
        globalFilter={globalFilter ? this.props.globalFilter : null}
        dashboardType={dashboardType}
      />
    );
  };

  dashboardStatsCard = (title, figure, icon, colorbox) => {
    let classNameStr = "p-grid card colorbox " + colorbox;
    return (
      <div
        className="p-col-12 p-sm-12 p-md-6 p-lg-3"
        onClick={(e) => {
          if (title === "Enquiries") {
            window.location = "#/enquiries";
          }
          // this.setState({ rowClass: "OPEN" });
        }}
        style={{ cursor: "pointer" }}
      >
        <div className={classNameStr}>
          <div className="p-col-4">
            <i className="material-icons">{icon}</i>
          </div>
          <div className="p-col-8">
            <span className="colorbox-name">{title}</span>
            <span className="colorbox-count">{figure}</span>
          </div>
        </div>
      </div>
    );
  };

  displayCouncilView = () => {
    return (
      <React.Fragment>
        <pr.TabView style={{ width: "100%" }}>
          <pr.TabPanel header="Manufacturing">
            <div className="p-grid">
              <div className="p-col-12 card">
                <u>
                  <i>
                    <h2>Business</h2>
                  </i>
                </u>
                <div className="p-grid">
                  {this.statCardSpace(12, 1, 0, 0)}
                  <div className="p-col-10">
                    <div className="p-grid">
                      {this.councilStatCard(
                        "Successful",
                        this.props.businessSuccessful,
                        "",
                        "colorbox-green",
                        "/all_profiles/Approved"
                      )}
                      {this.councilStatCard(
                        "Pending",
                        this.props.businessPending,
                        "",
                        "colorbox-orange",
                        "/all_profiles/Pending"
                      )}
                      {this.councilStatCard(
                        "Unsuccessful",
                        this.props.businessUnsuccessful,
                        "",
                        "colorbox-red",
                        "/all_profiles/Unsuccessful"
                      )}
                    </div>
                  </div>
                  {this.statCardSpace(12, 2, 0, 0)}
                </div>
              </div>
              <div className="p-col-12 card">
                <u>
                  <i>
                    <h2>Applications</h2>
                  </i>
                </u>
                <div className="p-grid">
                  {this.statCardSpace(12, 1, 0, 0)}
                  <div className="p-col-10">
                    <div className="p-grid">
                      {this.councilStatCard(
                        "Submitted",
                        this.props.applicationsSubmitted,
                        "",
                        "colorbox-green",
                        "/overview_of_applications/Submitted"
                      )}

                      {this.councilStatCard(
                        "InProgress",
                        this.props.applicationsInProgress,
                        "",
                        "colorbox-orange",
                        "/overview_of_applications/In_Progress"
                      )}
                      {this.councilStatCard(
                        "Unsuccessful",
                        this.props.applciationsUnsuccessful,
                        "",
                        "colorbox-red",
                        "/overview_of_applications/Unsuccessful"
                      )}
                    </div>
                  </div>

                  {this.statCardSpace(12, 1, 0, 0)}
                  {this.statCardSpace(12, 1, 0, 0)}
                  <div className="p-col-10">
                    <div className="p-grid">
                      {this.councilStatCard(
                        "Accepted",
                        this.props.applicationsAccepted,
                        "",
                        "colorbox-green",
                        "/overview_of_applications/Accepted"
                      )}
                      {this.councilStatCard(
                        "Seen/Read",
                        this.props.applicationsSeen,
                        "",
                        "colorbox-orange",
                        "/overview_of_applications/Seen"
                      )}
                      {this.councilStatCard(
                        "Withdrawn",
                        this.props.applicationsWithdrawn,
                        "",
                        "colorbox-black",
                        "/overview_of_applications/Withdrawn"
                      )}
                    </div>
                  </div>
                  {this.statCardSpace(12, 1, 0, 0)}
                </div>
              </div>
              <div className="p-col-12 card">
                <u>
                  <i>
                    <h2>Gap Analysis</h2>
                  </i>
                </u>
                <div className="p-grid">
                  {this.statCardSpace(12, 1, 0, 0)}
                  <div className="p-col-10">
                    <div className="p-grid">
                      {this.councilStatCard(
                        "Overall",
                        this.props.gapAnalysisOverall,
                        "file_download",
                        "colorbox-grey"
                      )}
                      {this.councilStatCard(
                        "Buyer",
                        this.props.gapAnalysisBuyer,
                        "file_download",
                        "colorbox-light-blue"
                      )}
                      {this.councilStatCard(
                        "Supplier",
                        this.props.gapAnalysisSupplier,
                        "file_download",
                        "colorbox-green"
                      )}
                    </div>
                  </div>
                  {this.statCardSpace(12, 1, 0, 0)}
                </div>
              </div>
            </div>
          </pr.TabPanel>
          <pr.TabPanel header="PPE">
            <div className="p-grid">
              <div className="p-col-12 card">
                <u>
                  <i>
                    <h2>Business</h2>
                  </i>
                </u>
                <div className="p-grid">
                  {this.statCardSpace(12, 1, 0, 0)}
                  <div className="p-col-10">
                    <div className="p-grid">
                      {this.councilStatCard(
                        "Successful",
                        this.props.ppeBusinessStats.businessSuccessful,
                        "",
                        "colorbox-green",
                        "/all_profiles/Approved"
                      )}
                      {this.councilStatCard(
                        "Pending",
                        this.props.ppeBusinessStats.businessPending,
                        "",
                        "colorbox-orange",
                        "/all_profiles/Pending"
                      )}
                      {this.councilStatCard(
                        "Unsuccessful",
                        this.props.ppeBusinessStats.businessUnsuccessful,
                        "",
                        "colorbox-red",
                        "/all_profiles/Unsuccessful"
                      )}
                    </div>
                  </div>
                  {this.statCardSpace(12, 2, 0, 0)}
                </div>
              </div>
              <div className="p-col-12 card">
                <u>
                  <i>
                    <h2>Applications</h2>
                  </i>
                </u>
                <div className="p-grid">
                  {this.statCardSpace(12, 1, 0, 0)}
                  <div className="p-col-10">
                    <div className="p-grid">
                      {this.councilStatCard(
                        "Submitted",
                        this.props.ppeApplicationStats.applicationsSubmitted,
                        "",
                        "colorbox-green",
                        "/overview_of_applications/Submitted"
                      )}

                      {this.councilStatCard(
                        "InProgress",
                        this.props.ppeApplicationStats.applicationsInProgress,
                        "",
                        "colorbox-orange",
                        "/overview_of_applications/InProgress"
                      )}
                      {this.councilStatCard(
                        "Unsuccessful",
                        this.props.ppeApplicationStats.applciationsUnsuccessful,
                        "",
                        "colorbox-red",
                        "/overview_of_applications/Unsuccessful"
                      )}
                    </div>
                  </div>

                  {this.statCardSpace(12, 1, 0, 0)}
                  {this.statCardSpace(12, 1, 0, 0)}
                  <div className="p-col-10">
                    <div className="p-grid">
                      {this.councilStatCard(
                        "Accepted",
                        this.props.ppeApplicationStats.applicationsAccepted,
                        "",
                        "colorbox-green",
                        "/overview_of_applications/Accepted"
                      )}
                      {this.councilStatCard(
                        "Seen/Read",
                        this.props.ppeApplicationStats.applicationsSeen,
                        "",
                        "colorbox-orange",
                        "/overview_of_applications/Seen"
                      )}
                      {this.councilStatCard(
                        "Withdrawn",
                        this.props.ppeApplicationStats.applicationsWithdrawn,
                        "",
                        "colorbox-black",
                        "/overview_of_applications/Withdrawn"
                      )}
                    </div>
                  </div>
                  {this.statCardSpace(12, 1, 0, 0)}
                </div>
              </div>
            </div>
          </pr.TabPanel>
        </pr.TabView>
      </React.Fragment>
    );
  };

  statCardSpace = (col, lg, md, sl) => {
    let classNameStr =
      "p-col-" + col + " p-sm-" + sl + " p-md-" + md + " p-lg-" + lg;
    return <div className={classNameStr}></div>;
  };

  councilStatCard = (title, figure, icon, colorbox, redirect) => {
    let classNameStr = "p-grid card colorbox " + colorbox;
    return (
      <div className="p-col-12 p-sm-12 p-md-6 p-lg-4">
        <div className={classNameStr}>
          {format.isNotNull(icon) ? (
            <>
              <div className="p-col-12">
                <span className="colorbox-name">{title}</span>
              </div>
              <div
                className="p-col-12"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (format.isNotNull(figure)) {
                    window.open(figure);
                  }
                }}
              >
                <i className="material-icons">{icon}</i>
              </div>
            </>
          ) : (
            <div
              className="p-col-12"
              style={format.isNotNull(redirect) ? { cursor: "pointer" } : {}}
              onClick={() => {
                if (format.isNotNull(redirect)) {
                  window.location = "#" + redirect;
                }
              }}
            >
              <span className="colorbox-name">{title}</span>
              <span className="colorbox-count">{figure}</span>
            </div>
          )}
        </div>
      </div>
    );
  };

  tutorialVideo = (title, source) => {
    return (
      <pr.Dialog
        header={title}
        footer={
          <pr.Button
            className="secondary-btn"
            label="Close"
            icon="pi pi-times"
            onClick={() => {
              this.props.updateState("showTutorial", false);
            }}
            style={{
              float: "right",
            }}
          />
        }
        visible={true}
        onHide={() => {
          this.props.updateState("showTutorial", false);
        }}
        modal={true}
        blockScroll={true}
        style={{ width: "80vw" }}
      >
        {/* <video width="100%" height="98%" controls>
          <source src={source} type="video/webm" />
          Your browser does not support video elements, please update to the
          latest version
        </video> */}
        <div>
          <center>
            <iframe
              src={source}
              allowFullScreen
              style={{
                position: "relative",
                top: 0,
                left: 0,
              }}
            ></iframe>
          </center>
        </div>
      </pr.Dialog>
    );
  };

  render() {
    // console.log(this.props.contactList);
    return (
      <div
        className="dashboard"
        style={
          this.props.loading === true
            ? { pointerEvents: "none" }
            : { padding: "1em" }
        }
      >
        {this.props.loading === true && <LoadScreen />}
        {this.props.loading !== true && (
          <React.Fragment>
            {this.props.viewType === ViewType.BUYER &&
              this.props.showTutorial &&
              this.tutorialVideo(
                "Dashboard Tutorial",
                // "/assets/layout/images/council/buyer-dashboard.webm"
                "https://player.vimeo.com/video/582850743?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              )}
            {this.props.viewType === ViewType.SUPPLIER &&
              this.props.showTutorial &&
              this.tutorialVideo(
                "Dashboard Tutorial",
                // "/assets/layout/images/council/supplier-dashboard.webm"
                "https://player.vimeo.com/video/582850457?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              )}
            {this.props.viewType === ViewType.BUYERSUPPLIER &&
              this.props.showTutorial &&
              this.tutorialVideo(
                "Dashboard Tutorial",
                // "/assets/layout/images/council/buyer-supplier-dashboard.webm"
                "https://player.vimeo.com/video/582850703?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              )}

            {this.props.viewType === ViewType.COUNCIL ? (
              this.displayCouncilView()
            ) : (
              <React.Fragment>
                <div
                  className="p-grid"
                  style={{ marginTop: "3px", width: "100%" }}
                >
                  <div className="p-col-12 p-sm-12 p-md-6 p-lg-3">
                    {this.props.viewType === ViewType.BUYER && (
                      <h1>{"Buyer Dashboard"}</h1>
                    )}
                    {this.props.viewType === ViewType.SUPPLIER && (
                      <h1>{"Supplier Dashboard"}</h1>
                    )}
                    {this.props.viewType === ViewType.BUYERSUPPLIER && (
                      <h1>{"Buyer & Supplier Dashboard"}</h1>
                    )}
                  </div>

                  {this.dashboardStatsCard(
                    "Enquiries",
                    this.props.applicationList.length,
                    "email",
                    "colorbox-1"
                  )}
                  {this.dashboardStatsCard(
                    "Applications Pending",
                    this.props.applicationsPending.length,
                    "info",
                    "colorbox-2"
                  )}
                  {this.dashboardStatsCard(
                    "Contacts made",
                    this.props.contactList.length,
                    "person",
                    "colorbox-3"
                  )}

                  <div className="p-col-12">
                    <pr.Button
                      icon="pi pi-info"
                      onClick={() => {
                        this.props.updateState("showTutorial", true);
                      }}
                      style={{ fontSize: "10px", float: "right" }}
                    />
                  </div>
                  <div className="p-col-12 p-md-3"></div>
                  <div className="p-col-12 p-md-6">
                    <Input
                      id={"globalFilter"}
                      labelDisplay={"Type here to search"}
                      labelDisplayPosition={LabelPosition.NONE}
                      required={false}
                      type={InputType.STRING}
                      disabled={false}
                      value={this.props.globalFilter}
                      onChangeType={UpdateType.CUSTOM}
                      onChange={(e) => {
                        if (this.props.productFilterListEmpty) {
                          this.props.updateState(
                            "productFilterListEmpty",
                            false
                          );
                        }
                        this.props.updateState("globalFilter", e.target.value);
                      }}
                      field={"globalFilter"}
                      visible={true}
                      style={{ backgroundColor: "#dae4f1" }}
                      onBlur={() => {
                        analytics.searchEvent(this.props.globalFilter);
                      }}
                    />
                  </div>
                </div>

                {this.props.viewType === ViewType.BUYER && (
                  <>
                    {this.product(BusinessType.SUPPLIER, true)}
                    {this.props.productFilterListEmpty && (
                      <div className="p-col-12">
                        <pr.Accordion>
                          <pr.AccordionTab header="Cannot find what your looking for? Here are some businesses releated to your desired categories">
                            {this.business(BusinessType.SUPPLIER, false)}
                          </pr.AccordionTab>
                        </pr.Accordion>
                      </div>
                    )}
                  </>
                )}

                {this.props.viewType === ViewType.SUPPLIER && (
                  <>
                    {this.product(BusinessType.BUYER, true)}
                    {/* {this.business(BusinessType.BUYER, true)} */}
                  </>
                )}

                {this.props.viewType === ViewType.BUYERSUPPLIER && (
                  <div className="p-grid" style={{ width: "100%" }}>
                    <div className="p-col-12">
                      <pr.TabView>
                        <pr.TabPanel header="Specific Products For You">
                          {this.product(BusinessType.SUPPLIER, true)}
                          {this.props.productFilterListEmpty && (
                            <div className="p-col-12">
                              <pr.Accordion>
                                <pr.AccordionTab header="Cannot find what your looking for? Maybe these businesses can supply to you">
                                  {this.business(BusinessType.SUPPLIER, false)}
                                </pr.AccordionTab>
                              </pr.Accordion>
                            </div>
                          )}
                        </pr.TabPanel>
                        <pr.TabPanel header="Specific Buyer Requirements">
                          {this.product(BusinessType.BUYER, true)}
                          {/* {this.business(BusinessType.BUYER, true)} */}
                        </pr.TabPanel>
                      </pr.TabView>
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}
