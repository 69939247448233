import React, { Component } from "react";
//PrimeReact Components
import * as pr from "../../common/PrimeComponents";
//Common Functions
import { resetPassword } from "../../common/helpers/auth";

export class Password extends Component {
  state = { email: "" };

  handleSubmitPassword = () => {
    this.growl.clear();
    resetPassword(this.state.email)
      .then(() => {
        this.growl.show({
          severity: "success",
          summary: <br />,
          detail: "Success: Password reset email sent",
          life: 3000,
        });
      })
      .catch((error) => {
        this.growl.show({
          severity: "error",
          summary: <br />,
          detail: error.toString(),
          life: 3000,
        });
      });
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.handleSubmitPassword();
    }
  };

  render() {
    const s = this.state;

    return (
      <div>
        <pr.Messages
          style={{ textAlign: "left" }}
          ref={(el) => (this.growl = el)}
        />
        <div className="p-grid" style={{ marginRight: 0 }}>
          <div className="login-panel card">
            <div className="p-grid">
              <div
                className="p-col-12"
                style={{ paddingTop: "25px !important" }}
              >
                <img
                  src="/assets/layout/images/council/logo.png"
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
              <div className="p-col-12" style={{ paddingBottom: "0" }}>
                <center>
                  <b className="custom-label">Reset Password</b>
                </center>
                <hr />
              </div>
              <div className="p-col-12">
                <span
                  className="md-inputfield form-field"
                  style={{ marginTop: "0px" }}
                >
                  <pr.InputText
                    value={s.email || ""}
                    onChange={(e) =>
                      this.setState({
                        email: e.target.value,
                      })
                    }
                    style={{ textTransform: "lowercase" }}
                  />
                  <label className="input-label">Email Address</label>
                </span>
              </div>
              <div className="p-col-12">
                <pr.Button
                  icon="pi pi-user-plus"
                  label="Reset Password"
                  onClick={() => {
                    this.handleSubmitPassword();
                  }}
                  style={{ width: "100%" }}
                />
              </div>
              <div className="p-col-12">
                <a href="#/login">Sign In?</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
