import React from "react";
import { ProfileController } from "./ProfileController";
import { ProfileViewModel } from "./ProfileViewModel";
import * as feedback from "../../common/Feedback/Feedback";
import * as log from "../../common/Feedback/Log";
import * as format from "../../common/FormatFunctions";

export class ProfileProvider extends React.Component {
  constructor(props) {
    super(props);
    this.viewModel = new ProfileViewModel();
    this.logger = log;
    this.feedback = feedback;
    if (format.isNotNull(props.match)) {
      this.params = props.match.params;
    }
  }

  render() {
    return (
      <ProfileController
        viewModel={this.viewModel}
        log={this.logger}
        feedback={this.feedback}
        toggleTopbar={this.props.toggleTopbar}
        // Additional Props
        sidebarWidth={this.props.sidebarWidth}
        // Add ViewType in src/common/config/AppConstants.js
        viewType={this.props.viewType}
        globalFilter={this.props.globalFilter}
        dashboardType={this.props.dashboardType}
        params={this.params}
        // dependant on viewType
        crudlState={this.props.crudlState}
        editedObjectFromProps={this.props.editedObjectFromProps}
        updateStateFromProps={this.props.updateStateFromProps}
        updateObjectFromProps={this.props.updateObjectFromProps}
      />
    );
  }
}
