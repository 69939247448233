import React from "react";
//PrimeReact Components
import * as pr from "../../../common/PrimeComponents";
import {
  CrudlStatus,
  ValidateState,
} from "../../../common/config/AppConstants";
import * as format from "../../../common/FormatFunctions";
import { SupplyDisplay } from "./SupplyDisplay";
import ReactHtmlParser from "react-html-parser";
import { MVVMValidateDialog } from "../../Common/MVVMValidateDialog";
import { FileViewer } from "../../Common/FileViewer";
import { ImageViewer } from "../../Common/ImageViewer";
export class SupplyTable extends React.Component {
  export = () => {
    this.dt.exportCSV();
  };

  getSupplyColumns = () => {
    console.log(this.props);
    var allColumns = [];
    // allColumns.push(
    //   <pr.Column
    //     key="expander"
    //     expander={true}
    //     style={{ width: "5em", float: "middle" }}
    //   />
    // );
    if (format.isNotNull(this.props.tableColumns)) {
      let columns = this.props.tableColumns.map((col, i) => {
        return (
          <pr.Column
            key={col.field}
            field={col.field}
            header={col.header}
            sortable={col.sortable}
            filter={col.filter}
            filterMatchMode={col.filterMatchMode}
            style={col.style}
          />
        );
      });

      columns.forEach((e) => {
        allColumns.push(e);
      });
    }
    return allColumns;
  };

  render() {
    return (
      <div className="p-grid bpm">
        <div className="p-col-12">
          <div className="content-section">
            <div className="feature-intro">
              <h1>{"My Supplies"}</h1>
              <p>{"A List of Products/Services I can supply"}</p>
            </div>
          </div>
        </div>
        <MVVMValidateDialog
          header="Delete Supply"
          isVisible={this.props.validateState === ValidateState.DELETE}
          visibleField="validateState"
          updateVisibility={this.props.updateState}
          message={" Are you sure you want to delete?"}
          type={"CRUDL"}
          crudlExecute={this.props.crudlExecute}
          crudlState={this.props.crudlState}
        />
        <pr.Sidebar
          className="productDetails"
          header={"Product Details"}
          style={{ width: "75%" }}
          position="left"
          visible={this.props.productDetailsView}
          onHide={() => {
            this.props.updateState("productDetailsView", false);
          }}
          maximizable
          blockScroll
        >
          <div className="p-grid">
            <div className="p-col-12"></div>

            <div className="p-col-12">
              {format.isNotNull(this.props.productDetails) && (
                <div className="p-col-12 card">
                  <div className="p-col-12">
                    {/*Empty div for spacing*/}
                    <label />
                  </div>
                  <div className="p-col-12">
                    <div className="p-grid">
                      <div className="p-col-6">
                        <b>Category: </b>
                        <label>
                          {this.props.productDetails.componentNameToDisplay ||
                            "Not Set"}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="p-col-12">
                    <div className="p-grid">
                      <div className="p-col-6">
                        <b> Name: </b>
                        <label>
                          {this.props.productDetails.name || "Not Set"}
                        </label>
                      </div>
                      <div className="p-col-6">
                        <b>Supplier Name: </b>
                        <label>
                          {this.props.productDetails.businessName || "Not Set"}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="p-col-12">
              <pr.TabView>
                <pr.TabPanel header="Details">
                  <div className="p-grid card">
                    {format.isNotNull(this.props.productDetails) && (
                      <div className="p-col-10">
                        {ReactHtmlParser(
                          this.props.productDetails.longDescriptionText
                        )}
                      </div>
                    )}
                  </div>
                </pr.TabPanel>
                <pr.TabPanel
                  header="Images"
                  headerStyle={
                    format.isNotNull(this.props.productDetails) &&
                    this.props.productDetails.imageUri
                      ? {}
                      : { display: "none" }
                  }
                >
                  <div className="p-grid card">
                    {format.isNotNull(this.props.productDetails) && (
                      <div className="p-col-12">
                        <ImageViewer
                          fileList={this.props.productDetails.imageUri}
                          readOnly={true}
                        />
                      </div>
                    )}
                  </div>
                </pr.TabPanel>
                <pr.TabPanel
                  header="Files"
                  headerStyle={
                    format.isNotNull(this.props.productDetails) &&
                    this.props.productDetails.pdfUrls
                      ? {}
                      : { display: "none" }
                  }
                >
                  <div className="p-grid card">
                    {format.isNotNull(this.props.productDetails) && (
                      <div className="p-col-12">
                        <FileViewer
                          fileList={this.props.productDetails.pdfUrls}
                          readOnly={true}
                        />
                      </div>
                    )}
                  </div>
                </pr.TabPanel>
              </pr.TabView>

              <div className="p-col-12">
                <pr.Button
                  label="Close"
                  onClick={() => {
                    this.props.updateState("productDetailsView", false);
                  }}
                  style={{ float: "right" }}
                />
              </div>
            </div>
          </div>
        </pr.Sidebar>
        {/* <div className="p-col-6">
          <pr.Button
            label={"Add New"}
            style={{
              float: "right",
            }}
            onClick={() => {
              this.props.crudlControl(CrudlStatus.CREATE);
            }}
          />
        </div> */}

        <div className="p-col-12">
          <pr.DataTable
            ref={(el) => {
              this.dt = el;
            }}
            value={this.props.supplyList}
            selectionMode="single"
            onSelectionChange={(e) => {}}
            emptyMessage="No Product/Services"
            expandedRows={this.props.expandedRows}
            onRowToggle={(e) => this.props.updateState("expandedRows", e.data)}
            rowExpansionSupply={this.getDisplayCard}
            rows={Infinity}
            autoLayout={true}
            paginator={false}
            alwaysShowPaginator={false}
            responsive={true}
            loading={this.props.loading}
            scrollable={true}
            scrollHeight="30vh"
          >
            <pr.Column
              key={"name"}
              field={"name"}
              header={"Name"}
              sortable={true}
              filter={true}
              filterMatchMode={"contains"}
              style={{ overflow: "auto" }}
            ></pr.Column>
            <pr.Column
              key={"type"}
              field={"componentNameToDisplay"}
              header={"Type"}
              sortable={true}
              filter={true}
              filterMatchMode={"contains"}
              style={{ overflow: "auto" }}
            ></pr.Column>
            <pr.Column
              header="Product/Service Details"
              body={this.moreDetails}
            ></pr.Column>
            <pr.Column
              key={"shortDescription"}
              field={"shortDescription"}
              header={"Link To E-Commerce"}
              body={this.getLinkToWebsite}
            ></pr.Column>
            <pr.Column
              key={"location"}
              field={"location"}
              header={"Location"}
              sortable={true}
              filter={true}
              filterMatchMode={"contains"}
              style={{ overflow: "auto" }}
            ></pr.Column>
            <pr.Column
              key={"productStatus"}
              field={"productStatus"}
              header={"Status"}
              sortable={true}
              filter={true}
              filterMatchMode={"contains"}
              style={{ overflow: "auto" }}
            ></pr.Column>
            <pr.Column body={this.getEditDeleteTemplate}></pr.Column>
          </pr.DataTable>
        </div>
      </div>
    );
  }
  getLinkToWebsite = (rowData) => {
    if (format.isNotNull(rowData.shortDescription)) {
      if (rowData.shortDescription.includes("http")) {
        return (
          <a
            style={{ display: "table-cell" }}
            href={rowData.shortDescription}
            target="_blank"
            rel="noreferrer"
          >
            Buy Online
          </a>
        );
      } else {
        return <label>Invalid Link</label>;
      }
    }

    return <label></label>;
  };
  moreDetails = (rowData, column) => {
    return (
      <div className="p-grid">
        <div className="p-col-12">
          <pr.Button
            type="button"
            label={"View Details"}
            onClick={(e) => {
              this.props.updateState("productDetails", rowData);
              this.props.updateState("productDetailsView", true);
            }}
            style={{}}
          ></pr.Button>
        </div>
      </div>
    );
  };
  getEditDeleteTemplate = (rowData, column) => {
    return (
      <div className="p-grid">
        <div className="p-col-12">
          <pr.Button
            type="button"
            icon="pi pi-pencil"
            onClick={(e) =>
              this.props.crudlControl(CrudlStatus.UPDATE, rowData)
            }
            style={{}}
          ></pr.Button>
          <pr.Button
            type="button"
            icon="pi pi-trash"
            onClick={(e) => {
              this.props.crudlControl(CrudlStatus.DELETE, rowData);
            }}
            style={{ backgroundColor: "red" }}
          ></pr.Button>
        </div>
      </div>
    );
  };
  getDisplayCard = (data) => {
    return <SupplyDisplay data={data} onClick={this.props.crudlControl} />;
  };
}
