import React, { Component } from "react";
import * as pr from "../../common/PrimeComponents";
import * as Log from "../../common/Feedback/Log";
import { SingleFileUploader } from "./SingleFileUploader";

export const InputType = {
  STRING: "STRING",
  TEXT: "TEXT",
  NOTES: "NOTES",
  NUMBER: "NUMBER",
  DROPDOWN: "DROPDOWN",
  DROPDOWN_MULTISELECT: "DROPDOWN_MULTISELECT",
  DATE: "DATE",
  SINGLE_FILE: "SINGLE_FILE",
  CHECKBOX: "CHECKBOX",
};

export const LabelPosition = {
  TOP: "TOP",
  LEFT: "LEFT",
  NONE: "NONE",
};
export const UpdateType = {
  STANDARD: "STANDARD",
  CUSTOM: "CUSTOM",
};

export class Input extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: null,
      errors: [],
    };

    this.logger = Log;
  }

  saveValue = (e) => {
    var valueToSave = null;
    if (this.props.type === InputType.STRING) {
      valueToSave = e.target.value;
    }
    if (this.props.type === InputType.TEXT) {
      valueToSave = e.target.value;
    }
    if (this.props.type === InputType.NOTES) {
      valueToSave = e.htmlValue;
    }
    if (this.props.type === InputType.NUMBER) {
      valueToSave = e.target.value;
    }
    if (this.props.type === InputType.DROPDOWN) {
      valueToSave = e.value;
    }
    if (this.props.type === InputType.DROPDOWN_MULTISELECT) {
      valueToSave = e.value;
    }
    if (this.props.type === InputType.DATE) {
      valueToSave = e.value;
    }
    if (this.props.type === InputType.SINGLE_FILE) {
      valueToSave = e;
    }
    if (this.props.type === InputType.CHECKBOX) {
      valueToSave = e.checked;
    }
    if (this.props.onChangeType !== UpdateType.CUSTOM) {
      console.log("Standard UpdateValue");
      if (!this.props.disabled) {
        this.props.onChange(this.props.field, valueToSave);
      }
    } else {
      //What to do here?
      if (!this.props.disabled) {
        this.props.onChange(e);
      }
    }
  };
  displayLabel = () => {
    if (this.props.disabled) {
      return (
        <label
          className={
            this.props.className ? this.props.className : "input-label"
          }
        >
          {this.props.value || ""}
        </label>
      );
    }
  };

  render() {
    if (this.props.visible === false) {
      return <></>;
    }

    if (this.props.type === InputType.STRING) {
      return this.getStringInput();
    }
    if (this.props.type === InputType.TEXT) {
      return this.getTextInput();
    }
    if (this.props.type === InputType.NOTES) {
      return this.getNotesInput();
    }
    if (this.props.type === InputType.NUMBER) {
      return this.getNumberInput();
    }
    if (this.props.type === InputType.DROPDOWN) {
      return this.getDropdownInput();
    }
    if (this.props.type === InputType.DROPDOWN_MULTISELECT) {
      return this.getDropdownMultipleSelect();
    }
    if (this.props.type === InputType.DATE) {
      return this.getDateInput();
    }
    if (this.props.type === InputType.SINGLE_FILE) {
      return this.getSingleFileUpload();
    }
    if (this.props.type === InputType.CHECKBOX) {
      return this.getCheckbox();
    }

    return <></>;
  }

  getDisabledView = () => {
    if (this.props.disabledViewFormat) {
      return (
        <label
          className={
            this.props.className ? this.props.className : "input-label"
          }
        >
          {this.props.disabledViewFormat(this.props.value) || "ERROR"}
        </label>
      );
    } else {
      return (
        <label
          className={
            this.props.className ? this.props.className : "input-label"
          }
        >
          {this.props.value || ""}
        </label>
      );
    }
  };
  getStringInput = () => {
    var labelCol = 3;
    var elementCol = 9;
    if (this.props.toolTip) {
      elementCol = 8;
    }

    if (this.props.displayOptions) {
      labelCol = this.props.displayOptions.label;
      elementCol = this.props.displayOptions.element;
    }
    return (
      <div className="p-grid">
        {this.props.labelDisplayPosition === LabelPosition.TOP && (
          <>
            <div className="p-col-12">
              <label
                htmlFor={this.props.id}
                className={
                  this.props.className ? this.props.className : "input-label"
                }
              >
                <b>
                  {this.props.labelDisplay + (this.props.required ? "*" : "")}
                </b>
              </label>
              {this.props.disabled && this.getDisabledView()}
              {!this.props.disabled && (
                <pr.InputText
                  id={this.props.id}
                  className={this.props.value ? "" : "p-invalid"}
                  placeholder={
                    this.props.placeholder
                      ? this.props.placeholder
                      : this.props.labelDisplay
                  }
                  required={this.props.required}
                  value={this.props.value || ""}
                  onChange={this.saveValue}
                  disabled={this.props.disabled}
                  onFocus={this.props.onFocus}
                  style={
                    this.props.style ? this.props.style : { width: "100%" }
                  }
                />
              )}
            </div>
            {this.props.toolTip && (
              <div className={"p-col-1"}>
                <pr.Button
                  label="i"
                  tooltip={this.props.toolTipLabel}
                  tooltipOptions={{
                    position: "left",
                  }}
                  style={{ borderRadius: "25px" }}
                />
              </div>
            )}
          </>
        )}
        {this.props.labelDisplayPosition === LabelPosition.NONE && (
          <>
            <div className="p-col-12">
              {/* {this.props.disabled && this.getDisabledView()} */}
              {/* {!this.props.disabled && ( */}
              <pr.InputText
                id={this.props.id}
                className={this.props.value ? "" : "p-invalid"}
                placeholder={
                  this.props.placeholder
                    ? this.props.placeholder
                    : this.props.labelDisplay
                }
                required={this.props.required}
                value={this.props.value || ""}
                onChange={this.saveValue}
                disabled={this.props.disabled}
                onFocus={this.props.onFocus}
                style={this.props.style ? this.props.style : { width: "100%" }}
                onBlur={this.props.onBlur}
              />
              {/* )} */}
            </div>
            {this.props.toolTip && (
              <div className={"p-col-1"}>
                <pr.Button
                  label="i"
                  tooltip={this.props.toolTipLabel}
                  tooltipOptions={{
                    position: "left",
                  }}
                  style={{ borderRadius: "25px" }}
                />
              </div>
            )}
          </>
        )}
        {this.props.labelDisplayPosition === LabelPosition.LEFT && (
          <>
            <div
              className={
                "p-col-12 p-lg-" + labelCol + " p-md-" + labelCol + " p-sm-12"
              }
            >
              <label
                className={
                  this.props.className ? this.props.className : "input-label"
                }
              >
                {this.props.labelDisplay +
                  (this.props.required && !this.props.disabled ? "*" : "")}
              </label>
            </div>
            <div
              className={
                "p-col-12 p-lg-" +
                elementCol +
                " p-md-" +
                elementCol +
                " p-sm-11"
              }
            >
              {/* {this.props.disabled && this.getDisabledView()} */}
              {/* {!this.props.disabled && ( */}
              <pr.InputText
                id={this.props.id}
                className={this.props.value ? "" : "p-invalid"}
                placeholder={
                  this.props.placeholder
                    ? this.props.placeholder
                    : this.props.labelDisplay
                }
                required={this.props.required}
                value={this.props.value || ""}
                onChange={this.saveValue}
                disabled={this.props.disabled}
                onFocus={this.props.onFocus}
                style={this.props.style ? this.props.style : { width: "100%" }}
              />
              {/* )} */}
            </div>

            {this.props.toolTip && (
              <div className={"p-col-1"}>
                <pr.Button
                  label="i"
                  tooltip={this.props.toolTipLabel}
                  tooltipOptions={{
                    position: "left",
                  }}
                  style={{ borderRadius: "25px" }}
                />
              </div>
            )}
          </>
        )}
      </div>
    );
  };
  handleKeyPress = (event) => {
    console.log(event.key);
  };
  getTextInput = () => {
    var labelCol = 3;
    var elementCol = 9;

    if (this.props.displayOptions) {
      labelCol = this.props.displayOptions.label;
      elementCol = this.props.displayOptions.element;
    }
    return (
      <div className="p-grid">
        {this.props.labelDisplayPosition === LabelPosition.TOP && (
          <div className="p-col-12">
            <label
              htmlFor={this.props.id}
              className={
                this.props.className ? this.props.className : "input-label"
              }
            >
              <b>
                {this.props.labelDisplay + (this.props.required ? "*" : "")}
              </b>
            </label>
            <textarea
              id={this.props.id}
              className={this.props.value ? "" : "p-invalid"}
              placeholder={
                this.props.placeholder
                  ? this.props.placeholder
                  : this.props.labelDisplay
              }
              rows={5}
              required={this.props.required}
              value={this.props.value || ""}
              onChange={this.saveValue}
              disabled={this.props.disabled}
              onFocus={this.props.onFocus}
              style={this.props.style ? this.props.style : { width: "100%" }}
              maxLength={this.props.maxCharacterLength}
            />
          </div>
        )}
        {this.props.labelDisplayPosition === LabelPosition.NONE && (
          <div className="p-col-12">
            <textarea
              id={this.props.id}
              className={this.props.value ? "" : "p-invalid"}
              placeholder={
                this.props.placeholder
                  ? this.props.placeholder
                  : this.props.labelDisplay
              }
              rows={5}
              required={this.props.required}
              value={this.props.value || ""}
              onChange={this.saveValue}
              disabled={this.props.disabled}
              onFocus={this.props.onFocus}
              style={this.props.style ? this.props.style : { width: "100%" }}
              maxLength={this.props.maxCharacterLength}
            />
          </div>
        )}
        {this.props.labelDisplayPosition === LabelPosition.LEFT && (
          <>
            <div
              className={
                "p-col-12 p-lg-" + labelCol + " p-md-" + labelCol + " p-sm-12"
              }
            >
              <label
                className={
                  this.props.className ? this.props.className : "input-label"
                }
              >
                <b>
                  {this.props.labelDisplay +
                    (this.props.required && !this.props.disabled ? "*" : "")}
                </b>
              </label>
            </div>
            <div
              className={
                "p-col-12 p-lg-" +
                elementCol +
                " p-md-" +
                elementCol +
                " p-sm-12"
              }
            >
              <textarea
                id={this.props.id}
                className={this.props.value ? "" : "p-invalid"}
                placeholder={
                  this.props.placeholder
                    ? this.props.placeholder
                    : this.props.labelDisplay
                }
                rows={5}
                required={this.props.required}
                value={this.props.value || ""}
                onChange={this.saveValue}
                disabled={this.props.disabled}
                onFocus={this.props.onFocus}
                style={this.props.style ? this.props.style : { width: "100%" }}
                maxLength={this.props.maxCharacterLength}
              />
            </div>
          </>
        )}
      </div>
    );
  };
  getNotesInput = () => {
    var labelCol = 3;
    var elementCol = 9;

    if (this.props.displayOptions) {
      labelCol = this.props.displayOptions.label;
      elementCol = this.props.displayOptions.element;
    }
    const header = (
      <span className="ql-formats">
        <button className="ql-bold" aria-label="Bold"></button>
        <button className="ql-italic" aria-label="Italic"></button>
        <button className="ql-underline" aria-label="Underline"></button>
        <button
          className="ql-list"
          value="ordered"
          aria-label="NumberedList"
        ></button>
        <button
          className="ql-list"
          value="bullet"
          aria-label="BulletPoints"
        ></button>
        <button className="ql-link" aria-label="Link"></button>
      </span>
    );

    return (
      <div className="p-grid">
        {this.props.labelDisplayPosition === LabelPosition.TOP && (
          <div className="p-col-12">
            <label
              htmlFor={this.props.id}
              className={
                this.props.className ? this.props.className : "input-label"
              }
            >
              <b>
                {this.props.labelDisplay + (this.props.required ? "*" : "")}
              </b>
            </label>
            <pr.Editor
              id={this.props.id}
              className={this.props.value ? "" : "ui-state-error"}
              required={this.props.required}
              value={this.props.value || ""}
              onTextChange={this.saveValue}
              readOnly={this.props.disabled}
              style={this.props.style ? this.props.style : { height: "50vh" }}
              headerTemplate={
                this.props.headerTemplate ? this.props.headerTemplate : header
              }
            />
          </div>
        )}
        {this.props.labelDisplayPosition === LabelPosition.NONE && (
          <div className="p-col-12">
            <pr.Editor
              id={this.props.id}
              className={this.props.value ? "" : "ui-state-error"}
              required={this.props.required}
              value={this.props.value || ""}
              onTextChange={this.saveValue}
              readOnly={this.props.disabled}
              style={this.props.style ? this.props.style : { height: "50vh" }}
              headerTemplate={
                this.props.headerTemplate ? this.props.headerTemplate : header
              }
            />
          </div>
        )}
        {this.props.labelDisplayPosition === LabelPosition.LEFT && (
          <>
            <div
              className={
                "p-col-12 p-lg-" + labelCol + " p-md-" + labelCol + " p-sm-12"
              }
            >
              <label
                className={
                  this.props.className ? this.props.className : "input-label"
                }
              >
                <b>
                  {this.props.labelDisplay +
                    (this.props.required && !this.props.disabled ? "*" : "")}
                </b>
              </label>
            </div>
            <div
              className={
                "p-col-12 p-lg-" +
                elementCol +
                " p-md-" +
                elementCol +
                " p-sm-12"
              }
            >
              <pr.Editor
                id={this.props.id}
                className={this.props.value ? "" : "ui-state-error"}
                required={this.props.required}
                value={this.props.value || ""}
                onTextChange={this.saveValue}
                readOnly={this.props.disabled}
                style={this.props.style ? this.props.style : { height: "50vh" }}
                headerTemplate={
                  this.props.headerTemplate ? this.props.headerTemplate : header
                }
              />
            </div>
          </>
        )}
      </div>
    );
  };
  getNumberInput = () => {
    var labelCol = 3;
    var elementCol = 9;

    if (this.props.displayOptions) {
      labelCol = this.props.displayOptions.label;
      elementCol = this.props.displayOptions.element;
    }
    return (
      <div className="p-grid">
        {this.props.labelDisplayPosition === LabelPosition.TOP && (
          <div className="p-col-12">
            <label
              htmlFor={this.props.id}
              className={
                this.props.className ? this.props.className : "input-label"
              }
            >
              <b>
                {this.props.labelDisplay + (this.props.required ? "*" : "")}
              </b>
            </label>
            {this.props.disabled && this.getDisabledView()}
            {!this.props.disabled && (
              <pr.InputNumber
                id={this.props.id}
                className={this.props.value ? "" : "p-invalid"}
                placeholder={
                  this.props.placeholder
                    ? this.props.placeholder
                    : this.props.labelDisplay
                }
                required={this.props.required}
                value={this.props.value || ""}
                onValueChange={this.saveValue}
                disabled={this.props.disabled}
                onFocus={this.props.onFocus}
                style={this.props.style ? this.props.style : { width: "100%" }}
                mode={this.props.numberOptions.mode || "integer"}
                minFractionDigits={
                  this.props.numberOptions.minFractionDigitis || 2
                }
                maxFractionDigits={
                  this.props.numberOptions.minFractionDigitis || 2
                }
              />
            )}
          </div>
        )}
        {this.props.labelDisplayPosition === LabelPosition.NONE && (
          <div className="p-col-12">
            {this.props.disabled && this.getDisabledView()}
            {!this.props.disabled && (
              <pr.InputNumber
                id={this.props.id}
                className={this.props.value ? "" : "p-invalid"}
                placeholder={
                  this.props.placeholder
                    ? this.props.placeholder
                    : this.props.labelDisplay
                }
                required={this.props.required}
                value={this.props.value || ""}
                onValueChange={this.saveValue}
                disabled={this.props.disabled}
                onFocus={this.props.onFocus}
                style={this.props.style ? this.props.style : { width: "100%" }}
                mode={this.props.numberOptions.mode || "integer"}
                minFractionDigits={
                  this.props.numberOptions.minFractionDigitis || 2
                }
                maxFractionDigits={
                  this.props.numberOptions.minFractionDigitis || 2
                }
              />
            )}
          </div>
        )}
        {this.props.labelDisplayPosition === LabelPosition.LEFT && (
          <>
            <div
              className={
                "p-col-12 p-lg-" + labelCol + " p-md-" + labelCol + " p-sm-12"
              }
            >
              <label
                className={
                  this.props.className ? this.props.className : "input-label"
                }
              >
                <b>
                  {this.props.labelDisplay +
                    (this.props.required && !this.props.disabled ? "*" : "")}
                </b>
              </label>
            </div>
            <div
              className={
                "p-col-12 p-lg-" +
                elementCol +
                " p-md-" +
                elementCol +
                " p-sm-12"
              }
            >
              {this.props.disabled && this.getDisabledView()}
              {!this.props.disabled && (
                <pr.InputNumber
                  id={this.props.id}
                  className={this.props.value ? "" : "p-invalid"}
                  placeholder={
                    this.props.placeholder
                      ? this.props.placeholder
                      : this.props.labelDisplay
                  }
                  required={this.props.required}
                  value={this.props.value || ""}
                  onValueChange={this.saveValue}
                  disabled={this.props.disabled}
                  onFocus={this.props.onFocus}
                  style={
                    this.props.style ? this.props.style : { width: "100%" }
                  }
                  mode={this.props.numberOptions.mode || "integer"}
                  minFractionDigits={
                    this.props.numberOptions.minFractionDigitis || 2
                  }
                  maxFractionDigits={
                    this.props.numberOptions.minFractionDigitis || 2
                  }
                />
              )}
            </div>
          </>
        )}
      </div>
    );
  };
  getDropdownInput = () => {
    var labelCol = 3;
    var elementCol = 9;
    if (this.props.toolTip) {
      elementCol = 8;
    }

    if (this.props.displayOptions) {
      labelCol = this.props.displayOptions.label;
      elementCol = this.props.displayOptions.element;
    }
    return (
      <div className="p-grid">
        {this.props.labelDisplayPosition === LabelPosition.TOP && (
          <>
            <div className="p-col-12">
              <label
                htmlFor={this.props.id}
                className={
                  this.props.className ? this.props.className : "input-label"
                }
              >
                <b>
                  {this.props.labelDisplay + (this.props.required ? "*" : "")}
                </b>
              </label>
              {this.props.disabled && this.getDisabledView()}
              {!this.props.disabled && (
                <pr.Dropdown
                  id={this.props.id}
                  className={this.props.value ? "" : "p-invalid"}
                  placeholder={
                    this.props.placeholder
                      ? this.props.placeholder
                      : this.props.labelDisplay
                  }
                  required={this.props.required}
                  options={this.props.dropdownOptions}
                  value={this.props.value || ""}
                  onChange={this.saveValue}
                  disabled={this.props.disabled}
                  onFocus={this.props.onFocus}
                  filter={true}
                  filterBy="label"
                  filterPlaceholder="Search"
                  style={
                    this.props.style ? this.props.style : { width: "100%" }
                  }
                />
              )}
            </div>
            {this.props.toolTip && (
              <div className={"p-col-1"}>
                <pr.Button
                  label="i"
                  tooltip={this.props.toolTipLabel}
                  tooltipOptions={{
                    position: "left",
                  }}
                  style={{ borderRadius: "25px" }}
                />
              </div>
            )}
          </>
        )}
        {this.props.labelDisplayPosition === LabelPosition.NONE && (
          <>
            <div className="p-col-12">
              {this.props.disabled && this.getDisabledView()}
              {!this.props.disabled && (
                <pr.Dropdown
                  id={this.props.id}
                  className={this.props.value ? "" : "p-invalid"}
                  placeholder={
                    this.props.placeholder
                      ? this.props.placeholder
                      : this.props.labelDisplay
                  }
                  required={this.props.required}
                  options={this.props.dropdownOptions}
                  value={this.props.value || ""}
                  onChange={this.saveValue}
                  disabled={this.props.disabled}
                  onFocus={this.props.onFocus}
                  filter={true}
                  filterBy="label"
                  filterPlaceholder="Search"
                  style={
                    this.props.style ? this.props.style : { width: "100%" }
                  }
                />
              )}
            </div>
            {this.props.toolTip && (
              <div className={"p-col-1"}>
                <pr.Button
                  label="i"
                  tooltip={this.props.toolTipLabel}
                  tooltipOptions={{
                    position: "left",
                  }}
                  style={{ borderRadius: "25px" }}
                />
              </div>
            )}
          </>
        )}
        {this.props.labelDisplayPosition === LabelPosition.LEFT && (
          <>
            <div
              className={
                "p-col-12 p-lg-" + labelCol + " p-md-" + labelCol + " p-sm-12"
              }
            >
              <label
                className={
                  this.props.className ? this.props.className : "input-label"
                }
              >
                <b>
                  {this.props.labelDisplay +
                    (this.props.required && !this.props.disabled ? "*" : "")}
                </b>
              </label>
            </div>
            <div
              className={
                "p-col-12 p-lg-" +
                elementCol +
                " p-md-" +
                elementCol +
                " p-sm-12"
              }
            >
              {this.props.disabled && this.getDisabledView()}
              {!this.props.disabled && (
                <pr.Dropdown
                  id={this.props.id}
                  className={this.props.value ? "" : "p-invalid"}
                  placeholder={
                    this.props.placeholder
                      ? this.props.placeholder
                      : this.props.labelDisplay
                  }
                  required={this.props.required}
                  options={this.props.dropdownOptions}
                  value={this.props.value || ""}
                  onChange={this.saveValue}
                  disabled={this.props.disabled}
                  onFocus={this.props.onFocus}
                  filter={true}
                  filterBy="label"
                  filterPlaceholder="Search"
                  style={
                    this.props.style ? this.props.style : { width: "100%" }
                  }
                />
              )}
            </div>
            {this.props.toolTip && (
              <div className={"p-col-1"}>
                <pr.Button
                  label="i"
                  tooltip={this.props.toolTipLabel}
                  tooltipOptions={{
                    position: "left",
                  }}
                  style={{ borderRadius: "25px" }}
                />
              </div>
            )}
          </>
        )}
      </div>
    );
  };
  getDropdownMultipleSelect = () => {
    var labelCol = 3;
    var elementCol = 9;
    if (this.props.toolTip) {
      elementCol = 8;
    }

    if (this.props.displayOptions) {
      labelCol = this.props.displayOptions.label;
      elementCol = this.props.displayOptions.element;
    }
    return (
      <div className="p-grid">
        {this.props.labelDisplayPosition === LabelPosition.TOP && (
          <>
            <div className="p-col-12">
              <label
                htmlFor={this.props.id}
                className={
                  this.props.className ? this.props.className : "input-label"
                }
              >
                <b>
                  {this.props.labelDisplay + (this.props.required ? "*" : "")}
                </b>
              </label>
              {this.props.disabled && this.getDisabledView()}
              {!this.props.disabled && (
                <pr.MultiSelect
                  id={this.props.id}
                  className={this.props.value ? "" : "p-invalid"}
                  placeholder={
                    this.props.placeholder
                      ? this.props.placeholder
                      : this.props.labelDisplay
                  }
                  required={this.props.required}
                  options={this.props.dropdownOptions}
                  value={this.props.value || ""}
                  onChange={this.saveValue}
                  // disabled={this.props.disabled}
                  onFocus={this.props.onFocus}
                  filter={true}
                  filterBy="label"
                  filterPlaceholder="Search"
                  style={
                    this.props.style ? this.props.style : { width: "100%" }
                  }
                  panelHeaderTemplate={this.props.panelHeaderTemplate}
                  panelFooterTemplate={this.props.panelFooterTemplate}
                  selectedItemTemplate={this.props.selectedItemTemplate}
                />
              )}
            </div>
            {this.props.toolTip && (
              <div className={"p-col-1"}>
                <pr.Button
                  label="i"
                  tooltip={this.props.toolTipLabel}
                  tooltipOptions={{
                    position: "left",
                  }}
                  style={{ borderRadius: "25px" }}
                />
              </div>
            )}
          </>
        )}
        {this.props.labelDisplayPosition === LabelPosition.NONE && (
          <>
            <div className="p-col-12">
              {this.props.disabled && (
                <label
                  className={
                    this.props.className ? this.props.className : "input-label"
                  }
                >
                  {this.props.value || ""}
                </label>
              )}
              {!this.props.disabled && (
                <pr.MultiSelect
                  id={this.props.id}
                  className={this.props.value ? "" : "p-invalid"}
                  placeholder={
                    this.props.placeholder
                      ? this.props.placeholder
                      : this.props.labelDisplay
                  }
                  required={this.props.required}
                  options={this.props.dropdownOptions}
                  value={this.props.value || ""}
                  onChange={this.saveValue}
                  disabled={this.props.disabled}
                  onFocus={this.props.onFocus}
                  filter={true}
                  filterBy="label"
                  filterPlaceholder="Search"
                  style={
                    this.props.style ? this.props.style : { width: "100%" }
                  }
                  panelHeaderTemplate={this.props.panelHeaderTemplate}
                  panelFooterTemplate={this.props.panelFooterTemplate}
                  selectedItemTemplate={this.props.selectedItemTemplate}
                />
              )}
            </div>
            {this.props.toolTip && (
              <div className={"p-col-1"}>
                <pr.Button
                  label="i"
                  tooltip={this.props.toolTipLabel}
                  tooltipOptions={{
                    position: "left",
                  }}
                  style={{ borderRadius: "25px" }}
                />
              </div>
            )}
          </>
        )}
        {this.props.labelDisplayPosition === LabelPosition.LEFT && (
          <>
            <div
              className={
                "p-col-12 p-lg-" + labelCol + " p-md-" + labelCol + " p-sm-12"
              }
            >
              <label
                className={
                  this.props.className ? this.props.className : "input-label"
                }
              >
                <b>
                  {this.props.labelDisplay +
                    (this.props.required && !this.props.disabled ? "*" : "")}
                </b>
              </label>
            </div>
            <div
              className={
                "p-col-12 p-lg-" +
                elementCol +
                " p-md-" +
                elementCol +
                " p-sm-12"
              }
            >
              {this.props.disabled && this.getDisabledView()}
              {!this.props.disabled && (
                <pr.MultiSelect
                  id={this.props.id}
                  className={this.props.value ? "" : "p-invalid"}
                  placeholder={
                    this.props.placeholder
                      ? this.props.placeholder
                      : this.props.labelDisplay
                  }
                  required={this.props.required}
                  options={this.props.dropdownOptions}
                  value={this.props.value || ""}
                  onChange={this.saveValue}
                  disabled={this.props.disabled}
                  onFocus={this.props.onFocus}
                  filter={true}
                  filterBy="label"
                  filterPlaceholder="Search"
                  style={
                    this.props.style ? this.props.style : { width: "100%" }
                  }
                  panelHeaderTemplate={this.props.panelHeaderTemplate}
                  panelFooterTemplate={this.props.panelFooterTemplate}
                  selectedItemTemplate={this.props.selectedItemTemplate}
                />
              )}
            </div>
            {this.props.toolTip && (
              <div className={"p-col-1"}>
                <pr.Button
                  label="i"
                  tooltip={this.props.toolTipLabel}
                  tooltipOptions={{
                    position: "left",
                  }}
                  style={{ borderRadius: "25px" }}
                />
              </div>
            )}
          </>
        )}
      </div>
    );
  };
  getDateInput = () => {
    var labelCol = 3;
    var elementCol = 9;

    if (this.props.displayOptions) {
      labelCol = this.props.displayOptions.label;
      elementCol = this.props.displayOptions.element;
    }
    return (
      <div className="p-grid">
        {this.props.labelDisplayPosition === LabelPosition.TOP && (
          <div className="p-col-12">
            <label
              htmlFor={this.props.id}
              className={
                this.props.className ? this.props.className : "input-label"
              }
            >
              <b>
                {this.props.labelDisplay + (this.props.required ? "*" : "")}
              </b>
            </label>
            {this.props.disabled && this.getDisabledView()}
            {!this.props.disabled && (
              <pr.Calendar
                id={this.props.id}
                showIcon={false}
                className={this.props.value ? "" : "p-invalid"}
                placeholder={
                  this.props.placeholder
                    ? this.props.placeholder
                    : this.props.labelDisplay
                }
                required={this.props.required}
                value={this.props.value || ""}
                onChange={this.saveValue}
                disabled={this.props.disabled}
                onFocus={this.props.onFocus}
                style={this.props.style ? this.props.style : { width: "100%" }}
              />
            )}
          </div>
        )}
        {this.props.labelDisplayPosition === LabelPosition.NONE && (
          <div className="p-col-12">
            {this.props.disabled && (
              <label
                className={
                  this.props.className ? this.props.className : "input-label"
                }
              >
                {this.props.value || ""}
              </label>
            )}
            {!this.props.disabled && (
              <pr.Calendar
                id={this.props.id}
                showIcon={false}
                className={this.props.value ? "" : "p-invalid"}
                placeholder={
                  this.props.placeholder
                    ? this.props.placeholder
                    : this.props.labelDisplay
                }
                required={this.props.required}
                value={this.props.value || ""}
                onChange={this.saveValue}
                disabled={this.props.disabled}
                onFocus={this.props.onFocus}
                style={this.props.style ? this.props.style : { width: "100%" }}
              />
            )}
          </div>
        )}
        {this.props.labelDisplayPosition === LabelPosition.LEFT && (
          <>
            <div
              className={
                "p-col-12 p-lg-" + labelCol + " p-md-" + labelCol + " p-sm-12"
              }
            >
              <label
                className={
                  this.props.className ? this.props.className : "input-label"
                }
              >
                <b>
                  {this.props.labelDisplay +
                    (this.props.required && !this.props.disabled ? "*" : "")}
                </b>
              </label>
            </div>
            <div
              className={
                "p-col-12 p-lg-" +
                elementCol +
                " p-md-" +
                elementCol +
                " p-sm-12"
              }
            >
              {this.props.disabled && this.getDisabledView()}
              {!this.props.disabled && (
                <pr.Calendar
                  id={this.props.id}
                  showIcon={false}
                  className={this.props.value ? "" : "p-invalid"}
                  placeholder={
                    this.props.placeholder
                      ? this.props.placeholder
                      : this.props.labelDisplay
                  }
                  required={this.props.required}
                  value={new Date(this.props.value) || ""}
                  onChange={this.saveValue}
                  disabled={this.props.disabled}
                  onFocus={this.props.onFocus}
                  style={
                    this.props.style ? this.props.style : { width: "100%" }
                  }
                />
              )}
            </div>
          </>
        )}
      </div>
    );
  };
  getSingleFileUpload = () => {
    // var labelCol = 3;
    // var elementCol = 9;

    // if (this.props.displayOptions) {
    //   labelCol = this.props.displayOptions.label;
    //   elementCol = this.props.displayOptions.element;
    // }
    return (
      <div className="p-grid">
        {this.props.labelDisplayPosition === LabelPosition.TOP && (
          <>
            <div className="p-col-12 ui-md-12">
              {this.props.value ? (
                <img
                  src={this.props.value}
                  alt=""
                  style={
                    this.props.imageStyle
                      ? this.props.imageStyle
                      : {
                          width: "50%",
                          height: "6em",
                          opacity: "0.6",
                        }
                  }
                />
              ) : (
                <img
                  src={"assets/layout/images/council/no-image-placeholder.png"}
                  alt=""
                  style={
                    this.props.imageStyle
                      ? this.props.imageStyle
                      : {
                          width: "50%",
                          height: "6em",
                          opacity: "0.6",
                        }
                  }
                />
              )}
            </div>
            <div className="p-col-12">
              {!this.props.disabled && (
                <SingleFileUploader
                  value={this.props.value || ""}
                  updateValue={this.saveValue}
                  accept={this.props.accept}
                  style={
                    this.props.style ? this.props.style : { float: "left" }
                  }
                  chooseLabel={this.props.labelDisplay}
                />
              )}
            </div>
          </>
        )}
        {this.props.labelDisplayPosition === LabelPosition.NONE && (
          <>
            <div className="p-col-12 ui-md-12">
              {this.props.value ? (
                <img
                  src={this.props.value}
                  alt=""
                  style={
                    this.props.imageStyle
                      ? this.props.imageStyle
                      : {
                          width: "50%",
                          height: "6em",
                          opacity: "0.6",
                        }
                  }
                />
              ) : (
                <img
                  src={"assets/layout/images/council/no-image-placeholder.png"}
                  alt=""
                  style={
                    this.props.imageStyle
                      ? this.props.imageStyle
                      : {
                          width: "50%",
                          height: "6em",
                          opacity: "0.6",
                        }
                  }
                />
              )}
            </div>
            <div className="p-col-12">
              {!this.props.disabled && (
                <SingleFileUploader
                  value={this.props.value || ""}
                  updateValue={this.saveValue}
                  accept={this.props.accept}
                  style={
                    this.props.style ? this.props.style : { float: "left" }
                  }
                  chooseLabel={this.props.labelDisplay}
                />
              )}
            </div>
          </>
        )}
        {this.props.labelDisplayPosition === LabelPosition.LEFT && (
          <>
            <div className="p-col-6">
              {!this.props.disabled && (
                <SingleFileUploader
                  value={this.props.value || ""}
                  updateValue={this.saveValue}
                  accept={this.props.accept}
                  style={
                    this.props.style ? this.props.style : { float: "left" }
                  }
                  chooseLabel={this.props.labelDisplay}
                />
              )}
            </div>
            <div className="p-col-6 ui-md-6">
              {this.props.value ? (
                <img
                  src={this.props.value}
                  alt=""
                  style={
                    this.props.imageStyle
                      ? this.props.imageStyle
                      : {
                          width: "50%",
                          height: "6em",
                          opacity: "0.6",
                        }
                  }
                />
              ) : (
                <img
                  src={"assets/layout/images/council/no-image-placeholder.png"}
                  alt=""
                  style={
                    this.props.imageStyle
                      ? this.props.imageStyle
                      : {
                          width: "50%",
                          height: "6em",
                          opacity: "0.6",
                        }
                  }
                />
              )}
            </div>
          </>
        )}
      </div>
    );
  };
  getCheckbox = () => {
    var labelCol = 3;
    var elementCol = 1;
    var elementColRem = 8;

    if (this.props.displayOptions) {
      labelCol = this.props.displayOptions.label;
      elementCol = this.props.displayOptions.element;
      elementColRem = this.props.displayOptions.elementRem;
    }
    return (
      <div className="p-grid">
        {this.props.labelDisplayPosition === LabelPosition.TOP && (
          <div className="p-col-12">
            <label
              htmlFor={this.props.id}
              className={
                this.props.className ? this.props.className : "input-label"
              }
            >
              <b>
                {this.props.labelDisplay + (this.props.required ? "*" : "")}
              </b>
            </label>
            {this.props.disabled && this.getDisabledView()}
            {!this.props.disabled && (
              <pr.Checkbox
                id={this.props.id}
                className={this.props.value ? "" : "p-invalid"}
                placeholder={
                  this.props.placeholder
                    ? this.props.placeholder
                    : this.props.labelDisplay
                }
                required={this.props.required}
                checked={this.props.value}
                onChange={this.saveValue}
                disabled={this.props.disabled}
                style={this.props.style ? this.props.style : { width: "100%" }}
              />
            )}
          </div>
        )}
        {this.props.labelDisplayPosition === LabelPosition.NONE && (
          <div className="p-col-12">
            {this.props.disabled && this.getDisabledView()}
            {!this.props.disabled && (
              <pr.Checkbox
                id={this.props.id}
                className={this.props.value ? "" : "p-invalid"}
                placeholder={
                  this.props.placeholder
                    ? this.props.placeholder
                    : this.props.labelDisplay
                }
                required={this.props.required}
                checked={this.props.value}
                onChange={this.saveValue}
                disabled={this.props.disabled}
                style={this.props.style ? this.props.style : { width: "100%" }}
              />
            )}
          </div>
        )}
        {this.props.labelDisplayPosition === LabelPosition.LEFT && (
          <>
            <div
              className={
                "p-col-12 p-lg-" + labelCol + " p-md-" + labelCol + " p-sm-12"
              }
            >
              <label
                className={
                  this.props.className ? this.props.className : "input-label"
                }
                style={this.props.checkboxLabelStyle}
              >
                {/* <b> */}
                {this.props.labelDisplay +
                  (this.props.required && !this.props.disabled ? "*" : "")}
                {/* </b> */}
              </label>
            </div>
            <div
              className={
                "p-col-12 p-lg-" +
                elementCol +
                " p-md-" +
                elementCol +
                " p-sm-12"
              }
            >
              <center>
                <pr.Checkbox
                  id={this.props.id}
                  className={this.props.value ? "" : "p-invalid"}
                  placeholder={
                    this.props.placeholder
                      ? this.props.placeholder
                      : this.props.labelDisplay
                  }
                  required={this.props.required}
                  checked={this.props.value}
                  onChange={this.saveValue}
                  disabled={this.props.disabled}
                  style={
                    this.props.style ? this.props.style : { width: "100%" }
                  }
                />
              </center>
            </div>
            <div
              className={
                "p-col-12 p-lg-" +
                elementColRem +
                " p-md-" +
                elementColRem +
                " p-sm-12"
              }
            ></div>
          </>
        )}
      </div>
    );
  };
}
