/**
 * buysupply-production.appspot.com
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.1
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient', 'model/IProfileItemBean'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('./IProfileItemBean'));
  } else {
    // Browser globals (root is window)
    if (!root.BuysupplyProductionappspotcom) {
      root.BuysupplyProductionappspotcom = {};
    }
    root.BuysupplyProductionappspotcom.ProfileItemResponse = factory(root.BuysupplyProductionappspotcom.ApiClient, root.BuysupplyProductionappspotcom.IProfileItemBean);
  }
}(this, function(ApiClient, IProfileItemBean) {
  'use strict';




  /**
   * The ProfileItemResponse model module.
   * @module model/ProfileItemResponse
   * @version 1.0.0
   */

  /**
   * Constructs a new <code>ProfileItemResponse</code>.
   * @alias module:model/ProfileItemResponse
   * @class
   */
  var exports = function() {
    var _this = this;




















  };

  /**
   * Constructs a <code>ProfileItemResponse</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ProfileItemResponse} obj Optional instance to populate.
   * @return {module:model/ProfileItemResponse} The populated <code>ProfileItemResponse</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('codeTag')) {
        obj['codeTag'] = ApiClient.convertToType(data['codeTag'], 'String');
      }
      if (data.hasOwnProperty('dateCreated')) {
        obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
      }
      if (data.hasOwnProperty('errorCode')) {
        obj['errorCode'] = ApiClient.convertToType(data['errorCode'], 'Number');
      }
      if (data.hasOwnProperty('formId')) {
        obj['formId'] = ApiClient.convertToType(data['formId'], 'String');
      }
      if (data.hasOwnProperty('formItemId')) {
        obj['formItemId'] = ApiClient.convertToType(data['formItemId'], 'String');
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('lastUpdatedDateTime')) {
        obj['lastUpdatedDateTime'] = ApiClient.convertToType(data['lastUpdatedDateTime'], 'Date');
      }
      if (data.hasOwnProperty('profileId')) {
        obj['profileId'] = ApiClient.convertToType(data['profileId'], 'String');
      }
      if (data.hasOwnProperty('profileItemList')) {
        obj['profileItemList'] = ApiClient.convertToType(data['profileItemList'], [IProfileItemBean]);
      }
      if (data.hasOwnProperty('question')) {
        obj['question'] = ApiClient.convertToType(data['question'], 'String');
      }
      if (data.hasOwnProperty('questionNumber')) {
        obj['questionNumber'] = ApiClient.convertToType(data['questionNumber'], 'String');
      }
      if (data.hasOwnProperty('responseBoolean')) {
        obj['responseBoolean'] = ApiClient.convertToType(data['responseBoolean'], 'Boolean');
      }
      if (data.hasOwnProperty('responseInt')) {
        obj['responseInt'] = ApiClient.convertToType(data['responseInt'], 'Number');
      }
      if (data.hasOwnProperty('responseMsg')) {
        obj['responseMsg'] = ApiClient.convertToType(data['responseMsg'], 'String');
      }
      if (data.hasOwnProperty('responseString')) {
        obj['responseString'] = ApiClient.convertToType(data['responseString'], 'String');
      }
      if (data.hasOwnProperty('responseType')) {
        obj['responseType'] = ApiClient.convertToType(data['responseType'], 'String');
      }
      if (data.hasOwnProperty('sectionName')) {
        obj['sectionName'] = ApiClient.convertToType(data['sectionName'], 'String');
      }
      if (data.hasOwnProperty('sectionNumber')) {
        obj['sectionNumber'] = ApiClient.convertToType(data['sectionNumber'], 'Number');
      }
      if (data.hasOwnProperty('userId')) {
        obj['userId'] = ApiClient.convertToType(data['userId'], 'String');
      }
    }
    return obj;
  }

  /**
   * @member {String} codeTag
   */
  exports.prototype['codeTag'] = undefined;
  /**
   * @member {Date} dateCreated
   */
  exports.prototype['dateCreated'] = undefined;
  /**
   * @member {Number} errorCode
   */
  exports.prototype['errorCode'] = undefined;
  /**
   * @member {String} formId
   */
  exports.prototype['formId'] = undefined;
  /**
   * @member {String} formItemId
   */
  exports.prototype['formItemId'] = undefined;
  /**
   * @member {String} id
   */
  exports.prototype['id'] = undefined;
  /**
   * @member {Date} lastUpdatedDateTime
   */
  exports.prototype['lastUpdatedDateTime'] = undefined;
  /**
   * @member {String} profileId
   */
  exports.prototype['profileId'] = undefined;
  /**
   * @member {Array.<module:model/IProfileItemBean>} profileItemList
   */
  exports.prototype['profileItemList'] = undefined;
  /**
   * @member {String} question
   */
  exports.prototype['question'] = undefined;
  /**
   * @member {String} questionNumber
   */
  exports.prototype['questionNumber'] = undefined;
  /**
   * @member {Boolean} responseBoolean
   */
  exports.prototype['responseBoolean'] = undefined;
  /**
   * @member {Number} responseInt
   */
  exports.prototype['responseInt'] = undefined;
  /**
   * @member {String} responseMsg
   */
  exports.prototype['responseMsg'] = undefined;
  /**
   * @member {String} responseString
   */
  exports.prototype['responseString'] = undefined;
  /**
   * @member {String} responseType
   */
  exports.prototype['responseType'] = undefined;
  /**
   * @member {String} sectionName
   */
  exports.prototype['sectionName'] = undefined;
  /**
   * @member {Number} sectionNumber
   */
  exports.prototype['sectionNumber'] = undefined;
  /**
   * @member {String} userId
   */
  exports.prototype['userId'] = undefined;



  return exports;
}));


