/**
 * buysupply-production.appspot.com
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.1
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient', 'model/IMatrixBean'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('./IMatrixBean'));
  } else {
    // Browser globals (root is window)
    if (!root.BuysupplyProductionappspotcom) {
      root.BuysupplyProductionappspotcom = {};
    }
    root.BuysupplyProductionappspotcom.MatrixResponse = factory(root.BuysupplyProductionappspotcom.ApiClient, root.BuysupplyProductionappspotcom.IMatrixBean);
  }
}(this, function(ApiClient, IMatrixBean) {
  'use strict';




  /**
   * The MatrixResponse model module.
   * @module model/MatrixResponse
   * @version 1.0.0
   */

  /**
   * Constructs a new <code>MatrixResponse</code>.
   * @alias module:model/MatrixResponse
   * @class
   */
  var exports = function() {
    var _this = this;














  };

  /**
   * Constructs a <code>MatrixResponse</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/MatrixResponse} obj Optional instance to populate.
   * @return {module:model/MatrixResponse} The populated <code>MatrixResponse</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('archived')) {
        obj['archived'] = ApiClient.convertToType(data['archived'], 'Boolean');
      }
      if (data.hasOwnProperty('codeTag')) {
        obj['codeTag'] = ApiClient.convertToType(data['codeTag'], 'String');
      }
      if (data.hasOwnProperty('dateCreated')) {
        obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
      }
      if (data.hasOwnProperty('dateCreatedToDisplay')) {
        obj['dateCreatedToDisplay'] = ApiClient.convertToType(data['dateCreatedToDisplay'], 'String');
      }
      if (data.hasOwnProperty('errorCode')) {
        obj['errorCode'] = ApiClient.convertToType(data['errorCode'], 'Number');
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('lastUpdatedDateTime')) {
        obj['lastUpdatedDateTime'] = ApiClient.convertToType(data['lastUpdatedDateTime'], 'Date');
      }
      if (data.hasOwnProperty('matrixItemList')) {
        obj['matrixItemList'] = ApiClient.convertToType(data['matrixItemList'], ['String']);
      }
      if (data.hasOwnProperty('matrixList')) {
        obj['matrixList'] = ApiClient.convertToType(data['matrixList'], [IMatrixBean]);
      }
      if (data.hasOwnProperty('matrixType')) {
        obj['matrixType'] = ApiClient.convertToType(data['matrixType'], 'String');
      }
      if (data.hasOwnProperty('pdfUrl')) {
        obj['pdfUrl'] = ApiClient.convertToType(data['pdfUrl'], 'String');
      }
      if (data.hasOwnProperty('responseMsg')) {
        obj['responseMsg'] = ApiClient.convertToType(data['responseMsg'], 'String');
      }
      if (data.hasOwnProperty('userId')) {
        obj['userId'] = ApiClient.convertToType(data['userId'], 'String');
      }
    }
    return obj;
  }

  /**
   * @member {Boolean} archived
   */
  exports.prototype['archived'] = undefined;
  /**
   * @member {String} codeTag
   */
  exports.prototype['codeTag'] = undefined;
  /**
   * @member {Date} dateCreated
   */
  exports.prototype['dateCreated'] = undefined;
  /**
   * @member {String} dateCreatedToDisplay
   */
  exports.prototype['dateCreatedToDisplay'] = undefined;
  /**
   * @member {Number} errorCode
   */
  exports.prototype['errorCode'] = undefined;
  /**
   * @member {String} id
   */
  exports.prototype['id'] = undefined;
  /**
   * @member {Date} lastUpdatedDateTime
   */
  exports.prototype['lastUpdatedDateTime'] = undefined;
  /**
   * @member {Array.<String>} matrixItemList
   */
  exports.prototype['matrixItemList'] = undefined;
  /**
   * @member {Array.<module:model/IMatrixBean>} matrixList
   */
  exports.prototype['matrixList'] = undefined;
  /**
   * @member {String} matrixType
   */
  exports.prototype['matrixType'] = undefined;
  /**
   * @member {String} pdfUrl
   */
  exports.prototype['pdfUrl'] = undefined;
  /**
   * @member {String} responseMsg
   */
  exports.prototype['responseMsg'] = undefined;
  /**
   * @member {String} userId
   */
  exports.prototype['userId'] = undefined;



  return exports;
}));


