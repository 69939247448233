import React from "react";
import { BusinessIndustryProvider } from "../BusinessIndustry/BusinessIndustryProvider";
import { ComponentProvider } from "../Component/ComponentProvider";
import {
  CrudlStatus,
  ToggleState,
  PageState,
  FeedbackState,
} from "../../common/config/AppConstants";
import { ViewState } from "../../common/config/AppConstants";
import { ValidateState } from "../../common/config/AppConstants";
import * as pr from "../../common/PrimeComponents";
import * as format from "../../common/FormatFunctions";
import { ErrorScreen } from "../Common/ErrorScreen";

export class ConfigurationController extends React.Component {
  state = {
    pageName: "ConfigurationController",
    pageState: PageState.IDLE,
    sidebarWidth: "100%",

    //DISPLAY STATES
    viewState: ViewState.TABLE,
    validateState: ValidateState.NONE,
    crudlState: CrudlStatus.LIST,
    menuState: [],

    // PROPS
    viewType: "STANDARD", // e.g. InvoiceViewType.STANDARD

    // DATA TABLE
    expandedRows: null,
    tableColumns: [],
    columnOptions: [],
    settingsToggle: ToggleState.OFF,

    // DETAILS
    configurationList: null,
    originalEditedConfiguration: "",
    editedConfiguration: null,
    receivedFeedback: {
      state: FeedbackState.NONE,
      display: "Configuration",
      crudlStatus: CrudlStatus.LIST,
    },
  };

  componentDidMount = async () => {
    await this.initialDataRender();
  };

  componentDidCatch = (error, info) => {
    this.props.log("This threw an error");
    this.props.log(error);
    this.props.log(info);
    //COMPONENT ISSUE CHECK IF THIS ERROR HANDLING IS CORRECT
    this.setError(false, "componentDidCatch", error);
  };

  initialDataRender = async () => {
    this.setViewModel();
    await this.setPageData();
  };

  setError = (critical, methodName, error) => {
    this.props.log.error(error.message);
    if (critical) {
      this.setCriticalError();
    } else {
      this.setStandardError();
    }
  };
  setStandardError = () => {
    //MORE CAN BE ADDED HERE AS APPROPRIATE
    this.showError("Configuration");
    this.setState({ pageState: PageState.IDLE });
  };
  setCriticalError = () => {
    //SET ERROR STATE OF PAGE
    this.setState({ pageState: PageState.ERROR });
  };

  setViewModel = () => {
    try {
      this.props.viewModel.updateState = this.updateState;
      this.props.viewModel.showError = this.showError;
      this.props.viewModel.showSuccess = this.showSuccess;
      this.props.viewModel.showEmpty = this.showEmpty;
    } catch (error) {
      this.setError(false, "setViewModel", error);
    }
  };

  setPageData = async () => {
    try {
      this.setState({ pageState: PageState.LOADING });

      this.menuState(CrudlStatus.LIST);

      await this.handleProps();
      // this.setUpColumns();

      this.setState({
        pageState: PageState.IDLE,
      });
    } catch (error) {
      this.setError(true, "setPageData", error);
    }
  };
  handleProps = async () => {
    if (format.isNotNull(this.props.viewType)) {
      await this.setState({ viewType: this.props.viewType });
    }
    if (format.isNotNull(this.props.sidebarWidth)) {
      await this.setState({ sidebarWidth: this.props.sidebarWidth });
    }
    if (format.isNotNull(this.props.crudlState)) {
      await this.setState({ crudlState: this.props.crudlState });
      // Add Conditions per viewType if applicable
      if (this.props.crudlState === CrudlStatus.CREATE) {
        this.executeSetup(this.props.crudlState);
      } else if (this.props.crudlState === CrudlStatus.UPDATE) {
        this.executeSetup(
          this.props.crudlState,
          this.props.editedObjectFromProps
        );
      }
    }
  };
  showError = (display, crudlStatus, whereToView) => {
    try {
      if (whereToView === ViewState.TABLE) {
        this.props.feedback.showError(this.growl, display, crudlStatus);
      }
      if (whereToView === ViewState.CARD) {
        this.setState({
          receivedFeedback: {
            state: FeedbackState.ERROR,
            display: display,
            crudlStatus: crudlStatus,
          },
        });
      }
    } catch (error) {
      this.setError(false, "showError", error);
    }
  };

  showSuccess = (display, crudlStatus, whereToView) => {
    try {
      if (whereToView === ViewState.TABLE) {
        this.props.feedback.showSuccess(this.growl, display, crudlStatus);
      }
      if (whereToView === ViewState.CARD) {
        this.setState({
          receivedFeedback: {
            state: FeedbackState.SUCCESS,
            display: display,
            crudlStatus: crudlStatus,
          },
        });
      }
    } catch (error) {
      this.setError(false, "showSuccess", error);
    }
  };

  showEmpty = (display, whereToView) => {
    try {
      if (whereToView === ViewState.TABLE) {
        this.props.feedback.showEmpty(this.growl, display);
      }
      if (whereToView === ViewState.CARD) {
        this.setState({
          receivedFeedback: {
            state: FeedbackState.EMPTY,
            display: display,
            crudlStatus: CrudlStatus.LIST,
          },
        });
      }
    } catch (error) {
      this.setError(false, "showEmpty", error);
    }
  };

  getStateValuesAsObject = () => {
    try {
      var externalObject = {};
      externalObject.configurationBean = this.state.editedConfiguration;
      externalObject.viewType = this.state.viewType;

      return externalObject;
    } catch (error) {
      this.setError(false, "getStateValuesAsObject", error);
    }
  };

  updateBean = async (field, value) => {
    try {
      this.props.log.info(
        "Updating : [" + field + "] with value [" + value + "]"
      );
      await this.setState((prevState) => ({
        editedConfiguration: {
          // object that we want to update
          ...prevState.editedConfiguration, // keep all other key-value pairs
          [field]: value,
        },
      }));
    } catch (error) {
      this.setError(false, "updateBean", error);
    }
  };

  updateState = (key, value) => {
    try {
      this.setState({ [key]: value });
    } catch (error) {
      this.setError(false, "updateState", error);
    }
  };

  validateChanges = async () => {
    try {
      if (this.state.editedConfiguration === null) {
        await this.discardChanges();
      } else {
        if (
          format.isJsonEqual(
            this.state.originalEditedConfiguration,
            this.state.editedConfiguration
          )
        ) {
          await this.discardChanges();
        } else {
          this.setState({ validateState: ValidateState.CONFIRM });
        }
      }
    } catch (error) {
      this.setError(false, "validateChanges", error);
    }
  };

  discardChanges = async () => {
    try {
      this.setState({
        viewState: ViewState.TABLE,
        toggleChangesMade: false,
        crudlState: CrudlStatus.LIST,
        selectedConfiguration: null,
      });

      // TODO use viewType constant from AppConstants
      if (this.props.viewType !== "STANDARD") {
        if (format.isFunction(this.props.updateStateFromProps)) {
          // Toggle any relevant Views for create or update pages
          // this.props.updateStateFromProps("", false);
        }
      }

      await this.setPageData();
      this.menuState(CrudlStatus.LIST);
    } catch (error) {
      this.setError(false, "discardChanges", error);
    }
  };

  checkRequiredFields = () => {
    try {
      if (format.isNotNull(this.state.editedConfiguration)) {
        // ADD REQUIREDD FIELDS HERE AS APPROPRIATE
        return format.validateValues([this.state.editedConfiguration.name]);
      } else {
        return true;
      }
    } catch (error) {
      this.setError(false, "checkRequiredFields", error);
    }
  };

  menuState = (crudlStatus) => {
    try {
      this.setState({
        menuState: pr.getBreadcrumbMenuState(
          crudlStatus,
          "Configuration",
          "#/configurations"
        ),
      });
    } catch (error) {
      this.setError(false, "menuState", error);
    }
  };

  executeCrudl = async (stayOnPage) => {
    try {
      if (format.isNotNull(this.state.crudlState)) {
        this.props.log.info(
          "Executing " +
            this.state.crudlState.value +
            " Staying[" +
            stayOnPage +
            "]"
        );

        switch (this.state.crudlState) {
          case CrudlStatus.CREATE:
            await this.addConfiguration(stayOnPage);
            break;
          case CrudlStatus.UPDATE:
            await this.updateConfiguration(stayOnPage);
            break;
          case CrudlStatus.DELETE:
            await this.deleteConfiguration();
            break;
          default:
            break;
        }
      }
    } catch (error) {
      this.setError(false, "executeCrudl", error);
    }
  };

  executeSetup = async (requiredCrudlState, data) => {
    try {
      if (format.isNotNull(requiredCrudlState)) {
        this.props.log.info("Setting up " + requiredCrudlState.value);
        switch (requiredCrudlState) {
          case CrudlStatus.CREATE:
            await this.createSetup();
            break;
          case CrudlStatus.UPDATE:
            await this.updateSetup(data);
            break;
          case CrudlStatus.VIEW:
            await this.viewSetup(data);
            break;
          case CrudlStatus.DELETE:
            await this.deleteSetup(data);
            break;
          default:
            break;
        }
      }
    } catch (error) {
      this.setError(false, "executeSetup", error);
    }
  };

  setUpColumns = () => {
    let localCols = [];
    //SPECIFIC COLUMNS FOR TABLE
    localCols.push(pr.getTableColumn("name", "Name"));

    this.setState({ tableColumns: localCols });
    var colOptions = pr.getColumnOptions(localCols);
    this.setState({ columnOptions: colOptions });
  };

  columnToggle = (event) => {
    this.setState({ tableColumns: event.value });
  };

  createSetup = async () => {
    window.scrollTo(0, 0);

    var data = {};
    data = await this.additionalParseFunctions(data, CrudlStatus.CREATE);

    this.setState({
      viewState: ViewState.CARD,
      crudlState: CrudlStatus.CREATE,
      editedConfiguration: data,
      originalEditedConfiguration: JSON.stringify(data),
    });

    this.menuState(CrudlStatus.CREATE);
  };

  updateSetup = async (data) => {
    window.scrollTo(0, 0);

    data = await this.additionalParseFunctions(data, CrudlStatus.UPDATE);

    this.setState({
      viewState: ViewState.CARD,
      crudlState: CrudlStatus.UPDATE,
      editedConfiguration: data,
      originalEditedConfiguration: JSON.stringify(data),
    });

    this.menuState(CrudlStatus.UPDATE);
  };

  viewSetup = async (data) => {
    window.scrollTo(0, 0);

    data = await this.additionalParseFunctions(data, CrudlStatus.VIEW);

    this.setState({
      viewState: ViewState.CARD,
      crudlState: CrudlStatus.VIEW,
      editedConfiguration: data,
      originalEditedConfiguration: JSON.stringify(data),
    });

    this.menuState(CrudlStatus.VIEW);
  };

  deleteSetup = async (data) => {
    data = await this.additionalParseFunctions(data, CrudlStatus.DELETE);

    this.setState({
      crudlState: CrudlStatus.DELETE,
      editedConfiguration: data,
      originalEditedConfiguration: JSON.stringify(data),
      validateState: ValidateState.DELETE,
    });
    this.menuState(CrudlStatus.DELETE);
  };

  additionalParseFunctions = async (data, crudlState) => {
    switch (crudlState) {
      case CrudlStatus.CREATE:
        break;
      case CrudlStatus.UPDATE || CrudlStatus.VIEW:
        break;
      case CrudlStatus.DELETE:
        break;
      default:
        break;
    }
    this.props.log.info(data);
    return data;
  };

  addConfiguration = async (stayOnPage) => {
    this.setState({ pageState: PageState.LOADING });
    var stateVariables = this.getStateValuesAsObject();

    if (!format.isNotNull(stayOnPage) || stayOnPage === false) {
      stateVariables.viewState = ViewState.TABLE;
    } else {
      stateVariables.viewState = ViewState.CARD;
    }

    var configurationResponse = await this.props.viewModel.executeCRUDLAction(
      CrudlStatus.CREATE,
      stateVariables
    );

    if (configurationResponse.errorCode === 0) {
      if (!format.isNotNull(stayOnPage) || stayOnPage === false) {
        await this.updateConfigurationList(
          configurationResponse,
          CrudlStatus.CREATE,
          stateVariables
        );
      } else {
        await this.updateCurrentConfiguration(
          configurationResponse,
          CrudlStatus.CREATE,
          stateVariables
        );
      }
    } else {
      this.setState({ pageState: PageState.IDLE });
    }
  };
  updateConfiguration = async (stayOnPage) => {
    this.setState({ pageState: PageState.LOADING });
    var stateVariables = this.getStateValuesAsObject();

    if (!format.isNotNull(stayOnPage) || stayOnPage === false) {
      stateVariables.viewState = ViewState.TABLE;
    } else {
      stateVariables.viewState = ViewState.CARD;
    }

    var configurationResponse = await this.props.viewModel.executeCRUDLAction(
      CrudlStatus.UPDATE,
      stateVariables
    );

    if (configurationResponse.errorCode === 0) {
      if (!format.isNotNull(stayOnPage) || stayOnPage === false) {
        await this.updateConfigurationList(
          configurationResponse,
          CrudlStatus.CREATE,
          stateVariables
        );
      } else {
        await this.updateCurrentConfiguration(
          configurationResponse,
          CrudlStatus.CREATE,
          stateVariables
        );
      }
    } else {
      this.setState({ pageState: PageState.IDLE });
    }
  };

  deleteConfiguration = async () => {
    this.setState({ pageState: PageState.LOADING });
    var stateVariables = this.getStateValuesAsObject();

    var configurationResponse = await this.props.viewModel.executeCRUDLAction(
      CrudlStatus.DELETE,
      stateVariables
    );

    if (configurationResponse.errorCode === 0) {
      await this.updateConfigurationList(
        configurationResponse,
        CrudlStatus.DELETE,
        stateVariables
      );
    } else {
      this.setState({ pageState: PageState.IDLE });
    }
  };

  updateConfigurationList = async (apiResponse, type, stateVariables) => {
    this.setState({ pageState: PageState.LOADING });

    // TODO use viewType constant from AppConstants
    if (this.props.viewType !== "STANDARD") {
      if (format.isFunction(this.props.updateObjectFromProps)) {
        // any specific setting variables back up stream for props
        // this.props.updateObjectFromProps(
        //   "name",
        //   stateVariables.configurationBean.name
        // );
      }
    }

    await this.props.viewModel.getConfigurations();

    // toggle any create or update Props views to close or change
    if (this.props.viewType !== "STANDARD") {
      if (format.isFunction(this.props.updateStateFromProps)) {
        // this.props.updateStateFromProps("toggleCreateConfiguration", false); // example
        // this.props.updateStateFromProps("configurationList", this.state.configurationList); // example
      }
    }

    this.setState({
      viewState: ViewState.TABLE,
      crudlState: CrudlStatus.LIST,
      validateState: ValidateState.NONE,
      editedConfiguration: null,
    });

    this.menuState(CrudlStatus.LIST);

    this.setState({ pageState: PageState.IDLE });
  };

  updateCurrentConfiguration = async (apiResponse, type, stateVariables) => {
    this.setState({ pageState: PageState.LOADING });

    var configurationList =
      await this.props.viewModel.getConfigurationsReturned();
    var configuration = {};
    if (format.isNotNull(configurationList)) {
      configurationList.forEach((element) => {
        if (element.id === apiResponse.id) {
          configuration = format.deepCopy(element);
        }
      });
    }
    await this.updateSetup(configuration);
    this.setState({ configurationList: configurationList });
    this.setState({ pageState: PageState.IDLE });
  };

  render() {
    return (
      <React.Fragment>
        <pr.Messages
          style={{ textAlign: "left" }}
          ref={(el) => (this.growl = el)}
        />

        {/*VERY VERY BAD ERROS ONLY eg PAGE LOAD*/}
        {this.state.pageState === PageState.ERROR && (
          <ErrorScreen
            loading={this.state.pageState === PageState.LOADING}
            refresh={this.setPageData}
          />
        )}

        {this.state.pageState !== PageState.ERROR && (
          <React.Fragment>
            <BusinessIndustryProvider />
            <ComponentProvider />
          </React.Fragment>
          // <ConfigurationView
          //   //STATE + HELPER VALUES
          //   viewState={this.state.viewState}
          //   validateState={this.state.validateState}
          //   crudlState={this.state.crudlState}
          //   menuState={this.state.menuState}
          //   loading={this.state.pageState === PageState.LOADING}
          //   updateState={this.updateState}
          //   refresh={this.setPageData}
          //   feedback={this.props.feedback}
          //   receivedFeedback={this.state.receivedFeedback}
          //   //CARD SPECIFIC PROPS
          //   viewType={this.state.viewType}
          //   editedObject={this.state.editedConfiguration}
          //   configurationTypeOptions={this.state.configurationTypeOptions}
          //   updateEdited={this.updateBean}
          //   validateChanges={this.validateChanges}
          //   discardChanges={this.discardChanges}
          //   checkRequiredFields={this.checkRequiredFields}
          //   crudlExecute={this.executeCrudl}
          //   sidebarWidth={this.state.sidebarWidth}
          //   //TABLE SPECIFIC PROPS
          //   crudlControl={this.executeSetup}
          //   configurationList={this.state.configurationList}
          //   expandedRows={this.state.expandedRows}
          //   tableColumns={this.state.tableColumns}
          //   columnOptions={this.state.columnOptions}
          //   columnToggle={this.columnToggle}
          //   tableReference={this.state.tableReference}
          //   settingsToggle={this.state.settingsToggle}
          // />
        )}
      </React.Fragment>
    );
  }
}
