import React from "react";
import { ReportingCard } from "./UI/ReportingCard";
import { ReportingTable } from "./UI/ReportingTable";
import { ValidateState } from "../../common/config/AppConstants";
import { ViewState } from "../../common/config/AppConstants";
import * as pr from "../../common/PrimeComponents";
import * as format from "../../common/FormatFunctions";

import { MVVMValidateDialog } from "../Common/MVVMValidateDialog";
export class ReportingView extends React.Component {
  render() {
    return (
      <div
        className="dashboard"
        style={this.props.loading === true ? { pointerEvents: "none" } : {}}
      >
        <div className="p-grid bpm">
          <div className="p-col-12">
            <iframe
              style={{ width: "100%", height: "80vh" }}
              src={this.props.reportingUrl}
              allowfullscreen="true"
            ></iframe>
          </div>
        </div>
        {/*
        {this.props.viewState === ViewState.CARD && (
          <React.Fragment>
            <pr.Sidebar
              visible={true}
              position="right"
              fullScreen={this.props.sidebarWidth === "100%" ? true : false}
              onHide={() => {
                this.props.validateChanges();
              }}
              baseZIndex={10000000000}
              style={{
                width: this.props.sidebarWidth,
                zIndex: 10000000000,
              }}
              icons={() => (
                <div
                  className="p-col-12"
                  style={{
                    float: "left",
                    paddingLeft: "3rem",
                    fontSize: "initial",
                  }}
                >
                  <div className="p-grid">
                    <div className="p-col-6">
                      <label className="header-label">
                        {format.capitalize(this.props.crudlState.display) +
                          " Reporting"}
                      </label>
                    </div>
                  </div>
                </div>
              )}
            >
              <MVVMValidateDialog
                header="Reporting"
                isVisible={this.props.validateState === ValidateState.CONFIRM}
                visibleField="validateState"
                updateVisibility={this.props.updateState}
                runConfirm={this.props.discardChanges}
                message={"Discard Changes?"}
              />
              <MVVMValidateDialog
                header="Delete Reporting"
                isVisible={this.props.validateState === ValidateState.DELETE}
                visibleField="validateState"
                updateVisibility={this.props.updateState}
                message={" Are you sure you want to delete?"}
                type={"CRUDL"}
                crudlExecute={this.props.crudlExecute}
                crudlState={this.props.crudlState}
              />
              <ReportingCard
                loading={this.props.loading}
                feedback={this.props.feedback}
                receivedFeedback={this.props.receivedFeedback}
                crudlState={this.props.crudlState}
                crudlControl={this.props.crudlControl}
                updateValue={this.props.updateEdited}
                updateState={this.props.updateState}
                editedObject={this.props.editedObject}
                checkRequiredFields={this.props.checkRequiredFields}
                discardChanges={this.props.discardChanges}
                validateState={this.props.validateState}
                validateChanges={this.props.validateChanges}
                crudlExecute={this.props.crudlExecute}
                viewType={this.props.viewType}
              />
            </pr.Sidebar>
            <div className="custom-overlay" />
          </React.Fragment>
        )}

         <ReportingTable
          //CUSTOMISATION
          tableReference={this.props.tableReference}
          tableColumns={this.props.tableColumns}
          columnOptions={this.props.columnOptions}
          columnToggle={this.props.columnToggle}
          expandedRows={this.props.expandedRows}
          settingsToggle={this.props.settingsToggle}
          //
          updateState={this.props.updateState}
          reportingList={this.props.reportingList}
          crudlControl={this.props.crudlControl}
          validateState={this.props.validateState}
          menuState={this.props.menuState}
          crudlState={this.props.crudlState}
          loading={this.props.loading}
          refresh={this.props.refresh}
          viewType={this.props.viewType}
        /> */}
      </div>
    );
  }
}
