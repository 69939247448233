import React from "react";
import { ProfileItemCard } from "./UI/ProfileItemCard";

export class ProfileItemView extends React.Component {
  render() {
    return (
      <div
        className="dashboard"
        style={this.props.loading === true ? { pointerEvents: "none" } : {}}
      >
        <ProfileItemCard
          loading={this.props.loading}
          feedback={this.props.feedback}
          receivedFeedback={this.props.receivedFeedback}
          crudlState={this.props.crudlState}
          crudlControl={this.props.crudlControl}
          updateValue={this.props.updateEdited}
          updateState={this.props.updateState}
          editedObject={this.props.editedObject}
          checkRequiredFields={this.props.checkRequiredFields}
          discardChanges={this.props.discardChanges}
          validateState={this.props.validateState}
          validateChanges={this.props.validateChanges}
          crudlExecute={this.props.crudlExecute}
          viewType={this.props.viewType}
          profileItemList={this.props.profileItemList}
          updateProfileItemList={this.props.updateProfileItemList}
          // Toggles
          toggleGeneral={this.props.toggleGeneral}
          toggleQuality={this.props.toggleQuality}
          toggleCost={this.props.toggleCost}
          toggleLogistics={this.props.toggleLogistics}
          toggleDevelopment={this.props.toggleDevelopment}
          toggleManagement={this.props.toggleManagement}
        />
      </div>
    );
  }
}
