import React, { Component } from "react";
import { Status } from "../Status/Status";
import * as pr from "../../common/PrimeComponents";

export class Scenario2 extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    if (this.props.status === "Pending") {
      return (
        <React.Fragment>
          <Status
            ppe={true}
            noReturn={true}
            message={
              <span>
                Thank you for registering with BuySupplyNI, your application
                will be reviewed within the next 48 hours, if you have not had a
                response please get in touch with the team by emailing{" "}
                <label
                  className="custom-label"
                  onClick={() => {
                    window.open("mailto:buysupplyni@midandeastantrim.gov.uk");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  buysupplyni@midandeastantrim.gov.uk
                </label>{" "}
                or by calling on 028 2563 3345
              </span>
            }
          />
          <div className="p-col-12">
            <pr.Button
              className="secondary-btn"
              label="Close"
              icon="pi pi-check"
              onClick={(e) => {
                this.props.close();
              }}
              style={{ float: "right" }}
            />
          </div>
        </React.Fragment>
      );
    } else if (this.props.status === "Unsuccessful") {
      return (
        <React.Fragment>
          <Status
            ppe={true}
            noReturn={true}
            message={<span>Your application has been unsuccessful</span>}
          />
          <div className="p-col-12">
            <pr.Button
              className="secondary-btn"
              label="Close"
              icon="pi pi-check"
              onClick={(e) => {
                this.props.close();
              }}
              style={{ float: "right" }}
            />
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Status
            ppe={true}
            noReturn={true}
            message={
              <span>No application for this business could be found</span>
            }
          />
          <div className="p-col-12">
            <pr.Button
              className="secondary-btn"
              label="Close"
              icon="pi pi-check"
              onClick={(e) => {
                this.props.close();
              }}
              style={{ float: "right" }}
            />
          </div>
        </React.Fragment>
      );
    }
  }
}
