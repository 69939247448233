import React, { Component } from "react";
import firebase from "firebase";
import axios from "axios";
//PrimeReact Components
import * as pr from "../../common/PrimeComponents";
//Common Functions
import { api } from "../../common/CloudService/ApiPromisify";
import { login } from "../../common/helpers/auth";
import * as c from "../../common/config/AppConstants";
//ClientLibsReference
import { CloudService } from "../../common/CloudService/ClientLibsReference";

import backgroundPPE1 from "../../../public/assets/layout/images/council/background-ppe.jpg";
import backgroundPPE2 from "../../../public/assets/layout/images/council/background-buy.jpg";
import backgroundPPE3 from "../../../public/assets/layout/images/council/background-supply.jpg";
import backgroundPPE4 from "../../../public/assets/layout/images/council/background-council.jpg";
import backgroundPPE5 from "../../../public/assets/layout/images/council/background-ppe1.jpg";
import backgroundPPE6 from "../../../public/assets/layout/images/council/background-ppe2.jpg";
import { LoadScreen } from "../Common/LoadScreen";

export class PPELogin extends Component {
  constructor() {
    super();
    this.state = {
      stripePublicKey: "",
      stripeSecretKey: "",
      email: "",
      password: "",
      showPassword: false,
      loading: false,
      initialLoading: true,
      imagesArray: [
        backgroundPPE1,
        backgroundPPE2,
        backgroundPPE3,
        backgroundPPE4,
        backgroundPPE5,
        backgroundPPE6,
      ],
      image: backgroundPPE1,
    };
  }

  componentDidMount = () => {
    var min = 0;
    var max = 6;
    var random = min + Math.random() * (max - min);
    console.log(random);
    this.setState({
      image: this.state.imagesArray[Math.floor(random)],
      initialLoading: false,
    });

    //this.getStripeDetails(); Stripe code for later implementation
  };

  getStripeDetails = async () => {
    let settingsRequest = new CloudService.SettingsRequest();
    settingsRequest.codeTag = c.defaultedGlobalBid;
    settingsRequest.page = "DISPLAY_PAYMENT";

    await api("settingsApiV1SettingsGetByPage", settingsRequest).then((res) => {
      if (res.data) {
        let settingsResponse =
          CloudService.SettingsResponse.constructFromObject(res.data);
        console.log(settingsResponse);

        if (settingsResponse.errorCode === 0) {
          if (Array.isArray(settingsResponse.settingsList)) {
            settingsResponse.settingsList.forEach((e) => {
              if (e.settingName === "stripePublicKey") {
                this.setState({ stripePublicKey: e.value });
              }
              if (e.settingName === "stripeSecretKey") {
                this.setState({ stripeSecretKey: e.value });
              }
            });
          }
        } else {
          this.growl.show({
            severity: "error",
            summary: "Error",
            detail:
              "An error occured retrieving the Stripe details, please refresh and try again",
            life: 3000,
          });
        }
      } else {
        console.log("settingsApiV1SettingsGetByPage has failed");
      }
    });
  };

  getProfile = async (user) => {
    localStorage.setItem("loginPage", "#/login-covid-19-supplies");

    let profileRequest = new CloudService.ProfileRequest();
    profileRequest.codeTag = localStorage.getItem("clientId");

    var success = false;
    await api("profileApiV1ProfileGetByCodeTag", profileRequest).then((res) => {
      if (res.data) {
        let profileResponse = CloudService.ProfileResponse.constructFromObject(
          res.data
        );
        console.log(profileResponse);
        var validPPEProfile = "";
        var validPPEProfileStatus = "";
        if (Array.isArray(profileResponse.profileList)) {
          profileResponse.profileList.forEach((element) => {
            if (element.type === "PPESupplier") {
              validPPEProfile = "PPESupplier";
              validPPEProfileStatus = element.profileStatus;
            }
            if (element.type === "PPEBuyer") {
              validPPEProfile = "PPEBuyer";
              validPPEProfileStatus = element.profileStatus;
            }
            if (element.type === "Both") {
              validPPEProfile = "Both";
              validPPEProfileStatus = element.profileStatus;
            }
          });
        }

        //TEST A
        //validPPEProfile = "";
        if (validPPEProfile === "") {
          //No Valid Profile
          this.setState({ loading: false });
          this.growl.show({
            severity: "error",
            summary: <br />,
            detail: "Error: No Valid Profile found",
            life: 3000,
          });
        } else {
          localStorage.setItem("profileStatus", validPPEProfileStatus);
          localStorage.setItem("profileType", "PPE");
          localStorage.setItem("validPPEProfile", validPPEProfile);

          //TEST 1
          //localStorage.setItem("validPPEProfile", "PPESupplier");

          //TEST 2
          //localStorage.setItem("validPPEProfile", "PPEBuyer");

          //TEST 2
          // localStorage.setItem("validPPEProfile", "Both");

          if (user.emailVerified) {
            //Original Flow
            console.log("User Verified");
            localStorage.setItem("email", user.email);
            window.location = "#/Dashboard";
            window.location.reload();

            //Payment Flow
            /* if (
              loginResponse.stripeCustomer &&
              loginResponse.stripeCustomer.stripeCustomerId
            ) {
              localStorage.setItem(
                "stripeCustomerId",
                loginResponse.stripeCustomer.stripeCustomerId
              );
              this.checkSubscriptions(user.email);
            } else {
              window.location = "#/Payment";
            } */
          } else {
            console.log("User Not Verified");

            window.location = "#/Verify";
          }
        }
      }
    });

    return success;
  };

  loginCloud = async (user) => {
    let loginRequest = new CloudService.LoginRequest();

    loginRequest.codeTag = localStorage.getItem("globalBid");
    if (!localStorage.getItem("globalBid")) {
      loginRequest.codeTag = c.defaultedGlobalBid;
    }
    loginRequest.userEmail = user.email.replace(/\s+/g, "");

    await api("loginApiV1Login", loginRequest).then(async (res) => {
      let loginResponse = CloudService.LoginResponse.constructFromObject(
        res.data
      );
      console.log(loginResponse);

      if (loginResponse.errorCode !== 0) {
        this.setState({ loading: false });
        this.growl.show({
          severity: "error",
          summary: <br />,
          detail:
            "Error: User deleted or does not exist. Contact Administrator.",
          life: 3000,
        });
        localStorage.clear();
        localStorage.setItem("globalBid", loginResponse.globalBid);
      } else {
        if (loginResponse.roleType === "100") {
          this.setState({ loading: false });
          this.growl.show({
            severity: "error",
            summary: <br />,
            detail: "Error: User not authorised.",
            life: 3000,
          });
        } else {
          Object.entries(loginResponse).forEach(([key, value]) => {
            localStorage.setItem(key, value);
          });
        }
      }
    });
  };
  handleSubmitLogin = async () => {
    this.setState({ loading: true });
    this.growl.clear();
    let didError = false;

    await login(this.state.email, this.state.password).catch((error) => {
      this.setState({ loading: false });
      this.growl.show({
        severity: "error",
        summary: <br />,
        detail: error.toString(),
        life: 3000,
      });
      didError = true;
    });

    if (!didError) {
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          firebase
            .auth()
            .currentUser.getIdToken(true)
            .then((idToken) => {
              localStorage.setItem("idToken", idToken);
            });
          await this.loginCloud(user);
          await this.getProfile(user);
        }
      });
    }
  };

  checkSubscriptions = (email) => {
    var opts = {
      customer: localStorage.getItem("stripeCustomerId"),
      limit: 100,
    };

    axios({
      method: "get",
      url: "https://api.stripe.com/v1/subscriptions",
      params: opts,
      headers: {
        Authorization: "Bearer " + this.state.stripeSecretKey,
      },
    }).then((res) => {
      if (res.data && res.data.data) {
        let subscriptionList = res.data.data;
        if (Array.isArray(subscriptionList) && subscriptionList.length) {
          let activeSubscription = false;

          subscriptionList.forEach((e) => {
            if (e.plan && e.plan.active) {
              activeSubscription = true;
            }
          });

          if (activeSubscription) {
            localStorage.setItem("email", email);
            window.location = "#/Dashboard";
            window.location.reload();
          } else {
            window.location = "#/Payment";
          }
        } else {
          window.location = "#/Payment";
        }
      } else {
        console.log(res);
      }
    });
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.handleSubmitLogin();
    }
  };

  render() {
    const s = this.state;

    if (this.state.initialLoading === true) {
      return <LoadScreen></LoadScreen>;
    }
    return (
      <div
        onKeyPress={this.handleKeyPress}
        style={{
          backgroundImage: "url(" + this.state.image + ")",
          backgroundSize: "100% 100%",
          height: "100%",
        }}
      >
        <div className="p-grid">
          <div
            className="card login-panel p-fluid"
            style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
          >
            <pr.Messages
              style={{ textAlign: "left" }}
              ref={(el) => (this.growl = el)}
            />
            <div className="p-grid">
              <div className="p-col-12">
                <img
                  src="/assets/layout/images/council/logo.png"
                  alt=""
                  height="100%"
                  width="100%"
                />
              </div>
              <div className="p-col-12">
                <span
                  className="md-inputfield form-field"
                  style={{ marginTop: "0px" }}
                >
                  <pr.InputText
                    value={s.email || ""}
                    onChange={(e) =>
                      this.setState({
                        email: e.target.value,
                      })
                    }
                  />
                  <label className="custom-label">Email Address</label>
                </span>
              </div>
              <div className="p-col-12 p-grid">
                <div className="p-col-10" style={{ padding: "0" }}>
                  <span
                    className="md-inputfield form-field"
                    style={{ marginTop: "0px" }}
                  >
                    {this.state.showPassword === false ? (
                      <pr.Password
                        feedback={false}
                        promptLabel="Strength"
                        value={s.password || ""}
                        onChange={(e) =>
                          this.setState({
                            password: e.target.value,
                          })
                        }
                      />
                    ) : (
                      <pr.InputText
                        value={s.password || ""}
                        onChange={(e) =>
                          this.setState({
                            password: e.target.value,
                          })
                        }
                      />
                    )}
                    <label className="custom-label">Password</label>
                  </span>
                </div>
                <div className="p-col-2">
                  <pr.Button
                    icon={
                      s.showPassword === true ? "pi pi-eye-slash" : "pi pi-eye"
                    }
                    onClick={() => {
                      this.setState({ showPassword: !s.showPassword });
                    }}
                    style={{ margin: "0" }}
                  />
                </div>
              </div>
              <div
                className="p-col-12"
                style={{ paddingTop: "0", width: "100%" }}
              >
                <pr.Button
                  icon={
                    s.loading === false
                      ? "pi pi-sign-in"
                      : "pi pi-spin pi-spinner"
                  }
                  label="Sign In"
                  onClick={() => {
                    this.handleSubmitLogin();
                  }}
                  style={{ width: "100%" }}
                />
              </div>
              <div
                className="p-col-12"
                style={{ paddingTop: "0", width: "100%" }}
              >
                <pr.Button
                  className="secondary-btn"
                  icon="pi pi-user-plus"
                  label="Register"
                  onClick={() => {
                    window.location = "#/register-covid-19-supplies";
                  }}
                  style={{ width: "100%" }}
                />
              </div>
              <div className="p-col-12" style={{ paddingTop: "0" }}>
                <a href="#/Password">Reset Password</a>
              </div>
            </div>
          </div>
        </div>
        <img
          src="/assets/layout/images/council/logo-white.png"
          alt=""
          style={{
            position: "absolute",
            right: "1em",
            bottom: "1em",
            width: "6em",
            height: "2em",
            zIndex: "1",
          }}
        />
      </div>
    );
  }
}
