import React, { Component } from "react";
import * as pr from "../../common/PrimeComponents";
import * as Log from "../../common/Feedback/Log";
import * as format from "../../common/FormatFunctions";
import * as Constant from "../../common/config/Configuration";

export class ImageViewer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: null,
      errors: [],
      layout: "grid",
      deleteDialog: false,
      currentImageUri: "",
      sidebarVisible: false,
      loading: false,
    };

    this.logger = Log;
  }
  onClick = (evt) => {
    window.open(evt.currentTarget.id);
  };

  onHold = (image) => {
    console.log(image);
    this.setState({ currentImageUri: image });
    this.toggleDialog(true);
  };

  productIcon = (image) => {
    if (this.props.updateLogoType === "LIST") {
      this.props.updateValueProductLogo("productLogo", image);
    } else if (this.props.updateLogoType === "CUSTOM") {
      this.props.updateValueProductLogo(image);
    } else {
      this.props.updateValueProductLogo("productLogo", image);
    }
  };

  toggleDialog = (booleanValue) => {
    this.setState({ deleteDialog: booleanValue });
  };

  onRemoveImage = () => {
    this.logger.info(this.state.currentImageUri);
    var localImageUri = format.deepCopy(this.props.imageList);
    localImageUri = localImageUri.filter(
      (element) => element !== this.state.currentImageUri
    );

    if (this.props.updateType === "LIST") {
      if (this.props.productLogo === this.state.currentImageUri) {
        this.props.updateValue("productLogo", "");
      }
      this.props.updateValue(this.props.field, localImageUri, this.props.index);
    } else if (this.props.updateType === "CUSTOM") {
      this.props.updateValue(localImageUri);
    } else {
      if (this.props.productLogo === this.state.currentImageUri) {
        this.props.updateValue("productLogo", "");
      }
      this.props.updateValue(this.props.field, localImageUri);
    }

    this.setState({ currentImageUri: "" });
    this.toggleDialog(false);
  };

  onUploadImage = (files, onlyOne) => {
    this.setState({ loading: true });
    const ref = Constant.getFirebaseStorage();
    this.logger.info(Object.values(files));
    const fileArray = Object.values(files);
    var requiredLength = fileArray.length;
    var localList = format.deepCopy(this.props.imageList);
    if (!format.isNotNull(localList)) {
      localList = [];
    }

    requiredLength = requiredLength + localList.length;

    fileArray.forEach((e) => {
      const name =
        format.formatDate(new Date(), "DDMMYYYYHHMMSS") + "-" + e.name;
      const metadata = {
        contentType: e.type,
        visibilty: "public",
      };

      const task = ref.child(name).put(e, metadata);
      task
        .then((snapshot) => snapshot.ref.getDownloadURL())
        .then((url) => {
          this.logger.info(url);

          localList.push(url);

          this.logger.info(localList);
          if (localList.length === requiredLength) {
            if (this.props.updateType === "LIST") {
              this.props.updateValue(
                this.props.field,
                localList,
                this.props.index
              );
            } else if (this.props.updateType === "CUSTOM") {
              console.log("CUSTOM", localList);
              this.props.updateValue(localList);
            } else {
              this.props.updateValue(this.props.field, localList);
            }
            //this.dt.clear();
            this.setState({ sidebarVisible: false, loading: false }, () => {
              if (this.fileUploader) {
                this.fileUploader.clear();
              }
            });
          }
        });
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className="p-grid bpm card">
          <div className="p-col-12">
            {!this.props.readOnly && (
              <pr.Button
                label="Add Images"
                icon="pi pi-check"
                onClick={() => {
                  this.setState({ sidebarVisible: true });
                }}
                style={{
                  color: "white",
                  float: "right",
                }}
              />
            )}

            {this.state.sidebarVisible && (
              <pr.Sidebar
                //. className="sdbr"
                visible={true}
                fullScreen={false}
                position="right"
                onHide={() => {
                  this.setState({ sidebarVisible: false });
                }}
                baseZIndex={100000000000000}
                style={{
                  width: "40%",
                  zIndex: 100000000000000,
                }}
              >
                <pr.FileUpload
                  ref={(el) => {
                    this.fileUploader = el;
                  }}
                  style={{ width: "100%" }}
                  multiple={true}
                  customUpload={true}
                  uploadHandler={(e) => {
                    console.log("Upload Happening");
                    this.onUploadImage(e.files);
                  }}
                  accept={this.props.accept}
                  chooseLabel={"Choose"}
                  visible={false}
                ></pr.FileUpload>
                <div
                  className={
                    this.state.loading === true ? "custom-overlay" : ""
                  }
                />
              </pr.Sidebar>
            )}
          </div>
          <br />
          <pr.Dialog
            header={"Remove Image"}
            footer={
              <div>
                <pr.Button
                  className="secondary-btn"
                  label="Yes"
                  icon="pi pi-check"
                  onClick={() => {
                    this.onRemoveImage();
                  }}
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    float: "right",
                  }}
                />
                <pr.Button
                  className="secondary-btn"
                  label="No"
                  icon="pi pi-times"
                  onClick={() => {
                    this.toggleDialog(false);
                  }}
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    float: "left",
                  }}
                />
              </div>
            }
            visible={this.state.deleteDialog}
            // style={{ backgroundColor: "#ffffff", color: "white" }}
            modal={true}
            onHide={(e) => this.toggleDialog(false)}
          >
            Are you sure you want to delete this Image?
          </pr.Dialog>
          {!format.isNotNull(this.props.imageList) ? (
            <pr.Galleria
              value={["assets/layout/images/council/no-image-placeholder.png"]}
              item={this.getIndividualTemplate}
              thumbnail={this.itemTemplate}
              style={{ width: "100%" }}
              numVisible={1}
              showThumbnails={true}
              showIndicators={true}
            ></pr.Galleria>
          ) : (
            <pr.Galleria
              value={this.props.imageList}
              item={this.getIndividualTemplate}
              thumbnail={this.itemTemplate}
              style={{ width: "100%" }}
              numVisible={1}
              showThumbnails={true}
              showIndicators={true}
            ></pr.Galleria>
          )}
        </div>
      </React.Fragment>
    );
  }

  itemTemplate = (item) => {
    return (
      <div className="p-grid p-nogutter p-justify-center">
        <img
          src={`${item}`}
          alt={item}
          style={{
            width: "25%",
          }}
        />
      </div>
    );
  };

  getIndividualTemplate = (image) => {
    console.log(image);
    return (
      <div className="p-col-12" style={{ position: "relative", width: "100%" }}>
        <div className="p-grid">
          <div className="p-col-2"></div>

          <div className="p-col-8">
            {format.isNotNull(this.props.imageList) && (
              <>
                {!this.props.readOnly && (
                  <pr.Button
                    icon="pi pi-times"
                    onClick={() => {
                      this.onHold(image);
                    }}
                    style={{
                      float: "right",
                      backgroundColor: "red",
                      position: "absolute",
                    }}
                  />
                )}
                {!this.props.readOnly && (
                  <>
                    {this.props.toggleProductLogo && (
                      <>
                        {this.props.productLogo !== image ? (
                          <pr.Button
                            icon="pi pi-check"
                            label={"Make this image the Product Logo?"}
                            onClick={() => {
                              this.productIcon(image);
                            }}
                            style={{
                              float: "right",
                            }}
                          />
                        ) : (
                          <pr.Button
                            label={"Default Image"}
                            disabled
                            onClick={() => {}}
                            style={{
                              float: "right",
                              backgroundColor: "#00ac86",
                            }}
                          />
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
            <div className="p-grid p-nogutter p-justify-center">
              <img
                src={image}
                alt={image}
                style={{
                  width: "50%",
                  height: "50%",
                }}
                onClick={() => {
                  window.open(image);
                }}
              />
            </div>
          </div>

          <div className="p-col-2"></div>
        </div>
      </div>
    );
  };
  getItemTemplate = (imageList) => {
    var componentArray = [];
    var index = 0;

    if (format.isNotNull(imageList)) {
      imageList.forEach((image) => {
        componentArray[index] = this.getIndividualTemplate(image);
        index++;
      });
    }

    var rowChecker = 0;
    var totalRowsRequiredMinimum = (index / 3) >> 0;

    var remainder = index % 3;
    if (remainder > 0) {
      totalRowsRequiredMinimum++;
    }
    var rowArray = [];
    for (var i = 1; i <= totalRowsRequiredMinimum; i++) {
      rowArray[i] = (
        <div className="p-col-12 row">
          {this.getValue(componentArray, rowChecker)}
          {this.getValue(componentArray, rowChecker + 1)}
          {this.getValue(componentArray, rowChecker + 2)}
        </div>
      );
      rowChecker = rowChecker + 3;
    }
    return rowArray;
  };

  getValue = (componentArray, index) => {
    if (format.isNotNull(componentArray)) {
      if (format.isNotNull(componentArray[index])) {
        return componentArray[index];
      } else {
        return <div className="p-col-12 p-md-4 cell"></div>;
      }
    } else {
      return <div className="p-col-12 p-md-4 cell"></div>;
    }
  };
}
