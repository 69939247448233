import React from "react";
import { RegisterView } from "./RegisterView";
import {
  CrudlStatus,
  ToggleState,
  PageState,
  FeedbackState,
  BusinessType,
  ProfileStatus,
  ProductType,
  ViewType,
  ProductStatus,
} from "../../common/config/AppConstants";
import { ViewState } from "../../common/config/AppConstants";
import { ValidateState } from "../../common/config/AppConstants";
import * as pr from "../../common/PrimeComponents";
import * as format from "../../common/FormatFunctions";
import { ErrorScreen } from "../Common/ErrorScreen";
import {
  CapabilityOptions,
  CapabilityBuyerOptions,
  CapabilitySupplierOptions,
} from "../../common/config/EnumDropdowns";
import firebase from "firebase";
import * as analytics from "../../common/helpers/firebaseAnalytics";

export class RegisterController extends React.Component {
  state = {
    index: 0,

    pageName: "RegisterController",
    pageState: PageState.IDLE,
    sidebarWidth: "100%",

    //DISPLAY STATES
    viewState: ViewState.TABLE,
    validateState: ValidateState.NONE,
    crudlState: CrudlStatus.LIST,
    menuState: [],

    // PROPS
    viewType: ViewType.STANDARD,

    // DATA TABLE
    expandedRows: null,
    tableColumns: [],
    columnOptions: [],
    settingsToggle: ToggleState.OFF,

    // DETAILS
    registerList: null,
    originalEditedRegister: "",
    editedRegister: null,
    receivedFeedback: {
      state: FeedbackState.NONE,
      display: "Register",
      crudlStatus: CrudlStatus.LIST,
    },

    // Business
    editedBusiness: {},
    // editedBusiness: {
    //   businessName: "Engineering Example Ltd",
    //   addressLine1: "Main Street",
    //   addressLine2: "Unit 12",
    //   city: "Belfast",
    //   countyState: "Antrim",
    //   postalZipCode: "BT123AB",
    //   demographicArea: "Belfast City Council",
    //   email: "engineering@email.com",
    //   telephoneNumber: "02812345678",
    //   discoveredVia: "Email",
    //   typeBuyer: true,
    //   typeSupplier: true,
    //   type: "BuyerSupplier",
    //   businessAlreadyExists: false,
    //   subType: "BIN00000000000004550001",
    //   businessDescription: "Tell us a little about yourself",
    //   discoveredViaDescription:
    //     "Is there anything else users would need to hear about your business?",
    // },

    // User
    editedUser: {},
    // editedUser: {
    //   conditionsSigned: true,
    //   firstName: "Exmaple",
    //   surname: "User",
    //   email: "example-user@email.com",
    //   password: "password",
    //   confirmPassword: "password",
    //   userAlreadyExists: false,
    //   telephone: "0778888888888",
    // },

    // Components
    componentList: [],
    componentOptions: [],

    // Products
    editedProduct: {},
    // editedProduct: {
    //   productStatus: "Active",
    //   productLogo:
    //     "https://firebasestorage.googleapis.com/v0/b/buysupply-development.appspot.com/o/22-07-2021%2010%3A15%3A34-Product%20fabrication%20drawing.jpg?alt=media&token=bab2197c-a7c2-4677-95e3-24a739c42947",
    //   imageUri: [
    //     "https://firebasestorage.googleapis.com/v0/b/buysupply-development.appspot.com/o/22-07-2021%2010%3A15%3A34-Product%20fabrication%20drawing.jpg?alt=media&token=bab2197c-a7c2-4677-95e3-24a739c42947",
    //   ],
    //   // pdfUrls: [
    //   //   {
    //   //     url: "https://firebasestorage.googleapis.com/v0/b/buysupply-development.appspot.com/o/Engineering%20Drawings.jpg?alt=media&token=b32aff39-b5aa-4e10-9507-34f3608a9870",
    //   //     name: "Engineering Drawings.jpg",
    //   //   },
    //   // ],
    // },

    // Tab View Toggles
    componentsFormComplete: false,
    businessFormComplete: false,
    userFormComplete: false,
  };

  componentDidMount = async () => {
    await this.initialDataRender();
    analytics.screenViewEvent(analytics.PageView.REGISTER);
  };

  componentDidCatch = (error, info) => {
    this.props.log("This threw an error");
    this.props.log(error);
    this.props.log(info);
    //COMPONENT ISSUE CHECK IF THIS ERROR HANDLING IS CORRECT
    this.setError(false, "componentDidCatch", error);
  };

  initialDataRender = async () => {
    this.setViewModel();
    await this.setPageData();
  };

  setError = (critical, methodName, error) => {
    this.props.log.error(error.message);
    if (critical) {
      this.setCriticalError();
    } else {
      this.setStandardError();
    }
  };
  setStandardError = () => {
    //MORE CAN BE ADDED HERE AS APPROPRIATE
    this.showError("Register");
    this.setState({ pageState: PageState.IDLE });
  };
  setCriticalError = () => {
    //SET ERROR STATE OF PAGE
    this.setState({ pageState: PageState.ERROR });
  };

  setViewModel = () => {
    try {
      this.props.viewModel.updateState = this.updateState;
      this.props.viewModel.showError = this.showError;
      this.props.viewModel.showSuccess = this.showSuccess;
      this.props.viewModel.showEmpty = this.showEmpty;
    } catch (error) {
      this.setError(false, "setViewModel", error);
    }
  };

  setPageData = async () => {
    try {
      this.setState({ pageState: PageState.LOADING });

      // this.menuState(CrudlStatus.LIST);

      await this.handleProps();
      localStorage.setItem("loginPage", "#/login");
      // this.setUpColumns();

      // SPECIFIC API CALLS GO HERE
      this.props.viewModel.getComponents();
      await this.props.viewModel.getBusinessIndustries();

      this.setState({
        pageState: PageState.IDLE,
      });
    } catch (error) {
      this.setError(true, "setPageData", error);
    }
  };
  handleProps = async () => {
    if (format.isNotNull(this.props.viewType)) {
      await this.setState({ viewType: this.props.viewType });
    }
    if (format.isNotNull(this.props.sidebarWidth)) {
      await this.setState({ sidebarWidth: this.props.sidebarWidth });
    }
    if (format.isNotNull(this.props.crudlState)) {
      await this.setState({ crudlState: this.props.crudlState });
      // Add Conditions per viewType if applicable
      if (this.props.crudlState === CrudlStatus.CREATE) {
        this.executeSetup(this.props.crudlState);
      } else if (this.props.crudlState === CrudlStatus.UPDATE) {
        this.executeSetup(
          this.props.crudlState,
          this.props.editedObjectFromProps
        );
      }
    }
  };
  showError = (display, crudlStatus, whereToView) => {
    try {
      if (whereToView === ViewState.TABLE) {
        this.props.feedback.showError(this.growl, display, crudlStatus);
      }
      if (whereToView === ViewState.CARD) {
        this.setState({
          receivedFeedback: {
            state: FeedbackState.ERROR,
            display: display,
            crudlStatus: crudlStatus,
          },
        });
      }
    } catch (error) {
      this.setError(false, "showError", error);
    }
  };

  showSuccess = (display, crudlStatus, whereToView) => {
    try {
      if (whereToView === ViewState.TABLE) {
        this.props.feedback.showSuccess(this.growl, display, crudlStatus);
      }
      if (whereToView === ViewState.CARD) {
        this.setState({
          receivedFeedback: {
            state: FeedbackState.SUCCESS,
            display: display,
            crudlStatus: crudlStatus,
          },
        });
      }
    } catch (error) {
      this.setError(false, "showSuccess", error);
    }
  };

  showEmpty = (display, whereToView) => {
    try {
      if (whereToView === ViewState.TABLE) {
        this.props.feedback.showEmpty(this.growl, display);
      }
      if (whereToView === ViewState.CARD) {
        this.setState({
          receivedFeedback: {
            state: FeedbackState.EMPTY,
            display: display,
            crudlStatus: CrudlStatus.LIST,
          },
        });
      }
    } catch (error) {
      this.setError(false, "showEmpty", error);
    }
  };

  getStateValuesAsObject = () => {
    try {
      var externalObject = {};
      externalObject.registerBean = this.state.editedRegister;
      externalObject.viewType = this.state.viewType;

      return externalObject;
    } catch (error) {
      this.setError(false, "getStateValuesAsObject", error);
    }
  };

  updateBean = async (field, value) => {
    try {
      this.props.log.info(
        "Updating : [" + field + "] with value [" + value + "]"
      );
      await this.setState((prevState) => ({
        editedRegister: {
          // object that we want to update
          ...prevState.editedRegister, // keep all other key-value pairs
          [field]: value,
        },
      }));
    } catch (error) {
      this.setError(false, "updateBean", error);
    }
  };
  updateBeanUser = async (field, value) => {
    try {
      this.props.log.info(
        "Updating : [" + field + "] with value [" + value + "]"
      );
      await this.setState((prevState) => ({
        editedUser: {
          // object that we want to update
          ...prevState.editedUser, // keep all other key-value pairs
          [field]: value,
        },
      }));
    } catch (error) {
      this.setError(false, "updateBeanUser", error);
    }
  };
  updateBeanBusiness = async (field, value) => {
    try {
      this.props.log.info(
        "Updating : [" + field + "] with value [" + value + "]"
      );
      await this.setState((prevState) => ({
        editedBusiness: {
          // object that we want to update
          ...prevState.editedBusiness, // keep all other key-value pairs
          [field]: value,
        },
      }));
    } catch (error) {
      this.setError(false, "updateBeanBusiness", error);
    }
  };
  updateBeanProduct = async (field, value) => {
    try {
      this.props.log.info(
        "Updating : [" + field + "] with value [" + value + "]"
      );
      await this.setState((prevState) => ({
        editedProduct: {
          // object that we want to update
          ...prevState.editedProduct, // keep all other key-value pairs
          [field]: value,
        },
      }));
    } catch (error) {
      this.setError(false, "updateBeanProduct", error);
    }
  };

  updateState = (key, value) => {
    try {
      console.log([key], value);
      this.setState({ [key]: value });
    } catch (error) {
      this.setError(false, "updateState", error);
    }
  };

  checkRequiredFields = () => {
    try {
      let fieldList = [];
      if (
        format.isNotNull(this.state.editedBusiness) &&
        format.isNotNull(this.state.componentList) &&
        format.isNotNull(this.state.editedUser) &&
        format.isNotNull(this.state.editedProduct)
      ) {
        // Business
        fieldList.push(this.state.editedBusiness.businessName);
        fieldList.push(this.state.editedBusiness.city);
        fieldList.push(this.state.editedBusiness.countyState);
        fieldList.push(this.state.editedBusiness.demographicArea);
        fieldList.push(this.state.editedBusiness.postalZipCode);
        fieldList.push(this.state.editedBusiness.email);
        fieldList.push(this.state.editedBusiness.telephoneNumber);
        fieldList.push(this.state.editedBusiness.discoveredVia);
        if (
          !this.state.editedBusiness.typeBuyer &&
          !this.state.editedBusiness.typeSupplier
        ) {
          return true;
        }

        // Category
        fieldList.push(this.state.componentList);

        // User
        fieldList.push(this.state.editedUser.conditionsSigned);
        fieldList.push(this.state.editedUser.firstName);
        fieldList.push(this.state.editedUser.surname);
        fieldList.push(this.state.editedUser.email);
        fieldList.push(this.state.editedUser.password);
        fieldList.push(this.state.editedUser.confirmPassword);
        if (this.state.editedUser.userAlreadyExists) {
          return true;
        }

        // Product
        fieldList.push(this.state.editedProduct.name);
        // fieldList.push(this.state.editedProduct.productLogo);
        // fieldList.push(this.state.editedProduct.shortDescription);
        fieldList.push(this.state.editedProduct.longDescription);
        fieldList.push(this.state.editedProduct.componentList);

        if (
          !this.state.editedBusiness.typeBuyer &&
          this.state.editedBusiness.typeSupplier
        ) {
          fieldList.push(this.state.editedProduct.dateRequired);
        } else if (
          this.state.editedBusiness.typeBuyer &&
          this.state.editedBusiness.typeSupplier
        ) {
          fieldList.push(this.state.editedProduct.dateRequired);
        }

        return format.validateValues(fieldList);
      } else {
        return true;
      }
    } catch (error) {
      this.setError(false, "checkRequiredFields", error);
    }
  };

  changeCategoriesState = (key, value) => {
    let categoryList = this.state.componentList;
    let componentOptions = this.state.componentOptions;

    console.log(key, value);

    categoryList.forEach((e) => {
      if (key === e.name) {
        if (value === false) {
          e.selectedOnCreate = value;
          e.score = "";
          e.details = "";
        } else {
          e.selectedOnCreate = value;
          if (format.isNotNull(this.state.editedBusiness)) {
            if (this.state.editedBusiness.type === BusinessType.BUYER) {
              e.score = CapabilityBuyerOptions[0].value;
            } else if (
              this.state.editedBusiness.type === BusinessType.SUPPLIER
            ) {
              e.score = CapabilitySupplierOptions[0].value;
            } else if (
              this.state.editedBusiness.type === BusinessType.BUYERSUPPLIER
            ) {
              e.score = CapabilityOptions[0].value;
            }
          }

          let options = {};
          options.label = e.name;
          options.value = e.id;
          componentOptions.push(options);
        }
      }
      if (key === e.name + " Score") {
        console.log("Score Set");
        e.score = value;

        e.selectedOnCreate = value;
        // if (format.isNotNull(this.state.editedBusiness)) {
        //   if (this.state.editedBusiness.type === BusinessType.BUYER) {
        //     e.score = CapabilityBuyerOptions[0].value;
        //   } else if (this.state.editedBusiness.type === BusinessType.SUPPLIER) {
        //     e.score = CapabilitySupplierOptions[0].value;
        //   } else if (
        //     this.state.editedBusiness.type === BusinessType.BUYERSUPPLIER
        //   ) {
        //     e.score = CapabilityOptions[0].value;
        //   }
        // }

        let options = {};
        options.label = e.name;
        options.value = e.id;
        componentOptions.push(options);
      }
      if (key === e.name + " Details") {
        e.details = value;
      }
    });

    console.log(componentOptions);

    this.setState({
      categoryList: categoryList,
      componentOptions: componentOptions,
    });
  };

  onRegister = async () => {
    this.setState({ pageState: PageState.LOADING });

    let businessResponse = await this.createBusiness();
    if (
      format.isNotNull(businessResponse.errorCode) &&
      businessResponse.errorCode === 0
    ) {
      let userResponse = await this.createUser();
      if (
        format.isNotNull(userResponse.errorCode) &&
        userResponse.errorCode === 0
      ) {
        let matrixItemResponse = await this.createMatrixItem();
        if (
          format.isNotNull(matrixItemResponse.errorCode) &&
          matrixItemResponse.errorCode === 0
        ) {
          let profileResponse = await this.createProfile();
          if (
            format.isNotNull(profileResponse.errorCode) &&
            profileResponse.errorCode === 0
          ) {
            let productResponse = await this.createProduct();

            if (
              format.isNotNull(productResponse) &&
              productResponse.errorCode === 0
            ) {
              this.sendVerificationEmail();
            }
          }
        }
      }
    }
  };

  createBusiness = async () => {
    let request = this.state.editedBusiness;
    request.currency = "GBP";
    request.countryCode = "GBR";
    request.timezone = "Europe/London";
    request.configJsonStr = "{}";
    if (request.typeBuyer && !request.typeSupplier) {
      request.type = BusinessType.BUYER;
    } else if (!request.typeBuyer && request.typeSupplier) {
      request.type = BusinessType.SUPPLIER;
    } else if (request.typeBuyer && request.typeSupplier) {
      request.type = BusinessType.BUYERSUPPLIER;
    }

    analytics.signUpEvent(request);

    let response = await this.props.viewModel.createBusiness(request);
    return response;
  };
  createUser = async () => {
    let request = this.state.editedUser;
    request.codeTag = this.state.businessId;
    request.roleType = 40;

    let response = await this.props.viewModel.createUser(request);
    return response;
  };
  createUserFirebase = () => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(
        this.state.editedUser.email,
        this.state.editedUser.password
      )
      .then(async () => {
        await this.onRegister();
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loaded: true }, () => {
          this.showError("Failed to create user");
        });
      });
  };
  createMatrixItem = async () => {
    let matrixItemRequest = {};
    let matrixItemArray = [];

    if (format.isNotNull(this.state.componentList)) {
      this.state.componentList.forEach((e) => {
        if (e.selectedOnCreate) {
          matrixItemArray.push({
            codeTag: this.state.businessId,
            userId: this.state.userId,
            componentId: e.id,
            score: e.score,
            otherComponent: e.details,
          });
        }
      });
    }

    matrixItemRequest.matrixItemList = matrixItemArray;
    matrixItemRequest.codeTag = this.state.businessId;
    matrixItemRequest.userId = this.state.userId;
    matrixItemRequest.status = "PENDING";

    let response = await this.props.viewModel.createMatrixItem(
      matrixItemRequest
    );
    return response;
  };
  createProfile = async () => {
    let request = this.state.editedBusiness;
    request.codeTag = this.state.businessId;
    request.userId = this.state.userId;

    let componentIdList = [];
    if (format.isNotNull(this.state.componentList)) {
      this.state.componentList.forEach((e) => {
        if (e.selectedOnCreate) {
          componentIdList.push(e.id);
        }
      });
    }

    request.componentList = componentIdList;
    request.status = ProfileStatus.PENDING;

    let response = await this.props.viewModel.createProfile(request);
    return response;
  };
  createProduct = async () => {
    let request = this.state.editedProduct;
    request.codeTag = this.state.businessId;
    request.userId = this.state.userId;
    if (
      this.state.editedBusiness.typeBuyer &&
      !this.state.editedBusiness.typeSupplier
    ) {
      request.type = ProductType.BUYER;
      request.productStatus = ProductStatus.ACTIVE;
    } else if (
      !this.state.editedBusiness.typeBuyer &&
      this.state.editedBusiness.typeSupplier
    ) {
      request.type = ProductType.SUPPLIER;
      request.productStatus = ProductStatus.ACTIVE;
    } else if (
      this.state.editedBusiness.typeBuyer &&
      this.state.editedBusiness.typeSupplier
    ) {
      request.type = ProductType.SUPPLIER;
      request.productStatus = ProductStatus.ACTIVE;
    }

    let response = await this.props.viewModel.createProduct(request);
    return response;
  };
  sendVerificationEmail = () => {
    firebase
      .auth()
      .signInWithEmailAndPassword(
        this.state.editedUser.email,
        this.state.editedUser.password
      )
      .then(() => {
        firebase
          .auth()
          .currentUser.reload()
          .then(() => {
            let user = firebase.auth().currentUser;
            user.sendEmailVerification().then(() => {
              this.setState({ pageState: PageState.IDLE });

              window.location = "#/Verify";
            });
          });
      });
  };

  checkEmailExistsInFirebase = () => {
    firebase
      .auth()
      .fetchSignInMethodsForEmail(this.state.editedUser.email)
      .then((result) => {
        console.log(result);
        this.updateBeanUser("validatedEmail", true);
        console.log(this.state.editedUser.validatedEmail);
        if (!format.isNotNull(result)) {
          console.log("User does not exist");
          this.updateBeanUser("userAlreadyExists", false);

          return <label> User does not exist </label>;
        }

        if (format.isNotNull(result)) {
          console.log("Sigin in methods array is populated");
          this.updateBeanUser("userAlreadyExists", true);

          return <label>User already exists</label>;
        }
      })

      .catch((error) => {
        // TODO handle the error here and return false
        console.log(error);
        if (error.code === "auth/quota-exceeded") {
          this.growl.show({
            severity: "error",
            summary: <br />,
            detail: error.message + " Try again in 1 minute",
            life: 3000,
          });
        }
      });
  };

  checkEmailAddressAlreadyExists = async () => {
    if (
      format.isNotNull(this.state.editedUser.email) &&
      this.state.editedUser.email.includes("@") &&
      this.state.editedUser.email.includes(".")
    ) {
      let userResponse = await this.props.viewModel.userGetByEmail(
        this.state.editedUser.email
      );
      if (format.isNotNull(userResponse)) {
        if (userResponse.errorCode === 0) {
          this.updateBeanUser("userAlreadyExists", false);
          return this.checkEmailExistsInFirebase();
        } else {
          this.updateBeanUser("userAlreadyExists", true);
          return <label>User already exists</label>;
        }
      } else {
        this.updateBeanUser("userAlreadyExists", false);
        return <label>User already exists</label>;
      }
    }
  };

  checkBusinessAlreadyExists = async () => {
    if (format.isNotNull(this.state.editedBusiness.businessName)) {
      let businessResponse = await this.props.viewModel.businessListByName(
        this.state.editedBusiness.businessName
      );
      if (format.isNotNull(businessResponse)) {
        if (businessResponse.errorCode === 0) {
          if (
            format.isNotNull(businessResponse.businesses) &&
            businessResponse.businesses.length > 0
          ) {
            this.updateBeanBusiness("businessAlreadyExists", true);
          } else {
            this.updateBeanBusiness("businessAlreadyExists", false);
          }
        } else {
          this.updateBeanBusiness("businessAlreadyExists", true);
        }
      } else {
        this.updateBeanBusiness("businessAlreadyExists", true);
      }
    }
  };

  updateBusinessType = (target, value) => {
    let editedBusiness = format.deepCopy(this.state.editedBusiness);
    this.updateBeanBusiness(target, value);
    editedBusiness[target] = value;

    if (editedBusiness.typeBuyer && !editedBusiness.typeSupplier) {
      this.updateBeanBusiness("type", BusinessType.BUYER);
    } else if (!editedBusiness.typeBuyer && editedBusiness.typeSupplier) {
      this.updateBeanBusiness("type", BusinessType.SUPPLIER);
    } else if (editedBusiness.typeBuyer && editedBusiness.typeSupplier) {
      this.updateBeanBusiness("type", BusinessType.BUYERSUPPLIER);
    } else {
      this.updateBeanBusiness("type", null);
    }
  };

  render() {
    return (
      <React.Fragment>
        <pr.Messages
          style={{ textAlign: "left" }}
          ref={(el) => (this.growl = el)}
        />

        {/*VERY VERY BAD ERROS ONLY eg PAGE LOAD*/}
        {this.state.pageState === PageState.ERROR && (
          <ErrorScreen
            loading={this.state.pageState === PageState.LOADING}
            refresh={this.setPageData}
          />
        )}

        {this.state.pageState !== PageState.ERROR && (
          <RegisterView
            //STATE + HELPER VALUES
            viewState={this.state.viewState}
            validateState={this.state.validateState}
            crudlState={this.state.crudlState}
            menuState={this.state.menuState}
            loading={this.state.pageState === PageState.LOADING}
            updateState={this.updateState}
            refresh={this.setPageData}
            feedback={this.props.feedback}
            receivedFeedback={this.state.receivedFeedback}
            //CARD SPECIFIC PROPS
            viewType={this.state.viewType}
            editedObject={this.state.editedRegister}
            updateEdited={this.updateBean}
            checkRequiredFields={this.checkRequiredFields}
            //
            index={this.state.index}
            componentFormComplete={this.state.componentFormComplete}
            componentList={this.state.componentList}
            changeCategoriesState={this.changeCategoriesState}
            businessFormComplete={this.state.businessFormComplete}
            userFormComplete={this.state.userFormComplete}
            editedBusiness={this.state.editedBusiness}
            editedUser={this.state.editedUser}
            editedProduct={this.state.editedProduct}
            //
            updateEditedBusiness={this.updateBeanBusiness}
            updateEditedUser={this.updateBeanUser}
            updateEditedProduct={this.updateBeanProduct}
            componentOptions={this.state.componentOptions}
            onRegister={this.createUserFirebase}
            checkEmailAddressAlreadyExists={this.checkEmailAddressAlreadyExists}
            checkBusinessAlreadyExists={this.checkBusinessAlreadyExists}
            updateBusinessType={this.updateBusinessType}
            businessTypeOptions={this.state.businessTypeOptions}
          />
        )}
      </React.Fragment>
    );
  }
}
