import React from "react";
//PrimeReact Components
import * as pr from "../../../common/PrimeComponents";
import { CrudlStatus } from "../../../common/config/AppConstants";
import * as format from "../../../common/FormatFunctions";
import { ComponentDisplay } from "./ComponentDisplay";

export class ComponentTable extends React.Component {
  export = () => {
    this.dt.exportCSV();
  };

  getComponentColumns = () => {
    var allColumns = [];
    // allColumns.push(
    //   <pr.Column
    //     key="expander"
    //     expander={true}
    //     style={{ width: "5em", float: "middle" }}
    //   />
    // );
    if (format.isNotNull(this.props.tableColumns)) {
      let columns = this.props.tableColumns.map((col, i) => {
        return (
          <pr.Column
            key={col.field}
            field={col.field}
            header={col.header}
            sortable={col.sortable}
            filter={col.filter}
            filterMatchMode={col.filterMatchMode}
            style={col.style}
          />
        );
      });

      columns.forEach((e) => {
        allColumns.push(e);
      });
    }
    return allColumns;
  };

  render() {
    return (
      <div className="p-grid bpm">
        <div className="p-col-12">
          <div className="p-col-12 p-lg-3">
            <div className="content-section">
              <div className="feature-intro">
                <h1>{"Components"}</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="p-col-12">
          <pr.DataTable
            ref={(el) => {
              this.dt = el;
            }}
            value={this.props.componentList}
            selectionMode="single"
            onSelectionChange={(e) => {
              this.props.crudlControl(CrudlStatus.UPDATE, e.value);
            }}
            header={
              <div className="p-grid">
                <div className="p-col-12">
                  <pr.Button
                    className="table-button"
                    label={"Add New"}
                    icon="pi-md-add"
                    style={{
                      float: "right",
                    }}
                    onClick={() => {
                      this.props.crudlControl(CrudlStatus.CREATE);
                    }}
                  />
                  <pr.Button
                    className="table-button"
                    type="button"
                    label={"Export Data"}
                    style={{
                      float: "right",
                    }}
                    icon="pi-md-launch"
                    onClick={this.export}
                  />
                  <pr.Button
                    className="table-button"
                    type="button"
                    style={{
                      float: "right",
                    }}
                    label={"Refresh"}
                    icon={
                      this.props.loading === true
                        ? "pi-spin pi-md-autorenew"
                        : "pi-md-autorenew"
                    }
                    onClick={this.props.refresh}
                  />
                </div>
              </div>
            }
            emptyMessage="No Components"
            expandedRows={this.props.expandedRows}
            onRowToggle={(e) => this.props.updateState("expandedRows", e.data)}
            rowExpansionComponent={this.getDisplayCard}
            rows={Infinity}
            autoLayout={true}
            paginator={false}
            alwaysShowPaginator={false}
            responsive={true}
            loading={this.props.loading}
          >
            {this.getComponentColumns()}
          </pr.DataTable>
        </div>
      </div>
    );
  }
  getDisplayCard = (data) => {
    return <ComponentDisplay data={data} onClick={this.props.crudlControl} />;
  };
}
