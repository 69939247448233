/**
 * buysupply-production.appspot.com
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.1
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient', 'model/GenericBean'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('./GenericBean'));
  } else {
    // Browser globals (root is window)
    if (!root.BuysupplyProductionappspotcom) {
      root.BuysupplyProductionappspotcom = {};
    }
    root.BuysupplyProductionappspotcom.UserBean = factory(root.BuysupplyProductionappspotcom.ApiClient, root.BuysupplyProductionappspotcom.GenericBean);
  }
}(this, function(ApiClient, GenericBean) {
  'use strict';




  /**
   * The UserBean model module.
   * @module model/UserBean
   * @version 1.0.0
   */

  /**
   * Constructs a new <code>UserBean</code>.
   * @alias module:model/UserBean
   * @class
   */
  var exports = function() {
    var _this = this;






































  };

  /**
   * Constructs a <code>UserBean</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/UserBean} obj Optional instance to populate.
   * @return {module:model/UserBean} The populated <code>UserBean</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('addressLine1')) {
        obj['addressLine1'] = ApiClient.convertToType(data['addressLine1'], 'String');
      }
      if (data.hasOwnProperty('addressLine2')) {
        obj['addressLine2'] = ApiClient.convertToType(data['addressLine2'], 'String');
      }
      if (data.hasOwnProperty('authenticateProvider')) {
        obj['authenticateProvider'] = ApiClient.convertToType(data['authenticateProvider'], 'String');
      }
      if (data.hasOwnProperty('codeTag')) {
        obj['codeTag'] = ApiClient.convertToType(data['codeTag'], 'String');
      }
      if (data.hasOwnProperty('contactTelephone')) {
        obj['contactTelephone'] = ApiClient.convertToType(data['contactTelephone'], 'String');
      }
      if (data.hasOwnProperty('country')) {
        obj['country'] = ApiClient.convertToType(data['country'], 'String');
      }
      if (data.hasOwnProperty('countryCode')) {
        obj['countryCode'] = ApiClient.convertToType(data['countryCode'], 'String');
      }
      if (data.hasOwnProperty('county')) {
        obj['county'] = ApiClient.convertToType(data['county'], 'String');
      }
      if (data.hasOwnProperty('dateCreated')) {
        obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
      }
      if (data.hasOwnProperty('dateDeleted')) {
        obj['dateDeleted'] = ApiClient.convertToType(data['dateDeleted'], 'Date');
      }
      if (data.hasOwnProperty('email')) {
        obj['email'] = ApiClient.convertToType(data['email'], 'String');
      }
      if (data.hasOwnProperty('facebookId')) {
        obj['facebookId'] = ApiClient.convertToType(data['facebookId'], 'String');
      }
      if (data.hasOwnProperty('firstName')) {
        obj['firstName'] = ApiClient.convertToType(data['firstName'], 'String');
      }
      if (data.hasOwnProperty('gcmRegistrationids')) {
        obj['gcmRegistrationids'] = ApiClient.convertToType(data['gcmRegistrationids'], ['String']);
      }
      if (data.hasOwnProperty('genericBeanList')) {
        obj['genericBeanList'] = ApiClient.convertToType(data['genericBeanList'], [GenericBean]);
      }
      if (data.hasOwnProperty('genericDate1')) {
        obj['genericDate1'] = ApiClient.convertToType(data['genericDate1'], 'Date');
      }
      if (data.hasOwnProperty('genericDate2')) {
        obj['genericDate2'] = ApiClient.convertToType(data['genericDate2'], 'Date');
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('isValidated')) {
        obj['isValidated'] = ApiClient.convertToType(data['isValidated'], 'Boolean');
      }
      if (data.hasOwnProperty('label')) {
        obj['label'] = ApiClient.convertToType(data['label'], 'String');
      }
      if (data.hasOwnProperty('languageId')) {
        obj['languageId'] = ApiClient.convertToType(data['languageId'], 'String');
      }
      if (data.hasOwnProperty('lastUpdatedDateTime')) {
        obj['lastUpdatedDateTime'] = ApiClient.convertToType(data['lastUpdatedDateTime'], 'Date');
      }
      if (data.hasOwnProperty('latitude')) {
        obj['latitude'] = ApiClient.convertToType(data['latitude'], 'Number');
      }
      if (data.hasOwnProperty('level')) {
        obj['level'] = ApiClient.convertToType(data['level'], ['String']);
      }
      if (data.hasOwnProperty('longitude')) {
        obj['longitude'] = ApiClient.convertToType(data['longitude'], 'Number');
      }
      if (data.hasOwnProperty('notes')) {
        obj['notes'] = ApiClient.convertToType(data['notes'], 'String');
      }
      if (data.hasOwnProperty('pin')) {
        obj['pin'] = ApiClient.convertToType(data['pin'], 'String');
      }
      if (data.hasOwnProperty('postcode')) {
        obj['postcode'] = ApiClient.convertToType(data['postcode'], 'String');
      }
      if (data.hasOwnProperty('roleType')) {
        obj['roleType'] = ApiClient.convertToType(data['roleType'], 'Number');
      }
      if (data.hasOwnProperty('salutation')) {
        obj['salutation'] = ApiClient.convertToType(data['salutation'], 'String');
      }
      if (data.hasOwnProperty('signatureURI')) {
        obj['signatureURI'] = ApiClient.convertToType(data['signatureURI'], 'String');
      }
      if (data.hasOwnProperty('surname')) {
        obj['surname'] = ApiClient.convertToType(data['surname'], 'String');
      }
      if (data.hasOwnProperty('town')) {
        obj['town'] = ApiClient.convertToType(data['town'], 'String');
      }
      if (data.hasOwnProperty('twitterId')) {
        obj['twitterId'] = ApiClient.convertToType(data['twitterId'], 'String');
      }
      if (data.hasOwnProperty('userId')) {
        obj['userId'] = ApiClient.convertToType(data['userId'], 'String');
      }
      if (data.hasOwnProperty('userName')) {
        obj['userName'] = ApiClient.convertToType(data['userName'], 'String');
      }
      if (data.hasOwnProperty('verifyUser')) {
        obj['verifyUser'] = ApiClient.convertToType(data['verifyUser'], 'Boolean');
      }
    }
    return obj;
  }

  /**
   * @member {String} addressLine1
   */
  exports.prototype['addressLine1'] = undefined;
  /**
   * @member {String} addressLine2
   */
  exports.prototype['addressLine2'] = undefined;
  /**
   * @member {String} authenticateProvider
   */
  exports.prototype['authenticateProvider'] = undefined;
  /**
   * @member {String} codeTag
   */
  exports.prototype['codeTag'] = undefined;
  /**
   * @member {String} contactTelephone
   */
  exports.prototype['contactTelephone'] = undefined;
  /**
   * @member {String} country
   */
  exports.prototype['country'] = undefined;
  /**
   * @member {String} countryCode
   */
  exports.prototype['countryCode'] = undefined;
  /**
   * @member {String} county
   */
  exports.prototype['county'] = undefined;
  /**
   * @member {Date} dateCreated
   */
  exports.prototype['dateCreated'] = undefined;
  /**
   * @member {Date} dateDeleted
   */
  exports.prototype['dateDeleted'] = undefined;
  /**
   * @member {String} email
   */
  exports.prototype['email'] = undefined;
  /**
   * @member {String} facebookId
   */
  exports.prototype['facebookId'] = undefined;
  /**
   * @member {String} firstName
   */
  exports.prototype['firstName'] = undefined;
  /**
   * @member {Array.<String>} gcmRegistrationids
   */
  exports.prototype['gcmRegistrationids'] = undefined;
  /**
   * @member {Array.<module:model/GenericBean>} genericBeanList
   */
  exports.prototype['genericBeanList'] = undefined;
  /**
   * @member {Date} genericDate1
   */
  exports.prototype['genericDate1'] = undefined;
  /**
   * @member {Date} genericDate2
   */
  exports.prototype['genericDate2'] = undefined;
  /**
   * @member {String} id
   */
  exports.prototype['id'] = undefined;
  /**
   * @member {Boolean} isValidated
   */
  exports.prototype['isValidated'] = undefined;
  /**
   * @member {String} label
   */
  exports.prototype['label'] = undefined;
  /**
   * @member {String} languageId
   */
  exports.prototype['languageId'] = undefined;
  /**
   * @member {Date} lastUpdatedDateTime
   */
  exports.prototype['lastUpdatedDateTime'] = undefined;
  /**
   * @member {Number} latitude
   */
  exports.prototype['latitude'] = undefined;
  /**
   * @member {Array.<String>} level
   */
  exports.prototype['level'] = undefined;
  /**
   * @member {Number} longitude
   */
  exports.prototype['longitude'] = undefined;
  /**
   * @member {String} notes
   */
  exports.prototype['notes'] = undefined;
  /**
   * @member {String} pin
   */
  exports.prototype['pin'] = undefined;
  /**
   * @member {String} postcode
   */
  exports.prototype['postcode'] = undefined;
  /**
   * @member {Number} roleType
   */
  exports.prototype['roleType'] = undefined;
  /**
   * @member {String} salutation
   */
  exports.prototype['salutation'] = undefined;
  /**
   * @member {String} signatureURI
   */
  exports.prototype['signatureURI'] = undefined;
  /**
   * @member {String} surname
   */
  exports.prototype['surname'] = undefined;
  /**
   * @member {String} town
   */
  exports.prototype['town'] = undefined;
  /**
   * @member {String} twitterId
   */
  exports.prototype['twitterId'] = undefined;
  /**
   * @member {String} userId
   */
  exports.prototype['userId'] = undefined;
  /**
   * @member {String} userName
   */
  exports.prototype['userName'] = undefined;
  /**
   * @member {Boolean} verifyUser
   */
  exports.prototype['verifyUser'] = undefined;



  return exports;
}));


