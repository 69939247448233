//ClientLibsReference
import { CloudService } from "./ClientLibsReference";
import * as logger from "../Feedback/Log";
import * as Constant from "../config/Configuration";
import * as auth from "../helpers/auth";

var apiService = new CloudService.DefaultApi();

const handle = (promise) => {
  return promise
    .then((data) => [data, undefined])
    .catch((error) => Promise.resolve([undefined, error]));
};

export const api = async (name, o) => {
  var opts = {
    body: o,
  };

  //Authorization
  var defaultClient = CloudService.ApiClient.instance;

  //Api Key
  defaultClient.authentications["api_key"].apiKey = Constant.getApiKey();

  //Firebase

  var securityToken = await auth.checkIdToken(true);
  console.log(o);
  logger.info("Security Token [" + securityToken + "]");
  logger.info(`Called API: ${name}`);
  defaultClient.authentications["firebase"].accessToken = securityToken;

  var [response, error] = await handle(
    new Promise((resolve, reject) => {
      var callback = function (error, data, response) {
        if (error) {
          logger.info(`[${name}] Got error: ${error}`);
          reject(error);
        } else {
          resolve({ data });
        }
      };
      apiService[name](opts, callback);
    })
  );

  if (error) {
    console.log("Error Occurred");
    console.log(error);
    error.message = error.message + " [" + name + "]";

    return { data: null, error: error };
  } else {
    console.log(response.data);
    return { data: response.data, error: null };
  }
};

export const apiUnsecure = async (name, o) => {
  var opts = {
    body: o,
  };

  //Authorization
  var defaultClient = CloudService.ApiClient.instance;

  //Api Key
  defaultClient.authentications["api_key"].apiKey = Constant.getApiKey();

  //Firebase

  var [response, error] = await handle(
    new Promise((resolve, reject) => {
      var callback = function (error, data, response) {
        if (error) {
          logger.info(`[${name}] Got error: ${error}`);
          reject(error);
        } else {
          resolve({ data });
        }
      };
      apiService[name](opts, callback);
    })
  );

  if (error) {
    console.log("Error Occurred");
    console.log(error);
    error.message = error.message + " [" + name + "]";

    return { data: null, error: error };
  } else {
    console.log(response.data);
    return { data: response.data, error: null };
  }
};
