import { api } from "../../common/CloudService/ApiPromisify";
import * as c from "../../common/config/AppConstants";
import { CloudService } from "../../common/CloudService/ClientLibsReference";

export class ScenarioAlternateViewModel {
  showLoadingScreen = () => {
    this.updateState("loaded", false);
  };

  hideLoadingScreen = () => {
    this.updateState("loaded", true);
  };

  componentGetByType = async () => {
    let componentRequest = new CloudService.ComponentRequest();
    componentRequest.codeTag = c.defaultedGlobalBid;
    componentRequest.type = c.ComponentType.MANUFACTURE;

    var list = [];
    await api("componentApiV1ComponentGetByCodeTag", componentRequest).then(
      (res) => {
        if (res.data) {
          let componentResponse =
            CloudService.ComponentResponse.constructFromObject(res.data);
          console.log(componentResponse);
          list = componentResponse.componentList;
        }
      }
    );

    return list;
  };

  getAndUpdateUserRequest = async (codeTag, userId, type) => {
    let userRequest = new CloudService.UserRequest();

    userRequest.codeTag = codeTag;
    userRequest.id = userId;

    console.log(userRequest);

    await api("userApiV1UserGetAccountGlobal", userRequest).then(
      async (res) => {
        if (res.data) {
          let userResponse = CloudService.UserResponse.constructFromObject(
            res.data
          );
          console.log(userResponse);

          if (userResponse.errorCode === 0) {
            var roletype = userResponse.roleType;
            if (type === "Supplier") {
              if (roletype !== 40) {
                userRequest.roleType = 40;
                userRequest.lastUpdatedDateTime =
                  userResponse.lastUpdatedDateTime;

                await api("userApiV1UserUpdateAccountGlobal", userRequest).then(
                  (res) => {}
                );
              }
            }
            if (type === "Buyer") {
              if (roletype !== 30) {
                userRequest.roleType = 30;
                userRequest.lastUpdatedDateTime =
                  userResponse.lastUpdatedDateTime;

                await api("userApiV1UserUpdateAccountGlobal", userRequest).then(
                  (res) => {}
                );
              }
            }

            return userId;
          } else {
            return "error";
          }
        }
      }
    );

    return userRequest;
  };

  createProfile = async (codeTag, userId, categoryList, type, formsObject) => {
    let componentIdList = [];
    let profileRequest = new CloudService.ProfileRequest();
    profileRequest.codeTag = codeTag;
    profileRequest.userId = userId;
    categoryList.forEach((e) => {
      if (e.selectedOnCreate) {
        componentIdList.push(e.id);
      }
    });

    profileRequest.componentList = componentIdList;
    profileRequest.type = type;

    profileRequest.status = "Pending";

    console.log(profileRequest);

    await api("profileApiV1ProfileCreate", profileRequest).then(async (res) => {
      if (res.data) {
        let profileResponse = CloudService.ProfileResponse.constructFromObject(
          res.data
        );
        console.log(profileResponse);

        if (profileResponse.errorCode === 0) {
          if (type === "Supplier") {
            await this.createProfileItems(
              codeTag,
              userId,
              profileResponse.id,
              formsObject
            );
          }

          //TEMP Fix commenting out and hopefully wont be needed again
          //await this.getAndUpdateUserRequest(codeTag, userId, type);
        }
      }
    });
  };

  createMatrixItem = async (codeTag, userId, categoryList) => {
    let matrixItemArray = [];

    categoryList.forEach((e) => {
      if (e.selectedOnCreate) {
        matrixItemArray.push({
          codeTag: codeTag,
          userId: userId,
          componentId: e.id,
          score: e.score,
          otherComponent: e.details,
        });
      }
    });

    let matrixItemRequest = new CloudService.MatrixItemRequest();
    matrixItemRequest.codeTag = codeTag;
    matrixItemRequest.userId = userId;
    matrixItemRequest.matrixItemList = matrixItemArray;

    await api("matrixItemApiV1MatrixItemCreate", matrixItemRequest).then(
      (res) => {
        if (res.data) {
          let matrixItemResponse =
            CloudService.MatrixItemResponse.constructFromObject(res.data);
          console.log(matrixItemResponse);

          if (matrixItemResponse.errorCode === 0) {
          }
        }
      }
    );
  };

  createProfileItems = async (codeTag, userId, profileId, formsObject) => {
    let profileItemArray = [];
    let questionCount = 1;

    var generalFormsObject = formsObject.general;
    var generalFormsEntries = Object.entries(generalFormsObject);

    generalFormsEntries.forEach((e) => {
      profileItemArray.push({
        codeTag: codeTag,
        userId: userId,
        profileId: profileId,
        sectionName: "General",
        sectionNumber: 1,
        question: e[0],
        questionNumber: questionCount,
        responseString: e[1],
      });

      questionCount++;
    });

    var qualityFormsObject = formsObject.quality;
    var qualityFormsEntries = Object.entries(qualityFormsObject);

    qualityFormsEntries.forEach((e) => {
      profileItemArray.push({
        codeTag: codeTag,
        userId: userId,
        profileId: profileId,
        sectionName: "Quality",
        sectionNumber: 2,
        question: e[0],
        questionNumber: questionCount,
        responseString: e[1],
      });

      questionCount++;
    });

    var costFormsObject = formsObject.cost;
    var costFormsEntries = Object.entries(costFormsObject);

    costFormsEntries.forEach((e) => {
      profileItemArray.push({
        codeTag: codeTag,
        userId: userId,
        profileId: profileId,
        sectionName: "Cost",
        sectionNumber: 3,
        question: e[0],
        questionNumber: questionCount,
        responseString: e[1],
      });

      questionCount++;
    });

    var logisticsFormsObject = formsObject.logistics;
    var logisticsFormsEntries = Object.entries(logisticsFormsObject);

    logisticsFormsEntries.forEach((e) => {
      profileItemArray.push({
        codeTag: codeTag,
        userId: userId,
        profileId: profileId,
        sectionName: "Logistics",
        sectionNumber: 4,
        question: e[0],
        questionNumber: questionCount,
        responseString: e[1],
      });

      questionCount++;
    });

    var developmentFormsObject = formsObject.development;
    var developmentFormsEntries = Object.entries(developmentFormsObject);

    developmentFormsEntries.forEach((e) => {
      profileItemArray.push({
        codeTag: codeTag,
        userId: userId,
        profileId: profileId,
        sectionName: "Development",
        sectionNumber: 5,
        question: e[0],
        questionNumber: questionCount,
        responseString: e[1],
      });

      questionCount++;
    });

    var managementFormsObject = formsObject.management;
    var managementFormsEntries = Object.entries(managementFormsObject);

    managementFormsEntries.forEach((e) => {
      profileItemArray.push({
        codeTag: codeTag,
        userId: userId,
        profileId: profileId,
        sectionName: "Management",
        sectionNumber: 6,
        question: e[0],
        questionNumber: questionCount,
        responseString: e[1],
      });

      questionCount++;
    });

    let profileItemRequest = new CloudService.MatrixItemRequest();
    profileItemRequest.codeTag = codeTag;
    profileItemRequest.userId = userId;
    profileItemRequest.profileItemList = profileItemArray;

    await api("profileItemApiV1ProfileItemCreate", profileItemRequest).then(
      (res) => {
        if (res.data) {
          let profileItemResponse =
            CloudService.ProfileItemResponse.constructFromObject(res.data);
          console.log(profileItemResponse);

          if (profileItemResponse.errorCode === 0) {
          }
        }
      }
    );
  };

  getProfile = async () => {
    let profileRequest = new CloudService.ProfileRequest();
    profileRequest.codeTag = localStorage.getItem("clientId");

    var returnStatus = "";
    var returnType = "";
    var returnProfile = "";
    await api("profileApiV1ProfileGetByCodeTag", profileRequest).then((res) => {
      if (res.data) {
        let profileResponse = CloudService.ProfileResponse.constructFromObject(
          res.data
        );
        console.log(profileResponse);
        var validPPEProfile = "";
        var validPPEProfileStatus = "";
        if (Array.isArray(profileResponse.profileList)) {
          profileResponse.profileList.forEach((element) => {
            if (element.type === "PPESupplier") {
              validPPEProfile = "PPESupplier";
              validPPEProfileStatus = element.profileStatus;
            }
            if (element.type === "PPEBuyer") {
              validPPEProfile = "PPEBuyer";
              validPPEProfileStatus = element.profileStatus;
            }
            if (element.type === "Both") {
              validPPEProfile = "Both";
              validPPEProfileStatus = element.profileStatus;
            }
          });
        }

        //TEST A
        //validPPEProfile = "";
        if (validPPEProfile === "") {
          //Use Case 1 - Need to Create Profile
        } else {
          if (validPPEProfileStatus !== "Approved") {
            // Use Case 2 need to Diplsy information about not yet being approved
            returnStatus = validPPEProfileStatus;
          } else {
            returnStatus = validPPEProfileStatus;
            returnType = "PPE";
            returnProfile = validPPEProfile;
          }
        }
      }
    });

    return [returnStatus, returnType, returnProfile];
  };
}
