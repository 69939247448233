import React from "react";
import { LoginController } from "./LoginController";
import { LoginViewModel } from "./LoginViewModel";

export class LoginProvider extends React.Component {
  constructor(props) {
    super(props);
    this.viewModel = new LoginViewModel();
  }

  render() {
    return (
      <LoginController
        viewModel={this.viewModel}
        viewType={this.props.viewType}
      />
    );
  }
}
