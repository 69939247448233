/**
 * buysupply-production.appspot.com
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.1
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'));
  } else {
    // Browser globals (root is window)
    if (!root.BuysupplyProductionappspotcom) {
      root.BuysupplyProductionappspotcom = {};
    }
    root.BuysupplyProductionappspotcom.RegisterResponse = factory(root.BuysupplyProductionappspotcom.ApiClient);
  }
}(this, function(ApiClient) {
  'use strict';




  /**
   * The RegisterResponse model module.
   * @module model/RegisterResponse
   * @version 1.0.0
   */

  /**
   * Constructs a new <code>RegisterResponse</code>.
   * @alias module:model/RegisterResponse
   * @class
   */
  var exports = function() {
    var _this = this;










  };

  /**
   * Constructs a <code>RegisterResponse</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/RegisterResponse} obj Optional instance to populate.
   * @return {module:model/RegisterResponse} The populated <code>RegisterResponse</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('businessTypeList')) {
        obj['businessTypeList'] = ApiClient.convertToType(data['businessTypeList'], ['String']);
      }
      if (data.hasOwnProperty('componentHeaderList')) {
        obj['componentHeaderList'] = ApiClient.convertToType(data['componentHeaderList'], ['String']);
      }
      if (data.hasOwnProperty('componentTypeList')) {
        obj['componentTypeList'] = ApiClient.convertToType(data['componentTypeList'], ['String']);
      }
      if (data.hasOwnProperty('demographicAreaList')) {
        obj['demographicAreaList'] = ApiClient.convertToType(data['demographicAreaList'], ['String']);
      }
      if (data.hasOwnProperty('errorCode')) {
        obj['errorCode'] = ApiClient.convertToType(data['errorCode'], 'Number');
      }
      if (data.hasOwnProperty('matrixItemScoreList')) {
        obj['matrixItemScoreList'] = ApiClient.convertToType(data['matrixItemScoreList'], ['String']);
      }
      if (data.hasOwnProperty('productStatusList')) {
        obj['productStatusList'] = ApiClient.convertToType(data['productStatusList'], ['String']);
      }
      if (data.hasOwnProperty('responseMsg')) {
        obj['responseMsg'] = ApiClient.convertToType(data['responseMsg'], 'String');
      }
      if (data.hasOwnProperty('sectorList')) {
        obj['sectorList'] = ApiClient.convertToType(data['sectorList'], ['String']);
      }
    }
    return obj;
  }

  /**
   * @member {Array.<String>} businessTypeList
   */
  exports.prototype['businessTypeList'] = undefined;
  /**
   * @member {Array.<String>} componentHeaderList
   */
  exports.prototype['componentHeaderList'] = undefined;
  /**
   * @member {Array.<String>} componentTypeList
   */
  exports.prototype['componentTypeList'] = undefined;
  /**
   * @member {Array.<String>} demographicAreaList
   */
  exports.prototype['demographicAreaList'] = undefined;
  /**
   * @member {Number} errorCode
   */
  exports.prototype['errorCode'] = undefined;
  /**
   * @member {Array.<String>} matrixItemScoreList
   */
  exports.prototype['matrixItemScoreList'] = undefined;
  /**
   * @member {Array.<String>} productStatusList
   */
  exports.prototype['productStatusList'] = undefined;
  /**
   * @member {String} responseMsg
   */
  exports.prototype['responseMsg'] = undefined;
  /**
   * @member {Array.<String>} sectorList
   */
  exports.prototype['sectorList'] = undefined;



  return exports;
}));


