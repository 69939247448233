import React, { Component } from "react";
//PrimeReact Components
import * as pr from "../../common/PrimeComponents";
//Common Functions
import { LoadScreen } from "../Common/LoadScreen";

export class HelpDesk extends React.Component {
  constructor() {
    super();
    this.state = {
      roleType: "",
    };
  }

  componentDidMount = () => {
    this.setState({ roleType: localStorage.getItem("roleType") });
  };

  displayVideo = (title, source) => {
    return (
      <div className="p-col-12 p-md-6">
        <center>
          <b>{title}</b>
        </center>
        <div>
          <center>
            <iframe
              src={source}
              allowFullScreen
              style={{
                position: "relative",
                top: 0,
                left: 0,
              }}
            ></iframe>
          </center>
        </div>
        {/* <video width="100%" height="98%" controls>
          <source
            src={source}
            type="video/mp4"
          />
          Your browser does not support video elements, please update to the
          latest version
        </video> */}
      </div>
    );
  };

  render() {
    const s = this.state;

    return (
      <div
        className="dashboard"
        style={s.editLoaded === false ? { pointerEvents: "none" } : {}}
      >
        <pr.Messages
          style={{ textAlign: "left" }}
          ref={(el) => (this.growl = el)}
        />

        {s.loaded === false ? (
          <LoadScreen />
        ) : (
          <div className="p-grid">
            <div className="p-col-12 card">
              <div className="p-grid card" style={{ marginBottom: "1px" }}>
                <div className="p-col-12">
                  <h1>Contact Info</h1>
                </div>
                <div className="p-col-12 p-md-6">
                  <div className="p-grid">
                    <div className="p-col-12 p-md-4">
                      <b>Email</b>
                    </div>
                    <div className="p-col-12 p-md-8">
                      <label
                        className="custom-label"
                        onClick={() => {
                          window.open(
                            "mailto:buysupplyni@midandeastantrim.gov.uk"
                          );
                        }}
                        style={{ cursor: "pointer", wordWrap: "break-word" }}
                      >
                        buysupplyni@midandeastantrim.gov.uk
                      </label>
                    </div>
                  </div>
                </div>
                <div className="p-col-12 p-md-6">
                  <div className="p-grid">
                    <div className="p-col-12 p-md-4">
                      <b>Telephone</b>
                    </div>
                    <div className="p-col-12 p-md-8">
                      <label>028 2563 3345</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-grid card" style={{ marginBottom: "1px" }}>
                <div className="p-col-12">
                  <h1>User Guide</h1>
                </div>
                {this.state.roleType === "40" &&
                  this.displayVideo(
                    "Dashboard",
                    "https://player.vimeo.com/video/582850703?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  )}

                {(this.state.roleType === "10" ||
                  this.state.roleType === "20") && (
                  <React.Fragment>
                    {/** This should be the JIRA help desk for Council Users */}
                    {/* {this.displayVideo(
                      "View All Businesses",
                      "/assets/layout/images/council/view-all-businesses.mp4"
                    )}
                    {this.displayVideo(
                      "Overview of Applications",
                      "/assets/layout/images/council/view-all-businesses.mp4"
                    )}
                    {this.displayVideo(
                      "Gap Analysis",
                      "/assets/layout/images/council/gap-analysis.mp4"
                    )}
                    {this.displayVideo(
                      "Review Interactions",
                      "/assets/layout/images/council/review-interactions.mp4"
                    )} */}
                  </React.Fragment>
                )}
                {(this.state.roleType === "30" ||
                  this.state.roleType === "40") && (
                  <React.Fragment>
                    {this.displayVideo(
                      "Buyer Dashboard",
                      "https://player.vimeo.com/video/582850743?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    )}
                    {this.displayVideo(
                      "Supplier Dashboard",
                      "https://player.vimeo.com/video/582850457?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    )}

                    {this.displayVideo(
                      "View All Buyers",
                      "https://player.vimeo.com/video/582850523?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    )}
                    {this.displayVideo(
                      "View All Suppliers",
                      "https://player.vimeo.com/video/582850555?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    )}
                    {/* {this.state.roleType === "30" && (
                      this.displayVideo(
                        "Form Requests",
                        "/assets/layout/images/council/form-requests-buyer.mp4"
                      )
                    )}
                    {this.state.roleType === "40" && (
                      this.displayVideo(
                        "Form Requests",
                        "/assets/layout/images/council/form-requests.mp4"
                      )
                    )} */}
                    {this.displayVideo(
                      "Enquiries",
                      "https://player.vimeo.com/video/582850309?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    )}
                  </React.Fragment>
                )}
              </div>
              <div className="p-grid card" style={{ marginBottom: "0" }}>
                <div className="p-col-12">
                  <h1>Business Guide</h1>
                </div>
                <React.Fragment>
                  {this.displayVideo(
                    "Business Details",
                    "https://player.vimeo.com/video/582861891?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  )}
                  {this.displayVideo(
                    "My Products",
                    "https://player.vimeo.com/video/582850361?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  )}
                  {this.displayVideo(
                    "My Requirements",
                    "https://player.vimeo.com/video/582850415?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  )}
                  {this.displayVideo(
                    "Buyer Requirements",
                    "https://player.vimeo.com/video/582864768?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  )}
                  {this.displayVideo(
                    "Supplier Products & Services",
                    "https://player.vimeo.com/video/582850491?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  )}
                  {this.displayVideo(
                    "Categories",
                    "https://player.vimeo.com/video/582850588?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  )}
                  {this.displayVideo(
                    "Form",
                    "https://player.vimeo.com/video/582850645?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  )}
                  {this.displayVideo(
                    "Users",
                    "https://player.vimeo.com/video/582850675?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  )}
                </React.Fragment>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
