import React, { Component } from "react";

export class MasterFooterImage extends Component {
  render() {
    return (
      <div className="footer">
        <div className="clearfix">
          <span className="footer-text-left">{/*Empty div for spacing*/}</span>
          <span className="footer-text-right" style={{ marginRight: "30px" }}>
            <img
              src="/assets/layout/images/council/logo-black.png"
              alt=""
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                width: "8em",
                height: "3em",
              }}
            />
          </span>
        </div>
      </div>
    );
  }
}
