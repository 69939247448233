import { BaseViewModel } from "../../common/BaseViewModel";
import * as Format from "../../common/FormatFunctions";
import { CloudService } from "../../common/CloudService/ClientLibsReference";
import * as ApiService from "../../common/CloudService/ApiService";
import { CrudlStatus, ViewState } from "../../common/config/AppConstants";
export class ComponentViewModel extends BaseViewModel {
  getComponents = async () => {
    //CRITICAL - ERROR + EMPTY MESSAGE
    var componentResponse = await this.componentGetByCodeTag();
    if (
      Format.isNotNull(componentResponse.errorCode) &&
      componentResponse.errorCode === 0
    ) {
      if (Format.isNotNull(componentResponse.componentList)) {
        this.updateState("componentList", componentResponse.componentList);
        this.logger.info(componentResponse.componentList);
      } else {
        this.showEmpty("Component", ViewState.TABLE);
      }
    } else {
      this.showError("Component", CrudlStatus.LIST, ViewState.TABLE);
    }
  };

  getComponentsReturned = async () => {
    //CRITICAL - ERROR + EMPTY MESSAGE
    var componentResponse = await this.componentGetByCodeTag();
    if (
      Format.isNotNull(componentResponse.errorCode) &&
      componentResponse.errorCode === 0
    ) {
      if (Format.isNotNull(componentResponse.componentList)) {
        this.logger.info(componentResponse.componentList);
        return componentResponse.componentList;
      } else {
        this.showEmpty("Component", ViewState.TABLE);
      }
    } else {
      this.showError("Component", CrudlStatus.LIST, ViewState.TABLE);
    }
  };

  reloadDataAfterExecute = (
    components,
    apiResponse,
    type,
    externalStateValues
  ) => {
    var updatedComponentList = [];
    if (type === CrudlStatus.CREATE) {
      updatedComponentList = this.reloadAfterCreate(
        components,
        apiResponse,
        externalStateValues
      );
    }
    if (type === CrudlStatus.UPDATE) {
      updatedComponentList = this.reloadAfterUpdate(
        components,
        apiResponse,
        externalStateValues
      );
    }
    if (type === CrudlStatus.DELETE) {
      updatedComponentList = this.reloadAfterDelete(
        components,
        apiResponse,
        externalStateValues
      );
    }

    this.updateState("componentList", updatedComponentList);
  };

  reloadAfterCreate = (componentList, apiResponse, externalStateValues) => {
    if (componentList === undefined || componentList === null) {
      componentList = [];
    }
    var componentBean = externalStateValues.componentBean;
    componentBean.id = apiResponse.id;
    componentBean.lastUpdatedDateTime = apiResponse.lastUpdatedDateTime;

    componentList.push(componentBean);

    return componentList;
  };

  reloadAfterUpdate = (componentList, apiResponse, externalStateValues) => {
    var i = 0;
    componentList.forEach((e) => {
      if (apiResponse.id === e.id) {
        componentList[i] = externalStateValues.componentBean;
        componentList[i].lastUpdatedDateTime = apiResponse.lastUpdatedDateTime;
      }
      i++;
    });

    return componentList;
  };

  reloadAfterDelete = (componentList, apiResponse, externalStateValues) => {
    var i = 0;
    componentList.forEach((e) => {
      //DELETE
      if (apiResponse.id === e.id) {
        componentList.splice(i, 1);
      }
      i++;
    });

    return componentList;
  };
  executeCRUDLAction = async (crudlStatus, stateVariables) => {
    let componentRequest = CloudService.ComponentRequest.constructFromObject(
      stateVariables.componentBean
    );
    if (crudlStatus === CrudlStatus.CREATE) {
      return await this.createComponent(componentRequest, stateVariables);
    }
    if (crudlStatus === CrudlStatus.UPDATE) {
      return await this.updateComponent(componentRequest, stateVariables);
    }
    if (crudlStatus === CrudlStatus.DELETE) {
      return await this.deleteComponent(componentRequest);
    }
  };

  createComponent = async (componentRequest, stateVariables) => {
    componentRequest.codeTag = this.codeTag;
    componentRequest.userId = this.userId;

    let componentResponse = await ApiService.componentCreate(componentRequest);
    if (componentResponse.errorCode === 0) {
      this.showSuccess(
        "Component",
        CrudlStatus.CREATE,
        stateVariables.viewState
      );
    } else {
      this.showError("Component", CrudlStatus.CREATE, ViewState.CARD);
    }

    return componentResponse;
  };

  deleteComponent = async (componentRequest) => {
    //RESET PRODUCT REQUEST
    var request = {};
    request.codeTag = this.codeTag;
    request.userId = this.userId;
    request.id = componentRequest.id;
    request.lastUpdatedDateTime = componentRequest.lastUpdatedDateTime;

    let componentResponse = await ApiService.componentDelete(request);
    if (componentResponse.errorCode === 0) {
      this.showSuccess("Component", CrudlStatus.DELETE, ViewState.TABLE);
    } else {
      this.showError("Component", CrudlStatus.DELETE, ViewState.CARD);
    }

    return componentResponse;
  };

  updateComponent = async (componentRequest, stateVariables) => {
    componentRequest.codeTag = this.codeTag;
    componentRequest.userId = this.userId;

    let componentResponse = await ApiService.componentUpdate(componentRequest);
    if (componentResponse.errorCode === 0) {
      this.showSuccess(
        "Component",
        CrudlStatus.UPDATE,
        stateVariables.viewState
      );
    } else {
      this.showError("Component", CrudlStatus.UPDATE, ViewState.CARD);
    }

    return componentResponse;
  };
}
