/**
 * buysupply-production.appspot.com
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.1
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient', 'model/ProductRequest'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('./ProductRequest'));
  } else {
    // Browser globals (root is window)
    if (!root.BuysupplyProductionappspotcom) {
      root.BuysupplyProductionappspotcom = {};
    }
    root.BuysupplyProductionappspotcom.ProductRequest = factory(root.BuysupplyProductionappspotcom.ApiClient, root.BuysupplyProductionappspotcom.ProductRequest);
  }
}(this, function(ApiClient, ProductRequest) {
  'use strict';




  /**
   * The ProductRequest model module.
   * @module model/ProductRequest
   * @version 1.0.0
   */

  /**
   * Constructs a new <code>ProductRequest</code>.
   * @alias module:model/ProductRequest
   * @class
   */
  var exports = function() {
    var _this = this;





































  };

  /**
   * Constructs a <code>ProductRequest</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ProductRequest} obj Optional instance to populate.
   * @return {module:model/ProductRequest} The populated <code>ProductRequest</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('businessName')) {
        obj['businessName'] = ApiClient.convertToType(data['businessName'], 'String');
      }
      if (data.hasOwnProperty('businessSubType')) {
        obj['businessSubType'] = ApiClient.convertToType(data['businessSubType'], 'String');
      }
      if (data.hasOwnProperty('codeTag')) {
        obj['codeTag'] = ApiClient.convertToType(data['codeTag'], 'String');
      }
      if (data.hasOwnProperty('companyType')) {
        obj['companyType'] = ApiClient.convertToType(data['companyType'], 'String');
      }
      if (data.hasOwnProperty('companyWebsite')) {
        obj['companyWebsite'] = ApiClient.convertToType(data['companyWebsite'], 'String');
      }
      if (data.hasOwnProperty('componentList')) {
        obj['componentList'] = ApiClient.convertToType(data['componentList'], ['String']);
      }
      if (data.hasOwnProperty('componentNameToDisplay')) {
        obj['componentNameToDisplay'] = ApiClient.convertToType(data['componentNameToDisplay'], 'String');
      }
      if (data.hasOwnProperty('contactEmail')) {
        obj['contactEmail'] = ApiClient.convertToType(data['contactEmail'], 'String');
      }
      if (data.hasOwnProperty('contactName')) {
        obj['contactName'] = ApiClient.convertToType(data['contactName'], 'String');
      }
      if (data.hasOwnProperty('contactNumber')) {
        obj['contactNumber'] = ApiClient.convertToType(data['contactNumber'], 'String');
      }
      if (data.hasOwnProperty('csvFileUrl')) {
        obj['csvFileUrl'] = ApiClient.convertToType(data['csvFileUrl'], 'String');
      }
      if (data.hasOwnProperty('dateCreated')) {
        obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
      }
      if (data.hasOwnProperty('dateCreatedToDisplay')) {
        obj['dateCreatedToDisplay'] = ApiClient.convertToType(data['dateCreatedToDisplay'], 'String');
      }
      if (data.hasOwnProperty('dateRequired')) {
        obj['dateRequired'] = ApiClient.convertToType(data['dateRequired'], 'Date');
      }
      if (data.hasOwnProperty('dateRequiredToDisplay')) {
        obj['dateRequiredToDisplay'] = ApiClient.convertToType(data['dateRequiredToDisplay'], 'String');
      }
      if (data.hasOwnProperty('demographicArea')) {
        obj['demographicArea'] = ApiClient.convertToType(data['demographicArea'], 'String');
      }
      if (data.hasOwnProperty('header')) {
        obj['header'] = ApiClient.convertToType(data['header'], 'String');
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('imageUri')) {
        obj['imageUri'] = ApiClient.convertToType(data['imageUri'], ['String']);
      }
      if (data.hasOwnProperty('lastUpdatedDateTime')) {
        obj['lastUpdatedDateTime'] = ApiClient.convertToType(data['lastUpdatedDateTime'], 'Date');
      }
      if (data.hasOwnProperty('location')) {
        obj['location'] = ApiClient.convertToType(data['location'], 'String');
      }
      if (data.hasOwnProperty('longDescription')) {
        obj['longDescription'] = ApiClient.convertToType(data['longDescription'], 'String');
      }
      if (data.hasOwnProperty('longDescriptionText')) {
        obj['longDescriptionText'] = ApiClient.convertToType(data['longDescriptionText'], 'String');
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = ApiClient.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('pdfUrls')) {
        obj['pdfUrls'] = ApiClient.convertToType(data['pdfUrls'], ['String']);
      }
      if (data.hasOwnProperty('productList')) {
        obj['productList'] = ApiClient.convertToType(data['productList'], [ProductRequest]);
      }
      if (data.hasOwnProperty('productLogo')) {
        obj['productLogo'] = ApiClient.convertToType(data['productLogo'], 'String');
      }
      if (data.hasOwnProperty('productStatus')) {
        obj['productStatus'] = ApiClient.convertToType(data['productStatus'], 'String');
      }
      if (data.hasOwnProperty('quantity')) {
        obj['quantity'] = ApiClient.convertToType(data['quantity'], 'Number');
      }
      if (data.hasOwnProperty('shortDescription')) {
        obj['shortDescription'] = ApiClient.convertToType(data['shortDescription'], 'String');
      }
      if (data.hasOwnProperty('size')) {
        obj['size'] = ApiClient.convertToType(data['size'], 'String');
      }
      if (data.hasOwnProperty('type')) {
        obj['type'] = ApiClient.convertToType(data['type'], 'String');
      }
      if (data.hasOwnProperty('unitPrice')) {
        obj['unitPrice'] = ApiClient.convertToType(data['unitPrice'], 'Number');
      }
      if (data.hasOwnProperty('unitStock')) {
        obj['unitStock'] = ApiClient.convertToType(data['unitStock'], 'Number');
      }
      if (data.hasOwnProperty('userEmail')) {
        obj['userEmail'] = ApiClient.convertToType(data['userEmail'], 'String');
      }
      if (data.hasOwnProperty('userId')) {
        obj['userId'] = ApiClient.convertToType(data['userId'], 'String');
      }
    }
    return obj;
  }

  /**
   * @member {String} businessName
   */
  exports.prototype['businessName'] = undefined;
  /**
   * @member {String} businessSubType
   */
  exports.prototype['businessSubType'] = undefined;
  /**
   * @member {String} codeTag
   */
  exports.prototype['codeTag'] = undefined;
  /**
   * @member {String} companyType
   */
  exports.prototype['companyType'] = undefined;
  /**
   * @member {String} companyWebsite
   */
  exports.prototype['companyWebsite'] = undefined;
  /**
   * @member {Array.<String>} componentList
   */
  exports.prototype['componentList'] = undefined;
  /**
   * @member {String} componentNameToDisplay
   */
  exports.prototype['componentNameToDisplay'] = undefined;
  /**
   * @member {String} contactEmail
   */
  exports.prototype['contactEmail'] = undefined;
  /**
   * @member {String} contactName
   */
  exports.prototype['contactName'] = undefined;
  /**
   * @member {String} contactNumber
   */
  exports.prototype['contactNumber'] = undefined;
  /**
   * @member {String} csvFileUrl
   */
  exports.prototype['csvFileUrl'] = undefined;
  /**
   * @member {Date} dateCreated
   */
  exports.prototype['dateCreated'] = undefined;
  /**
   * @member {String} dateCreatedToDisplay
   */
  exports.prototype['dateCreatedToDisplay'] = undefined;
  /**
   * @member {Date} dateRequired
   */
  exports.prototype['dateRequired'] = undefined;
  /**
   * @member {String} dateRequiredToDisplay
   */
  exports.prototype['dateRequiredToDisplay'] = undefined;
  /**
   * @member {String} demographicArea
   */
  exports.prototype['demographicArea'] = undefined;
  /**
   * @member {String} header
   */
  exports.prototype['header'] = undefined;
  /**
   * @member {String} id
   */
  exports.prototype['id'] = undefined;
  /**
   * @member {Array.<String>} imageUri
   */
  exports.prototype['imageUri'] = undefined;
  /**
   * @member {Date} lastUpdatedDateTime
   */
  exports.prototype['lastUpdatedDateTime'] = undefined;
  /**
   * @member {String} location
   */
  exports.prototype['location'] = undefined;
  /**
   * @member {String} longDescription
   */
  exports.prototype['longDescription'] = undefined;
  /**
   * @member {String} longDescriptionText
   */
  exports.prototype['longDescriptionText'] = undefined;
  /**
   * @member {String} name
   */
  exports.prototype['name'] = undefined;
  /**
   * @member {Array.<String>} pdfUrls
   */
  exports.prototype['pdfUrls'] = undefined;
  /**
   * @member {Array.<module:model/ProductRequest>} productList
   */
  exports.prototype['productList'] = undefined;
  /**
   * @member {String} productLogo
   */
  exports.prototype['productLogo'] = undefined;
  /**
   * @member {String} productStatus
   */
  exports.prototype['productStatus'] = undefined;
  /**
   * @member {Number} quantity
   */
  exports.prototype['quantity'] = undefined;
  /**
   * @member {String} shortDescription
   */
  exports.prototype['shortDescription'] = undefined;
  /**
   * @member {String} size
   */
  exports.prototype['size'] = undefined;
  /**
   * @member {String} type
   */
  exports.prototype['type'] = undefined;
  /**
   * @member {Number} unitPrice
   */
  exports.prototype['unitPrice'] = undefined;
  /**
   * @member {Number} unitStock
   */
  exports.prototype['unitStock'] = undefined;
  /**
   * @member {String} userEmail
   */
  exports.prototype['userEmail'] = undefined;
  /**
   * @member {String} userId
   */
  exports.prototype['userId'] = undefined;



  return exports;
}));


