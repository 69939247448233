import { BaseViewModel } from "../../common/BaseViewModel";
import * as Format from "../../common/FormatFunctions";
import { CloudService } from "../../common/CloudService/ClientLibsReference";
import * as ApiService from "../../common/CloudService/ApiService";
import { CrudlStatus, ViewState } from "../../common/config/AppConstants";

export class CategoryViewModel extends BaseViewModel {
  getCategorys = async () => {
    //CRITICAL - ERROR + EMPTY MESSAGE
    var categoryResponse = {}; //await this.getCategoryListByCodeTag();
    if (
      Format.isNotNull(categoryResponse.errorCode) &&
      categoryResponse.errorCode === 0
    ) {
      if (Format.isNotNull(categoryResponse.categoryList)) {
        this.updateState("categoryList", categoryResponse.categoryList);
        this.logger.info(categoryResponse.categoryList);
      } else {
        this.showEmpty("Category", ViewState.TABLE);
      }
    } else {
      this.showError("Category", CrudlStatus.LIST, ViewState.TABLE);
    }
  };

  getCategorysReturned = async () => {
    //CRITICAL - ERROR + EMPTY MESSAGE
    var categoryResponse = {}; //await this.getCategoryListByCodeTag();
    if (
      Format.isNotNull(categoryResponse.errorCode) &&
      categoryResponse.errorCode === 0
    ) {
      if (Format.isNotNull(categoryResponse.categoryList)) {
        this.logger.info(categoryResponse.categoryList);
        return categoryResponse.categoryList;
      } else {
        this.showEmpty("Category", ViewState.TABLE);
      }
    } else {
      this.showError("Category", CrudlStatus.LIST, ViewState.TABLE);
    }
  };

  executeCRUDLAction = async (crudlStatus, stateVariables) => {
    let categoryRequest = CloudService.CategoryRequest.constructFromObject(
      stateVariables.categoryBean
    );
    if (crudlStatus === CrudlStatus.CREATE) {
      return await this.createCategory(categoryRequest, stateVariables);
    }
    if (crudlStatus === CrudlStatus.UPDATE) {
      return await this.updateCategory(categoryRequest, stateVariables);
    }
    if (crudlStatus === CrudlStatus.DELETE) {
      return await this.deleteCategory(categoryRequest);
    }
  };

  createCategory = async (categoryRequest, stateVariables) => {
    categoryRequest.codeTag = this.codeTag;
    categoryRequest.userId = this.userId;

    let categoryResponse = await ApiService.categoryCreate(categoryRequest);
    if (categoryResponse.errorCode === 0) {
      this.showSuccess(
        "Category",
        CrudlStatus.CREATE,
        stateVariables.viewState
      );
    } else {
      this.showError("Category", CrudlStatus.CREATE, ViewState.CARD);
    }

    return categoryResponse;
  };

  deleteCategory = async (categoryRequest) => {
    //RESET PRODUCT REQUEST
    var request = {};
    request.codeTag = this.codeTag;
    request.userId = this.userId;
    request.id = categoryRequest.id;
    request.lastUpdatedDateTime = categoryRequest.lastUpdatedDateTime;

    let categoryResponse = await ApiService.categoryDelete(request);
    if (categoryResponse.errorCode === 0) {
      this.showSuccess("Category", CrudlStatus.DELETE, ViewState.TABLE);
    } else {
      this.showError("Category", CrudlStatus.DELETE, ViewState.CARD);
    }

    return categoryResponse;
  };

  updateCategory = async (categoryRequest, stateVariables) => {
    categoryRequest.codeTag = this.codeTag;
    categoryRequest.userId = this.userId;

    let categoryResponse = await ApiService.categoryUpdate(categoryRequest);
    if (categoryResponse.errorCode === 0) {
      this.showSuccess(
        "Category",
        CrudlStatus.UPDATE,
        stateVariables.viewState
      );
    } else {
      this.showError("Category", CrudlStatus.UPDATE, ViewState.CARD);
    }

    return categoryResponse;
  };

  getComponents = async (codeTag) => {
    var componentResponse = await this.componentGetMatrixItemsByCodeTag(
      codeTag
    );
    if (
      Format.isNotNull(componentResponse.errorCode) &&
      componentResponse.errorCode === 0
    ) {
      if (Format.isNotNull(componentResponse.componentList)) {
        componentResponse.componentList.forEach((e) => {
          if (Format.isNotNull(e.matrixItemList)) {
            e.selectedOnCreate = true;
            e.score = e.matrixItemList[0].score;
            e.details = e.matrixItemList[0].otherComponent;
          } else {
            e.selectedOnCreate = false;
          }
        });
        let componentList = Format.deepCopy(componentResponse.componentList);
        let categoryList = Format.deepCopy(componentResponse.componentList);
        this.updateState("componentList", componentList);
        this.updateState("categoryList", categoryList);
        return componentResponse.componentList;
      } else {
        this.showEmpty("Component", ViewState.TABLE);
      }
    } else {
      this.showError("Component", CrudlStatus.LIST, ViewState.TABLE);
    }
  };

  createMatrixItem = async (matrixItemRequest, codeTag) => {
    matrixItemRequest.codeTag = this.codeTag;
    if (Format.isNotNull(codeTag)) {
      matrixItemRequest.codeTag = codeTag;
    }
    matrixItemRequest.userId = this.userId;

    matrixItemRequest.matrixItemList.forEach((e) => {
      e.codeTag = this.codeTag;
      if (Format.isNotNull(codeTag)) {
        e.codeTag = codeTag;
      }
      e.userId = this.userId;
    });

    let matrixItemResponse = await ApiService.matrixItemCreate(
      matrixItemRequest
    );
    if (matrixItemResponse.errorCode === 0) {
      this.showSuccess("Category", CrudlStatus.CREATE, ViewState.CARD);
    } else {
      this.showError("Category", CrudlStatus.CREATE, ViewState.CARD);
    }

    return matrixItemResponse;
  };

  updateMatrixItem = async (matrixItemRequest, codeTag) => {
    matrixItemRequest.codeTag = this.codeTag;
    if (Format.isNotNull(codeTag)) {
      matrixItemRequest.codeTag = codeTag;
    }
    matrixItemRequest.userId = this.userId;

    matrixItemRequest.matrixItemList.forEach((e) => {
      e.codeTag = this.codeTag;
      if (Format.isNotNull(codeTag)) {
        e.codeTag = codeTag;
      }
      e.userId = this.userId;
    });
    let matrixItemResponse = await ApiService.matrixItemUpdate(
      matrixItemRequest
    );
    if (matrixItemResponse.errorCode === 0) {
      this.showSuccess("Category", CrudlStatus.CREATE, ViewState.CARD);
    } else {
      this.showError("Category", CrudlStatus.CREATE, ViewState.CARD);
    }

    return matrixItemResponse;
  };

  deleteMatrixItem = async (matrixItemRequest, codeTag) => {
    matrixItemRequest.codeTag = this.codeTag;
    if (Format.isNotNull(codeTag)) {
      matrixItemRequest.codeTag = codeTag;
    }
    matrixItemRequest.userId = this.userId;

    matrixItemRequest.matrixItemList.forEach((e) => {
      e.codeTag = this.codeTag;
      if (Format.isNotNull(codeTag)) {
        e.codeTag = codeTag;
      }
      e.userId = this.userId;
    });

    let matrixItemResponse = await ApiService.matrixItemDelete(
      matrixItemRequest
    );
    if (matrixItemResponse.errorCode === 0) {
      this.showSuccess("Category", CrudlStatus.CREATE, ViewState.CARD);
    } else {
      this.showError("Category", CrudlStatus.CREATE, ViewState.CARD);
    }

    return matrixItemResponse;
  };

  getMyProfile = async (codeTag) => {
    let request = {};
    request.codeTag = codeTag;
    var profileResponse = await this.profileGetByCodeTag(request);
    if (
      Format.isNotNull(profileResponse.errorCode) &&
      profileResponse.errorCode === 0
    ) {
      if (Format.isNotNull(profileResponse.profileList)) {
        this.updateState("editedProfile", profileResponse.profileList[0]);
        return profileResponse.profileList[0];
      } else {
        this.logger.info("Profile is empty");
      }
    } else {
      this.logger.info("Profile api failed");
    }
  };

  updateProfile = async (request) => {
    var profileResponse = await ApiService.profileUpdate(request);
    if (
      Format.isNotNull(profileResponse.errorCode) &&
      profileResponse.errorCode === 0
    ) {
      // this.showSuccess("Profile", CrudlStatus.CREATE, ViewState.CARD);
    } else {
      this.logger.info("Profile update failed");
    }
  };
}
