import React from "react";
//PrimeReact Components
import * as pr from "../../../common/PrimeComponents";
import {
  ApplicationStatus,
  CrudlStatus,
  PageState,
  ViewType,
} from "../../../common/config/AppConstants";
import * as EnumDropdowns from "../../../common/config/EnumDropdowns";
import * as format from "../../../common/FormatFunctions";
import {
  Input,
  InputType,
  LabelPosition,
  UpdateType,
} from "../../Common/Input";
import { ApplicationCard } from "./ApplicationCard";

export class ApplicationTable extends React.Component {
  export = () => {
    this.dt.exportCSV();
  };

  getApplicationColumns = () => {
    var allColumns = [];
    allColumns.push(
      <pr.Column
        key="expander"
        expander={true}
        style={{ width: "5em", float: "middle" }}
      />
    );
    if (format.isNotNull(this.props.tableColumns)) {
      let columns = this.props.tableColumns.map((col, i) => {
        return (
          <pr.Column
            key={col.field}
            field={col.field}
            header={col.header}
            sortable={col.sortable}
            filter={col.filter}
            filterMatchMode={col.filterMatchMode}
            style={col.style}
          />
        );
      });

      columns.forEach((e) => {
        allColumns.push(e);
      });

      if (this.props.viewType !== ViewType.COUNCIL) {
        allColumns.push(
          <pr.Column header="Action" body={this.getApplicatonTemplate} />
        );
      }
    }
    return allColumns;
  };

  render() {
    let toolTip = false;
    let toolTipLabel = "";
    if (!format.isNotNull(this.props.productList)) {
      toolTip = true;
      toolTipLabel =
        toolTipLabel + "Before creating contacts you must add a Product";
    }
    if (!format.isNotNull(this.props.profileList)) {
      toolTip = true;
      toolTipLabel = "You have no businesses available to contact";
    }
    return (
      <div className="p-grid bpm">
        <div className="p-col-12">
          <div className="p-col-12 p-lg-3">
            <div className="content-section">
              <div className="feature-intro">
                <h1>{"Enquiries"}</h1>
              </div>
            </div>
          </div>
        </div>

        {this.props.viewType === ViewType.COUNCIL && (
          <div className="p-col-12 card">
            <div className="p-grid">
              <div className="p-col-4">
                <center>
                  <Input
                    id={"applicationType"}
                    labelDisplay={"Applications made by:"}
                    labelDisplayPosition={LabelPosition.TOP}
                    required={false}
                    type={InputType.DROPDOWN}
                    dropdownOptions={EnumDropdowns.ApplicationTypeOptions}
                    disabled={false}
                    value={this.props.applicationType}
                    onChangeType={UpdateType.STANDARD}
                    onChange={this.props.updateState}
                    field={"applicationType"}
                    visible={true}
                    style={{ backgroundColor: "#dae4f1" }}
                  />
                </center>
              </div>
              <div className="p-col-4">
                <center>
                  <Input
                    id={"searchArchived"}
                    labelDisplay={"View Archived"}
                    labelDisplayPosition={LabelPosition.TOP}
                    required={false}
                    type={InputType.CHECKBOX}
                    disabled={false}
                    value={this.props.searchArchived}
                    onChangeType={UpdateType.STANDARD}
                    onChange={this.props.updateState}
                    field={"searchArchived"}
                    visible={true}
                    style={{ backgroundColor: "#dae4f1" }}
                    displayOptions={{ label: 6, element: 6, elementRem: 0 }}
                  />
                </center>
              </div>
              <div className="p-col-4">
                <div className="p-grid">
                  <div className="p-col-12"></div>
                  <div className="p-col-12">
                    <center>
                      <pr.Button
                        // className="flt-r"
                        label="Search"
                        onClick={async () => {
                          await this.props.getApplicationsCouncil();
                          this.props.updateState("pageState", PageState.IDLE);
                        }}
                      />
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="p-col-12 "></div>
        <div className="p-col-12 p-md-3"></div>
        <div className="p-col-12 p-md-6">
          <Input
            id={"globalFilter"}
            labelDisplay={"Type here to search entire table"}
            labelDisplayPosition={LabelPosition.NONE}
            required={false}
            type={InputType.STRING}
            disabled={false}
            value={this.props.globalFilter}
            onChangeType={UpdateType.STANDARD}
            onChange={this.props.updateState}
            field={"globalFilter"}
            visible={true}
            style={{ backgroundColor: "#dae4f1" }}
          />
        </div>

        <div className="p-col-12">
          <pr.DataTable
            ref={(el) => {
              this.dt = el;
            }}
            value={this.props.applicationList}
            selectionMode="single"
            // onSelectionChange={(e) => {
            //   this.props.crudlControl(CrudlStatus.UPDATE, e.value);
            // }}
            header={
              <div className="p-grid">
                {this.props.viewType !== ViewType.COUNCIL && (
                  <div className="p-col-12">
                    <pr.Button
                      className="table-button"
                      label={"Contact Multiple"}
                      icon="pi-md-add"
                      style={{
                        float: "right",
                      }}
                      onClick={() => {
                        if (toolTip === false) {
                          this.props.crudlControl(CrudlStatus.CREATE);
                        }
                      }}
                      tooltip={toolTipLabel}
                      tooltipOptions={{
                        position: "bottom",
                        mouseTrack: true,
                        mouseTrackTop: 15,
                      }}
                    />
                    <pr.Button
                      className="table-button"
                      type="button"
                      style={{
                        float: "right",
                      }}
                      label={"Refresh"}
                      icon={
                        this.props.loading === true
                          ? "pi-spin pi-md-autorenew"
                          : "pi-md-autorenew"
                      }
                      onClick={this.props.refresh}
                    />
                  </div>
                )}
              </div>
            }
            emptyMessage="No Applications"
            expandedRows={this.props.expandedRows}
            onRowToggle={(e) => this.props.updateState("expandedRows", e.data)}
            rowExpansionTemplate={this.getDisplayCard}
            rows={Infinity}
            autoLayout={true}
            paginator={false}
            alwaysShowPaginator={false}
            responsive={true}
            loading={this.props.loading}
            globalFilter={this.props.globalFilter}
          >
            {this.getApplicationColumns()}
          </pr.DataTable>
        </div>
      </div>
    );
  }

  getApplicatonTemplate = (rowData, column) => {
    if (this.props.viewType === ViewType.COUNCIL) {
    } else {
      if (rowData.processType === "Received") {
        this.getReceived(rowData);
      } else {
        this.getSent(rowData);
      }
    }
  };

  getReceived = (rowData) => {
    return (
      <div className="p-grid">
        <div className="p-col-12">
          <pr.Button
            type="button"
            label="Update"
            onClick={() => this.props.updateState("showDialogUpdate", true)}
          />

          <pr.Dialog
            header="Update Application"
            footer={
              <div>
                <pr.Button
                  disabled={
                    rowData.status === ApplicationStatus.ACCEPTED &&
                    (!rowData.feedbackType || !rowData.feedback)
                  }
                  className="secondary-btn"
                  label="Update"
                  icon="pi pi-check"
                  onClick={() => {
                    this.setState({ showDialogUpdate: false }, () => {
                      this.props.updateFromTable(rowData);
                    });
                  }}
                  style={{
                    float: "right",
                  }}
                />
                <pr.Button
                  className="secondary-btn"
                  label="Cancel"
                  icon="pi pi-times"
                  onClick={() => {
                    this.props.updateState("showDialogUpdate", false);
                  }}
                  style={{
                    float: "right",
                  }}
                />
              </div>
            }
            visible={this.props.showDialogUpdate}
            onHide={() => this.props.updateState("showDialogUpdate", false)}
            modal={true}
            blockScroll={true}
            style={{ width: "80vw" }}
          >
            <div className="p-col-12 card">
              <Input
                id={"status"}
                labelDisplay={"Status"}
                labelDisplayPosition={LabelPosition.LEFT}
                required={true}
                type={InputType.DROPDOWN}
                dropdownOptions={EnumDropdowns.ApplicationStatusOptions}
                disabled={this.props.crudlState === CrudlStatus.VIEW}
                value={rowData.status}
                onChangeType={UpdateType.CUSTOM}
                onChange={(e) => {
                  this.props.updateValueTableRowInList(
                    e.target.id,
                    e.target.value,
                    rowData
                  );
                }}
                field={"status"}
              />
              <Input
                id={"feedbackType"}
                labelDisplay={"Reason"}
                labelDisplayPosition={LabelPosition.LEFT}
                required={true}
                type={InputType.DROPDOWN}
                dropdownOptions={EnumDropdowns.ApplicationFeedbackOptions}
                disabled={this.props.crudlState === CrudlStatus.VIEW}
                value={rowData.feedbackType}
                onChangeType={UpdateType.CUSTOM}
                onChange={(e) => {
                  this.props.updateValueTableRowInList(
                    e.target.id,
                    e.target.value,
                    rowData
                  );
                }}
                field={"feedbackType"}
              />

              <Input
                id={"feedback"}
                labelDisplay={"Feedback"}
                labelDisplayPosition={LabelPosition.TOP}
                required={this.props.crudlState !== CrudlStatus.VIEW}
                type={InputType.NOTES}
                disabled={false}
                value={rowData.feedback}
                onChangeType={UpdateType.CUSTOM}
                onChange={(e) => {
                  console.log(e);
                  this.props.updateValueTableRowInList(
                    "feedback",
                    e.htmlValue,
                    rowData
                  );
                }}
                field={"feedback"}
                visible={true}
                style={{ height: "fit-content" }}
              />
            </div>
          </pr.Dialog>
        </div>
      </div>
    );
  };

  getSent = (rowData) => {
    if (
      rowData.status === ApplicationStatus.IN_PROGRESS ||
      rowData.status === ApplicationStatus.SUBMITTED ||
      rowData.status === ApplicationStatus.UNSUCCESSFUL ||
      rowData.status === ApplicationStatus.SEEN
    ) {
      let feedbackAttemptCount = 0;
      let feedbackAttemptMax = 3; // default is 3
      let label = "Request Feedback";
      let isFeedbackReqOutsideOfSpamTime = true;

      if (format.isNotNull(rowData.feedbackAttemptCount)) {
        feedbackAttemptCount = rowData.feedbackAttemptCount;
        label = label + "(" + rowData.feedbackAttemptCount + ")";
      } else {
        label = label + "(0)";
      }
      if (format.isNotNull(rowData.feedbackAttemptMax)) {
        feedbackAttemptMax = rowData.feedbackAttemptMax;
      }

      let newDate = new Date();
      let betweenTwoDates = false;
      if (format.isNotNull(rowData.lastFeedbackRequestDate)) {
        if (
          rowData.feedbackAttemptCount === 0 ||
          !format.isNotNull(rowData.feedbackAttemptCount)
        ) {
          newDate = format.addDaysToDate(rowData.lastFeedbackRequestDate, 2);
        }
        if (rowData.feedbackAttemptCount === 1) {
          newDate = format.addDaysToDate(rowData.lastFeedbackRequestDate, 4);
        }
        if (rowData.feedbackAttemptCount === 2) {
          newDate = format.addDaysToDate(rowData.lastFeedbackRequestDate, 6);
        }

        betweenTwoDates = format.isTodayWithinDates(
          rowData.lastFeedbackRequestDate,
          newDate
        );

        if (betweenTwoDates) {
          isFeedbackReqOutsideOfSpamTime = false;
        }
      }

      let disabled = false;
      if (isFeedbackReqOutsideOfSpamTime === false) {
        disabled = true;
      }
      if (feedbackAttemptCount >= feedbackAttemptMax) {
        disabled = true;
      }

      return (
        <pr.Button
          type="button"
          label={label}
          disabled={disabled}
          onClick={() => this.props.requestFeedback(rowData)}
        />
      );
    }
  };

  getDisplayCard = (data) => {
    return (
      <div className="card">
        <ApplicationCard
          editedObject={data}
          crudlState={CrudlStatus.VIEW}
          updateValue={this.props.updateValueTableRowInList}
          viewType={this.props.viewType}
          archiveApplication={this.props.archiveApplication}
          toggleUpdateApplication={this.props.toggleUpdateApplication}
          deleteApplication={this.props.deleteApplication}
        />
      </div>
    );
  };
}
