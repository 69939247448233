import React from "react";
import { SupplyCard } from "./UI/SupplyCard";
import { SupplyTable } from "./UI/SupplyTable";
import { ValidateState, CrudlStatus } from "../../common/config/AppConstants";
import { ViewState } from "../../common/config/AppConstants";
import * as pr from "../../common/PrimeComponents";

import { MVVMValidateDialog } from "../Common/MVVMValidateDialog";
export class SupplyView extends React.Component {
  render() {
    return (
      <div
        className="dashboard"
        style={this.props.loading === true ? { pointerEvents: "none" } : {}}
      >
        <SupplyTable
          //CUSTOMISATION
          tableReference={this.props.tableReference}
          tableColumns={this.props.tableColumns}
          columnOptions={this.props.columnOptions}
          columnToggle={this.props.columnToggle}
          expandedRows={this.props.expandedRows}
          rowCount={this.props.rowCount}
          settingsToggle={this.props.settingsToggle}
          //
          updateState={this.props.updateState}
          supplyList={this.props.supplyList}
          crudlControl={this.props.crudlControl}
          validateState={this.props.validateState}
          menuState={this.props.menuState}
          crudlState={this.props.crudlState}
          loading={this.props.loading}
          refresh={this.props.refresh}
          productDetails={this.props.productDetails}
          productDetailsView={this.props.productDetailsView}
        />

        {this.props.viewState === ViewState.CARD && !this.props.loading && (
          <React.Fragment>
            <MVVMValidateDialog
              header="Supply"
              isVisible={this.props.validateState === ValidateState.CONFIRM}
              visibleField="validateState"
              updateVisibility={this.props.updateState}
              runConfirm={this.props.discardChanges}
              message={"Discard Changes?"}
            />
            <pr.Dialog
              header={"Delete Supply"}
              footer={
                <div className="ui-g">
                  <div className="ui-g-12">
                    <pr.Button
                      className="blk mrgn flt-r"
                      label="Yes"
                      onClick={() => {
                        this.props.crudlExecute(this.props.crudlStatus);
                      }}
                    />
                    <pr.Button
                      className="blk mrgn flt-r"
                      label="No"
                      onClick={() => {
                        this.props.updateState(
                          "validateState",
                          ValidateState.NONE
                        );
                        this.props.crudlControl(CrudlStatus.CREATE);
                      }}
                    />
                  </div>
                </div>
              }
              visible={this.props.validateState === ValidateState.DELETE}
              onHide={() => {
                this.props.updateState("validateState", ValidateState.NONE);
                this.props.crudlControl(CrudlStatus.CREATE);
              }}
              modal={true}
            >
              {" Are you sure you want to delete?"}
            </pr.Dialog>

            <SupplyCard
              loading={this.props.loading}
              feedback={this.props.feedback}
              receivedFeedback={this.props.receivedFeedback}
              crudlState={this.props.crudlState}
              crudlControl={this.props.crudlControl}
              updateValue={this.props.updateEdited}
              updateState={this.props.updateState}
              editedObject={this.props.editedObject}
              checkRequiredFields={this.props.checkRequiredFields}
              discardChanges={this.props.discardChanges}
              validateState={this.props.validateState}
              validateChanges={this.props.validateChanges}
              crudlExecute={this.props.crudlExecute}
              componentList={this.props.componentList}
            />
          </React.Fragment>
        )}
      </div>
    );
  }
}
