import React, { Component } from "react";
//PrimeReact Components
import * as pr from "../../common/PrimeComponents";

export class Status extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const p = this.props;

    return (
      <div>
        <div className="login-page" id="login-council">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card login-panel ui-fluid">
                <pr.Messages
                  style={{ textAlign: "left" }}
                  ref={(el) => (this.growl = el)}
                />
                <div className="p-grid">
                  <div className="p-col-12">
                    <img
                      src="/assets/layout/images/council/logo.png"
                      alt=""
                      height="100%"
                      width="100%"
                    />
                  </div>
                  <div
                    className="p-col-12"
                    style={{
                      paddingTop: "0",
                      paddingBottom: "0",
                      fontSize: "18px",
                      lineHeight: "30px",
                    }}
                  >
                    {p.message}
                  </div>
                  <div className="p-col-12">
                    {!this.props.noReturn && (
                      <pr.Button
                        icon="pi pi-arrow-left"
                        label="Return"
                        onClick={() => {
                          let location = "#/";
                          if (
                            localStorage.getItem("roleType") === "10" ||
                            localStorage.getItem("roleType") === "20"
                          ) {
                            location = "#/Council_Login";
                          } else if (
                            localStorage.getItem("roleType") === "30"
                          ) {
                            location = "#/login";
                          } else if (
                            localStorage.getItem("roleType") === "40"
                          ) {
                            location = "#/login";
                          }

                          if (this.props.ppe) {
                            location = "#/login-covid-19-supplies";
                          }
                          localStorage.clear();
                          window.location = location;
                          window.location.reload();
                        }}
                        style={{ marginTop: "3.5px", marginBottom: "3.5px" }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src="/assets/layout/images/council/logo-white.png"
          alt=""
          style={{
            position: "absolute",
            right: "1em",
            bottom: "1em",
            width: "6em",
            height: "2em",
            zIndex: "1",
          }}
        />
      </div>
    );
  }
}
