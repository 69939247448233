import React from "react";
import { ProductView } from "./ProductView";
import {
  CrudlStatus,
  ToggleState,
  PageState,
  FeedbackState,
  ViewType,
  ProductType,
  ViewState,
  ValidateState,
  BusinessType,
  ProductStatus,
} from "../../common/config/AppConstants";
import * as pr from "../../common/PrimeComponents";
import * as format from "../../common/FormatFunctions";
import { ErrorScreen } from "../Common/ErrorScreen";
import * as analytics from "../../common/helpers/firebaseAnalytics";

export class ProductController extends React.Component {
  state = {
    pageName: "ProductController",
    pageState: PageState.IDLE,
    sidebarWidth: "100%",

    //DISPLAY STATES
    viewState: ViewState.TABLE,
    validateState: ValidateState.NONE,
    crudlState: CrudlStatus.LIST,
    menuState: [],

    // PROPS
    viewType: "STANDARD", // e.g. ViewType.STANDARD

    // DATA TABLE
    expandedRows: null,
    tableColumns: [],
    columnOptions: [],
    settingsToggle: ToggleState.OFF,

    // DETAILS
    productList: null,
    originalEditedProduct: "",
    editedProduct: null,
    receivedFeedback: {
      state: FeedbackState.NONE,
      display: "Product",
      crudlStatus: CrudlStatus.LIST,
    },
    componentOptions: [],
    codeTag: null, // used if finding products for a specific business passed via props
    batchUpload: false,
    displayBatchSummary: false,
    batchList: [],
    // productOptions: [{ label: "", value: "" }],
  };

  componentDidMount = async () => {
    await this.initialDataRender();
  };

  componentDidCatch = (error, info) => {
    this.props.log("This threw an error");
    this.props.log(error);
    this.props.log(info);
    //COMPONENT ISSUE CHECK IF THIS ERROR HANDLING IS CORRECT
    this.setError(false, "componentDidCatch", error);
  };

  initialDataRender = async () => {
    this.setViewModel();
    await this.setPageData();
  };

  setError = (critical, methodName, error) => {
    this.props.log.error(error.message);
    if (critical) {
      this.setCriticalError();
    } else {
      this.setStandardError();
    }
  };
  setStandardError = () => {
    //MORE CAN BE ADDED HERE AS APPROPRIATE
    this.showError("Product");
    this.setState({ pageState: PageState.IDLE });
  };
  setCriticalError = () => {
    //SET ERROR STATE OF PAGE
    this.setState({ pageState: PageState.ERROR });
  };

  setViewModel = () => {
    try {
      this.props.viewModel.updateState = this.updateState;
      this.props.viewModel.showError = this.showError;
      this.props.viewModel.showSuccess = this.showSuccess;
      this.props.viewModel.showEmpty = this.showEmpty;
    } catch (error) {
      this.setError(false, "setViewModel", error);
    }
  };

  setPageData = async () => {
    try {
      this.setState({
        pageState: PageState.LOADING,
        batchList: [this.batchListEmptyRow(1)],
      });

      this.menuState(CrudlStatus.LIST);

      await this.handleProps();
      this.setUpColumns();

      let businessComponentList = [];
      let componentList = [];
      if (this.props.viewType !== ViewType.PROFILE) {
        componentList = await this.props.viewModel.getComponents();
        businessComponentList = await this.props.viewModel.getProfile(
          this.state.codeTag
        );

        let availableList = [];
        if (format.isNotNull(businessComponentList)) {
          businessComponentList.forEach((e) => {
            if (format.isNotNull(componentList)) {
              componentList.forEach((f) => {
                if (e === f.id) {
                  availableList.push(f);
                }
              });
            }
          });
        }
        console.log("Available List", availableList);
        this.updateState(
          "componentOptions",
          // []
          pr.getDropdownOptions(availableList)
        );
      }

      // SPECIFIC API CALLS GO HERE
      if (this.props.viewType === ViewType.MY_PRODUCT) {
        await this.props.viewModel.getProducts(
          ProductType.SUPPLIER,
          this.state.codeTag
        );
      } else if (this.props.viewType === ViewType.MY_REQUIREMENT) {
        await this.props.viewModel.getProducts(
          ProductType.BUYER,
          this.state.codeTag
        );
      } else if (this.props.viewType === ViewType.PROFILE) {
        await this.props.viewModel.getProductsByCodeTag(this.state.codeTag);
      } else if (this.props.viewType === ViewType.BUYER) {
        await this.props.viewModel.getProductsByType(ProductType.BUYER);
      } else if (this.props.viewType === ViewType.SUPPLIER) {
        await this.props.viewModel.getProductsByType(ProductType.SUPPLIER);
      } else if (this.props.viewType === ViewType.DASHBOARD) {
        await this.props.viewModel.getProductsByComponentList(
          businessComponentList,
          this.state.dashboardType
        );
      }

      this.setState({
        pageState: PageState.IDLE,
      });
    } catch (error) {
      this.setError(true, "setPageData", error);
    }
  };
  handleProps = async () => {
    if (format.isNotNull(this.props.viewType)) {
      await this.setState({ viewType: this.props.viewType });
    }
    if (format.isNotNull(this.props.sidebarWidth)) {
      await this.setState({ sidebarWidth: this.props.sidebarWidth });
    }
    if (format.isNotNull(this.props.crudlState)) {
      await this.setState({ crudlState: this.props.crudlState });
      // Add Conditions per viewType if applicable
      if (this.props.crudlState === CrudlStatus.CREATE) {
        this.executeSetup(this.props.crudlState);
      } else if (this.props.crudlState === CrudlStatus.UPDATE) {
        this.executeSetup(
          this.props.crudlState,
          this.props.editedObjectFromProps
        );
      }
    }
    if (format.isNotNull(this.props.codeTag)) {
      await this.setState({ codeTag: this.props.codeTag });
    }
    // if (format.isNotNull(this.props.globalFilter)) {
    //   await this.setState({ globalFilter: this.props.globalFilter });
    // }
    if (format.isNotNull(this.props.dashboardType)) {
      await this.setState({ dashboardType: this.props.dashboardType });
    }
  };
  showError = (display, crudlStatus, whereToView) => {
    try {
      if (whereToView === ViewState.TABLE) {
        this.props.feedback.showError(this.growl, display, crudlStatus);
      }
      if (whereToView === ViewState.CARD) {
        this.setState({
          receivedFeedback: {
            state: FeedbackState.ERROR,
            display: display,
            crudlStatus: crudlStatus,
          },
        });
      }
    } catch (error) {
      this.setError(false, "showError", error);
    }
  };

  showSuccess = (display, crudlStatus, whereToView) => {
    try {
      if (whereToView === ViewState.TABLE) {
        this.props.feedback.showSuccess(this.growl, display, crudlStatus);
      }
      if (whereToView === ViewState.CARD) {
        this.setState({
          receivedFeedback: {
            state: FeedbackState.SUCCESS,
            display: display,
            crudlStatus: crudlStatus,
          },
        });
      }
    } catch (error) {
      this.setError(false, "showSuccess", error);
    }
  };

  showEmpty = (display, whereToView) => {
    try {
      if (whereToView === ViewState.TABLE) {
        this.props.feedback.showEmpty(this.growl, display);
      }
      if (whereToView === ViewState.CARD) {
        this.setState({
          receivedFeedback: {
            state: FeedbackState.EMPTY,
            display: display,
            crudlStatus: CrudlStatus.LIST,
          },
        });
      }
    } catch (error) {
      this.setError(false, "showEmpty", error);
    }
  };

  getStateValuesAsObject = () => {
    try {
      var externalObject = {};
      externalObject.productBean = this.state.editedProduct;
      externalObject.viewType = this.state.viewType;
      if (format.isNotNull(this.state.codeTag)) {
        externalObject.productBean.codeTag = this.state.codeTag;
      }

      return externalObject;
    } catch (error) {
      this.setError(false, "getStateValuesAsObject", error);
    }
  };

  updateBean = async (field, value) => {
    try {
      this.props.log.info(
        "Updating : [" + field + "] with value [" + value + "]"
      );
      await this.setState((prevState) => ({
        editedProduct: {
          // object that we want to update
          ...prevState.editedProduct, // keep all other key-value pairs
          [field]: value,
        },
      }));
    } catch (error) {
      this.setError(false, "updateBean", error);
    }
  };

  updateState = (key, value) => {
    try {
      this.setState({ [key]: value });
    } catch (error) {
      this.setError(false, "updateState", error);
    }
  };

  validateChanges = async () => {
    try {
      if (this.state.editedProduct === null) {
        await this.discardChanges();
      } else {
        if (
          format.isJsonEqual(
            this.state.originalEditedProduct,
            this.state.editedProduct
          )
        ) {
          await this.discardChanges();
        } else {
          this.setState({ validateState: ValidateState.CONFIRM });
        }
      }
    } catch (error) {
      this.setError(false, "validateChanges", error);
    }
  };

  validateChangesBatch = async () => {
    try {
      if (
        format.isNotNull(this.state.batchList) &&
        this.state.batchList.length <= 0
      ) {
        await this.discardChangesBatch();
      } else {
        this.setState({ validateState: ValidateState.CONFIRM });
      }
    } catch (error) {
      this.setError(false, "validateChangesBatch", error);
    }
  };

  discardChanges = async () => {
    try {
      this.setState({
        viewState: ViewState.TABLE,
        toggleChangesMade: false,
        crudlState: CrudlStatus.LIST,
        selectedProduct: null,
      });

      // TODO use viewType constant from AppConstants
      if (this.props.viewType !== "STANDARD") {
        if (format.isFunction(this.props.updateStateFromProps)) {
          // Toggle any relevant Views for create or update pages
          // this.props.updateStateFromProps("", false);
        }
      }

      await this.setPageData();
      this.menuState(CrudlStatus.LIST);
    } catch (error) {
      this.setError(false, "discardChanges", error);
    }
  };

  discardChangesBatch = async () => {
    try {
      this.setState({
        toggleChangesMade: false,
        batchUpload: false,
        displayBatchSummary: false,
        batchList: [this.batchListEmptyRow(1)],
      });

      this.menuState(CrudlStatus.LIST);
    } catch (error) {
      this.setError(false, "discardChangesBatch", error);
    }
  };

  checkRequiredFields = () => {
    try {
      let fieldList = [];
      if (format.isNotNull(this.state.editedProduct)) {
        if (this.state.viewType === ViewType.MY_PRODUCT) {
          fieldList.push(this.state.editedProduct.name);
          // fieldList.push(this.state.editedProduct.productLogo);
          // fieldList.push(this.state.editedProduct.shortDescription);
          // fieldList.push(this.state.editedProduct.longDescription);
          fieldList.push(this.state.editedProduct.componentList);
          fieldList.push(this.state.editedProduct.productStatus);
        }

        if (this.state.viewType === ViewType.MY_REQUIREMENT) {
          fieldList.push(this.state.editedProduct.name);
          fieldList.push(this.state.editedProduct.componentList);
          fieldList.push(this.state.editedProduct.productStatus);
          fieldList.push(this.state.editedProduct.dateRequired);
          fieldList.push(this.state.editedProduct.longDescription);
        }

        return format.validateValues(fieldList);
      } else {
        return true;
      }
    } catch (error) {
      this.setError(false, "checkRequiredFields", error);
    }
  };

  checkRequiredFieldsBatch = () => {
    try {
      if (format.isNotNull(this.state.batchList)) {
        // ADD REQUIREDD FIELDS HERE AS APPROPRIATE
        var disabled = false;
        this.state.batchList.forEach((e) => {
          if (format.isNotNull(e.empty) && e.empty === true) {
            // default empty row
            if (0 === this.state.batchList.length - 1) {
              disabled = true;
            }
            return;
          }
          var fieldList = [];

          if (this.state.viewType === ViewType.MY_PRODUCT) {
            fieldList.push(e.name);
            // fieldList.push(e.productLogo);
            // fieldList.push(e.shortDescription);
            // fieldList.push(e.componentList);
            fieldList.push(e.productStatus);
          }

          if (this.state.viewType === ViewType.MY_REQUIREMENT) {
            fieldList.push(e.name);
            fieldList.push(e.componentList);
            fieldList.push(e.productStatus);
            fieldList.push(e.dateRequired);
            fieldList.push(e.longDescription);
          }

          var validateBool = format.validateValues(fieldList);
          if (validateBool === true) {
            disabled = true;
          }
        });
        return disabled;
      } else {
        return true;
      }
    } catch (error) {
      this.setError(false, "checkRequiredFieldsBatch", error);
    }
  };

  menuState = (crudlStatus) => {
    try {
      this.setState({
        menuState: pr.getBreadcrumbMenuState(
          crudlStatus,
          "Product",
          "#/products"
        ),
      });
    } catch (error) {
      this.setError(false, "menuState", error);
    }
  };

  executeCrudl = async (stayOnPage) => {
    try {
      if (format.isNotNull(this.state.crudlState)) {
        this.props.log.info(
          "Executing " +
            this.state.crudlState.value +
            " Staying[" +
            stayOnPage +
            "]"
        );

        switch (this.state.crudlState) {
          case CrudlStatus.CREATE:
            await this.addProduct(stayOnPage);
            break;
          case CrudlStatus.UPDATE:
            await this.updateProduct(stayOnPage);
            break;
          case CrudlStatus.DELETE:
            await this.deleteProduct();
            break;
          default:
            break;
        }
      }
    } catch (error) {
      this.setError(false, "executeCrudl", error);
    }
  };

  executeSetup = async (requiredCrudlState, data) => {
    try {
      if (format.isNotNull(requiredCrudlState)) {
        this.props.log.info("Setting up " + requiredCrudlState.value);
        switch (requiredCrudlState) {
          case CrudlStatus.CREATE:
            await this.createSetup();
            break;
          case CrudlStatus.UPDATE:
            await this.updateSetup(data);
            break;
          case CrudlStatus.VIEW:
            await this.viewSetup(data);
            break;
          case CrudlStatus.DELETE:
            await this.deleteSetup(data);
            break;
          default:
            break;
        }
      }
    } catch (error) {
      this.setError(false, "executeSetup", error);
    }
  };

  setUpColumns = () => {
    let localCols = [];
    //SPECIFIC COLUMNS FOR TABLE
    if (this.state.viewType === ViewType.MY_PRODUCT) {
      localCols.push(pr.getTableColumn("name", "Name"));
      localCols.push(pr.getTableColumn("componentNameToDisplay", "Type"));
      localCols.push(pr.getTableColumn("location", "Location"));
      localCols.push(pr.getTableColumn("productStatus", "Status"));
    }
    if (this.state.viewType === ViewType.MY_REQUIREMENT) {
      localCols.push(pr.getTableColumn("name", "Name"));
      localCols.push(pr.getTableColumn("componentNameToDisplay", "Type"));
      localCols.push(
        pr.getTableColumn("dateRequiredToDisplay", "Date Required")
      );
      localCols.push(pr.getTableColumn("productStatus", "Status"));
    }
    if (this.state.viewType === ViewType.PROFILE) {
      localCols.push(pr.getTableColumn("name", "Name"));
      localCols.push(pr.getTableColumn("componentNameToDisplay", "Category"));
      localCols.push(pr.getTableColumn("location", "Location"));
      localCols.push(
        pr.getTableColumn("dateRequiredToDisplay", "Date Required")
      );
      localCols.push(pr.getTableColumn("productStatus", "Status"));
    }
    if (this.state.viewType === ViewType.BUYER) {
      localCols.push(pr.getTableColumn("name", "Name"));
      localCols.push(pr.getTableColumn("componentNameToDisplay", "Type"));
      localCols.push(pr.getTableColumn("businessName", "Business"));
      localCols.push(
        pr.getTableColumn("dateRequiredToDisplay", "Date Required")
      );
      localCols.push(pr.getTableColumn("productStatus", "Status"));
    }
    if (this.state.viewType === ViewType.SUPPLIER) {
      localCols.push(pr.getTableColumn("name", "Name"));
      localCols.push(pr.getTableColumn("componentNameToDisplay", "Type"));
      localCols.push(pr.getTableColumn("businessName", "Business"));
      localCols.push(pr.getTableColumn("location", "Location"));
      localCols.push(pr.getTableColumn("productStatus", "Status"));
    }

    if (this.state.viewType === ViewType.DASHBOARD) {
      if (this.state.dashboardType === BusinessType.BUYER) {
        localCols.push(pr.getTableColumn("name", "Name"));
        localCols.push(pr.getTableColumn("componentNameToDisplay", "Type"));
        localCols.push(pr.getTableColumn("businessName", "Business"));
        localCols.push(
          pr.getTableColumn("dateRequiredToDisplay", "Date Required")
        );
        localCols.push(pr.getTableColumn("productStatus", "Status"));
      }
      if (this.state.dashboardType === BusinessType.SUPPLIER) {
        localCols.push(pr.getTableColumn("name", "Name"));
        localCols.push(pr.getTableColumn("componentNameToDisplay", "Type"));
        localCols.push(pr.getTableColumn("businessName", "Business"));
        localCols.push(pr.getTableColumn("location", "Location"));
        localCols.push(pr.getTableColumn("productStatus", "Status"));
      }
    }

    this.setState({ tableColumns: localCols });
    var colOptions = pr.getColumnOptions(localCols);
    this.setState({ columnOptions: colOptions });
  };

  columnToggle = (event) => {
    this.setState({ tableColumns: event.value });
  };

  createSetup = async () => {
    window.scrollTo(0, 0);

    var data = {};
    data = await this.additionalParseFunctions(data, CrudlStatus.CREATE);

    this.setState({
      viewState: ViewState.CARD,
      crudlState: CrudlStatus.CREATE,
      editedProduct: data,
      originalEditedProduct: JSON.stringify(data),
    });

    this.menuState(CrudlStatus.CREATE);
  };

  updateSetup = async (data) => {
    window.scrollTo(0, 0);

    data = await this.additionalParseFunctions(data, CrudlStatus.UPDATE);

    this.setState({
      viewState: ViewState.CARD,
      crudlState: CrudlStatus.UPDATE,
      editedProduct: data,
      originalEditedProduct: JSON.stringify(data),
    });

    this.menuState(CrudlStatus.UPDATE);
  };

  viewSetup = async (data) => {
    window.scrollTo(0, 0);

    data = await this.additionalParseFunctions(data, CrudlStatus.VIEW);

    this.setState({
      viewState: ViewState.CARD,
      crudlState: CrudlStatus.VIEW,
      editedProduct: data,
      originalEditedProduct: JSON.stringify(data),
    });

    this.menuState(CrudlStatus.VIEW);
  };

  deleteSetup = async (data) => {
    data = await this.additionalParseFunctions(data, CrudlStatus.DELETE);

    this.setState({
      crudlState: CrudlStatus.DELETE,
      editedProduct: data,
      originalEditedProduct: JSON.stringify(data),
      validateState: ValidateState.DELETE,
    });
    this.menuState(CrudlStatus.DELETE);
  };

  additionalParseFunctions = async (data, crudlState) => {
    //TODO if required
    switch (crudlState) {
      case CrudlStatus.CREATE:
        if (this.state.viewType === ViewType.MY_PRODUCT) {
          data.type = ProductType.SUPPLIER;
        }
        if (this.state.viewType === ViewType.MY_REQUIREMENT) {
          data.type = ProductType.BUYER;
        }
        break;
      case CrudlStatus.UPDATE || CrudlStatus.VIEW:
        break;
      case CrudlStatus.DELETE:
        break;
      default:
        break;
    }
    this.props.log.info(data);
    return data;
  };

  addProduct = async (stayOnPage) => {
    this.setState({ pageState: PageState.LOADING });
    var stateVariables = this.getStateValuesAsObject();

    if (!format.isNotNull(stayOnPage) || stayOnPage === false) {
      stateVariables.viewState = ViewState.TABLE;
    } else {
      stateVariables.viewState = ViewState.CARD;
    }

    var productResponse = await this.props.viewModel.executeCRUDLAction(
      CrudlStatus.CREATE,
      stateVariables
    );

    if (productResponse.errorCode === 0) {
      if (!format.isNotNull(stayOnPage) || stayOnPage === false) {
        await this.updateProductList(
          productResponse,
          CrudlStatus.CREATE,
          stateVariables
        );
      } else {
        await this.updateCurrentProduct(
          productResponse,
          CrudlStatus.CREATE,
          stateVariables
        );
      }
    } else {
      this.setState({ pageState: PageState.IDLE });
    }
  };
  updateProduct = async (stayOnPage) => {
    this.setState({ pageState: PageState.LOADING });
    var stateVariables = this.getStateValuesAsObject();

    if (!format.isNotNull(stayOnPage) || stayOnPage === false) {
      stateVariables.viewState = ViewState.TABLE;
    } else {
      stateVariables.viewState = ViewState.CARD;
    }

    var productResponse = await this.props.viewModel.executeCRUDLAction(
      CrudlStatus.UPDATE,
      stateVariables
    );

    if (productResponse.errorCode === 0) {
      if (!format.isNotNull(stayOnPage) || stayOnPage === false) {
        await this.updateProductList(
          productResponse,
          CrudlStatus.CREATE,
          stateVariables
        );
      } else {
        await this.updateCurrentProduct(
          productResponse,
          CrudlStatus.CREATE,
          stateVariables
        );
      }
    } else {
      this.setState({ pageState: PageState.IDLE });
    }
  };

  deleteProduct = async () => {
    this.setState({ pageState: PageState.LOADING });
    var stateVariables = this.getStateValuesAsObject();

    var productResponse = await this.props.viewModel.executeCRUDLAction(
      CrudlStatus.DELETE,
      stateVariables
    );

    if (productResponse.errorCode === 0) {
      await this.updateProductList(
        productResponse,
        CrudlStatus.DELETE,
        stateVariables
      );
    } else {
      this.setState({ pageState: PageState.IDLE });
    }
  };

  updateProductList = async (apiResponse, type, stateVariables) => {
    this.setState({ pageState: PageState.LOADING });

    // TODO use viewType constant from AppConstants
    if (this.props.viewType !== "STANDARD") {
      if (format.isFunction(this.props.updateObjectFromProps)) {
        // any specific setting variables back up stream for props
        // this.props.updateObjectFromProps(
        //   "name",
        //   stateVariables.productBean.name
        // );
      }
    }

    if (this.props.viewType === ViewType.MY_PRODUCT) {
      await this.props.viewModel.getProducts(
        ProductType.SUPPLIER,
        this.state.codeTag
      );
    } else if (this.props.viewType === ViewType.MY_REQUIREMENT) {
      await this.props.viewModel.getProducts(
        ProductType.BUYER,
        this.state.codeTag
      );
    } else if (this.props.viewType === ViewType.PROFILE) {
      await this.props.viewModel.getProducts(this.props.productType);
    } else if (this.props.viewType === ViewType.BUYER) {
      await this.props.viewModel.getProductsByType(ProductType.BUYER);
    } else if (this.props.viewType === ViewType.SUPPLIER) {
      await this.props.viewModel.getProductsByType(ProductType.SUPPLIER);
    }

    // toggle any create or update Props views to close or change
    if (this.props.viewType !== "STANDARD") {
      if (format.isFunction(this.props.updateStateFromProps)) {
        // this.props.updateStateFromProps("toggleCreateProduct", false); // example
        // this.props.updateStateFromProps("productList", this.state.productList); // example
      }
    }

    this.setState({
      viewState: ViewState.TABLE,
      crudlState: CrudlStatus.LIST,
      validateState: ValidateState.NONE,
      editedProduct: null,
    });

    this.menuState(CrudlStatus.LIST);

    this.setState({ pageState: PageState.IDLE });
  };

  updateCurrentProduct = async (apiResponse, type, stateVariables) => {
    this.setState({ pageState: PageState.LOADING });

    var productList = {};

    if (this.props.viewType === ViewType.MY_PRODUCT) {
      productList = await this.props.viewModel.getProductsReturned(
        ProductType.SUPPLIER,
        this.state.codeTag
      );
    } else if (this.props.viewType === ViewType.MY_REQUIREMENT) {
      productList = await this.props.viewModel.getProductsReturned(
        ProductType.BUYER,
        this.state.codeTag
      );
    } else if (this.props.viewType === ViewType.PROFILE) {
      productList = await this.props.viewModel.getProducts(
        this.props.productType
      );
    } else if (this.props.viewType === ViewType.BUYER) {
      productList = await this.props.viewModel.getProductsByType(
        ProductType.BUYER
      );
    } else if (this.props.viewType === ViewType.SUPPLIER) {
      productList = await this.props.viewModel.getProductsByType(
        ProductType.SUPPLIER
      );
    }
    var product = {};
    if (format.isNotNull(productList)) {
      productList.forEach((element) => {
        if (element.id === apiResponse.id) {
          product = format.deepCopy(element);
        }
      });
    }
    await this.updateSetup(product);
    this.setState({ pageState: PageState.IDLE, productList: productList });
  };

  setUpApplication = (rowData) => {
    analytics.screenViewEvent(analytics.PageView.PRODUCT_CONTACT);
    console.log(rowData);
    if (rowData.type === ProductType.BUYER) {
      analytics.logApplicationProductEvent(
        analytics.Actions.BUYER_PRODUCT_CONTACT,
        rowData
      );
    } else if (rowData.type === ProductType.SUPPLIER) {
      analytics.logApplicationProductEvent(
        analytics.Actions.SUPPLIER_PRODUCT_CONTACT,
        rowData
      );
    }

    this.setState({ toggleApplication: true, productToContact: rowData });
  };

  batchListEmptyRow = (sequence) => {
    let pdfUrls = [];
    let imageUri = [];
    let productLogo = "";
    let shortDescription = "https://";
    let location = "";
    // if (sequence === 1) {
    //   pdfUrls = [
    //     '{"url":"https://firebasestorage.googleapis.com/v0/b/buysupply-development.appspot.com/o/26-07-2021%2009%3A32%3A41-M12%20Bolt.jpg?alt=media&token=976772c3-e5d9-45b1-b23a-51082a5d41bd","name":"M12 Bolt.png"}',
    //   ];
    //   productLogo =
    //     "https://firebasestorage.googleapis.com/v0/b/buysupply-development.appspot.com/o/26-07-2021%2009%3A32%3A41-M12%20Bolt.jpg?alt=media&token=976772c3-e5d9-45b1-b23a-51082a5d41bd";
    //   imageUri = [
    //     "https://firebasestorage.googleapis.com/v0/b/buysupply-development.appspot.com/o/26-07-2021%2009%3A32%3A41-M12%20Bolt.jpg?alt=media&token=976772c3-e5d9-45b1-b23a-51082a5d41bd",
    //   ];
    //   shortDescription = "https://www.google.com";
    //   location = "Belfast";
    // }
    var json = {
      sequenceId: sequence,
      empty: true,
      productStatus: [ProductStatus.ACTIVE],
      shortDescription: shortDescription,
      pdfUrls: pdfUrls,
      productLogo: productLogo,
      imageUri: imageUri,
      location: location,
      // name: "Batch Product (" + sequence + ")",
      // dateRequired: new Date(),
      // longDescription: "<p>some details</p>",
    };
    return json;
  };
  createBatchProducts = async () => {
    this.setState({
      batchProductsCreate: true,
    });
    var failedBatchList = [];
    var itemFailure = false;

    let type = "";
    if (this.state.viewType === ViewType.MY_PRODUCT) {
      type = ProductType.SUPPLIER;
    }
    if (this.state.viewType === ViewType.MY_REQUIREMENT) {
      type = ProductType.BUYER;
    }

    if (format.isNotNull(this.state.sortedBatchList)) {
      for (var e of this.state.sortedBatchList) {
        // set up state variables and run create for each
        var stateVariables = {};

        stateVariables.productBean = format.deepCopy(e);
        stateVariables.viewType = this.state.viewType;
        stateVariables.productBean.type = type;
        if (format.isNotNull(this.state.codeTag)) {
          stateVariables.productBean.codeTag = this.state.codeTag;
        }

        console.log(stateVariables.productBean);
        var productResponse = await this.props.viewModel.executeCRUDLAction(
          CrudlStatus.CREATE,
          stateVariables
        );

        if (productResponse.errorCode === 0) {
          e.state = "COMPLETE";
          e.id = productResponse.id;
        } else {
          e.state = "FAILED";
          itemFailure = true;
          failedBatchList.push(e);
        }
        this.findAndUpdateSortedList(e);
      }
    }

    if (itemFailure) {
      // An product in the list didnt create
      // store product and store batch items
      this.setState({
        productFailure: itemFailure,
        failedBatchList: failedBatchList,
      });
    } else {
      // All products created successfully
      this.setState({
        productFailure: false,
      });

      if (this.props.viewType === ViewType.MY_PRODUCT) {
        await this.props.viewModel.getProducts(
          ProductType.SUPPLIER,
          this.state.codeTag
        );
      } else if (this.props.viewType === ViewType.MY_REQUIREMENT) {
        await this.props.viewModel.getProducts(
          ProductType.BUYER,
          this.state.codeTag
        );
      }
    }
  };

  findAndUpdateSortedList = (row) => {
    var updatedList = [];
    if (format.isNotNull(this.state.sortedBatchList)) {
      updatedList = this.state.sortedBatchList;
      updatedList.forEach((element) => {
        if (element.sequenceId === row.sequenceId) {
          element = row;
        }
      });
      this.setState({ sortedBatchList: updatedList });
    }
  };

  sortBatchProducts = async () => {
    this.setState({
      pageState: PageState.LOADING,
      displayBatchSummary: true,
      batchUpload: false,
    });

    let sortedBatchList = [];
    if (format.isNotNull(this.state.batchList)) {
      this.state.batchList.forEach((e) => {
        if (!e.empty) {
          sortedBatchList.push(e);
        }
      });
    }

    this.setState({
      sortedBatchList: sortedBatchList,
      pageState: PageState.IDLE,
    });
  };

  finishBatchUpload = () => {
    this.setState({
      batchList: [this.batchListEmptyRow(1)],
      sortedBatchList: [],
      failedBatchList: [],
      batchUpload: false,
      displayBatchSummary: false,
      batchProductsCreate: false,
      productFailure: false,
    });
    this.setPageData();
  };
  onProductEditorValueChange = (row, field, value, updateSidebarObject) => {
    let updatedList = this.state.batchList;
    updatedList[row][field] = value;

    if (format.isNotNull(value)) {
      var item = format.deepCopy(updatedList[row]);
      if (format.isNotNull(item.id)) {
        item.crudlStatus = CrudlStatus.UPDATE.value;
      } else {
        item.crudlStatus = CrudlStatus.CREATE.value;
      }

      item.empty = false;

      if (field === "componentList") {
        if (format.isNotNull(this.state.componentList)) {
          this.state.componentList.forEach((e) => {
            value.forEach((f) => {
              if (e.id === f) {
                item.componentNameToDisplay = e.name;
              }
            });
          });
        }
      }

      updatedList[row] = item;
    }

    if (row === this.state.batchList.length - 1) {
      var sequence = updatedList[row]["sequenceId"];
      sequence = sequence + 1;
      updatedList.push(this.batchListEmptyRow(sequence));
    }

    this.setState({
      batchList: updatedList,
    });
    if (updateSidebarObject) {
      this.setState({
        toggleSidebarObject: updatedList[row],
      });
    }
  };

  toggleImageDisplay = (rowData, field) => {
    this.setState({
      toggleImageUpload: true,
      toggleSidebarObject: rowData,
      toggleImageField: field,
    });
  };
  toggleDocumentDisplay = (rowData, field) => {
    this.setState({
      toggleDocumentUpload: true,
      toggleSidebarObject: rowData,
      toggleDocumentField: field,
    });
  };
  toggleNotesDisplay = (rowData, field) => {
    this.setState({
      toggleNotesUpload: true,
      toggleSidebarObject: rowData,
      toggleNotesField: field,
    });
  };

  render() {
    return (
      <React.Fragment>
        <pr.Messages
          style={{ textAlign: "left" }}
          ref={(el) => (this.growl = el)}
        />

        {/*VERY VERY BAD ERROS ONLY eg PAGE LOAD*/}
        {this.state.pageState === PageState.ERROR && (
          <ErrorScreen
            loading={this.state.pageState === PageState.LOADING}
            refresh={this.setPageData}
          />
        )}

        {this.state.pageState !== PageState.ERROR && (
          <ProductView
            //STATE + HELPER VALUES
            viewState={this.state.viewState}
            validateState={this.state.validateState}
            crudlState={this.state.crudlState}
            menuState={this.state.menuState}
            loading={this.state.pageState === PageState.LOADING}
            updateState={this.updateState}
            refresh={this.setPageData}
            feedback={this.props.feedback}
            receivedFeedback={this.state.receivedFeedback}
            //CARD SPECIFIC PROPS
            viewType={this.state.viewType}
            editedObject={this.state.editedProduct}
            productTypeOptions={this.state.productTypeOptions}
            updateEdited={this.updateBean}
            validateChanges={this.validateChanges}
            discardChanges={this.discardChanges}
            checkRequiredFields={this.checkRequiredFields}
            crudlExecute={this.executeCrudl}
            sidebarWidth={this.state.sidebarWidth}
            //TABLE SPECIFIC PROPS
            crudlControl={this.executeSetup}
            productList={this.state.productList}
            expandedRows={this.state.expandedRows}
            tableColumns={this.state.tableColumns}
            columnOptions={this.state.columnOptions}
            columnToggle={this.columnToggle}
            tableReference={this.state.tableReference}
            settingsToggle={this.state.settingsToggle}
            componentList={this.state.componentList}
            componentOptions={this.state.componentOptions}
            productDetailsView={this.state.productDetailsView}
            globalFilter={
              this.props.globalFilter
                ? this.props.globalFilter
                : this.state.globalFilter
            }
            setUpApplication={this.setUpApplication}
            toggleApplication={this.state.toggleApplication}
            dashboardType={this.state.dashboardType}
            productFilterListEmpty={
              this.props.productFilterListEmpty
                ? this.props.productFilterListEmpty
                : this.state.productFilterListEmpty
            }
            updateStateFromProps={this.props.updateStateFromProps}
            productToContact={this.state.productToContact}
            // Bulk / Batch
            batchList={this.state.batchList}
            batchUpload={this.state.batchUpload}
            sortedBatchList={this.state.sortedBatchList}
            displayBatchSummary={this.state.displayBatchSummary}
            validateChangesBatch={this.validateChangesBatch}
            checkRequiredFieldsBatch={this.checkRequiredFieldsBatch}
            finishBatchUpload={this.finishBatchUpload}
            onProductEditorValueChange={this.onProductEditorValueChange}
            createBatchProducts={this.createBatchProducts}
            sortBatchProducts={this.sortBatchProducts}
            batchProductsCreate={this.state.batchProductsCreate}
            invoiceFailure={this.state.invoiceFailure}
            setUpBatchListOfFailures={this.setUpBatchListOfFailures}
            batchListEmptyRow={this.batchListEmptyRow}
            toggleImageUpload={this.state.toggleImageUpload}
            toggleImageDisplay={this.toggleImageDisplay}
            toggleImageField={this.state.toggleImageField}
            toggleDocumentUpload={this.state.toggleDocumentUpload}
            toggleDocumentDisplay={this.toggleDocumentDisplay}
            toggleSidebarObject={this.state.toggleSidebarObject}
            toggleDocumentField={this.state.toggleDocumentField}
            toggleNotesUpload={this.state.toggleNotesUpload}
            toggleNotesDisplay={this.toggleNotesDisplay}
            toggleNotesField={this.state.toggleNotesField}
            showTutorial={this.state.showTutorial}
            discardChangesBatch={this.discardChangesBatch}
          />
        )}
      </React.Fragment>
    );
  }
}
