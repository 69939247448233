import React from "react";
//PrimeReact Components
import * as pr from "../../../common/PrimeComponents";
import {
  CrudlStatus,
  FeedbackState,
  ViewType,
} from "../../../common/config/AppConstants";

import { BusinessCard } from "../../Business/UI/BusinessCard";
import { CategoryCard } from "../../Category/UI/CategoryCard";
import { UserCard } from "../../User/UI/UserCard";
import { ProductCard } from "../../Product/UI/ProductCard";

export class Register extends React.Component {
  componentDidUpdate = (prevProps) => {
    // Typical usage (don't forget to compare props):
    if (this.props.receivedFeedback !== prevProps.receivedFeedback) {
      switch (this.props.receivedFeedback.state) {
        case FeedbackState.EMPTY:
          this.props.feedback.showEmpty(
            this.growl,
            this.props.receivedFeedback.display
          );
          break;
        case FeedbackState.SUCCESS:
          this.props.feedback.showSuccess(
            this.growl,
            this.props.receivedFeedback.display,
            this.props.receivedFeedback.crudlStatus
          );
          break;
        case FeedbackState.ERROR:
          this.props.feedback.showError(
            this.growl,
            this.props.receivedFeedback.display,
            this.props.receivedFeedback.crudlStatus
          );
          break;
        default:
          break;
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <pr.Messages
          style={{ textAlign: "left" }}
          ref={(el) => (this.growl = el)}
        />
        <div className="login-page" id="login-supply">
          <div className="p-grid" style={{ padding: "1.5em" }}>
            <div className="p-col-12">
              <pr.TabView
                activeIndex={this.props.index}
                onTabChange={(e) => {
                  this.props.updateState("index", e.index);
                }}
              >
                <pr.TabPanel
                  header="Business Details"
                  onTabChange={(e) => {
                    this.props.updateState("index", e.index);
                  }}
                >
                  <BusinessCard
                    viewType={ViewType.REGISTER}
                    checkRequiredFields={this.props.checkRequiredFields}
                    updateState={this.props.updateState}
                    updateValue={this.props.updateValueBusiness}
                    editedObject={this.props.editedBusiness}
                    checkBusinessAlreadyExists={
                      this.props.checkBusinessAlreadyExists
                    }
                    updateBusinessType={this.props.updateBusinessType}
                    businessTypeOptions={this.props.businessTypeOptions}
                  />
                </pr.TabPanel>
                <pr.TabPanel
                  activeIndex={this.props.index}
                  disabled={!this.props.businessFormComplete}
                  header="Categories"
                  onTabChange={(e) => {
                    this.props.updateState("index", e.index);
                  }}
                >
                  <CategoryCard
                    viewType={ViewType.REGISTER}
                    checkRequiredFields={this.props.checkRequiredFields}
                    updateState={this.props.updateState}
                    updateValue={this.props.updateValueCategory}
                    componentList={this.props.componentList}
                    changeCategoriesState={this.props.changeCategoriesState}
                    editedBusiness={this.props.editedBusiness}
                    crudlState={CrudlStatus.CREATE}
                  />
                </pr.TabPanel>
                <pr.TabPanel
                  activeIndex={this.props.index}
                  disabled={!this.props.categoriesFormComplete}
                  header="User Details"
                  onTabChange={(e) => {
                    this.props.updateState("index", e.index);
                  }}
                >
                  <UserCard
                    viewType={ViewType.REGISTER}
                    checkRequiredFields={this.props.checkRequiredFields}
                    updateState={this.props.updateState}
                    updateValue={this.props.updateValueUser}
                    editedObject={this.props.editedUser}
                    checkEmailAddressAlreadyExists={
                      this.props.checkEmailAddressAlreadyExists
                    }
                  />
                </pr.TabPanel>
                <pr.TabPanel
                  activeIndex={this.props.index}
                  disabled={!this.props.userFormComplete}
                  header="Products"
                  onTabChange={(e) => {
                    this.props.updateState("index", e.index);
                  }}
                >
                  <ProductCard
                    viewType={ViewType.REGISTER}
                    checkRequiredFields={this.props.checkRequiredFields}
                    updateState={this.props.updateState}
                    updateValue={this.props.updateValueProduct}
                    editedObject={this.props.editedProduct}
                    componentOptions={this.props.componentOptions}
                    onRegister={this.props.onRegister}
                    loading={this.props.loading}
                    editedBusiness={this.props.editedBusiness}
                  />
                </pr.TabPanel>
              </pr.TabView>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
