import { BaseViewModel } from "../../common/BaseViewModel";

export class LoginViewModel extends BaseViewModel {
  loginApiV1Login = async (request) => {
    return this.login(request);
  };

  getProfileByCodeTag = async (request) => {
    return this.profileGetByCodeTag(request);
  };
}
