import React from "react";
//PrimeReact Components
import * as pr from "../../../common/PrimeComponents";
import {
  CrudlStatus,
  FeedbackState,
  ViewType,
  BusinessType,
  CapabilityTypes,
  CategoryTooltips,
} from "../../../common/config/AppConstants";
import * as format from "../../../common/FormatFunctions";
import { LoadScreen } from "../../Common/LoadScreen";
import { Input, InputType, LabelPosition } from "../../Common/Input";

export class CategoryCard extends React.Component {
  componentDidUpdate = (prevProps) => {
    // Typical usage (don't forget to compare props):
    if (this.props.receivedFeedback !== prevProps.receivedFeedback) {
      switch (this.props.receivedFeedback.state) {
        case FeedbackState.EMPTY:
          this.props.feedback.showEmpty(
            this.growl,
            this.props.receivedFeedback.display
          );
          break;
        case FeedbackState.SUCCESS:
          this.props.feedback.showSuccess(
            this.growl,
            this.props.receivedFeedback.display,
            this.props.receivedFeedback.crudlStatus
          );
          break;
        case FeedbackState.ERROR:
          this.props.feedback.showError(
            this.growl,
            this.props.receivedFeedback.display,
            this.props.receivedFeedback.crudlStatus
          );
          break;
        default:
          break;
      }
    }
  };

  displayCard = () => {
    let className = "scrl";
    if (this.props.viewType === ViewType.MY_BUSINESS) {
      className = null;
    }
    return (
      <React.Fragment>
        <div className={className}>
          <div className="p-grid">
            <div className="p-col-12"></div>
            {this.props.loading === true && <LoadScreen />}
            {this.props.loading !== true && (
              <div className="p-col-12 p-grid">
                {this.categoryListToCards()}
              </div>
            )}
            <div className="p-col-12">
              {this.props.crudlState !== CrudlStatus.VIEW ? (
                <pr.Button
                  className="mrgn flt-r"
                  disabled={this.isDisabled()}
                  label="Save Categories"
                  icon={
                    this.props.loading
                      ? "pi-spin pi-md-autorenew"
                      : "pi-md-save"
                  }
                  onClick={() => {
                    this.props.crudlExecute();
                  }}
                />
              ) : (
                <label />
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  displayHeaderRow = (title) => {
    return (
      <div className="p-col-12" key={title}>
        <div className="p-grid card categories-header-row">
          <div className="p-col-12">
            <label>{title}</label>
          </div>
        </div>
      </div>
    );
  };

  viewDisplayRow = (component) => {
    return (
      <div className="p-col-12" key={component.id}>
        <div className="p-grid card">
          <div className="p-col-1">
            <pr.Checkbox checked={true} />
          </div>
          <div className="p-col-5">
            <Input
              id={"name"}
              labelDisplay={"Name"}
              labelDisplayPosition={LabelPosition.NONE}
              required={false}
              type={InputType.STRING}
              disabled={true}
              value={component.name}
              field={"name"}
              visible={true}
            />
          </div>
          <div className="p-col-6">
            <Input
              id={"score"}
              labelDisplay={"Interest"}
              labelDisplayPosition={LabelPosition.NONE}
              required={false}
              type={InputType.STRING}
              disabled={true}
              value={component.score}
              field={"score"}
              visible={true}
            />
          </div>
        </div>
      </div>
    );
  };

  categoryListToCards = () => {
    let categoryArray = [];
    let i = 0;

    let otherArray = [];
    let ppeArray = [];

    if (format.isNotNull(this.props.componentList)) {
      if (
        this.props.viewType === ViewType.MY_BUSINESS &&
        this.props.crudlState === CrudlStatus.VIEW
      ) {
        categoryArray[i] = this.displayHeaderRow("Manufacturing");
        i++;
        this.props.componentList.forEach((e) => {
          if (format.isNotNull(e.type) && e.type === "PPE") {
            ppeArray.push(e);
          } else {
            if (e.selectedOnCreate) {
              if (e.name !== "Other") {
                categoryArray[i] = this.viewDisplayRow(e);
                i++;
              } else {
                otherArray.push(e);
              }
            }
          }
        });
      } else {
        categoryArray[i] = this.displayHeaderRow("Manufacturing");
        i++;

        this.props.componentList.forEach((e) => {
          if (format.isNotNull(e.type) && e.type === "PPE") {
            ppeArray.push(e);
          } else {
            if (e.name !== "Other") {
              categoryArray[i] = this.componentDisplay(e);
              i++;
            } else {
              otherArray.push(e);
            }
          }
        });
      }
    }
    if (format.isNotNull(otherArray)) {
      otherArray.forEach((e) => {
        if (this.props.crudlState === CrudlStatus.VIEW) {
          categoryArray[i] = this.viewDisplayRow(e);
        } else {
          categoryArray[i] = this.componentDisplay(e);
        }

        i++;
      });
    }
    if (format.isNotNull(ppeArray)) {
      categoryArray[i] = this.displayHeaderRow("PPE");
      i++;
      ppeArray.forEach((e) => {
        if (this.props.crudlState === CrudlStatus.VIEW) {
          categoryArray[i] = this.viewDisplayRow(e);
        } else {
          categoryArray[i] = this.componentDisplay(e);
        }

        i++;
      });
    }

    return categoryArray;
  };

  componentDisplay = (e) => {
    return (
      <>
        <div
          key={e.id}
          className="p-lg-1 p-md-0 p-sl-0"
          style={{ marginLeft: "-1.5em" }}
        ></div>
        <div className="p-lg-5 p-md-12 p-sl-12 card-categories">
          <div className="p-grid">
            <div className="p-col-12 p-md-4">
              <div className="p-grid" style={{ height: "100%" }}>
                <div className="p-col-12"></div>
                <div className="p-col-12">
                  <center>
                    <b>{e.name}</b>
                  </center>
                </div>
                <div className="p-col-12"></div>
              </div>
            </div>

            {this.optionsBasedOffCapability(e)}
            {e.name === "Other" && (
              <div className="p-col-12">
                <label htmlFor={e.name + " Details"} className="input-label">
                  Details
                </label>
                <textarea
                  id={e.name + " Details"}
                  disabled={!e.selectedOnCreate}
                  rows={5}
                  value={e.details || ""}
                  onChange={(f) => {
                    this.props.changeCategoriesState(
                      f.target.id,
                      f.target.value
                    );
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  optionsBasedOffCapability = (component) => {
    let type = this.props.editedBusiness.type;

    if (type === BusinessType.BUYER) {
      return (
        <div className="p-col-12 p-md-8">
          <div className="p-grid">
            <div className="p-col-3"></div>
            <div className="p-col-6 capabilityCard">
              {this.displayCapabilityCard(
                BusinessType.BUYER,
                CapabilityTypes.WANT_TO_PROCURE,
                CapabilityTypes.OPEN_TO_PROCUREMENT,
                component,
                CategoryTooltips.BUYER
              )}
            </div>
            <div className="p-col-3"></div>
          </div>
        </div>
      );
    } else if (type === BusinessType.SUPPLIER) {
      return (
        <div className="p-col-12 p-md-8">
          <div className="p-grid">
            <div className="p-col-3"></div>
            <div className="p-col-6 capabilityCard">
              {this.displayCapabilityCard(
                BusinessType.SUPPLIER,
                CapabilityTypes.CAN_SUPPLY,
                CapabilityTypes.OPEN_TO_SUPPLY,
                component,
                CategoryTooltips.SUPPLIER
              )}
            </div>
            <div className="p-col-3"></div>
          </div>
        </div>
      );
    } else if (type === BusinessType.BUYERSUPPLIER) {
      return (
        <div className="p-col-12 p-md-8">
          <div className="p-grid capabilityCard">
            <div className="p-col-6 divideRight">
              {this.displayCapabilityCard(
                BusinessType.BUYER,
                CapabilityTypes.WANT_TO_PROCURE,
                CapabilityTypes.OPEN_TO_PROCUREMENT,
                component,
                CategoryTooltips.BUYER
              )}
            </div>
            <div className="p-col-6">
              {this.displayCapabilityCard(
                BusinessType.SUPPLIER,
                CapabilityTypes.CAN_SUPPLY,
                CapabilityTypes.OPEN_TO_SUPPLY,
                component,
                CategoryTooltips.SUPPLIER
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return <label />;
    }
  };

  displayCapabilityCard = (title, option1, option2, component, tooltip) => {
    return (
      <div className="p-grid">
        <div className="p-col-12 capabilityTitle">
          {title}
          <pr.Button
            icon="pi pi-info"
            style={{ fontSize: "10px", float: "right" }}
            tooltip={tooltip}
            tooltipOptions={{
              position: "left",
            }}
          />
        </div>
        <div
          className="p-col-6 capabilityLeft"
          title={tooltip}
          style={
            component.score === option1
              ? { backgroundColor: "green", color: "white" }
              : {}
          }
          onClick={() => {
            if (this.props.crudlState !== CrudlStatus.VIEW) {
              this.props.changeCategoriesState(
                component.name + " Score",
                option1
              );
            }
          }}
        >
          {option1}
        </div>
        <div
          className="p-col-6 capabilityRight"
          title={tooltip}
          style={
            component.score === option2
              ? { backgroundColor: "green", color: "white" }
              : {}
          }
          onClick={() => {
            if (this.props.crudlState !== CrudlStatus.VIEW) {
              this.props.changeCategoriesState(
                component.name + " Score",
                option2
              );
            }
          }}
        >
          {option2}
        </div>
      </div>
    );
  };

  displayRegister = () => {
    return (
      <div className="p-grid dashboard">
        <div className="p-col-12"></div>
        <div className="p-col-12 p-grid">{this.categoryListToCards()}</div>
        <div className="p-col-12">
          <pr.Button
            className="flt-r"
            disabled={this.isDisabled()}
            label="Next"
            icon={"pi pi-arrow-right"}
            onClick={() => {
              this.props.updateState("index", 2);
            }}
          />
          <pr.Button
            className="flt-l"
            label="Back"
            icon="pi pi-arrow-left"
            onClick={() => {
              this.props.updateState("index", 0);
            }}
          />
        </div>
      </div>
    );
  };

  isDisabled = () => {
    let isDisabled = true;

    if (format.isNotNull(this.props.componentList)) {
      this.props.componentList.forEach((e) => {
        if (e.selectedOnCreate) {
          if (e.score) {
            isDisabled = false;
          }
        }
      });
    }

    return isDisabled;
  };

  render() {
    return (
      <React.Fragment>
        <pr.Messages
          style={{ textAlign: "left" }}
          ref={(el) => (this.growl = el)}
        />
        {this.props.viewType === ViewType.REGISTER
          ? this.displayRegister()
          : this.displayCard()}
      </React.Fragment>
    );
  }
}
