import { api } from "../../common/CloudService/ApiPromisify";
// import * as c from "../../common/AppConstants";
//ClientLibsReference
import { CloudService } from "../../common/CloudService/ClientLibsReference";

export class ScenarioViewModel {
  showLoadingScreen = () => {
    this.updateState("loaded", false);
  };

  hideLoadingScreen = () => {
    this.updateState("loaded", true);
  };

  componentGetByType = async () => {
    let componentRequest = new CloudService.ComponentRequest();

    componentRequest.type = "PPE";

    var list = [];
    await api("componentApiV1ComponentGetByType", componentRequest).then(
      (res) => {
        if (res.data) {
          let componentResponse =
            CloudService.ComponentResponse.constructFromObject(res.data);
          console.log(componentResponse);
          list = componentResponse.componentList;
        }
      }
    );

    return list;
  };

  createProfile = async (
    codeTag,
    userId,
    ppeBeanList,
    supplierFlag,
    buyerFlag,
    certifiedProducts,
    certifiedProductsDescription
  ) => {
    let profileRequest = new CloudService.ProfileRequest();
    profileRequest.codeTag = codeTag;
    profileRequest.userId = userId;

    profileRequest.certifiedProducts = certifiedProducts;
    profileRequest.certifiedProductsDescription = certifiedProductsDescription;

    if (supplierFlag === false && buyerFlag === false) {
      profileRequest.type = "PPEBuyer";
      profileRequest.certifiedProducts = true;
      let componentBuyerIdList = [];
      let componentIdList = [];
      ppeBeanList.forEach((e) => {
        if (e.checked) {
          componentBuyerIdList.push(e.id);
          componentIdList.push(e.id);
        }
      });
      profileRequest.componentListSupplier = componentIdList;
      profileRequest.componentListBuyer = componentIdList;
      profileRequest.componentList = componentIdList;
    }
    if (supplierFlag === true && buyerFlag === false) {
      profileRequest.type = "PPESupplier";
      let componentBuyerIdList = [];
      let componentIdList = [];
      ppeBeanList.forEach((e) => {
        if (e.checked) {
          componentBuyerIdList.push(e.id);
          componentIdList.push(e.id);
        }
      });
      profileRequest.componentListSupplier = componentIdList;
      profileRequest.componentListBuyer = componentIdList;
      profileRequest.componentList = componentIdList;
    }
    if (supplierFlag === false && buyerFlag === true) {
      profileRequest.type = "PPEBuyer";
      profileRequest.certifiedProducts = true;
      let componentBuyerIdList = [];
      let componentIdList = [];
      ppeBeanList.forEach((e) => {
        if (e.checked) {
          componentBuyerIdList.push(e.id);
          componentIdList.push(e.id);
        }
      });
      profileRequest.componentListSupplier = componentIdList;
      profileRequest.componentListBuyer = componentIdList;
      profileRequest.componentList = componentIdList;
    }
    if (supplierFlag === true && buyerFlag === true) {
      profileRequest.type = "Both";
      let componentSupplierIdList = [];
      let componentBuyerIdList = [];
      let componentIdList = [];
      ppeBeanList.forEach((e) => {
        if (e.checked) {
          if (e.localType === "Supply") {
            componentSupplierIdList.push(e.id);
          } else {
            componentBuyerIdList.push(e.id);
          }

          componentIdList.push(e.id);
        }
      });

      console.log(componentSupplierIdList);
      console.log(componentBuyerIdList);

      profileRequest.componentListSupplier = componentIdList;
      profileRequest.componentListBuyer = componentIdList;
      profileRequest.componentList = componentIdList;
    }

    profileRequest.status = "Pending";

    console.log(profileRequest);

    await api("profileApiV1ProfileCreate", profileRequest).then((res) => {
      if (res.data) {
        let profileResponse = CloudService.ProfileResponse.constructFromObject(
          res.data
        );
        console.log(profileResponse);

        if (profileResponse.errorCode === 0) {
        }
      }
    });
  };

  createMatrixItem = async (
    codeTag,
    userId,
    ppeBeanList,
    supplierFlag,
    buyerFlag
  ) => {
    let matrixItemArray = [];

    if (supplierFlag === false && buyerFlag === false) {
      ppeBeanList.forEach((e) => (e.localType = "Buy"));
    }
    if (supplierFlag === true && buyerFlag === false) {
      ppeBeanList.forEach((e) => (e.localType = "Supply"));
    }
    if (supplierFlag === false && buyerFlag === true) {
      ppeBeanList.forEach((e) => (e.localType = "Buy"));
    }
    if (supplierFlag === true && buyerFlag === true) {
      ppeBeanList.forEach((e) => (e.localType = "Both"));
    }
    ppeBeanList.forEach((e) => {
      if (e.checked) {
        var score = "";
        if (e.localType === "Both") {
          score = "Yes, can buy & can supply";
        } else if (e.localType === "Supply") {
          score = "Yes, can supply";
        } else {
          score = "Yes, can buy";
        }
        matrixItemArray.push({
          codeTag: codeTag,
          userId: userId,
          componentId: e.id,
          score: score,
        });
      }
    });

    let matrixItemRequest = new CloudService.MatrixItemRequest();
    matrixItemRequest.codeTag = codeTag;
    matrixItemRequest.userId = userId;
    matrixItemRequest.matrixItemList = matrixItemArray;

    await api("matrixItemApiV1MatrixItemCreate", matrixItemRequest).then(
      (res) => {
        if (res.data) {
          let matrixItemResponse =
            CloudService.MatrixItemResponse.constructFromObject(res.data);
          console.log(matrixItemResponse);

          if (matrixItemResponse.errorCode === 0) {
          }
        }
      }
    );
  };

  getProfile = async () => {
    let profileRequest = new CloudService.ProfileRequest();
    profileRequest.codeTag = localStorage.getItem("clientId");

    // var returnValue = 0;
    var returnStatus = "";
    var returnType = "";
    var returnProfile = "";
    await api("profileApiV1ProfileGetByCodeTag", profileRequest).then((res) => {
      if (res.data) {
        let profileResponse = CloudService.ProfileResponse.constructFromObject(
          res.data
        );
        console.log(profileResponse);
        var validPPEProfile = "";
        var validPPEProfileStatus = "";
        if (Array.isArray(profileResponse.profileList)) {
          profileResponse.profileList.forEach((element) => {
            if (element.type === "PPESupplier") {
              validPPEProfile = "PPESupplier";
              validPPEProfileStatus = element.profileStatus;
            }
            if (element.type === "PPEBuyer") {
              validPPEProfile = "PPEBuyer";
              validPPEProfileStatus = element.profileStatus;
            }
            if (element.type === "Both") {
              validPPEProfile = "Both";
              validPPEProfileStatus = element.profileStatus;
            }
          });
        }

        //TEST A
        //validPPEProfile = "";
        if (validPPEProfile === "") {
          // returnValue = 1;
          //Use Case 1 - Need to Create Profile
        } else {
          if (validPPEProfileStatus !== "Approved") {
            // Use Case 2 need to Diplsy information about not yet being approved
            // returnValue = 2;
            returnStatus = validPPEProfileStatus;
          } else {
            returnStatus = validPPEProfileStatus;
            returnType = "PPE";
            returnProfile = validPPEProfile;

            // returnValue = 3;
          }
        }
      }
    });

    return [returnStatus, returnType, returnProfile];
  };
}
