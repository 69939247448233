import React from "react";
//PrimeReact Components
import * as pr from "../../../common/PrimeComponents";
import { ViewType } from "../../../common/config/AppConstants";
import * as format from "../../../common/FormatFunctions";
import { ProfileDisplay } from "./ProfileDisplay";
import {
  Input,
  InputType,
  LabelPosition,
  UpdateType,
} from "../../Common/Input";
import * as analytics from "../../../common/helpers/firebaseAnalytics";

export class ProfileTable extends React.Component {
  export = () => {
    this.dt.exportCSV();
  };

  valuesToArray = (values) => {
    let csvList = [
      [
        "Business Name",
        "Address Line 1",
        "Address Line 2",
        "City",
        "County",
        "Postcode",
        "Demographic Area",
        "Email",
        "Telephone",
        "Type",
        "Status",
        "Date Created",
      ],
    ];

    values.forEach((e) => {
      if (e.companyDetails) {
        if (e.companyDetails.businessName) {
          e.companyDetails.businessName = e.companyDetails.businessName.replace(
            /,/g,
            ""
          );
        }

        if (e.companyDetails.businessDescription) {
          e.companyDetails.businessDescription =
            e.companyDetails.businessDescription.replace(/,/g, "");
        }

        if (e.companyDetails.addressLine1) {
          e.companyDetails.addressLine1 = e.companyDetails.addressLine1.replace(
            /,/g,
            ""
          );
        }

        if (e.companyDetails.addressLine2) {
          e.companyDetails.addressLine2 = e.companyDetails.addressLine2.replace(
            /,/g,
            ""
          );
        }

        if (e.companyDetails.city) {
          e.companyDetails.city = e.companyDetails.city.replace(/,/g, "");
        }

        if (e.companyDetails.countyState) {
          e.companyDetails.countyState = e.companyDetails.countyState.replace(
            /,/g,
            ""
          );
        }

        if (e.companyDetails.postalZipCode) {
          e.companyDetails.postalZipCode =
            e.companyDetails.postalZipCode.replace(/,/g, "");
        }

        if (e.companyDetails.demographicArea) {
          e.companyDetails.demographicArea =
            e.companyDetails.demographicArea.replace(/,/g, "");
        }

        if (e.companyDetails.email) {
          e.companyDetails.email = e.companyDetails.email.replace(/,/g, "");
        }

        if (e.companyDetails.telephoneNumber) {
          e.companyDetails.telephoneNumber =
            e.companyDetails.telephoneNumber.replace(/,/g, "");
        }

        if (e.type) {
          e.type = e.type.replace(/,/g, "");
          e.type = this.displayType(e.type);
        }

        if (e.profileStatus) {
          e.profileStatus = e.profileStatus.replace(/,/g, "");
        }
        if (e.dateCreated) {
          e.dateCreatedString = format.formatDate(e.dateCreated, "DDMMYY");
        }

        let nextRow = [
          e.companyDetails.businessName,
          e.companyDetails.addressLine1,
          e.companyDetails.addressLine2,
          e.companyDetails.city,
          e.companyDetails.countyState,
          e.companyDetails.postalZipCode,
          e.companyDetails.demographicArea,
          e.companyDetails.email,
          e.companyDetails.telephoneNumber,
          e.type,
          e.profileStatus,
          e.dateCreatedString,
        ];
        csvList.push(nextRow);
      } else {
        let nextRow = ["Error in business"];
        // csvList.push(nextRow);
      }
    });

    this.arrayToCSV(csvList);
  };

  arrayToCSV = (array) => {
    let csvContent =
      "data:text/csv;charset=utf-8," + array.map((e) => e.join(",")).join("\n");
    let encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "businesses.csv"); //Declare a csv name here
    document.body.appendChild(link);
    link.click();
  };

  displayType = (type) => {
    if (type === "Both") {
      return "PPE Buyer & Supplier";
    }
    if (type === "PPEBuyer") {
      return "PPE Buyer";
    }
    if (type === "PPESupplier") {
      return "PPE Supplier";
    }

    return type;
  };

  getProfileColumns = () => {
    var allColumns = [];
    if (format.isNotNull(this.props.tableColumns)) {
      let columns = this.props.tableColumns.map((col, i) => {
        return (
          <pr.Column
            key={col.field}
            field={col.field}
            header={col.header}
            sortable={col.sortable}
            filter={col.filter}
            filterMatchMode={col.filterMatchMode}
            style={col.style}
          />
        );
      });

      columns.forEach((e) => {
        allColumns.push(e);
      });

      if (
        this.props.viewType === ViewType.BUYER ||
        this.props.viewType === ViewType.SUPPLIER
      ) {
        allColumns.push(<pr.Column body={this.getProfileTemplate} />);
      }

      allColumns.unshift(<pr.Column expander style={{ width: "3em" }} />);
    }
    return allColumns;
  };

  render() {
    return (
      <div className="p-grid bpm">
        <>
          {this.props.viewType === ViewType.DASHBOARD ? (
            <>
              {/* {this.props.dashboardType === BusinessType.BUYER && (
                <h3 style={{ color: "blue" }}>{"Potential Buyers"}</h3>
              )}
              {this.props.dashboardType === BusinessType.SUPPLIER && (
                <h3 style={{ color: "blue" }}>
                  {
                    "Cannot find what your looking for? Maybe these business can supply what your looking for"
                  }
                </h3>
              )} */}
            </>
          ) : (
            <>
              <div className="p-col-12 p-lg-3 p-md-6 p-sl-12">
                <div className="content-section">
                  <div className="feature-intro">
                    {this.props.viewType === ViewType.BUYER && (
                      <h1>{"View All Buyers"}</h1>
                    )}
                    {this.props.viewType === ViewType.SUPPLIER && (
                      <h1>{"View All Suppliers"}</h1>
                    )}
                    {this.props.viewType === ViewType.COUNCIL && (
                      <h1>{"View All Businesses"}</h1>
                    )}
                  </div>
                </div>
              </div>
              <div className="p-col-12 p-lg-9 p-md-6 p-sl-12">
                {this.props.viewType !== ViewType.COUNCIL && (
                  <pr.Button
                    icon="pi pi-info"
                    onClick={() => {
                      this.props.updateState("showTutorial", true);
                    }}
                    style={{ fontSize: "10px", float: "right" }}
                  />
                )}
              </div>
            </>
          )}
        </>

        {this.props.viewType !== ViewType.DASHBOARD && (
          <>
            <div className="p-col-12 p-md-3"></div>
            <div className="p-col-12 p-md-6">
              <Input
                id={"globalFilter"}
                labelDisplay={"Type here to search entire table"}
                labelDisplayPosition={LabelPosition.NONE}
                required={false}
                type={InputType.STRING}
                disabled={false}
                value={this.props.globalFilter}
                onChangeType={UpdateType.STANDARD}
                onChange={this.props.updateState}
                field={"globalFilter"}
                visible={true}
                style={{ backgroundColor: "#dae4f1" }}
                onBlur={() => {
                  analytics.searchEvent(this.props.globalFilter);
                }}
              />
            </div>
          </>
        )}

        <div className="p-col-12">
          <pr.DataTable
            ref={(el) => {
              this.dt = el;
            }}
            header={
              <div className="p-grid">
                <div className="p-col-12">
                  {this.props.viewType === ViewType.COUNCIL && (
                    <pr.Button
                      className="table-button"
                      type="button"
                      label={"Export Data"}
                      style={{
                        float: "right",
                      }}
                      icon="pi-md-launch"
                      // onClick={this.export}
                      onClick={() => {
                        this.valuesToArray(
                          format.deepCopy(this.props.profileList)
                        );
                      }}
                    />
                  )}
                </div>
              </div>
            }
            value={this.props.profileList}
            // selectionMode="single"
            // onSelectionChange={(e) => {
            //   this.props.crudlControl(CrudlStatus.UPDATE, e.value);
            // }}
            emptyMessage="No Profiles"
            expandedRows={this.props.expandedRows}
            onRowToggle={(e) => this.props.updateState("expandedRows", e.data)}
            rowExpansionTemplate={this.getDisplayCard}
            rows={Infinity}
            autoLayout={true}
            paginator={false}
            alwaysShowPaginator={false}
            responsive={true}
            loading={this.props.loading}
            globalFilter={this.props.globalFilter}
          >
            {this.getProfileColumns()}
          </pr.DataTable>
        </div>
      </div>
    );
  }
  getProfileTemplate = (rowData, column) => {
    return (
      <div className="p-grid">
        <div className="p-col-2"> </div>
        <div className="p-col-8">
          <center>
            <pr.Button
              type="button"
              label={"Contact"}
              onClick={(e) => this.props.toggleApplicationCreate(rowData)}
            />
          </center>
        </div>
      </div>
    );
  };
  getDisplayCard = (data) => {
    analytics.viewItemEvent(data);
    return (
      <ProfileDisplay
        data={data}
        onClick={this.props.crudlControl}
        viewType={this.props.viewType}
        updateProfileStatus={this.props.updateProfileStatus}
        editBusiness={this.props.editBusiness}
      />
    );
  };
}
