import React, { Component } from "react";
import { Route } from "react-router-dom";
import { Master } from "./Master";

import { RegisterProvider } from "./components/Register/RegisterProvider";
import { LoginProvider } from "./components/Login/LoginProvider";
import { Password } from "./components/Password/Password";
import { Verify } from "./components/Verify/Verify";
import { Status } from "./components/Status/Status";

import { PPEProvider } from "./components/PPE/PPEProvider";
import { PPEMaster } from "./PPEMaster";
import { PPELogin } from "./components/PPELogin/PPELogin";
import { ViewType } from "./common/config/AppConstants";
import * as auth from "./common/helpers/auth";
import { LoadScreen } from "./components/Common/LoadScreen";

export class App extends Component {
  constructor() {
    super();
    this.state = {
      activeUser: auth.DisplayState.PENDING,
    };
  }

  updateAuthUser = (authState) => {
    this.setState({ activeUser: authState });
  };

  componentDidMount = async () => {
    auth.authenticationCheck(this.updateAuthUser);
    this.setBrowserInformation();
    this.setValidEmail();
  };

  setBrowserInformation = () => {
    // localStorage.setItem("deviceSettings", JSON.stringify(deviceDetect()));
  };

  setValidEmail = () => {
    this.setState({ validEmail: localStorage.getItem("email") });
  };

  render() {
    const validEmail = localStorage.getItem("email");

    const validStatus = localStorage.getItem("profileStatus");
    const profileType = localStorage.getItem("profileType");
    let profType = localStorage.getItem("profileType");

    if (this.state.activeUser === auth.DisplayState.PENDING) {
      return (
        <div className="dashboard">
          <LoadScreen></LoadScreen>
        </div>
      );
    }

    if (validEmail) {
      console.log(localStorage);
      if (
        localStorage.getItem("roleType") === "10" ||
        localStorage.getItem("roleType") === "20"
      ) {
        return <Master />;
      } else {
        if (validStatus === "Approved") {
          if (profileType === "PPE" || profType.includes("PPE")) {
            console.log("Trying to run PPEMaster");
            return <PPEMaster />;
          } else {
            console.log("Trying to run Master");
            return <Master />;
          }
        } else if (validStatus === "Pending") {
          return (
            <Status
              ppe={profileType === "PPE" || profType.includes("PPE")}
              message={
                <span>
                  Thank you for registering with BuySupplyNI, your application
                  will be reviewed within the next 48 hours, if you have not had
                  a response please get in touch with the team by emailing{" "}
                  <label
                    className="custom-label"
                    onClick={() => {
                      window.open("mailto:buysupplyni@midandeastantrim.gov.uk");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    buysupplyni@midandeastantrim.gov.uk
                  </label>{" "}
                  or by calling on 028 2563 3345
                </span>
              }
            />
          );
        } else if (validStatus === "Unsuccessful") {
          return (
            <Status
              ppe={profileType === "PPE"}
              message={<span>Your application has been unsuccessful</span>}
            />
          );
        } else {
          return (
            <Status
              ppe={profileType === "PPE"}
              message={
                <span>No application for this business could be found</span>
              }
            />
          );
        }
      }
    } else {
      return (
        <div
          ref={(el) => (this.layoutContainer = el)}
          // className="layout-wrapper login-page"
          className="layout-main"
        >
          <div style={{ height: "0px" }}>
            <Route
              path="/Council_Login"
              render={(props) => (
                <LoginProvider {...props} viewType={ViewType.COUNCIL} />
              )}
            />

            <Route
              path="/"
              exact
              render={(props) => (
                <LoginProvider {...props} viewType={ViewType.BUYERSUPPLIER} />
              )}
            />

            <Route
              path="/login"
              exact
              render={(props) => (
                <LoginProvider {...props} viewType={ViewType.BUYERSUPPLIER} />
              )}
            />

            <Route
              path="/Buyer_Login"
              exact
              render={(props) => (
                <LoginProvider {...props} viewType={ViewType.BUYERSUPPLIER} />
              )}
            />
            <Route path="/register" exact component={RegisterProvider} />
            <Route path="/Buyer_Register" exact component={RegisterProvider} />
            <Route
              path="/Supplier_Login"
              exact
              render={(props) => (
                <LoginProvider {...props} viewType={ViewType.BUYERSUPPLIER} />
              )}
            />
            <Route
              path="/Supplier_Register"
              exact
              component={RegisterProvider}
            />
            <Route path="/Password" exact component={Password} />
            <Route path="/Verify" exact component={Verify} />
            <Route
              path="/register-covid-19-supplies"
              exact
              component={PPEProvider}
            />
            <Route path="/login-covid-19-supplies" exact component={PPELogin} />
          </div>
        </div>
      );
    }
  }
}
