import React from "react";
//PrimeReact Components
import * as pr from "../../../common/PrimeComponents";
import * as format from "../../../common/FormatFunctions";
import { EnquiriesDisplay } from "./EnquiriesDisplay";
import ReactHtmlParser from "react-html-parser";

export class EnquiriesTable extends React.Component {
  export = () => {
    this.dt.exportCSV();
  };

  getEnquiriesColumns = () => {
    console.log(this.props);
    var allColumns = [];
    // allColumns.push(
    //   <pr.Column
    //     key="expander"
    //     expander={true}
    //     style={{ width: "5em", float: "middle" }}
    //   />
    // );
    if (format.isNotNull(this.props.tableColumns)) {
      let columns = this.props.tableColumns.map((col, i) => {
        return (
          <pr.Column
            key={col.field}
            field={col.field}
            header={col.header}
            sortable={col.sortable}
            filter={col.filter}
            filterMatchMode={col.filterMatchMode}
            style={col.style}
          />
        );
      });

      columns.forEach((e) => {
        allColumns.push(e);
      });
    }
    return allColumns;
  };

  render() {
    return (
      <div className="p-grid bpm">
        <div className="p-col-12">
          <div className="p-col-12">
            <div className="content-section">
              <div className="feature-intro">
                <h1>{"Enquiries"}</h1>
                <pr.Accordion>
                  <pr.AccordionTab header="How to Use">
                    <div className="p-grid">
                      <div className="p-col-12">
                        View below a list of enquiries related to products you
                        need and requirements you may have created. Track the
                        status of enquiries you have made, as well as update
                        potential suppliers on the status of their applications.
                        When you mark an enquiry as successful, the related
                        party will receive your contact details to further the
                        discussions around procurement
                      </div>
                    </div>
                  </pr.AccordionTab>
                </pr.Accordion>
              </div>
            </div>
          </div>
        </div>
        <pr.Dialog
          header={"Product Details"}
          style={{ width: "90%" }}
          footer={
            <div className="p-grid">
              <div className="p-col-12">
                <pr.Button
                  className="blk mrgn flt-r"
                  label="Close"
                  icon="pi-md-check"
                  onClick={() => {
                    this.props.updateState("productDetailsView", false);
                  }}
                />
              </div>
            </div>
          }
          visible={this.props.productDetailsView}
          onHide={() => {
            this.props.updateState("productDetailsView", false);
          }}
          modal={true}
        >
          <div className="p-grid">
            <div className="p-col-10">
              {ReactHtmlParser(this.props.productDetails)}
            </div>
          </div>
        </pr.Dialog>

        <div className="p-col-12">{/** spacing */}</div>
        <>
          <div className="p-col-12 p-md-3"></div>
          <div className="p-col-12 p-md-6">
            <pr.InputText
              id="title"
              value={this.props.globalFilter || ""}
              onChange={(e) => {
                this.props.updateState("globalFilter", e.target.value);
              }}
              placeholder="Type here to search entire table"
              style={{ height: "5vh", backgroundColor: "white" }}
            />
          </div>
        </>
        <div className="p-col-12">
          <pr.DataTable
            ref={(el) => {
              this.dt = el;
            }}
            value={this.props.enquiriesList}
            emptyMessage="No Enquiries"
            expandedRows={this.props.expandedRows}
            onRowToggle={(e) => this.props.updateState("expandedRows", e.data)}
            rowExpansionEnquiries={this.getDisplayCard}
            rows={Infinity}
            autoLayout={true}
            paginator={false}
            alwaysShowPaginator={false}
            responsive={true}
            loading={this.props.loading}
            scrollable={true}
            scrollHeight="60vh"
            globalFilter={this.props.globalFilter}
          >
            <pr.Column
              key={"type"}
              field={"processType"}
              header={"Type"}
              sortable={true}
              filter={true}
              filterMatchMode={"contains"}
              style={{ overflow: "auto" }}
            ></pr.Column>
            <pr.Column
              key={"madeBy"}
              field={"businessNameCodeTag"}
              header={"Made By"}
              sortable={true}
              filter={true}
              filterMatchMode={"contains"}
              style={{ overflow: "auto" }}
            ></pr.Column>
            <pr.Column
              key={"madeTo"}
              field={"businessNameBuyerId"}
              header={"Made To"}
              sortable={true}
              filter={true}
              filterMatchMode={"contains"}
              style={{ overflow: "auto" }}
            ></pr.Column>
            <pr.Column
              key={"productCategory"}
              field={"productCategory"}
              header={"Product Category"}
              sortable={true}
              filter={true}
              filterMatchMode={"contains"}
              style={{ overflow: "auto" }}
            ></pr.Column>
            <pr.Column
              key={"name"}
              field={"productName"}
              header={"Name"}
              sortable={true}
              filter={true}
              filterMatchMode={"contains"}
              style={{ overflow: "auto" }}
            ></pr.Column>
            <pr.Column header="Message" body={this.moreDetails}></pr.Column>
            <pr.Column
              key={"supplierName"}
              field={"dateCreatedToDisplayed"}
              header={"Date Created"}
              sortable={true}
              filter={true}
              filterMatchMode={"contains"}
              style={{ overflow: "auto" }}
            ></pr.Column>
            <pr.Column
              key={"supplierName"}
              field={"dateUpdatedToDisplayed"}
              header={"Last Updated Date"}
              sortable={true}
              filter={true}
              filterMatchMode={"contains"}
              style={{ overflow: "auto" }}
            ></pr.Column>
            <pr.Column
              key={"location"}
              field={"status"}
              header={"Status"}
              sortable={true}
              filter={true}
              filterMatchMode={"contains"}
              style={{ overflow: "auto" }}
            ></pr.Column>

            <pr.Column
              header={"Action"}
              body={this.getApplicationTemplate}
            ></pr.Column>
          </pr.DataTable>
        </div>
      </div>
    );
  }
  moreDetails = (rowData, column) => {
    return (
      <div className="p-grid">
        <div className="p-col-12">
          <pr.Button
            type="button"
            label={"View Details"}
            onClick={(e) => {
              this.props.updateState(
                "productDetails",
                rowData.longDescriptionText
              );
              this.props.updateState("productDetailsView", true);
            }}
            style={{}}
          ></pr.Button>
        </div>
      </div>
    );
  };
  getApplicationTemplate = (rowData, column) => {
    if (rowData.processType === "Received") {
      return (
        <div className="p-grid">
          <div className="p-col-12">
            <pr.Button
              type="button"
              label="Update"
              onClick={(e) => this[rowData.id + "Toggle"].toggle(e)}
            />

            <pr.OverlayPanel
              ref={(el) => (this[rowData.id + "Toggle"] = el)}
              showCloseIcon={false}
              dismissable={true}
              appendTo={document.body}
            >
              <pr.ListBox
                value={rowData.status}
                options={[
                  { label: "Submitted", value: "Submitted" },
                  { label: "Successful", value: "Successful" },
                  { label: "Unsuccessful", value: "Unsuccessful" },
                ]}
                onChange={(e) => {
                  this[rowData.id + "Toggle"].toggle(e);

                  var bean = format.deepCopy(rowData);
                  bean.status = e.value;
                  this.props.updateFromTable(bean);
                }}
              />
            </pr.OverlayPanel>
          </div>
        </div>
      );
    } else {
      return <label></label>;
    }
  };
  getDisplayCard = (data) => {
    return <EnquiriesDisplay data={data} onClick={this.props.crudlControl} />;
  };
}
