import React from "react";
import { PPEProfileCard } from "./UI/PPEProfileCard";

export class PPEProfileView extends React.Component {
  render() {
    return (
      <div
        className="dashboard"
        style={this.props.loading === true ? { pointerEvents: "none" } : {}}
      >
        <PPEProfileCard
          loading={this.props.loading}
          feedback={this.props.feedback}
          receivedFeedback={this.props.receivedFeedback}
          crudlState={this.props.crudlState}
          crudlControl={this.props.crudlControl}
          updateValue={this.props.updateEdited}
          updateState={this.props.updateState}
          editedObject={this.props.editedObject}
          checkRequiredFields={this.props.checkRequiredFields}
          discardChanges={this.props.discardChanges}
          validateState={this.props.validateState}
          validateChanges={this.props.validateChanges}
          crudlExecute={this.props.crudlExecute}
          //
          updatePPEBean={this.props.updatePPEBean}
          ppeBeanList={this.props.ppeBeanList}
          updateBusiness={this.props.updateBusiness}
          updateProfile={this.props.updateProfile}
          steps={this.props.steps}
          activeStep={this.props.activeStep}
          discoveredViaOptions={this.props.discoveredViaOptions}
          countyOptions={this.props.countyOptions}
          sectorOptions={this.props.sectorOptions}
          demographicAreaOptions={this.props.demographicAreaOptions}
        />
      </div>
    );
  }
}
