import React from "react";
//PrimeReact Components
import * as pr from "../../../common/PrimeComponents";
import { FeedbackState } from "../../../common/config/AppConstants";
import * as format from "../../../common/FormatFunctions";
import { LoadScreen } from "../../Common/LoadScreen";
import { Employee } from "../../PPEEmployee/Employee";

export class PPEProfileCard extends React.Component {
  componentDidUpdate = (prevProps) => {
    // Typical usage (don't forget to compare props):
    if (this.props.receivedFeedback !== prevProps.receivedFeedback) {
      switch (this.props.receivedFeedback.state) {
        case FeedbackState.EMPTY:
          this.props.feedback.showEmpty(
            this.growl,
            this.props.receivedFeedback.display
          );
          break;
        case FeedbackState.SUCCESS:
          this.props.feedback.showSuccess(
            this.growl,
            this.props.receivedFeedback.display,
            this.props.receivedFeedback.crudlStatus
          );
          break;
        case FeedbackState.ERROR:
          this.props.feedback.showError(
            this.growl,
            this.props.receivedFeedback.display,
            this.props.receivedFeedback.crudlStatus
          );
          break;
        default:
          break;
      }
    }
  };

  stepOneRequirementsDisabled = () => {
    if (format.isNotNull(this.props.editedObject)) {
      // ADD REQUIREDD FIELDS HERE AS APPROPRIATE

      return format.validateValues([
        this.props.editedObject.businessName,
        this.props.editedObject.businessDescription,
        this.props.editedObject.addressLine1,
        this.props.editedObject.city,
        this.props.editedObject.countyState,
        this.props.editedObject.demographicArea,
        this.props.editedObject.postalZipCode,
        this.props.editedObject.email,
        this.props.editedObject.websiteUrl,
      ]);
    } else {
      return true;
    }
  };
  stepTwoRequirementsDisabled = () => {
    if (format.isNotNull(this.props.ppeBeanList)) {
      // ADD REQUIREDD FIELDS HERE AS APPROPRIATE
      var disabled = true;
      this.props.ppeBeanList.forEach((element) => {
        if (element.checked === true) {
          disabled = false;
        }
      });

      return disabled;
    } else {
      return true;
    }
  };

  render() {
    return (
      <div className="card" id="login-buy">
        <div className="p-grid dashboard">
          {this.props.loading === true && <LoadScreen />}
          {this.props.loading !== true && (
            <React.Fragment>
              <div className="p-col-12">
                <pr.TabView style={{ border: "1px solid #d8d8d8" }}>
                  <pr.TabPanel leftIcon="pi pi-home" header="Business Details">
                    <div className="p-grid dashboard">
                      <div className="p-col-6">
                        <h1 style={{ lineHeight: "40px", margin: "0" }}>
                          {this.props.editedObject.businessName}
                        </h1>
                      </div>
                      <div className="p-col-6">
                        <pr.Button
                          label="Save"
                          icon={"pi pi-save"}
                          style={{ float: "right" }}
                          onClick={(e) => {
                            this.props.updateBusiness();
                          }}
                        />
                      </div>
                      <div className="p-col-12">
                        <div className="p-col-12 card">
                          <div className="p-col-12">
                            <div className="p-grid">
                              <div className="p-col-3">
                                <label
                                  htmlFor="businessDescription"
                                  className="custom-label"
                                >
                                  Business Description *
                                </label>
                              </div>
                              <div className="p-col-9">
                                <pr.InputTextarea
                                  id="businessDescription"
                                  rows={5}
                                  value={
                                    this.props.editedObject
                                      .businessDescription || ""
                                  }
                                  onChange={(e) => {
                                    this.props.updateValue(
                                      e.target.id,
                                      e.target.value
                                    );
                                  }}
                                  style={{ resize: "none" }}
                                />
                                <label htmlFor="shortDescription">
                                  Please tell us about your business in one
                                  sentence
                                </label>
                              </div>
                            </div>
                          </div>
                          {this.props.editedObject.buyer && (
                            <div className="p-col-12">
                              <div className="p-grid">
                                <div className="p-col-3">
                                  <label
                                    htmlFor="countyState"
                                    className="custom-label"
                                  >
                                    Sector *
                                  </label>
                                </div>
                                <div className="p-col-9">
                                  <pr.Dropdown
                                    id="sector"
                                    className="required"
                                    value={this.props.editedObject.sector}
                                    options={this.props.sectorOptions}
                                    onChange={(e) => {
                                      this.props.updateValue("sector", e.value);
                                    }}
                                    filter={false}
                                    filterBy="label"
                                    filterPlaceholder="Search"
                                    style={{ marginTop: "1px", width: "100%" }}
                                  />
                                  <label htmlFor="shortDescription">
                                    Please choose one
                                  </label>
                                </div>
                              </div>
                            </div>
                          )}
                          {this.props.editedObject.supplier && (
                            <div className="p-col-12">
                              <div className="p-grid">
                                <div className="p-col-3">
                                  <label
                                    htmlFor="countyState"
                                    className="custom-label"
                                  >
                                    Supplier Type? *
                                  </label>
                                </div>
                                <div className="p-col-9">
                                  <pr.Dropdown
                                    id="businessType"
                                    className="required"
                                    value={this.props.editedObject.subType}
                                    options={[
                                      {
                                        label: "Manufacturer",
                                        value: "Manufacturer",
                                      },
                                      {
                                        label: "Distributor",
                                        value: "Distributor",
                                      },
                                      { label: "Other", value: "Other" },
                                    ]}
                                    onChange={(e) => {
                                      this.props.updateValue(
                                        "subType",
                                        e.value
                                      );
                                    }}
                                    filter={false}
                                    filterBy="label"
                                    filterPlaceholder="Search"
                                    style={{ marginTop: "1px", width: "100%" }}
                                  />
                                  <label htmlFor="shortDescription">
                                    How would you best describe your business?
                                  </label>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="p-col-12">
                            <div className="p-grid">
                              <div className="p-col-3">
                                <label
                                  htmlFor="addressLine1"
                                  className="custom-label"
                                >
                                  Address Line 1 *
                                </label>
                              </div>
                              <div className="p-col-9">
                                <pr.InputText
                                  id="addressLine1"
                                  value={
                                    this.props.editedObject.addressLine1 || ""
                                  }
                                  onChange={(e) => {
                                    this.props.updateValue(
                                      e.target.id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="p-col-12">
                            <div className="p-grid">
                              <div className="p-col-3">
                                <label
                                  htmlFor="addressLine2"
                                  className="custom-label"
                                >
                                  Address Line 2
                                </label>
                              </div>
                              <div className="p-col-9">
                                <pr.InputText
                                  id="addressLine2"
                                  value={
                                    this.props.editedObject.addressLine2 || ""
                                  }
                                  onChange={(e) => {
                                    this.props.updateValue(
                                      e.target.id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="p-col-12">
                            <div className="p-grid">
                              <div className="p-col-3">
                                <label htmlFor="city" className="custom-label">
                                  City *
                                </label>
                              </div>
                              <div className="p-col-9">
                                <pr.InputText
                                  id="city"
                                  className="required"
                                  value={this.props.editedObject.city || ""}
                                  onChange={(e) => {
                                    this.props.updateValue(
                                      e.target.id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="p-col-12">
                            <div className="p-grid">
                              <div className="p-col-3">
                                <label
                                  htmlFor="countyState"
                                  className="custom-label"
                                >
                                  County *
                                </label>
                              </div>
                              <div className="p-col-9">
                                <pr.Dropdown
                                  id="countyState"
                                  className="required"
                                  value={this.props.editedObject.countyState}
                                  options={this.props.countyOptions}
                                  onChange={(e) => {
                                    this.props.updateValue(
                                      "countyState",
                                      e.value
                                    );
                                  }}
                                  filter={false}
                                  filterBy="label"
                                  filterPlaceholder="Search"
                                  style={{ marginTop: "1px", width: "100%" }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="p-col-12">
                            <div className="p-grid">
                              <div className="p-col-3">
                                <label
                                  htmlFor="demographicArea"
                                  className="custom-label"
                                >
                                  Demographic Area *
                                </label>
                              </div>
                              <div className="p-col-9">
                                <pr.Dropdown
                                  id="demographicArea"
                                  className="required"
                                  value={
                                    this.props.editedObject.demographicArea
                                  }
                                  options={this.props.demographicAreaOptions}
                                  onChange={(e) => {
                                    this.props.updateValue(
                                      "demographicArea",
                                      e.value
                                    );
                                  }}
                                  filter={false}
                                  filterBy="label"
                                  filterPlaceholder="Search"
                                  style={{ marginTop: "1px", width: "100%" }}
                                />
                                <label htmlFor="shortDescription">
                                  Please select where your business is located
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="p-col-12">
                            <div className="p-grid">
                              <div className="p-col-3">
                                <label
                                  htmlFor="postalZipCode"
                                  className="custom-label"
                                >
                                  Postcode *
                                </label>
                              </div>
                              <div className="p-col-9">
                                <pr.InputText
                                  id="postalZipCode"
                                  className="required"
                                  value={
                                    this.props.editedObject.postalZipCode || ""
                                  }
                                  onChange={(e) => {
                                    this.props.updateValue(
                                      e.target.id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="p-col-12">
                            <div className="p-grid">
                              <div className="p-col-3">
                                <label htmlFor="email" className="custom-label">
                                  Business Email *
                                </label>
                              </div>
                              <div className="p-col-9">
                                <pr.InputText
                                  id="email"
                                  className="required"
                                  value={this.props.editedObject.email || ""}
                                  onChange={(e) => {
                                    this.props.updateValue(
                                      e.target.id,
                                      e.target.value
                                    );
                                  }}
                                />
                                <label htmlFor="shortDescription">
                                  Try not to use personal emails, this
                                  information will be made available to users of
                                  the portal
                                </label>
                              </div>

                              {this.props.editedObject.email &&
                              (!this.props.editedObject.email.includes("@") ||
                                !this.props.editedObject.email.includes(
                                  "."
                                )) ? (
                                <label style={{ color: "red" }}>
                                  Incorrect email format
                                </label>
                              ) : (
                                <label />
                              )}
                            </div>
                          </div>
                          <div className="p-col-12">
                            <div className="p-grid">
                              <div className="p-col-3">
                                <label
                                  htmlFor="addressLine1"
                                  className="custom-label"
                                >
                                  Website Url *
                                </label>
                              </div>
                              <div className="p-col-9">
                                <pr.InputText
                                  id="websiteUrl"
                                  value={
                                    this.props.editedObject.websiteUrl || ""
                                  }
                                  onChange={(e) => {
                                    this.props.updateValue(
                                      e.target.id,
                                      e.target.value
                                    );
                                  }}
                                />
                                <label htmlFor="shortDescription">
                                  Please include https:// or http:// when
                                  filling out this field
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="p-col-12">
                            <div className="p-grid">
                              <div className="p-col-3">
                                <label
                                  htmlFor="telephoneNumber"
                                  className="custom-label"
                                >
                                  Telephone Number
                                </label>
                              </div>
                              <div className="p-col-9">
                                <pr.InputText
                                  id="telephoneNumber"
                                  className="required"
                                  value={
                                    this.props.editedObject.telephoneNumber ||
                                    ""
                                  }
                                  onChange={(e) => {
                                    this.props.updateValue(
                                      e.target.id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="p-col-12">
                            <div className="p-grid">
                              <div className="p-col-3">
                                <label
                                  htmlFor="discoveredVia"
                                  className="custom-label"
                                >
                                  Source
                                </label>
                              </div>
                              <div className="p-col-9">
                                <pr.Dropdown
                                  id="discoveredVia"
                                  className="required"
                                  value={this.props.editedObject.discoveredVia}
                                  options={this.props.discoveredViaOptions}
                                  onChange={(e) => {
                                    this.props.updateValue(
                                      "discoveredVia",
                                      e.value
                                    );
                                  }}
                                  filter={false}
                                  filterBy="label"
                                  filterPlaceholder="Search"
                                  style={{ marginTop: "1px", width: "100%" }}
                                />
                                <label htmlFor="shortDescription">
                                  Where did you find out about BuySupplyNI? *
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="p-col-12">
                          <pr.Button
                            label="Save"
                            icon={"pi pi-save"}
                            style={{ float: "right" }}
                            onClick={(e) => {
                              this.props.updateBusiness();
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </pr.TabPanel>
                  <pr.TabPanel leftIcon="pi pi-plus-circle" header="Categories">
                    <div className="p-grid dashboard">
                      <div className="p-col-6">
                        <h1 style={{ lineHeight: "40px", margin: "0" }}>
                          {"Categories"}
                        </h1>
                      </div>
                      <div className="p-col-6">
                        <pr.Button
                          label="Save"
                          icon={"pi pi-save"}
                          style={{ float: "right" }}
                          onClick={(e) => {
                            this.props.updateProfile();
                          }}
                        />
                      </div>
                      <div className="p-col-12 card">
                        <div className="p-col-12 p-grid">
                          <div className="p-col-3">
                            <label
                              htmlFor="businessDescription"
                              className="custom-label"
                            >
                              I would like to supply products and services
                            </label>
                          </div>
                          <div className="p-col-9">
                            <pr.Checkbox
                              id="supplier"
                              rows={5}
                              checked={
                                this.props.editedObject.supplier || false
                              }
                              onChange={(e) => {
                                this.props.updateValue("supplier", e.checked);
                              }}
                              style={{ resize: "none" }}
                            />
                          </div>
                        </div>
                        <div className="p-col-12 p-grid">
                          <div className="p-col-3">
                            <label
                              htmlFor="businessDescription"
                              className="custom-label"
                            >
                              I would like to buy products and services
                            </label>
                          </div>
                          <div className="p-col-9">
                            <pr.Checkbox
                              id="buyer"
                              rows={5}
                              checked={this.props.editedObject.buyer || false}
                              onChange={(e) => {
                                this.props.updateValue("buyer", e.checked);
                              }}
                              style={{ resize: "none" }}
                            />
                          </div>
                        </div>
                        <label
                          htmlFor="businessDescription"
                          className="custom-label"
                        >
                          ** Changes here will require a logout and login to
                          take effect
                        </label>
                      </div>
                      <div className="p-col-12">
                        <pr.Accordion activeIndex={0}>
                          <pr.AccordionTab header="PPE">
                            <div className="p-grid">
                              {this.displayPPEOptions(
                                this.props.ppeBeanList,
                                "PPE"
                              )}
                            </div>
                          </pr.AccordionTab>
                        </pr.Accordion>
                        <pr.Accordion activeIndex={0}>
                          <pr.AccordionTab header="Social Distancing Materials">
                            <div className="p-grid">
                              {this.displayPPEOptions(
                                this.props.ppeBeanList,
                                "Social Distancing Materials"
                              )}
                            </div>
                          </pr.AccordionTab>
                        </pr.Accordion>
                        <pr.Accordion activeIndex={0}>
                          <pr.AccordionTab header="Other Covid-19 Related Products">
                            <div className="p-grid">
                              {this.displayPPEOptions(
                                this.props.ppeBeanList,
                                "Other Covid-19 Related Products"
                              )}
                            </div>
                          </pr.AccordionTab>
                        </pr.Accordion>
                      </div>
                      <div className="p-col-12">
                        <pr.Button
                          label="Save"
                          icon={"pi pi-save"}
                          style={{ float: "right" }}
                          onClick={(e) => {
                            this.props.updateProfile();
                          }}
                        />
                      </div>
                    </div>
                  </pr.TabPanel>
                  <pr.TabPanel leftIcon="pi pi-users" header="Manage Users">
                    <div className="p-grid dashboard">
                      <div className="p-col-12">
                        <h1 style={{ lineHeight: "40px", margin: "0" }}>
                          {"Manage Users"}
                        </h1>
                      </div>
                      <pr.Accordion style={{ width: "100%" }}>
                        <pr.AccordionTab header="How to Use">
                          <div className="p-grid">
                            <div className="p-col-12">
                              Add other members of your team in order for them
                              to manage your business. Simply add them via the
                              portal. User will receive an email to reset their
                              password. Once completed, tell them to visit the
                              PPE Login screen and use their details
                            </div>
                          </div>
                        </pr.AccordionTab>
                      </pr.Accordion>

                      <div className="card">
                        <Employee></Employee>
                      </div>
                    </div>
                  </pr.TabPanel>
                </pr.TabView>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }

  displayPPEOptions = (ppeBeanList, heading) => {
    var componentArray = [];
    var index = 0;

    if (format.isNotNull(ppeBeanList)) {
      ppeBeanList.forEach((element) => {
        if (element.header === heading) {
          componentArray[index] = (
            <div className="p-col-12 p-grid">
              <div className="p-col-4">
                <label htmlFor={element.name} className="input-label">
                  {element.name}
                </label>
              </div>
              <div className="p-col-4">
                <pr.Checkbox
                  id={element.name}
                  checked={element.checked}
                  onChange={(e) => {
                    this.props.updatePPEBean(element.id, "checked", e.checked);
                  }}
                ></pr.Checkbox>
              </div>
            </div>
          );
          index++;
        }
      });
    }

    return componentArray;
  };

  filterOptions = (list) => {
    var returnList = [];
    if (format.isNotNull(list)) {
      list.forEach((element) => {
        if (element.checked) {
          returnList.push(element);
        }
      });
    }

    return returnList;
  };
}
