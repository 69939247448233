import React, { Component } from "react";
//PrimeReact Components
import * as pr from "../../common/PrimeComponents";
//Common Functions
import { api } from "../../common/CloudService/ApiPromisify";
import { LoadScreen } from "../Common/LoadScreen";
//ClientLibsReference
import { CloudService } from "../../common/CloudService/ClientLibsReference";

export class CouncilGapAnalysis extends Component {
  constructor() {
    super();
    this.state = {
      showTutorial: false,
      //Loading
      loaded: true,
      editLoaded: true,
      loadedOverall: false,
      loadedOverallArchived: false,
      loadedBuyer: false,
      loadedBuyerArchived: false,
      loadedSupplier: false,
      loadedSupplierArchived: false,
      //Display
      showDialog: false,
      toggleOverall: true,
      toggleBuyer: true,
      toggleSupplier: true,
      //Details
      //Overall
      matrixListOverall: [],
      overallArchived: "",
      overallCodeTag: "",
      overallDateCreated: "",
      overallDateCreatedToDisplay: "",
      overallId: "",
      overallLastUpdatedDateTime: "",
      overallMatrixType: "",
      overallPdfUrl: "",
      overallUserId: "",
      matrixListOverallArchived: [],
      //Buyer
      matrixListBuyer: [],
      buyerArchived: "",
      buyerCodeTag: "",
      buyerDateCreated: "",
      buyerDateCreatedToDisplay: "",
      buyerId: "",
      buyerLastUpdatedDateTime: "",
      buyerMatrixType: "",
      buyerPdfUrl: "",
      buyerUserId: "",
      matrixListBuyerArchived: [],
      //Supplier
      matrixListSupplier: [],
      supplierArchived: "",
      supplierCodeTag: "",
      supplierDateCreated: "",
      supplierDateCreatedToDisplay: "",
      supplierId: "",
      supplierLastUpdatedDateTime: "",
      supplierMatrixType: "",
      supplierPdfUrl: "",
      supplierUserId: "",
      matrixListSupplierArchived: [],
    };
  }

  componentDidMount = () => {
    if (
      localStorage.getItem("roleType") === "10" ||
      localStorage.getItem("roleType") === "20"
    ) {
      this.getOverallMatrix();
      this.getOverallArchivedMatrix();
      this.getBuyerMatrix();
      this.getBuyerArchivedMatrix();
      this.getSupplierMatrix();
      this.getSupplierArchivedMatrix();
    }
  };

  getOverallMatrix = async () => {
    this.setState({ loadedOverall: false });

    let matrixRequest = new CloudService.MatrixRequest();
    matrixRequest.codeTag = localStorage.getItem("globalBid");
    matrixRequest.userId = localStorage.getItem("userId");
    matrixRequest.matrixType = "Overall";

    await api("matrixApiV1MatrixGetByMatrixType", matrixRequest).then((res) => {
      if (res.data) {
        let matrixResponse = CloudService.MatrixResponse.constructFromObject(
          res.data
        );

        if (matrixResponse.errorCode === 0) {
          this.setState(
            {
              matrixListOverall: matrixResponse.matrixList,
            },
            () => {
              if (Array.isArray(this.state.matrixListOverall)) {
                this.state.matrixListOverall.forEach((e) => {
                  if (e.archived === false) {
                    this.setState({
                      overallArchived: e.archived,
                      overallCodeTag: e.codeTag,
                      overallDateCreated: e.dateCreated,
                      overallDateCreatedToDisplay: e.dateCreatedToDisplay,
                      overallId: e.id,
                      overallLastUpdatedDateTime: e.lastUpdatedDateTime,
                      overallMatrixType: e.matrixType,
                      overallPdfUrl: e.pdfUrl,
                      overallUserId: e.userId,
                    });
                  }
                });
              }
            }
          );
        } else {
          this.growl.show({
            severity: "error",
            summary: "Error",
            detail:
              "An error occured retrieving overall matrix, please refresh and try again",
            life: 3000,
          });
        }
      } else {
        console.log("matrixApiV1MatrixGetByMatrixType has failed");
      }
    });

    this.setState({ loadedOverall: true });
  };

  getOverallArchivedMatrix = async () => {
    this.setState({ loadedOverallArchived: false });

    let matrixRequest = new CloudService.MatrixRequest();
    matrixRequest.codeTag = localStorage.getItem("globalBid");
    matrixRequest.userId = localStorage.getItem("userId");
    matrixRequest.matrixType = "Overall";

    await api(
      "matrixApiV1MatrixGetByArchivedAndMatrixType",
      matrixRequest
    ).then((res) => {
      if (res.data) {
        let matrixResponse = CloudService.MatrixResponse.constructFromObject(
          res.data
        );

        if (matrixResponse.errorCode === 0) {
          this.setState({
            matrixListOverallArchived: matrixResponse.matrixList,
          });
        } else {
          this.growl.show({
            severity: "error",
            summary: "Error",
            detail:
              "An error occured retrieving archived overall matrices, please refresh and try again",
            life: 3000,
          });
        }
      } else {
        console.log("matrixApiV1MatrixGetByArchivedAndMatrixType has failed");
      }
    });

    this.setState({ loadedOverallArchived: true });
  };

  getBuyerMatrix = async () => {
    this.setState({ loadedBuyer: false });

    let matrixRequest = new CloudService.MatrixRequest();
    matrixRequest.codeTag = localStorage.getItem("globalBid");
    matrixRequest.userId = localStorage.getItem("userId");
    matrixRequest.matrixType = "Buyer";

    await api("matrixApiV1MatrixGetByMatrixType", matrixRequest).then((res) => {
      if (res.data) {
        let matrixResponse = CloudService.MatrixResponse.constructFromObject(
          res.data
        );

        if (matrixResponse.errorCode === 0) {
          this.setState(
            {
              matrixListBuyer: matrixResponse.matrixList,
            },
            () => {
              if (Array.isArray(this.state.matrixListBuyer)) {
                this.state.matrixListBuyer.forEach((e) => {
                  if (e.archived === false) {
                    this.setState({
                      buyerArchived: e.archived,
                      buyerCodeTag: e.codeTag,
                      buyerDateCreated: e.dateCreated,
                      buyerDateCreatedToDisplay: e.dateCreatedToDisplay,
                      buyerId: e.id,
                      buyerLastUpdatedDateTime: e.lastUpdatedDateTime,
                      buyerMatrixType: e.matrixType,
                      buyerPdfUrl: e.pdfUrl,
                      buyerUserId: e.userId,
                    });
                  }
                });
              }
            }
          );
        } else {
          this.growl.show({
            severity: "error",
            summary: "Error",
            detail:
              "An error occured retrieving buyer matrix, please refresh and try again",
            life: 3000,
          });
        }
      } else {
        console.log("matrixApiV1MatrixGetByMatrixType has failed");
      }
    });

    this.setState({ loadedBuyer: true });
  };

  getBuyerArchivedMatrix = async () => {
    this.setState({ loadedBuyerArchived: false });

    let matrixRequest = new CloudService.MatrixRequest();
    matrixRequest.codeTag = localStorage.getItem("globalBid");
    matrixRequest.userId = localStorage.getItem("userId");
    matrixRequest.matrixType = "Buyer";

    await api(
      "matrixApiV1MatrixGetByArchivedAndMatrixType",
      matrixRequest
    ).then((res) => {
      if (res.data) {
        let matrixResponse = CloudService.MatrixResponse.constructFromObject(
          res.data
        );

        if (matrixResponse.errorCode === 0) {
          this.setState({
            matrixListBuyerArchived: matrixResponse.matrixList,
          });
        } else {
          this.growl.show({
            severity: "error",
            summary: "Error",
            detail:
              "An error occured retrieving archived buyer matrices, please refresh and try again",
            life: 3000,
          });
        }
      } else {
        console.log("matrixApiV1MatrixGetByArchivedAndMatrixType has failed");
      }
    });

    this.setState({ loadedBuyerArchived: true });
  };

  getSupplierMatrix = async () => {
    this.setState({ loadedSupplier: false });

    let matrixRequest = new CloudService.MatrixRequest();
    matrixRequest.codeTag = localStorage.getItem("globalBid");
    matrixRequest.userId = localStorage.getItem("userId");
    matrixRequest.matrixType = "Supplier";

    await api("matrixApiV1MatrixGetByMatrixType", matrixRequest).then((res) => {
      if (res.data) {
        let matrixResponse = CloudService.MatrixResponse.constructFromObject(
          res.data
        );

        if (matrixResponse.errorCode === 0) {
          this.setState(
            {
              matrixListSupplier: matrixResponse.matrixList,
            },
            () => {
              if (Array.isArray(this.state.matrixListSupplier)) {
                this.state.matrixListSupplier.forEach((e) => {
                  if (e.archived === false) {
                    this.setState({
                      supplierArchived: e.archived,
                      supplierCodeTag: e.codeTag,
                      supplierDateCreated: e.dateCreated,
                      supplierDateCreatedToDisplay: e.dateCreatedToDisplay,
                      supplierId: e.id,
                      supplierLastUpdatedDateTime: e.lastUpdatedDateTime,
                      supplierMatrixType: e.matrixType,
                      supplierPdfUrl: e.pdfUrl,
                      supplierUserId: e.userId,
                    });
                  }
                });
              }
            }
          );
        } else {
          this.growl.show({
            severity: "error",
            summary: "Error",
            detail:
              "An error occured retrieving supplier matrix, please refresh and try again",
            life: 3000,
          });
        }
      } else {
        console.log("matrixApiV1MatrixGetByMatrixType has failed");
      }
    });

    this.setState({ loadedSupplier: true });
  };

  getSupplierArchivedMatrix = async () => {
    this.setState({ loadedSupplierArchived: false });

    let matrixRequest = new CloudService.MatrixRequest();
    matrixRequest.codeTag = localStorage.getItem("globalBid");
    matrixRequest.userId = localStorage.getItem("userId");
    matrixRequest.matrixType = "Supplier";

    await api(
      "matrixApiV1MatrixGetByArchivedAndMatrixType",
      matrixRequest
    ).then((res) => {
      if (res.data) {
        let matrixResponse = CloudService.MatrixResponse.constructFromObject(
          res.data
        );

        if (matrixResponse.errorCode === 0) {
          this.setState({
            matrixListSupplierArchived: matrixResponse.matrixList,
          });
        } else {
          this.growl.show({
            severity: "error",
            summary: "Error",
            detail:
              "An error occured retrieving archived supplier matrices, please refresh and try again",
            life: 3000,
          });
        }
      } else {
        console.log("matrixApiV1MatrixGetByArchivedAndMatrixType has failed");
      }
    });

    this.setState({ loadedSupplierArchived: true });
  };

  updateOverallMatrix = async () => {
    this.setState({ loadedOverall: false, loadedOverallArchived: false });

    let matrixRequest = new CloudService.MatrixRequest();
    matrixRequest.codeTag = localStorage.getItem("globalBid");
    matrixRequest.userId = localStorage.getItem("userId");
    matrixRequest.matrixType = "Overall";

    await api("matrixApiV1MatrixCreate", matrixRequest).then((res) => {
      if (res.data) {
        let matrixResponse = CloudService.MatrixResponse.constructFromObject(
          res.data
        );
        console.log(matrixResponse);

        if (matrixResponse.errorCode === 0) {
          this.growl.show({
            severity: "success",
            summary: "Success",
            detail: "Overall matrix updated successfully",
            life: 3000,
          });
          this.getOverallMatrix();
          this.getOverallArchivedMatrix();
        } else {
          this.setState(
            { loadedOverall: true, loadedOverallArchived: true },
            () => {
              this.growl.show({
                severity: "error",
                summary: "Error",
                detail:
                  "Overall matrix failed to update, please refresh and try again",
                life: 3000,
              });
            }
          );
        }
      } else {
        console.log("matrixApiV1MatrixCreate has failed");
      }
    });
  };

  updateBuyerMatrix = async () => {
    this.setState({ loadedBuyer: false, loadedBuyerArchived: false });

    let matrixRequest = new CloudService.MatrixRequest();
    matrixRequest.codeTag = localStorage.getItem("globalBid");
    matrixRequest.userId = localStorage.getItem("userId");
    matrixRequest.matrixType = "Buyer";

    await api("matrixApiV1MatrixCreate", matrixRequest).then((res) => {
      if (res.data) {
        let matrixResponse = CloudService.MatrixResponse.constructFromObject(
          res.data
        );
        console.log(matrixResponse);

        if (matrixResponse.errorCode === 0) {
          this.growl.show({
            severity: "success",
            summary: "Success",
            detail: "Buyer matrix updated successfully",
            life: 3000,
          });
          this.getBuyerMatrix();
          this.getBuyerArchivedMatrix();
        } else {
          this.setState(
            { loadedBuyer: true, loadedBuyerArchived: true },
            () => {
              this.growl.show({
                severity: "error",
                summary: "Error",
                detail:
                  "Buyer matrix failed to update, please refresh and try again",
                life: 3000,
              });
            }
          );
        }
      } else {
        console.log("matrixApiV1MatrixCreate has failed");
      }
    });
  };

  updateSupplierMatrix = async () => {
    this.setState({ loadedSupplier: false, loadedSupplierArchived: false });

    let matrixRequest = new CloudService.MatrixRequest();
    matrixRequest.codeTag = localStorage.getItem("globalBid");
    matrixRequest.userId = localStorage.getItem("userId");
    matrixRequest.matrixType = "Supplier";

    await api("matrixApiV1MatrixCreate", matrixRequest).then((res) => {
      if (res.data) {
        let matrixResponse = CloudService.MatrixResponse.constructFromObject(
          res.data
        );
        console.log(matrixResponse);

        if (matrixResponse.errorCode === 0) {
          this.growl.show({
            severity: "success",
            summary: "Success",
            detail: "Supplier matrix updated successfully",
            life: 3000,
          });
          this.getSupplierMatrix();
          this.getSupplierArchivedMatrix();
        } else {
          this.setState(
            { loadedSupplier: true, loadedSupplierArchived: true },
            () => {
              this.growl.show({
                severity: "error",
                summary: "Error",
                detail:
                  "Supplier matrix failed to update, please refresh and try again",
                life: 3000,
              });
            }
          );
        }
      } else {
        console.log("matrixApiV1MatrixCreate has failed");
      }
    });
  };

  render() {
    const s = this.state;

    return (
      <div
        className="p-grid dashboard bpm"
        style={s.editLoaded === false ? { pointerEvents: "none" } : {}}
      >
        <pr.Messages
          style={{ textAlign: "left" }}
          ref={(el) => (this.growl = el)}
        />
        <pr.Dialog
          header="Gap Analysis Tutorial"
          footer={
            <pr.Button
              className="secondary-btn"
              label="Close"
              icon="pi pi-times"
              onClick={() => {
                this.setState({ showTutorial: false });
              }}
              style={{
                float: "right",
              }}
            />
          }
          visible={this.state.showTutorial}
          onHide={() => {
            this.setState({ showTutorial: false });
          }}
          modal={true}
          blockScroll={true}
          style={{ width: "80vw" }}
        >
          <video width="100%" height="98%" controls>
            <source
              src="/assets/layout/images/council/gap-analysis.mp4"
              type="video/webm"
            />
            Your browser does not support video elements, please update to the
            latest version
          </video>
        </pr.Dialog>
        <div className="p-col-12">
          {/*Overall*/}
          {s.loadedOverall === false ? (
            <LoadScreen />
          ) : (
            <div className="p-col-12 card shadow-box p-shadow-2">
              <div className="p-col-12 card">
                <div className="p-col-12">
                  {/* <pr.Button
                    icon="pi pi-info"
                    onClick={() => {
                      this.setState({ showTutorial: true });
                    }}
                    style={{ fontSize: "10px", float: "right" }}
                  /> */}
                  <center>
                    <label className="header-label">Overall Gap Analysis</label>
                  </center>
                </div>
              </div>
              <div className="p-grid">
                <div className="p-col-12 p-md-4">
                  <center>
                    <b>{s.overallDateCreatedToDisplay}</b>
                  </center>
                </div>
                <div className="p-col-12 p-md-4">
                  <pr.Button
                    label="View PDF"
                    icon="pi pi-download"
                    onClick={() => {
                      window.open(s.overallPdfUrl);
                    }}
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="p-col-12 p-md-4">
                  <pr.Button
                    disabled={
                      !this.state.loadedOverall ||
                      !this.state.loadedBuyer ||
                      !this.state.loadedSupplier
                    }
                    label="Update Overall"
                    icon={
                      this.state.editLoaded === false
                        ? "pi pi-spin pi-spinner"
                        : "pi pi-upload"
                    }
                    onClick={() => {
                      this.updateOverallMatrix();
                    }}
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
              <div className="p-col-12">
                <pr.Fieldset
                  legend="Legacy Reports"
                  toggleable={true}
                  collapsed={s.toggleOverall}
                  onCollapse={() => {
                    this.setState({
                      toggleOverall: !this.state.toggleOverall,
                    });
                  }}
                >
                  {s.loadedOverallArchived === false ? (
                    <LoadScreen />
                  ) : (
                    <pr.DataTable
                      value={s.matrixListOverallArchived}
                      selectionMode="single"
                      header={
                        <div className="p-grid">
                          <div className="p-col-12">
                            <label className="header-label">Matrices</label>
                          </div>
                        </div>
                      }
                      emptyMessage="No Matrices"
                      rows={100}
                      autoLayout={true}
                      paginator={true}
                      alwaysShowPaginator={true}
                      responsive={true}
                    >
                      <pr.Column
                        field="dateCreatedToDisplay"
                        header="Date Created"
                        body={(e) => {
                          return <center>{e.dateCreatedToDisplay}</center>;
                        }}
                        sortable={true}
                        filter={true}
                        filterMatchMode="contains"
                        style={{ overflow: "auto" }}
                      />
                      <pr.Column
                        field="pdfUrl"
                        header="PDF"
                        body={(e) => {
                          return (
                            <a href={e.pdfUrl}>
                              <pr.Button
                                label="View PDF"
                                style={{ width: "100%" }}
                              />
                            </a>
                          );
                        }}
                        sortable={false}
                        filter={false}
                        filterMatchMode="contains"
                        style={{ overflow: "hidden" }}
                      />
                    </pr.DataTable>
                  )}
                </pr.Fieldset>
              </div>
            </div>
          )}

          {/*Buyer*/}
          {s.loadedBuyer === false ? (
            <LoadScreen />
          ) : (
            <div className="p-col-12 card shadow-box p-shadow-2">
              <div className="p-col-12 card">
                <div className="p-col-12">
                  <center>
                    <label className="header-label">Buyer Gap Analysis</label>
                  </center>
                </div>
              </div>
              <div className="p-grid">
                <div className="p-col-12 p-md-4">
                  <center>
                    <b>{s.buyerDateCreatedToDisplay}</b>
                  </center>
                </div>
                <div className="p-col-12 p-md-4">
                  <pr.Button
                    label="View PDF"
                    icon="pi pi-download"
                    onClick={() => {
                      window.open(s.buyerPdfUrl);
                    }}
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="p-col-12 p-md-4">
                  <pr.Button
                    disabled={
                      !this.state.loadedOverall ||
                      !this.state.loadedBuyer ||
                      !this.state.loadedSupplier
                    }
                    label="Update Buyer"
                    icon={
                      this.state.editLoaded === false
                        ? "pi pi-spin pi-spinner"
                        : "pi pi-upload"
                    }
                    onClick={() => {
                      this.updateBuyerMatrix();
                    }}
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
              <div className="p-col-12">
                <pr.Fieldset
                  legend="Legacy Reports"
                  toggleable={true}
                  collapsed={s.toggleBuyer}
                  onCollapse={() => {
                    this.setState({ toggleBuyer: !this.state.toggleBuyer });
                  }}
                >
                  {s.loadedBuyerArchived === false ? (
                    <LoadScreen />
                  ) : (
                    <pr.DataTable
                      value={s.matrixListBuyerArchived}
                      selectionMode="single"
                      header={
                        <div className="p-grid">
                          <div className="p-col-12">
                            <label className="header-label">Matrices</label>
                          </div>
                        </div>
                      }
                      emptyMessage="No Matrices"
                      rows={100}
                      autoLayout={true}
                      paginator={true}
                      alwaysShowPaginator={true}
                      responsive={true}
                    >
                      <pr.Column
                        field="dateCreatedToDisplay"
                        header="Date Created"
                        body={(e) => {
                          return <center>{e.dateCreatedToDisplay}</center>;
                        }}
                        sortable={true}
                        filter={true}
                        filterMatchMode="contains"
                        style={{ overflow: "auto" }}
                      />
                      <pr.Column
                        field="pdfUrl"
                        header="PDF"
                        body={(e) => {
                          return (
                            <a href={e.pdfUrl}>
                              <pr.Button
                                label="View PDF"
                                style={{ width: "100%" }}
                              />
                            </a>
                          );
                        }}
                        sortable={false}
                        filter={false}
                        filterMatchMode="contains"
                        style={{ overflow: "hidden" }}
                      />
                    </pr.DataTable>
                  )}
                </pr.Fieldset>
              </div>
            </div>
          )}

          {/*Supplier*/}
          {s.loadedSupplier === false ? (
            <LoadScreen />
          ) : (
            <div className="p-col-12 card shadow-box p-shadow-2">
              <div className="p-col-12 card">
                <div className="p-col-12">
                  <center>
                    <label className="header-label">
                      Supplier Gap Analysis
                    </label>
                  </center>
                </div>
              </div>
              <div className="p-grid">
                <div className="p-col-12 p-md-4">
                  <center>
                    <b>{s.supplierDateCreatedToDisplay}</b>
                  </center>
                </div>
                <div className="p-col-12 p-md-4">
                  <pr.Button
                    label="View PDF"
                    icon="pi pi-download"
                    onClick={async () => {
                      window.open(s.supplierPdfUrl);
                    }}
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="p-col-12 p-md-4">
                  <pr.Button
                    disabled={
                      !this.state.loadedOverall ||
                      !this.state.loadedBuyer ||
                      !this.state.loadedSupplier
                    }
                    label="Update Supplier"
                    icon={
                      this.state.editLoaded === false
                        ? "pi pi-spin pi-spinner"
                        : "pi pi-upload"
                    }
                    onClick={() => {
                      this.updateSupplierMatrix();
                    }}
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
              <div className="p-col-12">
                <pr.Fieldset
                  legend="Legacy Reports"
                  toggleable={true}
                  collapsed={s.toggleSupplier}
                  onCollapse={() => {
                    this.setState({
                      toggleSupplier: !this.state.toggleSupplier,
                    });
                  }}
                >
                  {s.loadedSupplierArchived === false ? (
                    <LoadScreen />
                  ) : (
                    <pr.DataTable
                      value={s.matrixListSupplierArchived}
                      selectionMode="single"
                      header={
                        <div className="p-grid">
                          <div className="p-col-12">
                            <label className="header-label">Matrices</label>
                          </div>
                        </div>
                      }
                      emptyMessage="No Matrices"
                      rows={100}
                      autoLayout={true}
                      paginator={true}
                      alwaysShowPaginator={true}
                      responsive={true}
                    >
                      <pr.Column
                        field="dateCreatedToDisplay"
                        header="Date Created"
                        body={(e) => {
                          return <center>{e.dateCreatedToDisplay}</center>;
                        }}
                        sortable={true}
                        filter={true}
                        filterMatchMode="contains"
                        style={{ overflow: "auto" }}
                      />
                      <pr.Column
                        field="pdfUrl"
                        header="PDF"
                        body={(e) => {
                          return (
                            <a href={e.pdfUrl}>
                              <pr.Button
                                label="View PDF"
                                style={{ width: "100%" }}
                              />
                            </a>
                          );
                        }}
                        sortable={false}
                        filter={false}
                        filterMatchMode="contains"
                        style={{ overflow: "hidden" }}
                      />
                    </pr.DataTable>
                  )}
                </pr.Fieldset>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
