import React from "react";
import { LoginCard } from "./UI/LoginCard";

export class LoginView extends React.Component {
  render() {
    const {
      loading,
      rememberMe,
      showPassword,
      email,
      password,
      handleSubmitFirebase,
      handleKeyPress,
      onInputChange,
      onCheckboxChange,
      toggleShowPassword,
      viewType,
    } = this.props;

    return (
      <div className="dashboard">
        <LoginCard
          loading={loading}
          rememberMe={rememberMe}
          showPassword={showPassword}
          email={email}
          password={password}
          handleSubmitFirebase={handleSubmitFirebase}
          handleKeyPress={handleKeyPress}
          onInputChange={onInputChange}
          onCheckboxChange={onCheckboxChange}
          toggleShowPassword={toggleShowPassword}
          viewType={viewType}
        />
      </div>
    );
  }
}
