import React from "react";
//PrimeReact Components
import * as pr from "../../../common/PrimeComponents";
import {
  CrudlStatus,
  FeedbackState,
  ViewType,
  ProcessType,
} from "../../../common/config/AppConstants";
import * as EnumDropdowns from "../../../common/config/EnumDropdowns";
import * as format from "../../../common/FormatFunctions";
import { LoadScreen } from "../../Common/LoadScreen";
import { ImageViewer } from "../../Common/ImageViewer";
import { FileViewer } from "../../Common/FileViewer";
import {
  Input,
  InputType,
  LabelPosition,
  UpdateType,
} from "../../Common/Input";

export class ApplicationCard extends React.Component {
  componentDidUpdate = (prevProps) => {
    // Typical usage (don't forget to compare props):
    if (this.props.receivedFeedback !== prevProps.receivedFeedback) {
      switch (this.props.receivedFeedback.state) {
        case FeedbackState.EMPTY:
          this.props.feedback.showEmpty(
            this.growl,
            this.props.receivedFeedback.display
          );
          break;
        case FeedbackState.SUCCESS:
          this.props.feedback.showSuccess(
            this.growl,
            this.props.receivedFeedback.display,
            this.props.receivedFeedback.crudlStatus
          );
          break;
        case FeedbackState.ERROR:
          this.props.feedback.showError(
            this.growl,
            this.props.receivedFeedback.display,
            this.props.receivedFeedback.crudlStatus
          );
          break;
        default:
          break;
      }
    }
  };

  render() {
    let scrl = "scrl";
    if (this.props.viewType === ViewType.ENQUIRIES) {
      scrl = "";
    }
    console.log("EditedObject", this.props.editedObject);
    console.log("Product Options", this.props.productOptions);
    return (
      <React.Fragment>
        <pr.Messages
          style={{ textAlign: "left" }}
          ref={(el) => (this.growl = el)}
        />
        <div className={scrl}>
          <div className="p-grid">
            <div className="p-col-12"></div>
            {this.props.loading === true && <LoadScreen />}
            {this.props.loading !== true && (
              <>
                <div className="p-col-12">
                  {this.props.crudlState === CrudlStatus.CREATE && (
                    <>
                      <Input
                        id={"profileList"}
                        labelDisplay={"To"}
                        labelDisplayPosition={LabelPosition.LEFT}
                        required={true}
                        type={InputType.DROPDOWN_MULTISELECT}
                        dropdownOptions={this.props.businessOptions}
                        disabled={this.props.crudlState === CrudlStatus.VIEW}
                        value={this.props.editedObject.profileList}
                        onChangeType={UpdateType.STANDARD}
                        onChange={this.props.updateValue}
                        field={"profileList"}
                        toolTip={true}
                        toolTipLabel={
                          "You can select multiple businesses to contact. Each business will be contacted privately"
                        }
                      />

                      <Input
                        id={"productList"}
                        labelDisplay={"My Product"}
                        style={
                          format.isNotNull(this.props.productOptions)
                            ? {}
                            : { color: "red" }
                        }
                        labelDisplayPosition={LabelPosition.LEFT}
                        required={true}
                        type={InputType.DROPDOWN_MULTISELECT}
                        dropdownOptions={this.props.productOptions}
                        disabled={
                          this.props.crudlState === CrudlStatus.VIEW ||
                          !format.isNotNull(this.props.productOptions)
                        }
                        value={this.props.editedObject.productList}
                        onChangeType={UpdateType.STANDARD}
                        onChange={this.props.updateValue}
                        field={"productList"}
                        toolTip={!format.isNotNull(this.props.productOptions)}
                        toolTipLabel={
                          "No Products available. Cannot complete contact request"
                        }
                      />
                    </>
                  )}
                  {this.props.viewType === ViewType.ENQUIRIES &&
                    this.props.crudlState !== CrudlStatus.CREATE && (
                      <>
                        {this.props.editedObject.processType ===
                          ProcessType.RECEIVED &&
                        this.props.crudlState !== CrudlStatus.CREATE ? (
                          <div className="p-grid">
                            <div className="p-col-3">
                              <label className="input-label">{"From: "}</label>
                            </div>
                            <div className="p-col-9">
                              <label
                                className="input-label href"
                                onClick={() => {
                                  this.props.displayBusinessDetails(
                                    this.props.editedObject.codeTag
                                  );
                                }}
                              >
                                {this.props.editedObject.businessNameCodeTag}
                              </label>
                            </div>
                          </div>
                        ) : (
                          <div className="p-grid">
                            <div className="p-col-3">
                              <label className="input-label">{"To: "}</label>
                            </div>
                            <div className="p-col-9">
                              <label
                                className="input-label href"
                                onClick={() => {
                                  this.props.displayBusinessDetails(
                                    this.props.editedObject.buyerId
                                  );
                                }}
                              >
                                {this.props.editedObject.businessNameBuyerId}
                              </label>
                            </div>
                          </div>
                        )}
                        <Input
                          id={"productName"}
                          labelDisplay={"Product Name"}
                          labelDisplayPosition={LabelPosition.LEFT}
                          required={true}
                          type={InputType.STRING}
                          disabled={this.props.crudlState === CrudlStatus.VIEW}
                          value={this.props.editedObject.productName}
                          onChangeType={UpdateType.STANDARD}
                          onChange={this.props.updateValue}
                          field={"productName"}
                          visible={true}
                        />
                      </>
                    )}

                  <Input
                    id={"title"}
                    labelDisplay={"Title"}
                    labelDisplayPosition={LabelPosition.LEFT}
                    required={true}
                    type={InputType.STRING}
                    disabled={this.props.crudlState === CrudlStatus.VIEW}
                    value={this.props.editedObject.title}
                    onChangeType={UpdateType.STANDARD}
                    onChange={this.props.updateValue}
                    field={"title"}
                    visible={true}
                  />
                  <Input
                    id={"shortDescription"}
                    labelDisplay={"Short Description"}
                    labelDisplayPosition={LabelPosition.LEFT}
                    required={true}
                    type={InputType.STRING}
                    disabled={this.props.crudlState === CrudlStatus.VIEW}
                    value={this.props.editedObject.shortDescription}
                    onChangeType={UpdateType.STANDARD}
                    onChange={this.props.updateValue}
                    field={"shortDescription"}
                    visible={true}
                  />
                  <div
                    className="p-col-12"
                    style={
                      this.props.crudlState === CrudlStatus.CREATE ||
                      format.isNotNull(this.props.editedObject.imageUriList)
                        ? {}
                        : { display: "none" }
                    }
                  >
                    <pr.Accordion>
                      <pr.AccordionTab header="Images">
                        <div className="p-grid">
                          <div className="p-col-12">
                            <ImageViewer
                              imageList={this.props.editedObject.imageUriList}
                              updateValue={this.props.updateValue}
                              field={"imageUriList"}
                              readOnly={
                                this.props.crudlState === CrudlStatus.VIEW
                              }
                            />
                          </div>
                        </div>
                      </pr.AccordionTab>
                    </pr.Accordion>
                  </div>
                  <div
                    className="p-col-12"
                    style={
                      this.props.crudlState === CrudlStatus.CREATE ||
                      format.isNotNull(this.props.editedObject.pdfList)
                        ? {}
                        : { display: "none" }
                    }
                  >
                    <pr.Accordion>
                      <pr.AccordionTab header="Documents">
                        <div className="p-grid">
                          <div className="p-col-12">
                            <FileViewer
                              fileList={this.props.editedObject.pdfList}
                              updateValue={this.props.updateValue}
                              field={"pdfList"}
                              readOnly={
                                this.props.crudlState === CrudlStatus.VIEW
                              }
                            />
                          </div>
                        </div>
                      </pr.AccordionTab>
                    </pr.Accordion>
                  </div>
                  <Input
                    id={"longDescriptionText"}
                    labelDisplay={"Long Description"}
                    labelDisplayPosition={LabelPosition.TOP}
                    required={this.props.crudlState !== CrudlStatus.VIEW}
                    type={InputType.NOTES}
                    disabled={this.props.crudlState === CrudlStatus.VIEW}
                    value={this.props.editedObject.longDescriptionText}
                    onChangeType={UpdateType.STANDARD}
                    onChange={this.props.updateValue}
                    field={"longDescriptionText"}
                    visible={true}
                    style={{ height: "100%" }}
                  />
                  <Input
                    id={"feedbackType"}
                    labelDisplay={"Feedback Reason"}
                    labelDisplayPosition={LabelPosition.LEFT}
                    required={true}
                    type={InputType.DROPDOWN}
                    dropdownOptions={EnumDropdowns.ApplicationFeedbackOptions}
                    disabled={true}
                    value={this.props.editedObject.feedbackType}
                    field={"feedbackType"}
                    visible={null != this.props.editedObject.feedbackType}
                  />
                  <Input
                    id={"feedback"}
                    labelDisplay={"Feedback"}
                    labelDisplayPosition={LabelPosition.LEFT}
                    required={this.props.crudlState !== CrudlStatus.VIEW}
                    type={InputType.NOTES}
                    disabled={true}
                    value={this.props.editedObject.feedback}
                    onChangeType={UpdateType.STANDARD}
                    onChange={this.props.updateValue}
                    field={"feedback"}
                    visible={null != this.props.editedObject.feedback}
                    style={{ height: "fit-content" }}
                    headerTemplate={<></>}
                  />
                  {this.props.viewType === ViewType.COUNCIL &&
                    this.props.crudlState === CrudlStatus.UPDATE && (
                      <Input
                        id={"status"}
                        labelDisplay={"Status"}
                        labelDisplayPosition={LabelPosition.LEFT}
                        required={true}
                        type={InputType.DROPDOWN}
                        dropdownOptions={
                          EnumDropdowns.ApplicationStatusOptionsAll
                        }
                        onChangeType={UpdateType.STANDARD}
                        onChange={this.props.updateValue}
                        disabled={this.props.crudlState === CrudlStatus.VIEW}
                        value={this.props.editedObject.status}
                        field={"status"}
                      />
                    )}
                </div>
                {this.props.viewType === ViewType.COUNCIL &&
                  this.props.crudlState !== CrudlStatus.UPDATE && (
                    <div className="p-col-12">
                      {(!format.isNotNull(
                        this.props.editedObject.archivedCouncil
                      ) ||
                        !this.props.editedObject.archivedCouncil) && (
                        <>
                          <pr.Button
                            className="flt-r"
                            icon="pi pi-inbox"
                            label="Archive application"
                            onClick={() => {
                              this.props.archiveApplication(
                                "Council",
                                this.props.editedObject
                              );
                            }}
                          />
                          <pr.Button
                            className="flt-r"
                            icon="pi pi-plus"
                            label="Update application"
                            onClick={() => {
                              this.props.toggleUpdateApplication(
                                this.props.editedObject
                              );
                            }}
                          />
                        </>
                      )}

                      <pr.Button
                        className="flt-r"
                        icon="pi pi-trash"
                        label="Delete application"
                        onClick={() => {
                          this.props.deleteApplication(this.props.editedObject);
                        }}
                      />
                    </div>
                  )}
              </>
            )}
          </div>
        </div>
        <div className="sidebar-footer">
          {this.props.crudlState !== CrudlStatus.VIEW && (
            <pr.Button
              className="mrgn flt-l"
              label="Cancel"
              icon="pi-md-close"
              onClick={() => {
                this.props.validateChanges();
              }}
            />
          )}
          {this.props.crudlState !== CrudlStatus.CREATE &&
          this.props.crudlState !== CrudlStatus.VIEW ? (
            <pr.Button
              className="mrgn flt-r"
              label="Delete"
              icon={
                this.props.loading ? "pi-spin pi-md-autorenew" : "pi-md-delete"
              }
              onClick={() => {
                this.props.crudlControl(
                  CrudlStatus.DELETE,
                  this.props.editedObject
                );
              }}
            />
          ) : (
            <label />
          )}
          {this.props.crudlState !== CrudlStatus.VIEW ? (
            <pr.Button
              className="mrgn flt-r"
              disabled={this.props.checkRequiredFields()}
              label="Send"
              icon={
                this.props.loading ? "pi-spin pi-md-autorenew" : "pi pi-send"
              }
              onClick={() => {
                this.props.crudlExecute(false);
              }}
            />
          ) : (
            <label />
          )}
        </div>
      </React.Fragment>
    );
  }
}
