import React from "react";
//PrimeReact Components
import * as pr from "../../../common/PrimeComponents";
import { CrudlStatus } from "../../../common/config/AppConstants";
import { ToggleState } from "../../../common/config/AppConstants";
import * as format from "../../../common/FormatFunctions";
import { BusinessDisplay } from "./BusinessDisplay";

export class BusinessTable extends React.Component {
  export = () => {
    this.dt.exportCSV();
  };

  getBusinessColumns = () => {
    console.log(this.props);
    var allColumns = [];
    // allColumns.push(
    //   <pr.Column
    //     key="expander"
    //     expander={true}
    //     style={{ width: "5em", float: "middle" }}
    //   />
    // );
    if (format.isNotNull(this.props.tableColumns)) {
      let columns = this.props.tableColumns.map((col, i) => {
        return (
          <pr.Column
            key={col.field}
            field={col.field}
            header={col.header}
            sortable={col.sortable}
            filter={col.filter}
            filterMatchMode={col.filterMatchMode}
            style={col.style}
          />
        );
      });

      columns.forEach((e) => {
        allColumns.push(e);
      });
    }
    return allColumns;
  };

  render() {
    return (
      <div className="p-grid bpm">
        <div className="p-col-12">
          <pr.BreadCrumb
            model={this.props.menuState}
            home={pr.getBreadcrumbMenuHome()}
          />
        </div>
        <div className="p-col-12">
          <div className="p-col-12 p-lg-3">
            <div className="content-section">
              <div className="feature-intro">
                <h1>{"Businesss"}</h1>
                <p>{"A collection of all Businesss"}</p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="p-col-12"
          style={
            this.props.settingsToggle === ToggleState.ON
              ? {}
              : { display: "none" }
          }
        >
          <div className="p-grid bpm">
            <div className="p-col-6">
              <h3>Table Columns</h3>
              <pr.MultiSelect
                value={this.props.tableColumns}
                options={this.props.columnOptions}
                onChange={this.props.columnToggle}
                style={{
                  width: "100%",
                  algin: "middle",
                }}
              />
            </div>
            {/* <div className="p-col-6">
            <h3>Table Rows</h3>
            <pr.Dropdown
              value={this.props.rowCount || ""}
              options={[
                { label: 5, value: 5 },
                { label: 10, value: 10 },
                { label: 25, value: 25 },
                { label: 50, value: 50 },
                { label: 75, value: 75 },
                { label: 100, value: 100 }
              ]}
              onChange={e => {
                console.log(e);
                this.props.updateState("rowCount", e.value);
              }}
              style={{ width: "100%" }}
            />
          </div> */}
          </div>
        </div>
        <div className="p-col-12">
          <pr.DataTable
            ref={(el) => {
              this.dt = el;
            }}
            value={this.props.businessList}
            selectionMode="single"
            onSelectionChange={(e) => {
              this.props.crudlControl(CrudlStatus.UPDATE, e.value);
            }}
            header={
              <div className="p-grid">
                <div className="p-col-12">
                  <pr.Button
                    className="table-button"
                    type="button"
                    style={{
                      float: "left",
                    }}
                    icon={
                      this.props.settingsToggle === ToggleState.OFF
                        ? "pi-md-settings"
                        : "pi-md-close"
                    }
                    onClick={(e) => {
                      if (this.props.settingsToggle === ToggleState.ON) {
                        console.log("SettingsToggle ==> OFF");
                        this.props.updateState(
                          "settingsToggle",
                          ToggleState.OFF
                        );
                      }
                      if (this.props.settingsToggle === ToggleState.OFF) {
                        console.log("SettingsToggle ==> ON");
                        this.props.updateState(
                          "settingsToggle",
                          ToggleState.ON
                        );
                      }
                    }}
                  />
                  <pr.Button
                    className="table-button"
                    label={"Add New"}
                    icon="pi-md-add"
                    style={{
                      float: "right",
                    }}
                    onClick={() => {
                      this.props.crudlControl(CrudlStatus.CREATE);
                    }}
                  />
                  <pr.Button
                    className="table-button"
                    type="button"
                    label={"Export Data"}
                    style={{
                      float: "right",
                    }}
                    icon="pi-md-launch"
                    onClick={this.export}
                  />
                  <pr.Button
                    className="table-button"
                    type="button"
                    style={{
                      float: "right",
                    }}
                    label={"Refresh"}
                    icon={
                      this.props.loading === true
                        ? "pi-spin pi-md-autorenew"
                        : "pi-md-autorenew"
                    }
                    onClick={this.props.refresh}
                  />
                </div>
              </div>
            }
            emptyMessage="No Businesss"
            expandedRows={this.props.expandedRows}
            onRowToggle={(e) => this.props.updateState("expandedRows", e.data)}
            rowExpansionBusiness={this.getDisplayCard}
            rows={Infinity}
            autoLayout={true}
            paginator={false}
            alwaysShowPaginator={false}
            responsive={true}
            loading={this.props.loading}
          >
            {this.getBusinessColumns()}
          </pr.DataTable>
        </div>
      </div>
    );
  }
  getDisplayCard = (data) => {
    return <BusinessDisplay data={data} onClick={this.props.crudlControl} />;
  };
}
