import React from "react";
//PrimeReact Components
import * as pr from "../../../common/PrimeComponents";
import {
  CrudlStatus,
  FeedbackState,
  ViewType,
} from "../../../common/config/AppConstants";
import { LoadScreen } from "../../Common/LoadScreen";
import { ImageViewer } from "../../Common/ImageViewer";
import { FileViewer } from "../../Common/FileViewer";
import {
  Input,
  InputType,
  LabelPosition,
  UpdateType,
} from "../../Common/Input";
import { ProductStatusOptions } from "../../../common/config/EnumDropdowns";

export class ProductCard extends React.Component {
  componentDidUpdate = (prevProps) => {
    // Typical usage (don't forget to compare props):
    if (this.props.receivedFeedback !== prevProps.receivedFeedback) {
      switch (this.props.receivedFeedback.state) {
        case FeedbackState.EMPTY:
          this.props.feedback.showEmpty(
            this.growl,
            this.props.receivedFeedback.display
          );
          break;
        case FeedbackState.SUCCESS:
          this.props.feedback.showSuccess(
            this.growl,
            this.props.receivedFeedback.display,
            this.props.receivedFeedback.crudlStatus
          );
          break;
        case FeedbackState.ERROR:
          this.props.feedback.showError(
            this.growl,
            this.props.receivedFeedback.display,
            this.props.receivedFeedback.crudlStatus
          );
          break;
        default:
          break;
      }
    }
  };

  displayCard = () => {
    return (
      <React.Fragment>
        <div className="scrl">
          <div className="p-grid">
            <div className="p-col-12"></div>
            {this.props.loading === true && <LoadScreen />}
            {this.props.loading !== true && (
              <div className="p-col-12">{this.displayProductView()}</div>
            )}
          </div>
        </div>
        <div className="sidebar-footer">
          <pr.Button
            className="mrgn flt-l"
            label="Cancel"
            icon="pi-md-close"
            onClick={() => {
              this.props.validateChanges();
            }}
          />
          {this.props.crudlState !== CrudlStatus.CREATE &&
          this.props.crudlState !== CrudlStatus.VIEW ? (
            <pr.Button
              className="mrgn flt-r"
              label="Delete"
              icon={
                this.props.loading ? "pi-spin pi-md-autorenew" : "pi-md-delete"
              }
              onClick={() => {
                this.props.crudlControl(
                  CrudlStatus.DELETE,
                  this.props.editedObject
                );
              }}
            />
          ) : (
            <label />
          )}
          {this.props.crudlState !== CrudlStatus.VIEW ? (
            <pr.Button
              className="mrgn flt-r"
              disabled={this.props.checkRequiredFields()}
              label="Save"
              icon={
                this.props.loading ? "pi-spin pi-md-autorenew" : "pi-md-save"
              }
              onClick={() => {
                this.props.crudlExecute(true);
              }}
            />
          ) : (
            <label />
          )}
          {this.props.crudlState !== CrudlStatus.VIEW ? (
            <pr.Button
              className="mrgn flt-r"
              disabled={this.props.checkRequiredFields()}
              label="Save and Close"
              icon={
                this.props.loading
                  ? "pi-spin pi-md-autorenew"
                  : "pi-md-tab-unselected"
              }
              onClick={() => {
                this.props.crudlExecute(false);
              }}
            />
          ) : (
            <label />
          )}
          {this.props.crudlState === CrudlStatus.VIEW ? (
            <pr.Button
              className="mrgn flt-r"
              label="Edit"
              icon={
                this.props.loading ? "pi-spin pi-md-autorenew" : "pi pi-md-edit"
              }
              onClick={() => {
                this.props.crudlControl(
                  CrudlStatus.UPDATE,
                  this.props.editedObject
                );
              }}
            />
          ) : (
            <label />
          )}
        </div>
      </React.Fragment>
    );
  };

  displayRegister = () => {
    return (
      <div className="p-grid dashboard">
        <div className="p-col-12"></div>
        {this.props.loading === true && <LoadScreen />}
        {this.props.loading !== true && (
          <React.Fragment>
            <div className="p-col-12">
              {this.props.editedBusiness.typeBuyer &&
                !this.props.editedBusiness.typeSupplier && (
                  <label>{"Add a Product you wish to Buy"}</label>
                )}
              {!this.props.editedBusiness.typeBuyer &&
                this.props.editedBusiness.typeSupplier && (
                  <label>{"Add a Product you can Supply"}</label>
                )}
              {this.props.editedBusiness.typeBuyer &&
                this.props.editedBusiness.typeSupplier && (
                  <label>{"Add a Product you can Supply"}</label>
                )}
            </div>
            <div className="p-col-12">{this.displayProductView()}</div>
            <div className="p-col-12">
              <pr.Button
                className="flt-r"
                disabled={this.props.checkRequiredFields()}
                label="Register"
                icon={"pi pi-save"}
                onClick={() => {
                  this.props.onRegister();
                }}
              />
              <pr.Button
                className="secondary-btn flt-l"
                label="Back"
                icon="pi pi-arrow-left"
                onClick={() => {
                  this.props.updateState("index", 2);
                }}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  };

  displayProductView = () => {
    return (
      <div className="p-grid">
        <div className="p-col-6">
          <Input
            id={"name"}
            labelDisplay={"Name"}
            labelDisplayPosition={LabelPosition.LEFT}
            required={true}
            type={InputType.STRING}
            disabled={this.props.crudlState === CrudlStatus.VIEW}
            value={this.props.editedObject.name}
            onChangeType={UpdateType.STANDARD}
            onChange={this.props.updateValue}
            field={"name"}
            visible={true}
          />
          <Input
            id={"componentList"}
            labelDisplay={"Type"}
            labelDisplayPosition={LabelPosition.LEFT}
            required={true}
            type={InputType.DROPDOWN_MULTISELECT}
            dropdownOptions={this.props.componentOptions}
            disabled={this.props.crudlState === CrudlStatus.VIEW}
            value={this.props.editedObject.componentList}
            onChangeType={UpdateType.STANDARD}
            onChange={this.props.updateValue}
            field={"componentList"}
          />
        </div>
        <div className="p-col-6">
          {this.props.viewType === ViewType.MY_PRODUCT && (
            <Input
              id={"shortDescription"}
              labelDisplay={"Link to Website"}
              labelDisplayPosition={LabelPosition.LEFT}
              required={false}
              type={InputType.STRING}
              disabled={this.props.crudlState === CrudlStatus.VIEW}
              value={this.props.editedObject.shortDescription}
              onChangeType={UpdateType.STANDARD}
              onChange={this.props.updateValue}
              field={"shortDescription"}
              visible={true}
              toolTip={true}
              toolTipLabel={
                "Please include https:// or http:// when filling out this field"
              }
            />
          )}

          {this.props.viewType !== ViewType.REGISTER && (
            <Input
              id={"productStatus"}
              labelDisplay={"Status"}
              labelDisplayPosition={LabelPosition.LEFT}
              required={this.props.viewType === ViewType.MY_REQUIREMENT}
              type={InputType.DROPDOWN}
              dropdownOptions={ProductStatusOptions}
              disabled={this.props.crudlState === CrudlStatus.VIEW}
              value={this.props.editedObject.productStatus}
              onChangeType={UpdateType.STANDARD}
              onChange={this.props.updateValue}
              field={"productStatus"}
              toolTip={true}
              toolTipLabel={
                this.props.viewType === ViewType.MY_PRODUCT
                  ? "Active: Buyers can view. Inactive: Buyers can't view"
                  : "Active : Suppliers can view, Inactive : Suppliers can't view"
              }
            />
          )}

          {this.props.viewType === ViewType.MY_REQUIREMENT ||
          (this.props.viewType === ViewType.REGISTER &&
            ((!this.props.editedBusiness.typeBuyer &&
              this.props.editedBusiness.typeSupplier) ||
              (this.props.editedBusiness.typeBuyer &&
                this.props.editedBusiness.typeSupplier))) ? (
            <Input
              id={"dateRequired"}
              labelDisplay={"Date Required"}
              labelDisplayPosition={LabelPosition.LEFT}
              required={true}
              type={InputType.DATE}
              disabled={this.props.crudlState === CrudlStatus.VIEW}
              value={this.props.editedObject.dateRequired}
              onChangeType={UpdateType.STANDARD}
              onChange={this.props.updateValue}
              field={"dateRequired"}
            />
          ) : (
            <></>
          )}

          {this.props.viewType === ViewType.MY_PRODUCT && (
            <Input
              id={"location"}
              labelDisplay={"Location"}
              labelDisplayPosition={LabelPosition.LEFT}
              required={false}
              type={InputType.STRING}
              disabled={this.props.crudlState === CrudlStatus.VIEW}
              value={this.props.editedObject.location}
              onChangeType={UpdateType.STANDARD}
              onChange={this.props.updateValue}
              field={"location"}
              visible={true}
              toolTip={true}
              toolTipLabel={
                "This is where your product is delivered from or where you are based, by default this is the city in your profile details"
              }
            />
          )}
        </div>
        <div className="p-col-12">
          <pr.Accordion>
            <pr.AccordionTab header="Images">
              <div className="p-grid">
                <div className="p-col-12">
                  <ImageViewer
                    imageList={this.props.editedObject.imageUri}
                    updateValue={this.props.updateValue}
                    field={"imageUri"}
                    toggleProductLogo={true}
                    updateValueProductLogo={this.props.updateValue}
                    productLogo={this.props.editedObject.productLogo}
                  />
                </div>
              </div>
            </pr.AccordionTab>
          </pr.Accordion>
        </div>
        <div className="p-col-12">
          <pr.Accordion>
            <pr.AccordionTab header="Documents">
              <div className="p-grid">
                <div className="p-col-12">
                  <FileViewer
                    fileList={this.props.editedObject.pdfUrls}
                    updateValue={this.props.updateValue}
                    field={"pdfUrls"}
                  />
                </div>
              </div>
            </pr.AccordionTab>
          </pr.Accordion>
        </div>
        <div className="p-col-12">
          <Input
            id={"longDescription"}
            labelDisplay={"Additional Details"}
            labelDisplayPosition={LabelPosition.TOP}
            required={
              this.props.viewType === ViewType.MY_REQUIREMENT ||
              this.props.viewType === ViewType.REGISTER
            }
            type={InputType.NOTES}
            disabled={this.props.crudlState === CrudlStatus.VIEW}
            value={this.props.editedObject.longDescription}
            onChangeType={UpdateType.STANDARD}
            onChange={this.props.updateValue}
            field={"longDescription"}
            visible={true}
            style={{ height: "100%" }}
          />
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <pr.Messages
          style={{ textAlign: "left" }}
          ref={(el) => (this.growl = el)}
        />
        {this.props.viewType === ViewType.REGISTER
          ? this.displayRegister()
          : this.displayCard()}
      </React.Fragment>
    );
  }
}
