import React, { Component } from "react";
import * as pr from "../../common/PrimeComponents";
import * as Log from "../../common/Feedback/Log";
import { ValidateState, CrudlStatus } from "../../common/config/AppConstants";

export class MVVMValidateDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      errors: [],
    };

    this.logger = Log;
  }

  setPreviousCrudl = () => {
    this.props.updateVisibility("crudlState", CrudlStatus.UPDATE);
  };

  updateVisibility = (key, value) => {
    if (this.props.type === "CRUDL") {
      this.setPreviousCrudl();
    }
    this.props.updateVisibility(key, value);
  };

  runConfirm = (key) => {
    if (this.props.type === "CRUDL") {
      this.props.crudlExecute(this.props.crudlStatus);
    } else {
      this.props.runConfirm();
    }
    this.updateVisibility(key, ValidateState.NONE);
  };

  render() {
    return (
      <React.Fragment>
        <pr.Dialog
          header={this.props.header}
          footer={
            <div className="p-grid">
              <div className="p-col-12">
                <pr.Button
                  className="blk mrgn flt-r"
                  label="Yes"
                  icon="pi-md-check"
                  onClick={() => {
                    this.runConfirm(this.props.visibleField);
                  }}
                />
                <pr.Button
                  className="blk mrgn flt-r"
                  label="No"
                  icon="pi-md-close"
                  onClick={() => {
                    this.updateVisibility(
                      this.props.visibleField,
                      ValidateState.NONE
                    );
                  }}
                />
              </div>
            </div>
          }
          visible={this.props.isVisible}
          onHide={() => {
            this.updateVisibility(this.props.visibleField, ValidateState.NONE);
          }}
          modal={false}
        >
          {this.props.message}
        </pr.Dialog>
        <div className={this.props.isVisible ? "custom-overlay" : ""}></div>
      </React.Fragment>
    );
  }
}
