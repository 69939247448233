import { api } from "./ApiPromisify";
import { apiUnsecure } from "./ApiPromisify";
import { CloudService } from "./ClientLibsReference";
import * as logger from "../Feedback/Log";

export const exceptionCreate = async (exception, page, method) => {
  var exceptionRequest = {};
  exceptionRequest.codeTag = localStorage.getItem("codeTag");
  exceptionRequest.userId = localStorage.getItem("userId");
  exceptionRequest.message = method + ": [ " + exception.message + "]";
  exceptionRequest.platform = "PORTAL";
  exceptionRequest.page = page;
  exceptionRequest.deviceType = JSON.parse(
    localStorage.deviceSettings
  ).engineName;
  exceptionRequest.deviceInformation = localStorage.deviceSettings;
  exceptionRequest.version = "Portal v0.5";
  exceptionRequest.logLevel = "ERROR";
  exceptionRequest.dateExceptionOccured = new Date();

  // var apiResponse = await api(
  //   "exceptionApiV1ExceptionCreate",
  //   exceptionRequest
  // );
  // logger.info(apiResponse);
  // let response = apiResponse.data;
  // logger.info(response);
  // return response;
  return { errorCode: 0 };
};

export const throwException = async (attributeRequest) => {
  logger.info(attributeRequest);

  var apiResponse = await api("apiTestApiV1ThrowException", attributeRequest);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "throwException");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.AttributeResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const throwCustomException = async (attributeRequest) => {
  logger.info(attributeRequest);

  var apiResponse = await api(
    "apiTestApiV1ThrowCustomException",
    attributeRequest
  );
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "throwException");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.AttributeResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};
export const throwNoNameException = async (attributeRequest) => {
  logger.info(attributeRequest);

  var apiResponse = await api("helloWorld", attributeRequest);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "throwException");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.AttributeResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};
////////////////////////////GENERATED APIS/////////////////////////////////
export const businessCreate = async (request) => {
  logger.info(request);

  var apiResponse = await apiUnsecure("businessApiV1CreateBusiness", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "businessCreate");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.BusinessResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const businessUpdate = async (request) => {
  logger.info(request);

  var apiResponse = await api("businessApiV1BusinessUpdate", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "businessUpdate");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.BusinessResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const businessDelete = async (request) => {
  logger.info(request);

  var apiResponse = await api("businessApiV1BusinessDelete", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "businessDelete");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.BusinessResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const businessListByName = async (request) => {
  logger.info(request);

  var apiResponse = await apiUnsecure(
    "businessApiV1BusinesslistByName",
    request
  );
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "businessListByName");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.BusinessResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const businessGetByCodeTag = async (request) => {
  logger.info(request);

  var apiResponse = await api("businessApiV1BusinessAccountGet", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "businessAccountGet");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.BusinessResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const businessListAll = async (request) => {
  logger.info(request);

  var apiResponse = await api("businessApiV1BusinesslistAll", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "businesslistAll");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.BusinessResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const loginListBusinesses = async (request) => {
  logger.info(request);

  var apiResponse = await api("loginApiV1LoginListBusinesses", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "loginListBusinesses");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.LoginResponse.constructFromObject(apiResponse.data);
    logger.info(response);
    return response;
  }
};

export const login = async (request) => {
  logger.info(request);

  var apiResponse = await api("loginApiV1Login", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "login");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.LoginResponse.constructFromObject(apiResponse.data);
    logger.info(response);
    return response;
  }
};

export const profileListAll = async (request) => {
  logger.info(request);

  var apiResponse = await api("profileApiV1ProfileListAll", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "profileListAll");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.ProfileResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};
export const profileGetByCodeTag = async (request) => {
  logger.info(request);

  var apiResponse = await api("profileApiV1ProfileGetByCodeTag", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "profileGetByCodeTag");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.ProfileResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};
export const profileGetByType = async (request) => {
  logger.info(request);

  var apiResponse = await api("profileApiV1ProfileGetByType", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "profileGetByType");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.ProfileResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};
export const profileGetByTypeAndComponentList = async (request) => {
  logger.info(request);

  var apiResponse = await api(
    "profileApiV1ProfileGetByTypeAndComponentId",
    request
  );
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(
      apiResponse.error,
      "API_SERVICE",
      "profileGetByTypeAndComponentId"
    );
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.ProfileResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};
export const profileGetByProfileStatus = async (request) => {
  logger.info(request);

  var apiResponse = await api("profileApiV1ProfileGetByProfileStatus", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(
      apiResponse.error,
      "API_SERVICE",
      "profileGetByProfileStatus"
    );
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.ProfileResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const profileCreate = async (request) => {
  logger.info(request);

  var apiResponse = await api("profileApiV1ProfileCreate", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "profile");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.ProfileResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const profileUpdate = async (request) => {
  logger.info(request);

  var apiResponse = await api("profileApiV1ProfileUpdate", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "profile");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.ProfileResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const profileDelete = async (request) => {
  logger.info(request);

  var apiResponse = await api("profileApiV1ProfileDelete", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "profile");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.ProfileResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const componentGetByCodeTag = async (request) => {
  logger.info(request);

  var apiResponse = await api("componentApiV1ComponentGetByCodeTag", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "component");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.ComponentResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const componentGetByCodeTagUnsecure = async (request) => {
  logger.info(request);

  var apiResponse = await apiUnsecure(
    "componentApiV1ComponentGetByCodeTag",
    request
  );
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "component");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.ComponentResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const componentGetMatrixItems = async (request) => {
  logger.info(request);

  var apiResponse = await api("componentApiV1ComponentGetMatrixItems", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(
      apiResponse.error,
      "API_SERVICE",
      "componentGetMatrixItems"
    );
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.ComponentResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const componentCreate = async (request) => {
  logger.info(request);

  var apiResponse = await api("componentApiV1ComponentCreate", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "component");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.ComponentResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const componentUpdate = async (request) => {
  logger.info(request);

  var apiResponse = await api("componentApiV1ComponentUpdate", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "component");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.ComponentResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const componentDelete = async (request) => {
  logger.info(request);

  var apiResponse = await api("componentApiV1ComponentDelete", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "component");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.ComponentResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const matrixItemGetByCodeTag = async (request) => {
  logger.info(request);

  var apiResponse = await api("matrixItemApiV1MatrixItemGetByCodeTag", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "matrixItem");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.MatrixItemResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const matrixItemCreate = async (request) => {
  logger.info(request);

  var apiResponse = await api("matrixItemApiV1MatrixItemCreate", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "matrixItem");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.MatrixItemResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const matrixItemUpdate = async (request) => {
  logger.info(request);

  var apiResponse = await api("matrixItemApiV1MatrixItemUpdate", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "matrixItem");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.MatrixItemResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const matrixItemDelete = async (request) => {
  logger.info(request);

  var apiResponse = await api("matrixItemApiV1MatrixItemDelete", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "matrixItem");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.MatrixItemResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const userGetByCodeTag = async (request) => {
  logger.info(request);

  var apiResponse = await api("userApiV1UserGetByCodeTag", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "user");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.UserResponse.constructFromObject(apiResponse.data);
    logger.info(response);
    return response;
  }
};

export const userListInBusinessGlobal = async (request) => {
  logger.info(request);

  var apiResponse = await api("userApiV1UserListInBusinessGlobal", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(
      apiResponse.error,
      "API_SERVICE",
      "userListInBusinessGlobal"
    );
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.UserResponse.constructFromObject(apiResponse.data);
    logger.info(response);
    return response;
  }
};

export const userGetByEmail = async (request) => {
  var apiResponse = await apiUnsecure("userApiV1UserGetByEmail", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "userGetByEmail");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.UserResponse.constructFromObject(apiResponse.data);
    logger.info(response);
    return response;
  }
};

export const userCreate = async (request) => {
  logger.info(request);

  var apiResponse = await api("userApiV1UserCreate", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "user");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.UserResponse.constructFromObject(apiResponse.data);
    logger.info(response);
    return response;
  }
};

export const userUpdate = async (request) => {
  logger.info(request);

  var apiResponse = await api("userApiV1UserUpdateAccountGlobal", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "user");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.UserResponse.constructFromObject(apiResponse.data);
    logger.info(response);
    return response;
  }
};

export const userDelete = async (request) => {
  logger.info(request);

  var apiResponse = await api("userApiV1UserDelete", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "user");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.UserResponse.constructFromObject(apiResponse.data);
    logger.info(response);
    return response;
  }
};

export const productGetByCodeTag = async (request) => {
  logger.info(request);

  var apiResponse = await api("productApiV1ProductGetByCodeTag", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "productGetByCodeTag");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.ProductResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const productGetByCodeTagAndType = async (request) => {
  logger.info(request);

  var apiResponse = await api(
    "productApiV1ProductGetByCodeTagAndType",
    request
  );
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(
      apiResponse.error,
      "API_SERVICE",
      "productGetByCodeTagAndType"
    );
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.ProductResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const productGetByType = async (request) => {
  logger.info(request);

  var apiResponse = await api("productApiV1ProductGetByType", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "productGetByType");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.ProductResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};
export const productGetByComponentList = async (request) => {
  logger.info(request);

  var apiResponse = await api("productApiV1ProductGetByComponentList", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(
      apiResponse.error,
      "API_SERVICE",
      "productGetByComponentList"
    );
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.ProductResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const productCreate = async (request) => {
  logger.info(request);

  var apiResponse = await api("productApiV1ProductCreate", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "product");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.ProductResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const productUpdate = async (request) => {
  logger.info(request);

  var apiResponse = await api("productApiV1ProductUpdate", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "product");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.ProductResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const productDelete = async (request) => {
  logger.info(request);

  var apiResponse = await api("productApiV1ProductDelete", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "product");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.ProductResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const matrixGetByMatrixType = async (request) => {
  logger.info(request);

  var apiResponse = await api("matrixApiV1MatrixGetByMatrixType", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "matrixGetByMatrixType");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.MatrixResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const matrixGetMostRecentByMatrixType = async (request) => {
  logger.info(request);

  var apiResponse = await api("matrixApiV1MatrixGetMostRecentByType", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(
      apiResponse.error,
      "API_SERVICE",
      "matrixGetMostRecentByType"
    );
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.MatrixResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const matrixGetByCodeTag = async (request) => {
  logger.info(request);

  var apiResponse = await api("matrixApiV1MatrixGetByCodeTag", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "matrix");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.MatrixResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const matrixCreate = async (request) => {
  logger.info(request);

  var apiResponse = await api("matrixApiV1MatrixCreate", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "matrix");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.MatrixResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const matrixUpdate = async (request) => {
  logger.info(request);

  var apiResponse = await api("matrixApiV1MatrixUpdate", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "matrix");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.MatrixResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const matrixDelete = async (request) => {
  logger.info(request);

  var apiResponse = await api("matrixApiV1MatrixDelete", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "matrix");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.MatrixResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const applicationListAll = async (request) => {
  logger.info(request);

  var apiResponse = await api("applicationApiV1ApplicationListAll", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "applicationListAll");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.ApplicationResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const applicationGetByCodeTag = async (request) => {
  logger.info(request);

  var apiResponse = await api(
    "applicationApiV1ApplicationGetByCodeTag",
    request
  );
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(
      apiResponse.error,
      "API_SERVICE",
      "applicationGetByCodeTag"
    );
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.ApplicationResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const applicationGetByApplicationType = async (request) => {
  logger.info(request);

  var apiResponse = await api(
    "applicationApiV1ApplicationGetByApplicationType",
    request
  );
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(
      apiResponse.error,
      "API_SERVICE",
      "applicationGetByCodeTag"
    );
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.ApplicationResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const applicationGetByBuyerId = async (request) => {
  logger.info(request);

  var apiResponse = await api(
    "applicationApiV1ApplicationGetByBuyerId",
    request
  );
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(
      apiResponse.error,
      "API_SERVICE",
      "applicationGetByBuyerId"
    );
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.ApplicationResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const applicationFeedbackRequest = async (request) => {
  logger.info(request);

  var apiResponse = await api(
    "applicationApiV1ApplicationRequestFeedback",
    request
  );
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(
      apiResponse.error,
      "API_SERVICE",
      "applicationRequestFeedback"
    );
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.ApplicationResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const applicationCreate = async (request) => {
  logger.info(request);

  var apiResponse = await api("applicationApiV1ApplicationCreate", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "application");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.ApplicationResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const applicationUpdate = async (request) => {
  logger.info(request);

  var apiResponse = await api("applicationApiV1ApplicationUpdate", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "application");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.ApplicationResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const applicationDelete = async (request) => {
  logger.info(request);

  var apiResponse = await api("applicationApiV1ApplicationDelete", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "application");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.ApplicationResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const activityGetByCodeTag = async (request) => {
  logger.info(request);

  var apiResponse = await api("activityApiV1ActivityGetByCodeTag", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "activity");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.ActivityResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const activityCreate = async (request) => {
  logger.info(request);

  var apiResponse = await api("activityApiV1ActivityCreate", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "activity");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.ActivityResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const activityUpdate = async (request) => {
  logger.info(request);

  var apiResponse = await api("activityApiV1ActivityUpdate", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "activity");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.ActivityResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const activityDelete = async (request) => {
  logger.info(request);

  var apiResponse = await api("activityApiV1ActivityDelete", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "activity");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.ActivityResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const contactGetByCodeTag = async (request) => {
  logger.info(request);

  var apiResponse = await api("contactApiV1ContactGetByCodeTag", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "contact");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.ContactResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const contactCreate = async (request) => {
  logger.info(request);

  var apiResponse = await api("contactApiV1ContactCreate", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "contact");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.ContactResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const contactUpdate = async (request) => {
  logger.info(request);

  var apiResponse = await api("contactApiV1ContactUpdate", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "contact");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.ContactResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const contactDelete = async (request) => {
  logger.info(request);

  var apiResponse = await api("contactApiV1ContactDelete", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "contact");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.ContactResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const formGetByCodeTag = async (request) => {
  logger.info(request);

  var apiResponse = await api("formApiV1FormGetByCodeTag", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "form");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.FormResponse.constructFromObject(apiResponse.data);
    logger.info(response);
    return response;
  }
};

export const formCreate = async (request) => {
  logger.info(request);

  var apiResponse = await api("formApiV1FormCreate", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "form");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.FormResponse.constructFromObject(apiResponse.data);
    logger.info(response);
    return response;
  }
};

export const formUpdate = async (request) => {
  logger.info(request);

  var apiResponse = await api("formApiV1FormUpdate", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "form");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.FormResponse.constructFromObject(apiResponse.data);
    logger.info(response);
    return response;
  }
};

export const formDelete = async (request) => {
  logger.info(request);

  var apiResponse = await api("formApiV1FormDelete", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "form");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.FormResponse.constructFromObject(apiResponse.data);
    logger.info(response);
    return response;
  }
};

export const formItemGetByCodeTag = async (request) => {
  logger.info(request);

  var apiResponse = await api("formItemApiV1FormItemGetByCodeTag", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "formItem");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.FormItemResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const formItemCreate = async (request) => {
  logger.info(request);

  var apiResponse = await api("formItemApiV1FormItemCreate", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "formItem");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.FormItemResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const formItemUpdate = async (request) => {
  logger.info(request);

  var apiResponse = await api("formItemApiV1FormItemUpdate", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "formItem");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.FormItemResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const formItemDelete = async (request) => {
  logger.info(request);

  var apiResponse = await api("formItemApiV1FormItemDelete", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "formItem");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.FormItemResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const profileItemGetByCodeTag = async (request) => {
  logger.info(request);

  var apiResponse = await api(
    "profileItemApiV1ProfileItemGetByCodeTag",
    request
  );
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "profileItem");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.ProfileItemResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const profileItemCreate = async (request) => {
  logger.info(request);

  var apiResponse = await api("profileItemApiV1ProfileItemCreate", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "profileItem");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.ProfileItemResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const profileItemUpdate = async (request) => {
  logger.info(request);

  var apiResponse = await api("profileItemApiV1ProfileItemUpdate", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "profileItem");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.ProfileItemResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const profileItemDelete = async (request) => {
  logger.info(request);

  var apiResponse = await api("profileItemApiV1ProfileItemDelete", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "profileItem");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.ProfileItemResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const pdfCreateMatrix = async (request) => {
  logger.info(request);

  var apiResponse = await api("pdfApiV1PdfCreateMatrix", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "pdf");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.PdfResponse.constructFromObject(apiResponse.data);
    logger.info(response);
    return response;
  }
};

export const categoryGetByCodeTag = async (request) => {
  logger.info(request);

  var apiResponse = await api("categoryApiV1CategoryGetByCodeTag", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "category");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.CategoryResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const categoryCreate = async (request) => {
  logger.info(request);

  var apiResponse = await api("categoryApiV1CategoryCreate", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "categoryCreate");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.CategoryResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const categoryUpdate = async (request) => {
  logger.info(request);

  var apiResponse = await api("categoryApiV1CategoryUpdate", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "categoryUpdate");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.CategoryResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const categoryDelete = async (request) => {
  logger.info(request);

  var apiResponse = await api("categoryApiV1CategoryDelete", request);
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "categoryDelete");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.CategoryResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const businessIndustryGetByCodeTag = async (request) => {
  logger.info(request);

  var apiResponse = await api(
    "businessIndustryApiV1BusinessIndustryGetByCodeTag",
    request
  );
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "businessIndustry");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.BusinessIndustryResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const businessIndustryGetByCodeTagUnsecure = async (request) => {
  logger.info(request);

  var apiResponse = await apiUnsecure(
    "businessIndustryApiV1BusinessIndustryGetByCodeTag",
    request
  );
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "businessIndustry");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.BusinessIndustryResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const businessIndustryCreate = async (request) => {
  logger.info(request);

  var apiResponse = await api(
    "businessIndustryApiV1BusinessIndustryCreate",
    request
  );
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "businessIndustry");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.BusinessIndustryResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const businessIndustryUpdate = async (request) => {
  logger.info(request);

  var apiResponse = await api(
    "businessIndustryApiV1BusinessIndustryUpdate",
    request
  );
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "businessIndustry");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.BusinessIndustryResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};

export const businessIndustryDelete = async (request) => {
  logger.info(request);

  var apiResponse = await api(
    "businessIndustryApiV1BusinessIndustryDelete",
    request
  );
  logger.info(apiResponse);
  let response = {};
  if (apiResponse.error) {
    exceptionCreate(apiResponse.error, "API_SERVICE", "businessIndustry");
    response.errorCode = 100;
    return response;
  } else {
    response = CloudService.BusinessIndustryResponse.constructFromObject(
      apiResponse.data
    );
    logger.info(response);
    return response;
  }
};
