import React from "react";
import { SupplyView } from "./SupplyView";
import {
  CrudlStatus,
  ToggleState,
  PageState,
  FeedbackState,
} from "../../common/config/AppConstants";
import { ViewState } from "../../common/config/AppConstants";
import { ValidateState } from "../../common/config/AppConstants";
import * as pr from "../../common/PrimeComponents";
import * as format from "../../common/FormatFunctions";
import { ErrorScreen } from "../Common/ErrorScreen";

export class SupplyController extends React.Component {
  state = {
    pageName: "SupplyController",
    pageState: PageState.IDLE,
    editLoading: true,

    //DISPLAY STATES
    viewState: ViewState.TABLE,
    validateState: ValidateState.NONE,
    crudlState: CrudlStatus.LIST,
    menuState: [],

    // DATA TABLE
    expandedRows: null,
    tableColumns: [],
    columnOptions: [],
    rowCount: 10,
    settingsToggle: ToggleState.OFF,

    // DETAILS
    supplyList: null,
    originalEditedSupply: "",
    editedSupply: null,
    receivedFeedback: {
      state: FeedbackState.NONE,
      display: "Supply",
      crudlStatus: CrudlStatus.LIST,
    },
  };

  componentDidMount = async () => {
    await this.initialDataRender();
  };

  componentDidCatch = (error, info) => {
    console.log("This went in here");

    //COMPONENT ISSUE CHECK IF THIS ERROR HANDLING IS CORRECT
    this.setError(false, "componentDidCatch", error);
  };

  initialDataRender = async () => {
    this.setViewModel();
    await this.setPageData();
  };

  setError = (critical, methodName, error) => {
    this.props.log.error(error.message);
    if (critical) {
      this.setCriticalError();
    } else {
      this.setStandardError();
    }
    // this.props.viewModel.createException(
    //   error,
    //   this.state.pageName,
    //   methodName
    // );
  };
  setStandardError = () => {
    //MORE CAN BE ADDED HERE AS APPROPRIATE
    this.showError("Supply");
    this.setState({ pageState: PageState.IDLE });
  };
  setCriticalError = () => {
    //SET ERROR STATE OF PAGE
    this.setState({ pageState: PageState.ERROR });
  };

  setViewModel = () => {
    try {
      this.props.viewModel.updateState = this.updateState;
      this.props.viewModel.showError = this.showError;
      this.props.viewModel.showSuccess = this.showSuccess;
      this.props.viewModel.showEmpty = this.showEmpty;
    } catch (error) {
      this.setError(false, "setViewModel", error);
    }
  };

  setPageData = async () => {
    try {
      this.setState({ pageState: PageState.LOADING });

      this.menuState(CrudlStatus.LIST);

      this.setUpColumns();

      // SPECIFIC API CALLS GO HERE
      await this.props.viewModel.getSupplys();
      await this.props.viewModel.getPossibleComponents();

      await this.createSetup();
      this.setState({
        pageState: PageState.IDLE,
      });
    } catch (error) {
      this.setError(true, "setPageData", error);
    }
  };
  showError = (display, crudlStatus, whereToView) => {
    try {
      if (whereToView === ViewState.TABLE) {
        this.props.feedback.showError(this.growl, display, crudlStatus);
      }
      if (whereToView === ViewState.CARD) {
        this.setState({
          receivedFeedback: {
            state: FeedbackState.ERROR,
            display: display,
            crudlStatus: crudlStatus,
          },
        });
      }
    } catch (error) {
      this.setError(false, "showError", error);
    }
  };

  showSuccess = (display, crudlStatus, whereToView) => {
    try {
      if (whereToView === ViewState.TABLE) {
        this.props.feedback.showSuccess(this.growl, display, crudlStatus);
      }
      if (whereToView === ViewState.CARD) {
        this.setState({
          receivedFeedback: {
            state: FeedbackState.SUCCESS,
            display: display,
            crudlStatus: crudlStatus,
          },
        });
      }
    } catch (error) {
      this.setError(false, "showSuccess", error);
    }
  };

  showEmpty = (display, whereToView) => {
    try {
      if (whereToView === ViewState.TABLE) {
        this.props.feedback.showEmpty(this.growl, display);
      }
      if (whereToView === ViewState.CARD) {
        this.setState({
          receivedFeedback: {
            state: FeedbackState.EMPTY,
            display: display,
            crudlStatus: CrudlStatus.LIST,
          },
        });
      }
    } catch (error) {
      this.setError(false, "showEmpty", error);
    }
  };

  getStateValuesAsObject = () => {
    try {
      var externalObject = {};
      externalObject.supplyBean = this.state.editedSupply;
      externalObject.componentList = this.state.componentList;

      return externalObject;
    } catch (error) {
      this.setError(false, "getStateValuesAsObject", error);
    }
  };

  updateBean = async (field, value) => {
    try {
      this.props.log.info(
        "Updating : [" + field + "] with value [" + value + "]"
      );
      await this.setState((prevState) => ({
        editedSupply: {
          // object that we want to update
          ...prevState.editedSupply, // keep all other key-value pairs
          [field]: value,
        },
      }));
    } catch (error) {
      this.setError(false, "updateBean", error);
    }
  };

  updateState = (key, value) => {
    try {
      this.setState({ [key]: value });
    } catch (error) {
      this.setError(false, "updateState", error);
    }
  };

  validateChanges = async () => {
    try {
      if (this.state.editedSupply === null) {
        await this.discardChanges();
      } else {
        if (
          format.isJsonEqual(
            this.state.originalEditedSupply,
            this.state.editedSupply
          )
        ) {
          await this.discardChanges();
        } else {
          this.setState({ validateState: ValidateState.CONFIRM });
        }
      }
    } catch (error) {
      this.setError(false, "validateChanges", error);
    }
  };

  discardChanges = async () => {
    try {
      this.setState({
        viewState: ViewState.TABLE,
        toggleChangesMade: false,
        crudlState: CrudlStatus.LIST,
        selectedSupply: null,
      });
      await this.setPageData();
      this.menuState(CrudlStatus.LIST);
    } catch (error) {
      this.setError(false, "discardChanges", error);
    }
  };

  checkRequiredFields = () => {
    try {
      if (format.isNotNull(this.state.editedSupply)) {
        // ADD REQUIREDD FIELDS HERE AS APPROPRIATE
        return format.validateValues([
          this.state.editedSupply.name,
          this.state.editedSupply.componentId,
          this.state.editedSupply.longDescriptionText,
        ]);
      } else {
        return true;
      }
    } catch (error) {
      this.setError(false, "checkRequiredFields", error);
    }
  };

  menuState = (crudlStatus) => {
    try {
      this.setState({
        menuState: pr.getBreadcrumbMenuState(
          crudlStatus,
          "Supply",
          "#/supplys"
        ),
      });
    } catch (error) {
      this.setError(false, "menuState", error);
    }
  };

  executeCrudl = async (stayOnPage) => {
    try {
      if (format.isNotNull(this.state.crudlState)) {
        this.props.log.info(
          "Executing " +
            this.state.crudlState.value +
            " Staying[" +
            stayOnPage +
            "]"
        );

        switch (this.state.crudlState) {
          case CrudlStatus.CREATE:
            await this.addSupply(stayOnPage);
            break;
          case CrudlStatus.UPDATE:
            await this.updateSupply(stayOnPage);
            break;
          case CrudlStatus.DELETE:
            await this.deleteSupply();
            break;
          default:
            break;
        }
      }
    } catch (error) {
      this.setError(false, "executeCrudl", error);
    }
  };

  executeSetup = async (requiredCrudlState, data) => {
    try {
      if (format.isNotNull(requiredCrudlState)) {
        this.props.log.info("Setting up " + requiredCrudlState.value);
        switch (requiredCrudlState) {
          case CrudlStatus.CREATE:
            await this.createSetup();
            break;
          case CrudlStatus.UPDATE:
            await this.updateSetup(data);
            break;
          case CrudlStatus.VIEW:
            await this.viewSetup(data);
            break;
          case CrudlStatus.DELETE:
            await this.deleteSetup(data);
            break;
          default:
            break;
        }
      }
    } catch (error) {
      this.setError(false, "executeSetup", error);
    }
  };

  setUpColumns = () => {
    let localCols = [];
    //SPECIFIC COLUMNS FOR TABLE
    localCols.push(pr.getTableColumn("name", "Name"));
    localCols.push(pr.getTableColumn("componentNameToDisplay", "Type"));
    localCols.push(pr.getTableColumn("productStatus", "Active"));
    this.setState({ tableColumns: localCols });
    var colOptions = pr.getColumnOptions(localCols);
    this.setState({ columnOptions: colOptions });
  };

  columnToggle = (event) => {
    this.setState({ tableColumns: event.value });
  };

  createSetup = async () => {
    window.scrollTo(0, 0);
    this.setState({
      viewState: ViewState.LIST,
      pageState: PageState.LOADING,
    });

    var data = {};
    data = await this.additionalParseFunctions(data, CrudlStatus.CREATE);

    this.setState({
      viewState: ViewState.CARD,
      crudlState: CrudlStatus.CREATE,
      editedSupply: data,
      pageState: PageState.IDLE,
      originalEditedSupply: JSON.stringify(data),
    });

    this.menuState(CrudlStatus.CREATE);
  };

  updateSetup = async (data) => {
    window.scrollTo(0, 0);

    this.setState({
      viewState: ViewState.LIST,
      pageState: PageState.LOADING,
    });

    data = await this.additionalParseFunctions(data, CrudlStatus.UPDATE);

    this.setState({
      viewState: ViewState.CARD,
      crudlState: CrudlStatus.UPDATE,
      pageState: PageState.IDLE,
      editedSupply: data,
      originalEditedSupply: JSON.stringify(data),
    });

    this.menuState(CrudlStatus.UPDATE);
  };

  viewSetup = async (data) => {
    window.scrollTo(0, 0);

    data = await this.additionalParseFunctions(data, CrudlStatus.VIEW);

    this.setState({
      viewState: ViewState.CARD,
      crudlState: CrudlStatus.VIEW,
      editedSupply: data,
      originalEditedSupply: JSON.stringify(data),
    });

    this.menuState(CrudlStatus.VIEW);
  };

  deleteSetup = async (data) => {
    this.setState({
      viewState: ViewState.LIST,
      pageState: PageState.LOADING,
    });

    data = await this.additionalParseFunctions(data, CrudlStatus.DELETE);

    this.setState({
      viewState: ViewState.CARD,
      crudlState: CrudlStatus.DELETE,
      pageState: PageState.IDLE,
      editedSupply: data,
      originalEditedSupply: JSON.stringify(data),
      validateState: ValidateState.DELETE,
    });
    this.menuState(CrudlStatus.DELETE);
  };

  additionalParseFunctions = async (data, crudlState) => {
    //TODO if required
    switch (crudlState) {
      case CrudlStatus.CREATE:
        data.longDescriptionText =
          "<p><strong><em>Please add some of the following details for potential buyers :</em></strong></p><p><br></p><p><br></p><ul><li>Product/Service Description</li><li>Product/Service Price</li><li>Images</li><li>Product Size</li><li>Manufacturing Capacity</li><li>Any other relevant information</li></ul>";
        data.productStatus = "Active";
        break;
      case CrudlStatus.UPDATE || CrudlStatus.VIEW:
        data.componentId = data.componentList[0];
        break;
      case CrudlStatus.DELETE:
        break;
      default:
        break;
    }
    this.props.log.info(data);
    return data;
  };

  addSupply = async (stayOnPage) => {
    this.setState({ pageState: PageState.LOADING });
    var stateVariables = this.getStateValuesAsObject();

    if (!format.isNotNull(stayOnPage) || stayOnPage === false) {
      stateVariables.viewState = ViewState.TABLE;
    } else {
      stateVariables.viewState = ViewState.CARD;
    }

    var supplyResponse = await this.props.viewModel.executeCRUDLAction(
      CrudlStatus.CREATE,
      stateVariables
    );

    if (supplyResponse.errorCode === 0) {
      if (!format.isNotNull(stayOnPage) || stayOnPage === false) {
        await this.updateSupplyList(
          supplyResponse,
          CrudlStatus.CREATE,
          stateVariables
        );
      } else {
        await this.updateCurrentSupply(
          supplyResponse,
          CrudlStatus.CREATE,
          stateVariables
        );
      }
    } else {
      this.setState({ pageState: PageState.IDLE });
    }
  };
  updateSupply = async (stayOnPage) => {
    this.setState({ pageState: PageState.LOADING });
    var stateVariables = this.getStateValuesAsObject();

    if (!format.isNotNull(stayOnPage) || stayOnPage === false) {
      stateVariables.viewState = ViewState.TABLE;
    } else {
      stateVariables.viewState = ViewState.CARD;
    }

    var supplyResponse = await this.props.viewModel.executeCRUDLAction(
      CrudlStatus.UPDATE,
      stateVariables
    );

    if (supplyResponse.errorCode === 0) {
      if (!format.isNotNull(stayOnPage) || stayOnPage === false) {
        await this.updateSupplyList(
          supplyResponse,
          CrudlStatus.CREATE,
          stateVariables
        );
      } else {
        await this.updateCurrentSupply(
          supplyResponse,
          CrudlStatus.CREATE,
          stateVariables
        );
      }
    } else {
      this.setState({ pageState: PageState.IDLE });
    }
  };

  deleteSupply = async () => {
    this.setState({ pageState: PageState.LOADING });
    var stateVariables = this.getStateValuesAsObject();

    var supplyResponse = await this.props.viewModel.executeCRUDLAction(
      CrudlStatus.DELETE,
      stateVariables
    );

    if (supplyResponse.errorCode === 0) {
      await this.updateSupplyList(
        supplyResponse,
        CrudlStatus.DELETE,
        stateVariables
      );
    } else {
      this.setState({ pageState: PageState.IDLE });
    }
  };

  updateSupplyList = async (apiResponse, type, stateVariables) => {
    this.setState({ pageState: PageState.LOADING });

    console.log("Updating Supply List");
    await this.props.viewModel.getSupplys();

    this.setState({
      viewState: ViewState.CARD,
      crudlState: CrudlStatus.CREATE,
      validateState: ValidateState.NONE,
    });

    await this.createSetup();

    this.menuState(CrudlStatus.CREATE);

    this.setState({ pageState: PageState.IDLE });
  };

  updateCurrentSupply = async (apiResponse, type, stateVariables) => {
    this.setState({ pageState: PageState.LOADING });

    var supplyList = await this.props.viewModel.getSupplysReturned();
    var supply = {};
    if (format.isNotNull(supplyList)) {
      supplyList.forEach((element) => {
        if (element.id === apiResponse.id) {
          supply = format.deepCopy(element);
        }
      });
    }
    await this.updateSetup(supply);
    this.setState({ supplyList: supplyList });
    this.setState({ pageState: PageState.IDLE });
  };

  render() {
    return (
      <React.Fragment>
        <pr.Messages
          style={{ textAlign: "left" }}
          ref={(el) => (this.growl = el)}
        />

        {/*VERY VERY BAD ERROS ONLY eg PAGE LOAD*/}
        {this.state.pageState === PageState.ERROR && (
          <ErrorScreen
            loading={this.state.pageState === PageState.LOADING}
            refresh={this.setPageData}
          />
        )}

        {this.state.pageState !== PageState.ERROR && (
          <SupplyView
            //STATE + HELPER VALUES
            viewState={this.state.viewState}
            validateState={this.state.validateState}
            crudlState={this.state.crudlState}
            menuState={this.state.menuState}
            loading={this.state.pageState === PageState.LOADING}
            updateState={this.updateState}
            refresh={this.setPageData}
            feedback={this.props.feedback}
            receivedFeedback={this.state.receivedFeedback}
            //CARD SPECIFIC PROPS
            editedObject={this.state.editedSupply}
            supplyTypeOptions={this.state.supplyTypeOptions}
            updateEdited={this.updateBean}
            validateChanges={this.validateChanges}
            discardChanges={this.discardChanges}
            checkRequiredFields={this.checkRequiredFields}
            crudlExecute={this.executeCrudl}
            sidebarWidth={"75%"}
            //TABLE SPECIFIC PROPS
            crudlControl={this.executeSetup}
            supplyList={this.state.supplyList}
            expandedRows={this.state.expandedRows}
            tableColumns={this.state.tableColumns}
            columnOptions={this.state.columnOptions}
            columnToggle={this.columnToggle}
            tableReference={this.state.tableReference}
            rowCount={this.state.rowCount}
            settingsToggle={this.state.settingsToggle}
            componentList={this.state.componentList}
            productDetails={this.state.productDetails}
            productDetailsView={this.state.productDetailsView}
          />
        )}
      </React.Fragment>
    );
  }
}
