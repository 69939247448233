import React from "react";
import { CategoryController } from "./CategoryController";
import { CategoryViewModel } from "./CategoryViewModel";
import * as feedback from "../../common/Feedback/Feedback";
import * as log from "../../common/Feedback/Log";

export class CategoryProvider extends React.Component {
  constructor(props) {
    super(props);
    this.viewModel = new CategoryViewModel();
    this.logger = log;
    this.feedback = feedback;

    console.log(props);
  }

  render() {
    return (
      <CategoryController
        viewModel={this.viewModel}
        log={this.logger}
        feedback={this.feedback}
        toggleTopbar={this.props.toggleTopbar}
        // Additional Props
        sidebarWidth={this.props.sidebarWidth}
        // Add ViewType in src/common/config/AppConstants.js
        viewType={this.props.viewType}
        codeTag={this.props.codeTag}
        // dependant on viewType
        crudlState={this.props.crudlState}
        editedObjectFromProps={this.props.editedObjectFromProps}
        updateStateFromProps={this.props.updateStateFromProps}
        updateObjectFromProps={this.props.updateObjectFromProps}
        editedBusiness={this.props.editedBusiness}
      />
    );
  }
}
