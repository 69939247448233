import {
  ApplicationStatus,
  ApplicationType,
  BusinessType,
  FeedbackOptions,
  ProductStatus,
} from "./AppConstants";

export const convertEnumToDropdown = (enumObject, changeCase) => {
  console.log(enumObject);
  console.log(changeCase);
  var all = [];
  for (var key in enumObject) {
    if (changeCase === true) {
      var label =
        enumObject[key].charAt(0).toUpperCase() +
        enumObject[key].slice(1).toLowerCase();
      all.push({ label: label, value: enumObject[key] });
    } else {
      all.push({ label: enumObject[key], value: enumObject[key] });
    }
  }
  return all;
};

export const CapabilityOptions = [
  { label: "Yes, I can supply", value: "Yes, I can supply" },
  {
    label: "Potential, open for development",
    value: "Potential, open for development",
  },
  { label: "Yes, I want to procure", value: "Yes, I want to procure" },
  {
    label: "Potential, open to procurement",
    value: "Potential, open to procurement",
  },
  {
    label: "Yes, I want to procure and can supply",
    value: "Yes, I want to procure and can supply",
  },
  {
    label: "Potential, open to procurement and open for development",
    value: "Potential, open to procurement and open for development",
  },
];

export const CapabilityBuyerOptions = [
  { label: "Yes, I want to procure", value: "Yes, I want to procure" },
  {
    label: "Potential, open to procurement",
    value: "Potential, open to procurement",
  },
];

export const CapabilitySupplierOptions = [
  { label: "Yes, I can supply", value: "Yes, I can supply" },
  {
    label: "Potential, open for development",
    value: "Potential, open for development",
  },
];

export const CountyOptions = [
  { label: "Antrim", value: "Antrim" },
  { label: "Armagh", value: "Armagh" },
  { label: "Derry/Londonderry", value: "Derry/Londonderry" },
  { label: "Down", value: "Down" },
  { label: "Fermanagh", value: "Fermanagh" },
  { label: "Tyrone", value: "Tyrone" },
  { label: "Republic of Ireland", value: "Republic of Ireland" },
];

export const DemographicAreaOptions = [
  {
    label: "Antrim and Newtownabbey Borough Council",
    value: "Antrim and Newtownabbey Borough Council",
  },
  {
    label: "Ards and North Down Borough Council",
    value: "Ards and North Down Borough Council",
  },
  {
    label: "Armagh City, Banbridge and Craigavon Borough",
    value: "Armagh City, Banbridge and Craigavon Borough Council",
  },
  { label: "Belfast City Council", value: "Belfast City Council" },
  {
    label: "Causeway Coast and Glens Borough Council",
    value: "Causeway Coast and Glens Borough Council",
  },
  {
    label: "Derry City and Strabane District Council",
    value: "Derry City and Strabane District Council",
  },
  {
    label: "Fermanagh and Omagh District Council",
    value: "Fermanagh and Omagh District Council",
  },
  {
    label: "Lisburn and Castlereagh City Council",
    value: "Lisburn and Castlereagh City Council",
  },
  {
    label: "Mid and East Antrim Borough Council",
    value: "Mid and East Antrim Borough Council",
  },
  {
    label: "Mid Ulster District Council",
    value: "Mid Ulster District Council",
  },
  {
    label: "Newry, Mourne and Down District Council",
    value: "Newry, Mourne and Down District Council",
  },
  {
    label: "Republic of Ireland",
    value: "Republic of Ireland",
  },
];

export const DiscoveredByOptions = [
  { label: "Direct Mail", value: "Direct Mail" },
  { label: "Email", value: "Email" },
  { label: "Advertising", value: "Advertising" },
  { label: "Event", value: "Event" },
  { label: "Social Media", value: "Social Media" },
  { label: "Word of Mouth", value: "Word of Mouth" },
  { label: "Council Staff", value: "Council Staff" },
];

export const BusinessTypeOptions = [
  { label: "Buyer", value: BusinessType.BUYER },
  { label: "Supplier", value: BusinessType.SUPPLIER },
  { label: "Buyer & Supplier", value: BusinessType.BUYERSUPPLIER },
];

export const ProductStatusOptions = [
  { label: "Active", value: ProductStatus.ACTIVE },
  { label: "Inactive", value: ProductStatus.INACTIVE },
];

export const ApplicationTypeOptions = [
  { label: ApplicationType.BUYER, value: ApplicationType.BUYER },
  { label: ApplicationType.SUPPLIER, value: ApplicationType.SUPPLIER },
];

export const ApplicationStatusOptions = [
  // { label: "Submitted", value: ApplicationStatus.SUBMITTED },
  { label: "Successful", value: ApplicationStatus.SUCCESSFUL },
  { label: "Unsuccessful", value: ApplicationStatus.UNSUCCESSFUL },
];

export const ApplicationStatusOptionsAll = [
  { label: "Accepted", value: ApplicationStatus.ACCEPTED },
  { label: "Submitted", value: ApplicationStatus.SUBMITTED },
  { label: "Seen", value: ApplicationStatus.SEEN },
  { label: "In Progress", value: ApplicationStatus.IN_PROGRESS },
  { label: "Unsuccessful", value: ApplicationStatus.UNSUCCESSFUL },
  { label: "Withdrawn", value: ApplicationStatus.WITHDRAWN },
  { label: "Successful", value: ApplicationStatus.SUCCESSFUL },
];

export const ApplicationFeedbackOptions = [
  { label: "Not Relevant", value: FeedbackOptions.NOT_RELEVANT },
  { label: "Quality", value: FeedbackOptions.QUALITY },
  { label: "Quantity", value: FeedbackOptions.QUANTITY },
  { label: "Unprofessional", value: FeedbackOptions.UNPROFESSIONAL },
  { label: "Service", value: FeedbackOptions.SERVICE },
];

export const CurrencyOptions = [
  {
    label: "US Dollar (USD)",
    value: "US Dollar (USD)",
  },
  { label: "Euro (EUR)", value: "Euro (EUR)" },
  {
    label: "Japenese Yen (JPY)",
    value: "Japenese Yen (JPY)",
  },
  {
    label: "Pound Sterling (GBP)",
    value: "Pound Sterling (GBP)",
  },
  {
    label: "Australian Dollar (AUD)",
    value: "Australian Dollar (AUD)",
  },
  {
    label: "Canadian Dollar (CAD)",
    value: "Canadian Dollar (CAD)",
  },
  {
    label: "Swiss Franc (CHF)",
    value: "Swiss Franc (CHF)",
  },
  {
    label: "Chinese Renminbi (CNH)",
    value: "Chinese Renminbi (CNH)",
  },
  {
    label: "Swedish Krona (SEK)",
    value: "Swedish Krona (SEK)",
  },
  {
    label: "New Zealand Dollar (NZD)",
    value: "New Zealand Dollar (NZD)",
  },
  {
    label: "Polish Złoty (PLN)",
    value: "Polish Złoty (PLN)",
  },
];

export const YES_NO_OPTIONS = [
  { label: "Yes", value: "Yes" },
  {
    label: "No",
    value: "No",
  },
];
export const BUSINESS_SIZE_OPTIONS = [
  { label: "Less than 10", value: "<10" },
  {
    label: "10-49",
    value: "10-49",
  },
  { label: "50-99", value: "50-99" },
  {
    label: "100-249",
    value: "100-249",
  },
  { label: "250", value: "499" },
  {
    label: "500+",
    value: "500+",
  },
];

export const COMPONENT_TYPE = [
  { label: "Manufacture", value: "MANUFACTURE" },
  {
    label: "PPE",
    value: "PPE",
  },
];
