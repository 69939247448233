import React, { Component } from "react";
import * as pr from "../../common/PrimeComponents";

export class ErrorScreen extends Component {
  render() {
    return (
      <div className="p-col-12">
        <div className="splash-screen" style={{ background: "#043066" }}>
          <div
            className="splash-container"
            style={{ paddingTop: "10em", paddingBottom: "10em" }}
          >
            <div>
              <img
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "99%"
                }}
                src="/assets/layout/images/business/error.png"
                alt=""
              />
            </div>
            <div
              style={{
                color: "white"
              }}
            >
              <label>
                Something went wrong there, please refresh to try again
              </label>

              {/* <div className="load-bar">
              <div className="bar" />
              <div className="bar" />
              <div className="bar" />
            </div> */}
            </div>
            <pr.Button
              style={{
                float: "right",
                background: "green"
              }}
              icon={
                this.props.loading === true
                  ? "pi pi-spin pi-spinner"
                  : "pi pi-refresh"
              }
              onClick={this.props.refresh}
            ></pr.Button>
          </div>
        </div>
      </div>
    );
  }
}
