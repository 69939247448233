import React from "react";
import { DashboardController } from "./DashboardController";
import { DashboardViewModel } from "./DashboardViewModel";
import * as feedback from "../../common/Feedback/Feedback";
import * as log from "../../common/Feedback/Log";

export class DashboardProvider extends React.Component {
  constructor(props) {
    super(props);
    this.viewModel = new DashboardViewModel();
    this.logger = log;
    this.feedback = feedback;

    console.log(props);
  }

  render() {
    return (
      <DashboardController
        viewModel={this.viewModel}
        log={this.logger}
        feedback={this.feedback}
        toggleTopbar={this.props.toggleTopbar}
        // Additional Props
        sidebarWidth={this.props.sidebarWidth}
        // Add ViewType in src/common/config/AppConstants.js
        viewType={this.props.viewType}
        // dependant on viewType
        crudlState={this.props.crudlState}
        editedObjectFromProps={this.props.editedObjectFromProps}
        updateStateFromProps={this.props.updateStateFromProps}
        updateObjectFromProps={this.props.updateObjectFromProps}
      />
    );
  }
}
