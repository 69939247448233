import React, { Component } from "react";
import PropTypes from "prop-types";

export class PPEMasterTopbar extends Component {
  static defaultProps = {
    onMenuButtonClick: null,
    onTopbarMenuButtonClick: null,
    onTopbarItemClick: null,
    profileMode: null,
    horizontal: false,
    topbarMenuActive: false,
    activeTopbarItem: null,
    onRightPanelButtonClick: null,
  };

  static propTypes = {
    onMenuButtonClick: PropTypes.func.isRequired,
    onTopbarMenuButtonClick: PropTypes.func.isRequired,
    onTopbarItemClick: PropTypes.func.isRequired,
    profileMode: PropTypes.string.isRequired,
    horizontal: PropTypes.bool.isRequired,
    topbarMenuActive: PropTypes.bool.isRequired,
    activeTopbarItem: PropTypes.string,
    onRightPanelButtonClick: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {};
  }

  onTopbarItemClick(event, item) {
    if (this.props.onTopbarItemClick) {
      this.props.onTopbarItemClick({
        originalEvent: event,
        item: item,
      });
    }
  }

  render() {
    return (
      <div className="topbar clearfix">
        <div
          className="topbar-left"
          style={{ backgroundColor: "transparent" }}
        ></div>

        <div className="topbar-right">
          <button id="menu-button" onClick={this.props.onMenuButtonClick}>
            <i />
          </button>
        </div>
      </div>
    );
  }
}
