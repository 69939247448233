import { BaseViewModel } from "../../common/BaseViewModel";
import * as Format from "../../common/FormatFunctions";
import { CloudService } from "../../common/CloudService/ClientLibsReference";
import { CrudlStatus, ViewState } from "../../common/config/AppConstants";
import * as ApiService from "../../common/CloudService/ApiService";
export class ReportingViewModel extends BaseViewModel {
  getReportUrl = async () => {
    var reportingResponse = {
      errorCode: 0,
      reportingUrl: localStorage.getItem("reportingUrl"),
    };

    if (
      Format.isNotNull(reportingResponse.errorCode) &&
      reportingResponse.errorCode === 0
    ) {
      if (Format.isNotNull(reportingResponse.reportingUrl)) {
        this.updateState("reportingUrl", reportingResponse.reportingUrl);
      } else {
        this.showEmpty("Reporting", ViewState.TABLE);
      }
    } else {
      this.showError("Reporting", CrudlStatus.LIST, ViewState.TABLE);
    }
  };

  getReportings = async () => {
    //CRITICAL - ERROR + EMPTY MESSAGE
    var reportingResponse = {}; //await this.getReportingListByCodeTag();
    if (
      Format.isNotNull(reportingResponse.errorCode) &&
      reportingResponse.errorCode === 0
    ) {
      if (Format.isNotNull(reportingResponse.reportingList)) {
        this.updateState("reportingList", reportingResponse.reportingList);
        this.logger.info(reportingResponse.reportingList);
      } else {
        this.showEmpty("Reporting", ViewState.TABLE);
      }
    } else {
      this.showError("Reporting", CrudlStatus.LIST, ViewState.TABLE);
    }
  };

  getReportingsReturned = async () => {
    //CRITICAL - ERROR + EMPTY MESSAGE
    var reportingResponse = {}; //await this.getReportingListByCodeTag();
    if (
      Format.isNotNull(reportingResponse.errorCode) &&
      reportingResponse.errorCode === 0
    ) {
      if (Format.isNotNull(reportingResponse.reportingList)) {
        this.logger.info(reportingResponse.reportingList);
        return reportingResponse.reportingList;
      } else {
        this.showEmpty("Reporting", ViewState.TABLE);
      }
    } else {
      this.showError("Reporting", CrudlStatus.LIST, ViewState.TABLE);
    }
  };

  reloadDataAfterExecute = (
    reportings,
    apiResponse,
    type,
    externalStateValues
  ) => {
    var updatedReportingList = [];
    if (type === CrudlStatus.CREATE) {
      updatedReportingList = this.reloadAfterCreate(
        reportings,
        apiResponse,
        externalStateValues
      );
    }
    if (type === CrudlStatus.UPDATE) {
      updatedReportingList = this.reloadAfterUpdate(
        reportings,
        apiResponse,
        externalStateValues
      );
    }
    if (type === CrudlStatus.DELETE) {
      updatedReportingList = this.reloadAfterDelete(
        reportings,
        apiResponse,
        externalStateValues
      );
    }

    this.updateState("reportingList", updatedReportingList);
  };

  reloadAfterCreate = (reportingList, apiResponse, externalStateValues) => {
    if (reportingList === undefined || reportingList === null) {
      reportingList = [];
    }
    var reportingBean = externalStateValues.reportingBean;
    reportingBean.id = apiResponse.id;
    reportingBean.lastUpdatedDateTime = apiResponse.lastUpdatedDateTime;

    reportingList.push(reportingBean);

    return reportingList;
  };

  reloadAfterUpdate = (reportingList, apiResponse, externalStateValues) => {
    var i = 0;
    reportingList.forEach((e) => {
      if (apiResponse.id === e.id) {
        reportingList[i] = externalStateValues.reportingBean;
        reportingList[i].lastUpdatedDateTime = apiResponse.lastUpdatedDateTime;
      }
      i++;
    });

    return reportingList;
  };

  reloadAfterDelete = (reportingList, apiResponse, externalStateValues) => {
    var i = 0;
    reportingList.forEach((e) => {
      //DELETE
      if (apiResponse.id === e.id) {
        reportingList.splice(i, 1);
      }
      i++;
    });

    return reportingList;
  };
  executeCRUDLAction = async (crudlStatus, stateVariables) => {
    let reportingRequest = CloudService.ReportingRequest.constructFromObject(
      stateVariables.reportingBean
    );
    if (crudlStatus === CrudlStatus.CREATE) {
      return await this.createReporting(reportingRequest, stateVariables);
    }
    if (crudlStatus === CrudlStatus.UPDATE) {
      return await this.updateReporting(reportingRequest, stateVariables);
    }
    if (crudlStatus === CrudlStatus.DELETE) {
      return await this.deleteReporting(reportingRequest);
    }
  };

  createReporting = async (reportingRequest, stateVariables) => {
    reportingRequest.codeTag = this.codeTag;
    reportingRequest.userId = this.userId;

    let reportingResponse = {}; //await ApiService.reportingCreate(reportingRequest);
    if (reportingResponse.errorCode === 0) {
      this.showSuccess(
        "Reporting",
        CrudlStatus.CREATE,
        stateVariables.viewState
      );
    } else {
      this.showError("Reporting", CrudlStatus.CREATE, ViewState.CARD);
    }

    return reportingResponse;
  };

  deleteReporting = async (reportingRequest) => {
    //RESET PRODUCT REQUEST
    var request = {};
    request.codeTag = this.codeTag;
    request.userId = this.userId;
    request.id = reportingRequest.id;
    request.lastUpdatedDateTime = reportingRequest.lastUpdatedDateTime;

    let reportingResponse = {}; //await ApiService.reportingDelete(request);
    if (reportingResponse.errorCode === 0) {
      this.showSuccess("Reporting", CrudlStatus.DELETE, ViewState.TABLE);
    } else {
      this.showError("Reporting", CrudlStatus.DELETE, ViewState.CARD);
    }

    return reportingResponse;
  };

  updateReporting = async (reportingRequest, stateVariables) => {
    reportingRequest.codeTag = this.codeTag;
    reportingRequest.userId = this.userId;

    let reportingResponse = {}; //await ApiService.reportingUpdate(reportingRequest);
    if (reportingResponse.errorCode === 0) {
      this.showSuccess(
        "Reporting",
        CrudlStatus.UPDATE,
        stateVariables.viewState
      );
    } else {
      this.showError("Reporting", CrudlStatus.UPDATE, ViewState.CARD);
    }

    return reportingResponse;
  };
}
