import { api } from "../../common/CloudService/ApiPromisify";
//ClientLibsReference

import * as pr from "../../common/PrimeComponents";

import * as Format from "../../common/FormatFunctions";
import { CloudService } from "../../common/CloudService/ClientLibsReference";

import { CrudlStatus, ViewState } from "../../common/config/AppConstants";

export class PPEProfileViewModel {
  showLoadingScreen = () => {
    this.updateState("loaded", false);
  };

  hideLoadingScreen = () => {
    this.updateState("loaded", true);
  };

  componentGetByType = async () => {
    let componentRequest = new CloudService.ComponentRequest();

    componentRequest.type = "PPE";

    var componentList = [];
    await api("componentApiV1ComponentGetByType", componentRequest).then(
      (res) => {
        if (res.data) {
          let componentResponse =
            CloudService.ComponentResponse.constructFromObject(res.data);
          console.log(componentResponse);
          componentList = componentResponse.componentList;
        }
      }
    );

    return componentList;
  };

  loadBusinessDetails = async () => {
    var allComponentList = await this.componentGetByType();

    var [profileBean, componentList, businessBean] = await this.getProfile();

    allComponentList.forEach((element) => {
      componentList.forEach((component) => {
        if (element.id === component) {
          element.checked = true;
        }
      });
    });

    if (profileBean.type === "Both") {
      businessBean.supplier = true;
      businessBean.buyer = true;
    }
    if (profileBean.type === "PPEBuyer") {
      businessBean.supplier = false;
      businessBean.buyer = true;
    }
    if (profileBean.type === "PPESupplier") {
      businessBean.supplier = true;
      businessBean.buyer = false;
    }
    console.log(profileBean);
    console.log(businessBean);
    console.log(allComponentList);
    this.updateState("componentList", allComponentList);
    this.updateState("businessBean", businessBean);
    this.updateState("profileBean", profileBean);
  };

  registerGetList = async () => {
    await api("registerApiV1RegisterGetEnums", {}).then((res) => {
      if (res.data) {
        let registerResponse =
          CloudService.RegisterResponse.constructFromObject(res.data);
        console.log(registerResponse);
        this.updateState(
          "sectorOptions",
          pr.getDropdownOptionsFromList(registerResponse.sectorList)
        );
        this.updateState(
          "demographicAreaOptions",
          pr.getDropdownOptionsFromList(registerResponse.demographicAreaList)
        );
        this.updateState(
          "businessType",
          pr.getDropdownOptionsFromList(registerResponse.businessTypeList)
        );
      }
    });
  };

  getProfile = async () => {
    let profileRequest = new CloudService.ProfileRequest();
    profileRequest.codeTag = localStorage.getItem("clientId");

    var profileBean = {};
    var businessBean = {};
    var componentList = [];

    await api("profileApiV1ProfileGetByCodeTag", profileRequest).then((res) => {
      if (res.data) {
        let profileResponse = CloudService.ProfileResponse.constructFromObject(
          res.data
        );
        console.log(profileResponse);

        if (Array.isArray(profileResponse.profileList)) {
          profileResponse.profileList.forEach((element) => {
            if (element.type === "PPESupplier") {
              componentList = element.componentList;
              profileBean = element;
              businessBean = element.companyDetails;
            }
            if (element.type === "PPEBuyer") {
              componentList = element.componentList;
              profileBean = element;
              businessBean = element.companyDetails;
            }
            if (element.type === "Both") {
              componentList = element.componentList;
              profileBean = element;
              businessBean = element.companyDetails;
            }
          });
        }
      }
    });

    return [profileBean, componentList, businessBean];
  };

  reloadDataAfterExecute = (
    ppeprofiles,
    apiResponse,
    type,
    externalStateValues
  ) => {
    var updatedPPEProfileList = [];
    if (type === CrudlStatus.CREATE) {
      updatedPPEProfileList = this.reloadAfterCreate(
        ppeprofiles,
        apiResponse,
        externalStateValues
      );
    }
    if (type === CrudlStatus.UPDATE) {
      updatedPPEProfileList = this.reloadAfterUpdate(
        ppeprofiles,
        apiResponse,
        externalStateValues
      );
    }
    if (type === CrudlStatus.DELETE) {
      updatedPPEProfileList = this.reloadAfterDelete(
        ppeprofiles,
        apiResponse,
        externalStateValues
      );
    }

    this.updateState("ppeprofileList", updatedPPEProfileList);
  };

  reloadAfterCreate = (ppeprofileList, apiResponse, externalStateValues) => {
    if (ppeprofileList === undefined || ppeprofileList === null) {
      ppeprofileList = [];
    }
    var ppeprofileBean = externalStateValues.ppeprofileBean;
    ppeprofileBean.id = apiResponse.id;
    ppeprofileBean.lastUpdatedDateTime = apiResponse.lastUpdatedDateTime;

    ppeprofileList.push(ppeprofileBean);

    return ppeprofileList;
  };

  reloadAfterUpdate = (ppeprofileList, apiResponse, externalStateValues) => {
    var i = 0;
    ppeprofileList.forEach((e) => {
      if (apiResponse.id === e.id) {
        ppeprofileList[i] = externalStateValues.ppeprofileBean;
        ppeprofileList[i].lastUpdatedDateTime = apiResponse.lastUpdatedDateTime;
      }
      i++;
    });

    return ppeprofileList;
  };

  reloadAfterDelete = (ppeprofileList, apiResponse, externalStateValues) => {
    var i = 0;
    ppeprofileList.forEach((e) => {
      //DELETE
      if (apiResponse.id === e.id) {
        ppeprofileList.splice(i, 1);
      }
      i++;
    });

    return ppeprofileList;
  };
  executeCRUDLAction = async (crudlStatus, stateVariables) => {
    let ppeprofileRequest = stateVariables.ppeprofileBean;
    if (crudlStatus === CrudlStatus.CREATE) {
      return await this.createPPEProfile(ppeprofileRequest, stateVariables);
    }
    if (crudlStatus === CrudlStatus.UPDATE) {
      return await this.updatePPEProfile(ppeprofileRequest, stateVariables);
    }
    if (crudlStatus === CrudlStatus.DELETE) {
      return await this.deletePPEProfile(ppeprofileRequest);
    }
  };

  updateBusiness = async (businessRequest) => {
    businessRequest.userId = localStorage.getItem("userId");

    delete businessRequest.businessName;

    console.log(businessRequest);
    var businessResponse = {};

    await api("businessApiV1BusinessUpdate", businessRequest).then((res) => {
      if (res.data) {
        businessResponse = CloudService.BusinessResponse.constructFromObject(
          res.data
        );
        console.log(businessResponse);
        if (
          Format.isNotNull(businessResponse.errorCode) &&
          businessResponse.errorCode === 0
        ) {
          this.showSuccess("Business", CrudlStatus.CREATE, ViewState.CARD);
        } else {
          this.showError("Business", CrudlStatus.CREATE, ViewState.CARD);
        }
      }
    });

    return businessResponse;
  };

  updateProfile = async (profileRequest, ppeBeanList) => {
    await this.getMatrixItemsAndDelete();

    if (profileRequest.supplier === false && profileRequest.buyer === false) {
      profileRequest.type = "PPEBuyer";
    }
    if (profileRequest.supplier === true && profileRequest.buyer === false) {
      profileRequest.type = "PPESupplier";
    }
    if (profileRequest.supplier === false && profileRequest.buyer === true) {
      profileRequest.type = "PPEBuyer";
    }
    if (profileRequest.supplier === true && profileRequest.buyer === true) {
      profileRequest.type = "Both";
    }
    if (Format.isNotNull(ppeBeanList)) {
      let componentIdList = [];
      ppeBeanList.forEach((e) => {
        if (e.checked) {
          componentIdList.push(e.id);
        }
      });

      profileRequest.componentListSupplier = componentIdList;
      profileRequest.componentListBuyer = componentIdList;
      profileRequest.componentList = componentIdList;
    }

    console.log(profileRequest);
    var profileResponse = {};

    await api("profileApiV1ProfileUpdate", profileRequest).then((res) => {
      if (res.data) {
        profileResponse = CloudService.ProfileResponse.constructFromObject(
          res.data
        );
        console.log(profileResponse);
        if (
          Format.isNotNull(profileResponse.errorCode) &&
          profileResponse.errorCode === 0
        ) {
          this.showSuccess("Profile", CrudlStatus.CREATE, ViewState.CARD);
        } else {
          this.showError("Profile", CrudlStatus.CREATE, ViewState.CARD);
        }
      }
    });

    var matrixItemArray = [];

    ppeBeanList.forEach((e) => {
      if (e.checked) {
        var score = "";
        if (profileRequest.type === "Both") {
          score = "Yes, can buy & can supply";
        } else if (e.localType === "PPESupplier") {
          score = "Yes, can supply";
        } else {
          score = "Yes, can buy";
        }
        matrixItemArray.push({
          codeTag: profileRequest.codeTag,
          userId: profileRequest.userId,
          componentId: e.id,
          score: score,
        });
      }
    });

    let matrixItemRequest = new CloudService.MatrixItemRequest();
    matrixItemRequest.codeTag = localStorage.getItem("clientId");
    matrixItemRequest.userId = localStorage.getItem("userId");
    matrixItemRequest.matrixItemList = matrixItemArray;

    await api("matrixItemApiV1MatrixItemCreate", matrixItemRequest).then(
      (res) => {
        if (res.data) {
          let matrixItemResponse =
            CloudService.MatrixItemResponse.constructFromObject(res.data);
          console.log(matrixItemResponse);

          if (matrixItemResponse.errorCode === 0) {
          } else {
            return "error";
          }
        }
      }
    );
  };

  getMatrixItemsAndDelete = async () => {
    let matrixItemRequest = new CloudService.MatrixItemRequest();
    matrixItemRequest.codeTag = localStorage.getItem("clientId");
    matrixItemRequest.userId = localStorage.getItem("userId");

    var matrixList = [];
    await api("matrixItemApiV1MatrixItemGetByCodeTag", matrixItemRequest).then(
      (res) => {
        if (res.data) {
          let matrixItemResponse =
            CloudService.MatrixItemResponse.constructFromObject(res.data);
          console.log(matrixItemResponse);

          if (matrixItemResponse.errorCode === 0) {
            matrixList = matrixItemResponse.matrixItemList;
          } else {
            return "error";
          }
        }
      }
    );

    matrixItemRequest = new CloudService.MatrixItemRequest();
    matrixItemRequest.codeTag = localStorage.getItem("clientId");
    matrixItemRequest.userId = localStorage.getItem("userId");

    matrixItemRequest.matrixItemList = matrixList;
    console.log(matrixList);
    await api("matrixItemApiV1MatrixItemDelete", matrixItemRequest).then(
      (res) => {
        if (res.data) {
          let matrixItemResponse =
            CloudService.MatrixItemResponse.constructFromObject(res.data);
          console.log(matrixItemResponse);

          if (matrixItemResponse.errorCode === 0) {
          } else {
            return "error";
          }
        }
      }
    );
  };
}
