import { BaseViewModel } from "../../common/BaseViewModel";
import * as Format from "../../common/FormatFunctions";
import { CloudService } from "../../common/CloudService/ClientLibsReference";
import * as ApiService from "../../common/CloudService/ApiService";
import { CrudlStatus, ViewState } from "../../common/config/AppConstants";

export class ProfileViewModel extends BaseViewModel {
  getProfilesByType = async (type) => {
    //CRITICAL - ERROR + EMPTY MESSAGE
    let request = {};
    request.type = type;
    var profileResponse = await this.getProfileListByType(request);
    if (
      Format.isNotNull(profileResponse.errorCode) &&
      profileResponse.errorCode === 0
    ) {
      if (Format.isNotNull(profileResponse.profileList)) {
        let profileList = [];
        profileResponse.profileList.forEach((profile) => {
          if (profile.codeTag !== this.codeTag) {
            profileList.push(profile);
          }
        });
        this.updateState("profileList", profileList);
        return profileList;
      } else {
        this.showEmpty("Profile", ViewState.TABLE);
      }
    } else {
      this.showError("Profile", CrudlStatus.LIST, ViewState.TABLE);
    }
  };

  getProfilesByTypeAndComponentList = async (type, componentList) => {
    //CRITICAL - ERROR + EMPTY MESSAGE
    let request = {};
    request.type = type;
    request.componentList = componentList;
    var profileResponse = await this.getProfileListByTypeAndComponentList(
      request
    );
    if (
      Format.isNotNull(profileResponse.errorCode) &&
      profileResponse.errorCode === 0
    ) {
      if (Format.isNotNull(profileResponse.profileList)) {
        this.updateState("profileList", profileResponse.profileList);
        return profileResponse.profileList;
      } else {
        this.showEmpty("Profile", ViewState.TABLE);
      }
    } else {
      this.showError("Profile", CrudlStatus.LIST, ViewState.TABLE);
    }
  };

  getProfiles = async () => {
    //CRITICAL - ERROR + EMPTY MESSAGE
    let request = {};
    var profileResponse = await this.getProfileListByCodeTag(request);
    if (
      Format.isNotNull(profileResponse.errorCode) &&
      profileResponse.errorCode === 0
    ) {
      if (Format.isNotNull(profileResponse.profileList)) {
        this.updateState("profileList", profileResponse.profileList);
        return profileResponse.profileList;
      } else {
        this.showEmpty("Profile", ViewState.TABLE);
      }
    } else {
      this.showError("Profile", CrudlStatus.LIST, ViewState.TABLE);
    }
  };

  getAllProfiles = async () => {
    let request = {};
    request.codeTag = localStorage.getItem("globalBid");
    request.userId = this.userId;
    var profileResponse = await this.profileListAll(request);
    if (
      Format.isNotNull(profileResponse.errorCode) &&
      profileResponse.errorCode === 0
    ) {
      if (Format.isNotNull(profileResponse.profileList)) {
        this.updateState("profileList", profileResponse.profileList);
        return profileResponse.profileList;
      } else {
        this.showEmpty("Profile", ViewState.TABLE);
      }
    } else {
      this.showError("Profile", CrudlStatus.LIST, ViewState.TABLE);
    }
  };

  getProfilesReturned = async () => {
    //CRITICAL - ERROR + EMPTY MESSAGE
    let request = {};
    var profileResponse = await this.getProfileListByCodeTag(request);
    if (
      Format.isNotNull(profileResponse.errorCode) &&
      profileResponse.errorCode === 0
    ) {
      if (Format.isNotNull(profileResponse.profileList)) {
        this.logger.info(profileResponse.profileList);
        return profileResponse.profileList;
      } else {
        this.showEmpty("Profile", ViewState.TABLE);
      }
    } else {
      this.showError("Profile", CrudlStatus.LIST, ViewState.TABLE);
    }
  };

  reloadDataAfterExecute = (
    profiles,
    apiResponse,
    type,
    externalStateValues
  ) => {
    var updatedProfileList = [];
    if (type === CrudlStatus.CREATE) {
      updatedProfileList = this.reloadAfterCreate(
        profiles,
        apiResponse,
        externalStateValues
      );
    }
    if (type === CrudlStatus.UPDATE) {
      updatedProfileList = this.reloadAfterUpdate(
        profiles,
        apiResponse,
        externalStateValues
      );
    }
    if (type === CrudlStatus.DELETE) {
      updatedProfileList = this.reloadAfterDelete(
        profiles,
        apiResponse,
        externalStateValues
      );
    }

    this.updateState("profileList", updatedProfileList);
  };

  reloadAfterCreate = (profileList, apiResponse, externalStateValues) => {
    if (profileList === undefined || profileList === null) {
      profileList = [];
    }
    var profileBean = externalStateValues.profileBean;
    profileBean.id = apiResponse.id;
    profileBean.lastUpdatedDateTime = apiResponse.lastUpdatedDateTime;

    profileList.push(profileBean);

    return profileList;
  };

  reloadAfterUpdate = (profileList, apiResponse, externalStateValues) => {
    var i = 0;
    profileList.forEach((e) => {
      if (apiResponse.id === e.id) {
        profileList[i] = externalStateValues.profileBean;
        profileList[i].lastUpdatedDateTime = apiResponse.lastUpdatedDateTime;
      }
      i++;
    });

    return profileList;
  };

  reloadAfterDelete = (profileList, apiResponse, externalStateValues) => {
    var i = 0;
    profileList.forEach((e) => {
      //DELETE
      if (apiResponse.id === e.id) {
        profileList.splice(i, 1);
      }
      i++;
    });

    return profileList;
  };
  executeCRUDLAction = async (crudlStatus, stateVariables) => {
    let profileRequest = CloudService.ProfileRequest.constructFromObject(
      stateVariables.profileBean
    );
    if (crudlStatus === CrudlStatus.CREATE) {
      return await this.createProfile(profileRequest, stateVariables);
    }
    if (crudlStatus === CrudlStatus.UPDATE) {
      return await this.updateProfile(profileRequest, stateVariables);
    }
    if (crudlStatus === CrudlStatus.DELETE) {
      return await this.deleteProfile(profileRequest);
    }
  };

  createProfile = async (profileRequest, stateVariables) => {
    profileRequest.codeTag = this.codeTag;
    profileRequest.userId = this.userId;

    let profileResponse = await ApiService.profileCreate(profileRequest);
    if (profileResponse.errorCode === 0) {
      this.showSuccess("Profile", CrudlStatus.CREATE, stateVariables.viewState);
    } else {
      this.showError("Profile", CrudlStatus.CREATE, ViewState.CARD);
    }

    return profileResponse;
  };

  deleteProfile = async (profileRequest) => {
    //RESET PRODUCT REQUEST
    var request = {};
    request.codeTag = profileRequest.codeTag; // we dont want council BID to be overwritten with this.codeTag
    request.userId = profileRequest.userId;
    request.id = profileRequest.id;
    request.lastUpdatedDateTime = profileRequest.lastUpdatedDateTime;

    let profileResponse = await ApiService.profileDelete(request);
    if (profileResponse.errorCode === 0) {
      this.showSuccess("Profile", CrudlStatus.DELETE, ViewState.TABLE);
    } else {
      this.showError("Profile", CrudlStatus.DELETE, ViewState.CARD);
    }

    return profileResponse;
  };

  updateProfile = async (profileRequest, stateVariables) => {
    // profileRequest.codeTag = this.codeTag;
    // profileRequest.userId = this.userId;

    let profileResponse = await ApiService.profileUpdate(profileRequest);
    if (profileResponse.errorCode === 0) {
      this.showSuccess("Profile", CrudlStatus.UPDATE, stateVariables.viewState);
    } else {
      this.showError("Profile", CrudlStatus.UPDATE, ViewState.CARD);
    }

    return profileResponse;
  };

  getComponentMatrixItemsByCodeTag = async () => {
    var componentResponse = await this.componentGetMatrixItemsByCodeTag(
      localStorage.getItem("globalBid")
    );
    if (
      Format.isNotNull(componentResponse.errorCode) &&
      componentResponse.errorCode === 0
    ) {
      if (Format.isNotNull(componentResponse.componentList)) {
        this.updateState("componentList", componentResponse.componentList);
        return componentResponse.componentList;
      } else {
        this.logger.info("Component is empty");
      }
    } else {
      this.logger.info("Component api failed");
    }
  };

  getMyProfile = async () => {
    let request = {};
    request.codeTag = this.codeTag;
    var profileResponse = await this.profileGetByCodeTag(request);
    if (
      Format.isNotNull(profileResponse.errorCode) &&
      profileResponse.errorCode === 0
    ) {
      if (Format.isNotNull(profileResponse.profileList)) {
        return profileResponse.profileList[0];
      } else {
        this.logger.info("Profile is empty");
      }
    } else {
      this.logger.info("Profile api failed");
    }
  };
}
