/**
 * buysupply-production.appspot.com
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.1
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient', 'model/BusinessBean', 'model/IApplicationBean', 'model/IComponentBean', 'model/IProductBean'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('./BusinessBean'), require('./IApplicationBean'), require('./IComponentBean'), require('./IProductBean'));
  } else {
    // Browser globals (root is window)
    if (!root.BuysupplyProductionappspotcom) {
      root.BuysupplyProductionappspotcom = {};
    }
    root.BuysupplyProductionappspotcom.ApplicationResponse = factory(root.BuysupplyProductionappspotcom.ApiClient, root.BuysupplyProductionappspotcom.BusinessBean, root.BuysupplyProductionappspotcom.IApplicationBean, root.BuysupplyProductionappspotcom.IComponentBean, root.BuysupplyProductionappspotcom.IProductBean);
  }
}(this, function(ApiClient, BusinessBean, IApplicationBean, IComponentBean, IProductBean) {
  'use strict';




  /**
   * The ApplicationResponse model module.
   * @module model/ApplicationResponse
   * @version 1.0.0
   */

  /**
   * Constructs a new <code>ApplicationResponse</code>.
   * @alias module:model/ApplicationResponse
   * @class
   */
  var exports = function() {
    var _this = this;






































  };

  /**
   * Constructs a <code>ApplicationResponse</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ApplicationResponse} obj Optional instance to populate.
   * @return {module:model/ApplicationResponse} The populated <code>ApplicationResponse</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('applicationList')) {
        obj['applicationList'] = ApiClient.convertToType(data['applicationList'], [IApplicationBean]);
      }
      if (data.hasOwnProperty('applicationType')) {
        obj['applicationType'] = ApiClient.convertToType(data['applicationType'], 'String');
      }
      if (data.hasOwnProperty('archivedBuyer')) {
        obj['archivedBuyer'] = ApiClient.convertToType(data['archivedBuyer'], 'Boolean');
      }
      if (data.hasOwnProperty('archivedCouncil')) {
        obj['archivedCouncil'] = ApiClient.convertToType(data['archivedCouncil'], 'Boolean');
      }
      if (data.hasOwnProperty('archivedSupplier')) {
        obj['archivedSupplier'] = ApiClient.convertToType(data['archivedSupplier'], 'Boolean');
      }
      if (data.hasOwnProperty('businessNameBuyerId')) {
        obj['businessNameBuyerId'] = ApiClient.convertToType(data['businessNameBuyerId'], 'String');
      }
      if (data.hasOwnProperty('businessNameCodeTag')) {
        obj['businessNameCodeTag'] = ApiClient.convertToType(data['businessNameCodeTag'], 'String');
      }
      if (data.hasOwnProperty('buyerId')) {
        obj['buyerId'] = ApiClient.convertToType(data['buyerId'], 'String');
      }
      if (data.hasOwnProperty('codeTag')) {
        obj['codeTag'] = ApiClient.convertToType(data['codeTag'], 'String');
      }
      if (data.hasOwnProperty('companyDetails')) {
        obj['companyDetails'] = BusinessBean.constructFromObject(data['companyDetails']);
      }
      if (data.hasOwnProperty('componentDetailsList')) {
        obj['componentDetailsList'] = ApiClient.convertToType(data['componentDetailsList'], [IComponentBean]);
      }
      if (data.hasOwnProperty('componentList')) {
        obj['componentList'] = ApiClient.convertToType(data['componentList'], ['String']);
      }
      if (data.hasOwnProperty('dateCreated')) {
        obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
      }
      if (data.hasOwnProperty('dateCreatedToDisplayed')) {
        obj['dateCreatedToDisplayed'] = ApiClient.convertToType(data['dateCreatedToDisplayed'], 'String');
      }
      if (data.hasOwnProperty('dateUpdatedToDisplayed')) {
        obj['dateUpdatedToDisplayed'] = ApiClient.convertToType(data['dateUpdatedToDisplayed'], 'String');
      }
      if (data.hasOwnProperty('errorCode')) {
        obj['errorCode'] = ApiClient.convertToType(data['errorCode'], 'Number');
      }
      if (data.hasOwnProperty('feedback')) {
        obj['feedback'] = ApiClient.convertToType(data['feedback'], 'String');
      }
      if (data.hasOwnProperty('feedbackAttemptCount')) {
        obj['feedbackAttemptCount'] = ApiClient.convertToType(data['feedbackAttemptCount'], 'String');
      }
      if (data.hasOwnProperty('feedbackAttemptMax')) {
        obj['feedbackAttemptMax'] = ApiClient.convertToType(data['feedbackAttemptMax'], 'String');
      }
      if (data.hasOwnProperty('feedbackType')) {
        obj['feedbackType'] = ApiClient.convertToType(data['feedbackType'], 'String');
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('imageUriList')) {
        obj['imageUriList'] = ApiClient.convertToType(data['imageUriList'], ['String']);
      }
      if (data.hasOwnProperty('lastFeedbackRequestDate')) {
        obj['lastFeedbackRequestDate'] = ApiClient.convertToType(data['lastFeedbackRequestDate'], 'Date');
      }
      if (data.hasOwnProperty('lastUpdatedDateTime')) {
        obj['lastUpdatedDateTime'] = ApiClient.convertToType(data['lastUpdatedDateTime'], 'Date');
      }
      if (data.hasOwnProperty('longDescription')) {
        obj['longDescription'] = ApiClient.convertToType(data['longDescription'], 'String');
      }
      if (data.hasOwnProperty('longDescriptionText')) {
        obj['longDescriptionText'] = ApiClient.convertToType(data['longDescriptionText'], 'String');
      }
      if (data.hasOwnProperty('pdfList')) {
        obj['pdfList'] = ApiClient.convertToType(data['pdfList'], ['String']);
      }
      if (data.hasOwnProperty('processType')) {
        obj['processType'] = ApiClient.convertToType(data['processType'], 'String');
      }
      if (data.hasOwnProperty('productCategory')) {
        obj['productCategory'] = ApiClient.convertToType(data['productCategory'], 'String');
      }
      if (data.hasOwnProperty('productDetailsList')) {
        obj['productDetailsList'] = ApiClient.convertToType(data['productDetailsList'], [IProductBean]);
      }
      if (data.hasOwnProperty('productList')) {
        obj['productList'] = ApiClient.convertToType(data['productList'], ['String']);
      }
      if (data.hasOwnProperty('productName')) {
        obj['productName'] = ApiClient.convertToType(data['productName'], 'String');
      }
      if (data.hasOwnProperty('responseMsg')) {
        obj['responseMsg'] = ApiClient.convertToType(data['responseMsg'], 'String');
      }
      if (data.hasOwnProperty('shortDescription')) {
        obj['shortDescription'] = ApiClient.convertToType(data['shortDescription'], 'String');
      }
      if (data.hasOwnProperty('status')) {
        obj['status'] = ApiClient.convertToType(data['status'], 'String');
      }
      if (data.hasOwnProperty('title')) {
        obj['title'] = ApiClient.convertToType(data['title'], 'String');
      }
      if (data.hasOwnProperty('userId')) {
        obj['userId'] = ApiClient.convertToType(data['userId'], 'String');
      }
    }
    return obj;
  }

  /**
   * @member {Array.<module:model/IApplicationBean>} applicationList
   */
  exports.prototype['applicationList'] = undefined;
  /**
   * @member {String} applicationType
   */
  exports.prototype['applicationType'] = undefined;
  /**
   * @member {Boolean} archivedBuyer
   */
  exports.prototype['archivedBuyer'] = undefined;
  /**
   * @member {Boolean} archivedCouncil
   */
  exports.prototype['archivedCouncil'] = undefined;
  /**
   * @member {Boolean} archivedSupplier
   */
  exports.prototype['archivedSupplier'] = undefined;
  /**
   * @member {String} businessNameBuyerId
   */
  exports.prototype['businessNameBuyerId'] = undefined;
  /**
   * @member {String} businessNameCodeTag
   */
  exports.prototype['businessNameCodeTag'] = undefined;
  /**
   * @member {String} buyerId
   */
  exports.prototype['buyerId'] = undefined;
  /**
   * @member {String} codeTag
   */
  exports.prototype['codeTag'] = undefined;
  /**
   * @member {module:model/BusinessBean} companyDetails
   */
  exports.prototype['companyDetails'] = undefined;
  /**
   * @member {Array.<module:model/IComponentBean>} componentDetailsList
   */
  exports.prototype['componentDetailsList'] = undefined;
  /**
   * @member {Array.<String>} componentList
   */
  exports.prototype['componentList'] = undefined;
  /**
   * @member {Date} dateCreated
   */
  exports.prototype['dateCreated'] = undefined;
  /**
   * @member {String} dateCreatedToDisplayed
   */
  exports.prototype['dateCreatedToDisplayed'] = undefined;
  /**
   * @member {String} dateUpdatedToDisplayed
   */
  exports.prototype['dateUpdatedToDisplayed'] = undefined;
  /**
   * @member {Number} errorCode
   */
  exports.prototype['errorCode'] = undefined;
  /**
   * @member {String} feedback
   */
  exports.prototype['feedback'] = undefined;
  /**
   * @member {String} feedbackAttemptCount
   */
  exports.prototype['feedbackAttemptCount'] = undefined;
  /**
   * @member {String} feedbackAttemptMax
   */
  exports.prototype['feedbackAttemptMax'] = undefined;
  /**
   * @member {String} feedbackType
   */
  exports.prototype['feedbackType'] = undefined;
  /**
   * @member {String} id
   */
  exports.prototype['id'] = undefined;
  /**
   * @member {Array.<String>} imageUriList
   */
  exports.prototype['imageUriList'] = undefined;
  /**
   * @member {Date} lastFeedbackRequestDate
   */
  exports.prototype['lastFeedbackRequestDate'] = undefined;
  /**
   * @member {Date} lastUpdatedDateTime
   */
  exports.prototype['lastUpdatedDateTime'] = undefined;
  /**
   * @member {String} longDescription
   */
  exports.prototype['longDescription'] = undefined;
  /**
   * @member {String} longDescriptionText
   */
  exports.prototype['longDescriptionText'] = undefined;
  /**
   * @member {Array.<String>} pdfList
   */
  exports.prototype['pdfList'] = undefined;
  /**
   * @member {String} processType
   */
  exports.prototype['processType'] = undefined;
  /**
   * @member {String} productCategory
   */
  exports.prototype['productCategory'] = undefined;
  /**
   * @member {Array.<module:model/IProductBean>} productDetailsList
   */
  exports.prototype['productDetailsList'] = undefined;
  /**
   * @member {Array.<String>} productList
   */
  exports.prototype['productList'] = undefined;
  /**
   * @member {String} productName
   */
  exports.prototype['productName'] = undefined;
  /**
   * @member {String} responseMsg
   */
  exports.prototype['responseMsg'] = undefined;
  /**
   * @member {String} shortDescription
   */
  exports.prototype['shortDescription'] = undefined;
  /**
   * @member {String} status
   */
  exports.prototype['status'] = undefined;
  /**
   * @member {String} title
   */
  exports.prototype['title'] = undefined;
  /**
   * @member {String} userId
   */
  exports.prototype['userId'] = undefined;



  return exports;
}));


