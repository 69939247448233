import React from "react";
//PrimeReact Components
import * as pr from "../../../common/PrimeComponents";
import {
  CrudlStatus,
  FeedbackState,
  ViewType,
  BusinessType,
} from "../../../common/config/AppConstants";
import {
  BUSINESS_SIZE_OPTIONS,
  CountyOptions,
  DemographicAreaOptions,
  DiscoveredByOptions,
} from "../../../common/config/EnumDropdowns";
import { LoadScreen } from "../../Common/LoadScreen";
import { SingleFileUploader } from "../../Common/SingleFileUploader";
import {
  Input,
  InputType,
  LabelPosition,
  UpdateType,
} from "../../Common/Input";
import { UserProvider } from "../../User/UserProvider";
import { CategoryProvider } from "../../Category/CategoryProvider";
import { ProfileItemProvider } from "../../ProfileItem/ProfileItemProvider";

export class BusinessCard extends React.Component {
  componentDidUpdate = (prevProps) => {
    // Typical usage (don't forget to compare props):
    if (this.props.receivedFeedback !== prevProps.receivedFeedback) {
      switch (this.props.receivedFeedback.state) {
        case FeedbackState.EMPTY:
          this.props.feedback.showEmpty(
            this.growl,
            this.props.receivedFeedback.display
          );
          break;
        case FeedbackState.SUCCESS:
          this.props.feedback.showSuccess(
            this.growl,
            this.props.receivedFeedback.display,
            this.props.receivedFeedback.crudlStatus
          );
          break;
        case FeedbackState.ERROR:
          this.props.feedback.showError(
            this.growl,
            this.props.receivedFeedback.display,
            this.props.receivedFeedback.crudlStatus
          );
          break;
        default:
          break;
      }
    }
  };

  tutorialVideo = (title, source) => {
    return (
      <pr.Dialog
        header={title}
        footer={
          <pr.Button
            className="secondary-btn"
            label="Close"
            icon="pi pi-times"
            onClick={() => {
              this.props.updateState("showTutorial", false);
            }}
            style={{
              float: "right",
            }}
          />
        }
        visible={true}
        onHide={() => {
          this.props.updateState("showTutorial", false);
        }}
        modal={true}
        blockScroll={true}
        style={{ width: "80vw" }}
      >
        {/* <video width="100%" height="98%" controls>
          <source src={source} type="video/webm" />
          Your browser does not support video elements, please update to the
          latest version
        </video> */}
        <div>
          <center>
            <iframe
              src={source}
              allowFullScreen
              style={{
                position: "relative",
                top: 0,
                left: 0,
              }}
            ></iframe>
          </center>
        </div>
      </pr.Dialog>
    );
  };

  displayCard = () => {
    let classname = "";
    if (this.props.viewType === ViewType.VIEW_BUSINESS) {
      classname = "scrl_full";
    }
    return (
      <React.Fragment>
        <div className={classname}>
          <div className="p-grid">
            <div className="p-col-12">
              {this.props.viewType === ViewType.MY_BUSINESS && (
                <pr.Button
                  icon="pi pi-info"
                  onClick={() => {
                    this.props.updateState("showTutorial", true);
                  }}
                  style={{ fontSize: "10px", float: "right" }}
                />
              )}
            </div>
            {this.props.loading === true && <LoadScreen />}
            {this.props.loading !== true && (
              <pr.TabView style={{ width: "100%" }}>
                <pr.TabPanel header="Business Details">
                  <div className="p-grid card">
                    {this.props.showTutorial &&
                      this.props.viewType === ViewType.MY_BUSINESS &&
                      this.tutorialVideo(
                        "My Business Tutorial",
                        // "/assets/layout/images/council/business-details.webm"
                        "https://player.vimeo.com/video/582861891?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                      )}

                    <div className="p-col-6 p-lg-6 p-md-12 p-sm-12">
                      <Input
                        id={"businessName"}
                        labelDisplay={"Business Name"}
                        labelDisplayPosition={LabelPosition.LEFT}
                        required={false}
                        type={InputType.STRING}
                        disabled={true}
                        value={this.props.editedObject.businessName}
                        onChangeType={UpdateType.STANDARD}
                        onChange={this.props.updateValue}
                        field={"businessName"}
                        visible={true}
                      />
                    </div>
                    <div className="p-col-6 p-lg-6 p-md-12 p-sm-12">
                      <Input
                        id={"addressLine1"}
                        labelDisplay={"Address Line 1"}
                        labelDisplayPosition={LabelPosition.LEFT}
                        required={true}
                        type={InputType.STRING}
                        disabled={this.props.crudlState === CrudlStatus.VIEW}
                        value={this.props.editedObject.addressLine1}
                        onChangeType={UpdateType.STANDARD}
                        onChange={this.props.updateValue}
                        field={"addressLine1"}
                        visible={true}
                      />
                    </div>
                    <div className="p-col-6 p-lg-6 p-md-12 p-sm-12">
                      <Input
                        id={"addressLine2"}
                        labelDisplay={"Address Line 2"}
                        labelDisplayPosition={LabelPosition.LEFT}
                        required={true}
                        type={InputType.STRING}
                        disabled={this.props.crudlState === CrudlStatus.VIEW}
                        value={this.props.editedObject.addressLine2}
                        onChangeType={UpdateType.STANDARD}
                        onChange={this.props.updateValue}
                        field={"addressLine2"}
                        visible={true}
                      />
                    </div>
                    <div className="p-col-6 p-lg-6 p-md-12 p-sm-12">
                      <Input
                        id={"city"}
                        labelDisplay={"City"}
                        labelDisplayPosition={LabelPosition.LEFT}
                        required={true}
                        type={InputType.STRING}
                        disabled={this.props.crudlState === CrudlStatus.VIEW}
                        value={this.props.editedObject.city}
                        onChangeType={UpdateType.STANDARD}
                        onChange={this.props.updateValue}
                        field={"city"}
                        visible={true}
                      />
                    </div>
                    <div className="p-col-6 p-lg-6 p-md-12 p-sm-12">
                      <Input
                        id={"postalZipCode"}
                        labelDisplay={"Postcode"}
                        labelDisplayPosition={LabelPosition.LEFT}
                        required={true}
                        type={InputType.STRING}
                        disabled={this.props.crudlState === CrudlStatus.VIEW}
                        value={this.props.editedObject.postalZipCode}
                        onChangeType={UpdateType.STANDARD}
                        onChange={this.props.updateValue}
                        field={"postalZipCode"}
                        visible={true}
                      />
                    </div>
                    <div className="p-col-6 p-lg-6 p-md-12 p-sm-12">
                      <Input
                        id={"countyState"}
                        labelDisplay={"County"}
                        labelDisplayPosition={LabelPosition.LEFT}
                        required={true}
                        type={InputType.DROPDOWN}
                        dropdownOptions={CountyOptions}
                        disabled={this.props.crudlState === CrudlStatus.VIEW}
                        value={this.props.editedObject.countyState}
                        onChangeType={UpdateType.STANDARD}
                        onChange={this.props.updateValue}
                        field={"countyState"}
                        visible={true}
                      />
                    </div>
                    <div className="p-col-6 p-lg-6 p-md-12 p-sm-12">
                      <Input
                        id={"email"}
                        labelDisplay={"Email"}
                        labelDisplayPosition={LabelPosition.LEFT}
                        required={true}
                        type={InputType.STRING}
                        disabled={this.props.crudlState === CrudlStatus.VIEW}
                        value={this.props.editedObject.email}
                        onChangeType={UpdateType.STANDARD}
                        onChange={this.props.updateValue}
                        field={"email"}
                        visible={true}
                      />
                    </div>
                    <div className="p-col-6 p-lg-6 p-md-12 p-sm-12">
                      <Input
                        id={"telephoneNumber"}
                        labelDisplay={"Telephone Number"}
                        labelDisplayPosition={LabelPosition.LEFT}
                        required={true}
                        type={InputType.STRING}
                        disabled={this.props.crudlState === CrudlStatus.VIEW}
                        value={this.props.editedObject.telephoneNumber}
                        onChangeType={UpdateType.STANDARD}
                        onChange={this.props.updateValue}
                        field={"telephoneNumber"}
                        visible={true}
                      />
                    </div>
                    <div className="p-col-12">
                      <Input
                        id={"demographicArea"}
                        labelDisplay={"Demographic Area"}
                        labelDisplayPosition={LabelPosition.LEFT}
                        required={false}
                        type={InputType.DROPDOWN}
                        dropdownOptions={DemographicAreaOptions}
                        disabled={this.props.crudlState === CrudlStatus.VIEW}
                        value={this.props.editedObject.demographicArea}
                        onChangeType={UpdateType.STANDARD}
                        onChange={this.props.updateValue}
                        field={"demographicArea"}
                        visible={true}
                      />
                    </div>
                    <div className="p-col-12">
                      <Input
                        id={"subType"}
                        labelDisplay={"Business Industry *"}
                        placeholder={" "}
                        labelDisplayPosition={LabelPosition.LEFT}
                        required={false}
                        type={InputType.DROPDOWN}
                        dropdownOptions={this.props.businessTypeOptions}
                        disabled={this.props.crudlState === CrudlStatus.VIEW}
                        value={this.props.editedObject.subType}
                        onChangeType={UpdateType.STANDARD}
                        onChange={this.props.updateValue}
                        field={"subType"}
                        visible={true}
                      />
                    </div>
                    <div className="p-col-12">
                      <Input
                        id={"businessSize"}
                        labelDisplay={"Business Size"}
                        placeholder={" "}
                        labelDisplayPosition={LabelPosition.LEFT}
                        required={false}
                        type={InputType.DROPDOWN}
                        dropdownOptions={BUSINESS_SIZE_OPTIONS}
                        disabled={this.props.crudlState === CrudlStatus.VIEW}
                        value={this.props.editedObject.businessSize}
                        onChangeType={UpdateType.STANDARD}
                        onChange={this.props.updateValue}
                        field={"businessSize"}
                        visible={true}
                      />
                    </div>
                    <div className="p-col-12">
                      <div className="p-grid card" id="type">
                        <div className="p-col-12">
                          <div className="p-grid" id="type">
                            <div className="p-col-6">
                              <label className="input-label">
                                {"Business Type *"}
                              </label>
                            </div>
                            <div className="p-col-6">
                              {this.props.viewType === ViewType.MY_BUSINESS && (
                                <pr.Button
                                  label="i"
                                  tooltip={
                                    "If you change your Business Type, you will have to logout and login for changes to take effect"
                                  }
                                  tooltipOptions={{
                                    position: "left",
                                  }}
                                  style={{
                                    borderRadius: "25px",
                                    float: "right",
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="p-col-6">
                          {" "}
                          <Input
                            id={"typeBuyer"}
                            labelDisplay={"Buyer"}
                            labelDisplayPosition={LabelPosition.LEFT}
                            required={false}
                            type={InputType.CHECKBOX}
                            disabled={
                              this.props.crudlState === CrudlStatus.VIEW
                            }
                            value={this.props.editedObject.typeBuyer}
                            onChangeType={UpdateType.CUSTOM}
                            onChange={(e) => {
                              this.props.updateBusinessType(
                                "typeBuyer",
                                e.target.checked
                              );
                            }}
                            field={"typeBuyer"}
                            visible={true}
                          />
                        </div>
                        <div className="p-col-6">
                          <Input
                            id={"typeSupplier"}
                            labelDisplay={"Supplier"}
                            labelDisplayPosition={LabelPosition.LEFT}
                            required={false}
                            type={InputType.CHECKBOX}
                            disabled={
                              this.props.crudlState === CrudlStatus.VIEW
                            }
                            value={this.props.editedObject.typeSupplier}
                            onChangeType={UpdateType.CUSTOM}
                            onChange={(e) => {
                              this.props.updateBusinessType(
                                "typeSupplier",
                                e.target.checked
                              );
                            }}
                            field={"typeSupplier"}
                            visible={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="p-col-12">
                      <Input
                        id={"businessDescription"}
                        labelDisplay={"Business Description"}
                        labelDisplayPosition={LabelPosition.LEFT}
                        required={false}
                        type={InputType.TEXT}
                        disabled={this.props.crudlState === CrudlStatus.VIEW}
                        value={this.props.editedObject.businessDescription}
                        onChangeType={UpdateType.STANDARD}
                        onChange={this.props.updateValue}
                        field={"businessDescription"}
                        visible={true}
                      />
                    </div>

                    <div className="p-col-12">
                      {this.props.crudlState !== CrudlStatus.VIEW ? (
                        <pr.Button
                          className="mrgn flt-r"
                          disabled={this.props.checkRequiredFields()}
                          label="Save Business Details"
                          icon={
                            this.props.loading
                              ? "pi-spin pi-md-autorenew"
                              : "pi-md-save"
                          }
                          onClick={() => {
                            this.props.crudlExecute(true);
                          }}
                        />
                      ) : (
                        <label />
                      )}
                    </div>
                  </div>
                </pr.TabPanel>
                <pr.TabPanel header="Categories">
                  <div className="p-col-12 card">
                    {this.props.showTutorial &&
                      this.props.viewType === ViewType.MY_BUSINESS &&
                      this.tutorialVideo(
                        "My Business Tutorial",
                        // "/assets/layout/images/council/business-categories.webm"
                        "https://player.vimeo.com/video/582850588?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                      )}
                    <CategoryProvider
                      viewType={ViewType.MY_BUSINESS}
                      codeTag={this.props.editedObject.codeTag}
                      editedBusiness={this.props.editedObject}
                      crudlState={this.props.crudlState}
                    />
                  </div>
                </pr.TabPanel>

                <pr.TabPanel
                  header="Form"
                  headerStyle={
                    this.props.editedObject.type === BusinessType.SUPPLIER ||
                    this.props.editedObject.type === BusinessType.BUYERSUPPLIER
                      ? {}
                      : { display: "none" }
                  }
                >
                  <div className="p-col-12 card">
                    {this.props.showTutorial &&
                      this.props.viewType === ViewType.MY_BUSINESS &&
                      this.tutorialVideo(
                        "My Business Tutorial",
                        // "/assets/layout/images/council/business-form.webm"
                        "https://player.vimeo.com/video/582850645?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                      )}
                    <ProfileItemProvider
                      viewType={ViewType.MY_BUSINESS}
                      profile={this.props.profileObj}
                      crudlState={this.props.crudlState}
                      // crudlState={CrudlStatus.VIEW}
                    />
                  </div>
                </pr.TabPanel>

                <pr.TabPanel
                  header="Users"
                  id="UsersTabPanel"
                  headerStyle={
                    this.props.crudlState !== CrudlStatus.VIEW
                      ? {}
                      : { display: "none" }
                  }
                >
                  <div className="p-col-12 card">
                    {this.props.showTutorial &&
                      this.props.viewType === ViewType.MY_BUSINESS &&
                      this.tutorialVideo(
                        "My Business Tutorial",
                        // "/assets/layout/images/council/business-users.webm"
                        "https://player.vimeo.com/video/582850675?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                      )}
                    <UserProvider
                      viewType={ViewType.MY_BUSINESS}
                      codeTag={this.props.editedObject.codeTag}
                      crudlState={
                        this.props.crudlState === CrudlStatus.VIEW
                          ? this.props.crudlState
                          : null
                      }
                    />
                  </div>
                </pr.TabPanel>
              </pr.TabView>
            )}
          </div>
        </div>
        {this.props.viewType !== ViewType.MY_BUSINESS &&
        this.props.viewType !== ViewType.VIEW_BUSINESS ? (
          <div className="sidebar-footer">
            {this.props.viewType !== ViewType.MY_BUSINESS &&
            this.props.viewType !== ViewType.VIEW_BUSINESS ? (
              <pr.Button
                className="mrgn flt-l"
                label="Cancel"
                icon="pi-md-close"
                onClick={() => {
                  this.props.validateChanges();
                }}
              />
            ) : (
              <></>
            )}
            {this.props.crudlState !== CrudlStatus.CREATE &&
            this.props.crudlState !== CrudlStatus.VIEW &&
            this.props.viewType !== ViewType.MY_BUSINESS &&
            this.props.viewType !== ViewType.VIEW_BUSINESS ? (
              <pr.Button
                className="mrgn flt-r"
                label="Delete"
                icon={
                  this.props.loading
                    ? "pi-spin pi-md-autorenew"
                    : "pi-md-delete"
                }
                onClick={() => {
                  this.props.crudlControl(
                    CrudlStatus.DELETE,
                    this.props.editedObject
                  );
                }}
              />
            ) : (
              <label />
            )}
            {this.props.crudlState !== CrudlStatus.VIEW ? (
              <pr.Button
                className="mrgn flt-r"
                disabled={this.props.checkRequiredFields()}
                label="Save"
                icon={
                  this.props.loading ? "pi-spin pi-md-autorenew" : "pi-md-save"
                }
                onClick={() => {
                  this.props.crudlExecute(true);
                }}
              />
            ) : (
              <label />
            )}
            {this.props.crudlState !== CrudlStatus.VIEW &&
            this.props.viewType !== ViewType.MY_BUSINESS &&
            this.props.viewType !== ViewType.VIEW_BUSINESS ? (
              <pr.Button
                className="mrgn flt-r"
                disabled={this.props.checkRequiredFields()}
                label="Save and Close"
                icon={
                  this.props.loading
                    ? "pi-spin pi-md-autorenew"
                    : "pi-md-tab-unselected"
                }
                onClick={() => {
                  this.props.crudlExecute(false);
                }}
              />
            ) : (
              <label />
            )}
            {this.props.crudlState === CrudlStatus.VIEW &&
            this.props.viewType !== ViewType.MY_BUSINESS &&
            this.props.viewType !== ViewType.VIEW_BUSINESS ? (
              <pr.Button
                className="mrgn flt-r"
                label="Edit"
                icon={
                  this.props.loading
                    ? "pi-spin pi-md-autorenew"
                    : "pi pi-md-edit"
                }
                onClick={() => {
                  this.props.crudlControl(
                    CrudlStatus.UPDATE,
                    this.props.editedObject
                  );
                }}
              />
            ) : (
              <label />
            )}
          </div>
        ) : (
          <></>
        )}
      </React.Fragment>
    );
  };

  displayInputText = (target, label, validateEmail, validateBusiness) => {
    return (
      <div className="p-col-12">
        <span className="md-inputfield form-field">
          <pr.InputText
            id={target}
            className="required"
            value={this.props.editedObject[target] || ""}
            onChange={(e) => {
              this.props.updateValue(target, e.target.value);
            }}
            onBlur={() => {
              if (validateBusiness) {
                this.props.checkBusinessAlreadyExists();
              }
            }}
          />
          <label htmlFor={target} className="input-label">
            {label}
          </label>
        </span>

        {validateEmail && (
          <>
            {this.props.editedObject[target] &&
            (!this.props.editedObject[target].includes("@") ||
              !this.props.editedObject[target].includes(".")) ? (
              <label style={{ color: "red" }}>Incorrect email format</label>
            ) : (
              <label />
            )}
          </>
        )}
        {validateBusiness && this.props.editedObject.businessAlreadyExists ? (
          <div>
            <label style={{ color: "red" }}>Business already exists</label>
          </div>
        ) : (
          <label />
        )}
      </div>
    );
  };

  displayInputTextarea = (target, label, maxCharacterLength) => {
    return (
      <div className="p-col-12">
        <label htmlFor={target} className="custom-label">
          {label}
        </label>
        <pr.InputTextarea
          id={target}
          rows={5}
          value={this.props.editedObject[target] || ""}
          onChange={(e) => {
            this.props.updateValue(target, e.target.value);
          }}
          style={{ resize: "none" }}
          maxLength={maxCharacterLength}
        />
      </div>
    );
  };

  displayDropdown = (target, label, options) => {
    return (
      <div className="p-col-12">
        <span className="md-inputfield form-field">
          <pr.Dropdown
            id={target}
            className="required"
            value={this.props.editedObject[target]}
            options={options}
            onChange={(e) => {
              this.props.updateValue(target, e.value);
            }}
            filter={false}
            filterBy="label"
            filterPlaceholder="Search"
            style={{ marginTop: "1px", width: "100%" }}
          />
          <label htmlFor={target} className="input-label">
            {label}
          </label>
        </span>
      </div>
    );
  };

  onUpload = (value) => {
    this.props.updateValue("businessLogo", value);
  };

  displayRegister = () => {
    return (
      <div className="p-grid dashboard">
        <div className="p-col-12">
          <div className="p-grid card">
            <div className="p-col-12">
              <label className="custom-label">Business Logo</label>
            </div>
            <div className="p-col-4 ui-md-4">{/*Empty div for spacing*/}</div>
            <div className="p-col-4 ui-md-4">
              {this.props.editedObject.businessLogo ? (
                <img
                  src={this.props.editedObject.businessLogo}
                  alt=""
                  style={{ width: "100%", height: "14em" }}
                />
              ) : (
                <img
                  // src={require("../../../../public/assets/layout/images/council/no-image-placeholder.png")}
                  src={"assets/layout/images/council/no-image-placeholder.png"}
                  alt=""
                  style={{
                    width: "100%",
                    height: "14em",
                    opacity: "0.6",
                  }}
                />
              )}
            </div>
            <div className="p-col-4 p-md-4">{/*Empty div for spacing*/}</div>
            <div className="p-col-12">
              <div className="p-grid">
                <div className="p-col-6">
                  <SingleFileUploader
                    value={this.props.editedObject.businessLogo || ""}
                    updateValue={this.onUpload}
                    accept="image/*"
                    style={{ float: "left" }}
                    chooseLabel="Upload Image"
                  />
                </div>
                <div className="p-col-6">
                  {" "}
                  <pr.Button
                    label="Clear Image"
                    icon="pi pi-minus"
                    onClick={() => {
                      this.props.updateValue("businessLogo", "");
                    }}
                    style={{ float: "right" }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="p-grid">
            <div className="p-col-6">
              {this.displayInputText(
                "businessName",
                "Business Name *",
                false,
                true
              )}

              {this.displayInputText("addressLine1", "AddressLine1")}
              {this.displayInputText("addressLine2", "Address Line 2")}
              {this.displayInputText("city", "City *")}
              {this.displayInputText("postalZipCode", "Postcode *")}
              {this.displayDropdown("countyState", "County *", CountyOptions)}
              {this.displayDropdown(
                "demographicArea",
                "Demographic Area *",
                DemographicAreaOptions
              )}
              {this.displayDropdown(
                "businessSize",
                "Business Size",
                BUSINESS_SIZE_OPTIONS
              )}

              <div className="p-col-12">
                <label
                  className="input-label"
                  style={{ fontSize: "12px", color: "#00ac86" }}
                >
                  {"Business Type *"}
                </label>
                <div className="p-grid" id="type">
                  <div className="p-col-6">
                    {" "}
                    <Input
                      id={"typeBuyer"}
                      labelDisplay={"Buyer"}
                      labelDisplayPosition={LabelPosition.LEFT}
                      required={false}
                      type={InputType.CHECKBOX}
                      disabled={this.props.crudlState === CrudlStatus.VIEW}
                      value={this.props.editedObject.typeBuyer}
                      onChangeType={UpdateType.CUSTOM}
                      onChange={(e) => {
                        this.props.updateBusinessType(
                          "typeBuyer",
                          e.target.checked
                        );
                      }}
                      field={"typeBuyer"}
                      visible={true}
                    />
                  </div>
                  <div className="p-col-6">
                    <Input
                      id={"typeSupplier"}
                      labelDisplay={"Supplier"}
                      labelDisplayPosition={LabelPosition.LEFT}
                      required={false}
                      type={InputType.CHECKBOX}
                      disabled={this.props.crudlState === CrudlStatus.VIEW}
                      value={this.props.editedObject.typeSupplier}
                      onChangeType={UpdateType.CUSTOM}
                      onChange={(e) => {
                        this.props.updateBusinessType(
                          "typeSupplier",
                          e.target.checked
                        );
                      }}
                      field={"typeSupplier"}
                      visible={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="p-col-6">
              {this.displayInputTextarea(
                "businessDescription",
                "Business Description (Character Limit: 1500)",
                1500
              )}

              {this.displayDropdown(
                "subType",
                "Business Industry *",
                this.props.businessTypeOptions
              )}

              {this.displayInputText("email", "Email *", true)}
              {this.displayInputText("telephoneNumber", "Telephone Number *")}
              {this.displayDropdown(
                "discoveredVia",
                "Where did you find out about BuySupplyNI? *",
                DiscoveredByOptions
              )}
              {this.displayInputTextarea(
                "discoveredViaDescription",
                "Additional Info  (Character Limit: 1500)",
                1500
              )}
            </div>
          </div>

          <div className="p-col-12">
            <pr.Button
              className="flt-r"
              disabled={
                !this.props.editedObject.businessName ||
                !this.props.editedObject.city ||
                !this.props.editedObject.countyState ||
                !this.props.editedObject.demographicArea ||
                !this.props.editedObject.postalZipCode ||
                !this.props.editedObject.email ||
                !this.props.editedObject.email.includes("@") ||
                !this.props.editedObject.email.includes(".") ||
                !this.props.editedObject.telephoneNumber ||
                !this.props.editedObject.discoveredVia ||
                this.props.editedObject.businessAlreadyExists ||
                !this.props.editedObject.type
              }
              label="Next"
              icon={"pi pi-arrow-right"}
              onClick={() => {
                this.props.updateState("index", 1);
              }}
            />
            <pr.Button
              className="flt-l"
              label="Cancel"
              icon="pi pi-times"
              onClick={() => {
                window.history.back();
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <pr.Messages
          style={{ textAlign: "left" }}
          ref={(el) => (this.growl = el)}
        />
        {this.props.viewType === ViewType.REGISTER
          ? this.displayRegister()
          : this.displayCard()}
      </React.Fragment>
    );
  }
}
