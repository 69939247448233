import { BaseViewModel } from "../../common/BaseViewModel";
import * as Format from "../../common/FormatFunctions";
import { CloudService } from "../../common/CloudService/ClientLibsReference";
import * as ApiService from "../../common/CloudService/ApiService";
import { CrudlStatus, ViewState } from "../../common/config/AppConstants";
export class UserViewModel extends BaseViewModel {
  getUsers = async (codeTag) => {
    var userResponse = await this.getUserListByCodeTag(codeTag);
    if (
      Format.isNotNull(userResponse.errorCode) &&
      userResponse.errorCode === 0
    ) {
      if (Format.isNotNull(userResponse.users)) {
        this.updateState("userList", userResponse.users);
        this.logger.info(userResponse.users);
      } else {
        this.showEmpty("User", ViewState.TABLE);
      }
    } else {
      this.showError("User", CrudlStatus.LIST, ViewState.TABLE);
    }
  };

  getUsersReturned = async (codeTag) => {
    var userResponse = await this.getUserListByCodeTag(codeTag);
    if (
      Format.isNotNull(userResponse.errorCode) &&
      userResponse.errorCode === 0
    ) {
      if (Format.isNotNull(userResponse.users)) {
        this.logger.info(userResponse.users);
        return userResponse.users;
      } else {
        this.showEmpty("User", ViewState.TABLE);
      }
    } else {
      this.showError("User", CrudlStatus.LIST, ViewState.TABLE);
    }
  };

  reloadDataAfterExecute = (users, apiResponse, type, externalStateValues) => {
    var updatedUserList = [];
    if (type === CrudlStatus.CREATE) {
      updatedUserList = this.reloadAfterCreate(
        users,
        apiResponse,
        externalStateValues
      );
    }
    if (type === CrudlStatus.UPDATE) {
      updatedUserList = this.reloadAfterUpdate(
        users,
        apiResponse,
        externalStateValues
      );
    }
    if (type === CrudlStatus.DELETE) {
      updatedUserList = this.reloadAfterDelete(
        users,
        apiResponse,
        externalStateValues
      );
    }

    this.updateState("userList", updatedUserList);
  };

  reloadAfterCreate = (userList, apiResponse, externalStateValues) => {
    if (userList === undefined || userList === null) {
      userList = [];
    }
    var userBean = externalStateValues.userBean;
    userBean.id = apiResponse.id;
    userBean.lastUpdatedDateTime = apiResponse.lastUpdatedDateTime;

    userList.push(userBean);

    return userList;
  };

  reloadAfterUpdate = (userList, apiResponse, externalStateValues) => {
    var i = 0;
    userList.forEach((e) => {
      if (apiResponse.id === e.id) {
        userList[i] = externalStateValues.userBean;
        userList[i].lastUpdatedDateTime = apiResponse.lastUpdatedDateTime;
      }
      i++;
    });

    return userList;
  };

  reloadAfterDelete = (userList, apiResponse, externalStateValues) => {
    var i = 0;
    userList.forEach((e) => {
      //DELETE
      if (apiResponse.id === e.id) {
        userList.splice(i, 1);
      }
      i++;
    });

    return userList;
  };
  executeCRUDLAction = async (crudlStatus, stateVariables) => {
    let userRequest = CloudService.UserRequest.constructFromObject(
      stateVariables.userBean
    );
    if (crudlStatus === CrudlStatus.CREATE) {
      return await this.createUser(userRequest, stateVariables);
    }
    if (crudlStatus === CrudlStatus.UPDATE) {
      return await this.updateUser(userRequest, stateVariables);
    }
    if (crudlStatus === CrudlStatus.DELETE) {
      return await this.deleteUser(userRequest);
    }
  };

  createUser = async (userRequest, stateVariables) => {
    if (!Format.isNotNull(userRequest.codeTag)) {
      userRequest.codeTag = this.codeTag;
    }

    userRequest.userId = this.userId;

    let userResponse = await ApiService.userCreate(userRequest);
    if (userResponse.errorCode === 0) {
      this.showSuccess("User", CrudlStatus.CREATE, stateVariables.viewState);
    } else {
      this.showError("User", CrudlStatus.CREATE, ViewState.CARD);
    }

    return userResponse;
  };

  deleteUser = async (userRequest) => {
    //RESET PRODUCT REQUEST
    var request = {};
    if (Format.isNotNull(userRequest.codeTag)) {
      request.codeTag = userRequest.codeTag;
    } else {
      request.codeTag = this.codeTag;
    }

    request.userId = this.userId;
    request.id = userRequest.id;
    request.lastUpdatedDateTime = userRequest.lastUpdatedDateTime;

    let userResponse = await ApiService.userDelete(request);
    if (userResponse.errorCode === 0) {
      this.showSuccess("User", CrudlStatus.DELETE, ViewState.TABLE);
    } else {
      this.showError("User", CrudlStatus.DELETE, ViewState.CARD);
    }

    return userResponse;
  };

  updateUser = async (userRequest, stateVariables) => {
    if (!Format.isNotNull(userRequest.codeTag)) {
      userRequest.codeTag = this.codeTag;
    }
    userRequest.userId = this.userId;

    let userResponse = await ApiService.userUpdate(userRequest);
    if (userResponse.errorCode === 0) {
      this.showSuccess("User", CrudlStatus.UPDATE, stateVariables.viewState);
    } else {
      this.showError("User", CrudlStatus.UPDATE, ViewState.CARD);
    }

    return userResponse;
  };

  userGetByEmail = async (email) => {
    let userRequest = {};
    userRequest.email = email;
    let userResponse = await ApiService.userGetByEmail(userRequest);
    return userResponse;
  };
}
