import React from "react";
import { PPEController } from "./PPEController";
import { PPEViewModel } from "./PPEViewModel";
import * as feedback from "../../common/Feedback/Feedback";
import * as log from "../../common/Feedback/Log";

export class PPEProvider extends React.Component {
  constructor(props) {
    super(props);
    this.viewModel = new PPEViewModel();
    this.logger = log;
    this.feedback = feedback;

    console.log(props);
  }

  render() {
    return (
      <PPEController
        viewModel={this.viewModel}
        log={this.logger}
        feedback={this.feedback}
        toggleTopbar={this.props.toggleTopbar}
      />
    );
  }
}
