import React from "react";
//PrimeReact Components
import * as pr from "../../../common/PrimeComponents";
import {
  CrudlStatus,
  FeedbackState,
} from "../../../common/config/AppConstants";
import * as format from "../../../common/FormatFunctions";
import { LoadScreen } from "../../Common/LoadScreen";
import { Notes } from "../../Common/Notes";
import ReactHtmlParser from "react-html-parser";
import { FileViewer } from "../../Common/FileViewer";
import { ImageViewer } from "../../Common/ImageViewer";
export class AvailableCard extends React.Component {
  componentDidUpdate = (prevProps) => {
    // Typical usage (don't forget to compare props):
    if (this.props.receivedFeedback !== prevProps.receivedFeedback) {
      switch (this.props.receivedFeedback.state) {
        case FeedbackState.EMPTY:
          this.props.feedback.showEmpty(
            this.growl,
            this.props.receivedFeedback.display
          );
          break;
        case FeedbackState.SUCCESS:
          this.props.feedback.showSuccess(
            this.growl,
            this.props.receivedFeedback.display,
            this.props.receivedFeedback.crudlStatus
          );
          break;
        case FeedbackState.ERROR:
          this.props.feedback.showError(
            this.growl,
            this.props.receivedFeedback.display,
            this.props.receivedFeedback.crudlStatus
          );
          break;
        default:
          break;
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <pr.Messages
          style={{ textAlign: "left" }}
          ref={(el) => (this.growl = el)}
        />
        <pr.ScrollPanel
          className="scrl"
          ref={(el) => (this.layoutMenuScroller = el)}
        >
          <div className="p-grid">
            <div className="p-col-12">
              <h2>{"Contact"}</h2>
              You are submitting an enquiry to the supplier of this product.
              Please include as much information as you can so the supplier can
              determine if they will be able to fulfil your order. Once this
              enquiry is accepted, you will receive the suppliers direct contact
              details via email
            </div>
            <div className="p-col-12"></div>
            {this.props.loading === true && <LoadScreen />}
            {this.props.loading !== true && (
              <React.Fragment>
                <div className="p-col-12 card">
                  <div className="p-col-12">
                    <div className="p-col-12 p-md-6">
                      <div className="p-col-12 p-md-3">
                        <label htmlFor="businessName" className="input-label">
                          Subject *
                        </label>
                      </div>
                      <div className="p-col-12 p-md-9">
                        <pr.InputText
                          id="title"
                          required={true}
                          value={this.props.editedObject.title || ""}
                          onChange={(e) => {
                            this.props.updateValue(e.target.id, e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="p-col-12 p-md-12">
                    <div className="p-col-12 p-md-3">
                      <label htmlFor="businessName" className="input-label">
                        Message *
                      </label>
                    </div>
                    <div className="p-col-12 p-md-10">
                      <Notes
                        id={this.props.editedObject.id}
                        required={true}
                        field={"longDescriptionText"}
                        value={this.props.editedObject.longDescriptionText}
                        updateValue={this.props.updateValue}
                        diabeld={false}
                      ></Notes>
                      {/* <pr.Editor
                 required={true}
                 value={
                   this.props.editedObject.longDescriptionText || ""
                 }
                 onTextChange={(e) =>
                   this.props.updateValue(
                     "longDescriptionText",
                     e.htmlValue
                   )
                 }
                 style={{ height: "50vh" }}
               /> */}
                    </div>
                  </div>
                </div>
                <pr.Accordion>
                  <pr.AccordionTab header="Product Details">
                    <div className="p-grid">
                      <div className="p-col-12">
                        <pr.DataTable
                          ref={(el) => {
                            this.dt = el;
                          }}
                          value={this.props.editedObject.tableList}
                          emptyMessage="No Availables"
                          expandedRows={this.props.expandedRows}
                          onRowToggle={(e) =>
                            this.props.updateState("expandedRows", e.data)
                          }
                          rowExpansionAvailable={this.getDisplayCard}
                          rows={Infinity}
                          autoLayout={true}
                          paginator={false}
                          alwaysShowPaginator={false}
                          responsive={true}
                          loading={this.props.loading}
                          scrollable={true}
                          scrollHeight="20vh"
                        >
                          <pr.Column
                            key={"header"}
                            field={"header"}
                            header={"Type"}
                            sortable={true}
                            filter={true}
                            filterMatchMode={"contains"}
                            style={{ overflow: "auto" }}
                          ></pr.Column>
                          <pr.Column
                            key={"componentNameToDisplay"}
                            field={"componentNameToDisplay"}
                            header={"Category"}
                            sortable={true}
                            filter={true}
                            filterMatchMode={"contains"}
                            style={{ overflow: "auto" }}
                          ></pr.Column>
                          <pr.Column
                            key={"name"}
                            field={"name"}
                            header={"Product/Service Name"}
                            sortable={true}
                            filter={true}
                            filterMatchMode={"contains"}
                            style={{ overflow: "auto" }}
                          ></pr.Column>

                          <pr.Column
                            key={"supplierName"}
                            field={"businessName"}
                            header={"Supplier Name"}
                            sortable={true}
                            filter={true}
                            filterMatchMode={"contains"}
                            style={{ overflow: "auto" }}
                          ></pr.Column>
                          <pr.Column
                            key={"companyType"}
                            field={"companyType"}
                            header={"Company Type"}
                            sortable={true}
                            filter={true}
                            filterMatchMode={"contains"}
                            style={{ overflow: "auto" }}
                          ></pr.Column>
                          <pr.Column
                            key={"companyWebsite"}
                            field={"companyWebsite"}
                            header={"Company Website"}
                            sortable={true}
                            filter={true}
                            filterMatchMode={"contains"}
                            style={{ overflow: "auto" }}
                          ></pr.Column>

                          <pr.Column
                            key={"location"}
                            field={"location"}
                            header={"Location/City"}
                            sortable={true}
                            filter={true}
                            filterMatchMode={"contains"}
                            style={{ overflow: "auto" }}
                          ></pr.Column>
                        </pr.DataTable>
                        <div className="p-col-12">
                          <pr.TabView>
                            <pr.TabPanel header="Details">
                              <div className="p-grid">
                                {format.isNotNull(
                                  this.props.editedObject.tableList
                                ) && (
                                  <div className="p-col-10">
                                    {ReactHtmlParser(
                                      this.props.editedObject.tableList[0]
                                        .longDescriptionText
                                    )}
                                  </div>
                                )}
                              </div>
                            </pr.TabPanel>
                            <pr.TabPanel header="Images">
                              <div className="p-grid">
                                {format.isNotNull(
                                  this.props.editedObject.tableList
                                ) && (
                                  <div className="p-col-12">
                                    <ImageViewer
                                      fileList={
                                        this.props.editedObject.tableList[0]
                                          .imageUri
                                      }
                                      readOnly={true}
                                    />
                                  </div>
                                )}
                              </div>
                            </pr.TabPanel>
                            <pr.TabPanel header="Files">
                              <div className="p-grid">
                                {format.isNotNull(
                                  this.props.editedObject.tableList
                                ) && (
                                  <div className="p-col-12">
                                    <FileViewer
                                      fileList={
                                        this.props.editedObject.tableList[0]
                                          .pdfUrls
                                      }
                                      readOnly={true}
                                    />
                                  </div>
                                )}
                              </div>
                            </pr.TabPanel>
                          </pr.TabView>
                        </div>
                      </div>
                    </div>
                  </pr.AccordionTab>
                </pr.Accordion>
              </React.Fragment>
            )}
          </div>
        </pr.ScrollPanel>
        <div className="sidebar-footer">
          <pr.Button
            style={{ float: "left" }}
            label="Cancel"
            onClick={() => {
              this.props.validateChanges();
            }}
          />
          {this.props.crudlState === CrudlStatus.CREATE ? (
            <pr.Button
              style={{ float: "right" }}
              disabled={this.props.checkRequiredFields()}
              label={"Submit Enquiry"}
              onClick={() => {
                this.props.crudlExecute(false);
              }}
            />
          ) : (
            <label />
          )}
        </div>
      </React.Fragment>
    );
  }
}
