import React from "react";
//PrimeReact Components
import * as pr from "../../../common/PrimeComponents";
import {
  CrudlStatus,
  FeedbackState,
  ProfileItemSection,
  ViewType,
} from "../../../common/config/AppConstants";
import * as EnumDropdowns from "../../../common/config/EnumDropdowns";
import * as format from "../../../common/FormatFunctions";
import { LoadScreen } from "../../Common/LoadScreen";
import {
  Input,
  InputType,
  LabelPosition,
  UpdateType,
} from "../../Common/Input";

export class ProfileItemCard extends React.Component {
  componentDidUpdate = (prevProps) => {
    // Typical usage (don't forget to compare props):
    if (this.props.receivedFeedback !== prevProps.receivedFeedback) {
      switch (this.props.receivedFeedback.state) {
        case FeedbackState.EMPTY:
          this.props.feedback.showEmpty(
            this.growl,
            this.props.receivedFeedback.display
          );
          break;
        case FeedbackState.SUCCESS:
          this.props.feedback.showSuccess(
            this.growl,
            this.props.receivedFeedback.display,
            this.props.receivedFeedback.crudlStatus
          );
          break;
        case FeedbackState.ERROR:
          this.props.feedback.showError(
            this.growl,
            this.props.receivedFeedback.display,
            this.props.receivedFeedback.crudlStatus
          );
          break;
        default:
          break;
      }
    }
  };

  displayProfileItems = () => {
    if (format.isNotNull(this.props.profileItemList)) {
      let general = [];
      let quality = [];
      let cost = [];
      let logistics = [];
      let development = [];
      let management = [];

      this.props.profileItemList.forEach((e) => {
        if (e.sectionName === ProfileItemSection.GENERAL) {
          general.push(e);
        }
        if (e.sectionName === ProfileItemSection.QUALITY) {
          quality.push(e);
        }
        if (e.sectionName === ProfileItemSection.COST) {
          cost.push(e);
        }
        if (e.sectionName === ProfileItemSection.LOGISTICS) {
          logistics.push(e);
        }
        if (e.sectionName === ProfileItemSection.DEVELOPMENT) {
          development.push(e);
        }
        if (e.sectionName === ProfileItemSection.MANAGEMENT) {
          management.push(e);
        }
      });

      return (
        <>
          {this.general(general)}
          {this.quality(quality)}
          {this.cost(cost)}
          {this.logistics(logistics)}
          {this.development(development)}
          {this.management(management)}
        </>
      );
    }
  };
  getObjectFromQuestion = (array, questionName) => {
    let obj = {};
    if (format.isNotNull(array)) {
      array.forEach((e) => {
        if (e.question === questionName) {
          obj = e;
        }
      });
    }

    return obj;
  };
  general = (array) => {
    let companyNameObj = this.getObjectFromQuestion(
      array,
      "formGeneralCompanyName"
    );
    let parentCompanyNameObj = this.getObjectFromQuestion(
      array,
      "formGeneralParentCompanyName"
    );
    let locationObj = this.getObjectFromQuestion(array, "formGeneralLocation");
    let productsObj = this.getObjectFromQuestion(array, "formGeneralProducts");
    let revenueObj = this.getObjectFromQuestion(array, "formGeneralRevenue");
    let employeesObj = this.getObjectFromQuestion(
      array,
      "formGeneralEmployees"
    );
    let utilisationObj = this.getObjectFromQuestion(
      array,
      "formGeneralCapacityUtilisation"
    );

    return (
      <div className="p-grid" style={{ width: "100%" }}>
        <div className="p-col-12">
          <pr.Fieldset
            legend="General"
            toggleable={true}
            collapsed={this.props.toggleGeneral}
            onCollapse={(e) => {
              this.props.updateState(
                "toggleGeneral",
                !this.props.toggleGeneral
              );
            }}
          >
            <Input
              id={"companyName"}
              className="custom-form-label"
              labelDisplay={"Company Name"}
              labelDisplayPosition={LabelPosition.LEFT}
              required={false}
              type={InputType.STRING}
              disabled={this.props.crudlState === CrudlStatus.VIEW}
              value={companyNameObj.responseString}
              onChangeType={UpdateType.CUSTOM}
              onChange={(e) => {
                companyNameObj.responseString = e.target.value;
                this.props.updateProfileItemList(companyNameObj);
              }}
              field={"responseString"}
              visible={true}
            />
            <Input
              id={"parentCompanyName"}
              labelDisplay={"Parent Company Name"}
              labelDisplayPosition={LabelPosition.LEFT}
              required={false}
              type={InputType.STRING}
              disabled={this.props.crudlState === CrudlStatus.VIEW}
              value={parentCompanyNameObj.responseString}
              onChangeType={UpdateType.CUSTOM}
              onChange={(e) => {
                parentCompanyNameObj.responseString = e.target.value;
                this.props.updateProfileItemList(parentCompanyNameObj);
              }}
              field={"responseString"}
              visible={true}
            />
            <Input
              id={"location"}
              labelDisplay={"Location"}
              labelDisplayPosition={LabelPosition.LEFT}
              required={false}
              type={InputType.STRING}
              disabled={this.props.crudlState === CrudlStatus.VIEW}
              value={locationObj.responseString}
              onChangeType={UpdateType.CUSTOM}
              onChange={(e) => {
                locationObj.responseString = e.target.value;
                this.props.updateProfileItemList(locationObj);
              }}
              field={"responseString"}
              visible={true}
            />
            <Input
              id={"products"}
              labelDisplay={"Products"}
              labelDisplayPosition={LabelPosition.LEFT}
              required={false}
              type={InputType.STRING}
              disabled={this.props.crudlState === CrudlStatus.VIEW}
              value={productsObj.responseString}
              onChangeType={UpdateType.CUSTOM}
              onChange={(e) => {
                productsObj.responseString = e.target.value;
                this.props.updateProfileItemList(productsObj);
              }}
              field={"responseString"}
              visible={true}
            />
            <Input
              id={"revenue"}
              labelDisplay={"Revenue"}
              labelDisplayPosition={LabelPosition.LEFT}
              required={false}
              type={InputType.STRING}
              disabled={this.props.crudlState === CrudlStatus.VIEW}
              value={revenueObj.responseString}
              onChangeType={UpdateType.CUSTOM}
              onChange={(e) => {
                revenueObj.responseString = e.target.value;
                this.props.updateProfileItemList(revenueObj);
              }}
              field={"responseString"}
              visible={true}
            />
            <Input
              id={"employees"}
              labelDisplay={"Number of Employees"}
              labelDisplayPosition={LabelPosition.LEFT}
              required={false}
              type={InputType.STRING}
              disabled={this.props.crudlState === CrudlStatus.VIEW}
              value={employeesObj.responseString}
              onChangeType={UpdateType.CUSTOM}
              onChange={(e) => {
                employeesObj.responseString = e.target.value;
                this.props.updateProfileItemList(employeesObj);
              }}
              field={"responseString"}
              visible={true}
            />
            <Input
              id={"utilisation"}
              labelDisplay={"Capacity Utilisation %"}
              labelDisplayPosition={LabelPosition.LEFT}
              required={false}
              type={InputType.STRING}
              disabled={this.props.crudlState === CrudlStatus.VIEW}
              value={utilisationObj.responseString}
              onChangeType={UpdateType.CUSTOM}
              onChange={(e) => {
                utilisationObj.responseString = e.target.value;
                this.props.updateProfileItemList(utilisationObj);
              }}
              field={"responseString"}
              visible={true}
            />
          </pr.Fieldset>
        </div>
      </div>
    );
  };
  quality = (array) => {
    let formQualityLeanManufacturing = this.getObjectFromQuestion(
      array,
      "formQualityLeanManufacturing"
    );
    let formQualityTotalQualityManage = this.getObjectFromQuestion(
      array,
      "formQualityTotalQualityManage"
    );
    let formQualityKaizen = this.getObjectFromQuestion(
      array,
      "formQualityKaizen"
    );
    let formQuality6Sigma = this.getObjectFromQuestion(
      array,
      "formQuality6Sigma"
    );
    let formQualityOther1 = this.getObjectFromQuestion(
      array,
      "formQualityOther1"
    );
    let formQualityOther2 = this.getObjectFromQuestion(
      array,
      "formQualityOther2"
    );
    let formQualityOther3 = this.getObjectFromQuestion(
      array,
      "formQualityOther3"
    );
    let formQualityOther4 = this.getObjectFromQuestion(
      array,
      "formQualityOther4"
    );
    let formQualityLean = this.getObjectFromQuestion(array, "formQualityLean");
    let formQualityLeanRegistrar = this.getObjectFromQuestion(
      array,
      "formQualityLeanRegistrar"
    );
    let formQualityTQM = this.getObjectFromQuestion(array, "formQualityTQM");
    let formQualityTQMRegistrar = this.getObjectFromQuestion(
      array,
      "formQualityTQMRegistrar"
    );
    let formQualityTS16949 = this.getObjectFromQuestion(
      array,
      "formQualityTS16949"
    );
    let formQualityTS16949Registrar = this.getObjectFromQuestion(
      array,
      "formQualityTS16949Registrar"
    );
    let formQualityCATCertified = this.getObjectFromQuestion(
      array,
      "formQualityCATCertified"
    );
    let formQualityCATCertifiedRegistrar = this.getObjectFromQuestion(
      array,
      "formQualityCATCertifiedRegistrar"
    );
    let formQualityISO9001 = this.getObjectFromQuestion(
      array,
      "formQualityISO9001"
    );
    let formQualityISO9001Registrar = this.getObjectFromQuestion(
      array,
      "formQualityISO9001Registrar"
    );
    let formQualityIATF = this.getObjectFromQuestion(array, "formQualityIATF");
    let formQualityIATFRegistrar = this.getObjectFromQuestion(
      array,
      "formQualityIATFRegistrar"
    );

    return (
      <div className="p-grid" style={{ width: "100%" }}>
        <div className="p-col-12">
          <pr.Fieldset
            legend="Quality"
            toggleable={true}
            collapsed={this.props.toggleQuality}
            onCollapse={(e) => {
              this.props.updateState(
                "toggleQuality",
                !this.props.toggleQuality
              );
            }}
          >
            <label className="input-label bold">
              {"1) Are you 3rd party certified?"}
            </label>
            <div className="p-grid">
              <div className="p-col-8">
                <Input
                  id={"formQualityLeanManufacturing"}
                  labelDisplay={"Lean Manufacturing"}
                  labelDisplayPosition={LabelPosition.LEFT}
                  required={false}
                  type={InputType.STRING}
                  disabled={this.props.crudlState === CrudlStatus.VIEW}
                  value={formQualityLeanManufacturing.responseString}
                  onChangeType={UpdateType.CUSTOM}
                  onChange={(e) => {
                    formQualityLeanManufacturing.responseString =
                      e.target.value;
                    this.props.updateProfileItemList(
                      formQualityLeanManufacturing
                    );
                  }}
                  field={"responseString"}
                  visible={true}
                />
              </div>
              <div className="p-col-4">
                <Input
                  id={"formQualityOther1"}
                  placeholder={"Other (please name)"}
                  labelDisplayPosition={LabelPosition.NONE}
                  required={false}
                  type={InputType.STRING}
                  disabled={this.props.crudlState === CrudlStatus.VIEW}
                  value={formQualityOther1.responseString}
                  onChangeType={UpdateType.CUSTOM}
                  onChange={(e) => {
                    formQualityOther1.responseString = e.target.value;
                    this.props.updateProfileItemList(formQualityOther1);
                  }}
                  field={"responseString"}
                  visible={true}
                />
              </div>
            </div>
            <div className="p-grid">
              <div className="p-col-8">
                <Input
                  id={"formQualityTotalQualityManage"}
                  labelDisplay={"Total Quality Management"}
                  labelDisplayPosition={LabelPosition.LEFT}
                  required={false}
                  type={InputType.STRING}
                  disabled={this.props.crudlState === CrudlStatus.VIEW}
                  value={formQualityTotalQualityManage.responseString}
                  onChangeType={UpdateType.CUSTOM}
                  onChange={(e) => {
                    formQualityTotalQualityManage.responseString =
                      e.target.value;
                    this.props.updateProfileItemList(
                      formQualityTotalQualityManage
                    );
                  }}
                  field={"responseString"}
                  visible={true}
                />
              </div>
              <div className="p-col-4">
                <Input
                  id={"formQualityOther2"}
                  placeholder={"Other (please name)"}
                  labelDisplayPosition={LabelPosition.NONE}
                  required={false}
                  type={InputType.STRING}
                  disabled={this.props.crudlState === CrudlStatus.VIEW}
                  value={formQualityOther2.responseString}
                  onChangeType={UpdateType.CUSTOM}
                  onChange={(e) => {
                    formQualityOther2.responseString = e.target.value;
                    this.props.updateProfileItemList(formQualityOther2);
                  }}
                  field={"responseString"}
                  visible={true}
                />
              </div>
            </div>
            <div className="p-grid">
              <div className="p-col-8">
                <Input
                  id={"formQualityKaizen"}
                  labelDisplay={"Kaizen"}
                  labelDisplayPosition={LabelPosition.LEFT}
                  required={false}
                  type={InputType.STRING}
                  disabled={this.props.crudlState === CrudlStatus.VIEW}
                  value={formQualityKaizen.responseString}
                  onChangeType={UpdateType.CUSTOM}
                  onChange={(e) => {
                    formQualityKaizen.responseString = e.target.value;
                    this.props.updateProfileItemList(formQualityKaizen);
                  }}
                  field={"responseString"}
                  visible={true}
                />
              </div>
              <div className="p-col-4">
                <Input
                  id={"formQualityOther3"}
                  placeholder={"Other (please name)"}
                  labelDisplayPosition={LabelPosition.NONE}
                  required={false}
                  type={InputType.STRING}
                  disabled={this.props.crudlState === CrudlStatus.VIEW}
                  value={formQualityOther3.responseString}
                  onChangeType={UpdateType.CUSTOM}
                  onChange={(e) => {
                    formQualityOther3.responseString = e.target.value;
                    this.props.updateProfileItemList(formQualityOther3);
                  }}
                  field={"responseString"}
                  visible={true}
                />
              </div>
            </div>
            <div className="p-grid">
              <div className="p-col-8">
                <Input
                  id={"formQuality6Sigma"}
                  labelDisplay={"6-Sigma"}
                  labelDisplayPosition={LabelPosition.LEFT}
                  required={false}
                  type={InputType.STRING}
                  disabled={this.props.crudlState === CrudlStatus.VIEW}
                  value={formQuality6Sigma.responseString}
                  onChangeType={UpdateType.CUSTOM}
                  onChange={(e) => {
                    formQuality6Sigma.responseString = e.target.value;
                    this.props.updateProfileItemList(formQuality6Sigma);
                  }}
                  field={"responseString"}
                  visible={true}
                />
              </div>
              <div className="p-col-4">
                <Input
                  id={"formQualityOther4"}
                  placeholder={"Other (please name)"}
                  labelDisplayPosition={LabelPosition.NONE}
                  required={false}
                  type={InputType.STRING}
                  disabled={this.props.crudlState === CrudlStatus.VIEW}
                  value={formQualityOther4.responseString}
                  onChangeType={UpdateType.CUSTOM}
                  onChange={(e) => {
                    formQualityOther4.responseString = e.target.value;
                    this.props.updateProfileItemList(formQualityOther4);
                  }}
                  field={"responseString"}
                  visible={true}
                />
              </div>
            </div>
            <label className="input-label bold">
              {
                "2) Do you have a continuous improvement process? If yes, check which one and identify the registrar"
              }
            </label>
            <div className="p-col-12">
              <div className="p-grid">
                <div className="p-col-8">
                  <Input
                    id={"formQualityLean"}
                    className="custom-form-label"
                    labelDisplay={"Lean"}
                    labelDisplayPosition={LabelPosition.LEFT}
                    required={false}
                    type={InputType.CHECKBOX}
                    disabled={this.props.crudlState === CrudlStatus.VIEW}
                    value={formQualityLean.responseString}
                    onChangeType={UpdateType.CUSTOM}
                    onChange={(e) => {
                      formQualityLean.responseString = e.checked;
                      this.props.updateProfileItemList(formQualityLean);
                    }}
                    field={"responseString"}
                    visible={true}
                    displayOptions={{ label: 6, element: 6, elementRem: 0 }}
                  />
                </div>
                <div className="p-col-4">
                  <Input
                    id={"formQualityLeanRegistrar"}
                    labelDisplay={"Registrar"}
                    labelDisplayPosition={LabelPosition.NONE}
                    required={false}
                    type={InputType.STRING}
                    disabled={this.props.crudlState === CrudlStatus.VIEW}
                    value={formQualityLeanRegistrar.responseString}
                    onChangeType={UpdateType.CUSTOM}
                    onChange={(e) => {
                      formQualityLeanRegistrar.responseString = e.target.value;
                      this.props.updateProfileItemList(
                        formQualityLeanRegistrar
                      );
                    }}
                    field={"responseString"}
                    visible={true}
                  />
                </div>
              </div>
              <div className="p-grid">
                <div className="p-col-8">
                  <Input
                    id={"formQualityTQM"}
                    className="custom-form-label"
                    labelDisplay={"TQM"}
                    labelDisplayPosition={LabelPosition.LEFT}
                    required={false}
                    type={InputType.CHECKBOX}
                    disabled={this.props.crudlState === CrudlStatus.VIEW}
                    value={formQualityTQM.responseString}
                    onChangeType={UpdateType.CUSTOM}
                    onChange={(e) => {
                      formQualityTQM.responseString = e.checked;
                      this.props.updateProfileItemList(formQualityTQM);
                    }}
                    field={"responseString"}
                    visible={true}
                    displayOptions={{ label: 6, element: 6, elementRem: 0 }}
                  />
                </div>
                <div className="p-col-4">
                  <Input
                    id={"formQualityTQMRegistrar"}
                    placeholder={"Registrar"}
                    labelDisplayPosition={LabelPosition.NONE}
                    required={false}
                    type={InputType.STRING}
                    disabled={this.props.crudlState === CrudlStatus.VIEW}
                    value={formQualityTQMRegistrar.responseString}
                    onChangeType={UpdateType.CUSTOM}
                    onChange={(e) => {
                      formQualityTQMRegistrar.responseString = e.target.value;
                      this.props.updateProfileItemList(formQualityTQMRegistrar);
                    }}
                    field={"responseString"}
                    visible={true}
                  />
                </div>
              </div>
              <div className="p-grid">
                <div className="p-col-8">
                  <Input
                    id={"formQualityTS16949"}
                    className="custom-form-label"
                    labelDisplay={"TS16949"}
                    labelDisplayPosition={LabelPosition.LEFT}
                    required={false}
                    type={InputType.CHECKBOX}
                    disabled={this.props.crudlState === CrudlStatus.VIEW}
                    value={formQualityTS16949.responseString}
                    onChangeType={UpdateType.CUSTOM}
                    onChange={(e) => {
                      formQualityTS16949.responseString = e.checked;
                      this.props.updateProfileItemList(formQualityTS16949);
                    }}
                    field={"responseString"}
                    visible={true}
                    displayOptions={{ label: 6, element: 6, elementRem: 0 }}
                  />
                </div>
                <div className="p-col-4">
                  <Input
                    id={"formQualityTS16949Registrar"}
                    placeholder={"Registrar"}
                    labelDisplayPosition={LabelPosition.NONE}
                    required={false}
                    type={InputType.STRING}
                    disabled={this.props.crudlState === CrudlStatus.VIEW}
                    value={formQualityTS16949Registrar.responseString}
                    onChangeType={UpdateType.CUSTOM}
                    onChange={(e) => {
                      formQualityTS16949Registrar.responseString =
                        e.target.value;
                      this.props.updateProfileItemList(
                        formQualityTS16949Registrar
                      );
                    }}
                    field={"responseString"}
                    visible={true}
                  />
                </div>
              </div>
              <div className="p-grid">
                <div className="p-col-8">
                  <Input
                    id={"formQualityCATCertified"}
                    className="custom-form-label"
                    labelDisplay={"CATCertified"}
                    labelDisplayPosition={LabelPosition.LEFT}
                    required={false}
                    type={InputType.CHECKBOX}
                    disabled={this.props.crudlState === CrudlStatus.VIEW}
                    value={formQualityCATCertified.responseString}
                    onChangeType={UpdateType.CUSTOM}
                    onChange={(e) => {
                      formQualityCATCertified.responseString = e.checked;
                      this.props.updateProfileItemList(formQualityCATCertified);
                    }}
                    field={"responseString"}
                    visible={true}
                    displayOptions={{ label: 6, element: 6, elementRem: 0 }}
                  />
                </div>
                <div className="p-col-4">
                  <Input
                    id={"formQualityCATCertifiedRegistrar"}
                    placeholder={"Registrar"}
                    labelDisplayPosition={LabelPosition.NONE}
                    required={false}
                    type={InputType.STRING}
                    disabled={this.props.crudlState === CrudlStatus.VIEW}
                    value={formQualityCATCertifiedRegistrar.responseString}
                    onChangeType={UpdateType.CUSTOM}
                    onChange={(e) => {
                      formQualityCATCertifiedRegistrar.responseString =
                        e.target.value;
                      this.props.updateProfileItemList(
                        formQualityCATCertifiedRegistrar
                      );
                    }}
                    field={"responseString"}
                    visible={true}
                  />
                </div>
              </div>
              <div className="p-grid">
                <div className="p-col-8">
                  <Input
                    id={"formQualityISO9001"}
                    className="custom-form-label"
                    labelDisplay={"ISO9001"}
                    labelDisplayPosition={LabelPosition.LEFT}
                    required={false}
                    type={InputType.CHECKBOX}
                    disabled={this.props.crudlState === CrudlStatus.VIEW}
                    value={formQualityISO9001.responseString}
                    onChangeType={UpdateType.CUSTOM}
                    onChange={(e) => {
                      formQualityISO9001.responseString = e.checked;
                      this.props.updateProfileItemList(formQualityISO9001);
                    }}
                    field={"responseString"}
                    visible={true}
                    displayOptions={{ label: 6, element: 6, elementRem: 0 }}
                  />
                </div>
                <div className="p-col-4">
                  <Input
                    id={"formQualityISO9001Registrar"}
                    placeholder={"Registrar"}
                    labelDisplayPosition={LabelPosition.NONE}
                    required={false}
                    type={InputType.STRING}
                    disabled={this.props.crudlState === CrudlStatus.VIEW}
                    value={formQualityISO9001Registrar.responseString}
                    onChangeType={UpdateType.CUSTOM}
                    onChange={(e) => {
                      formQualityISO9001Registrar.responseString =
                        e.target.value;
                      this.props.updateProfileItemList(
                        formQualityISO9001Registrar
                      );
                    }}
                    field={"responseString"}
                    visible={true}
                  />
                </div>
              </div>
              <div className="p-grid">
                <div className="p-col-8">
                  <Input
                    id={"formQualityIATF"}
                    className="custom-form-label"
                    labelDisplay={"IATF"}
                    labelDisplayPosition={LabelPosition.LEFT}
                    required={false}
                    type={InputType.CHECKBOX}
                    disabled={this.props.crudlState === CrudlStatus.VIEW}
                    value={formQualityIATF.responseString}
                    onChangeType={UpdateType.CUSTOM}
                    onChange={(e) => {
                      formQualityIATF.responseString = e.checked;
                      this.props.updateProfileItemList(formQualityIATF);
                    }}
                    field={"responseString"}
                    visible={true}
                    displayOptions={{ label: 6, element: 6, elementRem: 0 }}
                  />
                </div>
                <div className="p-col-4">
                  <Input
                    id={"formQualityIATFRegistrar"}
                    placeholder={"Registrar"}
                    labelDisplayPosition={LabelPosition.NONE}
                    required={false}
                    type={InputType.STRING}
                    disabled={this.props.crudlState === CrudlStatus.VIEW}
                    value={formQualityIATFRegistrar.responseString}
                    onChangeType={UpdateType.CUSTOM}
                    onChange={(e) => {
                      formQualityIATFRegistrar.responseString = e.target.value;
                      this.props.updateProfileItemList(
                        formQualityIATFRegistrar
                      );
                    }}
                    field={"responseString"}
                    visible={true}
                  />
                </div>
              </div>
            </div>
          </pr.Fieldset>
        </div>
      </div>
    );
  };
  cost = (array) => {
    let formCostOpenBook = this.getObjectFromQuestion(
      array,
      "formCostOpenBook"
    );
    let formCostShouldCost = this.getObjectFromQuestion(
      array,
      "formCostShouldCost"
    );
    let formCostCurrency = this.getObjectFromQuestion(
      array,
      "formCostCurrency"
    );
    return (
      <div className="p-grid" style={{ width: "100%" }}>
        <div className="p-col-12">
          <pr.Fieldset
            legend="Cost"
            toggleable={true}
            collapsed={this.props.toggleCost}
            onCollapse={(e) => {
              this.props.updateState("toggleCost", !this.props.toggleCost);
            }}
          >
            <Input
              id={"formCostOpenBook"}
              className="input-label bold"
              labelDisplay={"1) Do you have an open book costing philosophy?"}
              labelDisplayPosition={LabelPosition.LEFT}
              required={false}
              type={InputType.CHECKBOX}
              disabled={this.props.crudlState === CrudlStatus.VIEW}
              value={formCostOpenBook.responseString}
              onChangeType={UpdateType.CUSTOM}
              onChange={(e) => {
                formCostOpenBook.responseString = e.checked;
                this.props.updateProfileItemList(formCostOpenBook);
              }}
              field={"responseString"}
              visible={true}
              displayOptions={{ label: 5, element: 2, elementRem: 5 }}
            />
            <Input
              id={"formCostShouldCost"}
              className="input-label bold"
              labelDisplay={
                "2) Are you willing to participate in 'should cost' modelling to assure appropriate cost solutions are provided to the end customer?"
              }
              labelDisplayPosition={LabelPosition.LEFT}
              required={false}
              type={InputType.CHECKBOX}
              disabled={this.props.crudlState === CrudlStatus.VIEW}
              value={formCostShouldCost.responseString}
              onChangeType={UpdateType.CUSTOM}
              onChange={(e) => {
                formCostShouldCost.responseString = e.checked;
                this.props.updateProfileItemList(formCostShouldCost);
              }}
              field={"responseString"}
              visible={true}
              displayOptions={{ label: 5, element: 2, elementRem: 5 }}
            />
            <Input
              id={"formCostCurrency"}
              className="input-label bold"
              labelDisplay={"3) Currency"}
              labelDisplayPosition={LabelPosition.LEFT}
              placeholder={" "}
              required={false}
              type={InputType.DROPDOWN}
              dropdownOptions={EnumDropdowns.CurrencyOptions}
              disabled={this.props.crudlState === CrudlStatus.VIEW}
              value={formCostCurrency.responseString}
              onChangeType={UpdateType.CUSTOM}
              onChange={(e) => {
                formCostCurrency.responseString = e.value;
                this.props.updateProfileItemList(formCostCurrency);
              }}
              field={"responseString"}
              visible={true}
              displayOptions={{ label: 4, element: 8 }}
            />
          </pr.Fieldset>
        </div>
      </div>
    );
  };
  logistics = (array) => {
    let formLogisticsRawMaterials = this.getObjectFromQuestion(
      array,
      "formLogisticsRawMaterials"
    );
    let formLogisticsRawMaterialsExplanation = this.getObjectFromQuestion(
      array,
      "formLogisticsRawMaterialsExplanation"
    );
    let formLogisticsFinishedGoods = this.getObjectFromQuestion(
      array,
      "formLogisticsFinishedGoods"
    );
    let formLogisticsFinishedGoodsExplanation = this.getObjectFromQuestion(
      array,
      "formLogisticsFinishedGoodsExplanation"
    );
    let formLogisticsCurrentDeliveryPerformance = this.getObjectFromQuestion(
      array,
      "formLogisticsCurrentDeliveryPerformance"
    );
    let formLogisticsConsignmentStock = this.getObjectFromQuestion(
      array,
      "formLogisticsConsignmentStock"
    );
    let formLogisticsConsignmentStockExplanation = this.getObjectFromQuestion(
      array,
      "formLogisticsConsignmentStockExplanation"
    );
    let formLogisticsReturnablePackaging = this.getObjectFromQuestion(
      array,
      "formLogisticsReturnablePackaging"
    );
    let formLogisticsReturnablePackagingExplanation =
      this.getObjectFromQuestion(
        array,
        "formLogisticsReturnablePackagingExplanation"
      );
    let formLogisticsMaterialAcquisitionLeadTime = this.getObjectFromQuestion(
      array,
      "formLogisticsMaterialAcquisitionLeadTime"
    );
    let formLogisticsFinishedGoodsLeadTime = this.getObjectFromQuestion(
      array,
      "formLogisticsFinishedGoodsLeadTime"
    );
    let formLogisticsJIT = this.getObjectFromQuestion(
      array,
      "formLogisticsJIT"
    );
    let formLogisticsASN = this.getObjectFromQuestion(
      array,
      "formLogisticsASN"
    );
    let formLogisticsVMI = this.getObjectFromQuestion(
      array,
      "formLogisticsVMI"
    );
    let formLogisticsEDI = this.getObjectFromQuestion(
      array,
      "formLogisticsEDI"
    );
    let formLogisticsWarehousingFacilitiesNI = this.getObjectFromQuestion(
      array,
      "formLogisticsWarehousingFacilitiesNI"
    );
    let formLogisticsWarehousingCapabilityNI = this.getObjectFromQuestion(
      array,
      "formLogisticsWarehousingCapabilityNI"
    );
    return (
      <div className="p-grid" style={{ width: "100%" }}>
        <div className="p-col-12">
          <pr.Fieldset
            legend="Logistics"
            toggleable={true}
            collapsed={this.props.toggleLogistics}
            onCollapse={(e) => {
              this.props.updateState(
                "toggleLogistics",
                !this.props.toggleLogistics
              );
            }}
          >
            <div className="p-grid">
              <div className="p-col-6">
                <Input
                  id={"formLogisticsRawMaterials"}
                  className="input-label bold"
                  placeholder={"Raw Materials"}
                  labelDisplayPosition={LabelPosition.NONE}
                  required={false}
                  type={InputType.STRING}
                  disabled={this.props.crudlState === CrudlStatus.VIEW}
                  value={formLogisticsRawMaterials.responseString}
                  onChangeType={UpdateType.CUSTOM}
                  onChange={(e) => {
                    formLogisticsRawMaterials.responseString = e.target.value;
                    this.props.updateProfileItemList(formLogisticsRawMaterials);
                  }}
                  field={"responseString"}
                  visible={true}
                />

                <Input
                  id={"formLogisticsRawMaterialsExplanation"}
                  className="custom-form-label"
                  labelDisplay={"Please explain how you calculated this number"}
                  placeholder={" "}
                  labelDisplayPosition={LabelPosition.TOP}
                  required={false}
                  type={InputType.TEXT}
                  disabled={this.props.crudlState === CrudlStatus.VIEW}
                  value={formLogisticsRawMaterialsExplanation.responseString}
                  onChangeType={UpdateType.CUSTOM}
                  onChange={(e) => {
                    formLogisticsRawMaterialsExplanation.responseString =
                      e.target.value;
                    this.props.updateProfileItemList(
                      formLogisticsRawMaterialsExplanation
                    );
                  }}
                  field={"responseString"}
                  visible={true}
                />
              </div>
              <div className="p-col-6">
                <Input
                  id={"formLogisticsFinishedGoods"}
                  className="input-label bold"
                  placeholder={"Finished Goods"}
                  labelDisplayPosition={LabelPosition.NONE}
                  required={false}
                  type={InputType.STRING}
                  disabled={this.props.crudlState === CrudlStatus.VIEW}
                  value={formLogisticsFinishedGoods.responseString}
                  onChangeType={UpdateType.CUSTOM}
                  onChange={(e) => {
                    formLogisticsFinishedGoods.responseString = e.target.value;
                    this.props.updateProfileItemList(
                      formLogisticsFinishedGoods
                    );
                  }}
                  field={"responseString"}
                  visible={true}
                />

                <Input
                  id={"formLogisticsFinishedGoodsExplanation"}
                  className="custom-form-label"
                  labelDisplay={"Please explain how you calculated this number"}
                  labelDisplayPosition={LabelPosition.TOP}
                  placeholder={" "}
                  required={false}
                  type={InputType.TEXT}
                  disabled={this.props.crudlState === CrudlStatus.VIEW}
                  value={formLogisticsFinishedGoodsExplanation.responseString}
                  onChangeType={UpdateType.CUSTOM}
                  onChange={(e) => {
                    formLogisticsFinishedGoodsExplanation.responseString =
                      e.target.value;
                    this.props.updateProfileItemList(
                      formLogisticsFinishedGoodsExplanation
                    );
                  }}
                  field={"responseString"}
                  visible={true}
                />
              </div>
            </div>

            <Input
              id={"formLogisticsCurrentDeliveryPerformance"}
              className="input-label bold"
              labelDisplay={
                "2) What is your current delivery performance for your top five customers?"
              }
              labelDisplayPosition={LabelPosition.LEFT}
              placeholder={" "}
              required={false}
              type={InputType.STRING}
              disabled={this.props.crudlState === CrudlStatus.VIEW}
              value={formLogisticsCurrentDeliveryPerformance.responseString}
              onChangeType={UpdateType.CUSTOM}
              onChange={(e) => {
                formLogisticsCurrentDeliveryPerformance.responseString =
                  e.target.value;
                this.props.updateProfileItemList(
                  formLogisticsCurrentDeliveryPerformance
                );
              }}
              field={"responseString"}
              visible={true}
              displayOptions={{ label: 4, element: 8 }}
            />

            <div className="p-grid">
              <div className="p-col-8">
                <Input
                  id={"formLogisticsConsignmentStock"}
                  className="input-label bold"
                  labelDisplay={
                    "3) Does your company operate consignment stock for any customers?"
                  }
                  labelDisplayPosition={LabelPosition.LEFT}
                  placeholder={" "}
                  required={false}
                  type={InputType.STRING}
                  disabled={this.props.crudlState === CrudlStatus.VIEW}
                  value={formLogisticsConsignmentStock.responseString}
                  onChangeType={UpdateType.CUSTOM}
                  onChange={(e) => {
                    formLogisticsConsignmentStock.responseString =
                      e.target.value;
                    this.props.updateProfileItemList(
                      formLogisticsConsignmentStock
                    );
                  }}
                  field={"responseString"}
                  visible={true}
                  displayOptions={{ label: 7, element: 5 }}
                />
              </div>
              <div className="p-col-4">
                <Input
                  id={"formLogisticsConsignmentStockExplanation"}
                  placeholder={"If yes, please list the requirements"}
                  labelDisplayPosition={LabelPosition.NONE}
                  required={false}
                  type={InputType.STRING}
                  disabled={this.props.crudlState === CrudlStatus.VIEW}
                  value={
                    formLogisticsConsignmentStockExplanation.responseString
                  }
                  onChangeType={UpdateType.CUSTOM}
                  onChange={(e) => {
                    formLogisticsConsignmentStockExplanation.responseString =
                      e.target.value;
                    this.props.updateProfileItemList(
                      formLogisticsConsignmentStockExplanation
                    );
                  }}
                  field={"responseString"}
                  visible={true}
                />
              </div>
            </div>

            <div className="p-grid">
              <div className="p-col-8">
                <Input
                  id={"formLogisticsReturnablePackaging"}
                  className="input-label bold"
                  labelDisplay={
                    "4) Does your company operate returnable packaging programs?"
                  }
                  labelDisplayPosition={LabelPosition.LEFT}
                  placeholder={" "}
                  required={false}
                  type={InputType.STRING}
                  disabled={this.props.crudlState === CrudlStatus.VIEW}
                  value={formLogisticsReturnablePackaging.responseString}
                  onChangeType={UpdateType.CUSTOM}
                  onChange={(e) => {
                    formLogisticsReturnablePackaging.responseString =
                      e.target.value;
                    this.props.updateProfileItemList(
                      formLogisticsReturnablePackaging
                    );
                  }}
                  field={"responseString"}
                  visible={true}
                  displayOptions={{ label: 7, element: 5 }}
                />
              </div>
              <div className="p-col-4">
                <Input
                  id={"formLogisticsReturnablePackagingExplanation"}
                  className="custom-form-label"
                  placeholder={"If yes, who owns and maintains the containers?"}
                  labelDisplayPosition={LabelPosition.NONE}
                  required={false}
                  type={InputType.STRING}
                  disabled={this.props.crudlState === CrudlStatus.VIEW}
                  value={
                    formLogisticsReturnablePackagingExplanation.responseString
                  }
                  onChangeType={UpdateType.CUSTOM}
                  onChange={(e) => {
                    formLogisticsReturnablePackagingExplanation.responseString =
                      e.target.value;
                    this.props.updateProfileItemList(
                      formLogisticsReturnablePackagingExplanation
                    );
                  }}
                  field={"responseString"}
                  visible={true}
                />
              </div>
            </div>

            <Input
              id={"formLogisticsMaterialAcquisitionLeadTime"}
              className="input-label bold"
              labelDisplay={
                "5) What is your standard lead time for material aquisition?"
              }
              placeholder={"Lead Time"}
              labelDisplayPosition={LabelPosition.LEFT}
              required={false}
              type={InputType.STRING}
              disabled={this.props.crudlState === CrudlStatus.VIEW}
              value={formLogisticsMaterialAcquisitionLeadTime.responseString}
              onChangeType={UpdateType.CUSTOM}
              onChange={(e) => {
                formLogisticsMaterialAcquisitionLeadTime.responseString =
                  e.target.value;
                this.props.updateProfileItemList(
                  formLogisticsMaterialAcquisitionLeadTime
                );
              }}
              field={"responseString"}
              visible={true}
              displayOptions={{ label: 4, element: 8 }}
            />

            <Input
              id={"formLogisticsFinishedGoodsLeadTime"}
              className="input-label bold"
              labelDisplay={
                "6) What is your standard lead time for finished goods?"
              }
              placeholder={"Lead Time"}
              labelDisplayPosition={LabelPosition.LEFT}
              required={false}
              type={InputType.STRING}
              disabled={this.props.crudlState === CrudlStatus.VIEW}
              value={formLogisticsFinishedGoodsLeadTime.responseString}
              onChangeType={UpdateType.CUSTOM}
              onChange={(e) => {
                formLogisticsFinishedGoodsLeadTime.responseString =
                  e.target.value;
                this.props.updateProfileItemList(
                  formLogisticsFinishedGoodsLeadTime
                );
              }}
              field={"responseString"}
              visible={true}
              displayOptions={{ label: 4, element: 8 }}
            />

            <div className="p-col-12"></div>
            <label className="input-label bold">
              7) Please answer Yes/No to the following questions about other
              services:
            </label>

            <div className="p-col-12" style={{ padding: "10px" }}>
              <Input
                id={"formLogisticsJIT"}
                className="custom-form-label"
                labelDisplay={
                  "a) Do you currently make JIT deliveries to any of your customers?"
                }
                labelDisplayPosition={LabelPosition.LEFT}
                placeholder={" "}
                required={false}
                type={InputType.DROPDOWN}
                dropdownOptions={EnumDropdowns.YES_NO_OPTIONS}
                disabled={this.props.crudlState === CrudlStatus.VIEW}
                value={formLogisticsJIT.responseString}
                onChangeType={UpdateType.CUSTOM}
                onChange={(e) => {
                  formLogisticsJIT.responseString = e.value;
                  this.props.updateProfileItemList(formLogisticsJIT);
                }}
                field={"responseString"}
                visible={true}
                displayOptions={{ label: 4, element: 8 }}
              />
              <Input
                id={"formLogisticsASN"}
                className="custom-form-label"
                labelDisplay={
                  "b) Do you have electronic Advanced Shipping Notice (ASN) capability?"
                }
                labelDisplayPosition={LabelPosition.LEFT}
                placeholder={" "}
                required={false}
                type={InputType.DROPDOWN}
                dropdownOptions={EnumDropdowns.YES_NO_OPTIONS}
                disabled={this.props.crudlState === CrudlStatus.VIEW}
                value={formLogisticsASN.responseString}
                onChangeType={UpdateType.CUSTOM}
                onChange={(e) => {
                  formLogisticsASN.responseString = e.value;
                  this.props.updateProfileItemList(formLogisticsASN);
                }}
                field={"responseString"}
                visible={true}
                displayOptions={{ label: 4, element: 8 }}
              />
              <Input
                id={"formLogisticsVMI"}
                className="custom-form-label"
                labelDisplay={"c) Do you currently offer VMI to anybody?"}
                labelDisplayPosition={LabelPosition.LEFT}
                placeholder={" "}
                required={false}
                type={InputType.DROPDOWN}
                dropdownOptions={EnumDropdowns.YES_NO_OPTIONS}
                disabled={this.props.crudlState === CrudlStatus.VIEW}
                value={formLogisticsVMI.responseString}
                onChangeType={UpdateType.CUSTOM}
                onChange={(e) => {
                  formLogisticsVMI.responseString = e.value;
                  this.props.updateProfileItemList(formLogisticsVMI);
                }}
                field={"responseString"}
                visible={true}
                displayOptions={{ label: 4, element: 8 }}
              />
              <Input
                id={"formLogisticsEDI"}
                className="custom-form-label"
                labelDisplay={
                  "d) Do you have EDI capability for sending/receiving?"
                }
                labelDisplayPosition={LabelPosition.LEFT}
                placeholder={" "}
                required={false}
                type={InputType.DROPDOWN}
                dropdownOptions={EnumDropdowns.YES_NO_OPTIONS}
                disabled={this.props.crudlState === CrudlStatus.VIEW}
                value={formLogisticsEDI.responseString}
                onChangeType={UpdateType.CUSTOM}
                onChange={(e) => {
                  formLogisticsEDI.responseString = e.value;
                  this.props.updateProfileItemList(formLogisticsEDI);
                }}
                field={"responseString"}
                visible={true}
                displayOptions={{ label: 4, element: 8 }}
              />
              <Input
                id={"formLogisticsWarehousingFacilitiesNI"}
                className="custom-form-label"
                labelDisplay={
                  "e) Do you have warehousing facilities in Northern Ireland?"
                }
                labelDisplayPosition={LabelPosition.LEFT}
                placeholder={" "}
                required={false}
                type={InputType.DROPDOWN}
                dropdownOptions={EnumDropdowns.YES_NO_OPTIONS}
                disabled={this.props.crudlState === CrudlStatus.VIEW}
                value={formLogisticsWarehousingFacilitiesNI.responseString}
                onChangeType={UpdateType.CUSTOM}
                onChange={(e) => {
                  formLogisticsWarehousingFacilitiesNI.responseString = e.value;
                  this.props.updateProfileItemList(
                    formLogisticsWarehousingFacilitiesNI
                  );
                }}
                field={"responseString"}
                visible={true}
                displayOptions={{ label: 4, element: 8 }}
              />
              <Input
                id={"formLogisticsWarehousingCapabilityNI"}
                className="custom-form-label"
                labelDisplay={
                  "f) If not, are you willing to acquire warehousing capability in N.I.?"
                }
                labelDisplayPosition={LabelPosition.LEFT}
                placeholder={" "}
                required={false}
                type={InputType.DROPDOWN}
                dropdownOptions={EnumDropdowns.YES_NO_OPTIONS}
                disabled={this.props.crudlState === CrudlStatus.VIEW}
                value={formLogisticsWarehousingCapabilityNI.responseString}
                onChangeType={UpdateType.CUSTOM}
                onChange={(e) => {
                  formLogisticsWarehousingCapabilityNI.responseString = e.value;
                  this.props.updateProfileItemList(
                    formLogisticsWarehousingCapabilityNI
                  );
                }}
                field={"responseString"}
                visible={true}
                displayOptions={{ label: 4, element: 8 }}
              />
            </div>
          </pr.Fieldset>
        </div>
      </div>
    );
  };
  development = (array) => {
    let formDevelopmentSystem1 = this.getObjectFromQuestion(
      array,
      "formDevelopmentSystem1"
    );
    let formDevelopmentNumberOfStations1 = this.getObjectFromQuestion(
      array,
      "formDevelopmentNumberOfStations1"
    );
    let formDevelopmentSystem2 = this.getObjectFromQuestion(
      array,
      "formDevelopmentSystem2"
    );
    let formDevelopmentNumberOfStations2 = this.getObjectFromQuestion(
      array,
      "formDevelopmentNumberOfStations2"
    );
    let formDevelopmentSystem3 = this.getObjectFromQuestion(
      array,
      "formDevelopmentSystem3"
    );
    let formDevelopmentNumberOfStations3 = this.getObjectFromQuestion(
      array,
      "formDevelopmentNumberOfStations3"
    );
    let formDevelopmentRAndD = this.getObjectFromQuestion(
      array,
      "formDevelopmentRAndD"
    );
    let formDevelopmentRAndDExplanation = this.getObjectFromQuestion(
      array,
      "formDevelopmentRAndDExplanation"
    );
    let formDevelopmentDesignCapabilities = this.getObjectFromQuestion(
      array,
      "formDevelopmentDesignCapabilities"
    );
    let formDevelopmentPrototypingCapabilities = this.getObjectFromQuestion(
      array,
      "formDevelopmentPrototypingCapabilities"
    );
    let formDevelopmentProtottypingCapabiltiesWeeks =
      this.getObjectFromQuestion(
        array,
        "formDevelopmentProtottypingCapabiltiesWeeks"
      );
    let formDevelopmentRapidPrototypingCapability = this.getObjectFromQuestion(
      array,
      "formDevelopmentRapidPrototypingCapability"
    );
    let formDevelopmentFEASoftwareCapability = this.getObjectFromQuestion(
      array,
      "formDevelopmentFEASoftwareCapability"
    );
    let formDevelopmentOtherName1 = this.getObjectFromQuestion(
      array,
      "formDevelopmentOtherName1"
    );
    let formDevelopmentOtherName2 = this.getObjectFromQuestion(
      array,
      "formDevelopmentOtherName2"
    );
    let formDevelopmentOtherName3 = this.getObjectFromQuestion(
      array,
      "formDevelopmentOtherName3"
    );

    return (
      <div className="p-grid" style={{ width: "100%" }}>
        <div className="p-col-12">
          <pr.Fieldset
            legend="Development"
            toggleable={true}
            collapsed={this.props.toggleDevelopment}
            onCollapse={(e) => {
              this.props.updateState(
                "toggleDevelopment",
                !this.props.toggleDevelopment
              );
            }}
          >
            <label className="input-label bold">
              1) Which CAD (Computer Aided Design) systems do you use? (Please
              indicate all systems) Note the number of CAD "stations" in each
              system.
            </label>

            <div className="p-grid">
              <div className="p-col-8">
                <Input
                  id={"formDevelopmentSystem1"}
                  placeholder={"System"}
                  labelDisplayPosition={LabelPosition.NONE}
                  required={false}
                  type={InputType.STRING}
                  disabled={this.props.crudlState === CrudlStatus.VIEW}
                  value={formDevelopmentSystem1.responseString}
                  onChangeType={UpdateType.CUSTOM}
                  onChange={(e) => {
                    formDevelopmentSystem1.responseString = e.target.value;
                    this.props.updateProfileItemList(formDevelopmentSystem1);
                  }}
                  field={"responseString"}
                  visible={true}
                />
              </div>
              <div className="p-col-4">
                <Input
                  id={"formDevelopmentNumberOfStations1"}
                  placeholder={"# of Stations"}
                  labelDisplayPosition={LabelPosition.NONE}
                  required={false}
                  type={InputType.STRING}
                  disabled={this.props.crudlState === CrudlStatus.VIEW}
                  value={formDevelopmentNumberOfStations1.responseString}
                  onChangeType={UpdateType.CUSTOM}
                  onChange={(e) => {
                    formDevelopmentNumberOfStations1.responseString =
                      e.target.value;
                    this.props.updateProfileItemList(
                      formDevelopmentNumberOfStations1
                    );
                  }}
                  field={"responseString"}
                  visible={true}
                />
              </div>
            </div>

            <div className="p-grid">
              <div className="p-col-8">
                <Input
                  id={"formDevelopmentSystem2"}
                  placeholder={"System"}
                  labelDisplayPosition={LabelPosition.NONE}
                  required={false}
                  type={InputType.STRING}
                  disabled={this.props.crudlState === CrudlStatus.VIEW}
                  value={formDevelopmentSystem2.responseString}
                  onChangeType={UpdateType.CUSTOM}
                  onChange={(e) => {
                    formDevelopmentSystem2.responseString = e.target.value;
                    this.props.updateProfileItemList(formDevelopmentSystem2);
                  }}
                  field={"responseString"}
                  visible={true}
                />
              </div>
              <div className="p-col-4">
                <Input
                  id={"formDevelopmentNumberOfStations2"}
                  placeholder={"# of Stations"}
                  labelDisplayPosition={LabelPosition.NONE}
                  required={false}
                  type={InputType.STRING}
                  disabled={this.props.crudlState === CrudlStatus.VIEW}
                  value={formDevelopmentNumberOfStations2.responseString}
                  onChangeType={UpdateType.CUSTOM}
                  onChange={(e) => {
                    formDevelopmentNumberOfStations2.responseString =
                      e.target.value;
                    this.props.updateProfileItemList(
                      formDevelopmentNumberOfStations2
                    );
                  }}
                  field={"responseString"}
                  visible={true}
                />
              </div>
            </div>

            <div className="p-grid">
              <div className="p-col-8">
                <Input
                  id={"formDevelopmentSystem3"}
                  placeholder={"System"}
                  labelDisplayPosition={LabelPosition.NONE}
                  required={false}
                  type={InputType.STRING}
                  disabled={this.props.crudlState === CrudlStatus.VIEW}
                  value={formDevelopmentSystem3.responseString}
                  onChangeType={UpdateType.CUSTOM}
                  onChange={(e) => {
                    formDevelopmentSystem3.responseString = e.target.value;
                    this.props.updateProfileItemList(formDevelopmentSystem3);
                  }}
                  field={"responseString"}
                  visible={true}
                />
              </div>
              <div className="p-col-4">
                <Input
                  id={"formDevelopmentNumberOfStations3"}
                  placeholder={"# of Stations"}
                  labelDisplayPosition={LabelPosition.NONE}
                  required={false}
                  type={InputType.STRING}
                  disabled={this.props.crudlState === CrudlStatus.VIEW}
                  value={formDevelopmentNumberOfStations3.responseString}
                  onChangeType={UpdateType.CUSTOM}
                  onChange={(e) => {
                    formDevelopmentNumberOfStations3.responseString =
                      e.target.value;
                    this.props.updateProfileItemList(
                      formDevelopmentNumberOfStations3
                    );
                  }}
                  field={"responseString"}
                  visible={true}
                />
              </div>
            </div>

            <div className="p-grid">
              <div className="p-col-8">
                <Input
                  id={"formDevelopmentRAndD"}
                  className="input-label bold"
                  labelDisplay={
                    "2) Does your company have a Research and Development division or have you completed any recent R&D projects?"
                  }
                  labelDisplayPosition={LabelPosition.LEFT}
                  required={false}
                  type={InputType.CHECKBOX}
                  disabled={this.props.crudlState === CrudlStatus.VIEW}
                  value={formDevelopmentRAndD.responseString}
                  onChangeType={UpdateType.CUSTOM}
                  onChange={(e) => {
                    formDevelopmentRAndD.responseString = e.checked;
                    this.props.updateProfileItemList(formDevelopmentRAndD);
                  }}
                  field={"responseString"}
                  visible={true}
                  displayOptions={{ label: 6, element: 6, elementRem: 0 }}
                />
              </div>
              <div className="p-col-4">
                <Input
                  id={"formDevelopmentRAndDExplanation"}
                  className="custom-form-label"
                  labelDisplay={
                    "If yes, please provide brief information about the type and scope of work conducted"
                  }
                  labelDisplayPosition={LabelPosition.TOP}
                  placeholder={" "}
                  required={false}
                  type={InputType.STRING}
                  disabled={this.props.crudlState === CrudlStatus.VIEW}
                  value={formDevelopmentRAndDExplanation.responseString}
                  onChangeType={UpdateType.CUSTOM}
                  onChange={(e) => {
                    formDevelopmentRAndDExplanation.responseString =
                      e.target.value;
                    this.props.updateProfileItemList(
                      formDevelopmentRAndDExplanation
                    );
                  }}
                  field={"responseString"}
                  visible={true}
                />
              </div>
            </div>

            <Input
              id={"formDevelopmentDesignCapabilities"}
              className="input-label bold"
              labelDisplay={
                "3) Describe the design capabilities within your engineering group for applications and components."
              }
              placeholder={" "}
              labelDisplayPosition={LabelPosition.LEFT}
              required={false}
              type={InputType.STRING}
              disabled={this.props.crudlState === CrudlStatus.VIEW}
              value={formDevelopmentDesignCapabilities.responseString}
              onChangeType={UpdateType.CUSTOM}
              onChange={(e) => {
                formDevelopmentDesignCapabilities.responseString =
                  e.target.value;
                this.props.updateProfileItemList(
                  formDevelopmentDesignCapabilities
                );
              }}
              field={"responseString"}
              visible={true}
              displayOptions={{ label: 4, element: 8 }}
            />

            <div className="p-grid">
              <div className="p-col-8">
                <Input
                  id={"formDevelopmentPrototypingCapabilities"}
                  className="input-label bold"
                  labelDisplay={
                    "4) Does your company have a prototyping capability?"
                  }
                  labelDisplayPosition={LabelPosition.LEFT}
                  required={false}
                  type={InputType.CHECKBOX}
                  disabled={this.props.crudlState === CrudlStatus.VIEW}
                  value={formDevelopmentPrototypingCapabilities.responseString}
                  onChangeType={UpdateType.CUSTOM}
                  onChange={(e) => {
                    formDevelopmentPrototypingCapabilities.responseString =
                      e.checked;
                    this.props.updateProfileItemList(
                      formDevelopmentPrototypingCapabilities
                    );
                  }}
                  field={"responseString"}
                  visible={true}
                  displayOptions={{ label: 6, element: 6, elementRem: 0 }}
                />
              </div>
              <div className="p-col-4">
                <Input
                  id={"formDevelopmentProtottypingCapabiltiesWeeks"}
                  className="custom-form-label"
                  labelDisplay={
                    " If yes, please estimate lead time to provide prototype samples of your design."
                  }
                  placeholder={"Weeks"}
                  labelDisplayPosition={LabelPosition.TOP}
                  required={false}
                  type={InputType.STRING}
                  disabled={this.props.crudlState === CrudlStatus.VIEW}
                  value={
                    formDevelopmentProtottypingCapabiltiesWeeks.responseString
                  }
                  onChangeType={UpdateType.CUSTOM}
                  onChange={(e) => {
                    formDevelopmentProtottypingCapabiltiesWeeks.responseString =
                      e.target.value;
                    this.props.updateProfileItemList(
                      formDevelopmentProtottypingCapabiltiesWeeks
                    );
                  }}
                  field={"responseString"}
                  visible={true}
                />
              </div>
            </div>

            <div className="p-col-12"></div>
            <label className="input-label bold">
              5) Please note any other engineering capabilities you have.
            </label>

            <Input
              id={"formDevelopmentRapidPrototypingCapability"}
              className="custom-form-label"
              labelDisplay={"Rapid Prototyping Capabilty"}
              labelDisplayPosition={LabelPosition.LEFT}
              required={false}
              type={InputType.CHECKBOX}
              disabled={this.props.crudlState === CrudlStatus.VIEW}
              value={formDevelopmentRapidPrototypingCapability.responseString}
              onChangeType={UpdateType.CUSTOM}
              onChange={(e) => {
                formDevelopmentRapidPrototypingCapability.responseString =
                  e.checked;
                this.props.updateProfileItemList(
                  formDevelopmentRapidPrototypingCapability
                );
              }}
              field={"responseString"}
              visible={true}
              displayOptions={{ label: 5, element: 2, elementRem: 5 }}
            />
            <Input
              id={"formDevelopmentFEASoftwareCapability"}
              className="custom-form-label"
              labelDisplay={"FEA Software Capability"}
              labelDisplayPosition={LabelPosition.LEFT}
              required={false}
              type={InputType.CHECKBOX}
              disabled={this.props.crudlState === CrudlStatus.VIEW}
              value={formDevelopmentFEASoftwareCapability.responseString}
              onChangeType={UpdateType.CUSTOM}
              onChange={(e) => {
                formDevelopmentFEASoftwareCapability.responseString = e.checked;
                this.props.updateProfileItemList(
                  formDevelopmentFEASoftwareCapability
                );
              }}
              field={"responseString"}
              visible={true}
              displayOptions={{ label: 5, element: 2, elementRem: 5 }}
            />

            <Input
              id={"formDevelopmentOtherName1"}
              placeholder={"Other (name)"}
              labelDisplayPosition={LabelPosition.NONE}
              required={false}
              type={InputType.STRING}
              disabled={this.props.crudlState === CrudlStatus.VIEW}
              value={formDevelopmentOtherName1.responseString}
              onChangeType={UpdateType.CUSTOM}
              onChange={(e) => {
                formDevelopmentOtherName1.responseString = e.target.value;
                this.props.updateProfileItemList(formDevelopmentOtherName1);
              }}
              field={"responseString"}
              visible={true}
            />
            <Input
              id={"formDevelopmentOtherName2"}
              placeholder={"Other (name)"}
              labelDisplayPosition={LabelPosition.NONE}
              required={false}
              type={InputType.STRING}
              disabled={this.props.crudlState === CrudlStatus.VIEW}
              value={formDevelopmentOtherName2.responseString}
              onChangeType={UpdateType.CUSTOM}
              onChange={(e) => {
                formDevelopmentOtherName2.responseString = e.target.value;
                this.props.updateProfileItemList(formDevelopmentOtherName2);
              }}
              field={"responseString"}
              visible={true}
            />
            <Input
              id={"formDevelopmentOtherName3"}
              className={
                this.props.crudlState === CrudlStatus.VIEW
                  ? "disabled-label"
                  : ""
              }
              placeholder={"Other (name)"}
              labelDisplayPosition={LabelPosition.NONE}
              required={false}
              type={InputType.STRING}
              disabled={this.props.crudlState === CrudlStatus.VIEW}
              value={formDevelopmentOtherName3.responseString}
              onChangeType={UpdateType.CUSTOM}
              onChange={(e) => {
                formDevelopmentOtherName3.responseString = e.target.value;
                this.props.updateProfileItemList(formDevelopmentOtherName3);
              }}
              field={"responseString"}
              visible={true}
            />
          </pr.Fieldset>
        </div>
      </div>
    );
  };
  management = (array) => {
    let formManagementRoHsCompliance = this.getObjectFromQuestion(
      array,
      "formManagementRoHsCompliance"
    );
    let formManagementISO14001 = this.getObjectFromQuestion(
      array,
      "formManagementISO14001"
    );
    return (
      <div className="p-grid" style={{ width: "100%" }}>
        <div className="p-col-12">
          <pr.Fieldset
            legend="Management"
            toggleable={true}
            collapsed={this.props.toggleManagement}
            onCollapse={(e) => {
              this.props.updateState(
                "toggleManagement",
                !this.props.toggleManagement
              );
            }}
          >
            <label className="input-label">Do you have the following:</label>
            <Input
              id={"formManagementRoHsCompliance"}
              className="custom-form-label"
              labelDisplay={"RoHs Compliance"}
              labelDisplayPosition={LabelPosition.LEFT}
              required={false}
              type={InputType.CHECKBOX}
              disabled={this.props.crudlState === CrudlStatus.VIEW}
              value={formManagementRoHsCompliance.responseString}
              onChangeType={UpdateType.CUSTOM}
              onChange={(e) => {
                formManagementRoHsCompliance.responseString = e.checked;
                this.props.updateProfileItemList(formManagementRoHsCompliance);
              }}
              field={"responseString"}
              visible={true}
              displayOptions={{ label: 5, element: 2, elementRem: 5 }}
            />
            <Input
              id={"formManagementISO14001"}
              className="custom-form-label"
              labelDisplay={"ISO 14001"}
              labelDisplayPosition={LabelPosition.LEFT}
              required={false}
              type={InputType.CHECKBOX}
              disabled={this.props.crudlState === CrudlStatus.VIEW}
              value={formManagementISO14001.responseString}
              onChangeType={UpdateType.CUSTOM}
              onChange={(e) => {
                formManagementISO14001.responseString = e.checked;
                this.props.updateProfileItemList(formManagementISO14001);
              }}
              field={"responseString"}
              visible={true}
              displayOptions={{ label: 5, element: 2, elementRem: 5 }}
            />
          </pr.Fieldset>
        </div>
      </div>
    );
  };

  render() {
    let classname = "scrl";
    if (this.props.viewType === ViewType.MY_BUSINESS) {
      classname = "";
    }
    return (
      <React.Fragment>
        <pr.Messages
          style={{ textAlign: "left" }}
          ref={(el) => (this.growl = el)}
        />
        <div className={classname}>
          <div className="p-grid">
            <div className="p-col-12">
              {this.props.viewType === ViewType.MY_BUSINESS && (
                <pr.Button
                  icon="pi pi-info"
                  tooltip="This form is not a mandatory requirement to complete initially but once connections are made buyers may have certain requirements to progress to procurement stage. This form allows them to view your details when applicable."
                  tooltipOptions={{
                    position: "left",
                  }}
                  style={{ fontSize: "10px", float: "right" }}
                />
              )}
            </div>
            {this.props.loading === true && <LoadScreen />}
            {this.props.loading !== true && this.displayProfileItems()}

            <div className="p-col-12">
              {this.props.crudlState !== CrudlStatus.VIEW ? (
                <pr.Button
                  className="mrgn flt-r"
                  label="Save"
                  icon={
                    this.props.loading
                      ? "pi-spin pi-md-autorenew"
                      : "pi-md-save"
                  }
                  onClick={() => {
                    this.props.crudlExecute();
                  }}
                />
              ) : (
                <label />
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
