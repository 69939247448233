import { CrudlStatus } from "../config/AppConstants";
import * as format from "../FormatFunctions";

export const showError = (growl, service, crudlStatus) => {
  if (!format.isNotNull(growl)) {
    return;
  }
  let crudlVerb = "";
  switch (crudlStatus) {
    case CrudlStatus.CREATE:
      crudlVerb = "creating";
      break;
    case CrudlStatus.UPDATE:
      crudlVerb = "updating";
      break;
    case CrudlStatus.DELETE:
      crudlVerb = "deleting";
      break;
    case CrudlStatus.LIST:
      crudlVerb = "retrieving";
      break;
    default:
      crudlVerb = "processing";
      break;
  }
  growl.show({
    severity: "error",
    summary: "Error ",
    detail:
      "An error occured " +
      crudlVerb +
      " " +
      service +
      ", please refresh and try again",
    life: 3000,
  });
};

export const showSuccess = (growl, service, crudlStatus) => {
  if (!format.isNotNull(growl)) {
    return;
  }
  let crudlVerb = "";
  switch (crudlStatus) {
    case CrudlStatus.CREATE:
      crudlVerb = "created";
      break;
    case CrudlStatus.UPDATE:
      crudlVerb = "updated";
      break;
    case CrudlStatus.DELETE:
      crudlVerb = "deleted";
      break;
    case CrudlStatus.LIST:
      crudlVerb = "retrieved";
      break;
    default:
      crudlVerb = "processed";
      break;
  }
  growl.show({
    severity: "success",
    summary: "Success ",
    detail: service + " successfully " + crudlVerb,
    life: 3000,
  });
};
export const showEmpty = (growl, service) => {
  if (!format.isNotNull(growl)) {
    return;
  }
  growl.show({
    severity: "info",
    summary: "",
    detail: service + " has no items",
    life: 3000,
  });
};

export const showGeneric = (growl, service) => {
  if (!format.isNotNull(growl)) {
    return;
  }
  growl.show({
    severity: "info",
    summary: "",
    detail: service,
    life: 3000,
  });
};

export const showErrorMessage = (messages, summary, details) => {
  messages.show({ severity: "error", summary: summary, detail: details });
};
