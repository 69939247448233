import React from "react";
import firebase from "firebase";
import { LoginView } from "./LoginView";
//PrimeReact Components
import * as pr from "../../common/PrimeComponents";
import { Verify } from "../Verify/Verify";
import {
  CrudlStatus,
  FeedbackState,
  ViewType,
  ViewState,
  defaultedGlobalBid,
  BusinessType,
} from "../../common/config/AppConstants";
import * as format from "../../common/FormatFunctions";
import { CloudService } from "../../common/CloudService/ClientLibsReference";
import * as analytics from "../../common/helpers/firebaseAnalytics";

export class LoginController extends React.Component {
  state = {
    loading: false,
    rememberMe: false,
    showPassword: false,
    showBusinesses: false,
    businessOptions: [],
    selectedBusiness: "",
    email: "",
    // password: "password",
    password: "",
    verifyUser: false,
    currentBillingAccount: "",
    subscriptionStatus: "",
    viewType: ViewType.STANDARD,
  };

  componentDidMount = () => {
    this.setViewModel();
    this.handleProps();
    analytics.screenViewEvent(analytics.PageView.LOGIN);
  };

  setViewModel = () => {
    try {
      this.props.viewModel.updateState = this.updateState;
      this.props.viewModel.showError = this.showError;
      this.props.viewModel.showSuccess = this.showSuccess;
      this.props.viewModel.showEmpty = this.showEmpty;
    } catch (error) {
      this.setError(false, "setViewModel", error);
    }
  };

  handleProps = () => {
    if (format.isNotNull(this.props.viewType)) {
      this.setState({ viewType: this.props.viewType });
    }
  };

  updateState = (key, value) => {
    try {
      this.setState({ [key]: value });
    } catch (error) {
      this.setError(false, "updateState", error);
    }
  };

  showError = (display, crudlStatus, whereToView) => {
    try {
      if (whereToView === ViewState.TABLE) {
        this.props.feedback.showError(this.growl, display, crudlStatus);
      }
      if (whereToView === ViewState.CARD) {
        this.setState({
          receivedFeedback: {
            state: FeedbackState.ERROR,
            display: display,
            crudlStatus: crudlStatus,
          },
        });
      }
    } catch (error) {
      this.setError(false, "showError", error);
    }
  };

  showSuccess = (display, crudlStatus, whereToView) => {
    try {
      if (whereToView === ViewState.TABLE) {
        this.props.feedback.showSuccess(this.growl, display, crudlStatus);
      }
      if (whereToView === ViewState.CARD) {
        this.setState({
          receivedFeedback: {
            state: FeedbackState.SUCCESS,
            display: display,
            crudlStatus: crudlStatus,
          },
        });
      }
    } catch (error) {
      this.setError(false, "showSuccess", error);
    }
  };

  showEmpty = (display, whereToView) => {
    try {
      if (whereToView === ViewState.TABLE) {
        this.props.feedback.showEmpty(this.growl, display);
      }
      if (whereToView === ViewState.CARD) {
        this.setState({
          receivedFeedback: {
            state: FeedbackState.EMPTY,
            display: display,
            crudlStatus: CrudlStatus.LIST,
          },
        });
      }
    } catch (error) {
      this.setError(false, "showEmpty", error);
    }
  };

  handleSubmitFirebase = () => {
    localStorage.setItem("loginPage", "#/login");
    this.setState({ loading: true }, () => {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.state.email, this.state.password)
        .then((resp) => {
          firebase.auth().currentUser.reload();
          firebase
            .auth()
            .currentUser.getIdToken(true)
            .then((idToken) => {
              localStorage.setItem("firebaseIdToken", idToken);
              localStorage.setItem("rememberMe", this.state.rememberMe);
              if (resp.user.emailVerified === true) {
                console.log("Email already verified");
                this.login();
              } else {
                window.location = "#/Verify";
                this.setState({ verifyUser: true });
              }
            });
        })
        .catch((err) => {
          this.growl.show({
            severity: "error",
            summary: "Error",
            detail: err.message,
            life: 6000,
          });
        });

      this.setState({ loading: false });
    });
  };

  verifiedLogin = async (user) => {
    this.setState({ verifyUser: false });
    this.login();
  };

  login = async () => {
    this.setState({ loading: true });
    let didError = false;

    if (!didError) {
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          firebase
            .auth()
            .currentUser.getIdToken(true)
            .then((idToken) => {
              localStorage.setItem("idToken", idToken);
            });
          let loginRequest = new CloudService.LoginRequest();
          loginRequest.codeTag = localStorage.getItem("globalBid");
          if (!localStorage.getItem("globalBid")) {
            loginRequest.codeTag = defaultedGlobalBid;
          }
          loginRequest.userEmail = user.email.replace(/\s+/g, "");

          let loginResponse = await this.props.viewModel.loginApiV1Login(
            loginRequest
          );
          if (loginResponse.errorCode !== 0) {
            this.setState({ loading: false });
            this.showError(
              "User deleted or does not exist. Contact Adminstrator"
            );

            localStorage.clear();
            localStorage.setItem("globalBid", loginResponse.globalBid);
          } else {
            if (loginResponse.roleType === "100") {
              this.showError("User not authorised");
              this.setState({ loading: false });
            } else {
              localStorage.setItem("email", user.email);
              Object.entries(loginResponse).forEach(([key, value]) => {
                localStorage.setItem(key, value);
              });

              if (
                this.state.viewType === ViewType.COUNCIL ||
                loginResponse.roleType === "10"
              ) {
                await this.handleLoginCouncil(loginResponse, user);
              }
              if (this.state.viewType === ViewType.BUYERSUPPLIER) {
                await this.handleLoginBuyerSupplier(loginResponse, user);
              }
            }
          }
        }
      });
    }
  };

  handleLoginCouncil = async (loginResponse, user) => {
    let profileRequest = new CloudService.ProfileRequest();
    profileRequest.codeTag = localStorage.getItem("clientId");

    let profileResponse = await this.props.viewModel.getProfileByCodeTag(
      profileRequest
    );
    if (profileResponse.errorCode === 0) {
      if (format.isNotNull(profileResponse.profileList)) {
        localStorage.setItem(
          "profileStatus",
          profileResponse.profileList[0].profileStatus
        );
      }
      window.location = "#/council_dashboard";
      window.location.reload();
    } else {
      window.location.reload();
    }
  };

  handleLoginBuyerSupplier = async (loginResponse, user) => {
    let profileRequest = new CloudService.ProfileRequest();
    profileRequest.codeTag = localStorage.getItem("clientId");

    let profileResponse = await this.props.viewModel.getProfileByCodeTag(
      profileRequest
    );

    if (profileResponse.errorCode === 0) {
      var validProfile = "";
      var validProfileStatus = "";
      if (format.isNotNull(profileResponse.profileList)) {
        profileResponse.profileList.forEach((element) => {
          if (format.isNotNull(element.companyDetails)) {
            let business = format.deepCopy(element.companyDetails);
            localStorage.setItem("loggedInBusinessName", business.businessName);
            localStorage.setItem(
              "loggedInDemographicArea",
              business.demographicArea
            );
            let usersName =
              loginResponse.firstName + " " + loginResponse.surname;
            localStorage.setItem("loggedInUsersName", usersName);
          }

          if (element.type === BusinessType.BUYER) {
            validProfile = BusinessType.BUYER;
            validProfileStatus = element.profileStatus;
            analytics.loginEvent(BusinessType.BUYER);
            // analytics.loginCustomDimension(BusinessType.BUYER);
          } else if (element.type === BusinessType.SUPPLIER) {
            validProfile = BusinessType.SUPPLIER;
            validProfileStatus = element.profileStatus;
            analytics.loginEvent(BusinessType.SUPPLIER);
            // analytics.loginCustomDimension(BusinessType.SUPPLIER);
          } else if (element.type === BusinessType.BUYERSUPPLIER) {
            validProfile = BusinessType.BUYERSUPPLIER;
            validProfileStatus = element.profileStatus;
            analytics.loginEvent(BusinessType.BUYERSUPPLIER);
            // analytics.loginCustomDimension(BusinessType.BUYERSUPPLIER);
          }
        });
      }

      if (validProfile === "") {
        //No Valid Profile
        this.setState({ loading: false });
        this.showError("No valid profile found");
      } else {
        localStorage.setItem("profileStatus", validProfileStatus);
        localStorage.setItem("profileType", validProfile);

        if (user.emailVerified) {
          localStorage.setItem("email", user.email);
          window.location = "#/Dashboard";
        } else {
          window.location = "#/Verify";
        }
        window.location.reload();
      }
    }
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.handleSubmitFirebase();
    }
  };

  onInputChange = (e) => {
    if (format.isNotNull(e.target.id)) {
      this.setState({ [e.target.id]: e.target.value });
    } else if (format.isNotNull(e.target.parentElement.id)) {
      this.setState({ [e.target.parentElement.id]: e.target.value });
    }
  };

  onDropChange = (e) => {
    this.setState({ [e.id]: e.value });
  };

  onCheckboxChange = (e) => {
    this.setState({ [e.id]: e.checked });
  };

  toggleShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  render() {
    return (
      <React.Fragment>
        <pr.Messages ref={(el) => (this.growl = el)}></pr.Messages>
        {this.state.verifyUser === false && (
          <LoginView
            loading={this.state.loading}
            rememberMe={this.state.rememberMe}
            showPassword={this.state.showPassword}
            email={this.state.email}
            password={this.state.password}
            handleSubmitFirebase={this.handleSubmitFirebase}
            handleKeyPress={this.handleKeyPress}
            onInputChange={this.onInputChange}
            onCheckboxChange={this.onCheckboxChange}
            toggleShowPassword={this.toggleShowPassword}
            viewType={this.state.viewType}
          />
        )}

        {this.state.verifyUser === true && (
          <Verify verifiedLogin={this.verifiedLogin} />
        )}
      </React.Fragment>
    );
  }
}
