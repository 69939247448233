/**
 * buysupply-production.appspot.com
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.1
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient', 'model/BusinessBean', 'model/UserBean'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('./BusinessBean'), require('./UserBean'));
  } else {
    // Browser globals (root is window)
    if (!root.BuysupplyProductionappspotcom) {
      root.BuysupplyProductionappspotcom = {};
    }
    root.BuysupplyProductionappspotcom.BusinessResponse = factory(root.BuysupplyProductionappspotcom.ApiClient, root.BuysupplyProductionappspotcom.BusinessBean, root.BuysupplyProductionappspotcom.UserBean);
  }
}(this, function(ApiClient, BusinessBean, UserBean) {
  'use strict';




  /**
   * The BusinessResponse model module.
   * @module model/BusinessResponse
   * @version 1.0.0
   */

  /**
   * Constructs a new <code>BusinessResponse</code>.
   * @alias module:model/BusinessResponse
   * @class
   */
  var exports = function() {
    var _this = this;







































































  };

  /**
   * Constructs a <code>BusinessResponse</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/BusinessResponse} obj Optional instance to populate.
   * @return {module:model/BusinessResponse} The populated <code>BusinessResponse</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('addressLine1')) {
        obj['addressLine1'] = ApiClient.convertToType(data['addressLine1'], 'String');
      }
      if (data.hasOwnProperty('addressLine2')) {
        obj['addressLine2'] = ApiClient.convertToType(data['addressLine2'], 'String');
      }
      if (data.hasOwnProperty('addressLine3')) {
        obj['addressLine3'] = ApiClient.convertToType(data['addressLine3'], 'String');
      }
      if (data.hasOwnProperty('billingAddressLine1')) {
        obj['billingAddressLine1'] = ApiClient.convertToType(data['billingAddressLine1'], 'String');
      }
      if (data.hasOwnProperty('billingAddressLine2')) {
        obj['billingAddressLine2'] = ApiClient.convertToType(data['billingAddressLine2'], 'String');
      }
      if (data.hasOwnProperty('billingAddressLine3')) {
        obj['billingAddressLine3'] = ApiClient.convertToType(data['billingAddressLine3'], 'String');
      }
      if (data.hasOwnProperty('billingBusinessName')) {
        obj['billingBusinessName'] = ApiClient.convertToType(data['billingBusinessName'], 'String');
      }
      if (data.hasOwnProperty('billingCity')) {
        obj['billingCity'] = ApiClient.convertToType(data['billingCity'], 'String');
      }
      if (data.hasOwnProperty('billingCountyState')) {
        obj['billingCountyState'] = ApiClient.convertToType(data['billingCountyState'], 'String');
      }
      if (data.hasOwnProperty('billingEmail')) {
        obj['billingEmail'] = ApiClient.convertToType(data['billingEmail'], 'String');
      }
      if (data.hasOwnProperty('billingPostalZipCode')) {
        obj['billingPostalZipCode'] = ApiClient.convertToType(data['billingPostalZipCode'], 'String');
      }
      if (data.hasOwnProperty('bookingEnabled')) {
        obj['bookingEnabled'] = ApiClient.convertToType(data['bookingEnabled'], 'String');
      }
      if (data.hasOwnProperty('businessDescription')) {
        obj['businessDescription'] = ApiClient.convertToType(data['businessDescription'], 'String');
      }
      if (data.hasOwnProperty('businessImage1')) {
        obj['businessImage1'] = ApiClient.convertToType(data['businessImage1'], 'String');
      }
      if (data.hasOwnProperty('businessImage2')) {
        obj['businessImage2'] = ApiClient.convertToType(data['businessImage2'], 'String');
      }
      if (data.hasOwnProperty('businessImage3')) {
        obj['businessImage3'] = ApiClient.convertToType(data['businessImage3'], 'String');
      }
      if (data.hasOwnProperty('businessLogo')) {
        obj['businessLogo'] = ApiClient.convertToType(data['businessLogo'], 'String');
      }
      if (data.hasOwnProperty('businessName')) {
        obj['businessName'] = ApiClient.convertToType(data['businessName'], 'String');
      }
      if (data.hasOwnProperty('businessSize')) {
        obj['businessSize'] = ApiClient.convertToType(data['businessSize'], 'String');
      }
      if (data.hasOwnProperty('businesses')) {
        obj['businesses'] = ApiClient.convertToType(data['businesses'], [BusinessBean]);
      }
      if (data.hasOwnProperty('city')) {
        obj['city'] = ApiClient.convertToType(data['city'], 'String');
      }
      if (data.hasOwnProperty('codeTag')) {
        obj['codeTag'] = ApiClient.convertToType(data['codeTag'], 'String');
      }
      if (data.hasOwnProperty('configJsonStr')) {
        obj['configJsonStr'] = ApiClient.convertToType(data['configJsonStr'], 'String');
      }
      if (data.hasOwnProperty('country')) {
        obj['country'] = ApiClient.convertToType(data['country'], 'String');
      }
      if (data.hasOwnProperty('countryCode')) {
        obj['countryCode'] = ApiClient.convertToType(data['countryCode'], 'String');
      }
      if (data.hasOwnProperty('countyState')) {
        obj['countyState'] = ApiClient.convertToType(data['countyState'], 'String');
      }
      if (data.hasOwnProperty('currency')) {
        obj['currency'] = ApiClient.convertToType(data['currency'], 'String');
      }
      if (data.hasOwnProperty('currencyDecimalPlaces')) {
        obj['currencyDecimalPlaces'] = ApiClient.convertToType(data['currencyDecimalPlaces'], 'String');
      }
      if (data.hasOwnProperty('currencySymbol')) {
        obj['currencySymbol'] = ApiClient.convertToType(data['currencySymbol'], 'String');
      }
      if (data.hasOwnProperty('currentNumberOfUsers')) {
        obj['currentNumberOfUsers'] = ApiClient.convertToType(data['currentNumberOfUsers'], 'String');
      }
      if (data.hasOwnProperty('customerRefNo')) {
        obj['customerRefNo'] = ApiClient.convertToType(data['customerRefNo'], 'String');
      }
      if (data.hasOwnProperty('dateCreated')) {
        obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
      }
      if (data.hasOwnProperty('dateDeleted')) {
        obj['dateDeleted'] = ApiClient.convertToType(data['dateDeleted'], 'Date');
      }
      if (data.hasOwnProperty('deliveryPricePerMile')) {
        obj['deliveryPricePerMile'] = ApiClient.convertToType(data['deliveryPricePerMile'], 'Number');
      }
      if (data.hasOwnProperty('demographicArea')) {
        obj['demographicArea'] = ApiClient.convertToType(data['demographicArea'], 'String');
      }
      if (data.hasOwnProperty('discoveredVia')) {
        obj['discoveredVia'] = ApiClient.convertToType(data['discoveredVia'], 'String');
      }
      if (data.hasOwnProperty('discoveredViaDescription')) {
        obj['discoveredViaDescription'] = ApiClient.convertToType(data['discoveredViaDescription'], 'String');
      }
      if (data.hasOwnProperty('doesDeliveries')) {
        obj['doesDeliveries'] = ApiClient.convertToType(data['doesDeliveries'], 'Boolean');
      }
      if (data.hasOwnProperty('email')) {
        obj['email'] = ApiClient.convertToType(data['email'], 'String');
      }
      if (data.hasOwnProperty('errorCode')) {
        obj['errorCode'] = ApiClient.convertToType(data['errorCode'], 'Number');
      }
      if (data.hasOwnProperty('fixDeliveryCost')) {
        obj['fixDeliveryCost'] = ApiClient.convertToType(data['fixDeliveryCost'], 'Number');
      }
      if (data.hasOwnProperty('forceClosed')) {
        obj['forceClosed'] = ApiClient.convertToType(data['forceClosed'], 'Boolean');
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('isValidated')) {
        obj['isValidated'] = ApiClient.convertToType(data['isValidated'], 'Boolean');
      }
      if (data.hasOwnProperty('kitchenName')) {
        obj['kitchenName'] = ApiClient.convertToType(data['kitchenName'], 'String');
      }
      if (data.hasOwnProperty('lastOrderMinsBeforeClosing')) {
        obj['lastOrderMinsBeforeClosing'] = ApiClient.convertToType(data['lastOrderMinsBeforeClosing'], 'Number');
      }
      if (data.hasOwnProperty('lastUpdatedDateTime')) {
        obj['lastUpdatedDateTime'] = ApiClient.convertToType(data['lastUpdatedDateTime'], 'Date');
      }
      if (data.hasOwnProperty('latitude')) {
        obj['latitude'] = ApiClient.convertToType(data['latitude'], 'Number');
      }
      if (data.hasOwnProperty('licenceNumberOfUsers')) {
        obj['licenceNumberOfUsers'] = ApiClient.convertToType(data['licenceNumberOfUsers'], 'Number');
      }
      if (data.hasOwnProperty('longitude')) {
        obj['longitude'] = ApiClient.convertToType(data['longitude'], 'Number');
      }
      if (data.hasOwnProperty('maxNumberOfUsers')) {
        obj['maxNumberOfUsers'] = ApiClient.convertToType(data['maxNumberOfUsers'], 'String');
      }
      if (data.hasOwnProperty('openingHours')) {
        obj['openingHours'] = ApiClient.convertToType(data['openingHours'], 'String');
      }
      if (data.hasOwnProperty('openingHoursDisplay')) {
        obj['openingHoursDisplay'] = ApiClient.convertToType(data['openingHoursDisplay'], 'String');
      }
      if (data.hasOwnProperty('postalZipCode')) {
        obj['postalZipCode'] = ApiClient.convertToType(data['postalZipCode'], 'String');
      }
      if (data.hasOwnProperty('rating')) {
        obj['rating'] = ApiClient.convertToType(data['rating'], 'String');
      }
      if (data.hasOwnProperty('responseMsg')) {
        obj['responseMsg'] = ApiClient.convertToType(data['responseMsg'], 'String');
      }
      if (data.hasOwnProperty('reviews')) {
        obj['reviews'] = ApiClient.convertToType(data['reviews'], 'String');
      }
      if (data.hasOwnProperty('sector')) {
        obj['sector'] = ApiClient.convertToType(data['sector'], 'String');
      }
      if (data.hasOwnProperty('sectorOtherDescription')) {
        obj['sectorOtherDescription'] = ApiClient.convertToType(data['sectorOtherDescription'], 'String');
      }
      if (data.hasOwnProperty('stripeAccountNumber')) {
        obj['stripeAccountNumber'] = ApiClient.convertToType(data['stripeAccountNumber'], 'String');
      }
      if (data.hasOwnProperty('subType')) {
        obj['subType'] = ApiClient.convertToType(data['subType'], 'String');
      }
      if (data.hasOwnProperty('telephoneNumber')) {
        obj['telephoneNumber'] = ApiClient.convertToType(data['telephoneNumber'], 'String');
      }
      if (data.hasOwnProperty('timezone')) {
        obj['timezone'] = ApiClient.convertToType(data['timezone'], 'String');
      }
      if (data.hasOwnProperty('turnaroundTime')) {
        obj['turnaroundTime'] = ApiClient.convertToType(data['turnaroundTime'], 'String');
      }
      if (data.hasOwnProperty('type')) {
        obj['type'] = ApiClient.convertToType(data['type'], 'String');
      }
      if (data.hasOwnProperty('userGuideUrl')) {
        obj['userGuideUrl'] = ApiClient.convertToType(data['userGuideUrl'], 'String');
      }
      if (data.hasOwnProperty('users')) {
        obj['users'] = ApiClient.convertToType(data['users'], [UserBean]);
      }
      if (data.hasOwnProperty('voucherMaxDiscount')) {
        obj['voucherMaxDiscount'] = ApiClient.convertToType(data['voucherMaxDiscount'], 'String');
      }
      if (data.hasOwnProperty('websiteUrl')) {
        obj['websiteUrl'] = ApiClient.convertToType(data['websiteUrl'], 'String');
      }
      if (data.hasOwnProperty('weeklyInformation')) {
        obj['weeklyInformation'] = ApiClient.convertToType(data['weeklyInformation'], 'String');
      }
    }
    return obj;
  }

  /**
   * @member {String} addressLine1
   */
  exports.prototype['addressLine1'] = undefined;
  /**
   * @member {String} addressLine2
   */
  exports.prototype['addressLine2'] = undefined;
  /**
   * @member {String} addressLine3
   */
  exports.prototype['addressLine3'] = undefined;
  /**
   * @member {String} billingAddressLine1
   */
  exports.prototype['billingAddressLine1'] = undefined;
  /**
   * @member {String} billingAddressLine2
   */
  exports.prototype['billingAddressLine2'] = undefined;
  /**
   * @member {String} billingAddressLine3
   */
  exports.prototype['billingAddressLine3'] = undefined;
  /**
   * @member {String} billingBusinessName
   */
  exports.prototype['billingBusinessName'] = undefined;
  /**
   * @member {String} billingCity
   */
  exports.prototype['billingCity'] = undefined;
  /**
   * @member {String} billingCountyState
   */
  exports.prototype['billingCountyState'] = undefined;
  /**
   * @member {String} billingEmail
   */
  exports.prototype['billingEmail'] = undefined;
  /**
   * @member {String} billingPostalZipCode
   */
  exports.prototype['billingPostalZipCode'] = undefined;
  /**
   * @member {String} bookingEnabled
   */
  exports.prototype['bookingEnabled'] = undefined;
  /**
   * @member {String} businessDescription
   */
  exports.prototype['businessDescription'] = undefined;
  /**
   * @member {String} businessImage1
   */
  exports.prototype['businessImage1'] = undefined;
  /**
   * @member {String} businessImage2
   */
  exports.prototype['businessImage2'] = undefined;
  /**
   * @member {String} businessImage3
   */
  exports.prototype['businessImage3'] = undefined;
  /**
   * @member {String} businessLogo
   */
  exports.prototype['businessLogo'] = undefined;
  /**
   * @member {String} businessName
   */
  exports.prototype['businessName'] = undefined;
  /**
   * @member {String} businessSize
   */
  exports.prototype['businessSize'] = undefined;
  /**
   * @member {Array.<module:model/BusinessBean>} businesses
   */
  exports.prototype['businesses'] = undefined;
  /**
   * @member {String} city
   */
  exports.prototype['city'] = undefined;
  /**
   * @member {String} codeTag
   */
  exports.prototype['codeTag'] = undefined;
  /**
   * @member {String} configJsonStr
   */
  exports.prototype['configJsonStr'] = undefined;
  /**
   * @member {String} country
   */
  exports.prototype['country'] = undefined;
  /**
   * @member {String} countryCode
   */
  exports.prototype['countryCode'] = undefined;
  /**
   * @member {String} countyState
   */
  exports.prototype['countyState'] = undefined;
  /**
   * @member {String} currency
   */
  exports.prototype['currency'] = undefined;
  /**
   * @member {String} currencyDecimalPlaces
   */
  exports.prototype['currencyDecimalPlaces'] = undefined;
  /**
   * @member {String} currencySymbol
   */
  exports.prototype['currencySymbol'] = undefined;
  /**
   * @member {String} currentNumberOfUsers
   */
  exports.prototype['currentNumberOfUsers'] = undefined;
  /**
   * @member {String} customerRefNo
   */
  exports.prototype['customerRefNo'] = undefined;
  /**
   * @member {Date} dateCreated
   */
  exports.prototype['dateCreated'] = undefined;
  /**
   * @member {Date} dateDeleted
   */
  exports.prototype['dateDeleted'] = undefined;
  /**
   * @member {Number} deliveryPricePerMile
   */
  exports.prototype['deliveryPricePerMile'] = undefined;
  /**
   * @member {String} demographicArea
   */
  exports.prototype['demographicArea'] = undefined;
  /**
   * @member {String} discoveredVia
   */
  exports.prototype['discoveredVia'] = undefined;
  /**
   * @member {String} discoveredViaDescription
   */
  exports.prototype['discoveredViaDescription'] = undefined;
  /**
   * @member {Boolean} doesDeliveries
   */
  exports.prototype['doesDeliveries'] = undefined;
  /**
   * @member {String} email
   */
  exports.prototype['email'] = undefined;
  /**
   * @member {Number} errorCode
   */
  exports.prototype['errorCode'] = undefined;
  /**
   * @member {Number} fixDeliveryCost
   */
  exports.prototype['fixDeliveryCost'] = undefined;
  /**
   * @member {Boolean} forceClosed
   */
  exports.prototype['forceClosed'] = undefined;
  /**
   * @member {String} id
   */
  exports.prototype['id'] = undefined;
  /**
   * @member {Boolean} isValidated
   */
  exports.prototype['isValidated'] = undefined;
  /**
   * @member {String} kitchenName
   */
  exports.prototype['kitchenName'] = undefined;
  /**
   * @member {Number} lastOrderMinsBeforeClosing
   */
  exports.prototype['lastOrderMinsBeforeClosing'] = undefined;
  /**
   * @member {Date} lastUpdatedDateTime
   */
  exports.prototype['lastUpdatedDateTime'] = undefined;
  /**
   * @member {Number} latitude
   */
  exports.prototype['latitude'] = undefined;
  /**
   * @member {Number} licenceNumberOfUsers
   */
  exports.prototype['licenceNumberOfUsers'] = undefined;
  /**
   * @member {Number} longitude
   */
  exports.prototype['longitude'] = undefined;
  /**
   * @member {String} maxNumberOfUsers
   */
  exports.prototype['maxNumberOfUsers'] = undefined;
  /**
   * @member {String} openingHours
   */
  exports.prototype['openingHours'] = undefined;
  /**
   * @member {String} openingHoursDisplay
   */
  exports.prototype['openingHoursDisplay'] = undefined;
  /**
   * @member {String} postalZipCode
   */
  exports.prototype['postalZipCode'] = undefined;
  /**
   * @member {String} rating
   */
  exports.prototype['rating'] = undefined;
  /**
   * @member {String} responseMsg
   */
  exports.prototype['responseMsg'] = undefined;
  /**
   * @member {String} reviews
   */
  exports.prototype['reviews'] = undefined;
  /**
   * @member {String} sector
   */
  exports.prototype['sector'] = undefined;
  /**
   * @member {String} sectorOtherDescription
   */
  exports.prototype['sectorOtherDescription'] = undefined;
  /**
   * @member {String} stripeAccountNumber
   */
  exports.prototype['stripeAccountNumber'] = undefined;
  /**
   * @member {String} subType
   */
  exports.prototype['subType'] = undefined;
  /**
   * @member {String} telephoneNumber
   */
  exports.prototype['telephoneNumber'] = undefined;
  /**
   * @member {String} timezone
   */
  exports.prototype['timezone'] = undefined;
  /**
   * @member {String} turnaroundTime
   */
  exports.prototype['turnaroundTime'] = undefined;
  /**
   * @member {String} type
   */
  exports.prototype['type'] = undefined;
  /**
   * @member {String} userGuideUrl
   */
  exports.prototype['userGuideUrl'] = undefined;
  /**
   * @member {Array.<module:model/UserBean>} users
   */
  exports.prototype['users'] = undefined;
  /**
   * @member {String} voucherMaxDiscount
   */
  exports.prototype['voucherMaxDiscount'] = undefined;
  /**
   * @member {String} websiteUrl
   */
  exports.prototype['websiteUrl'] = undefined;
  /**
   * @member {String} weeklyInformation
   */
  exports.prototype['weeklyInformation'] = undefined;



  return exports;
}));


