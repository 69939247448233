import React from "react";
//PrimeReact Components
import * as pr from "../../../common/PrimeComponents";
import { CrudlStatus } from "../../../common/config/AppConstants";
import * as format from "../../../common/FormatFunctions";
import { AvailableDisplay } from "./AvailableDisplay";
import ReactHtmlParser from "react-html-parser";
import { ImageViewer } from "../../Common/ImageViewer";
import { FileViewer } from "../../Common/FileViewer";

export class AvailableTable extends React.Component {
  export = () => {
    this.dt.exportCSV();
  };

  getAvailableColumns = () => {
    var allColumns = [];
    // allColumns.push(
    //   <pr.Column
    //     key="expander"
    //     expander={true}
    //     style={{ width: "5em", float: "middle" }}
    //   />
    // );
    if (format.isNotNull(this.props.tableColumns)) {
      let columns = this.props.tableColumns.map((col, i) => {
        return (
          <pr.Column
            key={col.field}
            field={col.field}
            header={col.header}
            sortable={col.sortable}
            filter={col.filter}
            filterMatchMode={col.filterMatchMode}
            style={col.style}
          />
        );
      });

      columns.forEach((e) => {
        allColumns.push(e);
      });
    }
    return allColumns;
  };

  render() {
    return (
      <div className="p-grid bpm">
        <div className="p-col-12">
          <div className="p-col-12">
            <div className="content-section">
              <div className="feature-intro">
                <h1>{"Products & Services"}</h1>
                <pr.Accordion>
                  <pr.AccordionTab header="How to Use">
                    <div className="p-grid">
                      <div className="p-col-12">
                        A list of Products and Services currently available to
                        buying companies. Use the global filter combined with
                        the column filters to find what your business requires.
                        Use the portal to view details about the product or
                        service that the supplier has provided. If a business
                        has an online store, then click on the link to purchase
                        the product. Should you require a bit more information
                        from the supplier and would like to get through to their
                        sales team, fill out the contact form with more details.
                        If the supplier approves your request for information,
                        you will receive their contact details via email. Should
                        none of this work for you and you require something more
                        customised or not currently listed, please create your
                        specific requirement within the "My Requirements"
                        section. Suppliers will then be in contact with you to
                        discuss further.
                      </div>
                    </div>
                  </pr.AccordionTab>
                </pr.Accordion>
              </div>
            </div>
          </div>
        </div>
        <pr.Sidebar
          className="productDetails"
          header={"Product Details"}
          style={{ width: "75%", overflow: "auto" }}
          position="left"
          visible={this.props.productDetailsView}
          onHide={() => {
            this.props.updateState("productDetailsView", false);
          }}
          maximizable
          blockScroll
        >
          <div className="p-grid">
            <div className="p-col-12"></div>

            <div className="p-col-12">
              {format.isNotNull(this.props.productDetails) && (
                <div className="p-grid card">
                  <div className="p-col-12">
                    {/*Empty div for spacing*/}
                    <label />
                  </div>
                  <div className="p-col-12">
                    <div className="p-grid">
                      <div className="p-col-6">
                        <b>Category: </b>
                        <label>
                          {this.props.productDetails.componentNameToDisplay ||
                            "Not Set"}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="p-col-12">
                    <div className="p-grid">
                      <div className="p-col-6">
                        <b> Name: </b>
                        <label>
                          {this.props.productDetails.name || "Not Set"}
                        </label>
                      </div>
                      <div className="p-col-6">
                        <b>Supplier Name: </b>
                        <label>
                          {this.props.productDetails.businessName || "Not Set"}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="p-col-12">
              <pr.TabView>
                <pr.TabPanel header="Details">
                  <div className="p-grid card">
                    {format.isNotNull(this.props.productDetails) && (
                      <div className="p-col-10">
                        {ReactHtmlParser(
                          this.props.productDetails.longDescriptionText
                        )}
                      </div>
                    )}
                  </div>
                </pr.TabPanel>
                <pr.TabPanel
                  header="Images"
                  headerStyle={
                    format.isNotNull(this.props.productDetails) &&
                    this.props.productDetails.imageUri
                      ? {}
                      : { display: "none" }
                  }
                >
                  <div className="p-grid card">
                    {format.isNotNull(this.props.productDetails) && (
                      <div className="p-col-12">
                        <ImageViewer
                          fileList={this.props.productDetails.imageUri}
                          readOnly={true}
                        />
                      </div>
                    )}
                  </div>
                </pr.TabPanel>
                <pr.TabPanel
                  header="Files"
                  headerStyle={
                    format.isNotNull(this.props.productDetails) &&
                    this.props.productDetails.pdfUrls
                      ? {}
                      : { display: "none" }
                  }
                >
                  <div className="p-grid card">
                    {format.isNotNull(this.props.productDetails) && (
                      <div className="p-col-12">
                        <FileViewer
                          fileList={this.props.productDetails.pdfUrls}
                          readOnly={true}
                        />
                      </div>
                    )}
                  </div>
                </pr.TabPanel>
              </pr.TabView>

              <div className="p-col-12">
                <pr.Button
                  label="Close"
                  onClick={() => {
                    this.props.updateState("productDetailsView", false);
                  }}
                  style={{ float: "right" }}
                />
              </div>
            </div>
          </div>
        </pr.Sidebar>

        <div className="p-col-12">{/** spacing */}</div>
        <>
          <div className="p-col-12 p-md-3"></div>
          <div className="p-col-12 p-md-6">
            <pr.InputText
              id="title"
              value={this.props.globalFilter || ""}
              onChange={(e) => {
                this.props.updateState("globalFilter", e.target.value);
              }}
              placeholder="Type here to search entire table"
              style={{ height: "5vh", backgroundColor: "white" }}
            />
          </div>
        </>
        <div className="p-col-12">
          <pr.DataTable
            ref={(el) => {
              this.dt = el;
            }}
            value={this.props.availableList}
            emptyMessage="No Availables"
            expandedRows={this.props.expandedRows}
            onRowToggle={(e) => this.props.updateState("expandedRows", e.data)}
            rowExpansionAvailable={this.getDisplayCard}
            rows={Infinity}
            autoLayout={true}
            paginator={false}
            alwaysShowPaginator={false}
            responsive={true}
            loading={this.props.loading}
            scrollable={true}
            scrollHeight="60vh"
            globalFilter={this.props.globalFilter}
          >
            <pr.Column
              key={"header"}
              field={"header"}
              header={"Type"}
              sortable={true}
              filter={true}
              filterMatchMode={"contains"}
              style={{ overflow: "auto" }}
            ></pr.Column>
            <pr.Column
              key={"componentNameToDisplay"}
              field={"componentNameToDisplay"}
              header={"Category"}
              sortable={true}
              filter={true}
              filterMatchMode={"contains"}
              style={{ overflow: "auto" }}
            ></pr.Column>
            <pr.Column
              key={"name"}
              field={"name"}
              header={"Name"}
              sortable={true}
              filter={true}
              filterMatchMode={"contains"}
              style={{ overflow: "auto" }}
            ></pr.Column>
            <pr.Column header="Details" body={this.moreDetails}></pr.Column>
            <pr.Column
              key={"supplierName"}
              field={"businessName"}
              header={"Supplier Name"}
              sortable={true}
              filter={true}
              filterMatchMode={"contains"}
              style={{ overflow: "auto" }}
            ></pr.Column>
            <pr.Column
              key={"companyType"}
              field={"companyType"}
              header={"Supplier Type"}
              sortable={true}
              filter={true}
              filterMatchMode={"contains"}
              style={{ overflow: "auto" }}
            ></pr.Column>
            <pr.Column
              key={"companyWebsite"}
              field={"companyWebsite"}
              header={"Supplier Website"}
              body={this.getLinkToExternalWebsite}
            ></pr.Column>
            <pr.Column
              key={"shortDescription"}
              field={"shortDescription"}
              header={"Link To Purchase Online"}
              body={this.getLinkToWebsite}
            ></pr.Column>
            <pr.Column
              key={"location"}
              field={"location"}
              header={"Location/City"}
              sortable={true}
              filter={true}
              filterMatchMode={"contains"}
              style={{ overflow: "auto" }}
            ></pr.Column>

            <pr.Column body={this.getApplicationTemplate}></pr.Column>
          </pr.DataTable>
        </div>
      </div>
    );
  }
  getApplicationTemplate = (rowData, column) => {
    return (
      <div className="p-grid">
        <div className="p-col-2"></div>
        <div className="p-col-8">
          <pr.Button
            type="button"
            label={"Contact "}
            onClick={(e) =>
              this.props.crudlControl(CrudlStatus.CREATE, rowData)
            }
            style={{}}
          ></pr.Button>
        </div>
      </div>
    );
  };
  getLinkToWebsite = (rowData) => {
    if (format.isNotNull(rowData.shortDescription)) {
      if (rowData.shortDescription.includes("http")) {
        return (
          <a
            style={{ display: "table-cell" }}
            href={rowData.shortDescription}
            target="_blank"
            rel="noreferrer"
          >
            Buy Online
          </a>
        );
      } else {
        return <label></label>;
      }
    }

    return <label></label>;
  };
  getLinkToExternalWebsite = (rowData) => {
    if (format.isNotNull(rowData.companyWebsite)) {
      if (rowData.companyWebsite.includes("http")) {
        return (
          <a
            style={{ display: "table-cell" }}
            href={rowData.companyWebsite}
            target="_blank"
            rel="noreferrer"
          >
            View Website
          </a>
        );
      } else {
        return <label></label>;
      }
    }

    return <label></label>;
  };
  moreDetails = (rowData, column) => {
    return (
      <div className="p-grid">
        <div className="p-col-2"></div>
        <div className="p-col-8">
          <pr.Button
            type="button"
            label={"View Details"}
            onClick={(e) => {
              this.props.updateState("productDetails", rowData);
              this.props.updateState("productDetailsView", true);
            }}
            style={{}}
          ></pr.Button>
        </div>
      </div>
    );
  };
  getDisplayCard = (data) => {
    return <AvailableDisplay data={data} onClick={this.props.crudlControl} />;
  };
}
