import React from "react";
//PrimeReact Components
import * as pr from "../../../common/PrimeComponents";
import {
  CrudlStatus,
  ViewType,
  ToggleState,
  ValidateState,
  ProductType,
} from "../../../common/config/AppConstants";
import * as format from "../../../common/FormatFunctions";
import { ProductDisplay } from "./ProductDisplay";
import { MVVMValidateDialog } from "../../Common/MVVMValidateDialog";
import { ImageViewer } from "../../Common/ImageViewer";
import { FileViewer } from "../../Common/FileViewer";
import {
  Input,
  InputType,
  LabelPosition,
  UpdateType,
} from "../../Common/Input";
import * as analytics from "../../../common/helpers/firebaseAnalytics";

export class ProductTable extends React.Component {
  export = () => {
    this.dt.exportCSV();
  };

  getProductColumns = () => {
    var allColumns = [];
    if (format.isNotNull(this.props.tableColumns)) {
      let columns = this.props.tableColumns.map((col, i) => {
        return (
          <pr.Column
            key={col.field}
            field={col.field}
            header={col.header}
            sortable={col.sortable}
            filter={col.filter}
            filterMatchMode={col.filterMatchMode}
            style={col.style}
          />
        );
      });

      columns.forEach((e) => {
        allColumns.push(e);
      });

      if (
        this.props.viewType === ViewType.BUYER ||
        this.props.viewType === ViewType.SUPPLIER ||
        this.props.viewType === ViewType.DASHBOARD
      ) {
        allColumns.push(
          <pr.Column header="Product/Service Details" body={this.moreDetails} />
        );
        allColumns.push(<pr.Column body={this.contact} />);
      }

      if (this.props.viewType === ViewType.PROFILE) {
        allColumns.push(
          <pr.Column
            key={"shortDescription"}
            field={"shortDescription"}
            header={"Link To Website"}
            body={this.getLinkToWebsite}
          />
        );
        allColumns.push(
          <pr.Column header="Product/Service Details" body={this.moreDetails} />
        );
        allColumns.push(
          <pr.Column
            header="Buying / Can Supply"
            body={this.displayBuyOrSupply}
          />
        );
      }
      if (this.props.viewType === ViewType.MY_PRODUCT) {
        allColumns.push(
          <pr.Column
            key={"shortDescription"}
            field={"shortDescription"}
            header={"Link To Website"}
            body={this.getLinkToWebsite}
          />
        );
        allColumns.push(
          <pr.Column header="Product/Service Details" body={this.moreDetails} />
        );
        allColumns.push(<pr.Column body={this.getEditDeleteTemplate} />);
      }
      if (this.props.viewType === ViewType.MY_REQUIREMENT) {
        allColumns.push(<pr.Column body={this.getEditDeleteTemplate} />);
      }
    }
    return allColumns;
  };

  render() {
    return (
      <div className="p-grid bpm">
        <>
          {this.props.viewType === ViewType.DASHBOARD ? (
            <>
              {/* //   {this.props.dashboardType === BusinessType.BUYER && (
            //     <h3>{"Products Buyers wish to procure"}</h3>
            //   )}
            //   {this.props.dashboardType === BusinessType.SUPPLIER && (
            //     <h3>{"Products Supplier can supply"}</h3>
            //   )} */}
            </>
          ) : (
            <>
              <div className="p-col-12 p-lg-3 p-md-6 p-sl-12">
                <div className="content-section">
                  <div className="feature-intro">
                    {this.props.viewType === ViewType.MY_PRODUCT && (
                      <h1>{"My Products"}</h1>
                    )}
                    {this.props.viewType === ViewType.MY_REQUIREMENT && (
                      <h1>{"My Requirements"}</h1>
                    )}
                    {this.props.viewType === ViewType.BUYER && (
                      <h1>{"Buyer Requirements"}</h1>
                    )}
                    {this.props.viewType === ViewType.SUPPLIER && (
                      <h1>{"Supplier Products & Services"}</h1>
                    )}
                  </div>
                </div>
              </div>
              <div className="p-col-12 p-lg-9 p-md-6 p-sl-12">
                <pr.Button
                  icon="pi pi-info"
                  onClick={() => {
                    this.props.updateState("showTutorial", true);
                  }}
                  style={{ fontSize: "10px", float: "right" }}
                />
              </div>
            </>
          )}
        </>

        {this.props.productDetailsView && (
          <>
            <MVVMValidateDialog
              header="Delete"
              isVisible={this.props.validateState === ValidateState.DELETE}
              visibleField="validateState"
              updateVisibility={this.props.updateState}
              message={" Are you sure you want to delete?"}
              type={"CRUDL"}
              crudlExecute={this.props.crudlExecute}
              crudlState={this.props.crudlState}
            />
            <pr.Sidebar
              className="productDetails"
              header={"Product Details"}
              style={{ width: "75%" }}
              position="left"
              visible={true}
              onHide={() => {
                this.props.updateState("productDetailsView", false);
              }}
              maximizable
              // blockScroll
            >
              <div className="p-grid">
                <div className="p-col-12"></div>

                <div className="p-col-12">
                  {format.isNotNull(this.props.editedObject) && (
                    <div className="p-grid card" style={{ width: "100%" }}>
                      <div className="p-col-6">
                        <Input
                          id={"name"}
                          labelDisplay={"Name"}
                          labelDisplayPosition={LabelPosition.LEFT}
                          required={false}
                          type={InputType.STRING}
                          disabled={true}
                          value={this.props.editedObject.name}
                          field={"Name"}
                          visible={true}
                        />
                      </div>
                      <div className="p-col-6">
                        <Input
                          id={"componentNameToDisplay"}
                          labelDisplay={"Category"}
                          labelDisplayPosition={LabelPosition.LEFT}
                          required={false}
                          type={InputType.STRING}
                          disabled={true}
                          value={this.props.editedObject.componentNameToDisplay}
                          field={"componentNameToDisplay"}
                          visible={true}
                        />
                      </div>
                      <div className="p-col-6">
                        <Input
                          id={"businessName"}
                          labelDisplay={"Supplier Name"}
                          labelDisplayPosition={LabelPosition.LEFT}
                          required={false}
                          type={InputType.STRING}
                          disabled={true}
                          value={this.props.editedObject.businessName}
                          field={"businessName"}
                          visible={true}
                        />
                      </div>
                      <div className="p-col-6">
                        <Input
                          id={"location"}
                          labelDisplay={"Location"}
                          labelDisplayPosition={LabelPosition.LEFT}
                          required={false}
                          type={InputType.STRING}
                          disabled={true}
                          value={this.props.editedObject.location}
                          field={"location"}
                          visible={true}
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div className="p-col-12">
                  <div className="p-grid" style={{ width: "100%" }}>
                    <div className="p-col-12">
                      <pr.TabView>
                        <pr.TabPanel header="Details">
                          <div className="p-grid card">
                            {format.isNotNull(this.props.editedObject) && (
                              <div className="p-col-10">
                                <Input
                                  id={"longDescription"}
                                  labelDisplay={"Additional Details"}
                                  labelDisplayPosition={LabelPosition.TOP}
                                  required={false}
                                  type={InputType.NOTES}
                                  disabled={true}
                                  value={
                                    this.props.editedObject.longDescription
                                  }
                                  field={"longDescription"}
                                  visible={true}
                                />
                              </div>
                            )}
                          </div>
                        </pr.TabPanel>
                        <pr.TabPanel
                          header="Images"
                          headerStyle={
                            this.props.editedObject.imageUri
                              ? {}
                              : { display: "none" }
                          }
                        >
                          <div className="p-grid">
                            {format.isNotNull(this.props.editedObject) && (
                              <div className="p-col-12">
                                <ImageViewer
                                  imageList={this.props.editedObject.imageUri}
                                  readOnly={true}
                                  toggleProductLogo={false}
                                />
                              </div>
                            )}
                          </div>
                        </pr.TabPanel>
                        <pr.TabPanel
                          header="Files"
                          headerStyle={
                            this.props.editedObject.pdfUrls
                              ? {}
                              : { display: "none" }
                          }
                        >
                          <div className="p-grid card">
                            {format.isNotNull(this.props.editedObject) && (
                              <div className="p-col-12">
                                <FileViewer
                                  fileList={this.props.editedObject.pdfUrls}
                                  readOnly={true}
                                />
                              </div>
                            )}
                          </div>
                        </pr.TabPanel>
                      </pr.TabView>
                    </div>
                  </div>
                </div>
              </div>
            </pr.Sidebar>
          </>
        )}
        <div
          className="p-col-12"
          style={
            this.props.settingsToggle === ToggleState.ON
              ? {}
              : { display: "none" }
          }
        ></div>
        {this.props.viewType !== ViewType.DASHBOARD && (
          <>
            <div className="p-col-12 p-md-3"></div>
            <div className="p-col-12 p-md-6">
              <Input
                id={"globalFilter"}
                labelDisplay={"Type here to search entire table"}
                labelDisplayPosition={LabelPosition.NONE}
                required={false}
                type={InputType.STRING}
                disabled={false}
                value={this.props.globalFilter}
                onChangeType={UpdateType.STANDARD}
                onChange={this.props.updateState}
                field={"globalFilter"}
                visible={true}
                style={{ backgroundColor: "#dae4f1" }}
                onBlur={() => {
                  analytics.searchEvent(this.props.globalFilter);
                }}
              />
            </div>
          </>
        )}
        <div className="p-col-12">
          <pr.DataTable
            ref={(el) => {
              this.dt = el;
            }}
            value={this.props.productList}
            selectionMode="single"
            // onSelectionChange={(e) => {
            //   this.props.crudlControl(CrudlStatus.UPDATE, e.value);
            // }}
            header={
              <div className="p-grid">
                <div className="p-col-12">
                  {this.props.viewType !== ViewType.PROFILE &&
                    this.props.viewType !== ViewType.BUYER &&
                    this.props.viewType !== ViewType.SUPPLIER && (
                      <>
                        <pr.Button
                          className="table-button"
                          label={"Add New"}
                          icon="pi-md-add"
                          style={{
                            float: "right",
                          }}
                          onClick={() => {
                            this.props.crudlControl(CrudlStatus.CREATE);
                          }}
                        />
                        <pr.Button
                          className="table-button"
                          label={"Add Multiple"}
                          icon="pi-md-add"
                          style={{
                            float: "right",
                            marginRight: "5px",
                          }}
                          onClick={() => {
                            this.props.updateState("batchUpload", true);
                          }}
                        />
                      </>
                    )}
                </div>
              </div>
            }
            emptyMessage={(e) => {
              if (e === false) {
                if (!this.props.productFilterListEmpty) {
                  if (format.isFunction(this.props.updateStateFromProps)) {
                    this.props.updateStateFromProps(
                      "productFilterListEmpty",
                      true
                    );
                  }
                }
                return "No Products";
              }
            }}
            expandedRows={this.props.expandedRows}
            onRowToggle={(e) => this.props.updateState("expandedRows", e.data)}
            rowExpansionProduct={this.getDisplayCard}
            rows={Infinity}
            autoLayout={true}
            paginator={false}
            alwaysShowPaginator={false}
            responsive={true}
            loading={this.props.loading}
            globalFilter={this.props.globalFilter}
          >
            {this.getProductColumns()}
          </pr.DataTable>
        </div>
      </div>
    );
  }
  getLinkToWebsite = (rowData) => {
    if (format.isNotNull(rowData.shortDescription)) {
      if (rowData.shortDescription.includes("http")) {
        return (
          <a
            style={{ display: "table-cell" }}
            href={rowData.shortDescription}
            target="_blank"
            rel="noreferrer"
          >
            Buy Online
          </a>
        );
      } else {
        return <label>Invalid Link</label>;
      }
    }

    return <label></label>;
  };
  moreDetails = (rowData, column) => {
    return (
      <div className="p-grid">
        <div className="p-col-12">
          <pr.Button
            type="button"
            label={"View Details"}
            onClick={(e) => {
              this.props.updateState("editedProduct", rowData);
              this.props.updateState(
                "originalEditedProduct",
                JSON.stringify(rowData)
              );
              this.props.updateState("productDetailsView", true);
              analytics.selectProductEvent(this.props.viewType, rowData);
            }}
          />
        </div>
      </div>
    );
  };
  displayBuyOrSupply = (rowData, column) => {
    return (
      <div className="p-grid">
        <div className="p-col-12">
          {rowData.type === ProductType.BUYER && <label>{"Wish to Buy"}</label>}
          {rowData.type === ProductType.SUPPLIER && (
            <label>{"Can Supply"}</label>
          )}
          {rowData.type !== ProductType.BUYER &&
            rowData.type !== ProductType.SUPPLIER && (
              <label>{"Buy & Supply"}</label>
            )}
        </div>
      </div>
    );
  };
  getEditDeleteTemplate = (rowData, column) => {
    return (
      <div className="p-grid">
        <div className="p-col-12">
          <pr.Button
            type="button"
            icon="pi pi-pencil"
            onClick={(e) =>
              this.props.crudlControl(CrudlStatus.UPDATE, rowData)
            }
            style={{}}
          />
          {/* <pr.Button
            type="button"
            icon="pi pi-trash"
            onClick={(e) => {
              this.props.crudlControl(CrudlStatus.DELETE, rowData);
            }}
            style={{ backgroundColor: "red" }}
          /> */}
        </div>
      </div>
    );
  };
  contact = (rowData, column) => {
    return (
      <div className="p-grid">
        <div className="p-col-2"> </div>
        <div className="p-col-8">
          <center>
            <pr.Button
              type="button"
              label={"Contact"}
              onClick={(e) => this.props.setUpApplication(rowData)}
            />
          </center>
        </div>
      </div>
    );
  };
  getDisplayCard = (data) => {
    return <ProductDisplay data={data} onClick={this.props.crudlControl} />;
  };
}
