import { BaseViewModel } from "../../common/BaseViewModel";
import * as Format from "../../common/FormatFunctions";
import { CloudService } from "../../common/CloudService/ClientLibsReference";
import * as ApiService from "../../common/CloudService/ApiService";
import {
  CrudlStatus,
  ViewState,
  ProfileItemQuestions,
} from "../../common/config/AppConstants";
export class ProfileItemViewModel extends BaseViewModel {
  getProfileItems = async (profile) => {
    //CRITICAL - ERROR + EMPTY MESSAGE
    var profileitemResponse = await this.getProfileItemListByCodeTag();
    if (
      Format.isNotNull(profileitemResponse.errorCode) &&
      profileitemResponse.errorCode === 0
    ) {
      let profileItemList = [];
      let defaultList = this.setProfileItemDefaultList();

      if (Format.isNotNull(profileitemResponse.profileItemList)) {
        let profileId = profile;
        defaultList.forEach((def) => {
          let defaultReturned = false;
          profileitemResponse.profileItemList.forEach((existing) => {
            profileId = existing.profileId;
            if (def.question === existing.question) {
              existing.crudlState = CrudlStatus.UPDATE.value;
              defaultReturned = true;
              profileItemList.push(existing);
            }
          });
          if (!defaultReturned) {
            let obj = Format.deepCopy(def);
            obj.profileId = profileId;
            profileItemList.push(obj);
          }
        });

        this.updateState("profileItemList", profileItemList);
      } else {
        defaultList.forEach((e) => {
          e.profileId = profile;
        });
        console.log(defaultList);
        this.updateState("profileItemList", defaultList);
      }
    } else {
      this.showError("ProfileItem", CrudlStatus.LIST, ViewState.TABLE);
    }
  };

  getProfileItemsReturned = async () => {
    //CRITICAL - ERROR + EMPTY MESSAGE
    var profileitemResponse = await this.getProfileItemListByCodeTag();
    if (
      Format.isNotNull(profileitemResponse.errorCode) &&
      profileitemResponse.errorCode === 0
    ) {
      if (Format.isNotNull(profileitemResponse.profileItemList)) {
        this.logger.info(profileitemResponse.profileItemList);
        return profileitemResponse.profileItemList;
      } else {
        this.showEmpty("ProfileItem", ViewState.TABLE);
      }
    } else {
      this.showError("ProfileItem", CrudlStatus.LIST, ViewState.TABLE);
    }
  };

  reloadDataAfterExecute = (
    profileitems,
    apiResponse,
    type,
    externalStateValues
  ) => {
    var updatedProfileItemList = [];
    if (type === CrudlStatus.CREATE) {
      updatedProfileItemList = this.reloadAfterCreate(
        profileitems,
        apiResponse,
        externalStateValues
      );
    }
    if (type === CrudlStatus.UPDATE) {
      updatedProfileItemList = this.reloadAfterUpdate(
        profileitems,
        apiResponse,
        externalStateValues
      );
    }
    if (type === CrudlStatus.DELETE) {
      updatedProfileItemList = this.reloadAfterDelete(
        profileitems,
        apiResponse,
        externalStateValues
      );
    }

    this.updateState("profileItemList", updatedProfileItemList);
  };

  reloadAfterCreate = (profileItemList, apiResponse, externalStateValues) => {
    if (profileItemList === undefined || profileItemList === null) {
      profileItemList = [];
    }
    var profileitemBean = externalStateValues.profileitemBean;
    profileitemBean.id = apiResponse.id;
    profileitemBean.lastUpdatedDateTime = apiResponse.lastUpdatedDateTime;

    profileItemList.push(profileitemBean);

    return profileItemList;
  };

  reloadAfterUpdate = (profileItemList, apiResponse, externalStateValues) => {
    var i = 0;
    profileItemList.forEach((e) => {
      if (apiResponse.id === e.id) {
        profileItemList[i] = externalStateValues.profileitemBean;
        profileItemList[i].lastUpdatedDateTime =
          apiResponse.lastUpdatedDateTime;
      }
      i++;
    });

    return profileItemList;
  };

  reloadAfterDelete = (profileItemList, apiResponse, externalStateValues) => {
    var i = 0;
    profileItemList.forEach((e) => {
      //DELETE
      if (apiResponse.id === e.id) {
        profileItemList.splice(i, 1);
      }
      i++;
    });

    return profileItemList;
  };
  executeCRUDLAction = async (crudlStatus, stateVariables) => {
    let profileitemRequest =
      CloudService.ProfileItemRequest.constructFromObject(
        stateVariables.profileitemBean
      );
    if (crudlStatus === CrudlStatus.CREATE) {
      return await this.createProfileItem(profileitemRequest, stateVariables);
    }
    if (crudlStatus === CrudlStatus.UPDATE) {
      return await this.updateProfileItem(profileitemRequest, stateVariables);
    }
    if (crudlStatus === CrudlStatus.DELETE) {
      return await this.deleteProfileItem(profileitemRequest);
    }
  };

  createProfileItem = async (profileitemRequest, stateVariables) => {
    profileitemRequest.codeTag = this.codeTag;
    profileitemRequest.userId = this.userId;

    let profileitemResponse = await ApiService.profileItemCreate(
      profileitemRequest
    );
    if (profileitemResponse.errorCode === 0) {
      this.showSuccess(
        "ProfileItem",
        CrudlStatus.CREATE,
        stateVariables.viewState
      );
    } else {
      this.showError("ProfileItem", CrudlStatus.CREATE, ViewState.CARD);
    }

    return profileitemResponse;
  };

  deleteProfileItem = async (profileitemRequest) => {
    //RESET PRODUCT REQUEST
    var request = {};
    request.codeTag = this.codeTag;
    request.userId = this.userId;
    request.id = profileitemRequest.id;
    request.lastUpdatedDateTime = profileitemRequest.lastUpdatedDateTime;

    let profileitemResponse = await ApiService.profileItemDelete(request);
    if (profileitemResponse.errorCode === 0) {
      this.showSuccess("ProfileItem", CrudlStatus.DELETE, ViewState.TABLE);
    } else {
      this.showError("ProfileItem", CrudlStatus.DELETE, ViewState.CARD);
    }

    return profileitemResponse;
  };

  updateProfileItem = async (profileitemRequest, stateVariables) => {
    profileitemRequest.codeTag = this.codeTag;
    profileitemRequest.userId = this.userId;

    let profileitemResponse = await ApiService.profileItemUpdate(
      profileitemRequest
    );
    if (profileitemResponse.errorCode === 0) {
      this.showSuccess(
        "ProfileItem",
        CrudlStatus.UPDATE,
        stateVariables.viewState
      );
    } else {
      this.showError("ProfileItem", CrudlStatus.UPDATE, ViewState.CARD);
    }

    return profileitemResponse;
  };

  setProfileItemDefaultList = () => {
    let defaultProfileItemList = [];

    let questionList = ProfileItemQuestions;
    let questionCount = 1;

    for (const question in questionList) {
      if (questionList[question].includes("General")) {
        defaultProfileItemList.push(
          this.getObjectForProfileList(
            "General",
            1,
            questionList[question],
            questionCount
          )
        );
      } else if (questionList[question].includes("Quality")) {
        defaultProfileItemList.push(
          this.getObjectForProfileList(
            "Quality",
            2,
            questionList[question],
            questionCount
          )
        );
      } else if (questionList[question].includes("Cost")) {
        defaultProfileItemList.push(
          this.getObjectForProfileList(
            "Cost",
            3,
            questionList[question],
            questionCount
          )
        );
      } else if (questionList[question].includes("Logistics")) {
        defaultProfileItemList.push(
          this.getObjectForProfileList(
            "Logistics",
            4,
            questionList[question],
            questionCount
          )
        );
      } else if (questionList[question].includes("Development")) {
        defaultProfileItemList.push(
          this.getObjectForProfileList(
            "Development",
            5,
            questionList[question],
            questionCount
          )
        );
      } else if (questionList[question].includes("Management")) {
        defaultProfileItemList.push(
          this.getObjectForProfileList(
            "Management",
            6,
            questionList[question],
            questionCount
          )
        );
      }
      questionCount++;
    }

    this.updateState("defaultProfileItemList", defaultProfileItemList);
    return defaultProfileItemList;
  };

  getObjectForProfileList = (
    section,
    sectionNumber,
    question,
    questionNumber
  ) => {
    let obj = {};
    obj.codeTag = this.codeTag;
    obj.userId = this.userId;
    obj.profileId = "";
    obj.sectionName = section;
    obj.sectionNumber = sectionNumber;
    obj.question = question;
    obj.questionNumber = questionNumber;
    obj.responseString = "";
    obj.crudlState = CrudlStatus.CREATE.value;

    return obj;
  };
}
