import React from "react";
import { RequirementController } from "./RequirementController";
import { RequirementViewModel } from "./RequirementViewModel";
import * as feedback from "../../common/Feedback/Feedback";
import * as log from "../../common/Feedback/Log";

export class RequirementProvider extends React.Component {
  constructor(props) {
    super(props);
    this.viewModel = new RequirementViewModel();
    this.logger = log;
    this.feedback = feedback;

    console.log(props);
  }

  render() {
    return (
      <RequirementController
        viewModel={this.viewModel}
        log={this.logger}
        feedback={this.feedback}
        toggleTopbar={this.props.toggleTopbar}
      />
    );
  }
}
