import React, { Component } from "react";
import * as pr from "../../common/PrimeComponents";
import * as Log from "../../common/Feedback/Log";

export class Notes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: null,
      errors: [],
      loading: false,
    };

    this.logger = Log;
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.value !== this.props.value) {
      if (prevProps.id !== this.props.id) {
        this.setState({ loading: !this.state.loading });
      }
    } else {
    }
  };
  saveValue = (value) => {
    var valueToSave = value;
    this.props.updateValue(this.props.field, valueToSave);
  };

  render() {
    const header = (
      <span className="ql-formats">
        <button className="ql-bold" aria-label="Bold"></button>
        <button className="ql-italic" aria-label="Italic"></button>
        <button className="ql-underline" aria-label="Underline"></button>
        <button
          className="ql-list"
          value="ordered"
          aria-label="NumberedList"
        ></button>
        <button
          className="ql-list"
          value="bullet"
          aria-label="BulletPoints"
        ></button>
        <button className="ql-link" aria-label="Link"></button>
      </span>
    );

    return (
      <React.Fragment>
        <label className="input-label">{this.props.placeholder}</label>

        {!this.state.loading && (
          <pr.Editor
            className={this.props.value ? "" : "ui-state-error"}
            required={true}
            value={this.props.value || ""}
            onTextChange={(e) => this.saveValue(e.htmlValue)}
            disabled={this.props.disabled}
            style={{ height: "50vh" }}
            headerTemplate={header}
          />
        )}
        {this.state.loading && (
          <pr.Editor
            className={this.props.value ? "" : "ui-state-error"}
            required={true}
            value={this.props.value || ""}
            onTextChange={(e) => this.saveValue(e.htmlValue)}
            disabled={this.props.disabled}
            style={{ height: "50vh" }}
            headerTemplate={header}
          />
        )}
      </React.Fragment>
    );
  }
}
