import { BaseViewModel } from "../../common/BaseViewModel";
import * as Format from "../../common/FormatFunctions";

import { CrudlStatus, ViewState } from "../../common/config/AppConstants";
import { CloudService } from "../../common/CloudService/ClientLibsReference";
import { api } from "../../common/CloudService/ApiPromisify";
import * as pr from "../../common/PrimeComponents";
export class AvailableViewModel extends BaseViewModel {
  showLoadingScreen = () => {
    this.updateState("loaded", false);
  };

  hideLoadingScreen = () => {
    this.updateState("loaded", true);
  };

  getPossibleComponents = async () => {
    let componentRequest = new CloudService.ComponentRequest();
    componentRequest.codeTag = this.codeTag;
    componentRequest.userId = this.userId;

    componentRequest.type = "PPE";

    await api("componentApiV1ComponentGetByType", componentRequest).then(
      (res) => {
        if (res.data) {
          let componentResponse =
            CloudService.ComponentResponse.constructFromObject(res.data);
          console.log(componentResponse);
          this.updateState(
            "componentList",
            pr.getDropdownOptions(componentResponse.componentList)
          );
        }
      }
    );
  };

  getAvailables = async () => {
    let productRequest = new CloudService.ProductRequest();
    productRequest.codeTag = this.codeTag;
    productRequest.userId = this.userId;

    productRequest.type = "PPESupplier";

    await api("productApiV1ProductGetByType", productRequest).then((res) => {
      console.log(res);
      if (res.data) {
        let productResponse = CloudService.ProductResponse.constructFromObject(
          res.data
        );
        console.log(productResponse);
        if (
          Format.isNotNull(productResponse.errorCode) &&
          productResponse.errorCode === 0
        ) {
          if (Format.isNotNull(productResponse.productList)) {
            this.updateState("availableList", productResponse.productList);
            this.logger.info(productResponse.productList);
          } else {
            this.showEmpty("Available", ViewState.TABLE);
          }
        } else {
          this.showError("Available", CrudlStatus.LIST, ViewState.TABLE);
        }
      }
    });
  };
  getAvailablesReturned = async () => {
    let productRequest = new CloudService.ProductRequest();
    productRequest.codeTag = this.codeTag;
    productRequest.userId = this.userId;

    productRequest.type = "PPESupplier";

    await api("productApiV1ProductGetByType", productRequest).then((res) => {
      console.log(res);
      if (res.data) {
        let productResponse = CloudService.ProductResponse.constructFromObject(
          res.data
        );
        console.log(productResponse);
        if (
          Format.isNotNull(productResponse.errorCode) &&
          productResponse.errorCode === 0
        ) {
          if (Format.isNotNull(productResponse.productList)) {
            this.updateState("availableList", productResponse.productList);
            return productResponse.productList;
          } else {
            this.showEmpty("Available", ViewState.TABLE);
          }
        } else {
          this.showError("Available", CrudlStatus.LIST, ViewState.TABLE);
        }
      }
    });
  };

  reloadDataAfterExecute = (
    availables,
    apiResponse,
    type,
    externalStateValues
  ) => {
    var updatedAvailableList = [];
    if (type === CrudlStatus.CREATE) {
      updatedAvailableList = this.reloadAfterCreate(
        availables,
        apiResponse,
        externalStateValues
      );
    }
    if (type === CrudlStatus.UPDATE) {
      updatedAvailableList = this.reloadAfterUpdate(
        availables,
        apiResponse,
        externalStateValues
      );
    }
    if (type === CrudlStatus.DELETE) {
      updatedAvailableList = this.reloadAfterDelete(
        availables,
        apiResponse,
        externalStateValues
      );
    }

    this.updateState("availableList", updatedAvailableList);
  };

  reloadAfterCreate = (availableList, apiResponse, externalStateValues) => {
    if (availableList === undefined || availableList === null) {
      availableList = [];
    }
    var availableBean = externalStateValues.availableBean;
    availableBean.id = apiResponse.id;
    availableBean.lastUpdatedDateTime = apiResponse.lastUpdatedDateTime;

    availableList.push(availableBean);

    return availableList;
  };

  reloadAfterUpdate = (availableList, apiResponse, externalStateValues) => {
    var i = 0;
    availableList.forEach((e) => {
      if (apiResponse.id === e.id) {
        availableList[i] = externalStateValues.availableBean;
        availableList[i].lastUpdatedDateTime = apiResponse.lastUpdatedDateTime;
      }
      i++;
    });

    return availableList;
  };

  reloadAfterDelete = (availableList, apiResponse, externalStateValues) => {
    var i = 0;
    availableList.forEach((e) => {
      //DELETE
      if (apiResponse.id === e.id) {
        availableList.splice(i, 1);
      }
      i++;
    });

    return availableList;
  };
  executeCRUDLAction = async (crudlStatus, stateVariables) => {
    let availableRequest = stateVariables.availableBean;

    if (crudlStatus === CrudlStatus.CREATE) {
      return await this.createAvailable(availableRequest, stateVariables);
    }
    if (crudlStatus === CrudlStatus.UPDATE) {
      return await this.updateAvailable(availableRequest, stateVariables);
    }
    if (crudlStatus === CrudlStatus.DELETE) {
      return await this.deleteAvailable(availableRequest);
    }
  };

  createAvailable = async (availableRequest, stateVariables) => {
    availableRequest.codeTag = this.codeTag;
    availableRequest.userId = this.userId;

    console.log(availableRequest);
    var applicationResponse = {};

    await api("applicationApiV1ApplicationCreate", availableRequest).then(
      (res) => {
        if (res.data) {
          applicationResponse =
            CloudService.ApplicationResponse.constructFromObject(res.data);
          console.log(applicationResponse);
          if (
            Format.isNotNull(applicationResponse.errorCode) &&
            applicationResponse.errorCode === 0
          ) {
            this.showSuccess(
              "Application",
              CrudlStatus.CREATE,
              stateVariables.viewState
            );
          } else {
            this.showError("Application", CrudlStatus.CREATE, ViewState.CARD);
          }
        }
      }
    );

    return applicationResponse;
  };

  deleteAvailable = async (availableRequest) => {
    //RESET PRODUCT REQUEST
    // var request = {};
    // request.codeTag = this.codeTag;
    // request.userId = this.userId;
    // request.id = availableRequest.id;
    // request.lastUpdatedDateTime = availableRequest.lastUpdatedDateTime;
    // let availableResponse = await ApiService.availableDelete(request);
    // if (availableResponse.errorCode === 0) {
    //   this.showSuccess("Available", CrudlStatus.DELETE, ViewState.TABLE);
    // } else {
    //   this.showError("Available", CrudlStatus.DELETE, ViewState.CARD);
    // }
    // return availableResponse;
  };

  updateAvailable = async (availableRequest, stateVariables) => {
    // availableRequest.codeTag = this.codeTag;
    // availableRequest.userId = this.userId;
    // let availableResponse = await ApiService.availableUpdate(availableRequest);
    // if (availableResponse.errorCode === 0) {
    //   this.showSuccess(
    //     "Available",
    //     CrudlStatus.UPDATE,
    //     stateVariables.viewState
    //   );
    // } else {
    //   this.showError("Available", CrudlStatus.UPDATE, ViewState.CARD);
    // }
    // return availableResponse;
  };
}
