import { BaseViewModel } from "../../common/BaseViewModel";
import * as Format from "../../common/FormatFunctions";
import { CloudService } from "../../common/CloudService/ClientLibsReference";
import * as ApiService from "../../common/CloudService/ApiService";
import { CrudlStatus, ViewState } from "../../common/config/AppConstants";
export class BusinessIndustryViewModel extends BaseViewModel {
  getBusinessIndustrys = async () => {
    //CRITICAL - ERROR + EMPTY MESSAGE
    var businessIndustryResponse =
      await this.getBusinessIndustryListByCodeTag();
    if (
      Format.isNotNull(businessIndustryResponse.errorCode) &&
      businessIndustryResponse.errorCode === 0
    ) {
      if (Format.isNotNull(businessIndustryResponse.businessIndustryList)) {
        this.updateState(
          "businessIndustryList",
          businessIndustryResponse.businessIndustryList
        );
        this.logger.info(businessIndustryResponse.businessIndustryList);
      } else {
        this.showEmpty("BusinessIndustry", ViewState.TABLE);
      }
    } else {
      this.showError("BusinessIndustry", CrudlStatus.LIST, ViewState.TABLE);
    }
  };

  getBusinessIndustrysReturned = async () => {
    //CRITICAL - ERROR + EMPTY MESSAGE
    var businessIndustryResponse =
      await this.getBusinessIndustryListByCodeTag();
    if (
      Format.isNotNull(businessIndustryResponse.errorCode) &&
      businessIndustryResponse.errorCode === 0
    ) {
      if (Format.isNotNull(businessIndustryResponse.businessIndustryList)) {
        this.logger.info(businessIndustryResponse.businessIndustryList);
        return businessIndustryResponse.businessIndustryList;
      } else {
        this.showEmpty("BusinessIndustry", ViewState.TABLE);
      }
    } else {
      this.showError("BusinessIndustry", CrudlStatus.LIST, ViewState.TABLE);
    }
  };

  reloadDataAfterExecute = (
    businessindustrys,
    apiResponse,
    type,
    externalStateValues
  ) => {
    var updatedBusinessIndustryList = [];
    if (type === CrudlStatus.CREATE) {
      updatedBusinessIndustryList = this.reloadAfterCreate(
        businessindustrys,
        apiResponse,
        externalStateValues
      );
    }
    if (type === CrudlStatus.UPDATE) {
      updatedBusinessIndustryList = this.reloadAfterUpdate(
        businessindustrys,
        apiResponse,
        externalStateValues
      );
    }
    if (type === CrudlStatus.DELETE) {
      updatedBusinessIndustryList = this.reloadAfterDelete(
        businessindustrys,
        apiResponse,
        externalStateValues
      );
    }

    this.updateState("businessIndustryList", updatedBusinessIndustryList);
  };

  reloadAfterCreate = (
    businessIndustryList,
    apiResponse,
    externalStateValues
  ) => {
    if (businessIndustryList === undefined || businessIndustryList === null) {
      businessIndustryList = [];
    }
    var businessindustryBean = externalStateValues.businessindustryBean;
    businessindustryBean.id = apiResponse.id;
    businessindustryBean.lastUpdatedDateTime = apiResponse.lastUpdatedDateTime;

    businessIndustryList.push(businessindustryBean);

    return businessIndustryList;
  };

  reloadAfterUpdate = (
    businessIndustryList,
    apiResponse,
    externalStateValues
  ) => {
    var i = 0;
    businessIndustryList.forEach((e) => {
      if (apiResponse.id === e.id) {
        businessIndustryList[i] = externalStateValues.businessindustryBean;
        businessIndustryList[i].lastUpdatedDateTime =
          apiResponse.lastUpdatedDateTime;
      }
      i++;
    });

    return businessIndustryList;
  };

  reloadAfterDelete = (
    businessIndustryList,
    apiResponse,
    externalStateValues
  ) => {
    var i = 0;
    businessIndustryList.forEach((e) => {
      //DELETE
      if (apiResponse.id === e.id) {
        businessIndustryList.splice(i, 1);
      }
      i++;
    });

    return businessIndustryList;
  };
  executeCRUDLAction = async (crudlStatus, stateVariables) => {
    let businessindustryRequest =
      CloudService.BusinessIndustryRequest.constructFromObject(
        stateVariables.businessindustryBean
      );
    if (crudlStatus === CrudlStatus.CREATE) {
      return await this.createBusinessIndustry(
        businessindustryRequest,
        stateVariables
      );
    }
    if (crudlStatus === CrudlStatus.UPDATE) {
      return await this.updateBusinessIndustry(
        businessindustryRequest,
        stateVariables
      );
    }
    if (crudlStatus === CrudlStatus.DELETE) {
      return await this.deleteBusinessIndustry(businessindustryRequest);
    }
  };

  createBusinessIndustry = async (businessindustryRequest, stateVariables) => {
    businessindustryRequest.codeTag = this.codeTag;
    businessindustryRequest.userId = this.userId;

    let businessIndustryResponse = await ApiService.businessIndustryCreate(
      businessindustryRequest
    );
    if (businessIndustryResponse.errorCode === 0) {
      this.showSuccess(
        "BusinessIndustry",
        CrudlStatus.CREATE,
        stateVariables.viewState
      );
    } else {
      this.showError("BusinessIndustry", CrudlStatus.CREATE, ViewState.CARD);
    }

    return businessIndustryResponse;
  };

  deleteBusinessIndustry = async (businessindustryRequest) => {
    //RESET PRODUCT REQUEST
    var request = {};
    request.codeTag = this.codeTag;
    request.userId = this.userId;
    request.id = businessindustryRequest.id;
    request.lastUpdatedDateTime = businessindustryRequest.lastUpdatedDateTime;

    let businessIndustryResponse = await ApiService.businessIndustryDelete(
      request
    );
    if (businessIndustryResponse.errorCode === 0) {
      this.showSuccess("BusinessIndustry", CrudlStatus.DELETE, ViewState.TABLE);
    } else {
      this.showError("BusinessIndustry", CrudlStatus.DELETE, ViewState.CARD);
    }

    return businessIndustryResponse;
  };

  updateBusinessIndustry = async (businessindustryRequest, stateVariables) => {
    businessindustryRequest.codeTag = this.codeTag;
    businessindustryRequest.userId = this.userId;

    let businessIndustryResponse = await ApiService.businessIndustryUpdate(
      businessindustryRequest
    );
    if (businessIndustryResponse.errorCode === 0) {
      this.showSuccess(
        "BusinessIndustry",
        CrudlStatus.UPDATE,
        stateVariables.viewState
      );
    } else {
      this.showError("BusinessIndustry", CrudlStatus.UPDATE, ViewState.CARD);
    }

    return businessIndustryResponse;
  };
}
