import React from "react";
import { RequiredCard } from "./UI/RequiredCard";
import { RequiredTable } from "./UI/RequiredTable";
import { ValidateState } from "../../common/config/AppConstants";
import { ViewState } from "../../common/config/AppConstants";
import * as pr from "../../common/PrimeComponents";

import { MVVMValidateDialog } from "../Common/MVVMValidateDialog";
export class RequiredView extends React.Component {
  render() {
    return (
      <div
        className="dashboard"
        style={this.props.loading === true ? { pointerEvents: "none" } : {}}
      >
        {this.props.viewState === ViewState.CARD && (
          <React.Fragment>
            <pr.Sidebar
              visible={true}
              position="right"
              fullScreen={this.props.sidebarWidth === "100%" ? true : false}
              onHide={() => {
                this.props.validateChanges();
              }}
              baseZIndex={10000000000}
              style={{
                width: this.props.sidebarWidth,
              }}
            >
              <MVVMValidateDialog
                header="Required"
                isVisible={this.props.validateState === ValidateState.CONFIRM}
                visibleField="validateState"
                updateVisibility={this.props.updateState}
                runConfirm={this.props.discardChanges}
                message={"Discard Changes?"}
              />
              <MVVMValidateDialog
                header="Delete Required"
                isVisible={this.props.validateState === ValidateState.DELETE}
                visibleField="validateState"
                updateVisibility={this.props.updateState}
                message={" Are you sure you want to delete?"}
                type={"CRUDL"}
                crudlExecute={this.props.crudlExecute}
                crudlState={this.props.crudlState}
              />
              <RequiredCard
                loading={this.props.loading}
                feedback={this.props.feedback}
                receivedFeedback={this.props.receivedFeedback}
                crudlState={this.props.crudlState}
                crudlControl={this.props.crudlControl}
                updateValue={this.props.updateEdited}
                updateState={this.props.updateState}
                editedObject={this.props.editedObject}
                checkRequiredFields={this.props.checkRequiredFields}
                discardChanges={this.props.discardChanges}
                validateState={this.props.validateState}
                validateChanges={this.props.validateChanges}
                crudlExecute={this.props.crudlExecute}
              />
            </pr.Sidebar>
            <div className="custom-overlay" />
          </React.Fragment>
        )}

        <RequiredTable
          //CUSTOMISATION
          tableReference={this.props.tableReference}
          tableColumns={this.props.tableColumns}
          columnOptions={this.props.columnOptions}
          columnToggle={this.props.columnToggle}
          expandedRows={this.props.expandedRows}
          rowCount={this.props.rowCount}
          settingsToggle={this.props.settingsToggle}
          //
          updateState={this.props.updateState}
          requiredList={this.props.requiredList}
          crudlControl={this.props.crudlControl}
          validateState={this.props.validateState}
          menuState={this.props.menuState}
          crudlState={this.props.crudlState}
          loading={this.props.loading}
          refresh={this.props.refresh}
          productDetails={this.props.productDetails}
          productDetailsView={this.props.productDetailsView}
          globalFilter={this.props.globalFilter}
        />
      </div>
    );
  }
}
