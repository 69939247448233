import { BaseViewModel } from "../../common/BaseViewModel";
import * as Format from "../../common/FormatFunctions";
import {
  CrudlStatus,
  ViewState,
  MatrixType,
} from "../../common/config/AppConstants";
export class DashboardViewModel extends BaseViewModel {
  getProfile = async () => {
    let request = {};
    request.codeTag = this.codeTag;
    var profileResponse = await this.profileGetByCodeTag(request);
    if (
      Format.isNotNull(profileResponse.errorCode) &&
      profileResponse.errorCode === 0
    ) {
      if (Format.isNotNull(profileResponse.profileList)) {
        // let businessComponentList = [];
        // profileResponse.profileList.forEach((element) => {
        //   if (
        //     element.type === BusinessType.BUYER ||
        //     element.type === BusinessType.SUPPLIER ||
        //     element.type === BusinessType.BUYERSUPPLIER
        //   ) {
        //     businessComponentList = element.componentList;
        //   }
        // });

        return profileResponse;
      } else {
        this.logger.info("Profile is empty");
      }
    } else {
      this.logger.info("Profile api failed");
    }
  };

  getApplicationsByCodeTag = async () => {
    //CRITICAL - ERROR + EMPTY MESSAGE
    var applicationResponse = await this.getApplicationListByCodeTag();
    if (
      Format.isNotNull(applicationResponse.errorCode) &&
      applicationResponse.errorCode === 0
    ) {
      if (Format.isNotNull(applicationResponse.applicationList)) {
        this.updateState(
          "applicationList",
          applicationResponse.applicationList
        );
        this.logger.info(applicationResponse.applicationList);
        return applicationResponse.applicationList;
      } else {
        // this.showEmpty("Dashboard", ViewState.TABLE);
      }
    } else {
      this.showError("Application", CrudlStatus.LIST, ViewState.TABLE);
    }
  };

  getContactsByCodeTag = async () => {
    //CRITICAL - ERROR + EMPTY MESSAGE
    var contactResponse = await this.getContactListByCodeTag();
    if (
      Format.isNotNull(contactResponse.errorCode) &&
      contactResponse.errorCode === 0
    ) {
      if (Format.isNotNull(contactResponse.contactList)) {
        this.updateState("contactList", contactResponse.contactList);
        this.logger.info(contactResponse.contactList);
      } else {
        // this.showEmpty("Contact", ViewState.TABLE);
      }
    } else {
      this.showError("Contact", CrudlStatus.LIST, ViewState.TABLE);
    }
  };

  getAllProfiles = async () => {
    let request = {};
    request.codeTag = localStorage.getItem("globalBid");
    request.userId = this.userId;
    var profileResponse = await this.profileListAll(request);
    if (
      Format.isNotNull(profileResponse.errorCode) &&
      profileResponse.errorCode === 0
    ) {
      if (Format.isNotNull(profileResponse.profileList)) {
        return profileResponse.profileList;
      } else {
        return [];
      }
    } else {
      this.logger.info("Failed to get Profiles");
    }
  };

  getAllApplications = async () => {
    let request = {};
    request.codeTag = localStorage.getItem("globalBid");
    request.userId = this.userId;
    var applicationResponse = await this.applicationListAll(request);
    if (
      Format.isNotNull(applicationResponse.errorCode) &&
      applicationResponse.errorCode === 0
    ) {
      if (Format.isNotNull(applicationResponse.applicationList)) {
        return applicationResponse.applicationList;
      } else {
        return [];
      }
    } else {
      this.showError("Application", CrudlStatus.LIST, ViewState.TABLE);
    }
  };

  getAllMatrixAnalysis = async () => {
    let overall = await this.getMatrixMostRecentByMatrixType(
      MatrixType.OVERALL
    );
    let buyer = await this.getMatrixMostRecentByMatrixType(MatrixType.BUYER);
    let supplier = await this.getMatrixMostRecentByMatrixType(
      MatrixType.SUPPLIER
    );

    this.updateState("gapAnalysisListOverall", overall);
    this.updateState("gapAnalysisListBuyer", buyer);
    this.updateState("gapAnalysisListSupplier", supplier);

    this.extractLatestMatrixPdf(overall, "gapAnalysisOverall");
    this.extractLatestMatrixPdf(buyer, "gapAnalysisBuyer");
    this.extractLatestMatrixPdf(supplier, "gapAnalysisSupplier");
  };

  extractLatestMatrixPdf = (matrixArray, field) => {
    if (Format.isNotNull(matrixArray)) {
      matrixArray.forEach((e) => {
        if (e.archived === false) {
          this.updateState(field, e.pdfUrl);
        }
      });
    }
  };

  getMatrixByType = async (type) => {
    let request = {};
    request.codeTag = localStorage.getItem("globalBid");
    request.userId = this.userId;
    request.type = type;
    var matrixResponse = await this.getMatrixListByMatrixType(request);
    if (
      Format.isNotNull(matrixResponse.errorCode) &&
      matrixResponse.errorCode === 0
    ) {
      if (Format.isNotNull(matrixResponse.matrixList)) {
        return matrixResponse.matrixList;
      } else {
        return [];
      }
    } else {
      this.showError("Matrix", CrudlStatus.LIST, ViewState.TABLE);
    }
  };

  getMatrixMostRecentByMatrixType = async (type) => {
    let request = {};
    request.codeTag = localStorage.getItem("globalBid");
    request.userId = this.userId;
    request.matrixType = type;
    var matrixResponse = await this.getMatrixListMostRecentByMatrixType(
      request
    );
    if (
      Format.isNotNull(matrixResponse.errorCode) &&
      matrixResponse.errorCode === 0
    ) {
      if (Format.isNotNull(matrixResponse.matrixList)) {
        return matrixResponse.matrixList;
      } else {
        return [];
      }
    } else {
      this.showError("Matrix", CrudlStatus.LIST, ViewState.TABLE);
    }
  };
}
