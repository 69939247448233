import React from "react";
//PrimeReact Components
import {
  ViewType,
  ProfileStatus,
  BusinessType,
} from "../../../common/config/AppConstants";
import * as pr from "../../../common/PrimeComponents";
import * as format from "../../../common/FormatFunctions";
import { Input, InputType, LabelPosition } from "../../Common/Input";
import { ProductProvider } from "../../Product/ProductProvider";
import { UserProvider } from "../../User/UserProvider";
import * as analytics from "../../../common/helpers/firebaseAnalytics";

export class ProfileDisplay extends React.Component {
  componentListToCards = (array) => {
    let componentArray = [];
    let index = 0;
    if (format.isNotNull(array)) {
      array.forEach((component) => {
        componentArray[index] = (
          <div className="p-col-12" key={component.id}>
            <div className="p-grid card">
              <div className="p-col-1">
                <pr.Checkbox checked={true} />
              </div>
              <div className="p-col-5">
                <Input
                  id={"title"}
                  labelDisplay={"Title"}
                  labelDisplayPosition={LabelPosition.NONE}
                  required={false}
                  type={InputType.STRING}
                  disabled={true}
                  value={component.title}
                  field={"title"}
                  visible={true}
                />
              </div>
              <div className="p-col-6">
                <Input
                  id={"score"}
                  labelDisplay={"Interest"}
                  labelDisplayPosition={LabelPosition.NONE}
                  required={false}
                  type={InputType.STRING}
                  disabled={true}
                  value={component.score}
                  field={"score"}
                  visible={true}
                />
              </div>
            </div>
          </div>
        );
        index++;
      });
    } else {
      componentArray.push(<label>No categories selected</label>);
    }

    return componentArray;
  };

  runAnalytics = () => {
    if (this.props.viewType === ViewType.BUYER) {
      analytics.screenViewEvent(analytics.PageView.BUYER_PROFILE);
    } else if (this.props.viewType === ViewType.SUPPLIER) {
      analytics.screenViewEvent(analytics.PageView.SUPPLIER_PROFILE);
    } else if (this.props.viewType === ViewType.COUNCIL) {
      // analytics.screenViewEvent(analytics.PageView.BUYER_PROFILE);
    } else if (this.props.viewType === ViewType.DASHBOARD) {
      // analytics.screenViewEvent(analytics.PageView.BUYER_PROFILE);
    }
  };

  render() {
    var bean = this.props.data;
    if (!format.isNotNull(bean)) {
      return <div></div>;
    }
    this.runAnalytics();
    return (
      <div key={bean.id} className="p-col-12 card-round">
        <div className="p-grid" style={{ padding: "5px" }}>
          {this.props.viewType === ViewType.COUNCIL && (
            <div className="p-col-12 profile-card">
              <pr.Button
                icon="pi pi-times-circle"
                className="flt-r"
                label=" Reject"
                onClick={() => {
                  this.props.updateProfileStatus(
                    bean,
                    ProfileStatus.UNSUCCESSFUL
                  );
                }}
                style={{ backgroundColor: "rgb(231, 76, 60)" }}
              />
              <pr.Button
                icon="pi pi-minus-circle"
                className="flt-r"
                label=" Pending"
                onClick={() => {
                  this.props.updateProfileStatus(bean, ProfileStatus.PENDING);
                }}
                style={{ backgroundColor: "rgb(241, 196, 15)" }}
              />
              <pr.Button
                icon="pi pi-check-circle"
                className="flt-r"
                label=" Approve"
                onClick={() => {
                  this.props.updateProfileStatus(bean, ProfileStatus.APPROVED);
                }}
                style={{ backgroundColor: "rgb(46, 204, 11)" }}
              />
              <pr.Button
                icon="pi pi-pencil"
                className="flt-l"
                label="Edit Profile"
                onClick={() => {
                  this.props.editBusiness(bean);
                }}
                style={{ backgroundColor: "rgb(46, 204, 11)" }}
              />
            </div>
          )}
          <div className="p-col-12 profile-card">
            <div className="p-grid">
              <div className="p-col-6">
                <Input
                  id={"businessName"}
                  labelDisplay={"Business Name"}
                  labelDisplayPosition={LabelPosition.LEFT}
                  required={false}
                  type={InputType.STRING}
                  disabled={true}
                  value={bean.companyDetails.businessName}
                  field={"businessName"}
                  visible={true}
                />
              </div>
              <div className="p-col-6">
                <Input
                  id={"businessDescription"}
                  labelDisplay={"Business Description"}
                  labelDisplayPosition={LabelPosition.LEFT}
                  required={false}
                  type={InputType.STRING}
                  disabled={true}
                  value={bean.companyDetails.businessDescription}
                  field={"businessDescription"}
                  visible={true}
                />
              </div>
            </div>
          </div>
          <div className="p-col-12 profile-card">
            <div className="p-grid">
              <div className="p-col-6">
                <Input
                  id={"addressLine1"}
                  labelDisplay={"Address Line 1"}
                  labelDisplayPosition={LabelPosition.LEFT}
                  required={false}
                  type={InputType.STRING}
                  disabled={true}
                  value={bean.companyDetails.addressLine1}
                  field={"addressLine1"}
                  visible={true}
                />
                <Input
                  id={"city"}
                  labelDisplay={"City"}
                  labelDisplayPosition={LabelPosition.LEFT}
                  required={false}
                  type={InputType.STRING}
                  disabled={true}
                  value={bean.companyDetails.city}
                  field={"city"}
                  visible={true}
                />
                <Input
                  id={"postalZipCode"}
                  labelDisplay={"Postcode"}
                  labelDisplayPosition={LabelPosition.LEFT}
                  required={false}
                  type={InputType.STRING}
                  disabled={true}
                  value={bean.companyDetails.postalZipCode}
                  field={"postalZipCode"}
                  visible={true}
                />
                {this.props.viewType === ViewType.COUNCIL && (
                  <Input
                    id={"businessSize"}
                    labelDisplay={"Business Size"}
                    labelDisplayPosition={LabelPosition.LEFT}
                    required={false}
                    type={InputType.STRING}
                    disabled={true}
                    value={bean.companyDetails.businessSize}
                    field={"businessSize"}
                    visible={true}
                  />
                )}
              </div>
              <div className="p-col-6">
                <Input
                  id={"addressLine2"}
                  labelDisplay={"Address Line 2"}
                  labelDisplayPosition={LabelPosition.LEFT}
                  required={false}
                  type={InputType.STRING}
                  disabled={true}
                  value={bean.companyDetails.addressLine2}
                  field={"addressLine2"}
                  visible={true}
                />
                <Input
                  id={"countyState"}
                  labelDisplay={"County"}
                  labelDisplayPosition={LabelPosition.LEFT}
                  required={false}
                  type={InputType.STRING}
                  disabled={true}
                  value={bean.companyDetails.countyState}
                  field={"countyState"}
                  visible={true}
                />

                <Input
                  id={"demographicArea"}
                  labelDisplay={"Demographic Area"}
                  labelDisplayPosition={LabelPosition.LEFT}
                  required={false}
                  type={InputType.STRING}
                  disabled={true}
                  value={bean.companyDetails.demographicArea}
                  field={"demographicArea"}
                  visible={true}
                />
              </div>
            </div>
          </div>
          <div className="p-col-12 profile-card">
            <div className="p-grid">
              <div className="p-col-6">
                <Input
                  id={"email"}
                  labelDisplay={"Email"}
                  labelDisplayPosition={LabelPosition.LEFT}
                  required={false}
                  type={InputType.STRING}
                  disabled={true}
                  value={bean.companyDetails.email}
                  field={"email"}
                  visible={true}
                />
              </div>
              <div className="p-col-6">
                <Input
                  id={"telephoneNumber"}
                  labelDisplay={"Telephone"}
                  labelDisplayPosition={LabelPosition.LEFT}
                  required={false}
                  type={InputType.STRING}
                  disabled={true}
                  value={bean.companyDetails.telephoneNumber}
                  field={"telephoneNumber"}
                  visible={true}
                />
              </div>
            </div>
          </div>
          <div className="p-col-12">
            <pr.Accordion>
              <pr.AccordionTab header="Categories">
                <div className="p-grid">
                  <div className="p-col-12">
                    {this.componentListToCards(bean.componentDisplayList)}
                  </div>
                </div>
              </pr.AccordionTab>
            </pr.Accordion>
          </div>
          {this.props.viewType !== ViewType.COUNCIL ? (
            <div className="p-col-12">
              <pr.Accordion>
                <pr.AccordionTab header="Products">
                  <ProductProvider
                    viewType={ViewType.PROFILE}
                    codeTag={bean.codeTag}
                  />
                </pr.AccordionTab>
              </pr.Accordion>
            </div>
          ) : (
            <>
              {bean.type === BusinessType.BUYER && (
                <div className="p-col-12">
                  <pr.Accordion>
                    <pr.AccordionTab header="Requirements">
                      <ProductProvider
                        viewType={ViewType.MY_REQUIREMENT}
                        codeTag={bean.codeTag}
                      />
                    </pr.AccordionTab>
                  </pr.Accordion>
                </div>
              )}
              {bean.type === BusinessType.SUPPLIER && (
                <div className="p-col-12">
                  <pr.Accordion>
                    <pr.AccordionTab header="Products">
                      <ProductProvider
                        viewType={ViewType.MY_PRODUCT}
                        codeTag={bean.codeTag}
                      />
                    </pr.AccordionTab>
                  </pr.Accordion>
                </div>
              )}
              {bean.type === BusinessType.BUYERSUPPLIER && (
                <>
                  <div className="p-col-12">
                    <pr.Accordion>
                      <pr.AccordionTab header="Products">
                        <ProductProvider
                          viewType={ViewType.MY_PRODUCT}
                          codeTag={bean.codeTag}
                        />
                      </pr.AccordionTab>
                    </pr.Accordion>
                  </div>
                  <div className="p-col-12">
                    <pr.Accordion>
                      <pr.AccordionTab header="Requirements">
                        <ProductProvider
                          viewType={ViewType.MY_REQUIREMENT}
                          codeTag={bean.codeTag}
                        />
                      </pr.AccordionTab>
                    </pr.Accordion>
                  </div>
                </>
              )}
            </>
          )}

          {this.props.viewType === ViewType.COUNCIL && (
            <div className="p-col-12">
              <pr.Accordion>
                <pr.AccordionTab header="Users">
                  <UserProvider
                    // viewType={ViewType.PROFILE}
                    viewType={ViewType.MY_BUSINESS}
                    codeTag={bean.codeTag}
                  />
                </pr.AccordionTab>
              </pr.Accordion>
            </div>
          )}
        </div>
      </div>
    );
  }
}
