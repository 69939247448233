import React from "react";
import { ProfileCard } from "./UI/ProfileCard";
import { ProfileTable } from "./UI/ProfileTable";
import {
  ViewState,
  CrudlStatus,
  ValidateState,
  ViewType,
} from "../../common/config/AppConstants";
import * as pr from "../../common/PrimeComponents";
import * as format from "../../common/FormatFunctions";
import { ApplicationProvider } from "../Application/ApplicationProvider";

import { MVVMValidateDialog } from "../Common/MVVMValidateDialog";
import { BusinessProvider } from "../Business/BusinessProvider";
export class ProfileView extends React.Component {
  tutorialVideo = (title, source) => {
    return (
      <pr.Dialog
        header={title}
        footer={
          <pr.Button
            className="secondary-btn"
            label="Close"
            icon="pi pi-times"
            onClick={() => {
              this.props.updateState("showTutorial", false);
            }}
            style={{
              float: "right",
            }}
          />
        }
        visible={true}
        onHide={() => {
          this.props.updateState("showTutorial", false);
        }}
        modal={true}
        blockScroll={true}
        style={{ width: "80vw" }}
      >
        {/* <video width="100%" height="98%" controls>
          <source src={source} type="video/webm" />
          Your browser does not support video elements, please update to the
          latest version
        </video> */}
        <div>
          <center>
            <iframe
              src={source}
              allowFullScreen
              style={{
                position: "relative",
                top: 0,
                left: 0,
              }}
            ></iframe>
          </center>
        </div>
      </pr.Dialog>
    );
  };
  render() {
    return (
      <div
        className="dashboard"
        style={this.props.loading === true ? { pointerEvents: "none" } : {}}
      >
        {this.props.showTutorial &&
          this.props.viewType === ViewType.BUYER &&
          this.tutorialVideo(
            "View All Buyers Tutorial",
            // "/assets/layout/images/council/view-all-buyers.webm"
            "https://player.vimeo.com/video/582850523?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          )}
        {this.props.showTutorial &&
          this.props.viewType === ViewType.SUPPLIER &&
          this.tutorialVideo(
            "View All Suppliers Tutorial",
            // "/assets/layout/images/council/view-all-suppliers.webm"
            "https://player.vimeo.com/video/582850555?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          )}

        {this.props.toggleApplication && (
          <ApplicationProvider
            crudlState={CrudlStatus.CREATE}
            viewType={ViewType.CONTACT}
            editedObjectFromProps={this.props.productToContact} //product to contact about
            updateStateFromProps={this.props.updateState} // update state variable toggleApplication
            sidebarWidth={"60%"}
            dashboardType={this.props.dashboardType}
          />
        )}
        {this.props.toggleEditBusiness && (
          <React.Fragment>
            <pr.Sidebar
              visible={true}
              position="right"
              fullScreen={this.props.sidebarWidth === "100%" ? true : false}
              onHide={() => {
                this.props.updateState("toggleEditBusiness", false);
              }}
              baseZIndex={10000000000}
              style={{
                width: this.props.sidebarWidth,
                zIndex: 10000000000,
              }}
            >
              <BusinessProvider
                codeTag={this.props.selectedBusiness.codeTag}
                viewType={ViewType.COUNCIL}
                crudlState={CrudlStatus.UPDATE}
              />
            </pr.Sidebar>
            <div className="custom-overlay" />
          </React.Fragment>
        )}
        {this.props.viewState === ViewState.CARD && (
          <React.Fragment>
            <pr.Sidebar
              visible={true}
              position="right"
              fullScreen={this.props.sidebarWidth === "100%" ? true : false}
              onHide={() => {
                this.props.validateChanges();
              }}
              baseZIndex={10000000000}
              style={{
                width: this.props.sidebarWidth,
                zIndex: 10000000000,
              }}
              icons={() => (
                <div
                  className="p-col-12"
                  style={{
                    float: "left",
                    paddingLeft: "3rem",
                    fontSize: "initial",
                  }}
                >
                  <div className="p-grid">
                    <div className="p-col-6">
                      <label className="header-label">
                        {format.capitalize(this.props.crudlState.display) +
                          " Profile"}
                      </label>
                    </div>
                  </div>
                </div>
              )}
            >
              <MVVMValidateDialog
                header="Profile"
                isVisible={this.props.validateState === ValidateState.CONFIRM}
                visibleField="validateState"
                updateVisibility={this.props.updateState}
                runConfirm={this.props.discardChanges}
                message={"Discard Changes?"}
              />
              <MVVMValidateDialog
                header="Delete Profile"
                isVisible={this.props.validateState === ValidateState.DELETE}
                visibleField="validateState"
                updateVisibility={this.props.updateState}
                message={" Are you sure you want to delete?"}
                type={"CRUDL"}
                crudlExecute={this.props.crudlExecute}
                crudlState={this.props.crudlState}
              />
              <ProfileCard
                loading={this.props.loading}
                feedback={this.props.feedback}
                receivedFeedback={this.props.receivedFeedback}
                crudlState={this.props.crudlState}
                crudlControl={this.props.crudlControl}
                updateValue={this.props.updateEdited}
                updateState={this.props.updateState}
                editedObject={this.props.editedObject}
                checkRequiredFields={this.props.checkRequiredFields}
                discardChanges={this.props.discardChanges}
                validateState={this.props.validateState}
                validateChanges={this.props.validateChanges}
                crudlExecute={this.props.crudlExecute}
                viewType={this.props.viewType}
              />
            </pr.Sidebar>
            <div className="custom-overlay" />
          </React.Fragment>
        )}

        <ProfileTable
          //CUSTOMISATION
          tableReference={this.props.tableReference}
          tableColumns={this.props.tableColumns}
          columnOptions={this.props.columnOptions}
          columnToggle={this.props.columnToggle}
          expandedRows={this.props.expandedRows}
          settingsToggle={this.props.settingsToggle}
          //
          updateState={this.props.updateState}
          profileList={this.props.profileList}
          crudlControl={this.props.crudlControl}
          validateState={this.props.validateState}
          menuState={this.props.menuState}
          crudlState={this.props.crudlState}
          loading={this.props.loading}
          refresh={this.props.refresh}
          viewType={this.props.viewType}
          globalFilter={this.props.globalFilter}
          dashboardType={this.props.dashboardType}
          //council
          updateProfileStatus={this.props.updateProfileStatus}
          toggleApplicationCreate={this.props.toggleApplicationCreate}
          showTutorial={this.props.showTutorial}
          editBusiness={this.props.editBusiness}
        />
      </div>
    );
  }
}
