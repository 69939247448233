import { BaseViewModel } from "../../common/BaseViewModel";
import * as Format from "../../common/FormatFunctions";
import { CloudService } from "../../common/CloudService/ClientLibsReference";

import { CrudlStatus, ViewState } from "../../common/config/AppConstants";
import { api } from "../../common/CloudService/ApiPromisify";
import * as pr from "../../common/PrimeComponents";
export class RequiredViewModel extends BaseViewModel {
  showLoadingScreen = () => {
    this.updateState("loaded", false);
  };

  hideLoadingScreen = () => {
    this.updateState("loaded", true);
  };

  getPossibleComponents = async () => {
    let componentRequest = new CloudService.ComponentRequest();
    componentRequest.codeTag = this.codeTag;
    componentRequest.userId = this.userId;

    componentRequest.type = "PPE";

    await api("componentApiV1ComponentGetByType", componentRequest).then(
      (res) => {
        if (res.data) {
          let componentResponse =
            CloudService.ComponentResponse.constructFromObject(res.data);
          console.log(componentResponse);
          this.updateState(
            "componentList",
            pr.getDropdownOptions(componentResponse.componentList)
          );
        }
      }
    );
  };

  getProductListByCodeTag = async () => {
    var productRequest = {};
    productRequest.codeTag = localStorage.getItem("clientId");
    productRequest.userId = localStorage.getItem("userId");
    productRequest.type = this.type;

    await this.callApi(productRequest, "productApiV1ProductGetByType");
  };

  getRequireds = async () => {
    //CRITICAL - ERROR + EMPTY MESSAGE
    let productRequest = new CloudService.ProductRequest();
    productRequest.codeTag = this.codeTag;
    productRequest.userId = this.userId;

    productRequest.type = "PPEBuyer";

    await api("productApiV1ProductGetByType", productRequest).then((res) => {
      console.log(res);
      if (res.data) {
        let productResponse = CloudService.ProductResponse.constructFromObject(
          res.data
        );
        console.log(productResponse);
        if (
          Format.isNotNull(productResponse.errorCode) &&
          productResponse.errorCode === 0
        ) {
          if (Format.isNotNull(productResponse.productList)) {
            this.updateState("requiredList", productResponse.productList);
            this.logger.info(productResponse.productList);
          } else {
            this.showEmpty("Required", ViewState.TABLE);
          }
        } else {
          this.showError("Required", CrudlStatus.LIST, ViewState.TABLE);
        }
      }
    });
  };
  getRequiredsReturned = async () => {
    //CRITICAL - ERROR + EMPTY MESSAGE
    let productRequest = new CloudService.ProductRequest();
    productRequest.codeTag = this.codeTag;
    productRequest.userId = this.userId;

    productRequest.type = "PPEBuyer";

    await api("productApiV1ProductGetByType", productRequest).then((res) => {
      console.log(res);
      if (res.data) {
        let productResponse = CloudService.ProductResponse.constructFromObject(
          res.data
        );
        console.log(productResponse);
        if (
          Format.isNotNull(productResponse.errorCode) &&
          productResponse.errorCode === 0
        ) {
          if (Format.isNotNull(productResponse.productList)) {
            this.updateState("requiredList", productResponse.productList);
            return productResponse.productList;
          } else {
            this.showEmpty("Required", ViewState.TABLE);
          }
        } else {
          this.showError("Required", CrudlStatus.LIST, ViewState.TABLE);
        }
      }
    });
  };

  reloadDataAfterExecute = (
    requireds,
    apiResponse,
    type,
    externalStateValues
  ) => {
    var updatedProductList = [];
    if (type === CrudlStatus.CREATE) {
      updatedProductList = this.reloadAfterCreate(
        requireds,
        apiResponse,
        externalStateValues
      );
    }
    if (type === CrudlStatus.UPDATE) {
      updatedProductList = this.reloadAfterUpdate(
        requireds,
        apiResponse,
        externalStateValues
      );
    }
    if (type === CrudlStatus.DELETE) {
      updatedProductList = this.reloadAfterDelete(
        requireds,
        apiResponse,
        externalStateValues
      );
    }

    this.updateState("productList", updatedProductList);
  };

  reloadAfterCreate = (productList, apiResponse, externalStateValues) => {
    if (productList === undefined || productList === null) {
      productList = [];
    }
    var requiredBean = externalStateValues.requiredBean;
    requiredBean.id = apiResponse.id;
    requiredBean.lastUpdatedDateTime = apiResponse.lastUpdatedDateTime;

    productList.push(requiredBean);

    return productList;
  };

  reloadAfterUpdate = (productList, apiResponse, externalStateValues) => {
    var i = 0;
    productList.forEach((e) => {
      if (apiResponse.id === e.id) {
        productList[i] = externalStateValues.requiredBean;
        productList[i].lastUpdatedDateTime = apiResponse.lastUpdatedDateTime;
      }
      i++;
    });

    return productList;
  };

  reloadAfterDelete = (productList, apiResponse, externalStateValues) => {
    var i = 0;
    productList.forEach((e) => {
      //DELETE
      if (apiResponse.id === e.id) {
        productList.splice(i, 1);
      }
      i++;
    });

    return productList;
  };
  executeCRUDLAction = async (crudlStatus, stateVariables) => {
    let requiredRequest = stateVariables.requiredBean;
    if (crudlStatus === CrudlStatus.CREATE) {
      return await this.createRequired(requiredRequest, stateVariables);
    }
    if (crudlStatus === CrudlStatus.UPDATE) {
      return await this.updateRequired(requiredRequest, stateVariables);
    }
    if (crudlStatus === CrudlStatus.DELETE) {
      return await this.deleteRequired(requiredRequest);
    }
  };

  createRequired = async (requiredRequest, stateVariables) => {
    requiredRequest.codeTag = this.codeTag;
    requiredRequest.userId = this.userId;

    console.log(requiredRequest);
    var applicationResponse = {};

    await api("applicationApiV1ApplicationCreate", requiredRequest).then(
      (res) => {
        if (res.data) {
          applicationResponse =
            CloudService.ApplicationResponse.constructFromObject(res.data);
          console.log(applicationResponse);
          if (
            Format.isNotNull(applicationResponse.errorCode) &&
            applicationResponse.errorCode === 0
          ) {
            this.showSuccess(
              "Application",
              CrudlStatus.CREATE,
              stateVariables.viewState
            );
          } else {
            this.showError("Application", CrudlStatus.CREATE, ViewState.CARD);
          }
        }
      }
    );

    return applicationResponse;
  };

  deleteRequired = async (requiredRequest) => {
    //RESET PRODUCT REQUEST
    // var request = {};
    // request.codeTag = this.codeTag;
    // request.userId = this.userId;
    // request.id = requiredRequest.id;
    // request.lastUpdatedDateTime = requiredRequest.lastUpdatedDateTime;
    // let productResponse = await ApiService.requiredDelete(request);
    // if (productResponse.errorCode === 0) {
    //   this.showSuccess("Required", CrudlStatus.DELETE, ViewState.TABLE);
    // } else {
    //   this.showError("Required", CrudlStatus.DELETE, ViewState.CARD);
    // }
    // return productResponse;
  };

  updateRequired = async (requiredRequest, stateVariables) => {
    // requiredRequest.codeTag = this.codeTag;
    // requiredRequest.userId = this.userId;
    // let productResponse = await ApiService.requiredUpdate(requiredRequest);
    // if (productResponse.errorCode === 0) {
    //   this.showSuccess(
    //     "Required",
    //     CrudlStatus.UPDATE,
    //     stateVariables.viewState
    //   );
    // } else {
    //   this.showError("Required", CrudlStatus.UPDATE, ViewState.CARD);
    // }
    // return productResponse;
  };
}
