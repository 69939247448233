import React from "react";
//PrimeReact Components
import * as pr from "../../../common/PrimeComponents";
import {
  CrudlStatus,
  FeedbackState,
} from "../../../common/config/AppConstants";
import { LoadScreen } from "../../Common/LoadScreen";

export class ReportingCard extends React.Component {
  componentDidUpdate = (prevProps) => {
    // Typical usage (don't forget to compare props):
    if (this.props.receivedFeedback !== prevProps.receivedFeedback) {
      switch (this.props.receivedFeedback.state) {
        case FeedbackState.EMPTY:
          this.props.feedback.showEmpty(
            this.growl,
            this.props.receivedFeedback.display
          );
          break;
        case FeedbackState.SUCCESS:
          this.props.feedback.showSuccess(
            this.growl,
            this.props.receivedFeedback.display,
            this.props.receivedFeedback.crudlStatus
          );
          break;
        case FeedbackState.ERROR:
          this.props.feedback.showError(
            this.growl,
            this.props.receivedFeedback.display,
            this.props.receivedFeedback.crudlStatus
          );
          break;
        default:
          break;
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <pr.Messages
          style={{ textAlign: "left" }}
          ref={(el) => (this.growl = el)}
        />
        <div className="scrl">
          <div className="p-grid">
            <div className="p-col-12"></div>
            {this.props.loading === true && <LoadScreen />}
            {this.props.loading !== true && (
              <React.Fragment>{/* UI GOES HERE */}</React.Fragment>
            )}
          </div>
        </div>
        <div className="sidebar-footer">
          <pr.Button
            className="mrgn flt-l"
            label="Cancel"
            icon="pi-md-close"
            onClick={() => {
              this.props.validateChanges();
            }}
          />
          {this.props.crudlState !== CrudlStatus.CREATE &&
          this.props.crudlState !== CrudlStatus.VIEW ? (
            <pr.Button
              className="mrgn flt-r"
              label="Delete"
              icon={
                this.props.loading ? "pi-spin pi-md-autorenew" : "pi-md-delete"
              }
              onClick={() => {
                this.props.crudlControl(
                  CrudlStatus.DELETE,
                  this.props.editedObject
                );
              }}
            />
          ) : (
            <label />
          )}
          {this.props.crudlState !== CrudlStatus.VIEW ? (
            <pr.Button
              className="mrgn flt-r"
              disabled={this.props.checkRequiredFields()}
              label="Save"
              icon={
                this.props.loading ? "pi-spin pi-md-autorenew" : "pi-md-save"
              }
              onClick={() => {
                this.props.crudlExecute(true);
              }}
            />
          ) : (
            <label />
          )}
          {this.props.crudlState !== CrudlStatus.VIEW ? (
            <pr.Button
              className="mrgn flt-r"
              disabled={this.props.checkRequiredFields()}
              label="Save and Close"
              icon={
                this.props.loading
                  ? "pi-spin pi-md-autorenew"
                  : "pi-md-tab-unselected"
              }
              onClick={() => {
                this.props.crudlExecute(false);
              }}
            />
          ) : (
            <label />
          )}
          {this.props.crudlState === CrudlStatus.VIEW ? (
            <pr.Button
              className="mrgn flt-r"
              label="Edit"
              icon={
                this.props.loading ? "pi-spin pi-md-autorenew" : "pi pi-md-edit"
              }
              onClick={() => {
                this.props.crudlControl(
                  CrudlStatus.UPDATE,
                  this.props.editedObject
                );
              }}
            />
          ) : (
            <label />
          )}
        </div>
      </React.Fragment>
    );
  }
}
