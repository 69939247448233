/**
 * buysupply-production.appspot.com
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.1
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient', 'model/BusinessBean'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('./BusinessBean'));
  } else {
    // Browser globals (root is window)
    if (!root.BuysupplyProductionappspotcom) {
      root.BuysupplyProductionappspotcom = {};
    }
    root.BuysupplyProductionappspotcom.LoginResponse = factory(root.BuysupplyProductionappspotcom.ApiClient, root.BuysupplyProductionappspotcom.BusinessBean);
  }
}(this, function(ApiClient, BusinessBean) {
  'use strict';




  /**
   * The LoginResponse model module.
   * @module model/LoginResponse
   * @version 1.0.0
   */

  /**
   * Constructs a new <code>LoginResponse</code>.
   * @alias module:model/LoginResponse
   * @class
   */
  var exports = function() {
    var _this = this;



























  };

  /**
   * Constructs a <code>LoginResponse</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/LoginResponse} obj Optional instance to populate.
   * @return {module:model/LoginResponse} The populated <code>LoginResponse</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('authenticateProvider')) {
        obj['authenticateProvider'] = ApiClient.convertToType(data['authenticateProvider'], 'String');
      }
      if (data.hasOwnProperty('businessList')) {
        obj['businessList'] = ApiClient.convertToType(data['businessList'], [BusinessBean]);
      }
      if (data.hasOwnProperty('clearFieldConstant')) {
        obj['clearFieldConstant'] = ApiClient.convertToType(data['clearFieldConstant'], 'String');
      }
      if (data.hasOwnProperty('clientId')) {
        obj['clientId'] = ApiClient.convertToType(data['clientId'], 'String');
      }
      if (data.hasOwnProperty('codeTag')) {
        obj['codeTag'] = ApiClient.convertToType(data['codeTag'], 'String');
      }
      if (data.hasOwnProperty('contractorId')) {
        obj['contractorId'] = ApiClient.convertToType(data['contractorId'], 'String');
      }
      if (data.hasOwnProperty('demographicAreas')) {
        obj['demographicAreas'] = ApiClient.convertToType(data['demographicAreas'], ['String']);
      }
      if (data.hasOwnProperty('errorCode')) {
        obj['errorCode'] = ApiClient.convertToType(data['errorCode'], 'Number');
      }
      if (data.hasOwnProperty('facebookId')) {
        obj['facebookId'] = ApiClient.convertToType(data['facebookId'], 'String');
      }
      if (data.hasOwnProperty('firstName')) {
        obj['firstName'] = ApiClient.convertToType(data['firstName'], 'String');
      }
      if (data.hasOwnProperty('globalBid')) {
        obj['globalBid'] = ApiClient.convertToType(data['globalBid'], 'String');
      }
      if (data.hasOwnProperty('languageId')) {
        obj['languageId'] = ApiClient.convertToType(data['languageId'], 'String');
      }
      if (data.hasOwnProperty('message')) {
        obj['message'] = ApiClient.convertToType(data['message'], 'String');
      }
      if (data.hasOwnProperty('messageList')) {
        obj['messageList'] = ApiClient.convertToType(data['messageList'], 'String');
      }
      if (data.hasOwnProperty('pin')) {
        obj['pin'] = ApiClient.convertToType(data['pin'], 'String');
      }
      if (data.hasOwnProperty('reportingUrl')) {
        obj['reportingUrl'] = ApiClient.convertToType(data['reportingUrl'], 'String');
      }
      if (data.hasOwnProperty('responseMsg')) {
        obj['responseMsg'] = ApiClient.convertToType(data['responseMsg'], 'String');
      }
      if (data.hasOwnProperty('roleType')) {
        obj['roleType'] = ApiClient.convertToType(data['roleType'], 'String');
      }
      if (data.hasOwnProperty('scoreOptions')) {
        obj['scoreOptions'] = ApiClient.convertToType(data['scoreOptions'], ['String']);
      }
      if (data.hasOwnProperty('surname')) {
        obj['surname'] = ApiClient.convertToType(data['surname'], 'String');
      }
      if (data.hasOwnProperty('twitterId')) {
        obj['twitterId'] = ApiClient.convertToType(data['twitterId'], 'String');
      }
      if (data.hasOwnProperty('userEmail')) {
        obj['userEmail'] = ApiClient.convertToType(data['userEmail'], 'String');
      }
      if (data.hasOwnProperty('userId')) {
        obj['userId'] = ApiClient.convertToType(data['userId'], 'String');
      }
      if (data.hasOwnProperty('userName')) {
        obj['userName'] = ApiClient.convertToType(data['userName'], 'String');
      }
      if (data.hasOwnProperty('userStatus')) {
        obj['userStatus'] = ApiClient.convertToType(data['userStatus'], ['String']);
      }
      if (data.hasOwnProperty('validStatusList')) {
        obj['validStatusList'] = ApiClient.convertToType(data['validStatusList'], ['String']);
      }
    }
    return obj;
  }

  /**
   * @member {String} authenticateProvider
   */
  exports.prototype['authenticateProvider'] = undefined;
  /**
   * @member {Array.<module:model/BusinessBean>} businessList
   */
  exports.prototype['businessList'] = undefined;
  /**
   * @member {String} clearFieldConstant
   */
  exports.prototype['clearFieldConstant'] = undefined;
  /**
   * @member {String} clientId
   */
  exports.prototype['clientId'] = undefined;
  /**
   * @member {String} codeTag
   */
  exports.prototype['codeTag'] = undefined;
  /**
   * @member {String} contractorId
   */
  exports.prototype['contractorId'] = undefined;
  /**
   * @member {Array.<String>} demographicAreas
   */
  exports.prototype['demographicAreas'] = undefined;
  /**
   * @member {Number} errorCode
   */
  exports.prototype['errorCode'] = undefined;
  /**
   * @member {String} facebookId
   */
  exports.prototype['facebookId'] = undefined;
  /**
   * @member {String} firstName
   */
  exports.prototype['firstName'] = undefined;
  /**
   * @member {String} globalBid
   */
  exports.prototype['globalBid'] = undefined;
  /**
   * @member {String} languageId
   */
  exports.prototype['languageId'] = undefined;
  /**
   * @member {String} message
   */
  exports.prototype['message'] = undefined;
  /**
   * @member {String} messageList
   */
  exports.prototype['messageList'] = undefined;
  /**
   * @member {String} pin
   */
  exports.prototype['pin'] = undefined;
  /**
   * @member {String} reportingUrl
   */
  exports.prototype['reportingUrl'] = undefined;
  /**
   * @member {String} responseMsg
   */
  exports.prototype['responseMsg'] = undefined;
  /**
   * @member {String} roleType
   */
  exports.prototype['roleType'] = undefined;
  /**
   * @member {Array.<String>} scoreOptions
   */
  exports.prototype['scoreOptions'] = undefined;
  /**
   * @member {String} surname
   */
  exports.prototype['surname'] = undefined;
  /**
   * @member {String} twitterId
   */
  exports.prototype['twitterId'] = undefined;
  /**
   * @member {String} userEmail
   */
  exports.prototype['userEmail'] = undefined;
  /**
   * @member {String} userId
   */
  exports.prototype['userId'] = undefined;
  /**
   * @member {String} userName
   */
  exports.prototype['userName'] = undefined;
  /**
   * @member {Array.<String>} userStatus
   */
  exports.prototype['userStatus'] = undefined;
  /**
   * @member {Array.<String>} validStatusList
   */
  exports.prototype['validStatusList'] = undefined;



  return exports;
}));


