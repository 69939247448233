import React from "react";
//PrimeReact Components
import * as pr from "../../../common/PrimeComponents";
import {
  ApplicationStatus,
  CrudlStatus,
  ProcessType,
} from "../../../common/config/AppConstants";
import * as EnumDropdowns from "../../../common/config/EnumDropdowns";
import * as format from "../../../common/FormatFunctions";
import {
  Input,
  InputType,
  LabelPosition,
  UpdateType,
} from "../../Common/Input";
import { ApplicationCard } from "./ApplicationCard";

export class ApplicationOutlook extends React.Component {
  isTablet() {
    let width = window.innerWidth;
    return width <= 1024 && width > 640;
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }

  isMobile() {
    return window.innerWidth <= 640;
  }

  export = () => {
    this.dt.exportCSV();
  };

  displayInboxCardsFromArray = () => {
    let array = [];
    let i = 0;

    if (format.isNotNull(this.props.applicationList)) {
      this.props.applicationList.forEach((e) => {
        array[i] = this.componentDisplay(e);
        i++;
      });
    }

    return array;
  };

  componentDisplay = (application) => {
    let colour = "green";
    let spanToolTip = ProcessType.SENT;
    if (application.processType === ProcessType.RECEIVED) {
      colour = "blue";
      spanToolTip = ProcessType.RECEIVED;
    }
    return (
      <div
        className="p-col-12 outlook-card"
        key={application.id + application.processType}
        id={application.id + application.processType}
        onClick={(e) => {
          let element = document.getElementById(
            localStorage.getItem("manageSetsLastSelectedCardId")
          );
          if (element) {
            element.style = "outlook-card";
          }
          e.currentTarget.style.backgroundColor = "#00ac86";
          localStorage.setItem(
            "manageSetsLastSelectedCardId",
            e.currentTarget.id
          );
          this.props.crudlControl(CrudlStatus.UPDATE, application);
        }}
      >
        <div className="p-grid">
          <div className="p-col-11">
            {application.processType === ProcessType.RECEIVED ? (
              <Input
                id={"businessNameCodeTag"}
                labelDisplay={"From:"}
                labelDisplayPosition={LabelPosition.LEFT}
                required={false}
                type={InputType.STRING}
                disabled={true}
                value={application.businessNameCodeTag}
                field={"businessNameCodeTag"}
                visible={true}
              />
            ) : (
              <Input
                id={"businessNameBuyerId"}
                labelDisplay={"To:"}
                labelDisplayPosition={LabelPosition.LEFT}
                required={false}
                type={InputType.STRING}
                disabled={true}
                value={application.businessNameBuyerId}
                field={"businessNameBuyerId"}
                visible={true}
              />
            )}

            <div className="p-grid" style={{ width: "100%" }}>
              <div className="p-col-1"></div>
              <div className="p-col-10">
                <u>
                  <Input
                    id={"title"}
                    labelDisplay={"Title"}
                    labelDisplayPosition={LabelPosition.NONE}
                    required={false}
                    type={InputType.STRING}
                    disabled={true}
                    value={application.title}
                    field={"title"}
                    visible={true}
                  />
                </u>
              </div>
            </div>
            <div className="p-grid" style={{ width: "100%" }}>
              <div className="p-col-1"></div>
              <div className="p-col-10 p-text-nowrap p-text-truncate">
                <Input
                  id={"shortDescription"}
                  labelDisplay={"Short Description"}
                  labelDisplayPosition={LabelPosition.NONE}
                  required={false}
                  type={InputType.STRING}
                  disabled={true}
                  value={application.shortDescription}
                  field={"shortDescription"}
                  visible={true}
                />
              </div>
            </div>
          </div>
          <div className="p-col-1">
            <span
              className="material-icons"
              title={spanToolTip}
              style={{ color: colour }}
              onClick={() => {}}
            >
              {application.processType === ProcessType.SENT && "arrow_forward"}
              {application.processType === ProcessType.RECEIVED && "arrow_back"}
            </span>
          </div>
          <div className="p-col-12" style={{ textAlign: "right" }}>
            <label>{application.dateCreatedToDisplayed}</label>
          </div>
        </div>
      </div>
    );
  };

  displayInboxList = () => {
    let toolTip = false;
    let toolTipLabel = "";
    if (!format.isNotNull(this.props.productList)) {
      toolTip = true;
      toolTipLabel =
        toolTipLabel + "Before creating contacts you must add a Product";
    }
    if (!format.isNotNull(this.props.profileList)) {
      toolTip = true;
      toolTipLabel = "You have no businesses available to contact";
    }
    return (
      <div className="p-grid">
        <div className="p-col-12" style={{ backgroundColor: "lightgray" }}>
          <pr.Button
            className="button"
            label={"Contact Multiple"}
            icon="pi-md-add"
            style={{
              float: "right",
            }}
            onClick={() => {
              if (toolTip === false) {
                console.log("Running crudl Create");
                this.props.crudlControl(CrudlStatus.CREATE);
              }
            }}
            tooltip={toolTipLabel}
            tooltipOptions={{
              position: "bottom",
              mouseTrack: true,
              mouseTrackTop: 15,
            }}
          />
          <pr.Button
            className="button"
            type="button"
            style={{
              float: "left",
            }}
            label={"Refresh"}
            icon={
              this.props.loading === true
                ? "pi-spin pi-md-autorenew"
                : "pi-md-autorenew"
            }
            onClick={this.props.refresh}
          />
        </div>
        <div className="outlook-list">{this.displayInboxCardsFromArray()}</div>
      </div>
    );
  };

  displaySelectedMessage = () => {
    let disabled = false;
    let label = "Request Feedback";
    if (format.isNotNull(this.props.editedObject)) {
      if (
        this.props.editedObject.status === ApplicationStatus.IN_PROGRESS ||
        this.props.editedObject.status === ApplicationStatus.SUBMITTED ||
        this.props.editedObject.status === ApplicationStatus.UNSUCCESSFUL ||
        this.props.editedObject.status === ApplicationStatus.SEEN
      ) {
        let feedbackAttemptCount = 0;
        let feedbackAttemptMax = 3; // default is 3

        let isFeedbackReqOutsideOfSpamTime = true;

        if (format.isNotNull(this.props.editedObject.feedbackAttemptCount)) {
          feedbackAttemptCount = this.props.editedObject.feedbackAttemptCount;
          label =
            label + "(" + this.props.editedObject.feedbackAttemptCount + ")";
        } else {
          label = label + "(0)";
        }
        if (format.isNotNull(this.props.editedObject.feedbackAttemptMax)) {
          feedbackAttemptMax = this.props.editedObject.feedbackAttemptMax;
        }

        let newDate = new Date();
        let betweenTwoDates = false;
        if (format.isNotNull(this.props.editedObject.lastFeedbackRequestDate)) {
          if (
            this.props.editedObject.feedbackAttemptCount === 0 ||
            !format.isNotNull(this.props.editedObject.feedbackAttemptCount)
          ) {
            newDate = format.addDaysToDate(
              this.props.editedObject.lastFeedbackRequestDate,
              2
            );
          }
          if (this.props.editedObject.feedbackAttemptCount === 1) {
            newDate = format.addDaysToDate(
              this.props.editedObject.lastFeedbackRequestDate,
              4
            );
          }
          if (this.props.editedObject.feedbackAttemptCount === 2) {
            newDate = format.addDaysToDate(
              this.props.editedObject.lastFeedbackRequestDate,
              6
            );
          }

          betweenTwoDates = format.isTodayWithinDates(
            this.props.editedObject.lastFeedbackRequestDate,
            newDate
          );

          if (betweenTwoDates) {
            isFeedbackReqOutsideOfSpamTime = false;
          }
        }

        if (isFeedbackReqOutsideOfSpamTime === false) {
          disabled = true;
        }
        if (feedbackAttemptCount >= feedbackAttemptMax) {
          disabled = true;
        }
      }
    }
    return (
      <div className="">
        {format.isNotNull(this.props.editedObject) ? (
          <>
            {this.props.crudlState === CrudlStatus.CREATE ? (
              <div className="p-col-12 outlook-selectedMessage">
                <ApplicationCard
                  editedObject={this.props.editedObject}
                  crudlState={this.props.crudlState}
                  updateValue={this.props.updateValue}
                  updateState={this.props.updateState}
                  loading={this.props.loading}
                  viewType={this.props.viewType}
                  displayBusinessDetails={this.props.displayBusinessDetails}
                  checkRequiredFields={this.props.checkRequiredFields}
                  crudlExecute={this.props.crudlExecute}
                  businessOptions={this.props.businessOptions}
                  productOptions={this.props.productOptions}
                  feedback={this.props.feedback}
                  validateChanges={this.props.validateChanges}
                />
              </div>
            ) : (
              <>
                <div
                  className="outlook-selectedMessage"
                  style={{
                    marginBottom: "10px",
                    width: "100%",
                    backgroundColor: "lightgray",
                  }}
                >
                  {this.props.editedObject.processType === ProcessType.SENT && (
                    <>
                      {!format.isNotNull(this.props.editedObject.feedback) && (
                        <pr.Button
                          type="button"
                          className="flt-r"
                          label={label}
                          disabled={disabled}
                          onClick={() =>
                            this.props.requestFeedback(this.props.editedObject)
                          }
                        />
                      )}
                    </>
                  )}
                  {this.props.editedObject.processType ===
                    ProcessType.RECEIVED && (
                    <>
                      {!format.isNotNull(this.props.editedObject.feedback) && (
                        <pr.Button
                          type="button"
                          label="Provide Feedback"
                          className="flt-r"
                          onClick={() =>
                            this.props.updateState("showDialogUpdate", true)
                          }
                        />
                      )}

                      <pr.Dialog
                        header={<h2>{"Update Application"}</h2>}
                        footer={
                          <div>
                            <pr.Button
                              disabled={
                                this.props.editedObject.status ===
                                  ApplicationStatus.ACCEPTED &&
                                (!this.props.editedObject.feedbackType ||
                                  !this.props.editedObject.feedback)
                              }
                              className="secondary-btn"
                              label="Update"
                              icon="pi pi-check"
                              onClick={() => {
                                this.props.updateFromTable(
                                  this.props.editedObject
                                );
                              }}
                              style={{
                                float: "right",
                              }}
                            />
                            <pr.Button
                              className="secondary-btn"
                              label="Cancel"
                              icon="pi pi-times"
                              onClick={() => {
                                this.props.updateState(
                                  "showDialogUpdate",
                                  false
                                );
                              }}
                              style={{
                                float: "right",
                              }}
                            />
                          </div>
                        }
                        visible={this.props.showDialogUpdate}
                        onHide={() =>
                          this.props.updateState("showDialogUpdate", false)
                        }
                        modal={true}
                        blockScroll={true}
                        style={{ width: "80vw" }}
                      >
                        <div className="p-col-12 card">
                          <Input
                            id={"status"}
                            labelDisplay={"Status"}
                            labelDisplayPosition={LabelPosition.LEFT}
                            required={true}
                            type={InputType.DROPDOWN}
                            dropdownOptions={
                              EnumDropdowns.ApplicationStatusOptions
                            }
                            disabled={
                              this.props.crudlState === CrudlStatus.VIEW
                            }
                            value={this.props.editedObject.status}
                            onChangeType={UpdateType.STANDARD}
                            onChange={this.props.updateValue}
                            field={"status"}
                          />
                          <Input
                            id={"feedbackType"}
                            labelDisplay={"Reason"}
                            labelDisplayPosition={LabelPosition.LEFT}
                            required={true}
                            type={InputType.DROPDOWN}
                            dropdownOptions={
                              EnumDropdowns.ApplicationFeedbackOptions
                            }
                            disabled={
                              this.props.crudlState === CrudlStatus.VIEW
                            }
                            value={this.props.editedObject.feedbackType}
                            onChangeType={UpdateType.STANDARD}
                            onChange={this.props.updateValue}
                            field={"feedbackType"}
                            visible={
                              this.props.editedObject.status ===
                              ApplicationStatus.UNSUCCESSFUL
                            }
                          />

                          <Input
                            id={"feedback"}
                            labelDisplay={"Feedback"}
                            labelDisplayPosition={LabelPosition.TOP}
                            required={
                              this.props.editedObject.status ===
                              ApplicationStatus.UNSUCCESSFUL
                            }
                            type={InputType.NOTES}
                            disabled={false}
                            value={this.props.editedObject.feedback}
                            onChangeType={UpdateType.STANDARD}
                            onChange={this.props.updateValue}
                            field={"feedback"}
                            visible={true}
                            style={{ height: "fit-content" }}
                          />
                        </div>
                      </pr.Dialog>
                    </>
                  )}
                </div>

                <div className="p-col-12 outlook-selectedMessage">
                  <ApplicationCard
                    editedObject={this.props.editedObject}
                    crudlState={CrudlStatus.VIEW}
                    updateValue={this.props.updateValue}
                    updateState={this.props.updateState}
                    loading={this.props.loading}
                    viewType={this.props.viewType}
                    displayBusinessDetails={this.props.displayBusinessDetails}
                  />
                </div>
              </>
            )}
          </>
        ) : (
          this.displaySkeleton()
        )}
      </div>
    );
  };

  displaySelectedMessageOverlay = () => {
    return (
      <React.Fragment>
        <pr.Sidebar
          visible={null != this.props.editedObject}
          position="right"
          fullScreen={true}
          onHide={() => {
            this.props.updateState("editedApplication", null);
          }}
          baseZIndex={10000000000}
          style={{
            width: "100%",
            zIndex: 10000000000,
          }}
        >
          {this.displaySelectedMessage()}
        </pr.Sidebar>
      </React.Fragment>
    );
  };

  displaySkeleton = () => {
    return (
      <div className="card">
        <div className="p-grid p-formgrid">
          <div className="p-col-12">
            <div className="custom-skeleton p-grid">
              <div className="p-col-12">
                <center>
                  <label
                    id="input-label bold"
                    style={{ color: "orange", fontSize: "16px" }}
                  >
                    No Enquiries Selected
                  </label>
                </center>
              </div>

              <div className="p-lg-3">
                <pr.Skeleton
                  shape="circle"
                  size="4rem"
                  className="p-mr-2"
                ></pr.Skeleton>
              </div>
              <div className="p-lg-9">
                <pr.Skeleton width="10rem" className="p-mb-2"></pr.Skeleton>
                <pr.Skeleton width="5rem" className="p-mb-2"></pr.Skeleton>
                <pr.Skeleton height=".5rem"></pr.Skeleton>
              </div>
              <pr.Skeleton width="100%" height="150px"></pr.Skeleton>
              <div className="p-col-2">
                <pr.Skeleton width="4rem" height="2rem"></pr.Skeleton>
              </div>
              <div className="p-col-8"></div>
              <div className="p-col-2">
                <pr.Skeleton width="4rem" height="2rem"></pr.Skeleton>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  tutorialVideo = (title, source) => {
    return (
      <pr.Dialog
        header={title}
        footer={
          <pr.Button
            className="secondary-btn"
            label="Close"
            icon="pi pi-times"
            onClick={() => {
              this.props.updateState("showTutorial", false);
            }}
            style={{
              float: "right",
            }}
          />
        }
        visible={true}
        onHide={() => {
          this.props.updateState("showTutorial", false);
        }}
        modal={true}
        blockScroll={true}
        style={{ width: "80vw" }}
      >
        {/* <video width="100%" height="98%" controls>
          <source src={source} type="video/webm" />
          Your browser does not support video elements, please update to the
          latest version
        </video> */}
        <div>
          <center>
            <iframe
              src={source}
              allowFullScreen
              style={{
                position: "relative",
                top: 0,
                left: 0,
              }}
            ></iframe>
          </center>
        </div>
      </pr.Dialog>
    );
  };

  render() {
    return (
      <div className="p-grid bpm">
        <div className="p-col-12 p-lg-3 p-md-6 p-sl-12">
          <div className="content-section">
            <div className="feature-intro">
              <h1>{"Enquiries"}</h1>
            </div>
          </div>
        </div>
        <div className="p-col-12 p-lg-9 p-md-6 p-sl-12">
          <pr.Button
            icon="pi pi-info"
            onClick={() => {
              this.props.updateState("showTutorial", true);
            }}
            style={{ fontSize: "10px", float: "right" }}
          />
        </div>

        {this.props.showTutorial &&
          this.tutorialVideo(
            "Enquiries Tutorial",
            // "/assets/layout/images/council/enquiries.webm"
            "https://player.vimeo.com/video/582850309?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          )}

        <div className="p-col-12">
          {this.isDesktop() && (
            <div className="p-grid">
              <div className="p-col-3 card">{this.displayInboxList()}</div>
              <div className="p-col-9 card">
                {this.displaySelectedMessage()}
              </div>
            </div>
          )}
          {this.isTablet() && (
            <div className="p-grid">
              <div className="p-col-3 card">{this.displayInboxList()}</div>
              <div className="p-col-9 card">
                {this.displaySelectedMessage()}
              </div>
            </div>
          )}
          {this.isMobile() && (
            <div className="p-grid">
              <div className="p-col-12">{this.displayInboxList()}</div>
              <div className="p-col-12">
                {this.displaySelectedMessageOverlay()}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
