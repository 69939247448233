import React from "react";
import { PPEProfileController } from "./PPEProfileController";
import { PPEProfileViewModel } from "./PPEProfileViewModel";
import * as feedback from "../../common/Feedback/Feedback";
import * as log from "../../common/Feedback/Log";

export class PPEProfileProvider extends React.Component {
  constructor(props) {
    super(props);
    this.viewModel = new PPEProfileViewModel();
    this.logger = log;
    this.feedback = feedback;

    console.log(props);
  }

  render() {
    return (
      <PPEProfileController
        viewModel={this.viewModel}
        log={this.logger}
        feedback={this.feedback}
        toggleTopbar={this.props.toggleTopbar}
      />
    );
  }
}
