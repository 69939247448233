import * as Configuration from "../config/Configuration";
import * as Constants from "../config/AppConstants";

const trace = (function() {
  if (!window.console || !console.log) {
    return;
  }
  if (Configuration.logLevels.includes(Constants.LogLevel.SEVERE) === false) {
    return function() {};
  }
  return Function.prototype.bind.call(console.trace, console);
})();

const debug = (function() {
  if (!window.console || !console.log) {
    return;
  }
  if (Configuration.logLevels.includes(Constants.LogLevel.SEVERE) === false) {
    return function() {};
  }
  return Function.prototype.bind.call(console.debug, console);
})();

const warn = (function() {
  if (!window.console || !console.log) {
    return;
  }
  if (Configuration.logLevels.includes(Constants.LogLevel.WARNING) === false) {
    return function() {};
  }
  return Function.prototype.bind.call(console.warn, console);
})();

const error = (function() {
  if (!window.console || !console.log) {
    return;
  }
  if (Configuration.logLevels.includes(Constants.LogLevel.ERROR) === false) {
    return function() {};
  }
  return Function.prototype.bind.call(console.error, console);
})();

const info = (function() {
  if (!window.console || !console.log) {
    return;
  }
  if (Configuration.logLevels.includes(Constants.LogLevel.STANDARD) === false) {
    return function() {};
  }
  return Function.prototype.bind.call(console.info, console);
})();

export { trace, debug, warn, error, info };
