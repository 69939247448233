const globalBid_dev = "BID00000000000000000001";
const globalBid_prod = "BID00000000000002000001";
const clearFieldConstant = localStorage.getItem("clearFieldConstant");

export { globalBid_prod as defaultedGlobalBid };

export const CrudlStatus = {
  CREATE: { display: "create", value: "CREATE" },
  UPDATE: { display: "update", value: "UPDATE" },
  DELETE: { display: "delete", value: "DELETE" },
  LIST: { display: "retrieved", value: "LIST" },
  VIEW: { display: "view", value: "VIEW" },
};

export const ViewState = {
  CARD: "CARD",
  TABLE: "TABLE",
  OVERLAY: "OVERLAY",
  LOGIN: "LOGIN",
  NONE: "NONE",
};

export const FeedbackState = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  EMPTY: "EMPTY",
  NONE: "NONE",
};
export const ValidateState = {
  DELETE: "DELETE",
  CONFIRM: "CONFIRM",
  NONE: "NONE",
};

export const LogLevel = {
  STANDARD: "standard",
  ERROR: "error",
  SEVERE: "severe",
  WARNING: "warning",
};

export const ToggleState = {
  ON: "ON",
  OFF: "OFF",
};

export const PageState = {
  IDLE: "IDLE",
  LOADING: "LOADING",
  ERROR: "ERROR",
};

// When using Provider from Other folders
export const ViewType = {
  STANDARD: "STANDARD",
  BUYERSUPPLIER: "BUYERSUPPLIER",
  COUNCIL: "COUNCIL",
  BUYER: "BUYER",
  SUPPLIER: "SUPPLIER",
  REGISTER: "REGISTER",
  MY_PRODUCT: "MYPRODUCT",
  MY_REQUIREMENT: "MYREQUIREMENT",
  PROFILE: "PROFILE",
  DASHBOARD: "DASHBOARD",
  CONTACT: "CONTACT",
  ENQUIRIES: "ENQUIRIES",
  MY_BUSINESS: "MYBUSINESS",
  VIEW_BUSINESS: "VIEWBUSINESS",
};

export const BusinessType = {
  COUNCIL: "Council",
  BUYER: "Buyer",
  SUPPLIER: "Supplier",
  BUYERSUPPLIER: "BuyerSupplier",
  PPEBUYER: "PPEBuyer",
  PPESUPPLIER: "PPESupplier",
  PPEBOTH: "Both",
  SUB_MANUFACTURER: "Manufacturer",
  SUB_DISTRIBUTER: "Distributer",
  SUB_OTHER: "Other",
};

export const ApplicationType = {
  BUYER: "Buyer",
  SUPPLIER: "Supplier",
};

export const ProfileStatus = {
  INVALIDSTATUS: "Invalid Status",
  APPROVED: "Approved",
  PENDING: "Pending",
  UNSUCCESSFUL: "Unsuccessful",
};

export const ProductType = {
  SUPPLIER: "Supplier",
  BUYER: "Buyer",
  PPESUPPLIER: "PPESupplier",
  PPEBUYER: "PPEBuyer",
};

export const CapabilityTypes = {
  WANT_TO_PROCURE: "Yes, I want to procure",
  OPEN_TO_PROCUREMENT: "Potential, open to Procurement",
  CAN_SUPPLY: "Yes, I can supply",
  OPEN_TO_SUPPLY: "Potential, open for development",
};

export const ApplicationStatus = {
  ACCEPTED: "Accepted",
  SUBMITTED: "Submitted",
  SEEN: "Seen",
  IN_PROGRESS: "In Progress",
  UNSUCCESSFUL: "Unsuccessful",
  WITHDRAWN: "Withdrawn",
  SUCCESSFUL: "Successful",
};

export const ProcessType = {
  SENT: "Sent",
  RECEIVED: "Received",
};

export const FeedbackOptions = {
  NOT_RELEVANT: "Not Relevant",
  QUALITY: "Quality",
  QUANTITY: "Quantity",
  UNPROFESSIONAL: "Unprofessional",
  SERVICE: "Service",
};

export const ProductStatus = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
};

export const MatrixType = {
  OVERALL: "Overall",
  BUYER: "Buyer",
  SUPPLIER: "Supplier",
};

export const UserRoleType = {
  CouncilAdmin: 10,
  CouncilUser: 20,
  BuyerUser: 30,
  SupplierUser: 40,
  BuysupplyUser: 50,
};

export const ProfileItemSection = {
  GENERAL: "General",
  QUALITY: "Quality",
  COST: "Cost",
  LOGISTICS: "Logistics",
  DEVELOPMENT: "Development",
  MANAGEMENT: "Management",
};

export const CategoryTooltips = {
  SUPPLIER:
    "Please choose this option if you feel this is an area of your business where you have the capacity to develop products in this category.",
  BUYER:
    "Please choose this option if you feel this is an area of your business where you may require a specific product within this category in the future.  There may be an opportunity to link with a supplier to develop this product to your requirements",
};

export let ProfileItemQuestions = [
  "formGeneralCompanyName",
  "formGeneralParentCompanyName",
  "formGeneralLocation",
  "formGeneralProducts",
  "formGeneralRevenue",
  "formGeneralEmployees",
  "formGeneralCapacityUtilisation",
  "formQualityLeanManufacturing",
  "formQualityTotalQualityManage",
  "formQualityKaizen",
  "formQuality6Sigma",
  "formQualityOther1",
  "formQualityOther2",
  "formQualityOther3",
  "formQualityOther4",
  "formQualityLean",
  "formQualityLeanRegistrar",
  "formQualityTQM",
  "formQualityTQMRegistrar",
  "formQualityTS16949",
  "formQualityTS16949Registrar",
  "formQualityCATCertified",
  "formQualityCATCertifiedRegistrar",
  "formQualityISO9001",
  "formQualityISO9001Registrar",
  "formQualityIATF",
  "formQualityIATFRegistrar",
  "formCostOpenBook",
  "formCostShouldCost",
  "formCostCurrency",
  "formLogisticsRawMaterials",
  "formLogisticsRawMaterialsExplanation",
  "formLogisticsFinishedGoods",
  "formLogisticsFinishedGoodsExplanation",
  "formLogisticsCurrentDeliveryPerformance",
  "formLogisticsConsignmentStock",
  "formLogisticsConsignmentStockExplanation",
  "formLogisticsReturnablePackaging",
  "formLogisticsReturnablePackagingExplanation",
  "formLogisticsMaterialAcquisitionLeadTime",
  "formLogisticsFinishedGoodsLeadTime",
  "formLogisticsJIT",
  "formLogisticsASN",
  "formLogisticsVMI",
  "formLogisticsEDI",
  "formLogisticsWarehousingFacilitiesNI",
  "formLogisticsWarehousingCapabilityNI",
  "formDevelopmentSystem1",
  "formDevelopmentNumberOfStations1",
  "formDevelopmentSystem2",
  "formDevelopmentNumberOfStations2",
  "formDevelopmentSystem3",
  "formDevelopmentNumberOfStations3",
  "formDevelopmentRAndD",
  "formDevelopmentRAndDExplanation",
  "formDevelopmentDesignCapabilities",
  "formDevelopmentPrototypingCapabilities",
  "formDevelopmentProtottypingCapabiltiesWeeks",
  "formDevelopmentRapidPrototypingCapability",
  "formDevelopmentFEASoftwareCapability",
  "formDevelopmentOtherName1",
  "formDevelopmentOtherName2",
  "formDevelopmentOtherName3",
  "formManagementRoHsCompliance",
  "formManagementISO14001",
];

export const ComponentType = {
  MANUFACTURE: "MANUFACTURE",
  PPE: "PPE",
};
