import React from "react";
import { ApplicationView } from "./ApplicationView";
import {
  CrudlStatus,
  ToggleState,
  PageState,
  FeedbackState,
  ViewType,
  ProductType,
  ApplicationType,
  BusinessType,
} from "../../common/config/AppConstants";
import { ViewState } from "../../common/config/AppConstants";
import { ValidateState } from "../../common/config/AppConstants";
import * as pr from "../../common/PrimeComponents";
import * as format from "../../common/FormatFunctions";
import { ErrorScreen } from "../Common/ErrorScreen";
import * as analytics from "../../common/helpers/firebaseAnalytics";
import { profileGetByType } from "../../common/CloudService/ApiService";

export class ApplicationController extends React.Component {
  state = {
    pageName: "ApplicationController",
    pageState: PageState.IDLE,
    sidebarWidth: "100%",

    //DISPLAY STATES
    viewState: ViewState.TABLE,
    validateState: ValidateState.NONE,
    crudlState: CrudlStatus.LIST,
    menuState: [],

    // PROPS
    viewType: ViewType.STANDARD, // e.g. InvoiceViewType.STANDARD

    // DATA TABLE
    expandedRows: null,
    tableColumns: [],
    columnOptions: [],
    settingsToggle: ToggleState.OFF,

    // DETAILS
    applicationList: null,
    originalEditedApplication: "",
    editedApplication: null,
    receivedFeedback: {
      state: FeedbackState.NONE,
      display: "Application",
      crudlStatus: CrudlStatus.LIST,
    },
    businessOptions: [],
    showDialogUpdate: false,
    viewBusinessId: null,
    toggleViewBusinessDetails: false,
    // Council
    searchArchived: false,
    applicationType: ApplicationType.BUYER,
  };

  componentDidMount = async () => {
    let globalFilter = "";
    if (format.isNotNull(this.props.params)) {
      if (format.isNotNull(this.props.params.globalFilter)) {
        globalFilter = this.props.params.globalFilter.replace("_", " ");
      }
    }
    if (format.isNotNull(globalFilter)) {
      await this.setState({ globalFilter: globalFilter });
    }
    await this.initialDataRender();
  };

  componentDidCatch = (error, info) => {
    this.props.log("This threw an error");
    this.props.log(error);
    this.props.log(info);
    //COMPONENT ISSUE CHECK IF THIS ERROR HANDLING IS CORRECT
    this.setError(false, "componentDidCatch", error);
  };

  initialDataRender = async () => {
    this.setViewModel();
    await this.setPageData();
  };

  setError = (critical, methodName, error) => {
    this.props.log.error(error.message);
    if (critical) {
      this.setCriticalError();
    } else {
      this.setStandardError();
    }
  };
  setStandardError = () => {
    //MORE CAN BE ADDED HERE AS APPROPRIATE
    this.showError("Application");
    this.setState({ pageState: PageState.IDLE });
  };
  setCriticalError = () => {
    //SET ERROR STATE OF PAGE
    this.setState({ pageState: PageState.ERROR });
  };

  setViewModel = () => {
    try {
      this.props.viewModel.updateState = this.updateState;
      this.props.viewModel.showError = this.showError;
      this.props.viewModel.showSuccess = this.showSuccess;
      this.props.viewModel.showEmpty = this.showEmpty;
    } catch (error) {
      this.setError(false, "setViewModel", error);
    }
  };

  setPageData = async () => {
    try {
      this.setState({ pageState: PageState.LOADING });

      this.menuState(CrudlStatus.LIST);

      await this.handleProps();
      this.setUpColumns();

      if (this.props.viewType === ViewType.STANDARD) {
        await this.props.viewModel.getApplications();
        await this.props.viewModel.getAllProfiles();
        // Get My Products for creating Applications
        await this.props.viewModel.getProductsByCodeTagAndType(
          ProductType.SUPPLIER
        );
      }
      if (this.props.viewType === ViewType.CONTACT) {
        console.log(this.props.editedObjectFromProps);
        await this.props.viewModel.getAllProfiles();
        // Your wanting to contact the selected businesses Product
        // If Buyer Dashboard it should be ProductType.BUYER
        // If Supplier Dashboard it should be ProductType.SUPPLIER
        await this.props.viewModel.getProductsByCodeTagAndType(
          this.props.editedObjectFromProps.type,
          this.props.editedObjectFromProps.codeTag
        );

        if (format.isNotNull(this.props.editedObjectFromProps)) {
          let editedObject = format.deepCopy(this.state.editedApplication);
          let profileList = [];
          profileList.push(this.props.editedObjectFromProps.codeTag);
          editedObject.profileList = profileList;
          this.setState({ editedApplication: editedObject });
        }
      }
      if (this.props.viewType === ViewType.ENQUIRIES) {
        await this.props.viewModel.getApplications();
        await this.props.viewModel.getAllProfiles(BusinessType.BUYERSUPPLIER);
        // Get My Products for creating Applications
        await this.props.viewModel.getProductsByCodeTagAndType(
          ProductType.SUPPLIER
        );
        analytics.screenViewEvent(analytics.PageView.ENQUIRIES);
      }
      if (this.props.viewType === ViewType.COUNCIL) {
        await this.props.viewModel.getAllProfiles();
        // only a default to display something on initial loading
        await this.getApplicationsCouncil();
      }

      this.setState({
        pageState: PageState.IDLE,
      });
    } catch (error) {
      this.setError(true, "setPageData", error);
    }
  };
  handleProps = async () => {
    if (format.isNotNull(this.props.viewType)) {
      await this.setState({ viewType: this.props.viewType });
    }
    if (format.isNotNull(this.props.applicationType)) {
      await this.setState({ applicationType: this.props.applicationType });
    }
    if (format.isNotNull(this.props.sidebarWidth)) {
      await this.setState({ sidebarWidth: this.props.sidebarWidth });
    }
    if (format.isNotNull(this.props.crudlState)) {
      await this.setState({ crudlState: this.props.crudlState });
      // Add Conditions per viewType if applicable
      if (this.props.crudlState === CrudlStatus.CREATE) {
        console.log("Setting up contact create");
        this.executeSetup(this.props.crudlState);
      } else if (this.props.crudlState === CrudlStatus.UPDATE) {
        this.executeSetup(
          this.props.crudlState,
          this.props.editedObjectFromProps
        );
      }
    }
    if (format.isNotNull(this.props.dashboardType)) {
      await this.setState({ dashboardType: this.props.dashboardType });
    }
  };
  showError = (display, crudlStatus, whereToView) => {
    try {
      if (whereToView === ViewState.TABLE) {
        this.props.feedback.showError(this.growl, display, crudlStatus);
      }
      if (whereToView === ViewState.CARD) {
        this.setState({
          receivedFeedback: {
            state: FeedbackState.ERROR,
            display: display,
            crudlStatus: crudlStatus,
          },
        });
      }
    } catch (error) {
      this.setError(false, "showError", error);
    }
  };

  showSuccess = (display, crudlStatus, whereToView) => {
    try {
      if (whereToView === ViewState.TABLE) {
        this.props.feedback.showSuccess(this.growl, display, crudlStatus);
      }
      if (whereToView === ViewState.CARD) {
        this.setState({
          receivedFeedback: {
            state: FeedbackState.SUCCESS,
            display: display,
            crudlStatus: crudlStatus,
          },
        });
      }
    } catch (error) {
      this.setError(false, "showSuccess", error);
    }
  };

  showEmpty = (display, whereToView) => {
    try {
      if (whereToView === ViewState.TABLE) {
        this.props.feedback.showEmpty(this.growl, display);
      }
      if (whereToView === ViewState.CARD) {
        this.setState({
          receivedFeedback: {
            state: FeedbackState.EMPTY,
            display: display,
            crudlStatus: CrudlStatus.LIST,
          },
        });
      }
    } catch (error) {
      this.setError(false, "showEmpty", error);
    }
  };

  getStateValuesAsObject = (editedObject) => {
    try {
      var externalObject = {};
      if (format.isNotNull(editedObject)) {
        externalObject.applicationBean = editedObject;
      } else {
        externalObject.applicationBean = this.state.editedApplication;
      }
      externalObject.viewType = this.state.viewType;

      return externalObject;
    } catch (error) {
      this.setError(false, "getStateValuesAsObject", error);
    }
  };

  updateBean = async (field, value) => {
    try {
      this.props.log.info(
        "Updating : [" + field + "] with value [" + value + "]"
      );
      await this.setState((prevState) => ({
        editedApplication: {
          // object that we want to update
          ...prevState.editedApplication, // keep all other key-value pairs
          [field]: value,
        },
      }));
    } catch (error) {
      this.setError(false, "updateBean", error);
    }
  };

  updateState = (key, value) => {
    try {
      this.setState({ [key]: value });
    } catch (error) {
      this.setError(false, "updateState", error);
    }
  };

  validateChanges = async () => {
    try {
      if (this.props.viewType === ViewType.ENQUIRIES) {
        this.setState({
          viewState: ViewState.TABLE,
          toggleChangesMade: false,
          crudlState: CrudlStatus.UPDATE,
          selectedApplication: null,
          editedApplication: null,
          originalEditedApplication: "",
        });

        await this.setPageData();
      } else {
        if (this.state.editedApplication === null) {
          await this.discardChanges();
        } else {
          if (
            format.isJsonEqual(
              this.state.originalEditedApplication,
              this.state.editedApplication
            )
          ) {
            await this.discardChanges();
          } else {
            this.setState({ validateState: ValidateState.CONFIRM });
          }
        }
      }
    } catch (error) {
      this.setError(false, "validateChanges", error);
    }
  };

  discardChanges = async () => {
    try {
      this.setState({
        viewState: ViewState.TABLE,
        toggleChangesMade: false,
        crudlState: CrudlStatus.LIST,
        selectedApplication: null,
      });

      // TODO use viewType constant from AppConstants
      if (this.props.viewType === ViewType.CONTACT) {
        if (format.isFunction(this.props.updateStateFromProps)) {
          this.props.updateStateFromProps("toggleApplication", false);
        }
      }

      await this.setPageData();
      this.menuState(CrudlStatus.LIST);
    } catch (error) {
      this.setError(false, "discardChanges", error);
    }
  };

  checkRequiredFields = () => {
    try {
      let fieldList = [];
      if (format.isNotNull(this.state.editedApplication)) {
        if (this.props.viewType === ViewType.STANDARD) {
          fieldList.push(this.state.editedApplication.profileList);
        }
        fieldList.push(this.state.editedApplication.title);
        fieldList.push(this.state.editedApplication.shortDescription);
        fieldList.push(this.state.editedApplication.longDescriptionText);
        fieldList.push(this.state.editedApplication.productList);
        // fieldList.push(this.state.editedApplication.pdfList);
        // fieldList.push(this.state.editedApplication.imageUriList);

        return format.validateValues(fieldList);
      } else {
        return true;
      }
    } catch (error) {
      this.setError(false, "checkRequiredFields", error);
    }
  };

  menuState = (crudlStatus) => {
    try {
      this.setState({
        menuState: pr.getBreadcrumbMenuState(
          crudlStatus,
          "Application",
          "#/applications"
        ),
      });
    } catch (error) {
      this.setError(false, "menuState", error);
    }
  };

  executeCrudl = async (stayOnPage) => {
    try {
      if (format.isNotNull(this.state.crudlState)) {
        this.props.log.info(
          "Executing " +
            this.state.crudlState.value +
            " Staying[" +
            stayOnPage +
            "]"
        );

        switch (this.state.crudlState) {
          case CrudlStatus.CREATE:
            await this.addApplication(stayOnPage);
            break;
          case CrudlStatus.UPDATE:
            await this.updateApplication(stayOnPage);
            break;
          case CrudlStatus.DELETE:
            await this.deleteApplication();
            break;
          default:
            break;
        }
      }
    } catch (error) {
      this.setError(false, "executeCrudl", error);
    }
  };

  executeSetup = async (requiredCrudlState, data) => {
    try {
      if (format.isNotNull(requiredCrudlState)) {
        this.props.log.info("Setting up " + requiredCrudlState.value);
        switch (requiredCrudlState) {
          case CrudlStatus.CREATE:
            await this.createSetup();
            break;
          case CrudlStatus.UPDATE:
            await this.updateSetup(data);
            break;
          case CrudlStatus.VIEW:
            await this.viewSetup(data);
            break;
          case CrudlStatus.DELETE:
            await this.deleteSetup(data);
            break;
          default:
            break;
        }
      }
    } catch (error) {
      this.setError(false, "executeSetup", error);
    }
  };

  setUpColumns = () => {
    let localCols = [];
    //SPECIFIC COLUMNS FOR TABLE
    if (this.props.viewType !== ViewType.COUNCIL) {
      localCols.push(pr.getTableColumn("processType", "Type"));
    }

    localCols.push(pr.getTableColumn("businessNameCodeTag", "Made By"));
    localCols.push(pr.getTableColumn("businessNameBuyerId", "Sent To"));
    localCols.push(pr.getTableColumn("productCategory", "Product Category"));
    localCols.push(pr.getTableColumn("productName", "Name"));
    localCols.push(pr.getTableColumn("dateCreatedToDisplayed", "Date Created"));
    localCols.push(
      pr.getTableColumn("dateUpdatedToDisplayed", "Last Updated Date")
    );
    localCols.push(pr.getTableColumn("status", "Status"));

    this.setState({ tableColumns: localCols });
    var colOptions = pr.getColumnOptions(localCols);
    this.setState({ columnOptions: colOptions });
  };

  columnToggle = (event) => {
    this.setState({ tableColumns: event.value });
  };

  createSetup = async () => {
    window.scrollTo(0, 0);

    var data = {};
    data = await this.additionalParseFunctions(data, CrudlStatus.CREATE);

    this.setState({
      viewState: ViewState.CARD,
      crudlState: CrudlStatus.CREATE,
      editedApplication: data,
      originalEditedApplication: JSON.stringify(data),
    });

    this.menuState(CrudlStatus.CREATE);
  };

  updateSetup = async (data) => {
    window.scrollTo(0, 0);

    data = await this.additionalParseFunctions(data, CrudlStatus.UPDATE);

    this.setState({
      viewState: ViewState.CARD,
      crudlState: CrudlStatus.UPDATE,
      editedApplication: data,
      originalEditedApplication: JSON.stringify(data),
    });

    this.menuState(CrudlStatus.UPDATE);
  };

  viewSetup = async (data) => {
    window.scrollTo(0, 0);

    data = await this.additionalParseFunctions(data, CrudlStatus.VIEW);

    this.setState({
      viewState: ViewState.CARD,
      crudlState: CrudlStatus.VIEW,
      editedApplication: data,
      originalEditedApplication: JSON.stringify(data),
    });

    this.menuState(CrudlStatus.VIEW);
  };

  deleteSetup = async (data) => {
    data = await this.additionalParseFunctions(data, CrudlStatus.DELETE);

    this.setState({
      crudlState: CrudlStatus.DELETE,
      editedApplication: data,
      originalEditedApplication: JSON.stringify(data),
      validateState: ValidateState.DELETE,
    });
    this.menuState(CrudlStatus.DELETE);
  };

  additionalParseFunctions = async (data, crudlState) => {
    switch (crudlState) {
      case CrudlStatus.CREATE:
        if (this.state.viewType === ViewType.CONTACT) {
          console.log(
            "AdditionalParseFunctions Contact",
            this.props.editedObjectFromProps
          );
          if (format.isNotNull(this.props.editedObjectFromProps)) {
            data.buyerId = this.props.editedObjectFromProps.codeTag;
            data.profileList = [];
            if (format.isNotNull(this.state.profileList)) {
              this.state.profileList.forEach((e) => {
                if (e.codeTag === this.props.editedObjectFromProps.codeTag) {
                  data.profileList.push(e.id);
                }
              });
            }

            data.productList = [];
            if (format.isNotNull(this.props.editedObjectFromProps.id)) {
              data.productList.push(this.props.editedObjectFromProps.id);
            }
          }

          if (format.isNotNull(this.state.dashboardType)) {
            data.applicationType = this.state.dashboardType;
          }
        }
        break;
      case CrudlStatus.UPDATE || CrudlStatus.VIEW:
        break;
      case CrudlStatus.DELETE:
        break;
      default:
        break;
    }
    this.props.log.info(data);
    return data;
  };

  addApplication = async (stayOnPage) => {
    this.setState({ pageState: PageState.LOADING });
    var stateVariables = this.getStateValuesAsObject();

    if (!format.isNotNull(stayOnPage) || stayOnPage === false) {
      stateVariables.viewState = ViewState.TABLE;
    } else {
      stateVariables.viewState = ViewState.CARD;
    }

    if (format.isNotNull(stateVariables.applicationBean.profileList)) {
      if (stateVariables.applicationBean.profileList.length > 0) {
        let application = format.deepCopy(stateVariables.applicationBean);
        application.profileNameList = [];
        application.productNameList = [];
        application.profileList.forEach((addedProfile) => {
          this.state.profileList.forEach((profile) => {
            if (addedProfile === profile.id) {
              let companyDetails = format.deepCopy(profile.companyDetails);
              application.profileNameList.push(companyDetails.businessName);
            }
          });
        });
        application.productList.forEach((addedProduct) => {
          this.state.productList.forEach((product) => {
            if (addedProduct === product.id) {
              application.productNameList.push(product.name);
            }
          });
        });

        application.profileList.forEach((profile) => {
          application.productList.forEach((product) => {
            let applicationEvent = {
              productName: product,
              businessName: profile,
            };
            analytics.applicationContactMultipleEvent(
              analytics.Actions.CONTACT_MULTIPLE,
              applicationEvent
            );
          });
        });
      }
    }

    var applicationResponse = await this.props.viewModel.executeCRUDLAction(
      CrudlStatus.CREATE,
      stateVariables
    );

    if (applicationResponse.errorCode === 0) {
      if (!format.isNotNull(stayOnPage) || stayOnPage === false) {
        await this.updateApplicationList(
          applicationResponse,
          CrudlStatus.CREATE,
          stateVariables
        );
      } else {
        await this.updateCurrentApplication(
          applicationResponse,
          CrudlStatus.CREATE,
          stateVariables
        );
      }
    } else {
      this.setState({ pageState: PageState.IDLE });
    }
  };
  updateApplication = async (stayOnPage) => {
    this.setState({ pageState: PageState.LOADING });
    var stateVariables = this.getStateValuesAsObject();

    if (!format.isNotNull(stayOnPage) || stayOnPage === false) {
      stateVariables.viewState = ViewState.TABLE;
    } else {
      stateVariables.viewState = ViewState.CARD;
    }

    var applicationResponse = await this.props.viewModel.executeCRUDLAction(
      CrudlStatus.UPDATE,
      stateVariables
    );

    if (applicationResponse.errorCode === 0) {
      if (this.props.viewType === ViewType.COUNCIL) {
        await this.getApplicationsCouncil();
        this.setState({
          viewState: ViewState.TABLE,
          crudlState: CrudlStatus.LIST,
          validateState: ValidateState.NONE,
        });
        this.menuState(CrudlStatus.LIST);
        this.setState({ pageState: PageState.IDLE });
      } else {
        if (!format.isNotNull(stayOnPage) || stayOnPage === false) {
          await this.updateApplicationList(
            applicationResponse,
            CrudlStatus.CREATE,
            stateVariables
          );
        } else {
          await this.updateCurrentApplication(
            applicationResponse,
            CrudlStatus.CREATE,
            stateVariables
          );
        }
      }
    } else {
      this.setState({ pageState: PageState.IDLE });
    }
  };

  updateFromTable = async (data) => {
    window.scrollTo(0, 0);

    console.log("UpdateFromTable", data);

    data = await this.additionalParseFunctions(data, CrudlStatus.UPDATE);

    this.setState({
      viewState: ViewState.TABLE,
      pageState: PageState.LOADING,
      crudlState: CrudlStatus.UPDATE,
    });

    var externalObject = {};
    externalObject.applicationBean = data;

    var applicationResponse = await this.props.viewModel.executeCRUDLAction(
      CrudlStatus.UPDATE,
      externalObject
    );

    analytics.applicationFeedbackEvent(
      analytics.Actions.PROVIDE_FEEDBACK,
      data
    );

    if (applicationResponse.errorCode === 0) {
      await this.updateApplicationList(applicationResponse, CrudlStatus.CREATE);
    } else {
      this.setState({ pageState: PageState.IDLE });
    }
  };

  updateValueTableRowInList = (field, value, rowData) => {
    console.log("Updating field [" + field + "] with value [" + value + "]");

    let applicationList = [];
    if (format.isNotNull(this.state.applicationList)) {
      this.state.applicationList.forEach((application) => {
        if (application.id === rowData.id) {
          application[field] = value;
        }
        applicationList.push(application);
      });
      this.setState({ applicationList: applicationList });
    }
  };

  deleteApplication = async (editedObject) => {
    this.setState({ pageState: PageState.LOADING });
    var stateVariables = this.getStateValuesAsObject(editedObject);

    var applicationResponse = await this.props.viewModel.executeCRUDLAction(
      CrudlStatus.DELETE,
      stateVariables
    );

    if (applicationResponse.errorCode === 0) {
      if (this.state.viewType === ViewType.COUNCIL) {
        await this.getApplicationsCouncil();
      } else {
        await this.updateApplicationList(
          applicationResponse,
          CrudlStatus.DELETE,
          stateVariables
        );
      }
    } else {
      this.setState({ pageState: PageState.IDLE });
    }
  };

  updateApplicationList = async (apiResponse, type, stateVariables) => {
    this.setState({ pageState: PageState.LOADING });

    if (this.props.viewType === ViewType.STANDARD) {
      await this.props.viewModel.getApplications();
    } else if (this.props.viewType === ViewType.ENQUIRIES) {
      await this.props.viewModel.getApplications();
    }

    if (this.props.viewType === ViewType.CONTACT) {
      if (format.isFunction(this.props.updateStateFromProps)) {
        this.props.updateStateFromProps("toggleApplication", false);
      }
    }

    this.setState({
      viewState: ViewState.TABLE,
      crudlState: CrudlStatus.LIST,
      validateState: ValidateState.NONE,
      editedApplication: null,
      selectedApplication: null,
      showDialogUpdate: false,
    });

    this.menuState(CrudlStatus.LIST);

    this.setState({ pageState: PageState.IDLE });
  };

  updateCurrentApplication = async (apiResponse, type, stateVariables) => {
    this.setState({ pageState: PageState.LOADING });

    var applicationList = await this.props.viewModel.getApplicationsReturned();
    var application = {};
    if (format.isNotNull(applicationList)) {
      applicationList.forEach((element) => {
        if (element.id === apiResponse.id) {
          application = format.deepCopy(element);
        }
      });
    }
    await this.updateSetup(application);
    this.setState({ applicationList: applicationList });
    this.setState({ pageState: PageState.IDLE });
  };

  requestFeedback = async (data) => {
    this.setState({ pageState: PageState.LOADING });
    let request = format.deepCopy(data);
    delete request.productDetailsList;
    // request.feedbackAttemptCount;

    let applicationResponse =
      await this.props.viewModel.applicationFeedbackRequest(request);
    if (applicationResponse.errorCode === 0) {
      this.props.feedback.showGeneric(
        this.growl,
        "Feedback Requested Successfully"
      );

      analytics.applicationFeedbackEvent(
        analytics.Actions.REQUEST_FEEDBACK,
        request
      );

      if (this.props.viewType === ViewType.STANDARD) {
        await this.props.viewModel.getApplications();
      }
    } else {
      this.props.feedback.showGeneric(this.growl, "Failed to request feedback");
    }
    this.setState({ pageState: PageState.IDLE });
  };

  displayBusinessDetails = (businessToDisplay) => {
    analytics.selectBusinessEvent(this.props.viewType, businessToDisplay);
    this.setState({
      viewBusinessId: businessToDisplay,
      toggleViewBusinessDetails: true,
    });
  };

  getApplicationsCouncil = async () => {
    this.setState({ pageState: PageState.LOADING });
    // Double check roleTypes here ensuring council user
    if (
      localStorage.getItem("roleType") === "10" ||
      localStorage.getItem("roleType") === "20"
    ) {
      await this.props.viewModel.getApplicationsByApplicationType(
        this.state.applicationType,
        this.state.searchArchived
      );
    }
  };
  archiveApplication = async (archiveFrom, editedObject) => {
    this.setState({ pageState: PageState.LOADING });

    if (format.isNotNull(archiveFrom) && archiveFrom === "Council") {
      editedObject.archivedCouncil = true;
    }

    var stateVariables = this.getStateValuesAsObject(editedObject);

    stateVariables.viewState = ViewState.TABLE;

    var applicationResponse = await this.props.viewModel.executeCRUDLAction(
      CrudlStatus.UPDATE,
      stateVariables
    );

    if (applicationResponse.errorCode === 0) {
      await this.getApplicationsCouncil();
    }

    this.setState({ pageState: PageState.IDLE });
  };
  toggleUpdateApplication = async (editedObject) => {
    console.log("Toggle Update Application");
    await this.updateSetup(editedObject);
  };

  render() {
    return (
      <React.Fragment>
        <pr.Messages
          style={{ textAlign: "left" }}
          ref={(el) => (this.growl = el)}
        />

        {this.state.pageState === PageState.ERROR && (
          <ErrorScreen
            loading={this.state.pageState === PageState.LOADING}
            refresh={this.setPageData}
          />
        )}

        {this.state.pageState !== PageState.ERROR && (
          <ApplicationView
            //STATE + HELPER VALUES
            viewState={this.state.viewState}
            validateState={this.state.validateState}
            crudlState={this.state.crudlState}
            menuState={this.state.menuState}
            loading={this.state.pageState === PageState.LOADING}
            updateState={this.updateState}
            refresh={this.setPageData}
            feedback={this.props.feedback}
            receivedFeedback={this.state.receivedFeedback}
            //CARD SPECIFIC PROPS
            viewType={this.state.viewType}
            editedObject={this.state.editedApplication}
            applicationTypeOptions={this.state.applicationTypeOptions}
            updateEdited={this.updateBean}
            validateChanges={this.validateChanges}
            discardChanges={this.discardChanges}
            checkRequiredFields={this.checkRequiredFields}
            crudlExecute={this.executeCrudl}
            sidebarWidth={this.state.sidebarWidth}
            //TABLE SPECIFIC PROPS
            crudlControl={this.executeSetup}
            applicationList={this.state.applicationList}
            expandedRows={this.state.expandedRows}
            tableColumns={this.state.tableColumns}
            columnOptions={this.state.columnOptions}
            columnToggle={this.columnToggle}
            tableReference={this.state.tableReference}
            settingsToggle={this.state.settingsToggle}
            productDetails={this.state.productDetails}
            productDetailsView={this.state.productDetailsView}
            updateFromTable={this.updateFromTable}
            businessOptions={this.state.businessOptions}
            productOptions={this.state.productOptions}
            globalFilter={this.state.globalFilter}
            requestFeedback={this.requestFeedback}
            profileList={this.state.profileList}
            productList={this.state.productList}
            updateValueTableRowInList={this.updateValueTableRowInList}
            showDialogUpdate={this.state.showDialogUpdate}
            displayBusinessDetails={this.displayBusinessDetails}
            toggleViewBusinessDetails={this.state.toggleViewBusinessDetails}
            viewBusinessId={this.state.viewBusinessId}
            applicationType={this.state.applicationType}
            searchArchived={this.state.searchArchived}
            getApplicationsCouncil={this.getApplicationsCouncil}
            archiveApplication={this.archiveApplication}
            toggleUpdateApplication={this.toggleUpdateApplication}
            deleteApplication={this.deleteApplication}
            showTutorial={this.state.showTutorial}
          />
        )}
      </React.Fragment>
    );
  }
}
