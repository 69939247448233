import * as log from "./Feedback/Log";
import React from "react";
import { CrudlStatus } from "./config/AppConstants";
//General
export const formatId = (id) => {
  let reformattedId = "Not Set";

  if (id) {
    reformattedId = id.substring(3).replace(/^0+/, "");
  }

  return reformattedId;
};

export const formatDate = (date, format) => {
  let reformattedDate = "Not Set";

  if (date) {
    reformattedDate = JSON.stringify(date)
      .replace(/"/g, "")
      .replace("T", " ")
      .replace("Z", "");
    if (reformattedDate.includes("+")) {
      reformattedDate = reformattedDate.slice(0, -6);
    }
    var day = "";
    var month = "";
    var year = "";
    var hour = "";
    var minute = "";
    var second = "";

    switch (format) {
      case "YYYYMMDDHHMMSS":
        reformattedDate = reformattedDate.slice(0, -4);
        break;

      case "YYYYMMDDHHMM":
        reformattedDate = reformattedDate.slice(0, -7);
        break;

      case "YYYYMMDDHH":
        reformattedDate = reformattedDate.slice(0, -10);
        break;

      case "YYYYMMDD":
        reformattedDate = reformattedDate.slice(0, -13);
        break;

      case "YYMMDD":
        reformattedDate = reformattedDate.slice(2, -13);
        break;

      case "DDMMYY":
        console.log(reformattedDate);
        day = reformattedDate.slice(8, -13);
        month = reformattedDate.slice(5, -16);
        year = reformattedDate.slice(2, -19);
        reformattedDate = day + "-" + month + "-" + year;
        break;
      case "DDMMYYYY":
        day = reformattedDate.slice(8, -13);
        month = reformattedDate.slice(5, -16);
        year = reformattedDate.slice(0, -19);
        reformattedDate = day + "-" + month + "-" + year;
        break;

      case "DDMMYYYYHHMMSS":
        day = reformattedDate.slice(8, -13);
        month = reformattedDate.slice(5, -16);
        year = reformattedDate.slice(0, -19);
        hour = reformattedDate.slice(11, -10);
        minute = reformattedDate.slice(14, -7);
        second = reformattedDate.slice(17, -4);
        reformattedDate =
          day +
          "-" +
          month +
          "-" +
          year +
          " " +
          hour +
          ":" +
          minute +
          ":" +
          second;
        break;

      case "DDMMYYYYHHMM":
        day = reformattedDate.slice(8, -13);
        month = reformattedDate.slice(5, -16);
        year = reformattedDate.slice(0, -19);
        hour = reformattedDate.slice(11, -10);
        minute = reformattedDate.slice(14, -7);
        reformattedDate =
          day + "-" + month + "-" + year + " " + hour + ":" + minute;
        break;

      case "DDMMYYYYHH":
        day = reformattedDate.slice(8, -13);
        month = reformattedDate.slice(5, -16);
        year = reformattedDate.slice(0, -19);
        hour = reformattedDate.slice(11, -10);
        reformattedDate = day + "-" + month + "-" + year + " " + hour;
        break;

      default:
    }
  }

  return reformattedDate;
};

export const getCurrentDate = () => {
  var today = new Date();

  var date =
    today.getFullYear() + "/" + (today.getMonth() + 1) + "/" + today.getDate();

  return date;
};

export const getDifferenceInTwoDates = (d1, d2) => {
  var date1 = d1;
  var date2 = d2;

  // To calculate the time difference of two dates
  var Difference_In_Time = date2.getTime() - date1.getTime();

  // To calculate the no. of days between two dates
  var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  return Difference_In_Days;
};

export const getDateFromUnixTimestamp = (UNIX_Timestamp, dateFormat) => {
  var a = new Date(UNIX_Timestamp * 1000);
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes() < 10 ? "0" + a.getMinutes() : a.getMinutes();
  var sec = a.getSeconds() < 10 ? "0" + a.getSeconds() : a.getSeconds();
  var time = "";
  if (dateFormat) {
    if (dateFormat === "dd/mm/yyyy") {
      time = date + " " + month + " " + year;
    } else {
      time =
        date + "/" + a.month + "/" + year + " " + hour + ":" + min + ":" + sec;
    }
  } else {
    time = date + "/" + a.month + "/" + year;
  }

  return time;
};

export const isDateLessThanCurrentDate = (d1) => {
  console.log(d1);
  var date1 = new Date(d1);
  var date2 = new Date();

  // To calculate the time difference of two dates
  var booleanValue = date1.getTime() <= date2.getTime();
  // console.log(
  //   "Comparing Date [" +
  //     date1 +
  //     "] to Current Date [" +
  //     date2 +
  //     "] RESULT [" +
  //     booleanValue +
  //     "]"
  // );
  return booleanValue;
};

export const addDaysToDate = (date, days) => {
  var originalDate = new Date(date);
  var newDate = new Date();

  console.log(originalDate.getDate() + days);
  newDate.setDate(originalDate.getDate() + days);
  console.log(newDate);
  return newDate;
};

export const isTodayWithinDates = (d1, d2) => {
  var todaysDate = new Date();
  var date1 = new Date(d1);
  var date2 = new Date(d2);
  // console.log(date1);
  // console.log(date2);
  // console.log(todaysDate);

  // To calculate the time difference of two dates
  var isBetweenTwoDates = false;
  if (
    date1.getTime() <= todaysDate.getTime() &&
    date2.getTime() >= todaysDate.getTime()
  ) {
    // console.log("isBetweenTwoDates", isBetweenTwoDates);
    isBetweenTwoDates = true;
  } else {
    // console.log("isBetweenTwoDates", isBetweenTwoDates);
  }

  return isBetweenTwoDates;
};

export const priceToDisplay = (total, currency) => {
  var currencySymbol = "£"; // default
  var displayPrice = "";
  if (!isNotNull(total)) {
    displayPrice = currencySymbol + 0;
    return displayPrice;
  }
  total = parseFloat(total).toFixed(2);
  var totalStr = total.toString();

  if (currency === "gbp") {
    currencySymbol = "£";
  }
  if (currency === "usd") {
    currencySymbol = "$";
  }

  var extraLength = 0;
  // check if decimal exists
  if (!Number.isInteger(total)) {
    extraLength = 3;
  }
  // E.G. 1,000
  if (totalStr.length === 4 + extraLength) {
    totalStr =
      totalStr.substring(0, 1) + "," + totalStr.substring(1, totalStr.length);
    displayPrice = currencySymbol + totalStr;
    return displayPrice;
  }
  // E.G. 10,000
  if (totalStr.length === 5 + extraLength) {
    totalStr =
      totalStr.substring(0, 2) + "," + totalStr.substring(2, totalStr.length);
    displayPrice = currencySymbol + totalStr;
    return displayPrice;
  }
  // E.G. 100,000
  if (totalStr.length === 6 + extraLength) {
    totalStr =
      totalStr.substring(0, 3) + "," + totalStr.substring(3, totalStr.length);
    displayPrice = currencySymbol + totalStr;
    return displayPrice;
  }
  // E.G. 1,000,000
  if (totalStr.length === 7 + extraLength) {
    totalStr =
      totalStr.substring(0, 1) +
      "," +
      totalStr.substring(1, 4) +
      "," +
      totalStr.substring(4, totalStr.length);
    displayPrice = currencySymbol + totalStr;
    return displayPrice;
  }
  // E.G. 10,000,000
  if (totalStr.length === 8 + extraLength) {
    totalStr =
      totalStr.substring(0, 2) +
      "," +
      totalStr.substring(2, 5) +
      "," +
      totalStr.substring(5, totalStr.length);
    displayPrice = currencySymbol + totalStr;
    return displayPrice;
  }
  // E.G. 100,000,000
  if (totalStr.length === 9 + extraLength) {
    totalStr =
      totalStr.substring(0, 3) +
      "," +
      totalStr.substring(3, 6) +
      "," +
      totalStr.substring(6, totalStr.length);
    displayPrice = currencySymbol + totalStr;
    return displayPrice;
  }
  return (displayPrice = currencySymbol + totalStr);
};

export const formatPrice = (pence, currency) => {
  var currencySymbol = "£"; // default
  var price = "";
  var displayPrice = "";

  if (currency === "gbp") {
    currencySymbol = "£";
  }
  if (currency === "usd") {
    currencySymbol = "$";
  }

  if (pence) {
    price = pence / 100;
  }
  price = price.toString();
  if (price.includes(".")) {
    var res = price.split(".");
    var pen = res[1];

    if (pen.length === 0) {
      // add dropped 0
      price = price + "00";
    } else if (pen.length === 1) {
      // add dropped 0
      price = price + "0";
    }
  } else {
    // assume .00 has been dropped
    price = price + ".00";
  }

  displayPrice = currencySymbol + price;

  return displayPrice;
};

export const getEmbeddedDatePropertyFormatted = (
  object,
  propertyName,
  format
) => {
  var date = getEmbeddedProperty(object, propertyName);
  if (date) {
    return formatDate(date, format);
  } else {
    return "";
  }
};

export const cloneArray = (array) => {
  if (Array.isArray(array)) {
    return deepCopy(array);
  } else {
    return {};
  }
};
export const deepCopy = (object) => {
  try {
    return JSON.parse(JSON.stringify(object));
  } catch (error) {
    log.error(error.message);
    return null;
  }
};

export const parsePojoRow = (id, name, quantity) => {
  var pojo = {};
  pojo.id = id;
  pojo.quantity = quantity;
  pojo.name = name;
  return pojo;
};
export const parsePojoList = (pojoList) => {
  var pojoJSON = JSON.stringify(pojoList);
  return pojoJSON;
};

export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
export const setCrudlStatus = (object) => {
  if (isNotNull(object)) {
    if (object.id && object.lastUpdatedDateTime && object.dateCreated) {
      object.crudlStatus = CrudlStatus.UPDATE.value;
    } else {
      object.crudlStatus = CrudlStatus.CREATE.value;
    }
  }
};
export const checkAndSetId = (object, prefix) => {
  if (isNotNull(object)) {
    if (object.crudlStatus === CrudlStatus.CREATE.value) {
      if (!object.id) {
        if (prefix) {
          object.id = prefix + Date.now().toString();
        } else {
          object.id = Date.now().toString();
        }
      }
    }
  }
};
export const removeTemplateFields = (object, originalTemplateId) => {
  if (isNotNull(object)) {
    object.templateId = originalTemplateId;
    delete object.id;
    delete object.lastUpdatedDateTime;
    delete object.dateCreated;
    delete object.template;
  }
};
export const isNotNull = (object) => {
  if (object === null) {
    return false;
  }
  if (object === undefined) {
    return false;
  }
  if (Array.isArray(object) && object.length === 0) {
    return false;
  }
  if (object === "") {
    return false;
  }
  if (object === "undefined") {
    return false;
  }
  return true;
};

export const getEmbeddedProperty = (object, propertyName) => {
  if (!isNotNull(object)) {
    return "";
  } else {
    if (isNotNull(object[propertyName])) {
      return object[propertyName];
    } else {
      return "";
    }
  }
};

export const getBooleanValue = (object, propertyName) => {
  var boolean = getEmbeddedProperty(object, propertyName);
  if (boolean) {
    console.log("Returning YES for Boolean");
    return "Yes";
  } else {
    console.log("Returning NO for Boolean");
    return "No";
  }
};

export const validateValues = (fieldList) => {
  let disable = false;
  if (isNotNull(fieldList)) {
    if (Array.isArray(fieldList)) {
      fieldList.forEach((element) => {
        if (!element) {
          disable = true;
        }
      });
    } else {
      if (!fieldList) {
        disable = true;
      }
    }
  }
  return disable;
};

export const isJsonEqual = (original, current) => {
  if (original === JSON.stringify(current)) {
    return true;
  } else {
    return false;
  }
};

export const isFunction = (value) => {
  let isFunctionResult =
    value &&
    (Object.prototype.toString.call(value) === "[object Function]" ||
      "function" === typeof value ||
      value instanceof Function);

  return isFunctionResult;
};

export const readInAddressDetails = (object) => {
  if (isNotNull(object)) {
    let addressLine1 = getEmbeddedProperty(object, "addressLine1");
    let addressLine2 = getEmbeddedProperty(object, "addressLine2");
    let addressLine3 = getEmbeddedProperty(object, "addressLine3");
    let addressLine4 = getEmbeddedProperty(object, "addressLine4");
    let city = getEmbeddedProperty(object, "city");
    let postcode = getEmbeddedProperty(object, "postalZipCode");

    return (
      addressLine1 +
      "\n" +
      addressLine2 +
      "\n" +
      addressLine3 +
      "\n" +
      addressLine4 +
      "\n" +
      city +
      "\n" +
      postcode
    );
  } else {
    return "";
  }
};
export const readInCustomerAddressDetails = (customerId, list) => {
  if (isNotNull(customerId)) {
    if (isNotNull(list)) {
      var object = {};
      list.forEach((element) => {
        if (element.id === customerId) {
          object = deepCopy(element);
        }
      });
      let addressLine1 = getEmbeddedProperty(object, "addressLine1");
      let addressLine2 = getEmbeddedProperty(object, "addressLine2");
      let city = getEmbeddedProperty(object, "city");
      let postcode = getEmbeddedProperty(object, "postalZipCode");

      return addressLine1 + "\n" + addressLine2 + "\n" + city + "\n" + postcode;
    } else {
      return "";
    }
  } else {
    return "";
  }
};
export const setRecordBean = (propertyName, propertyType) => {
  return { property: propertyName, storageType: propertyType };
};

export const displayVatRatesUsed = (vatJson) => {
  var json = {};

  if (isNotNull(vatJson)) {
    json = JSON.parse(vatJson);
  }

  var componentArray = [];
  var index = 0;
  if (isNotNull(json.vatRates)) {
    json.vatRates.forEach((e) => {
      var name = "Vat at " + e.rate + "%";
      componentArray[index] = displaySummaryRow(
        name,
        e.amountToDisplay,
        e.rate
      );
      index++;
    });
  }
  return componentArray;
};

export const displaySummaryRow = (name, value, rate) => {
  return (
    <div className="p-col-12 row" key={rate}>
      <div
        className="p-col-6 cell"
        style={{
          float: "right",
          fontSize: "medium",
        }}
      >
        {name}
      </div>
      <div className="p-col-6 cell" style={{ fontSize: "medium" }}>
        {value || "Not set"}
      </div>
    </div>
  );
};

/**
 {
   "vatRates":[
     {
       "rate": 0,
       "sequence": 1,
       "amount": 1000,
       "amountToDisplay": "£1000.00"
     },
     {
       "rate": 20,
       "sequence": 2,
       "amount": 2000,
       "amountToDisplay": "£2000.00"
     },
     {
       "rate": 40,
       "sequence": 3,
       "amount": 3000,
       "amountToDisplay": "£3000.00"
     }
   ]
 }
 */
export const updateVatAmountPerRate = (vatJson, vatRate, vat) => {
  var json = {};

  if (isNotNull(vatJson)) {
    json = JSON.parse(vatJson);
  }

  if (isNotNull(json.vatRates)) {
    var addedToArray = false;
    json.vatRates.forEach((e) => {
      if (isNotNull(e.rate)) {
        if (e.rate === vatRate) {
          e.amount = e.amount + vat;
          e.amountToDisplay = priceToDisplay(formatValue(e.amount));
          addedToArray = true;
        }
      }
    });
    // rate does not exist in vatRates - Create new Obj
    if (addedToArray === false) {
      let vatObj = {};
      vatObj.rate = vatRate;
      vatObj.amount = vat;
      vatObj.amountToDisplay = priceToDisplay(formatValue(vat));
      json.vatRates.push(vatObj);
    }
  } else {
    // setting for first time
    json.vatRates = [];
    let vatObj = {};
    vatObj.rate = vatRate;
    vatObj.amount = vat;
    vatObj.amountToDisplay = priceToDisplay(formatValue(vat));
    json.vatRates.push(vatObj);
  }

  return JSON.stringify(json);
};

export const formatValue = (value, decimalPlaces) => {
  if (!isNotNull(decimalPlaces)) {
    decimalPlaces = 2;
  }
  if (!isNotNull(value)) {
    value = 0;
  }
  return parseFloat(value).toFixed(decimalPlaces);
};

export const concatenatePairOfArraysToSet = (array1, array2) => {
  var ids = new Set(array1.map((d) => d.id));
  var merged = [...array1, ...array2.filter((d) => !ids.has(d.id))];
  return merged;
};

export const formatOperatingStatus = (status) => {
  var returnStatus = "";
  switch (status) {
    case "IDLE_WITHOUT_WIP":
      returnStatus = "IDLE WITHOUT WIP";
      break;

    case "PLANNED_STOP":
      returnStatus = "PLANNED STOP";
      break;

    case "ACTIVE":
      returnStatus = "ACTIVE";
      break;

    case "IDLE_WITH_WIP":
      returnStatus = "IDLE WITH WIP";
      break;

    case "UNPLANNED_STOP":
      returnStatus = "UNPLANNED STOP";
      break;

    default:
      returnStatus = status;
      break;
  }

  return returnStatus;
};

export const formatStringDate = (date) => {
  var reformattedDate = date.slice(0, -40);

  return reformattedDate;
};
