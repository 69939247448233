import React, { Component } from "react";
import classNames from "classnames";
import * as pr from "./common/PrimeComponents";
import { MasterTopbar } from "./MasterTopbar";
import { MasterInlineProfile } from "./MasterInlineProfile";
import { MasterFooterImage } from "./MasterFooterImage";
import { AppMenu } from "./AppMenu";
import { AppRightPanel } from "./AppRightPanel";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Route } from "react-router-dom";

//
import { DashboardProvider } from "./components/Dashboard/DashboardProvider";
import { ApplicationProvider } from "./components/Application/ApplicationProvider";
import { BusinessProvider } from "./components/Business/BusinessProvider";
import { CategoryProvider } from "./components/Category/CategoryProvider";
import { ProductProvider } from "./components/Product/ProductProvider";
import { ProfileProvider } from "./components/Profile/ProfileProvider";
import { UserProvider } from "./components/User/UserProvider";
import { ConfigurationProvider } from "./components/Configuration/ConfigurationProvider";
import { PPEProvider } from "./components/PPE/PPEProvider";
import { ReportingProvider } from "./components/Reporting/ReportingProvider";
import { CouncilGapAnalysis } from "./components/CouncilGapAnalysis/CouncilGapAnalysis";
import { HelpDesk } from "./components/HelpDesk/HelpDesk";

import { CloudService } from "./common/CloudService/ClientLibsReference";
import { api } from "./common/CloudService/ApiPromisify";

// For switching to PPE or BuySupply
import { Scenario1 } from "./components/Switch/Scenario1";
import { Scenario2 } from "./components/Switch/Scenario2";
import * as analytics from "./common/helpers/firebaseAnalytics";

import "./ripple.js";
import "./Master.css";

import {
  BusinessType,
  CrudlStatus,
  ViewType,
} from "./common/config/AppConstants";

export class Master extends Component {
  constructor() {
    super();
    this.state = {
      layoutMode: "static",
      profileMode: "inline",
      overlayMenuActive: false,
      staticMenuDesktopInactive: false,
      staticMenuMobileActive: false,
      rotateMenuButton: false,
      topbarMenuActive: false,
      activeTopbarItem: null,
      darkMenu: false,
      rightPanelActive: false,
      menuActive: false,
    };

    this.onDocumentClick = this.onDocumentClick.bind(this);
    this.onMenuClick = this.onMenuClick.bind(this);
    this.onMenuButtonClick = this.onMenuButtonClick.bind(this);
    this.onTopbarMenuButtonClick = this.onTopbarMenuButtonClick.bind(this);
    this.onTopbarItemClick = this.onTopbarItemClick.bind(this);
    this.onMenuItemClick = this.onMenuItemClick.bind(this);
    this.onRootMenuItemClick = this.onRootMenuItemClick.bind(this);
    this.onRightPanelButtonClick = this.onRightPanelButtonClick.bind(this);
    this.onRightPanelClick = this.onRightPanelClick.bind(this);
    this.createMenu();
  }

  onMenuClick(event) {
    this.menuClick = true;

    if (!this.isHorizontal()) {
      setTimeout(() => {
        this.layoutMenuScroller.moveBar();
      }, 500);
    }
  }

  onMenuButtonClick(event) {
    this.menuClick = true;
    this.setState({
      rotateMenuButton: !this.state.rotateMenuButton,
      topbarMenuActive: false,
    });

    if (this.state.layoutMode === "overlay") {
      this.setState({
        overlayMenuActive: !this.state.overlayMenuActive,
      });
    } else {
      if (this.isDesktop())
        this.setState({
          staticMenuDesktopInactive: !this.state.staticMenuDesktopInactive,
        });
      else
        this.setState({
          staticMenuMobileActive: !this.state.staticMenuMobileActive,
        });
    }

    event.preventDefault();
  }

  onTopbarMenuButtonClick(event) {
    this.topbarItemClick = true;
    this.setState({ topbarMenuActive: !this.state.topbarMenuActive });
    this.hideOverlayMenu();
    event.preventDefault();
  }

  onTopbarItemClick(event) {
    this.topbarItemClick = true;

    if (this.state.activeTopbarItem === event.item)
      this.setState({ activeTopbarItem: null });
    else this.setState({ activeTopbarItem: event.item });

    event.originalEvent.preventDefault();
  }

  onMenuItemClick(event) {
    if (!event.item.items) {
      this.hideOverlayMenu();
    }
    if (!event.item.items && (this.isHorizontal() || this.isSlim())) {
      this.setState({
        menuActive: false,
      });
    }
  }

  onRootMenuItemClick(event) {
    this.setState({
      menuActive: !this.state.menuActive,
    });

    event.originalEvent.preventDefault();
  }

  onRightPanelButtonClick(event) {
    this.rightPanelClick = true;
    this.setState({
      rightPanelActive: !this.state.rightPanelActive,
    });
    event.preventDefault();
  }

  onRightPanelClick(event) {
    this.rightPanelClick = true;
  }

  onDocumentClick(event) {
    if (!this.topbarItemClick) {
      this.setState({
        activeTopbarItem: null,
        topbarMenuActive: false,
      });
    }

    if (!this.menuClick) {
      if (this.isHorizontal() || this.isSlim()) {
        this.setState({
          menuActive: false,
        });
      }

      this.hideOverlayMenu();
    }

    if (!this.rightPanelClick) {
      this.setState({
        rightPanelActive: false,
      });
    }

    this.topbarItemClick = false;
    this.menuClick = false;
    this.rightPanelClick = false;
  }

  hideOverlayMenu() {
    this.setState({
      rotateMenuButton: false,
      overlayMenuActive: false,
      staticMenuMobileActive: false,
    });
  }

  isTablet() {
    let width = window.innerWidth;
    return width <= 1024 && width > 640;
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }

  isMobile() {
    return window.innerWidth <= 640;
  }

  isOverlay() {
    return this.state.layoutMode === "overlay";
  }

  isHorizontal() {
    return this.state.layoutMode === "horizontal";
  }

  isSlim() {
    return this.state.layoutMode === "slim";
  }

  changeTheme(theme) {
    this.changeStyleSheetUrl("layout-css", theme, "layout");
    this.changeStyleSheetUrl("theme-css", theme, "theme");
  }

  changeStyleSheetUrl(id, value, prefix) {
    let element = document.getElementById(id);
    let urlTokens = element.getAttribute("href").split("/");
    urlTokens[urlTokens.length - 1] = prefix + "-" + value + ".css";
    let newURL = urlTokens.join("/");
    element.setAttribute("href", newURL);
  }

  createMenu() {
    if (
      localStorage.getItem("roleType") === "10" ||
      localStorage.getItem("roleType") === "20"
    ) {
      this.menu = [
        {
          label: "Dashboard",
          icon: "dashboard",
          command: () => {
            window.location = "#/council_dashboard";
          },
        },
        {
          label: "View All Businesses",
          icon: "build",
          command: () => {
            window.location = "#/all_profiles";
          },
        },
        {
          label: "Overview of Applications",
          icon: "move_to_inbox",
          command: () => {
            window.location = "#/overview_of_applications";
          },
        },
        {
          label: "Configurables",
          icon: "settings",
          command: () => {
            window.location = "#/configuration";
          },
        },
        {
          label: "Gap Analysis",
          icon: "border_all",
          command: () => {
            window.location = "#/Council_Gap_Analysis";
          },
        },
        {
          label: "Reporting",
          icon: "border_all",
          command: () => {
            window.location = "#/reporting";
          },
        },
        // {
        //   label: "Review Interactions",
        //   icon: "compare_arrows",
        //   command: () => {
        //     window.location = "#/Council_Review_Interactions";
        //   },
        // },
        // {
        //   label: "PPE Reports",
        //   icon: "description",
        //   command: () => {
        //     window.location = "#/PPE_Reporting";
        //   },
        // },
      ];
    } else {
      if (localStorage.getItem("profileType") === BusinessType.BUYER) {
        this.menu = [
          {
            label: "Dashboard",
            icon: "dashboard",
            command: () => {
              window.location = "#/dashboard";
            },
          },
          {
            label: "My Requirements",
            icon: "web",
            command: () => {
              window.location = "#/my_requirement";
            },
          },
          {
            label: "Suppliers Products & Services",
            icon: "web",
            command: () => {
              window.location = "#/supplier_products";
            },
          },
          {
            label: "View All Suppliers",
            icon: "web",
            command: () => {
              window.location = "#/supplier_profiles";
            },
          },
          {
            label: "Enquiries",
            icon: "archive",
            command: () => {
              window.location = "#/enquiries";
            },
          },
        ];
      } else if (
        localStorage.getItem("profileType") === BusinessType.SUPPLIER
      ) {
        this.menu = [
          {
            label: "Dashboard",
            icon: "dashboard",
            command: () => {
              window.location = "#/dashboard";
            },
          },
          {
            label: "My Products",
            icon: "web",
            command: () => {
              window.location = "#/my_product";
            },
          },
          {
            label: "Buyer Requirements",
            icon: "web",
            command: () => {
              window.location = "#/buyer_requirements";
            },
          },
          {
            label: "View All Buyers",
            icon: "web",
            command: () => {
              window.location = "#/buyer_profiles";
            },
          },
          // {
          //   label: "Form Requests",
          //   icon: "notifications",
          //   command: () => {
          //     window.location = "#/Form_Requests_Supplier";
          //   },
          // },
          {
            label: "Enquiries",
            icon: "archive",
            command: () => {
              window.location = "#/enquiries";
            },
          },
        ];
      } else if (
        localStorage.getItem("profileType") === BusinessType.BUYERSUPPLIER
      ) {
        this.menu = [
          {
            label: "Dashboard",
            icon: "dashboard",
            command: () => {
              window.location = "#/dashboard";
            },
          },
          {
            label: "My Products",
            icon: "web",
            command: () => {
              window.location = "#/my_product";
            },
          },
          {
            label: "My Requirements",
            icon: "web",
            command: () => {
              window.location = "#/my_requirement";
            },
          },
          {
            label: "Suppliers Products & Services",
            icon: "web",
            command: () => {
              window.location = "#/supplier_products";
            },
          },
          {
            label: "Buyer Requirements",
            icon: "web",
            command: () => {
              window.location = "#/buyer_requirements";
            },
          },
          {
            label: "View All Buyers",
            icon: "web",
            command: () => {
              window.location = "#/buyer_profiles";
            },
          },
          {
            label: "View All Suppliers",
            icon: "web",
            command: () => {
              window.location = "#/supplier_profiles";
            },
          },
          // {
          //   label: "Form Requests",
          //   icon: "notifications",
          //   command: () => {
          //     window.location = "#/Form_Requests_Supplier";
          //   },
          // },
          {
            label: "Enquiries",
            icon: "archive",
            command: () => {
              window.location = "#/enquiries";
            },
          },
        ];
      }
    }
  }

  checkForPPEProfile = async () => {
    this.setState({ profileLoading: true });
    var [value, status] = await this.getProfile();
    analytics.logEvent(analytics.Actions.SWITCH_TO_PPE);
    if (value === 1) {
      this.setState({ scenario1SideBar: true });
    }
    if (value === 2) {
      this.setState({ scenario2SideBar: true, ppeStatus: status });
    }
    if (value === 3) {
      //Your dead on here
    }
    this.setState({ profileLoading: false });
  };
  getProfile = async () => {
    let profileRequest = {};
    profileRequest.codeTag = localStorage.getItem("clientId");

    var returnValue = 0;
    var returnStatus = "";
    await api("profileApiV1ProfileGetByCodeTag", profileRequest).then((res) => {
      if (res.data) {
        let profileResponse = CloudService.ProfileResponse.constructFromObject(
          res.data
        );
        var validPPEProfile = "";
        var validPPEProfileStatus = "";
        if (Array.isArray(profileResponse.profileList)) {
          profileResponse.profileList.forEach((element) => {
            if (element.type === "PPESupplier") {
              validPPEProfile = "PPESupplier";
              validPPEProfileStatus = element.profileStatus;
            }
            if (element.type === "PPEBuyer") {
              validPPEProfile = "PPEBuyer";
              validPPEProfileStatus = element.profileStatus;
            }
            if (element.type === "Both") {
              validPPEProfile = "Both";
              validPPEProfileStatus = element.profileStatus;
            }
          });
        }

        //TEST A
        //validPPEProfile = "";
        if (validPPEProfile === "") {
          returnValue = 1;
          //Use Case 1 - Need to Create Profile
        } else {
          if (validPPEProfileStatus !== "Approved") {
            // Use Case 2 need to Diplsy information about not yet being approved
            returnValue = 2;
            returnStatus = validPPEProfileStatus;
          } else {
            localStorage.setItem("profileStatus", validPPEProfileStatus);
            localStorage.setItem("profileType", "PPE");
            localStorage.setItem("validPPEProfile", validPPEProfile);
            window.location = "#/Dashboard";
            window.location.reload();

            returnValue = 3;
          }
        }
      }
    });

    return [returnValue, returnStatus];
  };
  render() {
    let layoutContainerClassName = classNames("layout-container", {
      "menu-layout-static": this.state.layoutMode !== "overlay",
      "menu-layout-overlay": this.state.layoutMode === "overlay",
      "layout-menu-overlay-active": this.state.overlayMenuActive,
      "menu-layout-slim": this.state.layoutMode === "slim",
      "menu-layout-horizontal": this.state.layoutMode === "horizontal",
      "layout-menu-static-inactive": this.state.staticMenuDesktopInactive,
      "layout-menu-static-active": this.state.staticMenuMobileActive,
    });
    let menuClassName = classNames("layout-menu", {
      "layout-menu-dark": this.state.darkMenu,
    });

    return (
      <div className="layout-wrapper" onClick={this.onDocumentClick}>
        <div
          ref={(el) => (this.layoutContainer = el)}
          className={layoutContainerClassName}
        >
          {this.state.scenario1SideBar && (
            <pr.Sidebar
              visible={true}
              position="left"
              fullScreen={false}
              onHide={() => {
                this.setState({ scenario1SideBar: false });
              }}
              baseZIndex={10000000000}
              style={{
                width: "50%",
              }}
            >
              <Scenario1
                close={() => {
                  this.setState({ scenario1SideBar: false });
                }}
              ></Scenario1>
            </pr.Sidebar>
          )}

          <pr.Sidebar
            visible={this.state.scenario2SideBar}
            position="left"
            fullScreen={false}
            onHide={() => {
              this.setState({ scenario2SideBar: false });
            }}
            baseZIndex={10000000000}
            style={{
              width: "50%",
            }}
          >
            <Scenario2
              status={this.state.ppeStatus}
              close={() => {
                this.setState({ scenario2SideBar: false });
              }}
            ></Scenario2>
          </pr.Sidebar>
          <MasterTopbar
            profileMode={this.state.profileMode}
            horizontal={this.props.horizontal}
            topbarMenuActive={this.state.topbarMenuActive}
            activeTopbarItem={this.state.activeTopbarItem}
            onMenuButtonClick={this.onMenuButtonClick}
            onTopbarMenuButtonClick={this.onTopbarMenuButtonClick}
            onTopbarItemClick={this.onTopbarItemClick}
            onRightPanelButtonClick={this.onRightPanelButtonClick}
          />

          <div className={menuClassName} onClick={this.onMenuClick}>
            <pr.ScrollPanel
              ref={(el) => (this.layoutMenuScroller = el)}
              style={{ height: "100%" }}
            >
              <div className="menu-scroll-content" style={{ fontSize: "13px" }}>
                {this.state.profileMode === "inline" &&
                  this.state.layoutMode !== "horizontal" && (
                    <MasterInlineProfile />
                  )}
                <AppMenu
                  model={this.menu}
                  onMenuItemClick={this.onMenuItemClick}
                  onRootMenuItemClick={this.onRootMenuItemClick}
                  layoutMode={this.state.layoutMode}
                  active={this.state.menuActive}
                />
                <hr />
                <span style={{ color: "black" }}></span>

                {localStorage.getItem("roleType") !== "10" &&
                  localStorage.getItem("roleType") !== "20" && (
                    <div className="p-grid">
                      <div className="p-col-12">
                        <center>
                          <pr.Button
                            label={"Switch to PPE"}
                            onClick={(e) => {
                              this.checkForPPEProfile();
                            }}
                          />
                        </center>
                      </div>
                    </div>
                  )}
              </div>
            </pr.ScrollPanel>
          </div>

          <div className="layout-main">
            <Route
              path="/business_settings"
              exact
              render={(props) => (
                <BusinessProvider
                  {...props}
                  viewType={ViewType.MY_BUSINESS}
                  crudlState={CrudlStatus.UPDATE}
                />
              )}
            />
            <Route path="/category" exact component={CategoryProvider} />
            <Route path="/dashboard" exact component={DashboardProvider} />
            <Route
              path="/council_dashboard"
              exact
              render={(props) => (
                <DashboardProvider {...props} viewType={ViewType.COUNCIL} />
              )}
            />
            <Route
              path="/enquiries"
              exact
              render={(props) => (
                <ApplicationProvider {...props} viewType={ViewType.ENQUIRIES} />
              )}
            />
            <Route
              path="/overview_of_applications"
              exact
              render={(props) => (
                <ApplicationProvider {...props} viewType={ViewType.COUNCIL} />
              )}
            />
            <Route
              path="/overview_of_applications/:globalFilter"
              exact
              render={(props) => (
                <ApplicationProvider {...props} viewType={ViewType.COUNCIL} />
              )}
            />

            {/* <Route
              path="/enquiries_old"
              exact
              render={(props) => (
                <ApplicationProvider {...props} viewType={ViewType.STANDARD} />
              )}
            /> */}
            <Route path="/ppe" exact component={PPEProvider} />
            <Route path="/product" exact component={ProductProvider} />
            <Route
              path="/my_product"
              render={(props) => (
                <ProductProvider {...props} viewType={ViewType.MY_PRODUCT} />
              )}
            />
            <Route
              path="/my_requirement"
              render={(props) => (
                <ProductProvider
                  {...props}
                  viewType={ViewType.MY_REQUIREMENT}
                />
              )}
            />
            <Route
              path="/supplier_products"
              render={(props) => (
                <ProductProvider {...props} viewType={ViewType.SUPPLIER} />
              )}
            />
            <Route
              path="/buyer_requirements"
              render={(props) => (
                <ProductProvider {...props} viewType={ViewType.BUYER} />
              )}
            />
            <Route
              path="/buyer_profiles"
              render={(props) => (
                <ProfileProvider {...props} viewType={ViewType.BUYER} />
              )}
            />
            <Route
              path="/supplier_profiles"
              render={(props) => (
                <ProfileProvider {...props} viewType={ViewType.SUPPLIER} />
              )}
            />
            <Route
              path="/all_profiles"
              exact
              render={(props) => (
                <ProfileProvider {...props} viewType={ViewType.COUNCIL} />
              )}
            />
            <Route
              path="/all_profiles/:globalFilter"
              exact
              render={(props) => (
                <ProfileProvider {...props} viewType={ViewType.COUNCIL} />
              )}
            />
            <Route path="/reporting" exact component={ReportingProvider} />
            <Route path="/user" exact component={UserProvider} />
            <Route
              path="/council_users"
              exact
              render={(props) => (
                <UserProvider {...props} viewType={ViewType.COUNCIL} />
              )}
            />
            <Route
              path="/configuration"
              exact
              render={(props) => (
                <ConfigurationProvider {...props} viewType={ViewType.COUNCIL} />
              )}
            />
            <Route
              path="/Council_Gap_Analysis"
              exact
              component={CouncilGapAnalysis}
            />
            <Route path="/Help_Desk" exact component={HelpDesk} />

            <MasterFooterImage />
          </div>

          <AppRightPanel
            expanded={this.state.rightPanelActive}
            onContentClick={this.onRightPanelClick}
          />

          <div className="layout-mask" />
        </div>
      </div>
    );
  }
}
