import firebase from "firebase";
import "firebase/analytics";
import * as Constants from "./AppConstants";
import axios from "axios";

// IMPORT GOES HERE
//REPLACE ME - START
//DEVELOPMENT
import * as libraries from "swaggerV3Client";
import * as jsonKeys from "../config/Files/production.json";
const importLogLevels = [
  Constants.LogLevel.STANDARD,
  Constants.LogLevel.WARNING,
  Constants.LogLevel.ERROR,
  Constants.LogLevel.SEVERE,
];
const buildEnvironment = "PRODUCTION";
//REPLACE ME - END

//default
let clientLibs = libraries;
let keys = jsonKeys;
let environment = buildEnvironment;
let logLevels = importLogLevels;

console.log("Environment --> " + environment);
export { clientLibs as ClientLibraries, environment, logLevels };

//Standard Functions and Setup

export const getConfig = () => {
  console.log(keys);
  var config = {
    apiKey: keys.default.firebase.apiKey,
    authDomain: keys.default.firebase.authDomain,
    databaseURL: keys.default.firebase.databaseURL,
    projectId: keys.default.firebase.projectId,
    storageBucket: keys.default.firebase.storageBucket,
    messagingSenderId: keys.default.firebase.messagingSenderId,
    appId: keys.default.firebase.appId,
  };

  return config;
};

export const getApiKey = () => {
  return keys.default.apiKey;
};

firebase.initializeApp(getConfig());
firebase.initializeApp(getConfig, "Secondary");

export const getFirebaseDatabase = () => {
  return firebase.database().ref();
};

export const createUserWithFirebase = (email, password) => {
  var opts = {
    email: email,
    password: password,
  };

  return axios({
    method: "post",
    url:
      "https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=" +
      getApiKey(),
    params: opts,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => {
    if (res.data) {
      console.log(res.data);
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log(res);
    }
  });
};

export const getFirebaseStorage = () => {
  return firebase.storage().ref();
};

export const getFirebaseAuth = () => {
  return firebase.auth;
};
export const getFirestore = () => {
  return firebase.firestore();
};
export const getFirebaseAnalytics = () => {
  return firebase.analytics();
};
