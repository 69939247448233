import { BaseViewModel } from "../../common/BaseViewModel";
import * as Format from "../../common/FormatFunctions";
import { CloudService } from "../../common/CloudService/ClientLibsReference";
import { CrudlStatus, ViewState } from "../../common/config/AppConstants";
export class ConfigurationViewModel extends BaseViewModel {
  getConfigurations = async () => {
    //CRITICAL - ERROR + EMPTY MESSAGE
    var configurationResponse = {}; // await this.getConfigurationListByCodeTag();
    if (
      Format.isNotNull(configurationResponse.errorCode) &&
      configurationResponse.errorCode === 0
    ) {
      if (Format.isNotNull(configurationResponse.configurationList)) {
        this.updateState(
          "configurationList",
          configurationResponse.configurationList
        );
        this.logger.info(configurationResponse.configurationList);
      } else {
        this.showEmpty("Configuration", ViewState.TABLE);
      }
    } else {
      this.showError("Configuration", CrudlStatus.LIST, ViewState.TABLE);
    }
  };

  getConfigurationsReturned = async () => {
    //CRITICAL - ERROR + EMPTY MESSAGE
    var configurationResponse = {}; // await this.getConfigurationListByCodeTag();
    if (
      Format.isNotNull(configurationResponse.errorCode) &&
      configurationResponse.errorCode === 0
    ) {
      if (Format.isNotNull(configurationResponse.configurationList)) {
        this.logger.info(configurationResponse.configurationList);
        return configurationResponse.configurationList;
      } else {
        this.showEmpty("Configuration", ViewState.TABLE);
      }
    } else {
      this.showError("Configuration", CrudlStatus.LIST, ViewState.TABLE);
    }
  };

  reloadDataAfterExecute = (
    configurations,
    apiResponse,
    type,
    externalStateValues
  ) => {
    var updatedConfigurationList = [];
    if (type === CrudlStatus.CREATE) {
      updatedConfigurationList = this.reloadAfterCreate(
        configurations,
        apiResponse,
        externalStateValues
      );
    }
    if (type === CrudlStatus.UPDATE) {
      updatedConfigurationList = this.reloadAfterUpdate(
        configurations,
        apiResponse,
        externalStateValues
      );
    }
    if (type === CrudlStatus.DELETE) {
      updatedConfigurationList = this.reloadAfterDelete(
        configurations,
        apiResponse,
        externalStateValues
      );
    }

    this.updateState("configurationList", updatedConfigurationList);
  };

  reloadAfterCreate = (configurationList, apiResponse, externalStateValues) => {
    if (configurationList === undefined || configurationList === null) {
      configurationList = [];
    }
    var configurationBean = externalStateValues.configurationBean;
    configurationBean.id = apiResponse.id;
    configurationBean.lastUpdatedDateTime = apiResponse.lastUpdatedDateTime;

    configurationList.push(configurationBean);

    return configurationList;
  };

  reloadAfterUpdate = (configurationList, apiResponse, externalStateValues) => {
    var i = 0;
    configurationList.forEach((e) => {
      if (apiResponse.id === e.id) {
        configurationList[i] = externalStateValues.configurationBean;
        configurationList[i].lastUpdatedDateTime =
          apiResponse.lastUpdatedDateTime;
      }
      i++;
    });

    return configurationList;
  };

  reloadAfterDelete = (configurationList, apiResponse, externalStateValues) => {
    var i = 0;
    configurationList.forEach((e) => {
      //DELETE
      if (apiResponse.id === e.id) {
        configurationList.splice(i, 1);
      }
      i++;
    });

    return configurationList;
  };
  executeCRUDLAction = async (crudlStatus, stateVariables) => {
    let configurationRequest =
      CloudService.ConfigurationRequest.constructFromObject(
        stateVariables.configurationBean
      );
    if (crudlStatus === CrudlStatus.CREATE) {
      return await this.createConfiguration(
        configurationRequest,
        stateVariables
      );
    }
    if (crudlStatus === CrudlStatus.UPDATE) {
      return await this.updateConfiguration(
        configurationRequest,
        stateVariables
      );
    }
    if (crudlStatus === CrudlStatus.DELETE) {
      return await this.deleteConfiguration(configurationRequest);
    }
  };

  createConfiguration = async (configurationRequest, stateVariables) => {
    configurationRequest.codeTag = this.codeTag;
    configurationRequest.userId = this.userId;

    let configurationResponse = {}; // await ApiService.configurationCreate(configurationRequest);
    if (configurationResponse.errorCode === 0) {
      this.showSuccess(
        "Configuration",
        CrudlStatus.CREATE,
        stateVariables.viewState
      );
    } else {
      this.showError("Configuration", CrudlStatus.CREATE, ViewState.CARD);
    }

    return configurationResponse;
  };

  deleteConfiguration = async (configurationRequest) => {
    //RESET PRODUCT REQUEST
    var request = {};
    request.codeTag = this.codeTag;
    request.userId = this.userId;
    request.id = configurationRequest.id;
    request.lastUpdatedDateTime = configurationRequest.lastUpdatedDateTime;

    let configurationResponse = {}; // await ApiService.configurationDelete(request);
    if (configurationResponse.errorCode === 0) {
      this.showSuccess("Configuration", CrudlStatus.DELETE, ViewState.TABLE);
    } else {
      this.showError("Configuration", CrudlStatus.DELETE, ViewState.CARD);
    }

    return configurationResponse;
  };

  updateConfiguration = async (configurationRequest, stateVariables) => {
    configurationRequest.codeTag = this.codeTag;
    configurationRequest.userId = this.userId;

    let configurationResponse = {}; // await ApiService.configurationUpdate(configurationRequest);
    if (configurationResponse.errorCode === 0) {
      this.showSuccess(
        "Configuration",
        CrudlStatus.UPDATE,
        stateVariables.viewState
      );
    } else {
      this.showError("Configuration", CrudlStatus.UPDATE, ViewState.CARD);
    }

    return configurationResponse;
  };
}
